import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {useParams, useNavigate, Link} from "react-router-dom";
import $api, {API_URL} from "../http";
import {Context} from "../index";
import OrderChat from "./helpers/OrderChat";
import ModerationChat from "./helpers/ModerationChat";
import {observer} from 'mobx-react-lite';
import RequestSpace from "./helpers/RequestSpace";
import Stepper from "./helpers/Stepper";
import ModalDialog from "./helpers/ModalDialog";
import ReviewMaker from "./helpers/ReviewMaker";
import EmptyMessage from "./helpers/EmptyMessage";
import Dossier from "./Dossier";
import {toJS} from 'mobx'
import NestedMessageModal from "./helpers/NestedMessageModal";
import SvgAnswer from "../img/answer-arrow.svg";
import TextExpander from "./helpers/TextExpander";
import Loading from "./Loading"
import SvgOpenedEye from "../img/opened-eye.svg";
import SvgClosedEye from "../img/eye-closed.svg";
import SvgCheckUnread from "../img/checkmark-unread.svg"
import SvgCheckRead from "../img/checkmark-read.svg"
import {downloadHandler, getMinutesBetweenDates, textProcessor} from "./helpers/repeat_functions";
import ModalImgViewer from "./helpers/ModalImgViewer";
import Testtsttest from "./helpers/testtsttest";
import SvgPinRed from "../img/pin-red.svg";






//Это компонент для запроса и показа данных о конкретном заказе по его айдишнику
const OrderDetails = (props) => {


    const {store} = useContext(Context)

    const navigate = useNavigate()


    const markedElements = useRef(new Set())


    const stopMakingObserver = useRef(false)

    //этот стейт будет получать в свою переменную все детали заказа (автор, стоимость, дисуиплина и т.д.)
    const [orderDetails, setOrderDetails] = useState([])
    const [orderDoesntExist, setOrderDoesntExist] = useState(false)

    const [loading, setLoading] = useState(true)

    //стейт который говорит нужна ли ссылка на файл
    const [needLink, setNeedLink] = useState(false)

    //этот стейт триггерит useEffect, который обновляет ссылку, это нужно потому что на Я.Диск загрузка идет не быстро
    //и сначала ссылки нет, она появляется позже и человека нужно об этом уведомить
    const [link, setLink] = useState('')

    const [reviewSuccessFullyMade, setReviewSuccessFullyMade] = useState(false)


    //стейты чата:
    //видимость
    const [showChat, setShowChat] = useState({display: "none"})

    //основной массив сообщений
    const [chatMessagesMain, setChatMessagesMain] = useState([])


    const [allSortedMessages, setAllSortedMessages] = useState()
    const [allUsers, setAllUsers] = useState()
    const [userListToRoll, setUserListToRoll] = useState()
    const [messageListToRoll, setMessageListToRoll] = useState([])
    //короткая инфа о каждом юзере, айди, имя и фамилия
    //вид {айди юзера: {объект инфы}}
    const [shortUsersInfo, setShortUsersInfo] = useState({})

    const newMessageLineRef = useRef(true)



    //является ли автором данного заказа тот кто отправляет сообщение?
    const [isAuthor, setIsAuthor] = useState(false)

    //отмечаем активный чат
    const [activeUser, setActiveUser] = useState()

    const [activeSafeDealStart, setActiveSafeDealStart] = useState("no")
    const [activeHidePrice, setActiveHidePrice] = useState("no")
    const [activeDeleteModal, setActiveDeleteModal] = useState("no")
    const [activeInfoModal, setActiveInfoModal] = useState("no")
    const [activeArbitrInfoModal, setActiveArbitrInfoModal] = useState("no")
    const [activeRefuseModal, setActiveRefuseModal] = useState("no")
    const [activeCancelModeration, setActiveCancelModeration] = useState("no")
    const [activeAcceptModal, setActiveAcceptModal] = useState("no")
    const [activeDeclineModal, setActiveDeclineModal] = useState("no")
    const [activeCloseByModer, setActiveCloseByModer] = useState("no")
    const [activeArbitrAccess, setActiveArbitrAccess] = useState("no")
    const [activeArbitrationStart, setActiveArbitrationStart] = useState("no")
    const [activeArbitrationStop, setActiveArbitrationStop] = useState("no")
    const [activeShowDocumentModal, setActiveShowDocumentModal] = useState("no")
    const [activeSetPrice, setActiveSetPrice] = useState("no")
    const [fileViewPath, setFileViewPath] = useState("")
    const [fileViewName, setFileViewName] = useState("")
    const [fileViewType, setFileViewType] = useState("")


    const [winner, setWinner] = useState(null)
    const [decision, setDecision] = useState()


    const [isThereUnreaded, setIsThereUnreaded] = useState(false)


    const [wrongWay, setWrongWay] = useState(false)


    //хук useParams нужен для того, чтобы получить "параметр", как написано в инструкции
    //но на самом деле динамический кусок адреса, который закодирован, в данном случае как (:id)
    //в строке <Route path="/order-details/:id" element={<OrderDetails/>}/>
    //а строчка эта использована при настройке маршрутизации в компоненте MainField
    //это очень важная концепция, возможно простая для понимания, но сука очень непростая чтобы ее загуглить
    const params = useParams()
    //закидываем айдишник заказа в переменную, а ее пихаем ниже как аргумент в getDetails внутри useEffect
    const order_id = params.id

    const [requested, setRequested] = useState(false)

    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})


    const messagesAreaRef = useRef()

    const [unreadUsers, setUnreadUsers] = useState([])

    const [slicer, setSlicer] = useState({start: 0, stop: 50})
    const slicerRef =  useRef(0)

    const readMessages = useRef([])


    const inputRef = useRef(null)
    const [inputKey, setInputKey] = useState(0);
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [filesId, setFilesId] = useState(null)
    const [attachedFiles, setAttachedFiles] = useState([])
    const [fileNames, setFileNames] = useState([])

    function handleFileResponse(response) {
        if (response.status < 400) {

        setAttachedFiles((prev) => [...prev, ...response.data.files]);
        const names = Array.from(response.data.files, (file) => file.name);
        setFileNames((prev) => [...prev, ...names]);


        } else {
            setErrorMessage({ show: true, text: "Не удалось загрузить файл на сервер" });
        }
    }

    //проверялка длины имени файла
    async function lengthController(files){

        console.log(files)


        for (let file of files) {
            if(fileNames.includes(file.name)) {
                setErrorMessage({show: true, text: "Этот файл уже добавлен"})
                return false
            }
        }

        if(files.length > 10) {
            setErrorMessage({
                show: true,
                text: `Максимальное количество файлов, которое можно прикрепить к заказу 10, вы 
                пытаетесь прикрепить ${files.length}, так не получится =)`})
            return false
        }


        for(let i=0; i < files.length; i++) {
            if(files[i].name.length > 140) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет длину имени более 140 символов,
                    его нужно сократить, речь идет о файле ${files[i].name}`})
                return false
            }

            if(files[i].size > 104857600) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет размер более 100 мегабайт,
                    мы не принимаем файлы, чей размер превышает это значение, речь идет о файле ${files[i].name}`})
                return false
            }

            if(files[i].type === "application/x-msdownload") {
                setErrorMessage({
                    show: true,
                    text: `Вы пытаетесь прикрепить исполняемый файл, это противоречит всему на свете, речь идет о файле ${files[i].name}`})
                return false
            }

        }

        console.log(files)

        return true
    }

    async function attacheFilesHandler(e) {
        e.preventDefault();

        setUploading(true);
        setInputKey((prevKey) => prevKey + 1);

        await setErrorMessage((prev) => ({ show: false, text: "" }));

        const filesToAttach = e.target.files;

        console.log(filesToAttach)

        if (await lengthController(filesToAttach)) {
            try {
                // if (filesId) {
                //     const response = await $api.post(`/add_extra_materials_to_order/${store.user.id}/${orderDetails._id}/${filesId}`, filesToAttach, {
                //         onUploadProgress: (progressEvent) => {
                //             const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //             setUploadProgress(percentCompleted);
                //         },
                //     });
                //
                //     handleFileResponse(response);
                // } else {
                    console.log("This IS ELSE")
                    const response = await $api.post(`/add_extra_materials_to_order/${store.user.id}/${orderDetails._id}/`, filesToAttach, {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        },
                    });

                    console.log(response.data)
                    handleFileResponse(response);
                // }
            } catch (e) {
                setErrorMessage((prev) => ({ show: true, text: e.message }));
            }
        }

        setUploadProgress(0); // Сброс прогресса после завершения загрузки
        setUploading(false);
    }


    const [activeFileDoesntExist, setActiveFileDoesntExist] = useState("no")
    const [activeModalImgViewer, setActiveModalImgViewer] = useState("no")
    const [imageSource, setImageSource] = useState()


    function linkRoller(linksArray) {
        const result = []
        for(let link of linksArray) {

            let type

            if(link.type === "doc" || link.type === "docx") {
                type = "word"
            } else if (link.type === "xls" || link.type === "xlsx") {
                type = "excel"
            } else if (link.type === "ppt" || link.type === "pptx") {
                type = "power_point"
            } else if (link.type === "rar" || link.type === "zip") {
                type = "winrar"
            } else if (link.type === "pdf") {
                type = "pdf"
            } else if (link.type === "image") {
                type = "image"
            } else {
                type = "doc_thumb"
            }

            if(type === "image") {

                result.push(
                    <div
                        className={"chat-pre-image-2"}
                        onClick={() => {
                            setActiveModalImgViewer("progress")
                            setTimeout(() => {
                                setActiveModalImgViewer("active")
                            }, 100)
                            setImageSource(link.path)
                        }}
                    >
                        {/*<a className={"link-from-roller"} href={API_URL + "/" + link.path} target={"_blank"}>*/}
                        <img
                            className={"chat-pre-image dont-drag"}
                            src={API_URL + "/" + link.thumbnail}

                        />
                        {/*</a>*/}

                    </div>

                )
            } else {


                const filenameWindows = link.path.substring(link.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                let filename;

                if (filenameWindows.includes('/')) {
                    filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                } else {
                    filename = filenameWindows.replace(/_/g, " ");

                }

                result.push(
                    <div>


                        <a
                            className={"fake-link link-from-roller "}
                            onClick={async() => {

                                const condition = await downloadHandler(link.path)

                                if(condition === "FAIL") {
                                    setActiveFileDoesntExist("progress")
                                    setTimeout(() => {
                                        setActiveFileDoesntExist("active")
                                    }, 100)
                                }
                            }}
                        ><img className={"order-done-file-image dont-drag"} src={require(`../img/${type}.png`)} width={"35"}/>
                            "{filename.length > 60 ? filename.slice(0, 60) + "..." + filename.slice(-10) : filename}"</a>
                    </div>

                )
            }

        }

        return result

    }


    async function safeDealActivate(account, fee) {
        console.log(account, fee)
        const response = await $api.post('/safe_deal_init', {account, fee, order_id: orderDetails._id})
        console.log(response.data)
    }


    async function acceptOrder(order_id, price) {

        console.log(order_id, price)
        await $api.post('/assign_order', {doer: store.user.id, order: order_id, price, accept: true})

    }


    async function declineOrder(order_id, my_id) {
        console.log("START DECLINING")
        const response = await $api.post('/decline_order', {order_id, my_id})
        console.log(response.data)
        if(response.data === "OK") navigate('/order-feed')

    }




    const updateMessagesReadStatus = async (orderServiceMessage) => {

        stopMakingObserver.current = true

        const { direction, order_id, messagesIdArray, reader } = orderServiceMessage;


        if(allSortedMessages[activeUser]) {

            console.log(allSortedMessages)
            const updatedMessages = allSortedMessages[reader].map((message) => {

                console.log(message.read)

                if(!isThereUnreaded && showChat.display === "none" && message.userId._id !== store.user.id && !message.read.includes(store.user.id)) {
                    console.log("STUNT 1")
                    console.log(message)
                    setIsThereUnreaded(true)
                }

                if (messagesIdArray.includes(message._id)) {
                    return {
                        ...message,
                        read: [...message.read, reader],
                    };
                }
                return message;
            });

            console.log("ALL SORTED SET 1")
            setAllSortedMessages((prev) => ({
                ...prev,
                [reader]: updatedMessages,
            }));


        } else {
            const updatedMessages = allSortedMessages.map((message) => {
                console.log(message.read)
                if(!isThereUnreaded && showChat.display === "none" && message.userId._id !== store.user.id && !message.read.includes(store.user.id)) {

                    console.log("STUNT 2")
                    console.log(message)
                    setIsThereUnreaded(true)
                }

                if (messagesIdArray.includes(message._id)) {
                    console.log("returning updated")
                    return {
                        ...message,
                        read: [...message.read, reader],
                    };
                }
                console.log("returning old")
                return message;
            });
            console.log("ALL SORTED SET 2")

            console.log(updatedMessages)
            await setAllSortedMessages(updatedMessages);

        }


        // Выбор стейта на основе direction
        //const targetState = direction === "in" ? outgoingOrdersMessages : incomingOrdersMessages;


        // Поиск сообщений по order_id
        // const orderMessages = targetState[order_id];
        //
        // if (orderMessages) {
        //     // Обновление read для соответствующих сообщений
        //     const updatedMessages = orderMessages.map((message) => {
        //         if (messagesIdArray.includes(message._id)) {
        //             return {
        //                 ...message,
        //                 read: [...message.read, reader],
        //             };
        //         }
        //         return message;
        //     });
        //
        //     // Обновление стейта
        //     if (direction === "in") {
        //         setOutgoingOrdersMessages((prev) => ({
        //             ...prev,
        //             [order_id]: updatedMessages,
        //         }));
        //     } else {
        //         setIncomingOrdersMessages((prev) => ({
        //             ...prev,
        //             [order_id]: updatedMessages,
        //         }));
        //     }
        // }
    }

    const queueTimer = useRef()


    async function mergeArraysAndProcess(inputArray) {
        // Группируем объекты по полю order_id
        const groupedObjects = inputArray.reduce((groups, obj) => {
            const orderId = obj.order_id;

            if(orderId !== orderDetails._id) {
                return groups
            }

            if (!groups[orderId]) {
                groups[orderId] = [];
            }
            groups[orderId].push(obj);
            return groups;
        }, {});

        // Обработка каждой группы
        //for (const orderId in groupedObjects) {
            if (groupedObjects.hasOwnProperty(orderDetails._id)) {
                const group = groupedObjects[orderDetails._id];

                // Создаем объект для хранения уникальных элементов messagesIdArray
                let uniqueMessages = {};

                // Проходим по каждому объекту в группе
                group.forEach(obj => {
                    // Объединяем значения messagesIdArray в объект uniqueMessages
                    obj.messagesIdArray.forEach(messageId => {
                        uniqueMessages[messageId] = true;
                    });
                });

                // Создаем объект для результирующего массива
                let resultObject = {
                    // Выбираем любой объект из группы для копирования значений
                    ...group[0],
                    // Присваиваем объединенный массив uniqueMessages полю messagesIdArray
                    messagesIdArray: Object.keys(uniqueMessages)
                };


                console.log(resultObject)
                // Передаем объект в функцию и ждем выполнения
                await updateMessagesReadStatus(resultObject);
            }
        //}
    }




    useEffect(() => {

        // if(props.orderServiceMessage) {
        //     console.log("WE HAVE NEW SERVICE MESSAGE")
        //     console.log(props.orderServiceMessage)
        //     updateMessagesReadStatus(props.orderServiceMessage)
        // }


        if(props.orderServiceMessage?.length) {


            clearTimeout(queueTimer.current)

            queueTimer.current = setTimeout(() => {

                //updateMessagesReadStatus(mergeArrays(orderServiceMessage))
                console.log("Тут запускается магия!")
                console.log(props.orderServiceMessage)
                mergeArraysAndProcess(props.orderServiceMessage)
                props.setOrderServiceMessage([])

            }, 1500)

            console.log("WE HAVE NEW SERVICE MESSAGE")


        }



    }, [props.orderServiceMessage])


    useEffect(() => {
        if(!props.moderation) {
            store.editWhitePointer({place: "Детали заказа:"})
        }

    }, [])



    useEffect(() => {

        if(props.addTextArea) {
            setOrderDetails(prev => {
                return {...prev, moderator: store.user.id}
            })
        }

    }, [props.addTextArea])


    // useEffect(() => {
    //     if(props.moderation) {
    //         setChatMessages(props.messages)
    //     }
    // }, [props.messages])



    async function getDetails(order_id) {

        //await setErrorMessage(prev => prev.show = false)



        try {
            const response = await $api.get(`/order-details/${order_id}/${store.user.id}`)

            console.log(response.data)

            if (response.data.message === "ABSENT" || (response.data.orderStatus === "draft" && response.data.creator._id !== store.user.id  && !props.adminView) ) {
                console.log("SET DOESNT EXIST")

                setOrderDoesntExist(true)
            }

            //если заказ приватный и юзер не является его потенциальныи исполнителем или создателем, то показываем заглушку
            if(response.data.orderStatus === "private" && (response.data.potentialDoer?._id !== store.user.id && store.user.id !== response.data.creator._id) && !props.adminView) {
                setOrderDoesntExist(true)
            }

            //если респонс пустой, то похоже такого заказа либо никогда не было, либо его удалили
            //перекидываем юзера на страницу ленты заказов
            if(!response.data) {
                navigate('/order-feed')
            }

            console.log(response.data?.orderStatus !== "offer"
                && store.user.id !== response.data.doer?._id
                && store.user.id !== response.data.creator._id
                && !store.user.arbitr
                && !response.data.arbitration)


            if((orderDetails.doer?._id === store.user.id && orderDetails.reviews?.doer)
                || (orderDetails.creator?._id === store.user.id && orderDetails.reviews?.creator)
            ) {
                setReviewSuccessFullyMade(true)
            }






            //если заказ не в статусе offer и юзер не является ни создателем ни исполнителем, то перекидываем его на заглушку с сообщением
            if(response.data.orderStatus !== "offer"
                && store.user.id !== response.data.doer?._id
                && store.user.id !== response.data.creator._id
                && (!store.user.arbitr || (store.user.arbitr && !response.data.arbitration))
                && !store.user.admin
                && response.data.potentialDoer?._id !== store.user.id
            ) {
                await store.setErrorMessage(`Вы пытаетесь открыть страницу с деталями заказа #${response.data.number} "${response.data.title}", однако на этот заказ уже назначен другой исполнитель`)
                //navigate('/wrong_way')
                setWrongWay(true)

            }



            const messages = response.data.msgs.reverse()

            console.log(messages)



            const unreadSet = new Set()
            setAttachedFiles(response.data.extraMaterials)

            //если текущий пользователь создатель заказа
            if(response.data.creator._id === store.user.id && !response.data.doer) {

                setIsAuthor(true)


                //то надо привести в порядок сообщения, отсортировать по отправителю
                let orderMessagesTmp = messages.reduce((acc, current) => {

                    console.log(current)
                    if(!isThereUnreaded && showChat.display === "none" && current.userId?._id !== store.user.id && !current.read.includes(store.user.id)) {

                        console.log("STUNT 3")
                        console.log(current)
                        setIsThereUnreaded(true)
                    }

                    if(!current.read.includes(store.user.id)) {
                        unreadSet.add(current.userId?._id)
                    }

                    //если в акке уже есть ключ отправителя сообщения и отправитель не текущий юзер
                    if(acc.hasOwnProperty(current.userId?._id) && current.userId?._id !== store.user.id) {
                        acc[current.userId._id].push(current)}

                    //если в акке нет ключа отправителя сообщения и отправитель не текущий юзер
                    else if(!acc.hasOwnProperty(current.userId?._id) && current.userId?._id !== store.user.id) {
                        acc[current.userId._id] = [current]

                        //если отправитель текущий юзер (создатель заказа) и поле получателя как ключ есть
                    } else if(current.userId?._id === store.user.id && acc.hasOwnProperty(current.to[0])) {
                        acc[current.to].push(current)

                        //если отправитель текущий юзер (создатель заказа) и ключа получателя как ключ не существует
                    } else if(current.userId?._id === store.user.id && !acc.hasOwnProperty(current.to[0])) {
                        acc[current.to] = [current]
                    }

                    return acc
                }, {})
                await setUnreadUsers([...unreadSet])
                console.log("SETTING THIS")

                console.log(orderMessagesTmp)
                console.log("ALL SORTED SET 3")
                await setAllSortedMessages(orderMessagesTmp)
                await getShortUsersInfo(Object.keys(orderMessagesTmp))
            } else {
                console.log("SETTING THAT")
                console.log(messages)

                for(let message of messages) {
                    console.log(message.read)

                    if(!isThereUnreaded && showChat.display === "none" && message.userId._id !== store.user.id && !message.read.includes(store.user.id) && response.data.orderStatus !== "closed") {

                        console.log("STUNT 4")
                        console.log(message)
                        setIsThereUnreaded(true)
                        break;
                    }
                }
                console.log("ALL SORTED SET 4")
                await setAllSortedMessages(messages)
            }


            //await setAllUsers(Object.keys(orderMessagesTmp))


            await setOrderDetails(response.data)


            // const messages = response.data.messages
            // //console.log(messages)
            // const messages_to_pack = await messages.reverse()
            // console.log("FETCH MESS 0")
            // console.log(messages_to_pack)
            // await setChatMessagesMain(messages_to_pack)
            //console.log(chatMessagesMain)



            //если айдишник юзера содержется в запросах на этот заказ, то помечаем его как запрошенный
            if(response.data?.requests.indexOf(store.user.id) > -1) {
                setRequested(true)
            } else {
                setRequested(false)
            }


        } catch (e) {
            console.log(e)
            setErrorMessage({show: true, text: "Не удалось загрузить детали заказа"})
        }

        setLoading(false)

    };




    useEffect(() => {

        //запрашиваем детали заказа


        if(order_id) {
            getDetails(order_id)
        }

        if(props.moderation && props.moderatedOrder){

            setOrderDetails(props.moderatedOrder)
        }

        if(props.orderStatus === "closed" && store.user.id === orderDetails.creator._id) {
            store.removeMessagesAboutOutgoing(order_id)
        }

        console.log(props.moderatedOrder)

        if(props.moderatedOrder) {
            setLoading(false)
        }



    }, [store.update, store.updateOrderProcess, props.moderatedOrder])


    //функция добавлятор инфы про юзера в стейт с такой инфой
    function adderToInfo(prev, response) {
        const result = { ...prev };

        for (const key in response) {
            result[key] = response[key];
        }

        return result;
    }


    //функция запроса короткой инфы по юзеру из быза на основе его айдишника, или массива айдишников
    async function getShortUsersInfo(idList) {
        console.log(idList)
        //проверяем с помощью фильтра есть ли уже инфа по юзеру из данного списка
        //создаем массив только по тем юзерам которые нужны для запроса
        const listOfFilteredIds = idList.filter(id => !shortUsersInfo.hasOwnProperty(id));
        //если этот список получается не пустым
        console.log(listOfFilteredIds)
        if (listOfFilteredIds.length) {
            //...то передаем его на бэкенд
            const response = await $api.post('/get_short_user_info', {idList: listOfFilteredIds})
            //то что прилетело в ответ с помощью функции добавлятора, собственно добавляем в стейт с инфой по юзерам
            setShortUsersInfo(prev => adderToInfo(prev, response.data.shortInfo))
        }
    }




    useEffect(() => {

        const orderUsers = Object.keys(shortUsersInfo)?.map(user => {
            console.log("activeUser is - ", activeUser)
            console.log("Current User is - ", user)
            console.log(activeUser === user)
            console.log(user)
            console.log(unreadUsers)
            return <div key={user} className={"order-chat-user-buttons-n-notify-container"}>
                <div className={"private-notify-sign ubuntu"}>{unreadUsers.includes(user)
                    ?
                    "✉"
                    :
                    null

                }</div>
                <button
                    className={`btn user-switch-button ${activeUser === user ? "primary-btn-color" : "secondary-btn-color"}`}
                    onClick={() => {
                        console.log("setting active user to - ", user)
                        setActiveUser(user);

                    }}>
                    {shortUsersInfo[user].name + " " + shortUsersInfo[user].lastName}
                    {user === activeUser ? " ✓" : null}
                </button>
            </div>
        })

        setUserListToRoll(orderUsers)




    }, [shortUsersInfo, activeUser, unreadUsers])


    function dateLook(check) {
        if(new Date(Date.now()).toLocaleDateString() === new Date(check).toLocaleDateString()) {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleTimeString(
                    [],{hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        } else {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleDateString(
                    [],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        }
    }


    //const [pendingMessages, setPendingMessages] = useState([])

     const pendingTimer = useRef()
    //
    // useEffect(() => {
    //
    //     if(props.newOrderMessage && props.newOrderMessage?.about === orderDetails._id) {
    //
    //         console.log("МЫ ДВИЖЕМСЯ ПРАВИЛЬНО")
    //         console.log(pendingMessages)
    //
    //         setPendingMessages(prev => [...prev, props.newOrderMessage])
    //
    //
    //
    //     }
    //
    //     if(props.newOrderMessage.userId?._id !== store.user.id && showChat.display !== "none") {
    //         newMessageLineRef.current = false
    //     }
    //
    //
    //
    //
    //     setAlreadyRolled(false)
    //
    //
    // }, [props.newOrderMessage])



    const [nowHandling, setNowHandlind] = useState(false)


    useEffect(() => {

        if(props.pendingMessages?.length && !nowHandling) {

            clearTimeout(pendingTimer.current)

            pendingTimer.current = setTimeout(() => {
                console.log("ТАЙМЕР СРАБОТАЛ")
                handlePendingMessages(props.pendingMessages)
            }, 200)
        }


    }, [props.pendingMessages, nowHandling])




    async function handlePendingMessages(handlingArray) {
        console.log("HANDLE STARTED");
        setNowHandlind(true);
        stopMakingObserver.current = false;

        const usersData = {};

        props.setPendingMessages([])

        handlingArray.forEach(message => {

            console.log(message)


            if(message && message?.about === orderDetails._id) {

                console.log("Попадание в ветку!")

                const userId = message.userId?._id === store.user.id ? message?.to[0] : message.userId?._id;
                const sender = message.userId?._id


                if (!usersData[userId]) {
                    usersData[userId] = [message];
                } else {
                    usersData[userId].unshift(message);
                }

                if (showChat.display === "none" && sender !== store.user.id) {
                    console.log("STUNT 5")
                    console.log(message)
                    setIsThereUnreaded(true);
                }

                if (userId !== orderDetails.creator?._id) {
                    if (!Object.keys(shortUsersInfo).includes(userId)) {
                        getShortUsersInfo([userId]);
                    }
                }

            }

            if(message.userId?._id !== store.user.id && showChat.display !== "none") {
                newMessageLineRef.current = false
            }


        });
        console.log("ALL SORTED SET 5")
        setAllSortedMessages(prev => {

            console.log("setting all sorted")
            console.log(Array.isArray(prev))

            const updatedMessages = Array.isArray(prev) ? [...prev] : { ...prev };



            for (const userId in usersData) {

                if (Object.prototype.hasOwnProperty.call(usersData, userId)) {
                    console.log("Есть первый ИФ")
                    if (Array.isArray(updatedMessages)) {
                        console.log("Есть второй ИФ")
                        const existingIds = updatedMessages.map(message => message._id);
                        const newMessages = usersData[userId].filter(message => !existingIds.includes(message._id));
                        updatedMessages.unshift(...newMessages);
                    } else {
                        console.log("Есть второй ЭЛЗ")
                        if (!updatedMessages.hasOwnProperty(userId)) {
                            console.log("Есть третий ИФ")
                            updatedMessages[userId] = usersData[userId];
                        } else {
                            console.log("Есть третий ЭЛЗ")
                            const existingIds = updatedMessages[userId].map(message => message._id);
                            const newMessages = usersData[userId].filter(message => !existingIds.includes(message._id));
                            updatedMessages[userId] = [...newMessages, ...updatedMessages[userId]];
                        }
                    }
                }
            }


            return updatedMessages;
        });

        const newUnreadUsers = Object.keys(usersData).filter(userId => {
            const messagesForUser = usersData[userId];
            return messagesForUser.some(message => message.userId?._id === userId);
        });

        setUnreadUsers(prev => [...prev, ...newUnreadUsers]);
        setNowHandlind(false);
    }






    // useEffect(() => {
    //
    //
    //     console.log(props.newOrderMessage)
    //
    //     if(props.newOrderMessage && props.newOrderMessage?.about === orderDetails._id) {
    //
    //
    //
    //         stopMakingObserver.current = false
    //
    //
    //         setUnreadUsers(prev => [...prev, props.newOrderMessage.userId._id])
    //
    //
    //         console.log("Есть новое сообщение")
    //         console.log(props.newOrderMessage)
    //         //если сообщения лежат в массиве, то заказ смотрит либо модер, либо исполнитель, либо создатель, но уже назначен исполнитель
    //         if(Array.isArray(allSortedMessages)) {
    //             console.log("Блок массива")
    //
    //               if(showChat.display === "none" && props.newOrderMessage.userId._id !== store.user.id) {
    //                   setIsThereUnreaded(true)
    //               }
    //
    //             setAllSortedMessages(prev => [props.newOrderMessage, ...prev])
    //             //в противном случае заказ смотрит создатель и нужно нарулить маршрутизацию сохранения сообщения
    //         } else {
    //             console.log("Блок объекта")
    //
    //             //если сообщение не от создателя заказа и на заказе нет модератора
    //             if(props.newOrderMessage.userId?._id !== orderDetails.creator?._id) {
    //                 console.log("ИФ1")
    //                 if(showChat.display === "none" && props.newOrderMessage.userId._id !== store.user.id) {
    //                     setIsThereUnreaded(true)
    //                 }
    //                 setAllSortedMessages(prev => {
    //                     if(allSortedMessages?.hasOwnProperty(props.newOrderMessage?.userId?._id)) {
    //                         console.log("ИФ2")
    //                         return {
    //                             ...prev,
    //                             [props.newOrderMessage.userId._id]: [props.newOrderMessage, ...prev[props.newOrderMessage.userId._id]]
    //                         };
    //                     } else {
    //                         console.log("элз2")
    //                         // Если массив сообщений для заказа еще не существует, создаем его с новым сообщением
    //                         return {
    //                             ...prev,
    //                             [props.newOrderMessage.userId?._id]: [props.newOrderMessage]
    //                         };
    //                     }
    //                 })
    //             } else if(props.newOrderMessage.userId?._id === orderDetails.creator?._id) {
    //                 console.log("ИФ3")
    //                 if(showChat.display === "none" && props.newOrderMessage.userId._id !== store.user.id) {
    //                     setIsThereUnreaded(true)
    //                 }
    //                 console.log(props.newOrderMessage?.to[0])
    //                 setAllSortedMessages(prev => {
    //                     if(allSortedMessages?.hasOwnProperty(props.newOrderMessage?.to[0])) {
    //                         console.log("ИФ4")
    //
    //                         return {
    //                             ...prev,
    //                             [props.newOrderMessage.to[0]]: [props.newOrderMessage, ...prev[props.newOrderMessage.to[0]]]
    //                         };
    //                     } else {
    //                         console.log("элз4")
    //
    //                         // Если массив сообщений для заказа еще не существует, создаем его с новым сообщением
    //                         return {
    //                             ...prev,
    //                             [props.newOrderMessage?.to[0]]: [props.newOrderMessage]
    //                         };
    //                     }
    //                 })
    //             } else {
    //                 console.log("Непредвиденный случай!")
    //             }
    //
    //
    //             if (props.newOrderMessage.userId._id !== store.user.id && !Object.keys(shortUsersInfo).includes(props.newOrderMessage.userId._id)) {
    //
    //                 getShortUsersInfo([props.newOrderMessage.userId._id])
    //
    //             }
    //         }
    //     }
    //
    //
    //     if(props.newOrderMessage.userId?._id !== store.user.id && showChat.display !== "none") {
    //         newMessageLineRef.current = false
    //     }
    //
    //
    //
    //
    //     setAlreadyRolled(false)
    //
    //
    // }, [props.newOrderMessage])


    const [alreadyRolled, setAlreadyRolled] = useState(false)

    const [answeringMessageNative, setAnsweringMessageNative] = useState({
        text: null,
        id: null,
        date: null,
        self: null,
        quote: null,
        sender: null
    })


    function answerOnMessage(text, id, date, self, quote, sender) {
        console.log("SET 2")
        console.log(text, id, date, self)
        setAnsweringMessageNative({text: text, id: id, date: date, self: self, quote: quote, sender: sender})
    }



    function containsLink(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/;
        return linkRegex.test(text);
    }


    function splitWord(text, size) {
        const chunks = [];
        let container = [];
        console.log("NOW SPLITTING")
        console.log(text)

        for (let i = 0; i < text.length; i += size) {
            chunks.push(text.slice(i, i + size));
        }


        for(let chunk of chunks) {

            container.push(<span key={chunk}>{chunk}<wbr/></span>);
        }

        return container


    }

    function processMessageText(text) {

        if(text) {
            if (containsLink(text)) {
                const messageContent = text.replace(
                    /(https?:\/\/[^\s]+)/g,
                    (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
                );

                return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
            }
            //console.log(text)
            const main_array = text.split(" ")
            let decision = false

            let container = []

            for(let piece of main_array) {
                if (piece.length > 25) {
                    decision = true;
                    break
                }
            }

            if (decision) {

                const chunkSize = 20;



                //const wordChunks = splitWord(text, chunkSize);
                return splitWord(text, chunkSize);
                // for(let chunk of wordChunks) {
                //
                //     container.push(
                //         <span>{chunk}<wbr/></span>
                //     )
                // }
                //
                // return container

            } else {
                return text
            }
        } else {
            return text
        }


    }

    function textReducer(text) {
        if (text.length > 150) {
            return text.slice(0, 150) + "..."
        }
        return text
    }

    const [nestedContent, setNestedContent] = useState()
    const [stepCounter, setStepCounter] = useState(0)
    const [lastSteps, setLastSteps] = useState([])
    const [activeMessageModal, setActiveMessageModal] = useState("no")

    async function showNestedMessage(id, stepBack, marker) {
        console.log(id)
        console.log(marker, " - marker")
        const response = await $api.get(`/get_nested_message_work?id=${id}`)
        console.log(response.data)
        await setNestedContent(response.data)
        if (stepBack) {
            console.log("step back")
            setStepCounter(stepCounter - 1)
            setLastSteps(prev => prev.slice(0, -1));
        } else {
            console.log("step forward")
            setStepCounter(stepCounter + 1)
            setLastSteps(prev => [...prev, id])
        }

        setActiveMessageModal("progress")
        setTimeout(() => {
            setActiveMessageModal("active")
        }, 100)
    }



    function processQuote(message) {

        console.log(message)


        return <div className={"answered-message answered-right"}>

            {/*<p className={"private-message-date rem07"}>*/}
            {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
            {/*</p>*/}
            <span className={"bold rem08"}>{
                message.quote.isModerator || (message.quote_userId === orderDetails.moderator  && orderDetails.moderator)
                    ?
                    "Модератор"
                    :
                    message.quote?.userId === orderDetails?.creator?._id || message.quote_userId === orderDetails?.creator?._id
                        ?
                        orderDetails?.creator?._id === store.user.id
                            ?
                            "Я"
                            :
                            orderDetails?.creator?.name + " " + orderDetails?.creator?.lastName

                        :
                        orderDetails?.doer?._id === store.user.id
                            ?
                            "Я"
                            :
                            orderDetails?.doer
                                ?
                                orderDetails?.doer?.name + " " + orderDetails?.doer?.lastName
                                :
                                activeUser
                                    ?
                                    shortUsersInfo[activeUser]?.name + " " + shortUsersInfo[activeUser]?.lastName
                                    :
                                    store.user.name + " " + store.user.lastName

            }:</span>

            <span className={"rem08"}>
                {message.quote.text
                    ?
                    <TextExpander text={message?.quote.text} />
                    :
                    <TextExpander text={message?.quote_text} />
                }
            </span>

            {message.quote.quote || message.need_rolling
                ?
                <a
                    onClick={() => showNestedMessage(message.quote._id ? message.quote._id : message.quote , false, 2)}
                    className={'rem07 modal-link'}
                >показать вложение</a>
                :
                null
            }

        </div>
    }


    function computeSlicerCounter(messageMassive) {
        let counter = 0

        for(let message of messageMassive) {
            if(message.userId._id !== store.user.id
                && (!message.read?.includes(store.user.id)
                    || !readMessages.current?.includes(message._id))) {

                counter++
            }
            if(message.userId._id !== store.user.id
                && (message.read?.includes(store.user.id)
                    || readMessages.current?.includes(message._id))) {
                break;
            }
        }

        return counter

    }





    useEffect(() => {


        let newMessageLineSwitch = false
        let newMessagesLine = false
        let stopSearching = false

        let lastDate = Date.now()
        let lastUser = ""

        let index = 0

        if(allSortedMessages && activeUser && orderDetails.creator._id === store.user.id) {

            const rolledMessages = []

            if(allSortedMessages[activeUser]) {

                console.log("FIRST CASE")

                const slicerCounter = computeSlicerCounter(allSortedMessages[activeUser])
                let index = 0
                for(let message of allSortedMessages[activeUser]) {

                    console.log(activeUser)

                    if(slicerCounter > slicer.stop ? index <= slicerCounter + 30 : index <= slicer.stop){

                        const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                        //дата из прошлого сообщения для установки в разделительную линиию
                        const date = new Date(lastDate)
                        //обновление переменной с последней датой, именно в такой строжайшей последовательности
                        lastDate = message.date
                        lastUser = message.userId?._id


                        newMessagesLine = false
                        //это IF для первого сообщения при отправке заявки, его не должно быть видно
                        if (message.text === "#####") {
                            //return null
                        }

                        //если айдишник того кто написал данное сообщение совпадает с айдишником пользователя и..
                        //адресат сообщения это активный пользователь (открытый диалог) то разворачиваем такие сообщения
                        //справа в чате, это сообщения пользователя, ну то есть того человека который сидит за компом в данный момент))
                        if (message.userId._id === store.user.id) {


                            if (index === 0) {
                                stopSearching = true
                            }

                            if (newMessageLineSwitch) {
                                newMessageLineSwitch = false
                                newMessagesLine = true
                                console.log("СТАВИМ ФОЛС 1")
                                if (showChat.display !== "none") {
                                    newMessageLineRef.current = false
                                }
                            }


                            rolledMessages.push(<div className={"native-chat-main-message-wrapper"} key={message._id}>

                                <div className={"native-chat-message-wrapper-right"}>
                                    {store.user.id !== orderDetails.moderator && store.user.id !== orderDetails?.creator?._id && store.user.id !== orderDetails?.doer?._id && orderDetails.arbitration
                                        ?
                                        null
                                        :
                                        <img
                                            className={"private-arrow-svg arrows"}
                                            src={SvgAnswer}
                                            alt="Ответить"
                                            width={"25px"}

                                            onClick={(e) => {
                                                answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                            }}
                                        />
                                    }

                                    <div className={"chat-message chat-right"}>
                                        <div className={"order-chat-date-and-read-container"}>
                                            {dateLook(message.date)}
                                            {/*это глаз ЗАКАЗЧИКА когда еще не назначен исполнитель*/}
                                            <img
                                                className={"dont-drag work-read-img"}
                                                src={message.read.includes(activeUser) ? SvgCheckRead : SvgCheckUnread}
                                                width={"17px"}/>
                                        </div>
                                        {message.quote
                                            ?
                                            processQuote(message)
                                            :
                                            null
                                        }
                                        {textProcessor(message.text)}
                                        {message.links?.length
                                            ?
                                            <div className={"white-back margin-top-10"}>
                                                {linkRoller(message.links)}
                                            </div>
                                            :
                                            null
                                        }


                                    </div>

                                </div>
                                {newMessagesLine
                                    ?
                                    <div>
                                        <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                                    </div>
                                    :
                                    null
                                }
                                {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                            </div>)


                        } //else if (message.isModerator) {
                            //
                            //
                            // return <div className={"native-chat-message-wrapper-center"} key={message._id}>
                            //     <div
                            //     className={"chat-message chat-center"}
                            //
                            //     id={message._id}
                            //     data-read={message.read?.includes(store.user.id) || false}
                            // >
                            //     <div className={"chat-username"}>Модератор</div>
                            //     {dateLook(message.date)}
                            //     {message.text}
                            // </div>
                            //     {newMessagesLine
                            //         ?
                            //         <div>
                            //             <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                            //         </div>
                            //         :
                            //         null
                            //     }
                            // </div>

                        //}
                        else if (message.userId._id !== store.user.id) {


                            if (newMessageLineSwitch && message.read?.includes(store.user.id)) {
                                newMessageLineSwitch = false
                                newMessagesLine = true
                                console.log("СТАВИМ ФОЛС 2")
                                if (showChat.display !== "none") {
                                    newMessageLineRef.current = false
                                }
                            }


                            if (!stopSearching && newMessageLineRef.current && !message.read?.includes(store.user.id)) {
                                console.log(message)
                                newMessageLineSwitch = true
                                stopSearching = true
                            }


                            //такие сообщения мы отображаем слева, и их написал не тот кто здесь и сейчас сидит за компом
                            rolledMessages.push(<div className={"native-chat-main-message-wrapper"} key={message._id}>
                                {allSortedMessages[activeUser]?.length - 1 === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}
                                <div className={"native-chat-message-wrapper-left"}>
                                    <div
                                        className={"chat-message chat-left"}

                                        id={message._id}
                                        data-read={message.read?.includes(store.user.id) || false}
                                    >

                                        <div
                                            className={"chat-username"}>{message.userId.name + " " + message.userId.lastName}</div>
                                        {dateLook(message.date)}
                                        {message.quote
                                            ?
                                            processQuote(message)
                                            :
                                            null
                                        }
                                        {textProcessor(message.text)}
                                        {message.links?.length
                                            ?
                                            <div className={"white-back margin-top-10"}>
                                                {linkRoller(message.links)}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {store.user.id !== orderDetails.moderator && store.user.id !== orderDetails?.creator?._id && store.user.id !== orderDetails?.doer?._id && orderDetails.arbitration
                                        ?
                                        null
                                        :
                                        <img
                                            className={"private-arrow-svg arrows"}
                                            src={SvgAnswer}
                                            alt="Ответить"
                                            width={"25px"}

                                            onClick={(e) => {
                                                answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                            }}
                                        />
                                    }

                                </div>
                                {newMessagesLine
                                    ?
                                    <div>
                                        <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                                    </div>
                                    :
                                    null
                                }
                                {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                            </div>)

                        }
                        // else if (Array.isArray(message.to) && message.userId._id === store.user.id) {
                        //     return <div className={"chat-message chat-right"} key={message._id}>
                        //         {dateLook(message.date)}
                        //         {message.text}
                        //     </div>
                        // } else {
                        //     console.log(message)
                        // }


                    }
                    index++
                }
                        //const rolledMessages = allSortedMessages[activeUser].map((message, index) => {



                //это кусочек маппера
                //})
            }
            console.log("SETTING MLTRL 1")


            setMessageListToRoll(rolledMessages)

        } else if (allSortedMessages?.length) {

            console.log("SECOND CASE")

            //const rolledMessages = []

            console.log(allSortedMessages)


            const slicerCounter = computeSlicerCounter(allSortedMessages)


            const rolledMessages = allSortedMessages.slice(slicer.start, slicerCounter >  slicer.stop ? slicerCounter + 20 : slicer.stop).map((message, index) => {


                console.log(message)
                const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                //дата из прошлого сообщения для установки в разделительную линиию
                const date = new Date(lastDate)
                //обновление переменной с последней датой, именно в такой строжайшей последовательности
                lastDate = message.date
                lastUser = message.userId?._id

                newMessagesLine = false
                //это IF для первого сообщения при отправке заявки, его не должно быть видно
                if (message.text === "#####") {
                    return null
                }

                //если айдишник того кто написал данное сообщение совпадает с айдишником пользователя и..
                //адресат сообщения это активный пользователь (открытый диалог) то разворачиваем такие сообщения
                //справа в чате, это сообщения пользователя, ну то есть того человека который сидит за компом в данный момент))
                if (message.userId._id === store.user.id || (store.user.arbitr && orderDetails.arbitration && store.user.id !== orderDetails.creator._id && store.user.id !== orderDetails.doer._id && message.userId._id === orderDetails.creator._id)) {

                    if(index === 0) {
                        stopSearching = true
                    }

                    if(newMessageLineSwitch) {
                        newMessageLineSwitch = false
                        newMessagesLine = true
                        console.log("СТАВИМ ФОЛС 3")
                        if(showChat.display !== "none") {
                            newMessageLineRef.current = false
                        }
                    }


                    return <div className={"native-chat-main-message-wrapper"} key={message._id}>
                        {allSortedMessages?.length - 1 === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}
                        <div className={"native-chat-message-wrapper-right"}>
                            {store.user.id !== orderDetails.moderator && store.user.id !== orderDetails?.creator?._id && store.user.id !== orderDetails?.doer?._id && orderDetails.arbitration
                                ?
                                null
                                :
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            }
                        <div className={"chat-message chat-right"}>

                        {store.user.arbitr && orderDetails.arbitration && store.user.id !== orderDetails.creator._id
                            ?
                            <div className={"chat-username"}>{message.userId.name + " " + message.userId.lastName}</div>
                            :
                            null
                        }
                            <div className={"order-chat-date-and-read-container"}>
                                {dateLook(message.date)}
                                {/*это глаз ЗАКАЗЧИКА когда еще не назначен исполнитель*/}
                                {orderDetails.creator._id === store.user.id
                                    ?
                                    //это глаз ЗАКАЗЧИКА если есть исполнитель

                                    <img
                                        className={"dont-drag work-read-img margin-left-10"}
                                        src={message.read.includes(orderDetails.doer._id) ? SvgCheckRead : SvgCheckUnread}
                                        width={"17px"}/>

                                    :
                                    //это глаз ПОТЕНЦИАЛЬНОГО исполнителя

                                    <img
                                        className={"dont-drag work-read-img margin-left-10"}
                                        src={message.read.includes(orderDetails.creator._id) ? SvgCheckRead : SvgCheckUnread}
                                        width={"17px"}/>

                                }
                            </div>


                            {message.quote
                                ?
                                processQuote(message)
                                :
                                null
                            }

                            {textProcessor(message.text)}

                            {message.links?.length
                                ?
                                <div className={"white-back margin-top-10"}>
                                    {linkRoller(message.links)}
                                </div>
                                :
                                null
                            }
                            {/*{orderDetails.creator._id === store.user.id*/}
                            {/*    ?*/}
                            {/*    //это глаз ЗАКАЗЧИКА если есть исполнитель*/}
                            {/*    <div className={"work-read-marker margin-top-5"}>*/}
                            {/*        <img*/}
                            {/*            className={"dont-drag work-read-img"}*/}
                            {/*            src={message.read.includes(orderDetails.doer._id) ? SvgOpenedEye : SvgClosedEye}*/}
                            {/*            width={"20px"}/>*/}
                            {/*    </div>*/}
                            {/*    :*/}
                            {/*    //это глаз ПОТЕНЦИАЛЬНОГО исполнителя*/}
                            {/*    <div className={"work-read-marker margin-top-5"}>*/}
                            {/*        <img*/}
                            {/*            className={"dont-drag work-read-img"}*/}
                            {/*            src={message.read.includes(orderDetails.creator._id) ? SvgOpenedEye : SvgClosedEye}*/}
                            {/*            width={"20px"}/>*/}
                            {/*    </div>*/}
                            {/*}*/}

                    </div>

                        </div>
                        {newMessagesLine
                            ?
                            <div>
                                <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                            </div>
                            :
                            null
                        }
                        {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                    </div>


                } else if (message.isModerator) {
                    return <div className={"native-chat-main-message-wrapper"} key={message._id}>
                        <div className={"native-chat-message-wrapper-center"}>

                        <div
                        className={"chat-message chat-center"}

                        id={message._id}
                        data-read={message.read?.includes(store.user.id) || false}
                    >
                        <div className={"chat-username"}>Модератор</div>
                        {dateLook(message.date)}
                            {message.quote
                                ?
                                processQuote(message)
                                :
                                null
                            }
                        {message.text}
                        </div>
                            {store.user.id !== orderDetails.moderator && store.user.id !== orderDetails?.creator?._id && store.user.id !== orderDetails?.doer?._id && orderDetails.arbitration
                                ?
                                null
                                :
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            }

                        </div>
                        {newMessagesLine
                            ?
                            <div>
                                <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                            </div>
                            :
                            null
                        }
                        {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                    </div>

                } else if (message.userId._id !== store.user.id) {

                    if(newMessageLineSwitch && message.read?.includes(store.user.id)) {
                        newMessageLineSwitch = false
                        newMessagesLine = true
                        console.log("СТАВИМ ФОЛС 4")
                        if(showChat.display !== "none") {
                            newMessageLineRef.current = false
                        }

                    }


                    if(!stopSearching && newMessageLineRef.current && !message.read?.includes(store.user.id)) {
                        newMessageLineSwitch = true
                        stopSearching = true
                    }
                    //такие сообщения мы отображаем слева, и их написал не тот кто здесь и сейчас сидит за компом
                    return <div className={"native-chat-main-message-wrapper"} key={message._id}>
                        {allSortedMessages.slice(slicer.start, slicerCounter >  slicer.stop ? slicerCounter + 20 : slicer.stop)?.length - 1 === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}
                        <div className={"native-chat-message-wrapper-left"}>

                        <div
                        className={"chat-message chat-left"}

                        id={message._id}
                        data-read={message.read?.includes(store.user.id) || false}
                    >

                        <div className={"chat-username"}>{message.userId.name + " " + message.userId.lastName}</div>
                        {dateLook(message.date)}
                            {message.quote
                                ?
                                processQuote(message)
                                :
                                null
                            }
                            {textProcessor(message.text)}
                            {message.links?.length
                                ?
                                <div className={"white-back margin-top-10"}>
                                    {linkRoller(message.links)}
                                </div>
                                :
                                null
                            }
                    </div>
                            {store.user.id !== orderDetails.moderator && store.user.id !== orderDetails?.creator?._id && store.user.id !== orderDetails?.doer?._id && orderDetails.arbitration
                                ?
                                null
                                :
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            }
                        </div>
                        {newMessagesLine
                            ?
                            <div>
                                <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                            </div>
                            :
                            null
                        }
                        {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                    </div>

                }
                // else if (Array.isArray(message.to) && message.userId._id === store.user.id) {
                //     console.log("YES ARRAY")
                //     if(newMessageLineSwitch && message.read?.includes(store.user.id)) {
                //         newMessageLineSwitch = false
                //         newMessagesLine = true
                //     }
                //
                //
                //
                //     if(!message.read?.includes(store.user.id) && !stopSearching) {
                //         newMessageLineSwitch = true
                //         stopSearching = true
                //     }
                //
                //
                //     return <div className={"chat-message chat-right"} key={message._id}>
                //         {dateLook(message.date)}
                //         {message.text}
                //     </div>
                // } else {
                //     console.log(message)
                // }
            })
            console.log("SETTING MLTRL 2")




            setMessageListToRoll(rolledMessages)
        }







    }, [allSortedMessages, activeUser, slicer])



    useEffect(() => {
        console.log("Крутим 5")
        messagesAreaRef?.current?.scrollTo({
            top: 0,
            //behavior: 'smooth', // Прокручиваем с анимацией
        })
    }, [activeUser])





    //скрыть или показать чат
    function chatVisibility() {

        if (isAuthor) {
            try {
                document.getElementsByClassName('user-switch-button')[0].click()
            } catch {
                ;
            }

        }


        if (showChat.display === "none") {


            setIsThereUnreaded(false)


            if(props.moderation) {
                props.setIsChatVisible(true)
            }
            setShowChat({display: "block"})
            //const inp =
                //плавная прокручивалка вниз при развороте чата и фокусировка в строке ввода
                setTimeout(() => {
                    document.getElementsByClassName("chat-input")[0]?.focus()
                    window.scrollTo(0, document.body.scrollHeight);
                }, 100)

            if(messagesAreaRef.current?.querySelector('.new-message-line')) {
                //setTimeout(() => {
                    console.log("А ТЕПРЬ ПОЕХАЛИ!")
                    console.log(messagesAreaRef.current)
                    console.log(messagesAreaRef.current.querySelector('.new-message-line').offsetTop)
                    console.log("Крутим 3")
                    messagesAreaRef.current.scrollTo({
                        //top: messagesAreaRef.current.querySelector('.new-message-line').offsetTop, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                        top: messagesAreaRef.current.querySelector('.new-message-line').offsetTop - 250
                        //behavior: 'smooth', // Прокручиваем с анимацией
                    })
                //}, 1000)
            } else {
                if (
                    //окно сообщений откручено от начального положения не более чем на 300рх и текущий режим работы ИСПОЛНИТЕЛЬ
                    messagesAreaRef.current?.scrollTop < -300

                ) {

                    console.log("PIN 34")
                    console.log("Крутим 4")
                    //и крутим окошко просмотра сообщений в самый низ
                    messagesAreaRef.current.scrollTo({
                        top: messagesAreaRef.current.scrollHeight - messagesAreaRef.current.clientHeight,
                        //behavior: 'smooth', // Прокручиваем с анимацией
                    })
                }
            }


        } else {



            if(Array.isArray(allSortedMessages)) {
                for (let message of allSortedMessages) {
                    console.log(message)
                    if(message.userId._id !== store.user.id && !message.read.includes(store.user.id) && !props.orderIReadMassive.includes(message._id)) {
                        console.log("STUNT 6")
                        setIsThereUnreaded(true)
                        break;
                    }
                }
            } else {
                try {
                    for(let user in allSortedMessages) {
                        for(let message of allSortedMessages[user]) {
                            if(message.userId._id !== store.user.id && !message.read.includes(store.user.id) && !props.orderIReadMassive.includes(message._id)) {
                                console.log("STUNT 7")
                                setIsThereUnreaded(true)
                                break;
                            }
                        }
                    }
                } catch {
                    ;
                }
            }


            if(props.moderation) {
                props.setIsChatVisible(false)
            }

            setShowChat({display: "none"});
            // setTimeout(() => {
            //     window.scrollTo(0, document.body.scrollTop);
            // }, 100)
            newMessageLineRef.current = true


        }
    }


    const [offeredPrice, setOfferedPrice] = useState(0)


    async function sendRequest(price) {
        console.log(offeredPrice)
        //запрос на сервак принимает айди юзера и айди заказа
        const response = await $api.post("/send_request", {order: params.id, user: store.user.id, price: offeredPrice})

        //если ответ хороший то...
        if(response.data.message === "request accepted") {
            //запихиваем заказ в хранилище запросов mobx
            store.addRequests(orderDetails)

            //это типа нотифайка, она по прилёте заставит налету обновиться страничку на которой отображаются все заказы
            //на которые юзер кинул запрос. Это нужно для того, если вдруг она открыта в другой вкладке, то изменения сразу
            //отобразятся и там
            const update = {
                from: store.user.id,
                to: orderDetails.creator._id,
                event: 'update'
            }


            //формируем служебное сообщение, его не видно в чате, но благодаря нему активируется возможность для автора
            //писать в этот чат потенциальному исполнителю в чате заказа
            const msg = {
                isAuthor: isAuthor,
                to: [orderDetails.creator._id],
                userId: {_id: store.user.id, name: store.user.name, lastName: store.user.lastName},
                about: orderDetails._id,
                userName: {...store.user}.name + " " + {...store.user}.lastName,
                text: "Заявка на выполнение",
                date: Date.now(),
                read: [],
                event: 'message',
            }


            //формируем сообщение и постим его по адресу на сервак в БД
            await $api.post(`/chat_send/${orderDetails._id}`, msg)

            //а тут отправляем по вебсокету адресату
            //await props.notifySocket.current.send(JSON.stringify(msg));

            //а тут мы отправляем на вебсокет сервер триггер апдатера
            await props.notifySocket.current.send(JSON.stringify(update));



        }
        if(response.data.message === "absent") {

            setErrorMessage({show: true, text: "Заказ удалён, вы будете перемещены в ленту"})
            setTimeout(() => {
                navigate('/order-feed')
            }, 3000)
        }

        if(response.data.message === "request failed") {
            setErrorMessage({show: true, text: "Ошибка сервера"})
            console.log(response.data)
        }


    }





    //функция отправки запроса на выполнение заказа
    async function requestHandler() {


        // console.log(orderDetails.priceOffer)

        // if(orderDetails.priceOffer) {
            setOfferedPrice(orderDetails.price)
            setActiveSetPrice("progress")
            setTimeout(() => {
                setActiveSetPrice("active")
            }, 100)

        // } else {
        //
        //     sendRequest()
        //
        // }



    }

    //это функция для отзыва заявки, логика примерно как у вункции отправки заявки, но в обратную сторону
    async function takeBackRequest() {


        //если отозвать заявку хочет юзер, за которым закреплен заказ, то всё уже не так просто ))
        if(orderDetails.doer?._id === store.user.id) {

            console.log("Нельзя просто так взять и отменить!")
            setActiveRefuseModal("progress")
            setTimeout(() => {
                setActiveRefuseModal("active")
            }, 100)

        //если же заявка за ним не закреплена, то отзываем заявку без вопросов
        } else {


            const response = await $api.post("/take_back_request", {order: params.id, user: store.user.id})
            if(response.data.message === "request revoked") {

                //setRenewDetails(renewDetails + 1)
                await store.removeRequests(params.id)

                const update = {
                    from: store.user.id,
                    to: orderDetails.creator._id,
                    event: 'update'
                }

                await props.notifySocket.current.send(JSON.stringify(update));

            }
            if (response.data.message === "absent") {
                setErrorMessage({show: true, text: "Заказ удалён, вы будете перемещены в ленту"})
                setTimeout(() => {
                    navigate('/order-feed')
                }, 3000)
            }

            if (response.data.message === "revoke failed") {
                setErrorMessage({show: true, text: "Ошибка сервера"})
                console.log(response.data)
            }
        }
    }



    async function deleteOrder(order_id){
        console.log(`${order_id} DELETED`)
        const response = await $api.post('/order_delete', {order_id: order_id})
        if(response.status < 400) {
            console.log("Всё прошло хорошо")
            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)
        }
    }


    async function refuse(order_id){
        console.log(`${order_id} - REFUSED`)
        const response = await $api.post('/order_refuse', {order_id})
        console.log(response.data)
    }


    async function downloadHandler(path) {


        const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})

        // тут использован fetch вместо аксиоса, потому что Ulbi сказал что так проще блять
        // const response = await fetch("http://localhost:5000/download_file", {
        //     Authorization: `Bearer ${localStorage.getItem('token')}`,
        // })

        //создаем блоб (Binary Large OBject) из ответа
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        //link.download = await path.split('\\')[4]
        //link.download = path.substring(path.lastIndexOf("\\") + 1).replace(/_/g, " ")

        // Разделите строку пути с использованием '/' или '\', затем возьмите последний элемент
        const pathParts = path.split(/[\\/]/);
        const fileName = pathParts.pop();
        link.download = fileName.replace(/_/g, ' ');

        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)
    }


    async function deleteFile(path, index) {
        const response = await $api.post('/delete_file_from_order', {path: path, order_id: orderDetails._id, purpose: "extra"})
        if(response >= 400) {
            setErrorMessage({show: true, text: "Ошибка удаления файла"})
        } else {


            const newState = attachedFiles.slice(0)
            newState.splice(index, 1)
            setAttachedFiles(newState)

            const newStateNames = fileNames.slice(0)
            newStateNames.splice(index, 1)
            setFileNames(newStateNames)

        }
    }


    function mapMaterials(arrayToMap, extra) {
        let count = 0


        if(arrayToMap?.length > 0) {

            console.log(arrayToMap)



            return <div>

                {arrayToMap.map((file, index) => {

                    console.log(file)

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else {
                        type = "doc_thumb"
                    }


                    //пятерка в квадратных скобках это количество папок вложенных)))

                    const filenameWindows = file.path?.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");

                    //const filename = file.path.split('/')[5].replace(/_/g, " ")
                    let filename;

                    if (filenameWindows?.includes('/')) {
                        filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                    } else {
                        filename = filenameWindows;
                    }

                    count++
                    return<div key={file.path}>
                        {count > 1 ? <hr className={"native-blue"}/> : null}
                        <div className={"one-ready-order-container"} key={file.path}>

                            <div className={"ready-filename-container"}>

                                <img className={"order-done-file-image"} src={require(`../img/${type}.png`)} width={"50"}/>
                                <p className={"inline"}>{filename.length > 50 ? filename.slice(0, 50)+"..." : filename}</p>
                                <br/>
                                {extra
                                    ?
                                    <p className={"white-back inline-block padding-5"}> <img src={SvgPinRed} width={"20px"}/> {new Date(file.date).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</p>
                                    :
                                    null
                                }



                            </div>
                            <div>
                                {["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(file.type)
                                    ?
                                    <button
                                        className={"btn primary-btn-color order-download-btn"}
                                        onClick={() => {

                                            //тут у нас регулярка для того чтобы охватывать все слыши, и такие \ и такие /
                                            const parts = file.path.split(/[\\/]/);
                                            setFileViewPath(file.path)
                                            console.log(parts)
                                            setFileViewName(parts[parts.length - 1])
                                            setFileViewType(file.type)
                                            document.body.style.overflow = 'hidden';
                                            setActiveShowDocumentModal("progress")
                                            setTimeout(() => {
                                                setActiveShowDocumentModal("active")
                                            }, 100)
                                        }}
                                    >
                                        Посмотреть
                                    </button>
                                    :
                                    null
                                }

                                <button className={"btn primary-btn-color order-download-btn"} onClick={() => downloadHandler(file.path)}>
                                    Скачать
                                </button>
                                {getMinutesBetweenDates(new Date (file.date).getTime(), Date.now()) < 60 && orderDetails.creator._id === store.user.id
                                    ?
                                    <button className={"btn btn-danger border-radius-8 order-download-btn"} onClick={() => deleteFile(file.path, index)}>
                                        Удалить
                                    </button>
                                    :
                                    null
                                }
                            </div>



                        </div>
                    </div>
                })}
            </div>
        }

    }

    async function cancelModeration(id, decision) {
        const response = await $api.post('/cancel_moderation', {order_id: id, decision: decision, moder_id: store.user.id})

        if(response.status >= 400) {
            setErrorMessage({show: true, text: response.data.message})
        } else {
            props.setActiveOrder()
            props.removeFromStakan(id)
        }
    }

    async function closeByModer(id, decision) {

        const response = await $api.post('/close_by_moder', {order_id: id, winner: winner, decision: decision, moder_id: store.user.id})
        if(response.status >= 400) {
            setErrorMessage({show: true, text: response.data.message})
        }
    }


    async function startArbitration(id) {


        const response = await $api.post('/start_arbitration', {order_id: id, moder_id: store.user.id})
        if (response.data.message === "ALREADY") {
            setActiveArbitrInfoModal("progress")
            setTimeout(() => {
                setActiveArbitrInfoModal("active")
            }, 100)
        }
    }

    async function setArbitrAccess(id, state) {

        await $api.post('/arbitr_access', {order_id: id, state: state, moder_id: store.user.id})

    }


    async function stopArbitration(id, decision) {
        console.log(decision)
        console.log(id)
        await $api.post('/stop_arbitration', {order_id: id, decision, moder_id: store.user.id})
    }

    async function hidePrice() {
        await $api.get(`/hide_price?order_id=${orderDetails._id}`)
    }


    if(orderDoesntExist) {
        return (
        <div style={{margin: "auto"}}>
            <EmptyMessage title={"Этот заказ уже или ещё не существует"}
                          message={
                                <div className={"absent-order-empty-message"}>
                                    Вы можете поискать другие заказы в ленте -
                                    <Link to={"/order-feed"}>
                                        <button className={"btn btn-primary-poor margin-left-20"}>Лента заказов</button>
                                    </Link>
                                </div>
                            }
            />
        </div>)
    }


    return (
        wrongWay
            ?
            //если появился флаг wrong way то рисуем заглушку
            <div className={"empty-message-holder"}>
                <EmptyMessage title={"Вас на этой страничке быть не должно"}
                              message={store.errorMessage}
                />
            </div>
            :
            <div className={`${store.whitePointer.place === "Кабинет модератора:" ? "order-details-main-wrapper moderation" : "order-details-main-wrapper"}`}>
                {/*{store.whitePointer.place !== "Кабинет модератора:"*/}
                {/*    ?*/}
                {/*    <div className={"order-details-dossier-wrapper"}>*/}
                {/*        <Dossier/>*/}
                {/*    </div>*/}
                
                {/*    :*/}
                {/*    null*/}
                {/*}*/}
                {loading
                    ?
                    <Loading/>
                    :
                    <div className={"order-details-wrapper"}>
                        <div className={`${store.whitePointer.place === "Кабинет модератора:" ? "order-details-moderation" : "order-details" }`}>
                            <h2 className={"ubuntu"} style={{marginBottom: "30px"}}>Детали заказа #{orderDetails.number} {isThereUnreaded ? <span className={"red-for-blue"}>(есть сообщения)</span> : null}</h2>
                            {/*{orderDetails.orderStatus === "draft"*/}
                            {/*    ?*/}
                            {/*    <div className={'inline white'}>ЧЕРНОВИК 📝</div>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            {store.user.maintenance && orderDetails.onModeration?.status && store.user.id !== orderDetails.creator._id && store.user.id !== orderDetails.doer._id
                                ?
                                <div>
                                    <div className={'inline red-request'}>{`Спор открыл: ${orderDetails.onModeration.caller === "doer" ? "ИСПОЛНИТЕЛЬ" : "ЗАКАЗЧИК"}`}</div>
                                    <div className={"black-request margin-top-20"}>
                                        <h5 className={"ubuntu"}>Сообщение:</h5> {orderDetails.onModeration?.message.split("\n").map((item, key) => <p key={key}>{item}</p>)}</div>

                                    {orderDetails.onModeration.decision.length
                                        ?
                                        <div className={"margin-bottom-20 margin-top-20"}>
                                            <div className={"white-back padding-20"}>

                                                <h3 className={"ubuntu"}>Решени{orderDetails.onModeration.decision.length > 1 ? "я" : "е"} модератора:</h3>
                                                {orderDetails.onModeration.decision.map((decis, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {index > 0 ? <hr/> : null}
                                                            <h6 className={"inline"}>{index + 1}. </h6>

                                                            {decis.text.split("\n").map((item, key) => {
                                                                return <p className={`${key === 0 ? "inline" : "margin-for-decision"}`} key={key}>{item}</p>;
                                                            })    }
                                                            <p className={"margin-top-20"}>⏱ {new Date(decis.date).toLocaleString([], {
                                                                year: "numeric",
                                                                month: "numeric",
                                                                day: "numeric",
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            })}</p>

                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {orderDetails.arbitrDecision?.text
                                        ?
                                        <div className={"margin-bottom-20 margin-top-20"}>

                                            <div className={"white-back padding-20"}>
                                                <h3 className={"ubuntu"}>Решение арбитража:</h3>
                                                <p>{orderDetails.arbitrDecision?.text}</p>
                                                <p className={"margin-top-20"}>⏱ {new Date(orderDetails.arbitrDecision?.date).toLocaleString([], {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })}</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>

                                :
                                null
                            }


                            {!props.adminView
                                ?
                                <div className={"order-status-container"}>
                                    {orderDetails.creator?._id === store.user.id && (!orderDetails.requests.length || (orderDetails.requests.length === 1 && orderDetails.requests[0] === orderDetails.potentialDoer?._id)) || orderDetails.doer?._id === store.user.id
                                        ?
                                        orderDetails.doer
                                            ?
                                            orderDetails.doer?._id === store.user.id || orderDetails.creator?._id === store.user.id
                                                ?
                                                orderDetails.orderStatus === "closed"
                                                    ?
                                                    (orderDetails.doer?._id === store.user.id && orderDetails.reviews.doer) || (orderDetails.creator?._id === store.user.id && orderDetails.reviews.creator)
                                                        ?
                                                        <div className={'inline closed-request'}>ЗАВЕРШЁН</div>
                                                        :
                                                        <div className={'inline red-request'}>ТРЕБУЕТСЯ ОТЗЫВ</div>
                                                    :
                                                    orderDetails.orderStatus === "waiting" && !orderDetails.onModeration.status
                                                        ?
                                                        <div className={'inline red-request'}>ТРЕБУЕТСЯ ПОДТВЕРЖДЕНИЕ ЗАКРЫТИЯ</div>
                                                        :
                                                        orderDetails.refuse
                                                            ?
                                                            <div
                                                                className={'inline red-request'}>{orderDetails.onModeration.status ? "ОТКРЫТ СПОР" : "ЗАПРОШЕНА ОТМЕНА"}</div>
                                                            :
                                                            orderDetails.onModeration.status
                                                                ?
                                                                orderDetails.arbitration
                                                                    ?
                                                                    <div className={'inline red-request'}>ИДЁТ АРБИТРАЖ</div>
                                                                    :
                                                                    <div className={'inline red-request'}>ОТКРЫТ СПОР</div>
                                                                :
                                                                <div className={'inline green-request'}>ЗАКАЗ ЗАКРЕПЛЁН</div>
                                                :
                                                <div className={"inline"}>
                                                    <div className={'inline red-request'}>НАЗНАЧЕН ДРУГОЙ ИСПОЛНИТЕЛЬ</div>

                                                </div>
                                            :
                                            store.user.id === orderDetails.creator._id
                                                ?
                                                orderDetails.orderStatus === "offer"
                                                    ?
                                                    <div className={'inline blue-request'}>ОЖИДАНИЕ ОТКЛИКОВ</div>
                                                    :
                                                    orderDetails.orderStatus === "private"
                                                        ?
                                                        <div className={"inline"}>
                                                            <div className={'inline blue-request'}>ОЖИДАНИЕ ПОДТВЕРЖДЕНИЯ</div>
                                                            <p
                                                                className={"potential-doer-name inline"}
                                                            >от <Link to={`/user-details/${orderDetails.potentialDoer?._id}`}>{orderDetails.potentialDoer?.name + " " + orderDetails.potentialDoer?.lastName}</Link></p>
                                                            <img className={"avatar-mini dont-drag margin-left-20"} src={`${API_URL}/public/avatars/${orderDetails.potentialDoer?._id}/avatar.png`}/>


                                                        </div>
                                                        :
                                                        <div className={'inline black-request'}>ЧЕРНОВИК</div>
                                                :
                                                <div className={'inline blue-request'}>ЗАЯВКА ОТПРАВЛЕНА</div>
                                        :
                                        orderDetails.doer && orderDetails.doer._id !== store.user.id && orderDetails.creator._id !== store.user.id
                                            ?
                                            store.user.maintenance && orderDetails.onModeration.status
                                                ?
                                                props.moderation
                                                    ?
                                                    null
                                                    :
                                                    <div className={'inline red-request'}>ОТКРЫТ СПОР</div>
                                                :
                                                orderDetails.arbitration && store.user.arbitr
                                                    ?
                                                    <div className={"inline"}>
                                                        <div className={'inline black-request'}>ВЫ ОДИН ИЗ АРБИТРОВ ЭТОГО ЗАКАЗА</div>
                                                    </div>

                                                    :
                                                    <div className={"inline"}>
                                                        <div className={'inline red-request'}>НАЗНАЧЕН ДРУГОЙ ИСПОЛНИТЕЛЬ</div>
                                                    </div>
                                            :
                                            orderDetails.refuse
                                                ?
                                                <div className={'inline red-request'}>{
                                                    orderDetails.onModeration.status
                                                        ?
                                                        orderDetails.arbitration
                                                            ?
                                                            "ИДЁТ АРБИТРАЖ"
                                                            :
                                                            "ОТКРЫТ СПОР"
                                                        :
                                                        "ЗАПРОШЕНА ОТМЕНА"
                                                }</div>
                                                :
                                                null
                                                // <div className={'inline red-request'}>НЕПОНЯТНЫЙ СТАТУС - СООБЩИТЬ АДМИНУ</div>
                                    }

                                </div>
                                :
                                null
                            }


                            {
                                // ((orderDetails.requests?.length && orderDetails.creator._id === store.user.id && (orderDetails.doer || orderDetails.refuse))  || props.adminView) && orderDetails.orderStatus !== "private"
                                ((orderDetails.requests?.length && (orderDetails.creator?._id === store.user.id || props.adminView) ) && orderDetails.orderStatus === "offer") || (orderDetails.creator?._id === store.user.id && orderDetails.refuse) // && orderDetails.orderStatus !== "private"
                                ?
                                <RequestSpace

                                    requests={orderDetails.requests}
                                    offeredPrices={orderDetails.offeredPrices}
                                    priceOffer={orderDetails.priceOffer}
                                    order_id={order_id}
                                    orderNumber={orderDetails.number}
                                    orderTitle={orderDetails.title}
                                    doer={orderDetails.doer}
                                    deleteRequest={takeBackRequest}
                                    notifySocket={props.notifySocket}
                                    chatVisibility={chatVisibility}
                                    setActiveUser={setActiveUser}
                                    showChat={showChat}
                                    closestDate={orderDetails.closestDate}
                                    refuse={orderDetails.refuse}
                                    onModeration={orderDetails.onModeration}
                                    moderator={orderDetails.moderator}
                                    arbitration={orderDetails.arbitration}
                                    arbitrDecision={orderDetails.arbitrDecision}
                                    deadline={orderDetails.deadline}
                                    setErrorMessage={setErrorMessage}
                                    draft={orderDetails.orderStatus === "draft"}
                                    adminView={props.adminView}
                                />
                                :
                                null
                            }





                            <hr/>
                            <div className="order-top"><h4 className="order-title">"{orderDetails.title}"</h4>
                                {orderDetails.price && store.user.id !== orderDetails.creator._id
                                    ?
                                    <h4>{orderDetails.price} р. {!orderDetails.doer && orderDetails.offeredPrices && orderDetails.offeredPrices[store.user.id] ? <span className={"red-for-blue"}>({orderDetails.offeredPrices[store.user.id]} р.)</span> : null}</h4>
                                    :
                                    orderDetails.creator?._id === store.user.id
                                        ?
                                        !orderDetails.doer
                                            ?
                                            <h4 className={"red-for-blue"}>{orderDetails.price ? <span className={"text-black"}>({orderDetails.price}р.)</span> : "На оценку"}</h4>
                                            :
                                            <h4>{orderDetails.price} р.</h4>
                                        :
                                        <h4 className={"red-for-blue"}>{orderDetails.requests?.includes(store.user.id) ? orderDetails.offeredPrices[store.user.id] + " р." : "На оценку" }</h4>
                                }
                            </div>
                            {false && orderDetails.doer && orderDetails.creator._id === store.user.id && !orderDetails.safeDeal?.status && orderDetails.orderStatus === "taken"
                                ?
                                <div className={"safe-deal-btn-container"}>
                                    <button
                                        className={"btn btn-danger border-radius-8"}
                                        onClick={() => {
                                            setActiveSafeDealStart("progress")
                                            setTimeout(() => {
                                                setActiveSafeDealStart("active")
                                            }, 100)
                                        }}

                                    >Безопасная сделка</button>
                                </div>
                                :
                                null
                            }
                            {/*{orderDetails.orderStatus === "offer" && orderDetails.creator?._id === store.user.id && !orderDetails.priceOffer*/}
                            {/*    ?*/}
                            {/*    <div className={"flex-container-row"}>*/}
                            {/*        <button*/}
                            {/*            className={"btn btn-primary"}*/}
                            {/*            onClick={() => {*/}
                            {/*                setActiveHidePrice("progress")*/}
                            {/*                setTimeout(() => {*/}
                            {/*                    setActiveHidePrice("active")*/}
                            {/*                }, 100)*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            На оценку*/}
                            {/*        </button>*/}
                            {/*    </div>*/}

                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}

                            <br/>
                            <div className="order-bottom">
                                <div className="order-prefs-details">
                                    {/*<div>*/}
                                        <h5>{orderDetails.discipline ? orderDetails.discipline : null}</h5>
                                        <h6>{orderDetails.type}</h6>
                                        <h6>Срок: {orderDetails.closestDate ? "Ближайшая дата автора" : new Date(orderDetails.deadline).toLocaleDateString()}</h6>
                                        <h6>Антиплагиат: {orderDetails.antiplagiat?.need
                                            ?
                                            orderDetails.antiplagiat?.value + "% " + orderDetails.antiplagiat?.system
                                            :
                                            "не требуется"
                                        }</h6>
                                        {!props.adminView && store.user.id === orderDetails.creator?._id
                                            ?
                                            null
                                            :
                                            <h6>Заказчик: <Link
                                                    className={"agent-link"}
                                                to={`../user-details/${orderDetails.creator?._id}`}>{orderDetails.creator?.name +
                                                " " + orderDetails.creator?.lastName}</Link></h6>
                                        }

                                        {!props.adminView && (store.user.id === orderDetails.doer?._id || !orderDetails.doer)
                                            ?
                                            null
                                            :
                                            orderDetails.doer?._id
                                                ?
                                                <h6>Исполнитель: <Link
                                                    className={"agent-link"}
                                                    to={`../user-details/${orderDetails.doer?._id}`}>{orderDetails.doer?.name +
                                                    " " + orderDetails.doer?.lastName}</Link></h6>
                                                :
                                                <h6>Исполнитель: Не назначен</h6>

                                        }

                                        {/*{needLink*/}
                                        {/*    ?*/}
                                        {/*    <React.Fragment> {(link === "pending")*/}
                                        {/*        ?*/}
                                        {/*        <h6 style={{display: "inline"}}>Материалы: файл загружается на сервер, ссылка скоро*/}
                                        {/*            появится</h6>*/}
                                        {/*        :*/}
                                        {/*        <div>*/}
                                        {/*            <h6 style={{display: "inline"}}>Материалы: </h6>*/}
                                        {/*            {Array.isArray(orderDetails.reference)*/}
                                        {/*                ?*/}
                                        {/*                mapReadyFiles(orderDetails?.reference)*/}
                                        {/*                :*/}
                                        {/*            <a target="_blank" href={link}>скачать</a>*/}
                                        {/*            }*/}



                                        {/*        </div>}</React.Fragment>*/}
                                        {/*    :*/}
                                        {/*    null*/}
                                        {/*}*/}

                                        <h6 style={{marginTop: "30px"}}>Описание:</h6>
                                        <div className={"white-back order-description-container"}>
                                            <p className={"padding-10"}>{textProcessor(orderDetails.description)}</p>
                                        </div>


                                        {/*<a href="client/src/components/OrderDetails"></a>*/}
                                    {/*</div>*/}

                                </div>

                            </div>

                            {orderDetails?.reference?.length > 0
                                ?
                                <div className={"margin-top-20"}>
                                    <h6 style={{display: "inline"}}>Материалы: </h6>
                                    {mapMaterials(orderDetails?.reference)}
                                </div>
                                :
                                null
                            }

                            <hr/>
                            {orderDetails.doer
                                ?
                                <div className={"add-btn-n-label-taken-order margin-bottom-20"}>

                                    <h6>Дополнительные файлы:</h6><br/>
                                    {attachedFiles?.length > 0
                                        ?
                                        <div>
                                            {mapMaterials(attachedFiles, true)}
                                        </div>
                                        :
                                        <div className={"white-back padding-5"}>
                                            В этот блок можно добавить материалы касающиеся данного заказа, которые не были добавлены при его создании
                                        </div>
                                    }
                                    <input
                                        type={'file'}
                                        ref={inputRef}
                                        key={inputKey}
                                        multiple
                                        name={"files[]"}
                                        id={"order-files-input"}
                                        onChange={(e) => {
                                            attacheFilesHandler(e)
                                        }}/>
                                    {orderDetails.creator._id === store.user.id && !props.adminView && orderDetails.orderStatus !== "closed"  && !orderDetails.onModeration.status  && !orderDetails.arbitration
                                        ?
                                        <button
                                            className={"btn primary-btn-color margin-left-20 extra-files-add-btn"}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                document.getElementById("order-files-input").click()}}>
                                            {attachedFiles.length ? <span>Добавить <img src={SvgPinRed} width={"20px"}/></span> : <span>Прикрепить <img src={SvgPinRed} width={"20px"}/></span> }
                                        </button>
                                        :
                                        null
                                    }


                                </div>
                                :
                                null
                            }


                            {/*Если исполнителем заказа назначен текущий пользователь, или у заказа есть исполнитель,
            а пользователь является его создателем, то включаем степпер*/}
                            {orderDetails.doer?._id === store.user.id
                            || orderDetails.doer?._id && orderDetails.creator._id === store.user.id
                            || (orderDetails.arbitrAccess && store.user.arbitr)
                            || orderDetails.moderator === store.user.id
                            || (props.adminView && orderDetails.doer?._id)

                                ?
                                <Stepper
                                    doer={orderDetails.doer?._id === store.user.id}
                                    orderId={order_id}
                                    orderDetails={orderDetails}
                                    readyFiles={orderDetails.readyFiles}
                                    notifySocket={props.notifySocket}
                                    correctionFiles={orderDetails.corrections?.files}
                                    adminView={props.adminView}
                                />
                                :
                                null
                            }



                            {!reviewSuccessFullyMade && orderDetails.orderStatus === "closed"
                            && ((store.user.id === orderDetails.doer?._id && !orderDetails.reviews.doer) || (store.user.id === orderDetails.creator?._id && !orderDetails.reviews.creator))
                                ?
                                <ReviewMaker
                                    doer={orderDetails.doer?._id}
                                    creator={orderDetails.creator?._id}
                                    discipline={orderDetails.discipline}
                                    order_id={order_id}
                                    setErrorMessage={setErrorMessage}
                                    setReviewSuccesfullyMade={setReviewSuccessFullyMade}
                                />
                                :
                                orderDetails.orderStatus === "closed"
                                    ?
                                    orderDetails.onModeration.status
                                        ?
                                        <h3 className={"ubuntu"}>Спасибо за сотрудничество с нашим сервисом</h3>
                                        :
                                        <h3 className={"ubuntu"}>Спасибо за сотрудничество с нашим сервисом</h3>
                                    :
                                    null
                            }


                            {/*{store.user.maintenance && orderDetails.onModeration?.status && orderDetails.readyFiles.length && store.user.id !== orderDetails.creator._id && store.user.id !== orderDetails.doer._id*/}
                            {/*    ?*/}
                            {/*    <div>*/}
                            {/*        <hr/>*/}
                            {/*        <h3 className={"ubuntu"}>Ход работы над заказом:</h3>*/}
                            {/*        {props.mapFiles(orderDetails.readyFiles, orderDetails.corrections.files, orderDetails.step)}*/}

                            {/*    </div>*/}

                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}


                            <div className={"buttons-and-error-container"}>
                                {errorMessage.show
                                    ?
                                    <h6 className={"red"}>{errorMessage.text}</h6>
                                    :
                                    null
                                }
                                {orderDetails.orderStatus !== "closed"
                                    ?
                                    !props.adminView
                                        ?
                                        <div className="order-sub-bottom">

                                            {props.moderation
                                                ?
                                                orderDetails.moderator === store.user.id
                                                    ?
                                                    <div>
                                                        {orderDetails.arbitrDecision?.text
                                                            ?
                                                            <h3 className={"red ubuntu margin-left-20"}>Не забудьте завершить модерацию</h3>
                                                            :
                                                            null
                                                        }
                                                        {orderDetails.arbitration
                                                            ?
                                                            <button
                                                                className={"btn btn-success border-radius-8"}
                                                                title={"Остановить арбитраж"}
                                                                onClick={() => {
                                                                    setActiveArbitrationStop("progress")
                                                                    setTimeout(() => {
                                                                        setActiveArbitrationStop("active")
                                                                    }, 100)
                                                                }}
                                                            >🛑</button>
                                                            :
                                                            null
                                                        }
                                                        {orderDetails.arbitration
                                                            ?
                                                            <button
                                                                className={`btn ${orderDetails.arbitrAccess ? "primary-btn-color" : "btn-danger"} moder-decision-btn`}
                                                                onClick={() => {
                                                                    if(!orderDetails.arbitrAccess) {
                                                                        setActiveArbitrAccess("progress")
                                                                        setTimeout(() => {
                                                                            setActiveArbitrAccess("active")
                                                                        }, 100)
                                                                    } else {
                                                                        setArbitrAccess(orderDetails._id, !orderDetails.arbitrAccess)
                                                                    }

                                                                }}
                                                            >{orderDetails.arbitrAccess ? "Закрыть доступ" : "Открыть доступ"}</button>
                                                            :
                                                            <button
                                                                disabled={orderDetails.arbitrDecision?.text}
                                                                className={`btn ${orderDetails.arbitrDecision?.text ? "secondary-btn-color" : "btn-danger" } moder-decision-btn`}
                                                                onClick={() => {
                                                                    setActiveArbitrationStart("progress")
                                                                    setTimeout(() => {
                                                                        setActiveArbitrationStart("active")
                                                                    }, 100)
                                                                }}
                                                            >Начать арбитраж</button>
                                                        }

                                                        <button
                                                            disabled={orderDetails.arbitration}
                                                            className={"btn btn-danger moder-decision-btn"}
                                                            onClick={() => {
                                                                setActiveCloseByModer("progress")
                                                                setTimeout(() => {
                                                                    setActiveCloseByModer("active")
                                                                }, 100)
                                                            }}
                                                        >Закрыть заказ</button>
                                                        <button
                                                            disabled={orderDetails.arbitration}
                                                            className={"btn btn-danger moder-decision-btn"}
                                                            onClick={() => {
                                                                setActiveCancelModeration("progress")
                                                                setTimeout(() => {
                                                                    setActiveCancelModeration("active")
                                                                }, 100)
                                                            }}
                                                        >Отменить модерацию</button>
                                                        <button
                                                            className="btn primary-btn-color take-btn"
                                                            onClick={() => chatVisibility()}
                                                        >{showChat.display === "block" ? "Спрятать чат" : "Открыть чат"}</button>
                                                    </div>
                                                    :
                                                    <div>
                                                        <button
                                                            className="btn primary-btn-color take-btn"
                                                            onClick={() => chatVisibility()}
                                                        >{showChat.display === "block" ? "Спрятать чат" : "Открыть чат"}</button>
                                                    </div>

                                                :
                                                !props.adminView
                                                    ?
                                                    <button

                                                        disabled={
                                                            orderDetails.creator?._id === store.user.id
                                                            && !Object.keys(allSortedMessages)?.length
                                                            && !orderDetails.doer
                                                            && showChat.display !== "block"
                                                            ||
                                                            orderDetails.creator?._id !== store.user.id
                                                            && orderDetails.doer

                                                                ?
                                                                orderDetails.doer?._id !== store.user.id && !store.user.arbitr
                                                                :
                                                                false
                                                        }
                                                        onClick={() => chatVisibility()}
                                                        className={"btn primary-btn-color take-btn"}
                                                    >
                                                        {showChat.display === "block"
                                                            ?
                                                            "Спрятать чат"
                                                            :
                                                            orderDetails.creator?._id === store.user.id
                                                                ?
                                                                "Сообщения"
                                                                :
                                                                store.user.arbitr && orderDetails.arbitration
                                                                    ?
                                                                    "Открыть переписку"
                                                                    :
                                                                    "Задать вопросики"
                                                        }
                                                        {isThereUnreaded ? <div className={"notify-counter-red-border-3 inline margin-left-10"}>!</div> : null}
                                                    </button>
                                                    :
                                                    null
                                            }


                                            {orderDetails.creator?._id === store.user.id
                                                ?
                                                !orderDetails.doer
                                                    ?
                                                    <div>
                                                        <Link
                                                            className={"btn primary-btn-color take-btn"}
                                                            to={`/edit-order/${orderDetails._id}`}
                                                        >
                                                            Редактировать
                                                        </Link>
                                                        <button
                                                            className={"btn btn-danger take-btn"}
                                                            onClick={() => {
                                                                setActiveDeleteModal("progress")
                                                                setTimeout(() => {
                                                                    setActiveDeleteModal("active")
                                                                }, 100)
                                                            }}
                                                        >
                                                            Удалить заказ
                                                        </button>
                                                    </div>

                                                    :
                                                    null
                                                :
                                                requested && orderDetails.orderStatus !== "private"
                                                    ?
                                                    <button
                                                        disabled={orderDetails.refuse || orderDetails.onModeration.status || orderDetails.orderStatus === "waiting"}
                                                        className="btn btn-danger take-btn"
                                                        href="frontend/src/components/OrderDetails#"
                                                        onClick={(e) => takeBackRequest()}

                                                    >
                                                        {orderDetails.refuse && orderDetails.doer._id === store.user.id ? "Заявка отозвана" : "Отозвать заявку"}
                                                    </button>
                                                    :
                                                    props.moderation
                                                        ?
                                                        store.user.maintenance && !orderDetails.moderator
                                                            ?
                                                            <button

                                                                className="btn btn-danger take-btn"
                                                                href="frontend/src/components/OrderDetails#"
                                                                onClick={(e) => props.takeOrder()}>
                                                                Взять в работу
                                                            </button>
                                                            :
                                                            null
                                                        :
                                                        store.user.arbitr && orderDetails.arbitration
                                                            ?
                                                            null
                                                            :
                                                            orderDetails.potentialDoer?._id === store.user.id && !orderDetails.doer
                                                                ?
                                                                <div>
                                                                    <button
                                                                        disabled={!!orderDetails.doer}
                                                                        className={"btn take-btn primary-btn-color"}
                                                                        href="frontend/src/components/OrderDetails#"
                                                                        onClick={(e) => {
                                                                            setActiveAcceptModal("progress")
                                                                            setTimeout(() => {
                                                                                setActiveAcceptModal("active")
                                                                            }, 100)

                                                                        }}
                                                                    >
                                                                        Принять заказ
                                                                    </button>
                                                                    <button
                                                                        disabled={!!orderDetails.doer}
                                                                        className="btn btn-danger take-btn"
                                                                        href="frontend/src/components/OrderDetails#"
                                                                        onClick={(e) => {
                                                                            setActiveDeclineModal("progress")
                                                                            setTimeout(() => {
                                                                                setActiveDeclineModal("active")
                                                                            }, 100)

                                                                        }}
                                                                    >
                                                                        Отказаться
                                                                    </button>
                                                                </div>
                                                                :
                                                                !orderDetails.doer
                                                                    ?
                                                                    <button
                                                                        disabled={!!orderDetails.doer}
                                                                        className="btn primary-btn-color take-btn"
                                                                        href="frontend/src/components/OrderDetails#"
                                                                        onClick={(e) => requestHandler()}
                                                                    >
                                                                        Заявка на выполнение
                                                                    </button>
                                                                    :
                                                                    null
                                            }


                                        </div>
                                        :
                                        null
                                    :
                                    <div className="order-sub-bottom">
                                        <button
                                            onClick={() => chatVisibility()} className="btn primary-btn-color take-btn">
                                            {showChat.display === "block"
                                                ?
                                                "Спрятать чат"
                                                :
                                                "Посмотреть переписку"
                                            }
                                        </button>
                                    </div>
                                }

                            </div>

                            {props.moderation
                                ?
                                <ModerationChat
                                    messageListToRoll={props.messageListToRoll}
                                    setChatMessages={props.setMessages}
                                    chatMessagesOper={props.chatMessagesOper}
                                    setChatMessagesOper={props.setChatMessagesOper}
                                    showChat={showChat}
                                    isAuthor={isAuthor}
                                    activeUser={activeUser}
                                    setActiveUser={setActiveUser}
                                    orderDetails={orderDetails}
                                    id={order_id}
                                    notifySocket={props.notifySocket}
                                    newOrderMessage={props.newOrderMessage}
                                    doer={orderDetails.doer}
                                    setErrorMessage={setErrorMessage}
                                    navigate={navigate}
                                    moderation={props.moderation}
                                    moderatedOrder={props.moderatedOrder}
                                    messagesAreaRef={props.messageAreaModerRef}
                                    unreadOrders={props.unreadOrders}
                                    setUnreadOrders={props.setUnreadOrders}
                                    activeOrder={props.activeOrder}
                                    ordersInfo={props.ordersInfo}
                                    answeringMessage={props.answeringMessage}
                                    setAnsweringMessage={props.setAnsweringMessage}
                                    readMessagesModer={props.readMessagesModer}
                                    slicerModer={props.slicerModer}
                                    setSlicerModer={props.setSlicerModer}
                                    slicerModerRef={props.slicerModerRef}
                                    alreadyRolled={alreadyRolled}
                                    setAlreadyRolled={setAlreadyRolled}

                                />
                                :
                                !props.adminView
                                    ?
                                    <OrderChat

                                        markedElements={markedElements}
                                        isArray={Array.isArray(allSortedMessages)}
                                        chatMessagesMain={chatMessagesMain}
                                        setChatMessagesMain={setChatMessagesMain}
                                        showChat={showChat}
                                        isAuthor={isAuthor}
                                        activeUser={activeUser}
                                        setActiveUser={setActiveUser}
                                        orderDetails={orderDetails}
                                        id={order_id}
                                        notifySocket={props.notifySocket}
                                        newOrderMessage={props.newOrderMessage}
                                        doer={orderDetails.doer}
                                        creator={orderDetails.creator}
                                        setErrorMessage={setErrorMessage}
                                        navigate={navigate}
                                        alreadyRolled={alreadyRolled}
                                        setAlreadyuRolled={setAlreadyRolled}
                                        userListToRoll={userListToRoll}
                                        messageListToRoll={messageListToRoll}
                                        messagesAreaRef={messagesAreaRef}
                                        setUnreadUsers={setUnreadUsers}
                                        answeringMessage={answeringMessageNative}
                                        setAnsweringMessage={setAnsweringMessageNative}
                                        textReducer={textReducer}
                                        processMessageText={textProcessor}
                                        slicer={slicer}
                                        setSlicer={setSlicer}
                                        slicerRef={slicerRef}
                                        readMessages={readMessages}
                                        stopMakingObserver={stopMakingObserver.current}
                                        allSortedMessages={Array.isArray(allSortedMessages) ? allSortedMessages : allSortedMessages[activeUser]}
                                    />
                                    :
                                    null

                            }


                            <ModalDialog
                                active={activeDeleteModal}
                                setActive={setActiveDeleteModal}
                                action={() => deleteOrder(orderDetails._id)}
                                message={`Вы действительно хотите удалить заказ #${orderDetails.number} - "${orderDetails.title}"?`}
                                purpose={"choice"}
                            />

                            <ModalDialog
                                active={activeInfoModal}
                                setActive={setActiveInfoModal}
                                action={() => navigate('/order-feed')}
                                message={`Заказ #${orderDetails.number} - "${orderDetails.title}" удалён`}
                                purpose={"info"}
                            />

                            <ModalDialog
                                active={activeArbitrInfoModal}
                                setActive={setActiveArbitrInfoModal}
                                action={() => {}}
                                message={`В данный момент идет арбитраж по другому заказу и новый открыть нельзя. Вы можете ускорить открытый арбитраж если присоединитесь к нему.`}
                                purpose={"info"}
                            />

                            <ModalDialog
                                active={activeRefuseModal}
                                setActive={setActiveRefuseModal}
                                action={() => refuse(orderDetails._id)}
                                redMessage={`Вы собираетесь отказаться от выполнения закрепленного за вами заказа
                 #${orderDetails.number} - "${orderDetails.title}", данное действие требует подтверждения заказчика,
                  либо участия модератора, если возникла спорная ситуация`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"choice"}
                                timer={true}
                            />

                            <ModalDialog
                                active={activeAcceptModal}
                                setActive={setActiveAcceptModal}
                                action={() => acceptOrder(orderDetails._id, orderDetails.price)}
                                redMessage={`Вы собираетесь принять на исполнение заказ #${orderDetails.number} - "${orderDetails.title}"`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"choice"}
                                timer={true}
                            />

                            <ModalDialog
                                active={activeDeclineModal}
                                setActive={setActiveDeclineModal}
                                action={() => declineOrder(orderDetails._id, store.user.id)}
                                redMessage={`Вы собираетесь отказаться от приватного заказ #${orderDetails.number} - "${orderDetails.title}"`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"choice"}
                                timer={true}
                            />

                            <ModalDialog
                                active={activeCancelModeration}
                                setActive={setActiveCancelModeration}
                                action={() => cancelModeration(orderDetails._id, decision)}
                                redMessage={`Вы собераетесь отменить модерацию заказа
                 #${orderDetails.number} - "${orderDetails.title}", это вернет положение заказа на точку в которой был вызван модератор.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"moder_choice"}
                                text={true}
                                textValue={decision}
                                changeText={setDecision}
                            />

                            <ModalDialog
                                active={activeCloseByModer}
                                setActive={setActiveCloseByModer}
                                action={() => closeByModer(orderDetails._id, decision)}
                                redMessage={`Вы собераетесь закрыть заказа #${orderDetails.number} - "${orderDetails.title}", для продолжения нужно выбрать победителя спора.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"moder_choice"}
                                winner={winner}
                                setWinner={setWinner}
                                needWinner={true}
                                text={true}
                                textValue={decision}
                                changeText={setDecision}
                            />

                            <ModalDialog
                                active={activeArbitrAccess}
                                setActive={setActiveArbitrAccess}
                                action={() => setArbitrAccess(orderDetails._id, !orderDetails.arbitrAccess)}
                                redMessage={`Вы собераетесь открыть арбитрам доступ к заказу #${orderDetails.number} - "${orderDetails.title}", это значит что они смогут читать переписку заказчика, исполнителя и модератора, а так же скачивать файлы.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"choice"}
                                // winner={winner}
                                // setWinner={setWinner}
                                // needWinner={true}
                                // text={true}
                                // textValue={decision}
                                // changeText={setDecision}
                            />

                            <ModalDialog
                                active={activeArbitrationStart}
                                setActive={setActiveArbitrationStart}
                                action={() => startArbitration(orderDetails._id)}
                                redMessage={`Вы хотите инициировать арбитраж по заказу #${orderDetails.number} - "${orderDetails.title}", это можно сделать только если в данный момент не происходит арбитраж по другому заказу.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"choice"}
                                // winner={winner}
                                // setWinner={setWinner}
                                // needWinner={true}
                                // text={true}
                                // textValue={decision}
                                // changeText={setDecision}
                            />

                            <ModalDialog
                                active={activeHidePrice}
                                setActive={setActiveHidePrice}
                                action={() => hidePrice()}
                                redMessage={`Вы собираетесь разрешить исполнителям предлагать свою цену.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"choice"}
                            />



                            <ModalDialog
                                active={activeArbitrationStop}
                                setActive={setActiveArbitrationStop}
                                action={() => stopArbitration(orderDetails._id, decision)}
                                redMessage={`Вы собераетесь остановить арбитраж по заказу #${orderDetails.number} - "${orderDetails.title}", это стоит делать только если арбитраж действительно завершился и у вас есть решение, которое нужно записать ниже.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"arbitration_decision"}
                                text={true}
                                textValue={decision}
                                changeText={setDecision}
                            />

                            <ModalDialog
                                active={activeSafeDealStart}
                                setActive={setActiveSafeDealStart}
                                action={safeDealActivate}
                                redMessage={`Вы собираетесь подключить безопасную сделку для заказа #${orderDetails.number} - "${orderDetails.title}", для продолжения нужно выбрать за чей счет она будет организована.`}
                                message={`Уверены что хотите продолжить?`}
                                purpose={"safe_deal"}
                                winner={winner}
                                setWinner={setWinner}
                                needWinner={true}
                                textValue={decision}
                                changeText={setDecision}
                                price={orderDetails.price}
                            />


                            <ModalDialog
                                active={activeShowDocumentModal}
                                setActive={setActiveShowDocumentModal}
                                action={() => {
                                    setActiveShowDocumentModal("progress")
                                    setTimeout(() => {
                                        setActiveShowDocumentModal("no")
                                    }, 100)
                                }}

                                purpose={"fileviewer"}
                                filepath={fileViewPath}
                                filename={fileViewName}
                                filetype={fileViewType}

                            />


                            <ModalDialog
                                active={activeSetPrice}
                                setActive={setActiveSetPrice}
                                action={() => {
                                    sendRequest()
                                }}
                                message={"Вы можете оставить стоимость заказчика, или предложить свою"}
                                price={offeredPrice}
                                setPrice={setOfferedPrice}
                                purpose={"price-offer"}
                                timer={true}

                            />



                            <NestedMessageModal
                                active={activeMessageModal}
                                setActive={setActiveMessageModal}
                                message={nestedContent}
                                dateSet={dateLook}
                                // renderForwardedMessages={renderForwardedMessages}
                                showNestedMessage={showNestedMessage}
                                //linkRoller={linkRoller}
                                processMessageText={textProcessor}
                                lastSteps={lastSteps}
                                setLastSteps={setLastSteps}
                                stepCounter={stepCounter}
                                setStepCounter={setStepCounter}

                                workMessages={true}
                                moderator={orderDetails.moderator}
                                user_id={store.user.id}
                            />


                            <ModalDialog
                                active={activeFileDoesntExist}
                                setActive={setActiveFileDoesntExist}
                                action={() => {;}}
                                // redMessage={`Вы считаете что по заказу #`}
                                message={`Этот файл больше не существует`}
                                purpose={"info"}
                            />


                            <ModalImgViewer
                                active={activeModalImgViewer}
                                setActive={setActiveModalImgViewer}
                                action={() => {;}}
                                imgSrc={imageSource}
                                setImgSrc={setImageSource}
                            />


                        </div>
                    </div>
                }


            </div>
    );
};

export default observer(OrderDetails);