import React, {useEffect, useState, useRef} from 'react';
import $api, {API_URL} from "../../http";
import {Link} from "react-router-dom";
import {highlightTextAroundPattern} from "./repeat_functions";
import Loading from "../Loading";
import ModalDialog from "./ModalDialog";

const CheaterProcessor = ({admin, user_id}) => {


    const [searchString, setSearchString] = useState()
    const [results, setResults] = useState([])
    //const [searchTimeout, setSearchTimeout] = useState()
    const searchTimeout = useRef()
    const [searching, setSearching] = useState(false)
    const [addingCheater, setAddingCheater] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [addingServiceMessage, setAddingServiceMessage] = useState()
    const [cheaterEditing, setCheaterEditing] = useState(false)
    const [activeDeleteModal, setActiveDeleteModal] = useState("no")


    const [cheaterId, setCheaterId] = useState()
    const [name, setName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [vkLink, setVkLink] = useState()
    const [phone, setPhone] = useState()
    const [about, setAbout] = useState()


    function clearEditing() {
        setCheaterId("")
        setName("")
        setLastName("")
        setEmail("")
        setPhone("")
        setVkLink("")
        setAbout("")
        setCheaterEditing(false)
    }


    async function startSearch() {

        setSearching(true)
        const response = await $api.get(`/unreliable_user_search?search_string=${searchString.trim()}&admin_mode=${admin}&my_id=${user_id}`)
        console.log(response.data)
        setResults(response.data)
        if (!response.data[0])setErrorMessage(`По запросу "${searchString}" нет результатов`)
        setSearching(false)
    }


    useEffect(() => {



        clearTimeout(searchTimeout.current)
        if(searchString?.length === 0) {

            setErrorMessage()
            setResults([])
        }

        if(searchString && searchString.length >= 3) {

            searchTimeout.current = setTimeout(() => {
                startSearch()
            }, 1000)

        } else if (searchString && searchString.length < 3) {
            console.log("НЕ МНЕЕЕ 3")
            setErrorMessage("Не менее 3-х символов")
            setResults([])
        }

    }, [searchString])



    async function addCheater(cheater_id, name, lastName, email, vkLink, phone, about) {

        setAddingCheater(true)
        setAddingServiceMessage()
        console.log(cheater_id)
        try {

            if(name && lastName && about && (email || vkLink || phone)) {
                const response = await $api.post('/add_cheater', {
                    cheater_id, name, lastName, email, vkLink, phone, about
                })

                console.log(response.data)

                if(response.data === "OK") {
                    if(cheaterEditing) {
                        setAddingServiceMessage("Информация обновлена")
                    } else {
                        setAddingServiceMessage("Информация добавлена в базу")
                    }

                    setTimeout(() => {
                        setAddingServiceMessage()
                    }, 3000)


                    setResults(prev => {
                        return prev.map(item => {

                            if (item._id === cheater_id) {
                                return {
                                    ...item,
                                    name: name,
                                    lastName: lastName,
                                    email: email,
                                    vkLink: vkLink,
                                    phone: phone,
                                    about: about
                                };
                            }
                            return item;
                        });
                    })

                    clearEditing()



                } else {
                    setAddingServiceMessage("Ошибка сервера")
                }
            } else {
                setAddingServiceMessage("Недостаточно информации")
                setTimeout(() => {
                    setAddingServiceMessage()
                }, 3000)
            }





        } catch (e) {
            console.log(e)
            setAddingServiceMessage(e.message)
        } finally {
            setAddingCheater(false)

        }

    }


    async function deleteCheater(cheater_id) {
        const response = await $api.delete(`/cheater_delete?cheater_id=${cheater_id}`, )

        if(response.data === "OK") {

            setAddingServiceMessage("Информация удалена")


            setTimeout(() => {
                setAddingServiceMessage()
            }, 3000)


            setResults(prev => {
                return  prev.filter(item => item._id !== cheater_id)
            })

            clearEditing()



        } else {
            setAddingServiceMessage("Ошибка сервера")
        }

    }




    return (
        <div className={"cheater-processor-wrapper"}>
            <div className={"cheater-adder-container"}>
                <div className={"cheater-adder"}>
                    <input
                        className={"cheater-adder-input"}
                        placeholder={"Имя"}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                    />
                    <input
                        className={"cheater-adder-input"}
                        placeholder={"Фамилия"}
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value)
                        }}
                    />
                    <input
                        className={"cheater-adder-input"}
                        placeholder={"email"}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                    <input
                        className={"cheater-adder-input"}
                        placeholder={"ВКонтактик"}
                        value={vkLink}
                        onChange={(e) => {
                            setVkLink(e.target.value)
                        }}
                    />
                    <input
                        className={"cheater-adder-input"}
                        placeholder={"Телефон"}
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value)
                        }}
                    />
                    <textarea
                        placeholder={"Чем накосячил..."}
                        value={about}
                        onChange={(e) => {
                            setAbout(e.target.value)
                        }}
                    >

                    </textarea>

                    <div className={"cheater-add-btn-container"}>
                        {addingServiceMessage
                            ?
                            <h6 className={"ubuntu"}>{addingServiceMessage}</h6>
                            :
                            null
                        }
                        {addingCheater
                            ?
                            <img src={require('../../img/loading.gif')} height={"35px"}/>
                            :
                            null
                        }
                        {cheaterEditing
                            ?
                            <button
                                className={"btn btn-primary-poor cheater-add-btn margin-left-20"}
                                onClick={() => addCheater(cheaterId, name, lastName, email, vkLink, phone, about)}
                            >Сохранить</button>
                            :
                            <button
                                className={"btn btn-danger cheater-add-btn margin-left-20"}
                                onClick={() => addCheater(cheaterId, name, lastName, email, vkLink, phone, about)}
                            >Добавить</button>
                        }
                        <button
                            className={"btn btn-primary-poor cheater-add-btn margin-left-20"}
                            onClick={() => clearEditing()}
                        >Сброс</button>

                    </div>

                </div>
            </div>
            <div className={"cheater-search-container"}>
                <div className={"user-search-main"}>
                    <input
                        className={"user-search-query-input-unreliable"}
                        type={"text"}
                        placeholder={"Ваш запрос"}
                        value={searchString}
                        onChange={(e) => {
                            setSearchString(e.target.value)
                        }}
                    />

                </div>
                <div className={"cheater-search-results"}>
                    {searching
                        ?
                        <div className={"margin-top-20"}>
                            <Loading/>
                        </div>
                        :
                        searchString?.length && results.length
                            ?
                            results.map(user => {
                                return (
                                    <div className={"founded-cheater-tab"} key={user?.id}>
                                        <div className={"founded-cheater-tab-top"}>
                                            <img className="avatar-search" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                            <h4 className={"ubuntu"}>{user.name + `${user.lastName ? " " + user.lastName : ""}`}</h4>

                                        </div>
                                        <div className={"founded-cheater-tab-bottom"}>
                                            <div className={"unreliable-user-about bubuka"}>
                                                <span>{highlightTextAroundPattern(user.about, searchString, "orange", true)}</span>
                                                <h6 className={"margin-top-10"}>email: {user.email}</h6>
                                                <h6>телефон: {user.phone}</h6>
                                                <h6>ВК: <a target={"_blank"} href={user.vkLink}>{user.vkLink}</a></h6>
                                            </div>
                                            <div className={"cheater-manage-btn-container"}>
                                                <button
                                                    className={"btn btn-warning cheater-edit-btn"}
                                                    onClick={() => {
                                                        setCheaterId(user._id)
                                                        setName(user.name)
                                                        setLastName(user.lastName)
                                                        setEmail(user.email)
                                                        setVkLink(user.vkLink)
                                                        setPhone(user.phone)
                                                        setAbout(user.about)
                                                        setCheaterEditing(true)
                                                        window.scrollTo(0, 0)
                                                    }}
                                                >Редактировать</button>
                                                <button
                                                    className={"btn btn-danger cheater-edit-btn"}
                                                    onClick={() => {
                                                        setActiveDeleteModal("progress")
                                                        setTimeout(() => {
                                                            setActiveDeleteModal("active")
                                                        }, 100)
                                                        setCheaterId(user._id)
                                                    }}
                                                >Удалить</button>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                            :
                            <div className={"search-empty-tab"}>

                                {errorMessage
                                    ?
                                    <h5 className={"ubuntu no-margin"}>{errorMessage}</h5>
                                    :
                                    <h4 className={"ubuntu no-margin"}>Давайте найдём!</h4>
                                }
                            </div>
                    }
                </div>
            </div>
            <ModalDialog
                active={activeDeleteModal}
                setActive={setActiveDeleteModal}
                action={() => deleteCheater(cheaterId)}
                message={`Вы действительно хотите удалить информацию из базы?`}
                purpose={"choice"}
            />
        </div>
    );
};

export default CheaterProcessor;