import React, {useState, useEffect} from 'react';
import $api, {API_URL} from "../../http";
import {assignWidth} from "./repeat_functions";

const UserPicker = ({
                        userReviews,
                        setUserReviews,
                        setSelectedUser,
                        placeholder
                    }) => {


    const [userSearchString, setUserSearchString] = useState("")
    const [fullUserList, setFullUserList] = useState([])
    const [foundedUsers, setFoundedUsers] = useState([])

    const [saving, setSaving] = useState(false)
    const [asAuthor, setAsAuthor] = useState()
    const [reviewText, setReviewText] = useState("")

    const [editingReview, setEditingReview] = useState()


    async function saveReview(review_id, text, asAuthor) {

        await $api.post('/edit_review', {review_id, text, asAuthor});

        setUserReviews(prevReviews => {
            return prevReviews.map(review => {
                if (review._id === review_id) {
                    return {
                        ...review,
                        text: text,
                        asAuthor: asAuthor === "true"
                    };
                } else {
                    return review;
                }
            });
        });
        setSaving(false);
        setEditingReview()
        setReviewText()
        setAsAuthor()
    }



    useEffect(() => {
        if(saving) {
            console.log(editingReview, reviewText, asAuthor)
            saveReview(editingReview, reviewText, asAuthor)
        }
    }, [saving, editingReview, reviewText, asAuthor])


    async function getUsers() {
        const response = await $api.get('/get_users')
        console.log(response.data)
        setFullUserList(response.data)
    }


    useEffect(() => {

            getUsers()

    }, [])


    useEffect(() => {
        assignWidth("review-user-input", "review-user-list")
    }, [foundedUsers])


    useEffect(() => {
        if(userSearchString?.length > 2) {
            const searchResults = fullUserList.filter(user => {
                console.log(user.name + " " + user.lastName)
                const startString1 = user.name + " " + user.lastName
                const startString2 = user.lastName + " " + user.name
                if(startString1.toLowerCase().includes(userSearchString.toLowerCase()) || startString2.toLowerCase().includes(userSearchString.toLowerCase())) {
                    return true
                } else {
                    return false
                }
            })
            console.log(searchResults)
            setFoundedUsers(searchResults)

        } else {
            setFoundedUsers([])
        }
    }, [userSearchString])


    return (
        <div>
            <div className={"private-order-user-list"}>
                <input
                    id={"review-user-input"}
                    className={"private-order-user-input"}
                    placeholder={placeholder}
                    value={userSearchString}
                    onChange={(e) => {
                        setUserSearchString(e.target.value)
                    }}
                />
                {foundedUsers?.length
                    ?
                    <div className={"founded-user-list"} id={"review-user-list"}>
                        {/*<h5>Заглушка</h5>*/}
                        {foundedUsers.map(user => {
                            return (<div
                                className={"invocation-user-and-avatar-container"}
                                key={user.id}
                                onClick={() => {
                                    setFoundedUsers([])
                                    setUserSearchString("")
                                    setSelectedUser(user)
                                }}
                            >
                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                <p
                                    className={"invocation-user margin-0"}
                                >{user.name + " " + user.lastName}</p></div>)

                        })}
                    </div>
                    :
                    userSearchString?.length > 2
                        ?
                        <div className={"founded-user-list"} id={"review-user-list"}>
                            <h6 className={"invocation-user margin-0"}
                            >Нет совпадений</h6>
                        </div>
                        :
                        null
                }

            </div>
            {userReviews?.length
                ?
                <div className={"admin-review-editor"}>
                    {userReviews.map(review => {
                        console.log(review)
                    return (
                        <div className={"admin-review-tab"}>
                            {review._id === editingReview
                                ?
                                <textarea style={{width: "100%"}}>{review.text}</textarea>
                                :
                                <p style={{width: "100%"}}>{review.text}</p>
                            }

                            {review._id !== editingReview
                                ?
                                <button
                                    className={"btn btn-primary-poor margin-right-20"}
                                    onClick={() => {
                                        setEditingReview(review._id)
                                        setReviewText(review.text)
                                        setAsAuthor(review.asAuthor === true)
                                    }}
                                >Редактировать</button>
                                :
                                null
                            }

                            {review._id === editingReview
                                ?
                                <button
                                    className={"btn btn-primary-poor margin-right-20"}
                                    onClick={() => setSaving(true)}
                                >Сохранить</button>
                                :
                                null
                            }

                            {review._id === editingReview
                                ?
                                <select
                                    value={
                                        asAuthor && editingReview === review._id
                                            ?
                                            asAuthor
                                            :
                                            review.asAuthor === null
                                                ?
                                                false
                                                :
                                                review.asAuthor
                                }
                                    onChange={(e) => {
                                        setAsAuthor(e.target.value)
                                    }}
                                >
                                    <option value={true}>На заказчика</option>
                                    <option value={false}>На исполнителя</option>
                                </select>
                                :
                                review.asAuthor ? "На заказчика" : "На исполнителя"
                            }



                        </div>
                    )
                })}
                </div>



                :
                null
            }

        </div>
    );
};

export default UserPicker;