import React, {useEffect, useState, useContext} from 'react';
import $api from "../../http";
import {Context} from "../../index";
import ModalDialog from "./ModalDialog";
import {Link} from 'react-router-dom';



const RequestSpace = (props) => {

    const {store} = useContext(Context)

    //активатор модального окна выбора исполнителя
    const [activeAssign, setActiveAssign] = useState("no")

    const [activeCancel, setActiveCancel] = useState("no")

    const [activeConfirmCancel, setActiveConfirmCancel] = useState("no")
    const [activeModeratorInvite, setActiveModeratorInvite] = useState("no")

    const [moderatorMessage, setModeratorMessage] = useState("")


    //активатор модального информационного окна
    const [activeInfo, setActiveInfo] = useState("no")

    const [user, setUser] = useState()

    const [requestUsersInfo, setRequestUsersInfo] = useState()

    let counter = 0

    //стейты изменения даты в снятом заказе
    const [minDate, setMinDate] = useState()
    const [dateState, setDateState] = useState("0000-00-00")
    const [closestDate, setClosestDate] = useState(false)



    useEffect(() => {

        //console.log('requestUsersInfo triggered')
        async function getUsersInfo() {
            const response = await $api.post('/users_info', {ids: props.requests})
            await setRequestUsersInfo(response.data)


        }

        getUsersInfo()




    },[props.requests])


    // useEffect(() => {
    //
    // }, [requestUsersInfo])


    async function assignOrder(doer, price) {

        const response = await $api.post('/assign_order', {doer: doer, order: props.order_id, price})
        if(response.data === "OK") {

            //создаем апдейт триггер для исполнителя
            const update = {
                from: store.user.id,
                to: props.requests,
                event: 'approve'
            }

            //а тут мы его отправляем на вебсокет сервер
            await props.notifySocket.current.send(JSON.stringify(update));

            if(props.closestDate) {
                setActiveInfo("progress")
                setTimeout(() => {
                    setActiveInfo("active")
                }, 100)
            }

        }
    }



    async function cancelOrder() {

        try {
            const response = await $api.post('/cancel_order', {order_id: props.order_id, closestDate, dateState})
            console.log(response.data)
        } catch (e) {
            props.setErrorMessage({show: true, text: "Ошибка отмены заказа"})
        }






    }


    async function inviteModerator() {
        const response = await $api.post('/invite_moderator', {order_id: props.order_id, caller: "creator", message: moderatorMessage})

        if(response.data.status !== "OK") {
            props.setErrorMessage({show: true, text: "Не удалось пригласить модератора"})
            console.log(response.data)
        }



    }



    //let formattedDate
    useEffect(() => {



                   //Ниже две переменных и функция для приведения даты к формату который прожуёт инпут с датой в форме,
            // чтобы можно было установить минимальное значение даты в этой форме
            function addZero(num) {
                if (num <= 9) {
                    return '0' + num;
                } else {
                    return num;
                }
            }

            async function dateFormat() {
                let date = new Date()
                let formattedDate = date.getFullYear() + "-" + addZero(date.getMonth()+1) + "-" + addZero(date.getDate())
                setDateState(formattedDate)
                setMinDate(formattedDate)
            }

            dateFormat()

    }, [])






    return (
        <div className={"request-space-wrapper"}>
            <h4 className={"ubuntu"}>События заказа</h4><br/>

            {requestUsersInfo
                ?
                    props.doer
                        ?
                        props.refuse
                            ?
                            <div>
                                <h5 className={"inline request-user-info-line"}>
                                    {"Исполнитель " + requestUsersInfo[props.doer._id]?.name + " " + requestUsersInfo[props.doer._id]?.lastName}<span className={'red-for-blue'}> запрашивает отмену заказа</span>
                                </h5><br/>

                                {props.onModeration.status
                                    ?
                                    <div>
                                        <hr/>
                                        <h5 className={"margin-top-20"}>Вы пригласили модератора, он присоединится к чату заказа 🠗</h5>
                                    </div>

                                    :
                                    !props.adminView
                                        ?
                                        <div>
                                            <button
                                                className={"btn primary-btn-color margin-right-20 margin-top-20"}
                                                onClick={() => {
                                                        setActiveCancel("progress")
                                                        setTimeout(() => {
                                                            setActiveCancel("active")
                                                        }, 100)
                                                    }
                                                }
                                            >Подтвердить</button>
                                            <button
                                                className={"btn primary-btn-color margin-top-20"}
                                                onClick={() => {
                                                        setActiveModeratorInvite("progress")
                                                        setTimeout(() => {
                                                            setActiveModeratorInvite("active")
                                                        }, 100)
                                                    }
                                                }
                                            >Модератор</button>
                                        </div>
                                        :
                                        null
                                    }

                                {props.moderator
                                    ?
                                    <div>
                                        <hr/>
                                        <h5 className={"margin-top-20"}>Модератор присоединился к чату</h5>
                                    </div>
                                    :null
                                }
                                {props.onModeration.decision.length
                                    ?
                                    <div>
                                        <hr/>
                                        <h5 className={"margin-top-20"}>Модератор добавил решение</h5>
                                    </div>
                                    :null
                                }




                            </div>
                            :
                            <div>
                                <h5 className={"inline request-user-info-line"}>
                                    {/*{"Назначен исполнитель: " + requestUsersInfo[props.doer._id]?.name + " " + requestUsersInfo[props.doer._id]?.lastName + " ✅"}*/}
                                    {"Назначен исполнитель: " + props.doer?.name + " " + props.doer?.lastName}
                                </h5>
                                {props.onModeration.status
                                    ?
                                    <div>
                                        <hr/>
                                        {props.onModeration.caller === 'creator'
                                            ?
                                            <div>
                                                <h5 className={"inline request-user-info-line"}>
                                                    Вы пригласили модератора
                                                </h5>
                                                {props.moderator
                                                    ?
                                                    <div>
                                                        <hr/>
                                                        <h5 className={"margin-top-20"}>Модератор присоединился к чату</h5>
                                                    </div>
                                                    :null
                                                }

                                            </div>
                                            :
                                            <div>
                                                <h5 className={"inline request-user-info-line"}>
                                                    Исполнитель пригласил модератора
                                                </h5>
                                                    {props.moderator
                                                        ?
                                                        <div>
                                                            <hr/>
                                                            <h5 className={"margin-top-20"}>Модератор присоединился к чату</h5>
                                                        </div>
                                                        :null
                                                    }

                                            </div>
                                        }
                                        {props.onModeration.decision.length
                                            ?
                                            <div>
                                                <hr/>
                                                <h5 className={"margin-top-20"}>Модератор добавил решение</h5>
                                            </div>
                                            :null
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {props.arbitration
                                    ?
                                    <div>
                                        <hr/>
                                        <h5 className={"margin-top-20"}>По заказу инициирован арбитраж</h5>
                                    </div>
                                    :null
                                }
                            </div>
                        :
                        <div>
                            <h5 className={"ubuntu margin-bottom-20"}>Заявки на выполнение:</h5>
                            {props.requests.map(requester => {
                                    counter++

                                console.log(requestUsersInfo[requester])

                                    return (
                                        <div key={requester}>
                                            {counter > 1 ? <hr/> : null}
                                            <div className={"request-line"}>
                                                <h5 className={"inline request-user-info-line"}>
                                                    {counter + ". " + requestUsersInfo[requester]?.name + " " + requestUsersInfo[requester]?.lastName}
                                                    <span>{" ("}<span className={'red-for-blue'}>{props.offeredPrices[requester]}</span>{" р.) "}</span>
                                                </h5>


                                                <div>
                                                    <Link target={"_blank"} to={`../user-details/${requestUsersInfo[requester]?.id}`}><button
                                                        className={"btn primary-btn-color approve-request-btn"}

                                                    >Профиль</button></Link>

                                                    <button
                                                        disabled={props.draft || props.adminView}
                                                        className={"btn primary-btn-color approve-request-btn"}
                                                        onClick={() => {
                                                            if(!props.draft && !props.adminView) {
                                                                setActiveAssign("progress")
                                                                setTimeout(() => {
                                                                    setActiveAssign("active")
                                                                }, 100)
                                                                setUser({id: requestUsersInfo[requester].id, name: requestUsersInfo[requester].name + " " + requestUsersInfo[requester].lastName})
                                                            } else {
                                                                ;
                                                            }

                                                        }}
                                                    >Назначить</button>
                                                </div>
                                            </div>
                                        </div>)
                                })
                            }
                        </div>

                :
                null}

            <ModalDialog
                active={activeAssign}
                setActive={setActiveAssign}
                action={() => assignOrder(user?.id, props.offeredPrices[user?.id])}
                redMessage={props.closestDate
                                ? "ВНИМАНИЕ: крайний срок сдачи следует заранее " +
                                    "согласовать с исполнителем, чтобы он указал его перед выполнением."
                                : null}
                message={`Вы уверены что хотите закрепить заказ #${props.orderNumber} за автором ${user?.name}?`}
                purpose={"choice"}
                timer={true}
            />


            <ModalDialog
                active={activeModeratorInvite}
                setActive={setActiveModeratorInvite}
                action={() => inviteModerator()}
                redMessage={`Вы собираетесь пригласить модератора для решения спора по заказу #${props.orderNumber} 
                "${props.orderTitle}", это следует делать только если все способы решить вопрос друг с другом исчерпаны`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}
                text={true}
                changeText={setModeratorMessage}
                textValue={moderatorMessage}
            />


            <ModalDialog
                active={activeCancel}
                setActive={setActiveCancel}
                action={() => {
                    setActiveConfirmCancel("progress")
                    setTimeout(() => {
                        setActiveConfirmCancel("active")
                    }, 100)
                }}
                redMessage={`Вы собираетесь подтвердить отмену закрепленного за автором ${props.doer?.name + " " + props.doer?.lastName}  заказ #${props.orderNumber} "${props.orderTitle}", по его инициативе`}
                message={`Уверены что хотите продолжить?`}
                purpose={"choice"}
            />

            <ModalDialog
                active={activeConfirmCancel}
                setActive={setActiveConfirmCancel}
                action={() => cancelOrder()}
                redMessage={`Укажите новый срок выполнения:`}
                setDate={true}
                dateState={dateState}
                setDateState={setDateState}
                closestDate={closestDate}
                setClosestDate={setClosestDate}
                minDate={minDate}
                message={`Уверены что хотите продолжить?`}
                purpose={"choice"}
            />



        </div>
    );
};

export default RequestSpace;