import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import $api from "../../http";

const PasswordReset = () => {

    const params = useParams();
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const [email, setEmail] = useState();

    const [status, setStatus] = useState(false)
    const [attempt, setAttempt] = useState(false)

    const resetLink = params.link;

    useEffect(() => {


        async function checkLink(resetLink) {
            const response = await $api.get(`/check_reset_link/${resetLink}`);

            if (response.data !== "FAIL") {
                setEmail(response.data);
            }
        }

        checkLink(resetLink);
    }, []);



    async function setNewPass(e) {

        e.preventDefault()
        setAttempt(true)
        const response = await $api.post('/set_new_password', {pass: newPassword, link: resetLink})
        console.log(response)

        if(response.data === "OK") {
            setStatus(true)
        }
    }



    return (
        <div>
            {email
                ?
                attempt
                    ?
                    status
                        ?
                        <div className={"registration-form"}>
                            <h2>Новый пароль установлен</h2>
                            <Link to={"/"} className={"ubuntu btn btn-primary main-login"}>На главную</Link>
                        </div>
                        :
                        <div className={"registration-form"}>
                            <h2>Ставим новый пароль</h2>
                        </div>
                    :
                    <div className={"registration-form"}>
                        {newPassword.length === 0
                            ? <h3>Не менее 8 символов</h3>
                            : newPassword.length < 8
                                ? <h3>{`Осталось еще ${8 - newPassword.length}`}</h3>
                                : <h3>Достаточно =)</h3>
                        }

                        <input
                            className={"block personal-data-input"}
                            type={"password"}
                            placeholder={"новый пароль"}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                            value={newPassword}
                        />

                        {newPassword && newPassword.length > 7
                            ? (
                                <input
                                    className={"block personal-data-input"}
                                    type={"password"}
                                    placeholder={"ещё раз"}
                                    onChange={(e) => {
                                        setNewPasswordRepeat(e.target.value);
                                    }}
                                    value={newPasswordRepeat}
                                />
                            )
                            : null
                        }

                        {newPassword === newPasswordRepeat && newPassword !== ""
                            ? <button className="ubuntu btn btn-primary main-login" onClick={(e) => setNewPass(e)}>Хочу этот</button>
                            : newPasswordRepeat === ""
                                ? null
                                : <h3>Не совпадают =(</h3>
                        }
                    </div>


             :
                <div className={"registration-form"}><h2 className={"ubuntu"}>Нет такой ссылки</h2></div>
            }
        </div>
    );
};

export default PasswordReset;
