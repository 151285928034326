import React, {useState} from 'react';
import {textProcessor} from "./repeat_functions";

const TextExpander = ({text}) => {


    const [isExpanded, setIsExpanded] = useState(false)

    const cuttingNumber = 200

    const truncatedText = text?.length > cuttingNumber ? text.slice(0, cuttingNumber) + "..." : text

    return (
        <div>
            {isExpanded
                ?
                textProcessor(text)
                :
                textProcessor(truncatedText)
            }
            {text !== truncatedText
                ?
                <div className={"margin-top-20"}>
                    <a
                        className={"underline pointer"}
                        onClick={() => setIsExpanded(prev => !prev)}
                    >
                        {text?.length > cuttingNumber && !isExpanded
                            ? "Развернуть ↓"
                            : text?.length > cuttingNumber
                                ? "Свернуть ↑"
                                : null
                        }
                    </a>
                </div>
                :
                null
            }

        </div>
    );
};

export default TextExpander;