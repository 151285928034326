import React, {useEffect, useState, useContext} from 'react';
import {Link} from "react-router-dom";
import {Context} from "../index";
import $api, {API_URL} from "../http";
import ModalDialog from "./helpers/ModalDialog";
import BarChart from "./helpers/BarChart";


const InviteMaker = ({trigger}) => {

    const {store} = useContext(Context);
    const [codes, setCodes] = useState([])

    const [showStat, setShowStat] = useState(false)

    const [unregistered, setUnregistered] = useState(0)
    const [unfinished, setUnfinished] = useState(0)
    const [ready, setReady] = useState(0)


    useEffect(() => {
        if(codes.length) {

            let unreg = 0
            let unfin = 0
            let done = 0

            for(let code of codes) {
                if(!code.expired) {
                    unreg++
                } else if(code.user_id?.name && code.user_id?.lastName && code.user_id?.phone) {
                    done++
                } else {
                    unfin++
                }
            }

            setReady(done)
            setUnfinished(unfin)
            setUnregistered(unreg)

        }
    }, [codes])


    const [activeMakeInviteModal, setActiveMakeInviteModal] = useState("no")
    const [inviteFor, setInviteFor] = useState()

    async function make(inviteFor) {
        console.log(inviteFor)
        const code = await store.makeInvite(inviteFor)

        console.log(code)
        setCodes(prev => [code, ...prev])
        setInviteFor("")


    }

    async function get() {
        const response = await $api.get('/invite')
        console.log(response.data)
        await setCodes(response.data)


    }

    async function deleteInvite(code) {
        try {
            const response = await $api.delete(`/invite?code=${code}`)

            setCodes(prev => {
                const newCodesState = prev.filter(item => item.code !== response.data)
                return newCodesState
            })

        } catch (e) {
            console.log(e)
        }


    }

    useEffect(() => {

        get()

    }, [])


    useEffect(() => {

        if(trigger) {
            setCodes(prev => {
                const newCodesState = prev.map(item => {
                    if(item.code === trigger) {
                        item.expired = true
                    }
                    return item

                })
                return newCodesState
            })
        }



    }, [trigger])

    const [array, setArray] = useState({"first": 50, "second": 70, "third": 30, "четыр": 90, "fifth": 60})

    return (
        <div className={"invite-container"}>

            <div>
                <button
                    className={"btn btn-primary invite-btn margin-right-20"}
                    onClick={() => {
                        setShowStat(prev => !prev)
                    }}
                >Статистика</button>

                <button
                    className={"btn btn-primary invite-btn"}
                    onClick={() => {
                        setActiveMakeInviteModal("progress")
                        setTimeout(() => {
                            setActiveMakeInviteModal("active")
                        }, 100)
                    }}
                >Создать код</button>
            </div>



            {showStat
                ?
                <BarChart
                    data={{"Профиль заполнен": ready, "Профиль НЕ заполнен": unfinished, "Нет регистрации": unregistered}}
                    total={codes.length} title={"Статистика регистраций"}
                />
                :
                null
            }



            <table className="table invite-table">
                <thead>
                <tr>
                    <th scope="col">Активен</th>
                    <th scope="col">Инвайт-Код</th>
                    <th scope="col">Для</th>
                    <th scope="col">Зарегистрирован</th>
                    <th scope="col">Удалить</th>
                </tr>
                </thead>
                <tbody>

            {codes.map(code => {
                return (
                    <tr key={code.code}>
                        <td>{!code.expired ? "✔" : "❌" }</td>
                        <td>{code.code}</td>
                        <td><a target={"_blank"} href={code.issuedTo}>{code.issuedTo}</a></td>
                        <td>
                            {code.user_id && code.user_id.name
                                ?
                                <Link to={`/user-details/${code.user_id._id}`}>
                                    <img className={"ws-message-avatar dont-drag margin-right-20"} src={`${API_URL}/public/avatars/${code.user_id._id}/avatar.png`}/>
                                    {code.user_id.name + " " + code.user_id.lastName}

                                </Link>
                                :
                                code.user
                                    ?
                                    code.user
                                    :
                                    null
                            }
                        </td>
                        <td>
                            {!code.expired
                                ?
                                <button
                                    className={"btn btn-danger invite-delete-btn"}
                                    onClick={() => deleteInvite(code.code)}
                                >Удалить код</button>
                                :
                                null
                            }

                        </td>
                    </tr>
                )})
            }

                </tbody>
            </table>
            <ModalDialog
                active={activeMakeInviteModal}
                setActive={setActiveMakeInviteModal}
                action={() => make(inviteFor)}
                textValue={inviteFor}
                changeText={setInviteFor}
                message={`Для кого будем создавать код?`}
                purpose={"invite"}
            />
        </div>
    );
};

export default InviteMaker;