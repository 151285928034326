import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import {Context} from "../index";
import {API_URL} from "../http";


const OrderShield = (props) => {


    const {store} = useContext(Context)

    return (
        <div className={props.color === "green" ? "order blue-back" : "order"}>
            <div className="order-top">
                <h5 className="order-title ubuntu">{"#" + props.number + " " + `"`+props.title+`"`} </h5>
                {props.price
                    ?
                    <h4>{props.price} р.</h4>
                    :
                    <h4 className={"red"}>На оценку</h4>
                }

            </div>
            {(props.requests.length && props.requests[0] !== props.potentialDoer?._id) && props.cretorId === store.user.id
                ?
                props.doer
                    ?
                    props.status === "closed"
                        ?
                        props.reviewStatus
                            ?
                            <div className={'inline closed-request'}>ЗАВЕРШЁН</div>
                            :
                            <div className={'inline red-request'}>ТРЕБУЕТСЯ ОТЗЫВ</div>

                        :
                        props.onModeration.status
                            ?
                            props.arbitration
                                ?
                                <div className={'inline red-request'}>ИДЁТ АРБИТРАЖ</div>
                                :
                                <div className={'inline red-request'}>ОТКРЫТ СПОР</div>

                            :
                            props.refuse
                                ?
                                <div className={'inline red-request'}>
                                   {props.onModeration.status ? "ОТКРЫТ СПОР" : "ЗАПРОШЕНА ОТМЕНА" }
                                </div>
                                :
                                <div className={'green-request inline'}>
                                    НАЗНАЧЕН ИСПОЛНИТЕЛЬ
                                </div>

                    :
                    props.status === "offer"
                        ?
                        <div className={'red-request inline'}>ЕСТЬ ЗАПРОСЫ НА ВЫПОЛНЕНИЕ</div>
                        :
                        <div className={'inline black-request'}>ЧЕРНОВИК</div>
                :
                store.whitePointer.place !== "Лента заказов:"
                    ?
                    props.status === "offer"
                        ?
                        <div className={'inline blue-request'}>ОЖИДАНИЕ ОТКЛИКОВ</div>
                        :
                        props.status === "private"
                            ?
                            <div className={"agent-button-container"}>
                                <div className={'inline blue-request'}>ОЖИДАНИЕ ПОДТВЕРЖДЕНИЯ</div>
                                <p
                                    className={"potential-doer-name inline"}
                                >от </p><Link className={"btn primary-btn-color-outline"} to={`/user-details/${props.potentialDoer?._id}`}>{props.potentialDoer?.name + " " + props.potentialDoer?.lastName}</Link>
                                <img className={"avatar-mini dont-drag margin-left-20"} src={`${API_URL}/public/avatars/${props.potentialDoer?._id}/avatar.png`}/>


                            </div>
                            :
                            props.status === "taken"
                                ?
                                <div className={"green-request inline"}>
                                    НАЗНАЧЕН ИСПОЛНИТЕЛЬ
                                </div>
                                :
                                props.status === "closed"
                                    ?
                                    props.reviewStatus
                                        ?
                                        <div className={'inline closed-request'}>ЗАВЕРШЁН</div>
                                        :
                                        <div className={'inline red-request'}>ТРЕБУЕТСЯ ОТЗЫВ</div>
                                    :
                                    <div className={'inline black-request'}>ЧЕРНОВИК</div>
                    :
                    null
            }

            <br/><br/>
            <div className="order-bottom">
                <div className="order-prefs">

                    <h6>{props.type}</h6>
                    <h6>{props.discipline}</h6>
                    <h6>Срок: <span className={"normal"}>{props.date}</span></h6>
                    <h6>Антиплагиат: <span className={"normal"}>{props.antiplagiat.need ? props.antiplagiat.value + "% " + props.antiplagiat.system : "Не требуется"}</span></h6>
                    {props.marker === "my"
                        ?
                        null
                        :

                            <h6 className={""}>Заказчик:
                                <Link className={"agent-link normal"} to={`../user-details/${props.creatorId}`}>
                                    {" " + props.customer}
                                </Link>
                            </h6>



                    }
                    <h6>Дата публикации: <span className={"normal"}>{props.creationDate}</span></h6>
                    {props.doer
                        ?
                        <h6 className={"inline margin-0"}>Исполнитель:
                            <Link
                                to={`../user-details/${props.doer?._id}`}
                                className={"agent-link normal"}
                            >
                                {" " + props.doer?.name + " " + props.doer?.lastName}
                            </Link>
                        </h6>
                        :
                        null
                    }




                </div>

                <Link
                    to={"/order-details/" + props.id}
                    className={"btn primary-btn-color"}

                >{props.unreadModerMessages ? <span className={"blinking"}>{"Есть сообщения ✉"}</span> : "Подробнее..."}</Link>
            </div>


        </div>
    );
};

export default OrderShield;