import React, {useContext, useEffect, useState, useRef} from 'react';
import {Context} from "../../index";
import $api from "../../http";
import {toJS} from "mobx";
import {observer} from 'mobx-react-lite';
import SvgArrowDown from "../../img/arrow-down.svg";


//это компонент чатика для заказов
const ModerationChat = (props) => {

    const {store} = useContext(Context)

    //строка ввода
    const [chatValue, setChatValue] = useState("")

    //это лимиты отображения количества сообщений в чатике, меняются по скроллу
    const [chatLimits, setChatLimits] = useState({start: 0, stop: 100})



    //это реф-ссылка на обзёрвер который следит за появление хтмл элементов с сообщениями в области видмости
    //он нужен для того чтобы отмечать сообщения прочитанными
    const messageObserver = useRef(null);
    const observedAreaRef = useRef()

    //этот реф призван хранить элементы которые обсервер уже пронаблюдал в зоне видимости,
    //чтобы при добавлении не добавить ничего второй раз
    const markedElements = useRef(new Set())

    const textAreaRef = useRef()

    const [showArrowDown, setShowArrowDown] = useState(false)



    //функция изменения размера поля ввода текста, она срабатывает каждый раз при изменении его содержимого
    function textAreaAdjust() {
        //задаем максимальную границу высоты в пикселях
        const maxHeight = 300
        //обнуляем стили через ссылку (ref) на область ввода текста
        //этот чтобы высота уменьшилась, если текста стало меньше или если его вообще нет
        textAreaRef.current.style.height = "auto"
        //а этот чтобы скрыть бегунок если он пока не нужен
        textAreaRef.current.style.overflowY = "hidden";

        //теперь если текущая высота возможного скрола не превышает максимальный размер области
        if(textAreaRef.current.scrollHeight <= maxHeight) {
            //то увеличиваем размер области до размеров возможного скролла
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            //и прокручиваем окно
            window.scrollTo(0, document.body.scrollHeight)

            //если же возможный скролл превышает допустимую нами высоту
        } else {
            //то делаем высоту области максимально возможной
            textAreaRef.current.style.height = maxHeight + "px";
            //и разрешаем полосу прокрутки
            textAreaRef.current.style.overflowY = "auto";
        }
    }

    const handleScroll = throttle(scroller, 600)


    function scroller(e) {

        if(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 150) {
            props.setSlicerModer({start: 0, stop: props.slicerModer.stop + 50})
            props.slicerModerRef.current = e.target.scrollHeight
        }
        //а это условие обнуляет все лимиты если опуститься обратно в самый низ чата
        if(e.target.scrollTop === 0) {
            props.setSlicerModer({start: 0, stop: 50})
            props.slicerModerRef.current = 0

            if(props.slicerModer.stop > 50) {

            }

        }

        console.log(e.target.scrollTop)

        if (e.target.scrollTop < -150) {
            setShowArrowDown(true)
        }

        if (e.target.scrollTop > -10) { //&& stopDownFetchingRef.current) {
            setShowArrowDown(false)
        }


    }

    //ограничитель количества выполнений функции
    //принимает как аргументы саму функцию и задержку перед следующим выполнением в миллисекундах
    function throttle(func, delay) {
        let timerId;
        let lastExecTime = 0;

        return function (...args) {
            const currentTime = Date.now();

            if (currentTime - lastExecTime > delay) {
                func.apply(this, args);
                lastExecTime = currentTime;
            } else {
                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    func.apply(this, args);
                    lastExecTime = currentTime;
                }, delay);
            }
        };
    }



    //функция создания (ну и пересоздания) обсервара (наблюдателя) сообщений
    //для того чтобы отслеживать появление сообщения в зоне видимости и на основании этого отмечать его как прочитанное
    async function makeActualObserver(list) {

        //срабатывает только если массив поданный в функцию не является пустым
        if(list?.length) {
            console.log("MAKING OBSERVER")
            //получаем актуальную область наблюдения
            const messageArea = observedAreaRef.current

            // Создаем новый IntersectionObserver и передаем ему колбэк-функцию
            // для обработки изменений видимости элементов
            messageObserver.current = new IntersectionObserver((entries) => {
                // Проходимся по каждой записи в массиве entries
                entries.forEach(async (entry) => {
                    // Если элемент .chat-left или .chat-center находится в видимой части экрана
                    console.log("INTERSECTION WORK")

                    //console.log(entry.target.id)
                    // Если элемент наблюдаемый находится в видимой части экрана
                    // и не находится в массиве уже отработанных
                    if (entry.isIntersecting && !markedElements.current.has(entry.target.id)) {// && !markedElements.has(entry.target.id)) {
                        console.log("Вижу элемент")
                        // добавляем айди элемента в массив уже отработанных
                        markedElements.current.add(entry.target.id);

                        // Получаем элемент по айдишнику из DOM
                        const myElement = document.getElementById(entry.target.id)
                        //console.log(myElement)
                        if (myElement) {
                            //console.log("Выкидываем из наблюдения")
                            await messageObserver.current.unobserve(myElement)
                        }

                        console.log("В зоне видимости элемент - ", entry.target)
                        console.log("Его ID - ", entry.target.id)

                        //используем айдишник элемента для того чтобы отметить сообщение с соответствующим
                        //айди как прочитанное с помощью соответствующей функции
                        await markAsRead(entry.target.id)


                    } //else {
                    //console.log("НЕ вижу элемент")
                    //}
                });
            }, {
                // Передаем ссылку на родительский элемент .message-area
                root: messageArea,
                //это значение площади элемента на экране для того чтобы он считался видимым
                //в данном случае 10%
                threshold: 0.1
            });

            // Получаем все элементы .chat-left и .chat-center
            const chatMessages = document.querySelectorAll('.chat-left, .chat-right');
            //setAddFirstMessages(false)


            //фильтруем массив элементов так, чтобы не добавлять элементы которые уже были отработаны
            const newElements = Array.from(chatMessages).filter(element => {
                const isExistingElement = markedElements.current.has(element.id);
                return !isExistingElement;
            });



            // Проходимся по каждому элементу .chat-left и .chat-center
            newElements.forEach((chatMessage) => {
                //и если аттрибут 'data-read' равен false (этот аттрибут самопальный и его значение создается на основе
                // того, есть ли айди пользователя в массиве прочитавших в объекте сообщения)

                if (chatMessage.getAttribute('data-read') === "false") {
                    // Добавляем элемент в IntersectionObserver
                    messageObserver.current.observe(chatMessage);
                    //console.log(chatMessage)
                    //console.log("добавляем", chatMessage)
                    console.log("Добавляем наблюдалку")
                    console.log(chatMessage)
                }
            });
        }
    }



    //функция отмечания сообщения как прочитанного, принимает айдишник сообщения ак аргумент
    async function markAsRead(item, message_id) {

            if(item === "all") {

                const response = await $api.post('/mark_work_message_as_read',
                    {
                        user_id: store.user.id,
                        order_id: props.orderDetails._id,
                        direction: props.orderDetails.creator._id === store.user.id ? "out" : "in",
                        isModer: true,
                        all: true
                    })



                store.setWorkUnreadCounterModer(store.workUnreadCounterModer - response.data)

                props.setUnreadOrders(prev => {
                    const newArray = prev.filter(order => order !== props.activeOrder)
                    return newArray
                })

            } else {
                message_id = item


                props.readMessagesModer.current.push(message_id)

                //...то уменьшаем счетчик сообщений по ВХОДЯЩИМ заказам в store
                store.setWorkUnreadCounterModer(store.workUnreadCounterModer - 1)

                //и если окошко просмотра сообщений находится в нижнем положении или очень близко к нему...
                if(props.messagesAreaRef.current.scrollTop > -150) {

                    props.setUnreadOrders(prev => {
                        const newArray = prev.filter(order => order !== props.activeOrder)
                        return newArray
                    })
                }


                // это запрос на изменение счетчика непрочитанных сообщений в базе данных, в табличке информации о юзерах
                const response = await $api.post('/mark_work_message_as_read',
                    {
                        user_id: store.user.id,
                        message_id: message_id,
                        order_id: props.orderDetails._id,
                        direction: props.orderDetails.creator._id === store.user.id ? "out" : "in",
                        isModer: true
                    }
                )

                console.log(response.data)


            }


    }







    useEffect(() => {

        console.log(props.messageListToRoll.length)
        if(props.messageListToRoll.length) {
            makeActualObserver(props.messageListToRoll)
        }

    }, [props.messageListToRoll, props.moderatedOrder])




    useEffect(() => {

        console.log("ЭФФЕКТ КРУТИЛОКА")
        //если в DOM существует элемент .new-message-line, это линия НОВЫЕ СООБЩЕНИЯ
        //а она существует только тогда, когда они есть, и в данный момент
        if(props.messagesAreaRef.current.querySelector('.new-message-line')) {
            console.log('5 scroll')
            console.log(props.messagesAreaRef.current.querySelector('.new-message-line'))
            console.log(props.messagesAreaRef.current.querySelector('.new-message-line').offsetTop)
            //то прокручиваем область сообщений до этого элемента с небольшой поправкой в 250рх
            //setTimeout(() => {
            console.log("Крутим 1")
            props.messagesAreaRef.current.scrollTo({
                top: props.messagesAreaRef.current.querySelector('.new-message-line').offsetTop - 150, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                //behavior: 'smooth', // Прокручиваем с анимацией
            })
            //}, 1000)

            //если такого элемента нет...
        } else {
            //...а так же соблюдается ряд условий
            console.log(props.messagesAreaRef.current.scrollTop)
            console.log(props.orderDetails)
            if (
                //окно сообщений откручено от начального положения не более чем на 300рх и текущий режим работы ИСПОЛНИТЕЛЬ
                props.messagesAreaRef.current.scrollTop > -500
                && (props.newOrderMessage.userId?._id === props.activeUser || props.newOrderMessage.userId?._id === props.orderDetails.doer?._id)
                || (props.newOrderMessage.userId?._id === store.user.id && !props.alreadyRolled) || props.newOrderMessage.userId?._id === props.orderDetails.creator?._id === store.user.id

            ) {

                console.log("PIN 34")

                props.setAlreadyRolled(true)
                //и крутим окошко просмотра сообщений в самый низ
                console.log("Крутим 2")
                props.messagesAreaRef.current.scrollTo({
                    top: 0,
                    //behavior: 'smooth', // Прокручиваем с анимацией
                })
            }
        }

    }, [props.messageListToRoll, props.showChat])




    //при смене активного заказа обнуляем лимиты чатика
    useEffect(() => {
        setChatLimits({start: 0, stop: 100})

    }, [props.moderatedOrder?._id])


    //этот эффект помогает повесить слушатель события на элемент который помечен как ref
    //вот так через жопу это делается
    useEffect(() => {
        const element = props.messagesAreaRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [props.messagesAreaRef])


    useEffect(() => {
        //если чат развернут, то удаляеи уведомление об этом сообщении
        if(props.showChat.display === "block") {
            console.log("REMOVE MODER NOTIFY")
            //clearNotifications(store.user.id, props.orderDetails._id, props.orderDetails.creator._id)
            //store.removeFromModerNotifyCounter(props.newOrderMessage.about)
        }

    }, [props.newOrderMessage])


    //этот юзэффект помогает чистить лишние уведомления в случае если НЕ создатель заказа
    // скрывает/показывает чат
    useEffect(() => {

        if (props.showChat.display === "block" && !props.isAuthor) {
            console.log("Now Im Trying to CLEAR")
            //clearNotifications(store.user.id, props.orderDetails._id, props.orderDetails.creator._id)
        }

    }, [props.showChat])


    //функция отправки сообщений в этом чатике
    async function sendMessage() {


        console.log(props.answeringMessage)

        //если поле воода не пустое, то...
        if (chatValue !== "") {

            const msg = {
                isAuthor: false,
                isModerator: true,
                to: [props.orderDetails.creator._id, props.orderDetails.doer._id],
                userId: {_id: store.user.id},
                about: props.orderDetails._id,
                userName: "Модератор",
                read: [],
                text: chatValue,
                date: Date.now(),
                quote: props.answeringMessage.id ?? null,
                quote_self: props.answeringMessage.self,
                quote_date: props.answeringMessage.date,
                quote_text: props.answeringMessage.text,
                quote_userId: props.answeringMessage.sender,
                need_rolling: props.answeringMessage.quote,
                event: 'message',
            }


            //формируем сообщение и постим его по адресу на сервак в БД
            const response = await $api.post(`/chat_send/${props.orderDetails._id}`, msg)

            if (response.data.message === "OK") {

                //await props.notifySocket.current.send(JSON.stringify(msg));

                //ниже магия которая крутит окно сообщений с небольшой задержкой вниз после отправки сообщения
                const msgBox = document.getElementsByClassName("message-box")[0]

                setTimeout(() => {
                    msgBox?.scrollTo(0, 0)
                }, 100)

                //тут блок который отвечает за уведомления
                const moderNotifyToCreator = {

                    toCreator: true,
                    from: "moderator",
                    to: props.orderDetails.creator._id,
                    about: props.orderDetails._id,
                    event: 'notification',
                }

                const moderNotifyToDoer = {

                    toCreator: false,
                    from: "moderator",
                    to: props.orderDetails.doer,
                    about: props.orderDetails._id,
                    event: 'notification',
                }


                //await props.notifySocket.current.send(JSON.stringify(moderNotifyToCreator));
                //await props.notifySocket.current.send(JSON.stringify(moderNotifyToDoer));

                //очищаем поле ввода текста
                setChatValue("")
                props.setAnsweringMessage({text: null, id: null, date: null, self: null, quote: null, sender: null})
            }

            if(response.data.message === "error") {
                props.setErrorMessage({show: true, text: "Ошибка сервера"})
            }
        }
    }


    async function downArrowAction() {
        props.messagesAreaRef.current.scrollTo({top: 0, behavior: 'smooth'})
        markAsRead("all")

    }


    //это тоже компонент в компоненте, он же чатик целиком
    //я не выношу его в отдельнае компоненты потому что... не знаю, наверное потому что не хочу прокидывать пропсы
    function chat() {

            return <div className={"message-box-native-wrapper"} ref={observedAreaRef}>


                {showArrowDown
                    ?
                    <div
                        className={`fixed-arrow-moder`}
                        onClick={() => {
                            console.log("Давайте скроллить!")
                            downArrowAction()
                        }}
                    >
                        <img src={SvgArrowDown} width={"35"}/>
                    </div>
                    :
                    null
                }

                    <div className={`${props.messageListToRoll.length < 5 ? "message-box-native-empty" : "message-box-native-under"}`} ref={props.messagesAreaRef}>


                        <div className={"message-box-native"} style={{width: "100%"}}>


                        {/*если у заказа есть создатель, ну то есть если мы его уже получили (если он уже блять не undefined)...*/}

                        {props.orderDetails.creator ?
                            //то разворачиваем сообщения

                            props.messageListToRoll

                            //а если создателя мы еще не получили то ничего не разворачиваем, чтобы ошибок не было, видимо))
                            : null
                        }
                    </div>
                </div>
            </div>
    }


    function cancelAnswering() {
        console.log("SET 3")
        props.setAnsweringMessage({text: null, id: null, date: null, self: null, links: null, quote: null, sender: null})
    }

    function containsLink(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/;
        return linkRegex.test(text);
    }


    function splitWord(text, size) {
        const chunks = [];
        let container = [];
        console.log("NOW SPLITTING")
        console.log(text)

        for (let i = 0; i < text.length; i += size) {
            chunks.push(text.slice(i, i + size));
        }


        for(let chunk of chunks) {

            container.push(<span key={chunk}>{chunk}<wbr/></span>);
        }

        return container


    }


    function processMessageText(text) {

        if(text) {
            if (containsLink(text)) {
                const messageContent = text.replace(
                    /(https?:\/\/[^\s]+)/g,
                    (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
                );

                return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
            }
            //console.log(text)
            const main_array = text.split(" ")
            let decision = false

            let container = []

            for(let piece of main_array) {
                if (piece.length > 25) {
                    decision = true;
                    break
                }
            }

            if (decision) {

                const chunkSize = 20;



                //const wordChunks = splitWord(text, chunkSize);
                return splitWord(text, chunkSize);
                // for(let chunk of wordChunks) {
                //
                //     container.push(
                //         <span>{chunk}<wbr/></span>
                //     )
                // }
                //
                // return container

            } else {
                return text
            }
        } else {
            return text
        }


    }

    function textReducer(text) {
        if (text.length > 150) {
            return text.slice(0, 150) + "..."
        }
        return text
    }



    return (
        <div className={"chat"} style={props.showChat}>

            {chat()}
            {props.orderDetails.moderator === store.user.id
                ?
                <div>
                    {props.answeringMessage.text
                        ?
                        <h6 className={"margin-top-5"}>В ответ на:</h6>
                        :
                        null
                    }
                    {props.answeringMessage.text
                        ?
                        <div className={"answering-message-wrapper"}>
                            <div className={"answering-message"}>
                                {processMessageText(textReducer(props.answeringMessage.text))}
                            </div>
                            <button
                                className={"btn inline margin-left-20 cancel-answering-button"}
                                onClick={() => cancelAnswering()}
                            >❌</button>
                        </div>

                        :
                        null
                    }
                <div className={"chat-form"}>
                    <textarea className={"chat-input"} type={"text"} rows={1}
                              onKeyDown={async (key) => {

                                  if (key.code === "Enter" && key.ctrlKey) {
                                      await setChatValue(prev => prev + "\n")
                                      textAreaAdjust()
                                  }else if (key.code === "Enter" || key.code === "NumpadEnter") {
                                      sendMessage()
                                  } else {
                                      textAreaAdjust()
                                  }
                              }
                              }
                              ref={textAreaRef}
                              value={chatValue}
                              onChange={e => {
                                  if(e.target.value === "") {
                                      textAreaAdjust()
                                  }
                                  setChatValue(e.target.value)}}/>

                    <button className={"btn chat-send-btn"} onClick={sendMessage}>Сказать</button>

                </div></div>
                :
                null
            }


        </div>
    );
};

export default observer(ModerationChat);