import React, {useState, useEffect, useContext} from 'react';
import EmptyMessage from "./helpers/EmptyMessage";
import {Context} from "../index";
import {observer} from 'mobx-react-lite';
import {toJS} from "mobx";
import $api from "../http";
import {useParams, useNavigate, Link} from "react-router-dom";
import ReviewRoller  from "./helpers/ReviewRoller";
import {API_URL} from "../http";
import {formatPhoneNumber, getAgeFromDate, mapMaterials} from '../components/helpers/repeat_functions'
import ModalImgViewer from "./helpers/ModalImgViewer";



const UserDetails = () => {



    const {store} = useContext(Context)
    const navigate = useNavigate()

    //хук useParams нужен для того, чтобы получить "параметр", как написано в инструкции
    //но на самом деле динамический кусок адреса, который закодирован, в данном случае как (:id)
    //в строке <Route path="/user-details/:id" element={<UserDetails/>}/>
    //а строчка эта использована при настройке маршрутизации в компоненте MainField
    //это очень важная концепция, возможно простая для понимания, но сука очень непростая чтобы ее загуглить
    const params = useParams()
    //закидываем айдишник заказа в переменную, а ее пихаем ниже как аргумент в getDetails внутри useEffect
    const user_id = params.id


    const [userDetails, setUserDetails] = useState()
    const [reviews, setReviews] = useState([])

    const [showReviews, setShowReviews] = useState(false)
    const [showLoader, setShowLoader] =  useState(false)

    const [activeModalImgViewer, setActiveModalImgViewer] = useState("no")
    const [imageSource, setImageSource] = useState()




    async function getReviews(id) {

        const response = await $api.get(`/get_reviews/${id}`)
        const reviewData = JSON.parse(response.data)
        await setReviews(reviewData.reverse())
        window.scrollBy(0, 300); // Прокрутка на 10 пикселей вниз

        setShowLoader(false)

    }



    useEffect(() => {
        console.log("REVIEWS CHANGED")
    }, [reviews])


    useEffect(() => {


        if(showReviews && reviews.length === 0) {
            setShowLoader(true)
            // setTimeout(() => {
            getReviews(user_id)
            // }, 3000)


        }




    }, [showReviews])



    useEffect(() => {

        store.editWhitePointer({place: "Профиль участника:"})
        document.title = store.whitePointer.place


    }, [])



    useEffect(() => {


        async function getUserDetails(id) {

            const response = await $api.get(`/get_user_details_safe_dto?userId=${id}&isAdmin=${store.user.maintenance || store.user.admin}`)


            if(response.status < 400) {
                console.log(response.data)

                await setUserDetails(response.data)

            } else {
                console.log(response.status)
            }



        }


        getUserDetails(user_id)


    }, [])


    async function goToMessages() {
        //создаем в локальном хранилище переменную с данными юзера
        await store.setMessageTo({id: user_id, username: userDetails.name + " " + userDetails.lastName})

        let flag = true

        //бежим по списку открытых диалогов, список получен при входе пользователя на страничку
        for (let user of store.privateUserList) {
            //если айдишник пользователя в списке диалогов совпадает с тем юзером, с которым хотим открыть диалог...
            if(await toJS(user.id) === user_id) {
                //...то сбрасываем наш флаг
                flag = false
            }
        }

        //если флаг не сброшен, то юзера в листе нет, и его туда надо добавить
        if(flag) {
            const response = await $api.post('/add_user_to_private_list', {user_to_add: user_id, my_id: store.user.id})
            //const response = await $api.post('/add_user_to_private_list', {user_to_add: {id: user_id, username: userDetails.name + " " + userDetails.lastName}, my_id: store.user.id})
            if(response.status < 400) {
                //await store.addToPrivateUserList({id: user_id, username: userDetails.name + " " + userDetails.lastName})
                //navigate('/private_messages/?dialog=' + JSON.stringify({id: user_id, username: userDetails.name + " " + userDetails.lastName}))
            }
        } else {
            //navigate('/private_messages/?dialog=' + JSON.stringify({id: user_id, username: userDetails.name + " " + userDetails.lastName}))
        }
        navigate('/messenger')

    }



    return (
        <div className={"user-details-wrapper"}>
            <div className={"user-details-main"}>

                <div className={"user-details-top"}>

                    <div className={"user-details-top-left-half"}>
                        <div className={"avatar-n-name-container radius-10 margin-bottom-20"}>

                            <div className={"avatar-n-name-container-left"}>
                                <img className="avatar-page" src={`${API_URL}/public/avatars/${user_id}/avatar.png`}/>
                                <div>
                                <h4 className={"ubuntu"}>{userDetails?.name}</h4>
                                <h4 className={"ubuntu"}>{userDetails?.lastName}</h4>
                                <br/>
                                <button
                                    disabled={store.user.id === user_id}
                                    className={"btn primary-btn-color"}
                                    onClick={() => goToMessages()}
                                >Сообщение</button>
                                </div>
                            </div>
                            {userDetails?.untrustworthy
                                ?
                                <div className={"untrustworthy-sign"}>
                                    <h1 className={"ubuntu tip-trigger"}>⚠️</h1>
                                    <div className={"invisible-tip"}>
                                        Неблагонадежный партнёр, будьте внимательны при работе с ним
                                        и обязательно прочитайте отзывы других участников
                                    </div>
                                </div>

                                :
                                null
                            }


                        </div>
                        <div className={"private-dates-container padding-20 radius-10 margin-bottom-20 ubuntu"}>
                            {/*<h6>Рейтинг: <span className="colored-stars">★★★★★</span> <span className="rating gray-color">4.7</span></h6>*/}

                            <h6 className={"inline-block margin-right-20"}>Прошлый визит:</h6>
                            {userDetails?.online.status
                                ?
                                <h6 className={"inline-block green-color"}>Online</h6>
                                :
                                <h6 className={"inline-block gray-color"}>
                                    {new Date(userDetails?.online.lastVisit).toLocaleDateString([], {month: "long", year: "numeric", day: "numeric"})} в {new Date(userDetails?.online.lastVisit).toLocaleTimeString([], {hour: "numeric", minute: "numeric"})}
                                </h6>
                            }
                            <br/>
                            <h6 className={"inline-block margin-right-20"}>Дата регистрации:</h6>
                            <h6 className={"inline-block gray-color"}>{new Date(userDetails?.registrationDate).toLocaleDateString([], {month: "long", year: "numeric", day: "numeric"})}</h6><br/>
                            <h6 className={"inline-block margin-right-20"}>На платформе:</h6>
                            <h6 className={"inline-block gray-color"}>{getAgeFromDate(userDetails?.registrationDate)}</h6><br/>
                            <h6 className={"inline-block margin-right-20"}>ВК: </h6>
                            <a className={"inline-block"} href={userDetails?.vkLink} target={"_blank"}> <span className={"gray-color"}>{userDetails?.vkLink}</span></a>
                        </div>

                        <div className={"user-details-summary"}>

                            <div className={"inline-block padding-20 radius-10 ubuntu white-back"}>
                                <table className={"user-details-table"}>
                                    <tbody>
                                    <tr>
                                        <td><h6 className={"inline-block margin-right-20"}>Заказов в работе:</h6></td>
                                        <td><h6 className={"inline-block gray-color"}>{Array.isArray(userDetails?.incomingOrders) ? userDetails?.incomingOrders.length : userDetails?.incomingOrders}</h6></td>
                                    </tr>
                                    <tr>
                                        <td><h6 className={"inline-block margin-right-20"}>Заказов отдано:</h6></td>
                                        <td><h6 className={"inline-block  gray-color"}>{Array.isArray(userDetails?.outgoingOrders) ? userDetails?.outgoingOrders?.length : userDetails?.outgoingOrders}</h6></td>
                                    </tr>
                                    <tr>
                                        <td><h6 className={"inline-block margin-right-20"}>Всего выполнено:</h6></td>
                                        <td><h6 className={"inline-block  gray-color"}>{userDetails?.totalIncoming}</h6></td>
                                    </tr>
                                    <tr>
                                        <td><h6 className={"inline-block margin-right-20"}>Всего отдано:</h6></td>
                                        <td><h6 className={"inline-block  gray-color"}>{userDetails?.totalOutgoing + (Array.isArray(userDetails?.outgoingOrders) ? userDetails?.outgoingOrders?.length : userDetails?.outgoingOrders)}</h6></td>
                                    </tr>
                                    <tr>
                                        <td><h6 className={"inline-block margin-right-20"}>Участие в спорах:</h6></td>
                                        <td><h6 className={"inline-block  gray-color"}>{userDetails?.disputes}</h6></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>



                        </div>



                    </div>

                    <div className={"user-details-top-right-half"}>
                        <div className={"user-details-about-container radius-10 padding-20 ubuntu"}>
                            <h5>О себе:</h5>
                             {userDetails?.about?.split('\n').map((item, index) => <p key={index}>{item}</p>)}
                        </div>
                        <div className={"user-details-discipline-container radius-10 padding-20 ubuntu"}>
                            <h5>Я выполняю:</h5>
                            {userDetails?.disciplines.map((dsc, index) => <h6 key={index}>{index+1}. {dsc}</h6>)}
                        </div>
                        {store.user.admin
                            ?
                            <div className={"user-details-private-info-container padding-20 radius-10 ubuntu margin-top-20"}>
                                <h5>Личная информация:</h5>
                                <h6 className={"inline-block margin-right-20"}>Телефон: <span className={"gray-color"}>{formatPhoneNumber(userDetails?.phone)}</span></h6>
                                <h6 className={"inline-block margin-right-20"}>Почта: <span className={"gray-color"}>{userDetails?.email}</span></h6>
                                <h6 className={"inline-block margin-right-20"}>ВК: <a href={userDetails?.vkLink} target={"_blank"}> <span className={"gray-color"}>{userDetails?.vkLink}</span></a></h6>
                                <h6 className={"inline-block margin-right-20"}>Дата рождения: <span className={"gray-color"}>{new Date(userDetails?.birthDay).toLocaleDateString([], {month: "long", year: "numeric", day: "numeric"})}</span></h6>
                                <h6 className={"inline-block margin-right-20"}>Баланс: <span className={"gray-color"}>{userDetails?.balance} ₽</span></h6>
                            </div>
                            :
                            null
                        }

                    </div>
                </div>
                {userDetails?.portfolioFiles?.length
                    ?
                    <div className={"user-details-portfolio-container"}>
                        <div className={"portfolio-title-container"}>
                            <h4 className={"ubuntu"}>Портфолио</h4>
                            <hr className={"native-blue portfolio-horizont"}/>
                        </div>

                        {mapMaterials(userDetails?.portfolioFiles, false, setActiveModalImgViewer, setImageSource)}
                    </div>
                    :
                    null
                }


                <div className={"user-details-bottom"}>

                    {/*<div className={"user-details-bottom-left-half"}>*/}



                    {/*</div>*/}

                    {/*<div className={"user-details-bottom-right-half"}>*/}
                        {!showReviews
                            ?
                            <button
                                className={"btn btn-warning margin-top-20 toggle-review-btn"}
                                onClick={() => setShowReviews(true)}
                            >Показать отзывы</button>
                            :
                            <button
                                className={"btn btn-warning margin-top-20 toggle-review-btn"}
                                onClick={() => setShowReviews(false)}
                            >Скрыть отзывы</button>
                        }
                    {/*</div>*/}

                </div>

                {reviews?.length > 0 && showReviews
                    ?
                    showLoader
                        ?
                        <img src={require('../img/spinner_ring.gif')} height={"35px"} className={"margin-left-20"}/>
                        :
                        <ReviewRoller reviews={reviews} setReviews={setReviews}/>
                    :
                    showReviews
                        ?
                        showLoader
                            ?
                            <div className={"centrator-of-one"}>
                                <img src={require('../img/loading.gif')} height={"35px"} className={"margin-left-20"}/>
                            </div>

                            :
                            <div className={"centrator-of-one margin-top-20"}>
                                <EmptyMessage title={"У этого пользователя пока нет отзывов"}/>
                            </div>
                        :
                        null
                }
            </div>

            <ModalImgViewer
                active={activeModalImgViewer}
                setActive={setActiveModalImgViewer}
                // action={() => {;}}
                imgSrc={imageSource}
                setImgSrc={setImageSource}
            />

        </div>
    );
};

export default observer(UserDetails);