import React, {useState, useContext} from 'react';
import {getMinutesBetweenDates} from "./repeat_functions";
import $api, {API_URL} from "../../http";
import {Context} from "../../index";
import ModalImgViewer from "./ModalImgViewer";
import SvgPinRed from "../../img/pin-red.svg"


const FileAdderAndRoller = ({attachedFiles, setAttachedFiles, savingPath, title, noFilesDescription}) => {

    const {store} = useContext(Context)

    const [uploading, setUploading] = useState(false)
    const [inputKey, setInputKey] = useState(0)
    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})
    const [fileNames, setFileNames] = useState([])
    const [uploadProgress, setUploadProgress] = useState(0)
    const [filesId, setFilesId] = useState(null)
    const [activeModalImgViewer, setActiveModalImgViewer] = useState("no")
    const [imageSource, setImageSource] = useState()


    const maximumNameLength = 140
    const maximumFileSize = 104857600
    const maximumNumberOfFiles = 10

    async function deleteFile(path, index) {
        const response = await $api.post('/delete_any_file', {path: path, user_id: store.user.id, index})
        if(response >= 400) {
            setErrorMessage({show: true, text: "Ошибка удаления файла"})
        } else {


            const newState = attachedFiles.slice(0)
            newState.splice(index, 1)
            setAttachedFiles(newState)

            const newStateNames = fileNames.slice(0)
            newStateNames.splice(index, 1)
            setFileNames(newStateNames)

        }
    }

    async function downloadHandler(path) {


        const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})

        // тут использован fetch вместо аксиоса, потому что Ulbi сказал что так проще блять
        // const response = await fetch("http://localhost:5000/download_file", {
        //     Authorization: `Bearer ${localStorage.getItem('token')}`,
        // })

        //создаем блоб (Binary Large OBject) из ответа
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        //link.download = await path.split('\\')[4]
        //link.download = path.substring(path.lastIndexOf("\\") + 1).replace(/_/g, " ")

        // Разделите строку пути с использованием '/' или '\', затем возьмите последний элемент
        const pathParts = path.split(/[\\/]/);
        const fileName = pathParts.pop();
        link.download = fileName.replace(/_/g, ' ');

        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)
    }

    function handleFileResponse(response) {
        if (response.status < 400) {

            setAttachedFiles((prev) => [...prev, ...response.data.files]);
            const names = Array.from(response.data.files, (file) => file.name);
            setFileNames((prev) => [...prev, ...names]);


        } else {
            setErrorMessage({ show: true, text: "Не удалось загрузить файл на сервер" });
        }
    }


    async function lengthController(files){

        console.log(files)


        for (let file of files) {
            if(fileNames.includes(file.name)) {
                setErrorMessage({show: true, text: "Этот файл уже добавлен"})
                return false
            }
        }

        if(files.length > maximumNumberOfFiles) {
            setErrorMessage({
                show: true,
                text: `Максимальное количество файлов, которое можно прикрепить к заказу ${maximumNumberOfFiles}, вы 
                пытаетесь прикрепить ${files.length}, так не получится =)`})
            return false
        }


        for(let i=0; i < files.length; i++) {
            if(files[i].name.length > maximumNameLength) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет длину имени более ${maximumNameLength} символов,
                    его нужно сократить, речь идет о файле ${files[i].name}`})
                return false
            }

            if(files[i].size > 15485760) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет размер более 15 мегабайт,
                    мы не принимаем файлы, чей размер превышает это значение, речь идет о файле ${files[i].name}`})
                return false
            }

            if(files[i].type === "application/x-msdownload") {
                setErrorMessage({
                    show: true,
                    text: `Вы пытаетесь прикрепить исполняемый файл, это противоречит всему на свете, речь идет о файле ${files[i].name}`})
                return false
            }

        }

        console.log(files)

        return true
    }


    async function attacheFilesHandler(e) {
        e.preventDefault();

        setUploading(true);
        setInputKey((prevKey) => prevKey + 1);

        await setErrorMessage((prev) => ({ show: false, text: "" }));

        const filesToAttach = e.target.files;

        if (await lengthController(filesToAttach)) {
            try {


                // if (filesId) {
                //     const response = await $api.post(`${savingPath}/${filesId}`, filesToAttach, {
                //         onUploadProgress: (progressEvent) => {
                //             const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //             setUploadProgress(percentCompleted);
                //         },
                //     });
                //     handleFileResponse(response);
                // } else {
                    console.log("SENDING REQUEST")
                    const response = await $api.post(`/add_any_files?savingPath=${encodeURIComponent(savingPath)}&user_id=${store.user.id}`, filesToAttach, {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        },
                    });
                    console.log(response.data)
                    handleFileResponse(response);
                //}
            } catch (e) {
                setErrorMessage((prev) => ({ show: true, text: e.message }));
            }
        }

        setUploadProgress(0); // Сброс прогресса после завершения загрузки
        setUploading(false);
    }


    function mapMaterials(arrayToMap, showDate) {
        let count = 0

        if(arrayToMap?.length > 0) {

            console.log(arrayToMap)

            return <div>

                {arrayToMap.map((file, index) => {

                    console.log(file)

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else if (file.type === "png" || file.type === "jpg" || file.type === "jpeg") {
                        type = "image"
                    } else {
                        type = "doc_thumb"
                    }


                    //пятерка в квадратных скобках это количество папок вложенных)))

                    const filenameWindows = file.path?.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");

                    //const filename = file.path.split('/')[5].replace(/_/g, " ")
                    let filename;

                    if (filenameWindows?.includes('/')) {
                        filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                    } else {
                        filename = filenameWindows;
                    }

                    //убираем расширение
                    filename = filename.split('.').slice(0, -1).join('.');

                    count++
                    return<div key={file.path}>
                        {count > 1 ? <hr className={"native-blue"}/> : null}
                        <div className={"one-ready-order-container"} key={file.path}>

                            <div className={"users-filename-container"}>

                                {type === "image"
                                    ?
                                    <img className={"order-done-file-image"} src={API_URL + "/" + file.path} width={"50"}/>
                                    :
                                    <img className={"order-done-file-image"} src={require(`../../img/${type}.png`)} width={"50"}/>
                                }
                                <p title={filename} className={"filename-container"}>{filename}</p>
                                <br/>
                                {showDate
                                    ?
                                    <p className={"white-back inline-block padding-5"}><img src={SvgPinRed} width={"20px"}/> {new Date(file.date).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</p>
                                    :
                                    null
                                }



                            </div>
                            <div>
                                {type === "image"
                                    ?
                                    <button
                                        className={"btn primary-btn-color order-download-btn"}
                                        onClick={() => {
                                            setActiveModalImgViewer("progress")
                                            setTimeout(() => {
                                                setActiveModalImgViewer("active")
                                            }, 100)
                                            setImageSource(file.path)
                                        }}
                                    >
                                        Открыть
                                    </button>
                                    :
                                    <button className={"btn primary-btn-color order-download-btn"} onClick={() => downloadHandler(file.path)}>
                                        Скачать
                                    </button>
                                }

                                <button className={"btn btn-danger order-download-btn"} onClick={() => deleteFile(file.path, index)}>
                                    Удалить
                                </button>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        }

    }


    return (
        <div className={"file-adder-and-roller-wrapper"}>


                {title}<br/>
                {attachedFiles?.length > 0
                    ?
                    <div>
                        {mapMaterials(attachedFiles)}
                    </div>
                    :
                    <div className={"white-back padding-5 fit-content-w"}>
                        {noFilesDescription}
                    </div>
                }
                <input
                    type={'file'}
                    // ref={inputRef}
                    // key={inputKey}
                    multiple
                    name={"files[]"}
                    id={"portfolio-files-input"}
                    onChange={(e) => {
                        attacheFilesHandler(e)
                    }}/>

                    <button
                        className={"btn primary-btn-color margin-top-20"}
                        onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("portfolio-files-input").click()}}>
                        {attachedFiles.length ? <span>Добавить <img src={SvgPinRed} width={"20px"}/></span> : <span>Прикрепить <img src={SvgPinRed} width={"20px"}/></span> }
                    </button>
            {errorMessage.show
                ?
                <p className={"red"}>{errorMessage.text}</p>
                :
                null
            }
            {uploading && (
                <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                    <span className="progress-text">{uploadProgress}% загружено</span>
                </div>
            )}

            <ModalImgViewer
                active={activeModalImgViewer}
                setActive={setActiveModalImgViewer}
                // action={() => {;}}
                imgSrc={imageSource}
                setImgSrc={setImageSource}
            />



        </div>
    );
};

export default FileAdderAndRoller;