import React, {useState, useRef, useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import {Context} from "../index";
import $api, {API_URL} from "../http";
import Dossier from "./Dossier";
import {assignWidth} from "../components/helpers/repeat_functions"
import SvgPinRed from "../img/pin-red.svg";




//Это компонент формы для создания нового заказа
const NewOrder = ({editing, selectedValue}) => {
    //десятый раз повторяю, это переменная стор, в которой хранится куча всякой важной херни
    //доступной любому элементу, который имеет доступ к контексту
    //который открывается вот этой строкой
    const {store} = useContext(Context)


    // хук useNavigate позволяет делаеть редирект
    //тут мы создаем его, чтобы потом можно было обращаться к нему как к переменной
    const navigate = useNavigate()

    //этот стейт меняется на true после успешного сабмита формы
    const [isOrderPlaced, setOrderPlaced] = useState(false)
    //а этот стейт следит за сообщениями об успехе либо ошибке отправки формы на сервер
    const [status, setStatus] = useState({color: "", message: ""})
    const [orderStatus, setOrderStatus] = useState("offer")

    //сюда грузим дисциплины из главного списка
    const [disciplines, setDisciplines] = useState([])

    const [classifier, setClassifier] = useState()
    const [classifierNew, setClassifierNew] = useState()
    const [section, setSection] = useState()
    const [science, setScience] = useState()
    const [discipline, setDiscipline] = useState()

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDisciplines, setFilteredDisciplines] = useState([]);
    const selectRef = useRef(null);
    const selectDefaultRef = useRef(null)

    const timerRef = useRef()

    const [update, setUpdate] = useState(0)


    useEffect(() => {

        clearTimeout(timerRef.current)

        if(searchTerm) {
            //setDisString2("")
            //setDisString1("")
            //setSection(null)
            //setScience(null)
            //setDiscipline()
        }

        clearErrors('science')
        clearErrors('section')
        clearErrors('discipline')
        clearErrors('foundedDiscipline')

        if(searchTerm.length) {
            const intermediateResults = classifierNew.flatMap(section =>
                section.section.flatMap(science =>
                    science.science.filter(discipline =>
                        discipline.discipline.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                )
            );
            timerRef.current = setTimeout(() => {
                if(intermediateResults.length) {
                    console.log(intermediateResults)
                    setFilteredDisciplines(intermediateResults)
                } else {
                    setFilteredDisciplines([{discipline: 'Нет совпадений'}])
                }

            }, 1000)

        } else {
            //эта апдатилка нужна чтобы обновлять внешний вид классификатора, а то он почему то не успевает сам
            setTimeout(() => {
                setUpdate(prev => prev + 1)
            }, 100)

            setFilteredDisciplines([])
        }
    }, [searchTerm])

    useEffect(() => {

        //const selectElement = selectRef.current; // Получаем элемент <select> из рефа
        //const firstOptionValue = selectElement?.options[0]?.value


        if (selectRef.current) {
            selectRef.current.size = Math.min(selectRef.current.length > 1 ? selectRef.current.length : 2 , 10); // Максимально 10 строк
            selectRef.current.style.overflow = 'auto'; // Восстанавливаем скроллбар
        }



        if(searchTerm === "") {
            const defSel = document.getElementById("default-select")
            if(defSel) {
                defSel.size = 1;
            }
        }
    }, [filteredDisciplines, searchTerm]);





    const [price, setPrice] = useState()

    const params = useParams()
    //закидываем айдишник заказа в переменную, а ее пихаем ниже как аргумент в getDetails внутри useEffect
    const order_id = params.id


    const [orderTitle, setOrderTitle] = useState()
    const [workType, setWorkType] = useState()
    const [apSystem, setApSystem] = useState()
    const [apValue, setApValue] = useState()
    const [deadline, setDeadLine] = useState()
    const [description, setDescription] = useState()
    const orderId = useRef()
    const markedToDelete = useRef([])


    useEffect(() => {
        if(editing) {
            const handleBeforeUnload = (event) => {
                event.preventDefault(); // Отменяем закрытие страницы
                // Устанавливаем текст сообщения
                event.returnValue = 'Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны.';
            };

            // Добавляем обработчик события beforeunload
            window.addEventListener('beforeunload', handleBeforeUnload);

            // Убираем обработчик при размонтировании компонента
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }

    }, [])




    useEffect(() => {


        if(editing) {
            store.editWhitePointer({place: "Редактирование заказа:"})
        } else {
            store.editWhitePointer({place: "Создание заказа:"})
        }


        async function get() {
            const response = await $api.get('/get_disciplines')
            setDisciplines(response.data)
        }

        get()

        async function loadClassifier() {
            const response = await $api.get('/get_classifier')
            console.log(response.data)
            setClassifier(response.data.old)
            setClassifierNew(response.data.new)
            //const classifierTmp = response.data.old

            //console.log(Object.keys(classifierTmp))


        }

        loadClassifier()




    }, []);



    async function getOrderDetails() {
        const response = await $api.get(`/order-details/${order_id}/${store.user.id}`)

        console.log(response.data)
        setDisString1(response.data.discipline.split(',')[0])
        setValue('section', response.data.discipline.split(',')[0]);

        const orderDetails = response.data

        if(orderDetails.doer?._id || orderDetails.creator?._id !== store.user.id) {
            navigate(`/order-details/${orderDetails._id}`)
        }

        orderId.current = orderDetails._id
        setOrderTitle(orderDetails.title)
        setWorkType(orderDetails.type)
        console.log(orderDetails.orderStatus === "draft")
        setOrderStatus(orderDetails.orderStatus)
        setApChoice(Boolean(orderDetails.antiplagiat.need))
        setApSystem(orderDetails.antiplagiat.system)
        setApValue(orderDetails.antiplagiat.value)
        if(Number(orderDetails.price) > 0) {
            setPrice(orderDetails.price)
        }

        setDescription(orderDetails.description)
        setAttachedFiles(orderDetails.reference)
        setDisciplineString(orderDetails.discipline)
        setPriceOffer(orderDetails.priceOffer)
        console.log("Setting string - ", orderDetails.discipline)

        if(orderDetails.potentialDoer?._id) {
            setPotentialDoer({
                _id: orderDetails.potentialDoer?._id,
                title: orderDetails.potentialDoer?.name + " " + orderDetails.potentialDoer?.lastName
            })
        }





        setClosestDate(Boolean(orderDetails.closestDate))

        if(!Boolean(orderDetails.closestDate)) {
            const dateString = orderDetails.deadline;

            // Создаем объект даты
            const date = new Date(dateString);

            // Извлекаем год, месяц и день
            const year = date.getFullYear();
            // Для месяца добавляем 1, так как в JavaScript месяцы начинаются с 0
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Добавляем ведущий ноль, если месяц < 10
            const day = date.getDate().toString().padStart(2, '0'); // Добавляем ведущий ноль, если день < 10

            // Формируем строку в нужном формате
            const formatDate = `${year}-${month}-${day}`;

            setDeadLine(formatDate)
        }


        console.log("Тут начинается поиск классификатора")
        console.log(classifierNew)
        const selectedClassifier = classifierNew.find(item => {

            console.log("Ищем классификацию")
            // Проверяем, есть ли у объекта свойство _id и равно ли оно orderDetails.classifier_id
            if (item._id === orderDetails.classifier_id) {
                console.log("Нашли классификацию")
                return true;
            } else {
                console.log("Ищем классификацию на вложенных уровнях")
                // Если у объекта нет свойства _id, пробуем найти его на вложенных уровнях
                if (item.section) {
                    return item.section.find(sec => {
                        if (sec.science) {
                            console.log("Нашли классификацию 2")
                            return sec.science.find(sci => sci._id === orderDetails.classifier_id);
                        }
                    });
                }
                console.log("Нихуя не нашли")
            }
        });

        console.log(selectedClassifier)


        if (selectedClassifier) {
            // Устанавливаем значение раздела
            setSection(selectedClassifier.section);
            console.log(selectedClassifier.section)

            console.log(selectedClassifier.section)

            // Устанавливаем значение области
            const selectedSection = selectedClassifier.section.find(sec => sec.science.some(sci => sci._id === orderDetails.classifier_id));
            console.log(selectedSection)
            console.log("SETTING SCIENCE 1")
            setScience(selectedSection ? selectedSection.science : null);
            setDisString2(selectedSection.science_name)
            setDisString1(selectedClassifier.section_name)
            console.log(selectedSection.section_name)
            if(selectedSection.empty_massive) {
                setEmptyMassive(true)
            }
            console.log(selectedSection)



            // Устанавливаем значение дисциплины
            const selectedScience = selectedSection ? selectedSection.science.find(sci => sci._id === orderDetails.classifier_id) : null;
            setDiscipline(selectedScience ? selectedScience.discipline : null);
            //setDisString2(selectedScience.discipline)
            console.log(selectedScience.discipline)

            console.log(selectedScience)


            setClassifierId(selectedScience._id)




        }


    }



    useEffect(() => {
        if(editing && classifierNew?.length) {
            console.log(classifierNew)
            getOrderDetails()
        }
    }, [editing, classifierNew])


    //антиплагиат требуется или нет, по умолчанию нет
    const [apChoice, setApChoice] = useState(false)

    //ближайшай дата автора
    const [closestDate, setClosestDate] = useState(false)
    const [priceOffer, setPriceOffer] = useState(false)

    const [disableSend, setDisableSend] = useState(false)

    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})

    const [attachedFiles, setAttachedFiles] = useState([])
    const [fileNames, setFileNames] = useState([])

    const [filesId, setFilesId] = useState(null)


    const inputRef = useRef(null)

    const [file, setFile] = useState(''); // storing the uploaded file
    const [progress, setProgress] = useState(0); // progress bar
    const el = useRef(); // accessing input element



    function priceHandler(number) {
        console.log(number);
        console.log(typeof number);

        if(number > 500000 || number < 0) {
            return
        }

        // Убираем все символы, не являющиеся цифрой, +, -, . или e
        const sanitizedValue = number.replace(/[^\d+\-.e]/g, '');

        // Убираем ведущий ноль, если он есть
        const preparedValue = sanitizedValue.replace(/^0+/, '');

        // Ограничиваем количество знаков после запятой до двух
        const decimalIndex = preparedValue.indexOf('.');
        if (decimalIndex !== -1) {
            const integerPart = preparedValue.slice(0, decimalIndex);
            const decimalPart = preparedValue.slice(decimalIndex + 1, decimalIndex + 3);
            const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

            // Если десятичная часть начинается с точки, заменяем её на "0."
            // Если десятичная часть начинается с нуля, заменяем ведущий ноль на "0."
            setPrice(formattedValue.replace(/^\./, '0.'));
        } else {
            // Если целая часть начинается с нуля, заменяем ведущий ноль на "0"
            setPrice(preparedValue.replace(/^0+/, '0'));
        }
    }

    //реагировалка на указание файла в форме
    const handleChange = async (event) => {
        setProgress(0)
        event.preventDefault();
        if (event.target.files[0]) {
            await setFile(event.target.files[0]);
            setStatus({color: 'purple', message: "Файл загружается, не закрывайте страницу"})

        }
    };


    const [userListForPrivate, setUserListForPrivate] = useState([])
    const [userSearchString, setUserSearchString] = useState("")
    const [foundedUsers, setFoundedUsers] = useState([])
    const [potentialDoer, setPotentialDoer] = useState()

    // async function getUsers() {
    //     const response = await $api.get('/get_users')
    //     console.log(response.data)
    //     setUserListForPrivate(response.data)
    // }


    useEffect(() => {

        // if(orderStatus === 'private' && !userListForPrivate.length) {
        //     getUsers()
        // }

        //подгоняем размер инпута поиска юзера, потому что когда он внутри дива, то почему то становиться меньше автоматом
        assignWidth("order-title-input", "private-order-user-input")

    }, [orderStatus, potentialDoer])

    const userSearchTimer = useRef()
    const [nowUserSearching, setNowUserSearching] = useState(false)
    const [showEmptySearch, setShowEmptySearch] = useState(false)

    async function getSearchingUsers(value) {
        setNowUserSearching(true)
        const response = await $api.get(`/search_users?value=${value}`)
        setFoundedUsers(response.data)
        if(!response.data.length) setShowEmptySearch(true)
        setNowUserSearching(false)
        userSearchTimer.current = null
    }


    useEffect(() => {

        clearTimeout(userSearchTimer.current)
        setShowEmptySearch(false)
        if(userSearchString.length > 2) {

            userSearchTimer.current = setTimeout(() => {getSearchingUsers(userSearchString)}, 1000)

        } else {
            userSearchTimer.current = null
            setFoundedUsers([])
        }
    }, [userSearchString])



    useEffect(() => {
        assignWidth("order-title-input", "founded-user-list")
    }, [foundedUsers])






    const [disciplineString, setDisciplineString] = useState()

    const [emptyMassive, setEmptyMassive] = useState(false)
    const [disString1, setDisString1] = useState("")
    const [disString2, setDisString2] = useState("")
    const [classifierId, setClassifierId] = useState()


    useEffect(() => {

        setDisciplineString(`${disString1}${disString2 ? ", " + disString2 : ""}${discipline ? ", " + discipline : ""}`)
        console.log("Setting string - ", `${disString1}${disString2 ? ", " + disString2 : ""}${discipline ? ", " + discipline : ""}`)

    }, [section, science, discipline, emptyMassive])



    const [errorOfPlacing, setErrorOfPlacing] = useState(false)

    //функция для отправки сформированного заказа на сервер
    async function placeOrder() {

        setErrorOfPlacing(false)

        if(orderStatus === "private" && !potentialDoer) {
            setErrorOfPlacing(true)
            setStatus({color: 'red', message: "Не выбран потенциальный исполнитель"})
            return
        }


        //e.preventDefault();

        setOrderPlaced(true)
        await setDisableSend(true)

        let formElement = await document.querySelector("form");
        const formData = await new FormData(formElement);
        await formData.append('creator', {...store.user}.id)
        await formData.append('files', JSON.stringify(attachedFiles));
        await formData.append('discipline', disciplineString)
        await formData.append('classifier_id', classifierId)

        await formData.append('need', apChoice)
        await formData.append('system', apSystem)
        await formData.append('value', apValue)
        //const apData = { need: apChoice, system: apSystem, value: apValue };
        //console.log(apData);

        //await formData.append('ap', JSON.stringify(apData));



        if(editing) {
            await formData.append("editing", true)
            await formData.append("order_id", orderId.current)
        } else {
            await formData.append("editing", false)
        }



        await formData.append("orderStatus", orderStatus)

        if(potentialDoer && orderStatus === "private") {
            await formData.append("potentialDoer", potentialDoer?._id)
        }


        console.log(disciplineString)

        if(closestDate) {
            formData.append('deadline', "")
        }

        if(priceOffer) {
            formData.append('priceOffer', true)
        }


        console.log(formData)

        const response = await $api.post('/create_order', formData, {headers: {charset: 'win1251'}}, {onUploadProgress: (ProgressEvent) => {

                let progress = Math.round(
                        ProgressEvent.loaded / ProgressEvent.total * 100) + "%";
                    setProgress(progress);
                }}).catch((e) => {
            console.log('ЛОВИМ ОШИБКИ АКСИОСА')
            console.log(e.response.status)
            if (e.response.status >= 400) {
                setOrderPlaced(true)
                //так же ставим статус заказа для информирования пользователя, он будет показан
                setStatus({color: 'red', message: "Сервер недоступен, попробуйте позже"})
            }
        })

        if (response.data.status === 'fuckup2') {

            setOrderPlaced(true)
            //так же ставим статус заказа для информирования пользователя, он будет показан
            setStatus({color: 'red', message: "Хранилище недоступно, попробуйте позже"})
            setDisableSend(false)

        } else if (response.data.status === 'Ok') {
            setOrderPlaced(true)


            for(const path of markedToDelete.current) {
                await $api.post('/delete_file_from_order', {path: path})
            }

            //так же ставим статус заказа для информирования пользователя, он будет показан
            if (editing) {
                setStatus({color: 'white', message: "Сохраняем..."})
            } else {
                setStatus({color: 'white', message: "Заказ публикуется..."})
            }

            //через три секунды редиректим пользователя на страницу ленты заказов
            setTimeout(() => navigate(`/order-details/${response.data.orderId}`), 3000)
        } else {
            console.log('ДА, МЫ ЗДЕСЬ')
            console.log(response)
            setOrderPlaced(true)
            //так же ставим статус заказа для информирования пользователя, он будет показан
            setStatus({color: 'red', message: "Какая то ошибка сервера"})
            setDisableSend(false)
        }




        // axios.post("/create_order", formData, {headers: {charset: 'win1251'}},{onUploadProgress: (ProgressEvent) => {
        //
        //     console.log('Старт POST запроса с отправкой файла')
        //
        //
        //     let progress = Math.round(
        //             ProgressEvent.loaded / ProgressEvent.total * 100) + "%";
        //         setProgress(progress);
        //     }})
        //     .then(res => {
        //         console.log(res.status + ' - это рес статус');
        //         console.log(res);
        //         console.log('file was uploaded to tempo storage')
        //         setOrderPlaced(true)
        //                     //так же ставим статус заказа для информирования пользователя, он будет показан
        //                     setOrderStatus({color: 'green', message: "Заказ успешно опубликован"})
        //                     //через три секунды редиректим пользователя на страницу ленты заказов
        //                     setTimeout(() => navigate('/order-feed'), 3000)
        //
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     })




    }

    //создаем переменную из хука useForm, он используется для валидации форм
    const {
        //эта штука позволит зарегистрировать поле формы (там ниже смотри, всё понятно будет)
        register,
        //это видимо как то отвечает за ошибки, там внизу тоже все это есть
        formState: {errors},
        //это видимо хрень которая отлавливает сабмит и что то делает)))
        handleSubmit,
        //очистка ошибок
        clearErrors,
        //установщик собственных стейтов реакт-хук-форма
        setValue,
    } = useForm({mode: "onSubmit"});//это режим проверки данных только после попытки сабмита, есть и другие если надо



    useEffect(() => {
        setValue('title', orderTitle); // Устанавливаем значение поля ввода после регистрации
        setValue('deadline', deadline)
        setValue('description', description)
        setValue('price', price)

    }, [orderTitle, deadline, description, price]);




    //Ниже две переменных и функция для приведения даты к формату который прожуёт инпут с датой в форме,
    // чтобы можно было установить минимальное значение даты в этой форме
    function addZero(num) {
        if (num <= 9) {
            return '0' + num;
        } else {
            return num;
        }
    }

    let date = new Date()
    let formattedDate = date.getFullYear() + "-" + addZero(date.getMonth()+1) + "-" + addZero(date.getDate())

    console.log(formattedDate)
    console.log(section)




    //проверялка длины имени файла
    async function lengthController(files){

        console.log(files)


        for (let file of files) {
            if(fileNames.includes(file.name)) {
                setErrorMessage({show: true, text: "Этот файл уже добавлен"})
                return false
            }
        }

        if(files.length > 10) {
            setErrorMessage({
                show: true,
                text: `Максимальное количество файлов, которое можно прикрепить к заказу 10, вы 
                пытаетесь прикрепить ${files.length}, так не получится =)`})
            return false
        }


        for(let i=0; i < files.length; i++) {
            if(files[i].name.length > 140) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет длину имени более 140 символов,
                    его нужно сократить, речь идет о файле ${files[i].name}`})
                return false
            }

            if(files[i].size > 104857600) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет размер более 100 мегабайт,
                    мы не принимаем файлы, чей размер превышает это значение, речь идет о файле ${files[i].name}`})
                return false
            }

            if(files[i].type === "application/x-msdownload") {
                setErrorMessage({
                    show: true,
                    text: `Вы пытаетесь прикрепить исполняемый файл, это противоречит всему на свете, речь идет о файле ${files[i].name}`})
                return false
            }

        }

        console.log(files)

        return true
    }


    const [inputKey, setInputKey] = useState(0);


    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)



    async function attacheFilesHandler(e) {
        e.preventDefault();

        setUploading(true);
        setInputKey((prevKey) => prevKey + 1);

        await setErrorMessage((prev) => ({ show: false, text: "" }));

        const filesToAttach = e.target.files;

        if (await lengthController(filesToAttach)) {
            try {
                if (filesId) {
                    const response = await $api.post(`/add_materials_to_order/${store.user.id}/${filesId}`, filesToAttach, {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        },
                    });
                    handleFileResponse(response);
                } else {
                    const response = await $api.post(`/add_materials_to_order/${store.user.id}/`, filesToAttach, {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        },
                    });
                    handleFileResponse(response);
                }
            } catch (e) {
                setErrorMessage((prev) => ({ show: true, text: e.message }));
            }
        }

        setUploadProgress(0); // Сброс прогресса после завершения загрузки
        setUploading(false);
    }

    function handleFileResponse(response) {
        if (response.status < 400) {
            if(editing) {
                setAttachedFiles((prev) => [...response.data.files, ...prev]);
                const names = Array.from(response.data.files, (file) => file.name);
                setFileNames((prev) => [...names, ...prev]);
            } else {
                setAttachedFiles((prev) => [...prev, ...response.data.files]);
                const names = Array.from(response.data.files, (file) => file.name);
                setFileNames((prev) => [...prev, ...names]);
            }

        } else {
            setErrorMessage({ show: true, text: "Не удалось загрузить файл на сервер" });
        }
    }




    // //обработчик прикрепления файлов
    // async function attacheFilesHandler(e) {
    //     e.preventDefault()
    //
    //     setUploading(true)
    //     //это обновление ключа для поля инпут нужно чтобы при повторном добавлении удаленного файла с тем же именем
    //     //происходил сброс этого имени из кэша и происходило собственно добавление
    //     //если так не сделать то при повторном добавлении просто будет происходить НИЧЕГО))
    //     setInputKey((prevKey) => prevKey + 1); // Обновляем ключ поля ввода "file"
    //
    //     await setErrorMessage(prev => ({show: false, text: ""}))
    //
    //     console.log(e.target.files)
    //     const files_to_attach = e.target.files
    //
    //     if(await lengthController(files_to_attach)) {
    //
    //         try {
    //             console.log(files_to_attach)
    //             if(filesId) {
    //                 console.log("filesId exists")
    //                 const response = await $api.post(`/add_materials_to_order/${store.user.id}/${filesId}`, files_to_attach)
    //                 if(response.status < 400) {
    //                     await setAttachedFiles(prev => [...prev, ...response.data.files])
    //                     let names = []
    //                     for (let file of files_to_attach) {
    //                         names.push(file.name)
    //                     }
    //                     console.log(names)
    //
    //                     setFileNames(prev => [...prev, ...names])
    //
    //
    //                 } else {
    //                     setErrorMessage({show: true, text: "Не удалось загрузить файл на сервер"})
    //                 }
    //
    //             } else {
    //                 console.log("filesId doesn't exists")
    //                 const response = await $api.post(`/add_materials_to_order/${store.user.id}/`, files_to_attach)
    //                 if (response.status < 400) {
    //                     await setFilesId(response.data.files_id)
    //                     await setAttachedFiles(prev => [...prev, ...response.data.files])
    //
    //                     let names = []
    //                     for (let file of files_to_attach) {
    //                         names.push(file.name)
    //                     }
    //                     console.log(names)
    //
    //                     setFileNames(prev => [...prev, ...names])
    //
    //                 } else {
    //                     setErrorMessage({show: true, text: "Не удалось загрузить файл на сервер"})
    //                 }
    //
    //             }
    //
    //             console.log("SETFILES2")
    //
    //
    //
    //         } catch (e) {
    //
    //             setErrorMessage(prev => ({show: true, text: e.message}))
    //         }
    //     }

    //
    //     setUploading(false)
    //
    // }


    function markToDeleteFile(path, index) {
        const newState = attachedFiles.slice(0)
        newState.splice(index, 1)
        setAttachedFiles(newState)

        const newStateNames = fileNames.slice(0)
        newStateNames.splice(index, 1)
        setFileNames(newStateNames)

        markedToDelete.current.push(path)
    }



    async function deleteFile(path, index) {
        const response = await $api.post('/delete_file_from_order', {path: path})
        if(response >= 400) {
            setErrorMessage({show: true, text: "Ошибка удаления файла"})
        } else {


            const newState = attachedFiles.slice(0)
            newState.splice(index, 1)
            setAttachedFiles(newState)

            const newStateNames = fileNames.slice(0)
            newStateNames.splice(index, 1)
            setFileNames(newStateNames)

        }
    }

    function mapReadyFiles(arrayToMap, editing) {



        let count = 0

        console.log(arrayToMap)

        let reversedArray = [...arrayToMap]
        if(editing) {
            reversedArray.reverse()
        } else {
            reversedArray.reverse()
        }

        if(arrayToMap?.length > 0) {
            return <div>

                {reversedArray.reverse().map((file, index) => {

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else {
                        type = "doc_thumb"
                    }


                    //тройка в квадратных скобках это количество папок вложенных)))
                    //const filename = file.path.split('\\')[5].replace(/_/g, " ")
                    //const filename = file.path.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");

                    const filenameWindows = file.path.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                    let filename;

                    if (filenameWindows.includes('/')) {
                        filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                    } else {
                        filename = filenameWindows;
                    }


                    count++
                    return<div key={file.path}>
                        {count > 1 ? <hr className={"native-blue"}/> : null}
                        <div className={"one-ready-order-container"} key={file.path}>
                            <div className={"ready-filename-container"}>
                                <img className={"order-done-file-image"} src={require(`../img/${type}.png`)} width={"50"}/>
                                <p className={"inline"}>{filename.length > 50 ? filename.slice(0, 50)+"..." : filename}</p>
                            </div>
                            <button
                                className={"btn btn-danger border-radius-8 order-download-btn"}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if(editing) {
                                        markToDeleteFile(file.path, index)
                                    } else {
                                        deleteFile(file.path, index)
                                    }

                                }}
                            >
                                Удалить
                            </button>

                        </div>
                    </div>
                })}
            </div>
        }

    }



    return (
        //форма после сабмита вызывает функцию hadleSubmit из хука useForm для валидации зарегистрированных полей
        //а качестве аргумента передается функция размещения заказа
        <div className="new-order-wrapper">
            {/*<div className={"new-order-dossier-wrapper"}>*/}
            {/*    <Dossier/>*/}
            {/*</div>*/}
            <div className={"new-order-form-wrapper"}>
                <form encType="multipart/form-data" className="new-order-form" onSubmit={handleSubmit(placeOrder)}>

                    {/*//если стейт о сабмите меняется на true, то пользователь видит сообщение, если не меняется то не видит))*/}
                    {/*//Компонент ErrorMessage прилетел как раз из хука useForm*/}
                    {/*{isOrderPlaced ? <div><ErrorMessage color={status.color} message={status.message}/>{status.color === "purple" ? <img width="50px" src='https://c.tenor.com/NqKNFHSmbssAAAAi/discord-loading-dots-discord-loading.gif'/> : null}</div> : null}*/}

                    {editing
                        ?
                        <h2 className={"main-shield-title"} style={{marginBottom: "30px"}}>Редактирование</h2>
                        :
                        <h2 className={"main-shield-title"} style={{marginBottom: "30px"}}>Новый заказ</h2>
                    }



                    {/*//здесь первое зарегистрированное поле для валидации хуком useForm*/}
                    {/*//просто повторяем этот расклад для каждого нужного поля, опции можно менять, за этим пожалуйте в*/}
                    {/*//документацию, тут комментариев и так уже дохера*/}

                    <input
                        {...register('title', {required: true})}
                        placeholder="Тема работы"
                        name="title"
                        id={"order-title-input"}
                        onChange={(e) => {
                            clearErrors('title')
                            setOrderTitle(e.target.value)
                        }}
                        value={orderTitle}
                        defaultValue={orderTitle}
                    />
                    <div className="error-container">{errors?.title && <p className="error-message">Не заполнено поле "Тема"</p>}</div><br/>
                    {/*//а вот тут я сделал див как контейнер для отображаемой ошибки, и такой есть у каждого валидируемого поля*/}
                    {/*//функция внутри него проверяет существуют ли (errors?) ошибки для зарегистрированного поля с именем title*/}
                    {/*//и если да, то выводит ошибку*/}


                    {/*<select {...register('discipline', {required: true})} defaultValue={""} name="discipline">*/}
                    {/*    <option value={""} disabled>Дисциплина</option>*/}
                    {/*    {disciplines.map(dis => <option key={dis.name}>{dis.name}</option>)}*/}
                    {/*</select>*/}


                    <select

                        //defaultValue={selectedValue}
                        value={orderStatus ?? ""}
                        onChange={(e) => {
                            setOrderStatus(e.target.value)
                        }}
                    >
                        <option value={"offer"}>Актуальный</option>
                        <option value={"private"}>Приватный</option>
                        <option value={"draft"}>Черновик</option>
                    </select><br/>

                    {orderStatus === "private" && !potentialDoer
                        ?
                        <div className={"private-order-user-list"}>
                            <input
                                id={"private-order-user-input"}
                                className={"private-order-user-input"}
                                placeholder={"Начните писать имя..."}
                                value={userSearchString}
                                onChange={(e) => {
                                    setUserSearchString(e.target.value)
                                }}
                            />
                            {nowUserSearching
                                ?
                                <img src={require('../img/loading.gif')} width={"35px"} className={"dont-drag"}/>
                                :
                                null
                            }
                            {foundedUsers.length
                                ?
                                <div className={"founded-user-list"} id={"founded-user-list"}>
                                    {/*<h5>Заглушка</h5>*/}
                                    {foundedUsers.map(user => {
                                          return (<div
                                            className={"invocation-user-and-avatar-container"}
                                            key={user.id}
                                            onClick={() => {
                                                setPotentialDoer({_id: user.id, title: user.name + " " + user.lastName})
                                                setFoundedUsers([])
                                                setUserSearchString("")
                                                setErrorOfPlacing(false)
                                            }}
                                            >
                                            <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                            <p
                                                className={"invocation-user margin-0"}
                                            >{user.name + " " + user.lastName}</p></div>)

                                    })}
                                </div>
                                :
                                userSearchString.length > 2 && showEmptySearch
                                    ?
                                    <div className={"founded-user-list"} id={"founded-user-list"}>
                                        <h6 className={"invocation-user margin-0"}
                                        >Нет совпадений</h6>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                        :
                        potentialDoer && orderStatus === "private"
                            ?
                            <div
                                className={"potential-doer-container margin-bottom-20"}>
                                <img className={"avatar-dossier dont-drag"} src={`${API_URL}/public/avatars/${potentialDoer?._id}/avatar.png`}/>
                                <p
                                    className={"potential-doer-name"}
                                >для {potentialDoer?.title}</p>
                                <button
                                    title={"Отменить"}
                                    className={"btn margin-left-20 border-radius-8"}
                                    onClick={() => {
                                        setPotentialDoer()
                                    }}
                                >❌</button>
                            </div>
                            :
                            null
                    }



                    <div className={"classifier-and-search-discipline-container"}>
                        <div className={"classifier-and-errors-container"}>

                            {classifierNew && !filteredDisciplines.length
                                ?
                                <div className={"new-order-classifier-wrapper"}>
                                    <div className={"classifier-section-container"}>
                                        <select
                                            id={"default-select"}
                                            className={"width-100p"}
                                            {...register('section', {required: true})}
                                            name={"section"}
                                            defaultValue={selectedValue}
                                            // defaultValue={""}
                                            value={disString1}
                                            onChange={(e) => {
                                                const selectedSection = classifierNew.find(item => JSON.stringify(item.section) === e.target.selectedOptions[0].getAttribute('data'));
                                                setSection(selectedSection.section);
                                                console.log(selectedSection.section)
                                                setDisString1(selectedSection.section_name)
                                                setDisString2()
                                                console.log("SETTING SCIENCE 2")
                                                setScience(null);
                                                setDiscipline("");
                                                clearErrors('section');
                                                clearErrors('science')
                                                clearErrors('discipline')
                                                setValue('science', null)
                                                setValue('discipline', null)

                                            }}
                                        >
                                            <option value={""} disabled>
                                                Раздел
                                            </option>
                                            {classifierNew.map(item1 => (
                                                <option
                                                    key={item1._id}
                                                    value={item1.section_name}
                                                    data={JSON.stringify(item1.section)}
                                                >
                                                    {item1.section_name}
                                                </option>

                                            ))}
                                        </select>
                                    </div>

                                    {section
                                        ?
                                        <div className={"new-order-classifier-science-discipline-container"}>
                                            <div className={"classifier-section-container"}>
                                                <select
                                                    className={"width-100p"}
                                                    {...register('science', {required: true})}
                                                    name={"science"}
                                                    value={science ? JSON.stringify(science) : ""}
                                                    onChange={(e) => {
                                                        const selectedScience = JSON.parse(e.target.value);

                                                        console.log("SETTING SCIENCE 3")
                                                        setScience(selectedScience);
                                                        console.log(selectedScience)
                                                        setClassifierId()
                                                        const selectedSection = section.find(item => JSON.stringify(item.science) === e.target.value);
                                                        setEmptyMassive(selectedSection?.empty_massive || false);

                                                        if (selectedSection?.empty_massive) {
                                                            setClassifierId(selectedSection?.science[0]?._id);
                                                        } else {
                                                            setClassifierId(null);
                                                        }

                                                        const scienceName = section
                                                            .map(item2 => item2.science_name)
                                                            .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты
                                                            .sort() // Сортируем в алфавитном порядке
                                                            .find(scienceName => JSON.stringify(section.find(item => item.science_name === scienceName).science) === e.target.value);


                                                        setDisString2(scienceName);
                                                        setDiscipline("");
                                                        clearErrors('science');
                                                        clearErrors('discipline')
                                                        setValue('discipline', null)
                                                    }}
                                                >
                                                    <option value={""} disabled>
                                                        Область
                                                    </option>
                                                    {section
                                                        .map(item2 => item2.science_name)
                                                        .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты
                                                        .sort() // Сортируем в алфавитном порядке
                                                        .map(scienceName => (
                                                            <option key={scienceName} value={JSON.stringify(section.find(item => item.science_name === scienceName).science)}>
                                                                {scienceName}
                                                            </option>
                                                        ))}
                                                </select>

                                            </div>

                                            {science && !emptyMassive
                                                ?
                                                <div className={"classifier-section-container"}>
                                                    <select
                                                        className={"width-100p"}
                                                        {...register('discipline', {required: true})}
                                                        name={"discipline"}
                                                        value={discipline}
                                                        onChange={(e) => {
                                                            const selectedDisciplineId = e.target.options[e.target.selectedIndex].getAttribute('data-id');
                                                            setClassifierId(selectedDisciplineId);

                                                            setDiscipline(e.target.value);
                                                            clearErrors('discipline');
                                                        }}
                                                    >
                                                        <option value={""} disabled>
                                                            Дисциплина
                                                        </option>
                                                        {science
                                                            .map(item3 => item3.discipline)
                                                            .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты
                                                            .sort() // Сортируем в алфавитном порядке
                                                            .map(disciplineName => (
                                                                <option
                                                                    key={disciplineName}
                                                                    value={disciplineName}
                                                                    data-id={science.find(item => item.discipline === disciplineName)._id}
                                                                    title={disciplineName}
                                                                >
                                                                    {disciplineName}
                                                                </option>
                                                            ))}
                                                    </select>

                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                                :
                                filteredDisciplines.length
                                    ?

                                    <div className={"new-order-classifier-wrapper"}>
                                        <div className={"classifier-section-container"}>

                                            <select

                                                ref={selectRef}
                                                className={"width-100p"}
                                                defaultValue={""}

                                            >

                                                {filteredDisciplines
                                                    .map(item3 => item3.discipline)
                                                    .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты
                                                    .sort() // Сортируем в алфавитном порядке
                                                    .map(disciplineName => (
                                                        <option
                                                            className={"thats-it"}
                                                            disabled={disciplineName === 'Нет совпадений'}
                                                            key={disciplineName}
                                                            value={disciplineName}
                                                            //data-id={science.find(item => item.discipline === disciplineName)._id}
                                                            title={disciplineName}
                                                            onClick={() => {
                                                                const disciplineObject = filteredDisciplines.find(item => item.discipline === disciplineName)

                                                                const foundSectionObject = classifierNew.find(item =>
                                                                    item.section.some(sectionItem =>
                                                                            //sectionItem.science.some(scienceItem =>
                                                                            sectionItem._id === disciplineObject.parent_id
                                                                        //)
                                                                    )
                                                                );

                                                                console.log("РАЗДЕЛ")
                                                                console.log(foundSectionObject.section)
                                                                setSection()
                                                                setSection(foundSectionObject.section);
                                                                setDisString1(foundSectionObject.section_name)

                                                                const foundScienceObject = foundSectionObject.section.find(item =>
                                                                    item.science.some(item2 =>
                                                                        item2._id === disciplineObject._id
                                                                    )

                                                                )

                                                                console.log(foundScienceObject)
                                                                setEmptyMassive(foundScienceObject?.empty_massive || false);

                                                                console.log("ОБЛАСТЬ")
                                                                console.log(foundScienceObject.science)
                                                                console.log("SETTING SCIENCE 4")
                                                                setScience()
                                                                setScience(foundScienceObject.science)
                                                                if (foundScienceObject.science?.empty_massive) {
                                                                    setClassifierId(foundScienceObject.science?.science[0]?._id);
                                                                } else {
                                                                    setClassifierId(null);
                                                                }
                                                                setDisString2(foundScienceObject.science_name);

                                                                setClassifierId(disciplineObject._id);
                                                                console.log("ДИСЦИПЛИНА")
                                                                console.log(disciplineObject.discipline)
                                                                setDiscipline()
                                                                setDiscipline(disciplineObject.discipline);
                                                                setSearchTerm("")
                                                            }}
                                                        >
                                                            {disciplineName}
                                                        </option>
                                                    ))}
                                            </select>

                                        </div>
                                    </div>
                                    :
                                    null

                            }




                        </div>
                        <input
                            className={"margin-left-20 margin-top-10"}
                            type={'text'}
                            placeholder={"Поиск дисциплины"}
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                            }}
                        />
                    </div>
                    <div>{errors?.section && <p className="error-message">Не выбран раздел</p>}</div>
                    <div>{errors?.science && <p className="error-message">Не выбрана область</p>}</div>
                    <div>{errors?.discipline && <p className="error-message">Не выбрана дисциплина</p>}</div>



                    <br/>

                    <select
                        {...register('type', {required: true})}
                        defaultValue={selectedValue}
                        value={workType}
                        onChange={(e) => {
                            setWorkType(e.target.value)
                            clearErrors('type')
                        }}
                    >
                        <option value={""} disabled>Тип работы</option>
                        <option value="Диплом">Диплом</option>
                        <option value="Курсовая">Курсовая</option>
                        <option value="Контрольная">Контрольная</option>
                        <option value="Лабораторная">Лабораторная</option>
                        <option value="Задача">Задача</option>
                        <option value="Отчет по практике">Отчет по практике</option>
                        <option value="Статья">Статья</option>
                        <option value="Доклад">Доклад</option>
                        <option value="Эссе">Эссе</option>
                        <option value="Реферат">Реферат</option>
                        <option value="ВКР">ВКР</option>
                        <option value="Магистерская диссертация">Магистерская диссертация</option>
                        <option value="Кандидатская диссертация">Кандидатская диссертация</option>
                        <option value="Докторская диссертация">Докторская диссертация</option>
                        <option value="Бизнес план">Бизнес план</option>
                        <option value="Творческая работа">Творческая работа</option>
                        <option value="РГР">РГР</option>
                        <option value="НИР">НИР</option>
                        <option value="Онлайн">Онлайн</option>
                        <option value="Другое">Другое</option>

                    </select>
                    <div className="error-container">{errors?.type && <p className="error-message">Не выбран тип работы</p>}</div><br/>


                    <div>
                        <h6 className={'inline'}>Aнтиплагиат</h6>
                        <input
                            className={"ap-input"}
                            type={"checkbox"}
                            checked={apChoice}
                            name={"ap"}
                            value={apChoice}
                            onClick={() => {
                                setApSystem("АП-ВУЗ")
                                setApChoice(prev => !prev)}
                        }/><br/>
                        {apChoice
                            ?
                            <div>
                                <select
                                    name={"apSystem"}
                                    className={'ap-input-number'}
                                    defaultValue={selectedValue}
                                    value={apSystem}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setApSystem(e.target.value)
                                    }}
                                >
                                    <option value="АП-ВУЗ">АП-ВУЗ</option>
                                    <option value="АП-РУ">АП-РУ</option>
                                    <option value="Text.ru">Text.ru</option>
                                    <option value="RuContext">RuContext</option>
                                    <option value="Etxt">Etxt</option>
                                    <option value="Advego">Advego</option>
                                    <option value="Другое">Другое</option>
                                </select>
                                <select
                                    {...register('antiplagiat', {required: apChoice})}
                                    className={'ap-input-number'}
                                    defaultValue={selectedValue}
                                    value={apValue}
                                    onChange={(e) => {
                                        setApValue(e.target.value)
                                    }}
                                >
                                    <option value={""}>?</option>
                                    <option value="10-20">10-20</option>
                                    <option value="20-30">20-30</option>
                                    <option value="30-40">30-40</option>
                                    <option value="40-50">40-50</option>
                                    <option value="60-70">60-70</option>
                                    <option value="70-80">70-80</option>
                                    <option value="80-90">80-90</option>
                                    <option value="90-100">90-100</option>
                                </select>
                                <h6 className={'inline'}>%</h6>
                                <div className="error-container">{errors?.antiplagiat && <p className="error-message">Раз уж нужен, то укажите проценты</p>}</div><br/>
                            </div>
                            :
                            null}

                    </div>

                    <div>
                        <label><h6>Срок</h6></label><br/>
                        {closestDate
                            ?
                            null
                            :
                            <div className={"stepper-date-setter"}>
                                <input {...register('deadline', {required: true})}
                                       type="date"
                                       name="deadline"
                                       min={formattedDate}
                                       value={deadline}
                                       onChange={(e) => {
                                           setDeadLine(e.target.value)
                                           console.log(e.target.value)
                                           clearErrors('deadline')
                                       }}

                                /><br/></div>}
                        <label>Ближайшая дата автора</label>
                        <input
                            onClick={() => {
                                setClosestDate(prev => !prev)
                                clearErrors('deadline')

                            }}
                            className={"closest-date"}
                            type={"checkbox"}
                            checked={closestDate}
                            value={closestDate}
                        />
                        <div className="error-container">{errors?.deadline && <p className="error-message">Не установлен дедлайн</p>}</div><br/>
                    </div>


                    {/*<input onChange={handleChange} style={{backgroundColor: "#023047", color: "white", border: "1px solid black"}} accept=".rar, .zip" type="file" name="reference"/><br/>*/}
                    <div className={"add-btn-n-label-new-order margin-bottom-20"}>
                        <label><h6>Материалы (методички, документы и т.д.)</h6></label><br/>
                        <input
                            type={'file'}
                            ref={inputRef}
                            key={inputKey}
                            multiple
                            name={"files[]"}
                            id={"order-files-input"}
                            onChange={(e) => {
                                attacheFilesHandler(e)
                            }}/>
                        <button
                            className={"btn primary-btn-color margin-left-20"}
                            onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("order-files-input").click()}}>
                            {attachedFiles.length ? <span>Добавить <img src={SvgPinRed} width={"20px"}/></span> : <span>Прикрепить <img src={SvgPinRed} width={"20px"}/></span> }
                        </button>

                    </div>
                    {errorMessage.show ? <h5 className={"red error-message-ready-order"}>{errorMessage.text}</h5> : null}
                    {uploading && (
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                            <span className="progress-text">{uploadProgress}% загружено</span>
                        </div>
                    )}

                    {attachedFiles.length
                        ?
                        mapReadyFiles(attachedFiles, editing)
                        :
                        null
                    }




                    <br/>




                    {priceOffer
                        ?
                        null
                        :
                        <div className={"stepper-date-setter"}>

                            <input
                                className={"inline"}
                                {...register('price', {required: true})}
                                type="number"
                                min={0}
                                placeholder="Стоимость"
                                name="price"
                                value={price}
                                onChange={(e) => {
                                    priceHandler(e.target.value)
                                    clearErrors('price')
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === "Enter") {
                                        e.preventDefault(); // Предотвращаем ввод символа '+'
                                    }
                                    if((e.key === "." || e.key === ",") && price === "") {
                                        e.preventDefault()
                                    }
                                }}
                            />
                            <label style={{marginLeft: '20px'}}>₽</label>
                            <div className="error-container">{errors?.price && <p className="error-message">Вам не нужны деньги? =)</p>}</div>
                            <br/>
                        </div>
                    }
                    <br/>
                    <label>На оценку</label>
                    <input
                        onClick={() => {
                            setPrice(0)
                            setPriceOffer(prev => !prev)

                        }}
                        value={priceOffer}
                        checked={priceOffer}
                        className={"price-offer"} type={"checkbox"}
                    />



                    <div>{errors?.description && <p className="error-message">Добавьте описание к заказу</p>}</div>
                    <textarea
                        {...register('description', {required: true})}
                        placeholder="Описание заказа"
                        name="description"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            clearErrors("description")
                        }}
                    />
                    <div className="btn-container">

                        {isOrderPlaced
                            ?
                            <div className={"order-placing"}>
                                <ErrorMessage color={status.color} message={status.message}/>
                                {status.color === "purple"
                                    ?
                                    <img width="50px" src='https://c.tenor.com/NqKNFHSmbssAAAAi/discord-loading-dots-discord-loading.gif'/>
                                    :
                                    null
                                }
                                <img src={require('../img/loading.gif')} width={"35px"} className={"margin-left-20"}/>
                            </div>
                            :
                            null
                        }
                        {errorOfPlacing
                            ?
                            <div className={"order-placing"}>
                                <ErrorMessage color={status.color} message={status.message}/>
                            </div>
                            :
                            null
                        }
                        {/*//это кнопка сабмита, на нее не привязано никакой ссылки или действия по щелчку,*/}
                        {/*//но она имеет тип submit, что триггерит функцию внутри поля onSubmit формы внутри которой эта кнопка расположена*/}
                        <button
                            disabled={disableSend}
                            type="submit"
                            className={"btn primary-btn-color submit-order-btn"}

                        >{editing ? "Сохранить" : "Опубликовать"}</button>
                    </div>


                </form>

            </div>
        </div>
    );
};

export default NewOrder;