import React, {useContext, useEffect, useState, useRef} from 'react';
import {Context} from "../../index";
import $api from "../../http";
import {toJS} from "mobx";
import {observer} from 'mobx-react-lite';
import SvgAnswer from "../../img/answer-arrow.svg";
import SvgArrowDown from "../../img/arrow-down.svg";
import {getMinutesBetweenDates} from "./repeat_functions"; // Путь к SVG-файлу




//это компонент чатика для заказов
const OrderChat = (props) => {

    const today = Date.now()


    const {store} = useContext(Context)


    //эта переменная содержит айдишники юзеров которые задают вопросы создателю заказа
    let chatAgents = []


    //это лимиты отображения количества сообщений в чатике, меняются по скроллу
    const [chatLimits, setChatLimits] = useState({start: 0, stop: 100})
    const [shiftEnterPressed, setShiftEnterPressed] = useState(false);


    //строка ввода
    const [chatValue, setChatValue] = useState("")

    useEffect(() => {
        if(chatValue === "" && textAreaRef.current) {
            textAreaRef.current.style.height = "auto"
        }
        console.log("ADJUSTING 1")
        if(textAreaRef.current && props.showChat.display === "block") {
            textAreaAdjust()
        }
    }, [chatValue])

    //отмечаем активный чат
    //const [activeUser, setActiveUser] = useState("")
    //количество диалогов по данному заказу (содержит пользователей)
    const [dialogUsers, setDialogUsers] = useState([])


    //это реф-ссылка на обзёрвер который следит за появление хтмл элементов с сообщениями в области видмости
    //он нужен для того чтобы отмечать сообщения прочитанными
    const messageObserver = useRef(null);
    const observedAreaRef = useRef()

    //этот реф призван хранить элементы которые обсервер уже пронаблюдал в зоне видимости,
    //чтобы при добавлении не добавить ничего второй раз
    //const markedElements = useRef(new Set())

    const textAreaRef = useRef()

    const [showArrowDown, setShowArrowDown] = useState(false)



    //функция изменения размера поля ввода текста, она срабатывает каждый раз при изменении его содержимого
    function textAreaAdjust() {
        //задаем максимальную границу высоты в пикселях
        const maxHeight = 300
        //обнуляем стили через ссылку (ref) на область ввода текста
        //этот чтобы высота уменьшилась, если текста стало меньше или если его вообще нет
        textAreaRef.current.style.height = "auto"
        //а этот чтобы скрыть бегунок если он пока не нужен
        textAreaRef.current.style.overflowY = "hidden";

        //теперь если текущая высота возможного скрола не превышает максимальный размер области
        if(textAreaRef.current.scrollHeight <= maxHeight) {
            console.log("ADJUST IF")

            //то увеличиваем размер области до размеров возможного скролла
            if(textAreaRef.current?.scrollHeight === 0) {
                textAreaRef.current.style.height = 28 + "px";
            } else {
                textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            }

            //и прокручиваем окно
            window.scrollTo(0, document.body.scrollHeight)

            //если же возможный скролл превышает допустимую нами высоту
        } else {
            console.log("ADJUST ELSE")
            //то делаем высоту области максимально возможной
            textAreaRef.current.style.height = maxHeight + "px";
            //и разрешаем полосу прокрутки
            textAreaRef.current.style.overflowY = "auto";
        }
    }
    // function textAreaAdjust() {
    //     //задаем максимальную границу высоты в пикселях
    //     const maxHeight = 300
    //     //обнуляем стили через ссылку (ref) на область ввода текста
    //     //этот чтобы высота уменьшилась, если текста стало меньше или если его вообще нет
    //     textAreaRef.current.style.height = "auto"
    //     //а этот чтобы скрыть бегунок если он пока не нужен
    //     textAreaRef.current.style.overflowY = "hidden";
    //
    //     //теперь если текущая высота возможного скрола не превышает максимальный размер области
    //     if(textAreaRef.current.scrollHeight <= maxHeight) {
    //         //то увеличиваем размер области до размеров возможного скролла
    //         textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    //         //и прокручиваем окно
    //         window.scrollTo(0, document.body.scrollHeight)
    //
    //         //если же возможный скролл превышает допустимую нами высоту
    //     } else {
    //         //то делаем высоту области максимально возможной
    //         textAreaRef.current.style.height = maxHeight + "px";
    //         //и разрешаем полосу прокрутки
    //         textAreaRef.current.style.overflowY = "auto";
    //     }
    // }



    useEffect(() => {


        console.log("ЭФФЕКТ КРУТИЛОКА")
        //если в DOM существует элемент .new-message-line, это линия НОВЫЕ СООБЩЕНИЯ
        //а она существует только тогда, когда они есть, и в данный момент
        if(props.messagesAreaRef.current.querySelector('.new-message-line')) {
            console.log('5 scroll')
            console.log(props.messagesAreaRef.current.querySelector('.new-message-line'))
            console.log(props.messagesAreaRef.current.querySelector('.new-message-line').offsetTop)
            //то прокручиваем область сообщений до этого элемента с небольшой поправкой в 250рх
            //setTimeout(() => {
                console.log("Крутим 1")
                props.messagesAreaRef.current.scrollTo({
                    top: props.messagesAreaRef.current.querySelector('.new-message-line').offsetTop - 150, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                    //behavior: 'smooth', // Прокручиваем с анимацией
                })
            //}, 1000)

            //если такого элемента нет...
        } else {
            //...а так же соблюдается ряд условий
            console.log(props.messagesAreaRef.current.scrollTop)
            console.log(props.orderDetails)


            if (
                //окно сообщений откручено от начального положения не более чем на 300рх и текущий режим работы ИСПОЛНИТЕЛЬ
                props.messagesAreaRef.current.scrollTop > -500 && props.newOrderMessage.userId?._id
                && (props.newOrderMessage.userId?._id === props.activeUser || (props.orderDetails.doer?._id && props.newOrderMessage.userId?._id === props.orderDetails.doer?._id))
                || props.newOrderMessage.userId?._id === store.user.id && !props.alreadyRolled

            ) {

                console.log(props.messagesAreaRef.current.scrollTop > -500)
                console.log(props.messageListToRoll.length > 20)
                console.log(props.newOrderMessage.userId?._id === props.activeUser)
                console.log(props.newOrderMessage.userId?._id === props.orderDetails.doer?._id)
                console.log(props.newOrderMessage.userId?._id === store.user.id)
                console.log(props.newOrderMessage.userId?._id)

                console.log("PIN 34")

                props.setAlreadyuRolled(true)
                //и крутим окошко просмотра сообщений в самый низ
                console.log("Крутим 2")
                props.messagesAreaRef.current.scrollTo({
                    top: 0,
                    //behavior: 'smooth', // Прокручиваем с анимацией
                })
            }
        }

        //и уже после того как прокрутили окошко в правильное место
        //тогда и только тогда актуализируем информацию обсервера сообщений!!!
        if (store.user.id === props.orderDetails.creator?._id || store.user.id === props.orderDetails.doer?._id || !props.orderDetails.arbitration) {

            if(!props.stopMakingObserver) {
                makeActualObserver(props.messageListToRoll)
            }
        }





    }, [props.messageListToRoll, props.showChat])



    const handleScroll = throttle(scroller, 600)





    function scroller(e) {

        console.log(e.target.scrollTop)
        console.log(e.target.scrollHeight)
        console.log(e.target.clientHeight)
        console.log(e.target.scrollTop + e.target.scrollHeight - e.target.clientHeight)
        console.log("______________________")
        //это условие добавляет лимитов на отображение сообщений в чате, если докрутить его до самого верха
        if(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 150) {
            props.setSlicer({start: 0, stop: props.slicer.stop + 50})
            props.slicerRef.current = e.target.scrollHeight
        }
        //а это условие обнуляет все лимиты если опуститься обратно в самый низ чата
        if(e.target.scrollTop === 0) {
            props.setSlicer({start: 0, stop: 50})
            props.slicerRef.current = 0
        }

        if (e.target.scrollTop < -150) {
            setShowArrowDown(true)
        }

        if (e.target.scrollTop > -10) { //&& stopDownFetchingRef.current) {
            setShowArrowDown(false)
        }

    }


    //ограничитель количества выполнений функции
    //принимает как аргументы саму функцию и задержку перед следующим выполнением в миллисекундах
    function throttle(func, delay) {
        let timerId;
        let lastExecTime = 0;

        return function (...args) {
            const currentTime = Date.now();

            if (currentTime - lastExecTime > delay) {
                func.apply(this, args);
                lastExecTime = currentTime;
            } else {
                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    func.apply(this, args);
                    lastExecTime = currentTime;
                }, delay);
            }
        };
    }


    //функция отмечания сообщения как прочитанного, принимает айдишник сообщения ак аргумент
    async function markAsRead(item, message_id) {
        //console.log(`Marging as read - ${message_id}`)


        if(item === "all") {

            const response = await $api.post('/mark_work_message_as_read',
                {
                    user_id: store.user.id,
                    order_id: props.orderDetails._id,
                    direction: props.orderDetails.creator._id === store.user.id ? "out" : "in",
                    isModer: false,
                    all: true,
                    sender:
                        props.isArray
                            ?
                            props.isAuthor
                                ?
                                props.doer._id
                                :
                                props.creator._id
                            :
                            props.activeUser,
                    reader: store.user.id
                })

            store.setWorkUnreadCounterOut(store.workUnreadCounterIn - response.data)
            props.setUnreadUsers(prev => {
                const newArray = prev.filter(user => user !== props.activeUser)
                return newArray
            })


        } else {
            message_id = item

            //если режим исполнителя активен...
            if(props.orderDetails.creator._id === store.user.id) {


                props.readMessages.current.push(message_id)

                //...то уменьшаем счетчик сообщений по ВХОДЯЩИМ заказам в store
                store.setWorkUnreadCounterOut(store.workUnreadCounterIn - 1)

                //и если окошко просмотра сообщений находится в нижнем положении или очень близко к нему...
                if(props.messagesAreaRef.current.scrollTop > -150) {

                    props.setUnreadUsers(prev => {
                        const newArray = prev.filter(user => user !== props.activeUser)
                        return newArray
                    })

                    //..то выкидываем айдишник активного входящего заказа из стейта непрочитанных, чтобы погасить
                    //мигающий конверт
                    // await setUnreadOrdersIn(prev => {
                    //     const newState = prev.filter(id => id !== activeOrderIn)
                    //     return newState
                    // })
                }

                //если же активен режим заказчика...
            } else {



                props.readMessages.current.push(message_id)


                //то уменьшаем счетчик сообщений по исходящим заказам
                store.setWorkUnreadCounterIn(store.workUnreadCounterOut - 1)



                //и если окошко просмотра сообщений находится в нижнем положении или около него...
                if(props.messagesAreaRef.current.scrollTop > -50) {

                    console.log("сейчас маркер нужно убрать")
                    //то активируем логику отключения мигающего конвертика
                    // await setUnreadOrdersOut(prev => {
                    //     //сначала создаем новый массив с непрочитанными юзерами путем фильтрации, выкидывая от туда активного
                    //     const newArray = prev[activeOrderOut]?.filter(id => id !== activeUserOut && outgoingOrdersInfo[activeOrderOutRef.current].moderator !== id)
                    //     //дальше если этот массив не пустой...
                    //
                    //     if(newArray?.length) {
                    //         //возвращаем старое состояние стейта с обновлением ключа активного заказа на новый массив
                    //         return {
                    //             ...prev,
                    //             [activeOrderOut]: newArray
                    //         }
                    //         //а если массив опустел, то...
                    //     } else {
                    //         //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                    //         const { [activeOrderOut]: deletedKey, ...updatedState } = prev;
                    //         //и возвразаем то что получилось обновляя стейт
                    //         return updatedState;
                    //     }
                    // })
                }

        }

            const synchroMessage = {
                to: store.user.id,
                numberIn: Number(store.workUnreadCounterIn),
                numberOut: Number(store.workUnreadCounterOut),
                event: "synchro_work"
            }
            await props.notifySocket.current.send(JSON.stringify(synchroMessage));


        }


        // это запрос на изменение счетчика непрочитанных сообщений в базе данных, в табличке информации о юзерах
        const response = await $api.post('/mark_work_message_as_read',
            {
                user_id: store.user.id,
                message_id: message_id,
                order_id: props.orderDetails._id,
                direction: props.orderDetails.creator._id === store.user.id ? "out" : "in",
                sender:
                    props.isArray
                        ?
                        props.creator._id === store.user.id
                            ?
                            props.doer._id
                            :
                            props.creator._id
                        :
                        props.activeUser,
                reader: store.user.id
            }
        )

        console.log(response.data)
    }





    //функция создания (ну и пересоздания) обсервара (наблюдателя) сообщений
    //для того чтобы отслеживать появление сообщения в зоне видимости и на основании этого отмечать его как прочитанное
    async function makeActualObserver(list) {

        //срабатывает только если массив поданный в функцию не является пустым
        if(list?.length) {
            console.log("MAKING OBSERVER")
            //получаем актуальную область наблюдения
            const messageArea = observedAreaRef.current

            // Создаем новый IntersectionObserver и передаем ему колбэк-функцию
            // для обработки изменений видимости элементов
            messageObserver.current = new IntersectionObserver((entries) => {
                // Проходимся по каждой записи в массиве entries
                entries.forEach(async (entry) => {
                    // Если элемент .chat-left или .chat-center находится в видимой части экрана
                    console.log("INTERSECTION WORK")

                    //console.log(entry.target.id)
                    // Если элемент наблюдаемый находится в видимой части экрана
                    // и не находится в массиве уже отработанных
                    if (entry.isIntersecting && !props.markedElements.current.has(entry.target.id)) {// && !markedElements.has(entry.target.id)) {
                        console.log("Вижу элемент")
                        // добавляем айди элемента в массив уже отработанных
                        props.markedElements.current.add(entry.target.id);

                        // Получаем элемент по айдишнику из DOM
                        const myElement = document.getElementById(entry.target.id)
                        //console.log(myElement)
                        if (myElement) {
                            //console.log("Выкидываем из наблюдения")
                            await messageObserver.current.unobserve(myElement)
                        }

                        console.log("В зоне видимости элемент - ", entry.target)
                        console.log("Его ID - ", entry.target.id)

                        //используем айдишник элемента для того чтобы отметить сообщение с соответствующим
                        //айди как прочитанное с помощью соответствующей функции
                        await markAsRead(entry.target.id)


                    } //else {
                    //console.log("НЕ вижу элемент")
                    //}
                });
            }, {
                // Передаем ссылку на родительский элемент .message-area
                root: messageArea,
                //это значение площади элемента на экране для того чтобы он считался видимым
                //в данном случае 10%
                threshold: 0.1
            });

            // Получаем все элементы .chat-left и .chat-center
            const chatMessages = document.querySelectorAll('.chat-left, .chat-center');
            //setAddFirstMessages(false)


            //фильтруем массив элементов так, чтобы не добавлять элементы которые уже были отработаны
            const newElements = Array.from(chatMessages).filter(element => {
                const isExistingElement = props.markedElements.current.has(element.id);
                return !isExistingElement;
            });

            // Проходимся по каждому элементу .chat-left и .chat-center
            newElements.forEach((chatMessage) => {
                //и если аттрибут 'data-read' равен false (этот аттрибут самопальный и его значение создается на основе
                // того, есть ли айди пользователя в массиве прочитавших в объекте сообщения)
                if (chatMessage.getAttribute('data-read') === "false") {
                    // Добавляем элемент в IntersectionObserver
                    messageObserver.current.observe(chatMessage);
                    //console.log(chatMessage)
                    //console.log("добавляем", chatMessage)
                    console.log("Добавляем наблюдалку")
                    console.log(chatMessage)
                }
            });
        }
    }










    //этот эффект помогает повесить слушатель события на элемент который помечен как ref
    //вот так через жопу это делается
    useEffect(() => {
        const element = props.messagesAreaRef.current;
        console.log(element)
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [props.messagesAreaRef, props.showChat, props.slicer])




    const [messageSending, setMessageSending] = useState(false)

    //функция отправки сообщений в этом чатике
    async function sendMessage() {



        let needNotify = false

        if(!props.allSortedMessages.length) {
            needNotify = true
        } else if(getMinutesBetweenDates(new Date(props.allSortedMessages[0].date), Date.now()) > 720) {
            needNotify = true
        }

        setMessageSending(true)

        console.log(props.answeringMessage.quote?.quote)

        //console.log(store.messagesAbout)

        //этот кусок добавляет айдишник текущего заказа пользователю в список заказов по которым у него были сообщения
        //если такой айдтшник уже добавлен, или если это пишет автор или модератор заказа, то ничего не делаем
        if (!store.messagesAboutIncoming.includes(props.orderDetails._id)
            && props.orderDetails.creator._id !== store.user.id
            && props.orderDetails.moderator !== store.user.id
        ) {

            console.log("Order added to incoming messages list")
            await $api.post('/add_order_about_incoming', {user_id: store.user.id, order_id: props.orderDetails._id})
            store.addMessagesAboutIncoming(props.orderDetails._id)

        }

        // if(!store.messagesAboutOutgoing.includes(props.orderDetails._id) && props.orderDetails.creator._id !== store.user.id) {
        //
        //     await $api.post('/add_order_about_outgoing', {about: props.orderDetails.creator._id, from: store.user.id})
        //     store.addMessagesAboutOutgoing(props.orderDetails._id)
        //
        // }


        //если поле воода не пустое, то...
        if (chatValue !== "") {

            console.log(props.activeUser)


            const msg = {
                isAuthor: props.orderDetails.creator?._id === store.user.id,
                to:
                    props.orderDetails.moderator
                        ?
                        props.orderDetails.creator?._id === store.user.id
                            ?
                            [props.orderDetails.doer._id, props.orderDetails.moderator]
                            :
                            store.user.id === props.orderDetails.doer._id
                                ?
                                [props.orderDetails.creator._id, props.orderDetails.moderator]
                                :
                                [props.orderDetails.creator._id]
                        :
                        props.orderDetails.creator?._id === store.user.id ? [props.activeUser ?? props.orderDetails.doer._id] : [props.orderDetails.creator._id],
                userId: {_id: store.user.id, name: store.user.name, lastName: store.user.lastName},
                about: props.orderDetails._id,
                userName: store.user.name + " " + store.user.lastName,
                text: chatValue,
                read: [],
                date: Date.now(),
                quote: props.answeringMessage.id ?? null,
                quote_date: props.answeringMessage.date,
                quote_text: props.answeringMessage.text,
                quote_userId: props.answeringMessage.sender,
                need_rolling: props.answeringMessage.quote,
                moderator: props.orderDetails.moderator,
                event: 'message',
                needNotify: needNotify,
            }


            console.log(msg)


            //формируем сообщение и постим его по адресу на сервак в БД
            const response = await $api.post(`/chat_send/${props.orderDetails._id}`, msg)
            console.log(response)
            if (response.data.message === "OK") {

                //await props.notifySocket.current.send(JSON.stringify(msg));

                //ниже магия которая крутит окно сообщений с небольшой задержкой вниз после отправки сообщения
                // const msgBox = document.getElementsByClassName("message-box")[0]
                //
                // setTimeout(() => {
                //     myRef.current.scrollTo(0, 0)
                // }, 100)

                //тут блок который отвечает за уведомления
                //если создатель заказа отправляет уведомление, то оно формируется так..
                if (props.isAuthor) {

                    const notify = {
                        toCreator: false,
                        from: props.orderDetails.moderator && props.activeUser === props.orderDetails.doer._id
                                ?
                                "moderator"
                                :
                                props.orderDetails.creator._id,
                        to: props.activeUser,
                        about: props.orderDetails._id,
                        event: 'notification',
                    }

                    //и отправляется через вебсокет
                    //await props.notifySocket.current.send(JSON.stringify(notify));
                    console.log("Отправили нотифайку на сервак от автора")

                } else {

                    //а тут тоже самое если это сообщение отправил исполнитель
                    const notify = {
                        toCreator: true,
                        from:  props.orderDetails.moderator && store.user.id === props.orderDetails.doer._id
                                ?
                                "moderator"
                                :
                                store.user.id,
                        to: props.orderDetails.creator._id,
                        about: props.orderDetails._id,
                        event: 'notification',
                    }

                    //await props.notifySocket.current.send(JSON.stringify(notify));
                    //console.log("Отправили нотифайку на сервак от исполнителя")
                }

                if(props.orderDetails.moderator
                    && (store.user.id === props.orderDetails.creator._id || store.user.id === props.orderDetails.doer._id)
                ) {
                    const moder_notify ={
                        toModerator: true,
                        from: store.user.id,
                        to: props.orderDetails.moderator,
                        about: props.orderDetails._id,
                        event: 'moder_notification',
                    }

                    //await props.notifySocket.current.send(JSON.stringify(moder_notify));
                }

                //очищаем поле ввода текста
                setChatValue("")
                props.setAnsweringMessage({text: null, id: null, date: null, self: null, quote: null, sender: null})


            }


            if(response.data.message === "absent") {
                props.setErrorMessage({show: true, text: "Заказ удалён, вы будете перемещены в ленту"})
                setTimeout(() => {
                    props.navigate('/order-feed')
                }, 3000)
            }

            if(response.data.message === "error") {
                props.setErrorMessage({show: true, text: "Ошибка сервера"})
            }


        }
        setMessageSending(false)
    }

    //эта функция считает сколько сейчас активных пользователей задают вопросики по данному заказу
    function countOfAgents(messageList) {

        console.log("COUT OF AGENTS START")
        //тут я использовал функцию reduce
        //из массива всех сообщений по данному заказу она собирает уникальные айдишники тех кто писал сообщения
        //при этом пропуская айди создателя заказа, и скидывает их в переменную chatAgents
        //принцип ее работы приходится по сто раз понимать заново, и у меня есть об этом запись в блокнотике
        //как то так))
        messageList.reduce((acc, item) => {

            if (acc[item.userId] && !item.isAuthor) {
                acc[item] += 1
            } else if (!item.isAuthor) {
                acc[item.userId] = 1
                chatAgents.push([item.userId, item.userName])
            }

            return acc

        }, {})

        //тут обновляем стейт запихивая в него всех участников диалога
        setDialogUsers(chatAgents)


    }



    //это функция элемент в элементе, окошко со всеми сообщениями диалога
    //и она используется только когда диалоги заказа смотрит его создатель
    function hostMsgBox(dialog_user_id) {


        return <div
            className={"message-box-native-wrapper"}
            ref={observedAreaRef}

            style={
                {width: props.orderDetails.creator?._id !== store.user.id || props.orderDetails.doer
                        ?
                        "100%"
                        :
                        "72%"
                }
            }
        >
            {showArrowDown
                ?
                <div
                    className={`fixed-arrow-moder`}
                    onClick={() => {
                        console.log("Давайте скроллить!")
                        downArrowAction()
                    }}
                >
                    <img src={SvgArrowDown} width={"35"}/>
                </div>
                :
                null
            }
            <div
                className={`message-box-native-under ${props.messageListToRoll.length < 10 ? "message-box-background" : ""}`}
                id={"message-box"}
                ref={props.messagesAreaRef}


            >
            <div className={`message-box-native-under ${props.messageListToRoll.length >= 10 ? "message-box-background" : ""}`}>

                 {props.messageListToRoll}

            </div>
        </div>
        </div>
    }

    // function hostMsgBox(dialog_user_id) {
    //
    //     return <div className={"message-box"} style={{width: props.doer ? "100%" : "72%"}} >
    //
    //         {/*плюс обертка для каждого отдельного сообщения в этом диалоге*/}
    //         {props.chatMessagesMain.map(message => {
    //
    //                 //этот иф предотвращает отображение сообщения в чате заказа к которому данное сообщение не принадлежит
    //                 if(message.about !== props.orderDetails._id) {
    //
    //                     return null
    //                 }
    //                 //это IF для первого сообщения при отправке заявки, его не должно быть видно
    //                 if (message.text === "#####") {
    //                     return null
    //                 }
    //
    //                 //если айдишник того кто написал данное сообщение совпадает с айдишником пользователя и..
    //                 //адресат сообщения это активный пользователь (открытый диалог) то разворачиваем такие сообщения
    //                 //справа в чате, это сообщения пользователя, ну то есть того человека который сидит за компом в данный момент))
    //                 if (message.userId === {...store.user}.id && message.to === props.activeUser) {
    //                     return <div className={"chat-message chat-right"} key={message.id}>
    //                         {dateLook(message.id)}
    //                         {message.text}
    //                     </div>
    //
    //                     //тут тернарное условие если айдишник юзера undefined то условие if esle
    //                     // такое - айдишник автора сообщения не равен айдишнику создателя текущего заказа
    //                     //а если айдишник юзера известен, то условие if esle - автор сообщения это activeUser,
    //                     //то есть юзер открытого в данный момент диалога
    //                 } else if (dialog_user_id === undefined ? message.userId !== props.orderDetails.creator._id : message.userId === props.activeUser) {
    //
    //                     //такие сообщения мы отображаем слева, и их написал не тот кто здесь и сейчас сидит за компом
    //                     return <div className={"chat-message chat-left"} key={message.id}>
    //                         <div className={"chat-username"}>{message.userName}</div>
    //                         {dateLook(message.id)}
    //                         {message.text}
    //                     </div>
    //
    //                 } else if(message.userId === props.orderDetails.moderator) {
    //                     return <div className={"chat-message chat-center"} key={message.id}>
    //                         <div className={"chat-username"}>Модератор</div>
    //                         {dateLook(message.id)}
    //                         {message.text}
    //                     </div>
    //
    //                 } else if (Array.isArray(message.to) && message.userId === {...store.user}.id) {
    //                     return <div className={"chat-message chat-right"} key={message.id}>
    //                         {dateLook(message.id)}
    //                         {message.text}
    //                     </div>
    //                 }
    //
    //
    //
    //
    //             }
    //         )}
    //     </div>
    // }


    function dateLook(check) {
        if(new Date(today).toLocaleDateString() === new Date(check).toLocaleDateString()) {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleTimeString(
                    [],{hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        } else {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleDateString(
                    [],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        }
    }


    function cancelAnswering() {
        console.log("SET 3")
        props.setAnsweringMessage({text: null, id: null, date: null, self: null, links: null, quote: null, sender: null})
    }


    async function downArrowAction() {
        props.messagesAreaRef.current.scrollTo({top: 0, behavior: 'smooth'})
        markAsRead("all")

    }


    //это тоже компонент в компоненте, он же чатик целиком
    //я не выношу его в отдельнае компоненты потому что... не знаю, наверное потому что не хочу прокидывать пропсы
    function chat() {

        //тут если заказ смотрит НЕ создатель, то его окошко сообщений растянуто на всю ширину
        if (!props.isAuthor) {
            // console.log("HERE WE ARE")
            // console.log(props.messageListToRoll)

            return <div
                className={"message-box-native-wrapper-doer"}
                ref={observedAreaRef}
                style={{width: "100%"}}
            >
                {showArrowDown
                    ?
                    <div
                        className={`fixed-arrow-user`}
                        onClick={() => {
                            console.log("Давайте скроллить!")
                            downArrowAction()
                        }}
                    >
                        <img src={SvgArrowDown} width={"35"}/>
                    </div>
                    :
                    null
                }
                <div
                    // className={`${props.messageListToRoll.length < 5 ? "message-box-native-empty" : "message-box-native-under"}`}
                    className={`message-box-native-under ${props.messageListToRoll.length < 10 ? "message-box-background" : ""}`}
                    id={"message-box"}
                    ref={props.messagesAreaRef}

                >

            <div className={`message-box-native ${props.messageListToRoll.length >= 10 ? "message-box-background" : ""}`}>

                {/*это мы всё еще внутри DIV-a если что*/}
                {/*тут дальше магия в которя даже я уже могу запутаться,
                        какой впизду разбираться в чужом коде то, алё,*/}
                {props.messageListToRoll}
            </div></div></div>

            //а если автор открыл свой заказ и смотрит, то попадаем в else
        } else {
            //такое разделение сделано потому что у создателя заказа справа появляются кнопочки
            //с именми юзеров, для того чтобы можно было переключать активные диалоги
            //собственно главный DIV это контейнер для всего этого добра
            return <div className={"chat-and-switch-buttons"}>

                {/*если у нас нет активных юзеров или есть только один*/}
                {dialogUsers.length === 1
                    ?
                    // props.activeUser
                    //     ?
                        hostMsgBox()
                        // :
                        // null
                    :
                    // props.activeUser
                    //     ?
                        hostMsgBox(dialogUsers[1])
                        // :
                        // null
                }



                {props.orderDetails.creator?._id !== store.user.id || props.orderDetails.doer
                    ?
                    null

                    :
                    <div className={"switch-button-container"}>
                        {props.userListToRoll}
                      </div>
                }


            </div>
        }
    }


    return (
        <div className={"chat"} style={props.showChat}>

            {chat()}

            {props.answeringMessage.text
                ?
                <h6 className={"margin-top-5"}>В ответ на:</h6>
                :
                null
            }
            {props.answeringMessage.text
                ?
                <div className={"answering-message-wrapper"}>
                    <div className={"answering-message"}>
                        {props.processMessageText(props.textReducer(props.answeringMessage.text))}
                    </div>
                    <button
                        className={"btn inline margin-left-20 cancel-answering-button"}
                        onClick={() => cancelAnswering()}
                    >❌</button>
                </div>

                :
                null
            }
            {props.orderDetails.orderStatus !== "closed"
                ?
                store.user.arbitr && store.user.id !== props.orderDetails?.creator?._id
                && store.user.id !== props.orderDetails?.doer?._id && props.orderDetails.arbitration
                    ?
                    null
                    :
                    <div className={"chat-form"}>
                    <textarea
                        className={"chat-input"}
                        type={"text"}
                        rows={1}

                        onKeyUp={(key) => {
                            if (key.code === "ShiftLeft" || key.code === "ShiftRight") {
                                setShiftEnterPressed(false);
                            }
                        }}

                        onKeyDown={async (key) => {

                            if (key.shiftKey) {
                                setShiftEnterPressed(true);
                            }

                            if (key.code === "Enter" && shiftEnterPressed) {
                                key.preventDefault()
                                await setChatValue(prev => prev + "\n")
                                if(textAreaRef.current) {
                                    textAreaAdjust()
                                }

                            } else if (key.code === "Enter" || key.code === "NumpadEnter") {
                                key.preventDefault()
                                if(!messageSending) {
                                    sendMessage()
                                }
                            } else {
                                //textAreaAdjust()
                            }
                        }
                        }
                        value={chatValue}
                        ref={textAreaRef}
                        onChange={e => {

                            if(chatValue === "" && e.target.value === "\n") {
                                ;
                            } else {
                                setChatValue(e.target.value)
                                //textAreaAdjust()
                            }
                        }}
                    />

                        <button
                            className={"btn chat-send-btn"}
                            onClick={() => {
                                if(!messageSending) {
                                    sendMessage()
                                }
                            }}
                        >Отправить</button>

                    </div>
                :
                null
            }


        </div>
    );
};

export default observer(OrderChat);