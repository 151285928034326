import React, {useEffect, useState, useContext, useCallback} from 'react';
import Dossier from "./Dossier";
import {Context} from "../index";
import $api, {API_URL} from '../http'
import {Link} from "react-router-dom";
import Loading from './Loading'
import {highlightTextAroundPattern, processTextWithLinksAndHighlight} from "./helpers/repeat_functions";
import SvgQuestionSign from "../img/question-sign.svg"




const UserSearch = ({admin}) => {

    const {store} = useContext(Context)


    const [searchName, setSearchName] = useState("")
    const [searchDiscipline, setSearchDiscipline] =  useState("")
    const [results, setResults] = useState([])

    const [searchTimeout, setSearchTimeout] = useState(null)

    const [errorMessage, setErrorMessage] = useState("")

    const [searching, setSearching] = useState(false)

    const [balanceIncrease, setBalanceIncrease] = useState({})

    const [unreliableSearch, setUnreliableSearch] = useState(false)
    const [oldMode, setOldMode] = useState(false)
    const [reviewsFor, setReviewsFor] = useState()


    const [adminSearchFilter, setAdminSearchFilter] = useState({all: false, banned: false, benefit: false, untrustworthy: false})


    useEffect(() => {
        {admin
            ?
            store.editWhitePointer({place: "Хижина админа:", color: "gold-color"})
            :
            store.editWhitePointer({place: "Поиск людей:"})
        }


    }, [])



    const userSearch = useCallback(startSearch, [adminSearchFilter, searchName, searchDiscipline, unreliableSearch])



    async function startSearch() {

        console.log("Запускаем поиск!")

        if(!unreliableSearch) {

            const splittedString = searchName.trim().split(" ")

            const splitLessTwo = splittedString.some(item => item.length < 2)

            if(
                (searchName.trim().length >= 3 && !splitLessTwo)
                || searchDiscipline.length >= 3
                || Object.values(adminSearchFilter).includes(true)
            ) {
                setErrorMessage()

                console.log("Посылаем запрос")
                const response = await $api.get(`/user_search?name=${searchName.trim()}&discipline=${searchDiscipline.trim()}&my_id=${store.user.id}&filter=${JSON.stringify(adminSearchFilter)}&admin_mode=${admin ? true : false}`)
                console.log(response.data)
                setResults([...response.data.results, ...response.data.unreliableUsers])
                setOldMode(response.data.mode === "old")
                setSearching(false)

            } else {

                console.log("Не посылаем нихуя")

                setResults([])
                setSearching(false)
                setErrorMessage("Нужно больше 3-х символов в запросе")

            }

        }

        // else {
        //
        //     if(searchName.length >= 3) {
        //         setErrorMessage()
        //
        //         console.log("Посылаем запрос")
        //         const response = await $api.get(`/unreliable_user_search?search_string=${searchName.trim()}`)
        //         console.log(response.data)
        //         setResults(response.data)
        //         setSearching(false)
        //
        //     } else {
        //         console.log("Не посылаем нихуя")
        //
        //         setResults([])
        //         setSearching(false)
        //         setErrorMessage("Нужно больше 3-х символов в запросе")
        //     }
        // }
    }


    useEffect(() => {

        console.log("TRIGGER")

        console.log(Object.values(adminSearchFilter))

        if(!searchName.length && !searchDiscipline.length && !Object.values(adminSearchFilter).includes(true)) {
            console.log("TRIGGER 2")
            setResults([])
            setErrorMessage()
            setSearching(false)
        } else {
            console.log("TRIGGER 3")
            clearTimeout(searchTimeout)

            setSearching(true)
            setSearchTimeout(
                setTimeout(() => {
                    userSearch()
                }, 2000)
            )

        }

    }, [searchName, searchDiscipline, adminSearchFilter])


    async function makeModer(id, value) {
        const response = await $api.post('/make_moder', {id, value})

        if (response.status < 400) {
            userSearch()
        }
    }

    async function makeUntrustworthy(id, value) {
        const response = await $api.post('/make_untrastworthy', {id, value})

        if (response.status < 400) {
            userSearch()
        }
    }

    async function makeBanned(id, value) {

        console.log("Переводим бан в статус ", value)

        const response = await $api.post('/make_banned', {id, value})

        if (response.status < 400) {
            userSearch()
        }
    }

    async function makeArbitr(id, value) {
        const response = await $api.post('/make_arbitr', {id, value})

        console.log(response.data)

        if (response.status < 400) {
            userSearch()
        }
    }

    async function makeBenefit(id, value) {
        const response = await $api.post('/make_benefit', {id, value})

        if (response.status < 400) {
            userSearch()
        }
    }

    async function changeBalance(id, value) {

        if(value !== 0) {
            const response = await $api.post('/change_balance', {id, value})

            if (response.status < 400) {
                userSearch()
                setBalanceIncrease({})

            }
        } else {
            setErrorMessage("Нужно указать баланс")
        }

    }



    return (
        <div className={"user-search-wrapper"}>
            {/*{admin*/}
            {/*    ?*/}
            {/*    null*/}
            {/*    :*/}
            {/*    <div className={"user-search-dossier-container"}>*/}
            {/*        <Dossier/>*/}
            {/*    </div>*/}
            {/*}*/}

            <div className={`${admin ? `admin-search-work-place` : `${results.length ? "user-search-work-place upper-search" : "user-search-work-place"}`}`}>
                {/*{admin*/}
                {/*    ?*/}
                {/*    null*/}
                {/*    :*/}
                {/*    <div className={"search-mode-selector"}>*/}
                {/*        <button*/}
                {/*            className={`btn ${unreliableSearch ? "btn-secondary" : "btn-primary" }`}*/}
                {/*            onClick={() => {*/}
                {/*                setUnreliableSearch(false)*/}
                {/*                setResults([])*/}
                {/*            }}*/}
                {/*        >Среди участников</button>*/}
                {/*        <button*/}
                {/*            className={`btn ${!unreliableSearch ? "btn-secondary" : "btn-primary" }`}*/}
                {/*            onClick={() => {*/}
                {/*                setUnreliableSearch(true)*/}
                {/*                setResults([])*/}
                {/*            }}*/}
                {/*        >В базе неблагонадежных</button>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className={"user-search-main-wrapper"}>
                    <div className={"user-search-main"}>

                        <input
                            className={`${admin ? "" : "user-search-query-input"}`}
                            type={"text"}
                            placeholder={"Давайте найдём..."}
                            value={searchName}
                            onChange={(e) => {
                                setAdminSearchFilter({all: false, banned: false, benefit: false})
                                setSearchName(e.target.value)
                            }}
                        />


                        {/*{admin || unreliableSearch*/}
                        {/*    ?*/}
                        {/*    null*/}
                        {/*    :*/}
                        {/*    <input*/}
                        {/*        className={"margin-left-20"}*/}
                        {/*        type={"text"}*/}
                        {/*        placeholder={"Дисциплина"}*/}
                        {/*        value={searchDiscipline}*/}
                        {/*        onChange={(e) => setSearchDiscipline(e.target.value)}*/}
                        {/*    />*/}
                        {/*}*/}
                        {admin
                            ?
                            <div className={"user-search-admin-button-container"}>
                                <button
                                    className={`btn ${adminSearchFilter.all ? "btn-warning" : "btn-secondary"}`}
                                    onClick={() => {
                                        setSearchName("")
                                        setAdminSearchFilter({all: true, banned: false, benefit: false, untrustworthy: false})
                                    }}
                                >Показать всех</button>
                                <button
                                    className={`btn ${adminSearchFilter.banned ? "btn-warning" : "btn-secondary"}`}
                                    onClick={() => {
                                        setSearchName("")
                                        setAdminSearchFilter({all: false, banned: true, benefit: false, untrustworthy: false})
                                    }}
                                >Забаненные</button>
                                <button
                                    className={`btn ${adminSearchFilter.untrustworthy ? "btn-warning" : "btn-secondary"}`}
                                    onClick={() => {
                                        setSearchName("")
                                        setAdminSearchFilter({all: false, banned: false, benefit: false, untrustworthy: true})
                                    }}
                                >Ненадёжные</button>
                                <button
                                    className={`btn ${adminSearchFilter.benefit ? "btn-warning" : "btn-secondary"}`}
                                    onClick={() => {
                                        setSearchName("")
                                        setAdminSearchFilter({all: false, banned: false, benefit: true, untrustworthy: false})
                                    }}
                                >Льготники</button>

                            </div>
                            :
                            null
                        }

                    </div>
                    {admin
                        ?
                        null
                        :
                        <div>
                            <img className={"user-search-tip-image margin-left-10"} src={SvgQuestionSign} width={"35px"}/>
                            <div className={"user-search-tip"}>
                                <p>Вы можете искать людей по имени, выполняемой дисциплине, электронной почте, а также адресу "Вконтакте" и номеру телефона (для неблагонадёжных)</p>
                                <p>Поиск происходит по базам:</p>
                                <ul>
                                    <li>зарегистрированных пользователей</li>
                                    <li>идентифицированных пользователей, сформированной до появления платформы</li>
                                    <li>неблагонадёжных пользователей</li>
                                </ul>
                            </div>
                        </div>
                    }

                </div>



                {results.length
                    ?
                    admin
                        ?
                        searching
                            ?
                            <div className={"margin-top-20"}>
                                <Loading/>
                            </div>

                            :
                            results.map(user => {
                            return (<div className={"admin-founded-user-tab"} key={user.id}>
                                    <div className={"admin-founded-user-ava-name"}>
                                        <img className="avatar-search" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                        <h5 className={"ubuntu"}>{user.online?.status ? "🟢" : "🔴"} {user.name + " " + user.lastName}</h5>
                                    </div>

                                    <div className={"admin-founded-user-buttons-container"}>
                                        <button
                                            className={`btn ${!user.arbitr ? "btn-success" : "btn-warning"}`}
                                            onClick={() => makeArbitr(user.id, !user.arbitr)}
                                        >{!user.arbitr ? "Сделать арбитром" : "Забрать арбитра"}</button>
                                        <button
                                            className={`btn ${user.untrustworthy ? "btn-success" : "btn-warning"}`}
                                            onClick={() => makeUntrustworthy(user.id, !user.untrustworthy)}
                                        >{user.untrustworthy ? "Сделать надёжным" : "Сделать ненадёжным"}</button>
                                        <button
                                            className={`btn ${user.totalBan ? "btn-success" : "btn-danger"}`}
                                            onClick={() => makeBanned(user.id, !user.totalBan)}
                                        >{user.totalBan ? "Разбанить" : "Забанить"}</button>
                                        <button
                                            className={`btn ${user.maintenance ? "btn-danger" : "btn-success"}`}
                                            onClick={() => makeModer(user.id, !user.maintenance)}
                                        >{user.maintenance ? "Забрать модера" : "Сделать модером"}</button>
                                        <button
                                            className={`btn ${!user.benefit ? "btn-success" : "btn-warning"}`}
                                            onClick={() => makeBenefit(user.id, !user.benefit)}
                                        >{!user.benefit ? "Сделать льготником" : "Забрать льготу"}</button>
                                        <Link to={`/user-details/${user.id}`} target={"_blank"}>
                                            <button className={"btn btn-primary"}>Открыть профиль</button>
                                        </Link>
                                    </div>

                                    <div>
                                        <h6>{user.balance} р.</h6>
                                        <input
                                            type={"number"}
                                            value={balanceIncrease[user.id] || ""}
                                            onChange={(e) => setBalanceIncrease(prev => ({
                                                ...prev,
                                                    [user.id]: e.target.value
                                            }))}/>
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={() => changeBalance(user.id, balanceIncrease[user.id])}
                                        >Добавить</button>
                                    </div>
                            </div>
                            )})
                        :
                        // !oldMode
                        //     ?
                        results.map(user => {


                            return (
                                <div className={"founded-user-tab"} key={user.id}>
                                    <div className={"founded-user-tab-top"}>
                                        <img className="avatar-search" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                        <h4 className={"ubuntu"}>{`${user.name}  ${user.lastName ?? ""}${user.id === store.user.id ? " (Это вы)" : ""}`}</h4>
                                        {user.untrustworthy
                                            ?
                                            <h1 title={"Неблагонадёжный пользователь"} className={"ubuntu"}>⚠️</h1>
                                            :
                                            null
                                        }
                                        {user.unregUser || user.unreliable
                                            ?
                                            null
                                            :
                                            <Link
                                                to={`/user-details/${user.id}`}
                                                className={"btn primary-btn-color"}
                                                target={"_blank"}
                                            >Показать</Link>
                                        }

                                    </div>
                                    {user.unregUser || user.unreliable ? <h6 className={"inline"}>Не зарегистрирован на платформе</h6> : null}
                                    {user.unreliable ? <h6 className={"red-for-blue inline"}>Неблагонадёжный партнёр</h6> : null}
                                    {user.unregUser
                                        ?
                                        <div className={"founded-user-tab-bottom"}>
                                            <div className={"unreliable-user-about"}>
                                                <div>{highlightTextAroundPattern(user.about, searchName, "orange", true)}</div>
                                                <h6>email: {user.email}</h6>
                                                {/*<h6>телефон: {user.phone}</h6>*/}
                                                <h6>ВК: <a target={"_blank"} href={user.vkLink}>{user.vkLink}</a></h6>
                                                {user.altvk
                                                    ?
                                                    <h6>доп. ВК: {user.altvk}</h6>
                                                    :
                                                    null
                                                }
                                                {user.email2
                                                    ?
                                                    <h6>доп. email: {user.email}</h6>
                                                    :
                                                    null
                                                }


                                            </div>
                                            {user.reviews?.length
                                                ?
                                                <button
                                                    className={"btn btn-warning unreg-review-btn"}
                                                    onClick={() => {
                                                        setReviewsFor(prev => {
                                                            if(prev === user.vkLink) {
                                                                return null
                                                            } else {
                                                                return user.vkLink
                                                            }

                                                        })
                                                    }}
                                                >
                                                    {reviewsFor === user.vkLink ? "Скрыть" : "Отзывы"}
                                                </button>
                                                :
                                                null
                                            }
                                            {reviewsFor === user.vkLink
                                                ?
                                                user.reviews.map(review => {
                                                    return (<div className={"unreliable-user-about margin-top-10"}>
                                                        <h6 className={"inline-block"}>Оставил:</h6>
                                                        <a className={"margin-left-10"} href={"https://vk.com/id" + review.from}>{"https://vk.com/id" + review.from}</a><br/>
                                                        <h6 className={"inline-block"}>Оценка: {[...Array(Number(review.review_rate))].map((_, index) => (
                                                            <span key={index} role="img" aria-label="star">⭐</span>
                                                        ))}</h6>
                                                        <p>{processTextWithLinksAndHighlight(review.review_text)}</p>
                                                        <h6 className={"inline-block"}>Дата: {review.review_date}</h6>
                                                    </div>)
                                                })
                                                :
                                                null
                                            }

                                        </div>
                                        :
                                        <div className={"founded-user-tab-bottom"}>
                                            {user.founded_discipline
                                                ? <hr/>
                                                : null
                                            }
                                            {user.founded_discipline
                                                ?
                                                <div className={"flex-start"}>

                                                    <h6 className={"black inline margin-right-10"}>Дисциплины: </h6>
                                                    <div>
                                                        {user.founded_discipline.map(dis => {
                                                            return <h6>{highlightTextAroundPattern(dis, searchName, "orange")}</h6>
                                                        })}
                                                    </div>

                                                </div>
                                                :
                                                null
                                            }
                                            {user.foundInInfo
                                                ? <hr/>
                                                : null
                                            }
                                            {user.foundInInfo
                                                ?
                                                <div className={"flex-start"}>
                                                    <h6 className={"black inline margin-right-10"}>Найдено в инфо: </h6><h6 className={"inline"}>{highlightTextAroundPattern(user.foundInInfo, searchName, "orange")}</h6>
                                                </div>
                                                :
                                                null
                                            }
                                            {user.unreliable
                                                ?
                                                <div className={"unreliable-user-about"}>
                                                    <p>{processTextWithLinksAndHighlight(user.about, searchName, "orange", true)}</p>
                                                    <h6>email: {user.email}</h6>
                                                    {user.phone
                                                        ?
                                                        <h6>телефон: {processTextWithLinksAndHighlight(user.phone, searchName, "orange", true)}</h6>
                                                        :
                                                        null
                                                    }
                                                    <h6>ВК: <a target={"_blank"} href={user.vkLink}>{user.vkLink}</a></h6>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    }

                                    

                                </div>
                            )
                        })
                            // :
                            // results.map(user => {
                            //     return (
                            //         <div className={"founded-user-tab"} key={user.id}>
                            //             <div className={"founded-user-tab-top"}>
                            //                 <img className="avatar-search" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                            //                 <h4 className={"ubuntu"}>{`${user.name} ${user.lastName ?? ""}`}</h4>
                            //
                            //             </div>
                            //             <h6>(не зарегистрирован на платформе)</h6>
                            //             <div className={"founded-user-tab-bottom"}>
                            //                 <div className={"unreliable-user-about"}>
                            //                     <p>{highlightTextAroundPattern(user.about, searchName, "orange", true)}</p>
                            //                     <h6>email: {user.email}</h6>
                            //                     {/*<h6>телефон: {user.phone}</h6>*/}
                            //                     <h6>ВК: <a target={"_blank"} href={user.vkLink}>{user.vkLink}</a></h6>
                            //                     {user.altvk
                            //                         ?
                            //                         <h6>доп. ВК: {user.email}</h6>
                            //                         :
                            //                         null
                            //                     }
                            //                     {user.email2
                            //                         ?
                            //                         <h6>доп. email: {user.email}</h6>
                            //                         :
                            //                         null
                            //                     }
                            //
                            //
                            //                 </div>
                            //
                            //              </div>
                            //             {user.reviews?.length
                            //                 ?
                            //                 <button
                            //                     className={"btn btn-warning unreg-review-btn"}
                            //                     onClick={() => {
                            //                         setReviewsFor(prev => {
                            //                             if(prev === user.vkLink) {
                            //                                 return null
                            //                             } else {
                            //                                 return user.vkLink
                            //                             }
                            //
                            //                         })
                            //                     }}
                            //                 >
                            //                     {reviewsFor === user.vkLink ? "Скрыть" : "Отзывы"}
                            //                 </button>
                            //                 :
                            //                 null
                            //             }
                            //             {reviewsFor === user.vkLink
                            //                 ?
                            //                 user.reviews.map(review => {
                            //                 return (<div className={"unreliable-user-about margin-top-10"}>
                            //                     <h6 className={"inline-block"}>Оставил:</h6>
                            //                     <a className={"margin-left-10"} href={"https://vk.com/id" + review.from}>{"https://vk.com/id" + review.from}</a><br/>
                            //                     <h6 className={"inline-block"}>Оценка: {[...Array(Number(review.review_rate))].map((_, index) => (
                            //                         <span key={index} role="img" aria-label="star">⭐</span>
                            //                     ))}</h6>
                            //                     <p>{review.review_text}</p>
                            //                     <h6 className={"inline-block"}>Дата: {review.review_date}</h6>
                            //                 </div>)
                            //                 })
                            //                 :
                            //                 null
                            //             }
                            //
                            //         </div>
                            //     )
                            // })

                    :
                    searchName.length >= 3 || Object.values(adminSearchFilter).includes(true)
                        ?
                        searching
                            ?
                            <div className={"margin-top-20"}>
                                <Loading/>
                            </div>
                            :
                            <div className={"search-empty-tab"}>
                                <h4 className={"ubuntu no-margin"}>Поиск не дал результатов...</h4>
                            </div>
                        :
                        errorMessage
                            ?
                            <div className={"search-empty-tab"}>

                                {errorMessage
                                    ?
                                    <h5 className={"ubuntu red-for-blue no-margin"}>{errorMessage}</h5>
                                    :
                                    // <h4 className={"ubuntu no-margin"}>Давайте найдём!</h4>
                                    null
                                }
                            </div>
                            :
                            null

                }

            </div>

        </div>
    );
};

export default UserSearch;