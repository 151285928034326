import React, {useState, useContext, useEffect} from 'react';
import $api from "../http"
import {Context} from "../index";
import EmptyMessage from "./helpers/EmptyMessage";


const PayForm = () => {


    const {store} = useContext(Context)
    const [amount, setAmount] = useState(0)
    const [payments, setPayments] = useState([])

    const [overPrice, setOverPrice] = useState(false)
    const [loading, setLoading] = useState(false)


    function priceHandler(number) {

        if(number > 10000 || number < 0) {
            return
        }
        console.log(number);
        console.log(typeof number);
        if(number > 10000) {
            setOverPrice(true)
        } else {
            setOverPrice(false)
        }

        // Убираем все символы, не являющиеся цифрой, +, -, . или e
        const sanitizedValue = number.replace(/[^\d+\-.e]/g, '');

        setAmount(sanitizedValue)

        // // Убираем ведущий ноль, если он есть
        // const preparedValue = sanitizedValue.replace(/^0+/, '');
        //
        // // Ограничиваем количество знаков после запятой до двух
        // const decimalIndex = preparedValue.indexOf('.');
        // if (decimalIndex !== -1) {
        //     const integerPart = preparedValue.slice(0, decimalIndex);
        //     const decimalPart = preparedValue.slice(decimalIndex + 1, decimalIndex + 3);
        //     const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
        //
        //     // Если десятичная часть начинается с точки, заменяем её на "0."
        //     // Если десятичная часть начинается с нуля, заменяем ведущий ноль на "0."
        //     setAmount(formattedValue.replace(/^\./, '0.'));
        // } else {
        //     // Если целая часть начинается с нуля, заменяем ведущий ноль на "0"
        //     setAmount(preparedValue.replace(/^0+/, '0'));
        // }
    }





    useEffect(() => {
        store.editWhitePointer({place: "Касса:"})
    }, [])

    useEffect(() => {

        async function getPayments() {

            const getted_payments = await $api.get(`/get_payments/${store.user.id}`)
            console.log(getted_payments.data)
            setPayments(getted_payments.data)

            for (let payment in getted_payments.data) {
                if(getted_payments.data[payment].status === "pending") {

                    setTimeout(() => {
                        getPaymentsStatus()
                    }, 5000)
                    break
                }
            }
        }

        getPayments()

    }, [])


    async function getPaymentsStatus(e) {
        e?.preventDefault()
        setLoading(true)
        const response = await $api.get(`/update_pay_status/${store.user.id}`)
        console.log(response.data)
        setPayments(prev => response.data.payments)
        store.setBalance(response.data.balance)
        setLoading(false)

    }


    async function payForService(e) {
        e.preventDefault()
        if (amount > 0) {
            console.log(amount)

            let preparedAmount

            // Убираем ведущий ноль, если он есть
            const preparedValue = amount.replace(/^0+/, '');

            // Ограничиваем количество знаков после запятой до двух
            const decimalIndex = preparedValue.indexOf('.');
            if (decimalIndex !== -1) {
                const integerPart = preparedValue.slice(0, decimalIndex);
                let decimalPart = preparedValue.slice(decimalIndex + 1, decimalIndex + 3);
                // Если дробная часть отсутствует или состоит из одного знака, добавляем соответствующее количество нулей
                if (decimalPart.length === 0) {
                    decimalPart = '00';
                } else if (decimalPart.length === 1) {
                    decimalPart += '0';
                }

                const formattedValue = `${integerPart}.${decimalPart}`;
                // Если десятичная часть начинается с точки, заменяем её на "0."
                // Если десятичная часть начинается с нуля, заменяем ведущий ноль на "0."
                preparedAmount = formattedValue.replace(/^\./, '0.')
            } else {
                // Если целая часть начинается с нуля, заменяем ведущий ноль на "0"
                const formattedValue = preparedValue.replace(/^0+/, '0')
                preparedAmount = `${formattedValue}.00`
            }

            console.log(preparedAmount)

            const response = await $api.post('/payments',
                {
                    user_id: store.user.id,
                    amount: preparedAmount,
                    clientEmail: store.user.email
                }
            )
            window.location.replace(response.data.confirmation.confirmation_url);

        } else {
            console.log("Мало много денег")
        }
    }





    return (

        <div className={"pay-form-wrapper"}>
            <EmptyMessage
                title={"Внесение оплаты за сервис"}
                message={
                    <div>
                        <p>Просто напишите желаемую сумму, далее касса подскажет что делать</p>
                        <input
                            type={"number"}
                            value={amount}
                            onChange={(e) => {
                                priceHandler(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if(e.key === "Enter" && 10001 > amount > 0) {
                                    payForService(e)
                                }
                                if (e.key === '+' || e.key === '-' || e.key === 'e') {
                                    e.preventDefault(); // Предотвращаем ввод символа '+'
                                }
                                if((e.key === "." || e.key === ",") && amount === "") {
                                    e.preventDefault()
                                }
                            }}
                        />
                        <button
                            type="submit"
                            className="btn primary-btn-color submit-order-btn margin-left-20"
                            onClick={(e) => {
                                if(10001 > amount > 0) {
                                    payForService(e)
                                }
                            }}
                        >Заплатить</button>
                        <button

                            className="btn primary-btn-color submit-order-btn margin-left-20"
                            onClick={(e) => getPaymentsStatus(e)}
                        >Обновить</button>

                        {loading
                            ?
                            <img src={require('../img/spinner_ring.gif')} height={"35px"} className={"margin-left-20"}/>
                            :
                            null
                        }

                        <h4>{`Баланс: ${store.balance}`} ₽</h4>
                        <h4>Доступ <span className={store.user.paymentBlock ? "red" : "green-color" }>{`${ store.user.paymentBlock ? "закрыт" : "открыт" }`}</span></h4>
                        {overPrice
                            ?
                            <h4 className={"red"}>Максимальная сумма 10000</h4>
                            :
                            null
                        }
                    </div>

                }

            />
            <table className={"table pays"}>
                <thead>
                <tr>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Назначение</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                {payments?.map(payment => {
                    return(


                        <tr key={payment._id}>
                            <td>{new Date(payment.date).toLocaleDateString()}</td>
                            <td>{payment.amount}</td>
                            <td>{payment.description === "regular"
                                    ?
                                    "пополнение"
                                    :
                                    payment.description === "debit"
                                        ?
                                        "списание"
                                        :
                                        null
                            }</td>
                            {payment.status === null ? <td className={"green-color"}>проведен</td> : null}
                            {payment.status === "pending" ? <td>обработка</td> : null}
                            {payment.status === "canceled" ? <td className={"red"}>отменен</td> : null}
                            {payment.status === "succeeded" ? <td className={"green-color"}>проведен</td> : null}

                        </tr>


                    )})}
                </tbody>
            </table>


        </div>



    );
};

export default PayForm;