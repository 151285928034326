import React, {useContext} from 'react';
import {Context} from "../index";


const FirstChoice = () => {

    const {store} = useContext(Context)

    return (
        <div className={"empty-message-holder"}>
            <form className="registration-form">
                <h2 className={"ubuntu"}>Выбор прост...</h2><br/>


                <button className="ubuntu btn primary-btn-color main-login" onClick={() => store.setChoice("sign")}>...вход</button>
                <button className="ubuntu btn primary-btn-color main-login" onClick={() => store.setChoice("register")}>...регистрация</button>
            </form>

        </div>
    );
};

export default FirstChoice;