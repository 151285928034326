import React, {useState, useContext, useEffect} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {useForm} from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import $api from "../http";


const Login = () => {

    //тут немножко все переигралось и теперь состояния логина и пароля хранятся в store, это объект
    //контекста, он доступен любому компоненту в котором есть строчка как вот эта ниже
    const {store} = useContext(Context)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Обновляем состояния из автоматически заполненных данных браузера
    useEffect(() => {
        const usernameFromBrowser = document.getElementById('email').value;
        const passwordFromBrowser = document.getElementById('password').value;
        store.setMail(usernameFromBrowser);
        store.setPassword(passwordFromBrowser);
    }, []);

    //а этот стейт следит за сообщениями об успехе либо ошибке отправки формы на сервер
    const [orderStatus, setOrderStatus] = useState({color: "", message: ""})


    //создаем переменную из хука useForm, он используется для валидации форм
    const {
        //эта штука позволит зарегистрировать поле формы (там ниже смотри, всё понятно будет)
        register,
        //это видимо как то отвечает за ошибки, там внизу тоже все это есть
        formState: {errors},
        //это видимо хрень которая отлавливает сабмит и что то делает)))
        handleSubmit,
        clearErrors,
    } = useForm({mode: "onSubmit"});//это режим проверки данных только после попытки сабмита, есть и другие если надо




    const [passResetFlag, setPassResetFlag] = useState(false)


    function handleLoginSubmit() {
        store.login(store.mail, store.password)

    }


    function passReset(e) {
        e.preventDefault()
        setPassResetFlag(true)
    }



    const [status, setStatus] = useState()
    const [attempt, setAttempt] = useState(false)
    const [waiting, setWaiting] = useState(true)

    async function makeResetLink(e) {
        e.preventDefault()

        setAttempt(true)
        const response = await $api.post('/password_reset', {email: store.mail})



        if(response.data === "OK") {
            setStatus("OK")
            setWaiting(false)
        } else {
            setStatus("FAIL")
        }


    }





    return (
        <div className={"empty-message-holder"}>
            <h1>{store.isAuth ? `Пользователь авторизован  ${store.user.email}` : null}</h1>
            <form className="registration-form" onSubmit={handleSubmit(handleLoginSubmit)}>

                {!passResetFlag
                    ?
                    <h2 className={"ubuntu"}>Кто ты, воин?</h2>
                    :
                    waiting
                        ?
                        <h3 className={"ubuntu"}>А голову дома не забыл? =)</h3>
                        :
                        null
                }
                {/*<br/>*/}
                {store.isTriedToSign ? <div><ErrorMessage color="red" message={store.errorMessage}/></div> : null}
                <div className="error-container">{errors?.email && <p className="error-message red-for-blue">{store.mail === "" ? "Введите почту" : "Это не адрес почты"}</p>}</div>
                {waiting
                    ?
                    <input {...register('email', {required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                           onChange={(e) => {
                               clearErrors("email")
                               store.setErrorMessage("")
                               setAttempt(false)
                               setStatus()
                               store.setMail(e.target.value.toLowerCase().trim())
                           }}
                           value={store.mail}
                           placeholder={"email"}
                           id={"email"}
                           type={"text"}
                           autoFocus
                    />
                    :
                    null
                }

                {passResetFlag
                    ?
                    attempt
                        ?
                        status === "OK"
                            ?
                            <div className={"centrator-of-one"}>
                                <h2 className={"ubuntu green align-text-center"}>Ссылка сброса пароля на почте</h2>
                            </div>
                            :
                            waiting
                                ?
                                status === "FAIL"
                                    ?
                                    <div>
                                        <h2 className={"ubuntu red"}>Такой почты нет</h2>
                                    </div>
                                    :
                                    <div>
                                        <h2 className={"ubuntu red"}>Проверяем данные</h2>
                                    </div>
                                :
                                null

                        :
                        <div>
                            <button className="ubuntu btn primary-btn-color main-login" onClick={(e) => makeResetLink(e)}>Сбросить</button>
                            <button className="ubuntu btn primary-btn-color main-login" onClick={(e) => {
                                e.preventDefault()
                                setPassResetFlag(false)
                            }}>Я вспомнил...</button>
                        </div>

                    :
                    <div>
                        <input
                            placeholder="password"
                            id={"password"}
                            onChange={(e) => {
                                store.setPassword(e.target.value)
                                store.setErrorMessage("")
                            }}
                            value={store.password}
                            type={"password"}
                            style={{width: "100%"}}
                        />
                        <button className="ubuntu btn primary-btn-color main-login" type="submit">Войти</button>
                        <button className="ubuntu btn primary-btn-color main-login" onClick={() => store.setChoice("register")}>... или зарегистрироваться</button>
                        <button className="ubuntu btn primary-btn-color main-login" onClick={(e) => passReset(e)}>... я забыл</button>
                    </div>
                }


            </form>
        </div>
    );
};

export default observer(Login);
