import React, {useState, useContext} from 'react';
import ModalDialog from "./ModalDialog";
import $api from "../../http";
import {Context} from "../../index";


const ReviewMaker = ({doer, creator, discipline, order_id, setErrorMessage, setReviewSuccessFullyMade}) => {

    const {store} = useContext(Context)


    const [activeModal, setActiveModal] = useState("no")
    const [score, setScore] = useState(0)
    const [reviewText, setReviewText] = useState("")




    async function makeReview() {
        console.log("REVIEW MADE")
        console.log(doer)
        console.log(creator)
        console.log(score)


        const response = await $api.post('/publish_review', {
            asAuthor: store.user.id === doer,
            discipline: discipline,
            text: reviewText,
            score: score,
            to: store.user.id === creator ? doer : creator,
            from: store.user.id,
            order_id: order_id,
        })
        if (response.status >= 400) {
            setErrorMessage({show: true, text: response.data.message})
        } else {
            setReviewSuccessFullyMade(true)
        }
    }


    function reviewTextHandler(text) {
        if(text.length <= 1000) {
            setReviewText(text)
        } else {
            setReviewText(text.slice(0, 1000))
        }
    }



    return (
        <div className={"review-main-wrapper"}>
            <h5 className={"ubuntu"}>Оставьте отзыв о сотрудничестве с {store.user.id === creator ? "автором" : "заказчиком"} по данной работе:</h5>
            <textarea
                className={"review-text-area"}
                placeholder={"Тут можно и нужно написать отзыв"}
                onChange={(e) => reviewTextHandler(e.target.value)}
                onKeyDown={(e) => {

                    if(reviewText.length > 999
                        && e.key !== "Backspace"
                        && e.key !== "Delete"
                        && e.key !== "ArrowUp"
                        && e.key !== "ArrowDown"
                        && e.key !== "ArrowLeft"
                        && e.key !== "ArrowRight"
                        && e.key !== "Home"
                        && e.key !== "End"
                    ) {
                        e.preventDefault()
                    }
                }}
                value={reviewText}
            />
            <div className={"review-symbol-counter"}>
                <h6 className={`${reviewText.length >= 50 ? "" : "red-for-blue" }`}>Не менее 50 символов</h6>
                <h6 className={`review-length-counter ${reviewText.length === 1000 ? "red" : null}`}>{reviewText.length}/1000</h6>
            </div>

            <div className={"score-n-sent-btn-container"}>
                <select
                    className={"review-score-selector"}
                    defaultValue={""}
                    onChange={(e) => setScore(Number(e.target.value.slice(0, 1)))}
                >
                    <option value={""} disabled>Оценка</option>
                    <option>5⭐⭐⭐⭐⭐</option>
                    <option>4⭐⭐⭐⭐</option>
                    <option>3⭐⭐⭐</option>
                    <option>2⭐⭐</option>
                    <option>1⭐</option>
                </select>
                <button
                    disabled={score === 0 || reviewText.length < 50}
                    className={"btn primary-btn-color review-send-btn"}
                    onClick={() => {
                        setActiveModal("progress")
                        setTimeout(() => {
                            setActiveModal("active")
                        }, 100)
                    }}
                >Отправить</button>
            </div>

            <hr/>
            <ModalDialog
                active={activeModal}
                setActive={setActiveModal}
                action={() => makeReview()}
                redMessage={`Вы собираетесь нажать ДА или НЕТ =)`}
                message={ `Уверены что хотите продолжить?`}
                purpose={"choice"}
            />
        </div>
    );
};

export default ReviewMaker;