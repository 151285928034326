import React, {useEffect, useContext} from 'react';
import Loading from "../Loading";
import {useNavigate, useParams} from "react-router-dom";
import $api from "../../http"
import {Context} from "../../index";

const Activator = () => {

    const {store} = useContext(Context)
    const navigate = useNavigate()
    const params = useParams()
    const activationLink = params.link

    async function activate(link) {
        console.log(link)
        const response = await $api.get(`/activate/${link}`)
        if(response.data === "OK") {
            await store.checkAuth()
            navigate(`/personal_data`)
        }
    }


    useEffect(() => {
        if(activationLink) {
            activate(activationLink)
        }
    }, [activationLink])


    return (
        <div>
            <Loading/>
        </div>
    );
};

export default Activator;