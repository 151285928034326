import React from 'react';

function Footer() {

    let year = new Date().getFullYear()


    return (
        <div className="footer">

            <p className="copy">проект Союза Фрилансеров</p> <p className="copy"><img className={"margin-right-10 margin-left-10"} src={require("../img/transparent logo.png")} width={"23px"}/> © 2017 -  {year} ... и дальше...</p>
        </div>
    )
}

export default Footer;