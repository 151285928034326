import React, {useState, useEffect, useContext, useRef} from 'react';
import OrderShield from "./OrderShield";
import {Context} from "../index";
import {observer} from 'mobx-react-lite';
import $api from '../http'
import {log} from "util";
import Dossier from "./Dossier";
import {assignWidth} from "./helpers/repeat_functions";


const OrderFeed = (props) => {

    const {store} = useContext(Context)
    const [orderList, setOrderList] = useState([])
    const [newestOrderList, setNewestOrderList] = useState([])
    const [message,setMessage] = useState('Лента пуста')

    const [totalCount, setTotalCount] = useState(1)
    const [currentAmount, setCurrentAmount] = useState({start: 0, finish: 5})


    const [fetching, setFetching] = useState(true)
    const [stopFetching, setStopFetching] = useState(false)
    const [showEmpty, setShowEmpty]  = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const [withoutAP, setWithoutAP] = useState(localStorage.getItem('order-feed-filter-ap') === "true")
    const [showMine, setShowMine] = useState(localStorage.getItem('order-feed-filter-mine') === "true")
    const [onlyMyDisciplines, setOnlyMyDisciplines] = useState(localStorage.getItem('order-feed-filter-disciplines') === "true")
    const [disciplineState, setDisciplineState] = useState("")
    const [creatorState, setCreatorState] = useState("")
    const [dateSort, setDateSort] = useState("new")

    const [isFiltered, setIsFiltered] = useState(false)
    const [selectKey1, setSelectKey1] = useState(0)



    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', scrollHandler)

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [totalCount, orderList])


    function scrollHandler(e) {

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)
        }

        if (e.target.documentElement.scrollTop < 250) {
            store.setFeedBlinker(false)
        }

         //console.log('Общая высота', e.target.documentElement.scrollHeight)
         //console.log('Текущее положение', e.target.documentElement.scrollTop)
         //console.log('Высота видимой области', window.innerHeight)

    }



    useEffect(() => {
        store.editWhitePointer({place: "Лента заказов:"})
        store.setFeedBlinker(false)



        window.addEventListener('resize', assignWidth("order-sorting-section-wrapper", "order-sorting-section", 0.9))


        const handleScroll = () => {
            const orderSortingSection = document.getElementById('order-sorting-section');
            const orderFeed = document.getElementById('order-feed');
            const orderFeedRect = orderFeed.getBoundingClientRect();
            const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
            const distanceToLeft = orderFeedRect.left + document.documentElement.scrollLeft;
            const orderFeedWidth = orderFeed.offsetWidth;

            // console.log((-scrollLeft + orderFeedWidth))
            // console.log(orderFeedRect)

            orderSortingSection.style.left = (-scrollLeft + orderFeedWidth)+ distanceToLeft + 'px';
        }


        window.addEventListener('scroll', handleScroll);



        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', assignWidth);
        };





    }, [])



    async function fetchOrders(sort) {

        await setShowSpinner(true)

        const response = await $api.post("/order-feed", {
            userid: store.user.id,
            amount: currentAmount,
            filter: {disciplineState, creatorState, dateSort, withoutAP, showMine, onlyMyDisciplines}

        })

        console.log(response.data)

        if (response.status >= 400) {
            setMessage('Сервер временно недоступен')
        } else {


            if (currentAmount.start === 0) {

                await setOrderList(prev => response.data.orders)
            } else {
                await setOrderList(prev => [...prev, ...response.data.orders])
            }

            //await setTotalCount(prev => response.data.totalCount)
            await setCurrentAmount({start: currentAmount.finish, finish: currentAmount.finish + 5})
            await setFetching(false)
            if(response.data.orders.length === 0) {
                await setStopFetching(true)
            }

            if(response.data.orders.length === 0) {
                await setStopFetching(true)
            }


        }
        await setShowSpinner(false)
    }



    useEffect(() => {

        console.log(showMine)

        if(fetching && !stopFetching) {
            console.log('DO FETCH')
            fetchOrders()
        } else {
            console.log('NOT FETCH')
        }



        //ниже старая версия функции, работающая с помощью fetch, оставлю для истории

    //     async function getOrders() {
    //         const response = await fetch("/order-feed",
    //             {headers: {
    //                             userid: {...store.user}.id,
    //                             Authorization: `Bearer ${localStorage.getItem('token')}`
    //                             }
    //             });
    //         if (response.status >= 400) {
    //             setMessage('Сервер временно недоступен')
    //         } else {
    //
    //         const json = await response.json();
    //         console.log(json)
    //         await setOrderList(json)
    //
    //         }
    // };
        //getOrders()

    //},[fetching])
    },[store.update, fetching, showMine, onlyMyDisciplines])


    async function fetchUpdates(order) {

        let response

        //если есть айдишник заказа, то достаем конкретный заказ
        if(order) {
            response = await $api.get(`/order-details/${order}`)
            console.log(response)
            if (response.status >= 400) {
                setMessage('Какая то ошибка')
            } else {
                await setNewestOrderList(prev => [response.data, ...prev])
                //await setTotalCount(prev => response.data.totalCount)
            }

            //если айдишника нет, то достаем самый свежий заказ
        } else {
            response = await $api.post("/order-feed", {userid: store.user.id, amount: {start: 0, finish: 1}})
            console.log(response)

            if (response.status >= 400) {
                setMessage('Какая то ошибка')
            } else {
                await setNewestOrderList(prev => [...response.data.orders, ...prev])
                await setTotalCount(prev => response.data.totalCount)
            }
        }

        store.setFeedBlinker(true)

        setTimeout(() => {
            if (window.scrollY < 250) {
                store.setFeedBlinker(false)
            }

        }, 3000)

        setTimeout(() => {
            if (window.scrollY < 250) {
                store.setFeedBlinker(false)
            }

        }, 3000)

    }


    //Это эффект обновляющий ленту при появлении в ней новых заказов от других участников
    useEffect(() => {

        console.log('WE ARE GOING TO MAKE SOME ACTIONS 1')
        if(store.feedUpdater > 0 && currentAmount.start !== 0) {

            fetchUpdates()
            console.log(window.screenY)

        }

    }, [store.feedUpdater])


    async function updatePriceFor(id) {
        try {
            const response = await $api.get(`/get_order_details_by_id?order_id=${id}`);
            const newData = response.data;
            setOrderList(prevList => prevList.map(item => (item._id === id ? newData : item)));
            //store.setFeedBlinker(true);
        } catch {
            setMessage('Какая-то ошибка');
        }
    }


    useEffect(() => {

        console.log('WE ARE GOING TO MAKE SOME ACTIONS 123321')
        if(store.newPriceFor !== "") {
            console.log("getting - ", store.newPriceFors)
            updatePriceFor(store.newPriceFor)


        }

    }, [store.newPriceFor])



    async function getOrderById(id) {

        try {
            const response = await $api.get(`/get_order_details_by_id?order_id=${id}`)
            console.log(response)
            await setNewestOrderList(prev => [response.data, ...prev])
            store.setFeedBlinker(true)
        } catch {
            setMessage('Какая то ошибка')
        }
    }





    useEffect(() => {

        console.log('WE ARE GOING TO MAKE SOME ACTIONS 123')
        if(props.newOrderAdded) {
            console.log("getting - ", props.newOrderAdded)
            getOrderById(props.newOrderAdded)


        }

    }, [props.newOrderAdded])


    useEffect(() => {

        console.log('WE ARE GOING TO MAKE SOME ACTIONS 2')
        if(store.returnToFeed !== "") {

            fetchUpdates(store.returnToFeed)
            console.log(window.screenY)

        }

    }, [store.returnToFeed])



    useEffect(() => {


        async function updateOrdersState() {
            const updatedStateOfOrders = orderList.filter((order) => {
                if(order._id === store.removeFromFeed) {
                    return false
                } else {
                    return true
                }
            })

            setOrderList(prev => updatedStateOfOrders)

        }

        if(store.removeFromFeed) {
            console.log('Убираем из ленты - ', store.removeFromFeed)
            updateOrdersState()

        }




    }, [store.removeFromFeed])



    const orderCounterRef = useRef(0)

    // useEffect(() => {
    //
    //     orderCounterRef.current = 0
    //
    // }, [creatorState, disciplineState, withoutAP, showMine])


    // useEffect(() => {
    //
    //     async function fetchByDate(sort) {
    //
    //         await setNewestOrderList([])
    //
    //         await setCurrentAmount({start: 0, finish: 5})
    //
    //         const response = await $api.post("/order-feed", {
    //             userid: store.user.id,
    //             amount: {start: 0, finish: 100},
    //             dateSort: sort,
    //
    //         })
    //
    //         console.log(response.data)
    //
    //         if (response.status >= 400) {
    //             setMessage('Сервер временно недоступен')
    //         } else {
    //
    //             await setOrderList(prev => response.data.orders)
    //             await setTotalCount(prev => response.data.totalCount)
    //             await setCurrentAmount({start: currentAmount.finish, finish: currentAmount.finish + 5})
    //
    //
    //
    //         }
    //
    //     }
    //
    //         fetchByDate(dateSort)
    //
    // }, [dateSort])



    function clearFilter() {
        setCreatorState("")
        setDisciplineState("")
        setShowMine(false)
        setOnlyMyDisciplines(false)
        setWithoutAP(false)
        localStorage.setItem('order-feed-filter-ap', 'false')
        localStorage.setItem('order-feed-filter-mine', 'false')
    }


    const [filterTrigger, setFilterTrigger] = useState(0)

    let filterCounter

    async function setFilterTriggerTimer() {

        await clearInterval(filterCounter)

        filterCounter = setTimeout(() => {
            setStopFetching(false)
            setFilterTrigger(filterTrigger + 1)
        }, 1000)

    }


    useEffect(() => {

        console.log("FILTER TRIGGER IS - ", filterTrigger)

        console.log("TRIPPLE EFFECT")

        if (creatorState || dateSort === "old" || disciplineState) {
            console.log("GUESS RIGHT")
            setIsFiltered(true)
        } else {
            setIsFiltered(false)
        }

        setCurrentAmount({start: 0, finish: 5})
        setFilterTriggerTimer()



    }, [creatorState, dateSort, disciplineState, withoutAP, showMine, onlyMyDisciplines])


    useEffect(() => {
        if (filterTrigger > 1) {
            console.log("FILTER TRIGGER EFFECT")
            fetchOrders()
        }


    }, [filterTrigger])


    async function setFilter(value, purpose) {


        await setCurrentAmount({start: 0, finish: 5})



        if(purpose === "date") {
            console.log(`Setting ${purpose} state`)
            await setDateSort(value)
        }

        if(purpose === "reset") {

            await setShowEmpty(false)
            await setStopFetching(false)
            await setDateSort('new')
            await setCreatorState("")
            await setDisciplineState("")
            await setWithoutAP(false)
            await setShowMine(false)
            await setOnlyMyDisciplines(false)
            await setIsFiltered(false)
            await setSelectKey1(selectKey1 + 1)




        } else {
            await setIsFiltered(true)
        }


    }



    return (
        <div className={"order-feed-wrapper"}>
            {/*<div className="main-field">*/}
            {/*<Dossier/>*/}
            <div className="order-feed" id={'order-feed'}>


                {newestOrderList[0]
                    ?
                    newestOrderList.map((order, index) => {


                        return <OrderShield
                            color={"green"}
                            title={order.title}
                            number={order.number}
                            type={order.type}
                            price={order.price}
                            priceOffer={order.priceOffer}
                            discipline={order.discipline ? order.discipline : null}
                            date={order.closestDate ? "Ближайшая дата автора" : new Date(order.deadline).toLocaleDateString()}
                            antiplagiat={order.antiplagiat}
                            marker={props.marker}
                            requests={order.requests}
                            customer={order.creator.name + " " + order.creator.lastName}
                            creationDate={new Date(order.creationDate).toLocaleString([],
                                {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    day: "numeric",
                                    month : 'numeric',
                                    year: 'numeric'
                                })}
                            id={order._id}
                            creatorId={order.creator._id}
                            doer={order.doer}
                            key={index}
                        />
                    })
                    :
                    null
                }


                {orderList[0]
                    ?


                    orderList.map((order, index) => {

                       //если заказ не от текущего пользователя, то добавляем его в ленту


                            console.log(order._id)
                            if(!newestOrderList.some(item => item._id === order._id)) {
                                return <OrderShield
                                    title={order.title}
                                    number={order.number}
                                    type={order.type}
                                    price={order.price}
                                    discipline={order.discipline ? order.discipline : null}
                                    date={order.closestDate ? "Ближайшая дата автора" : new Date(order.deadline).toLocaleDateString()}
                                    antiplagiat={order.antiplagiat}
                                    marker={props.marker}
                                    requests={order.requests}
                                    customer={order.creator.name + " " + order.creator.lastName}
                                    creationDate={new Date(order.creationDate).toLocaleString([],
                                        {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            day: "numeric",
                                            month : 'numeric',
                                            year: 'numeric'
                                        })}
                                    id={order._id}

                                    creatorId={order.creator._id}
                                    status={order.orderStatus}
                                    doer={order.doer}
                                    skey={index}
                                />
                            }

                        //}
                    }
                    )

                    :
                    isFiltered
                        ?
                        <div className={"order-empty-placeholder"}>
                            <h5 className={"ubuntu"}>По данному фильтру нет совпадений ¯\_(ツ)_/¯</h5>

                        </div>
                        :
                        showEmpty
                            ?
                            <div className={"order-empty-placeholder"}>
                                <h5 className={"ubuntu"}>Вы еще не публиковали заказы ¯\_(ツ)_/¯</h5>
                            </div>
                            :
                            newestOrderList.length
                                ?
                                null
                                :
                                <div className={"order-empty-placeholder"}>
                                    <h5 className={"ubuntu"}>Новые заказы еще не опубликованы ¯\_(ツ)_/¯</h5>
                                </div>

                    // <h1>{newestOrderList[0]
                    //     ?
                    //     null
                    //     :
                    //     message
                    // }</h1>
            }

                {/*{orderCounterRef.current === 0*/}
                {/*    ?*/}
                {/*    <div className={"order-empty-placeholder"}>*/}
                {/*        <h5 className={"ubuntu"}>По данному фильтру нет совпадений</h5>*/}

                {/*    </div>*/}
                {/*    :*/}
                {/*    null}*/}


                {stopFetching && orderList.length
                    ?
                    <div className={"order-empty-placeholder"}>
                        <h5 className={"ubuntu"}>Вы докрутили до конца ¯\_(ツ)_/¯</h5>

                    </div>
                    :
                    null
                }

            </div>

            <div className={"order-sorting-section-wrapper"} id={"order-sorting-section-wrapper"}>
            <div className={"order-sorting-section"} id={"order-sorting-section"}>
                <h6 className={"ubuntu margin-bottom-20"}>Фильтр</h6>
                {/*<h5>Реф = {orderCounterRef.current}</h5>*/}
                <select
                    key={selectKey1}
                    className={"margin-bottom-10"}
                    onChange={(e) => setFilter(e.target.value, "date")}>
                    <option value={"new"}>Сначала новые</option>
                    <option value={"old"}>Сначала старые</option>
                </select>

                <input
                    className={"margin-bottom-10"}
                    placeholder={"По дисциплине..."}
                    onChange={(e) => {setDisciplineState(e.target.value)}}
                    value={disciplineState}
                />
                <input
                    className={"margin-bottom-10"}
                    placeholder={"По Заказчику..."}
                    onChange={(e) => {setCreatorState(e.target.value)}}
                    value={creatorState}
                />
                <div className={"flex-between"}>
                    <label className={"margin-right-10"}>Без антиплагиата</label>
                    <input
                        checked={withoutAP}
                        type={'checkbox'}
                        onChange={() => {
                            localStorage.setItem('order-feed-filter-ap', (!withoutAP).toString())
                            setWithoutAP(!withoutAP)
                        }}
                    />
                </div>
                <div className={"flex-between"}>
                    <label className={"margin-right-10"}>Показывать мои заказы</label>
                    <input
                        checked={showMine}
                        type={'checkbox'}
                        onChange={() => {
                            localStorage.setItem('order-feed-filter-mine', (!showMine).toString())
                            setShowMine(!showMine)
                        }}/>
                </div>
                <div className={"flex-between"}>
                    <label className={"margin-right-10"}>Только мои предметы</label>
                    <input

                        checked={onlyMyDisciplines}
                        type={'checkbox'}
                        onChange={() => {
                            localStorage.setItem('order-feed-filter-disciplines', (!onlyMyDisciplines).toString())
                            setOnlyMyDisciplines(!onlyMyDisciplines)
                        }}/>
                </div>

                <div className={"filter-spinner-n-reset-button-container"}>
                    <div className={"filter-spinner-container"}>
                        {showSpinner
                            ?
                            <img src={require('../img/loading.gif')} height={"35px"}/>
                            :
                            null
                        }
                    </div>

                    <div>
                        <button
                            className={"btn btn-danger btn-reset-filter"}
                            onClick={() => setFilter(null, 'reset')}
                        >Сбросить</button>
                    </div>

                </div>

            </div></div>

        </div>
    );
};

export default observer(OrderFeed);