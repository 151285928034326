import React, {useState, useRef, useEffect, useContext} from 'react'
import AvatarEditor from 'react-avatar-editor'
import $api from "../../http";
import {Context} from "../../index";
import {API_URL} from "../../http";


const MyEditor = (props) => {

    const {store} = useContext(Context)
    const editor = useRef(null);

    const [image, setImage] = useState(props.exists ? "23" : "")
    const [imageScale, setScale] = useState(1)
    const [imageRotate, setRotate] = useState(0)
    const [avatarSaved, setAvatarSaved] = useState(props.exists ? props.exists : false)
    const [avatarEdited, setAvatarEdited] = useState(false)
    const [avatarError, setAvatarError] = useState(false)
    const [src, setSrc] = useState({img: `${API_URL}/public/avatars/${props.type === "chat" ? props.purpose : store.user.id}/avatar.png`, hash: Date.now()})

    console.log(src)

    function handleNewImage(e) {
        setImage(e.target.files[0])

    }

    function handleScale(e) {
        const scale = parseFloat(e.target.value)
        setScale(scale + 1)
    }

    function handleRotate(e) {
        const rotate = parseFloat(e.target.value)
        setRotate(rotate)
    }

    useEffect(() => {

        if(avatarSaved) {
            async function fetchAvatar() {
                await setSrc({img: {...store.user}.id ? `${API_URL}/public/avatars/${props.type === "chat" ? props.purpose : store.user.id}/avatar.png` : `${API_URL}/public/avatars/${{...store.user}._id}/avatar.png`, hash: Date.now()})
            }

            fetchAvatar()

        }

    }, [avatarSaved])



    return (
        <div className={"avatar-editor-wrapper"}>
            {image === ""
                ?
                <div>
                    <h6>Загрузите аватарку:</h6>
                    <input name={"newImage"} accept={".jpg, .png, .jpeg"} type={"file"} className={"personal-data-input"} onChange={handleNewImage}/>
                    <br />
                </div>
                :null
            }


            {image !== ""
                ?

                <div className={"personal-data-editor-container"}>

                    {!avatarSaved
                        ?
                        <div>
                            <AvatarEditor
                                ref={editor}
                                width={150}
                                height={150}
                                border={20}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={imageScale}
                                image={image}
                                rotate={imageRotate}
                                className="editor-canvas"
                            />
                            {!avatarSaved
                                ?
                                <div>
                                    Размер:
                                    <input
                                        name="scale"
                                        type="range"
                                        onChange={handleScale}
                                        max="3"
                                        step="0.01"
                                        defaultValue="0"
                                    />
                                    <br />
                                    Поворот:
                                    <input

                                        name="rotate"
                                        type="range"
                                        onChange={handleRotate}
                                        max="360"
                                        step="0.01"
                                        defaultValue="0"
                                    />
                                </div>
                                :
                                avatarEdited && props.purpose !== "chat"
                                    ?
                                    <h6 className={"avatar-saved-sign"}>✅ Аватар сохранен</h6>
                                    :
                                    null
                            }
                        </div>
                        :
                        <img
                            className={"personal-data-avatar"}
                            src={`${src.img}?${src.hash}`}

                        />
                    }
                    {avatarSaved
                        ?
                        <button className={"btn primary-btn-color margin-left-20"} onClick={() => {
                            setImage("")
                            setAvatarSaved(false)
                            setSrc({img: "", hash: Date.now()})
                        }}>✏</button>
                        :
                        null
                    }
                    {!avatarSaved
                        ?

                            <button className={"btn primary-btn-color"} onClick={async (e) => {
                                if (editor) {
                                    e.preventDefault()
                                    //formData это просто контейнер для отправки данных на бэкенд, как всегда
                                    const formData = await new FormData();
                                    //эта строчка делает объект CANVAS который можно по всякому юзать
                                    const canvasScaled = await editor.current.getImageScaledToCanvas()

                                    //и мы трансвормируем этот объект в BLOB

                                    await canvasScaled.toBlob(async blob => {

                                        //добавляем его в контейнер для отправки
                                        //самое важное его правильно прикрепить при отправке
                                        //а крепить его нужно сраху как он создан, без всяких перекидываний по переменным туда и сюда
                                        //иначе он прилетит не как данные, а как строчка "[object]" или типа того
                                        //а из этого уже на сервере ничего не слепить
                                        formData.append("file", blob)
                                        //вместе с юзер айди
                                        formData.append('userId', {...store.user}.id)
                                        //и дальше магия происходит на сервере (см. ws-chat-controller)
                                        const response =  await $api.post(`/add_avatar?purpose=${props.purpose}`, formData, {headers: {charset: 'win1251'}})

                                        if(response.status === 200) {
                                            setAvatarSaved(true)
                                            setAvatarEdited(true)
                                            setTimeout(() => {
                                                setAvatarEdited(false)
                                            }, 2000)

                                        } else {
                                            setAvatarError(true)
                                        }
                                    })
                                }
                            }}>Применить</button>
                        : null}



                </div>
                    :
                    null}

        </div>

    )
}

export default MyEditor