import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const ReviewRoller = ({reviews, setReviews}) => {


    const [sortState, setSortState] = useState("all")
    const [disciplineState, setDisciplineState] = useState("")

    const [goodReviewCounterCreator, setGoodReviewCounterCreator] = useState(0)
    const [goodReviewCounterPerformer, setGoodReviewCounterPerformer] = useState(0)
    const [badReviewCounterCreator, setBadReviewCounterCreator] = useState(0)
    const [badReviewCounterPerformer, setBadReviewCounterPerformer] = useState(0)
    const [creatorScores, setCreatorScores] = useState([])
    const [performerScores, setPerformerScores] = useState([])
    const [creatorAverageScore, setCreatorAverageScore] = useState([])
    const [performerAverageScore, setPerformerAverageScore] = useState([])



    useEffect(() => {
        if(reviews) {

            const performerArray = []
            const creatorArray = []

            for(let item of reviews) {
                console.log(item)
                if(item.asAuthor) {
                    setCreatorScores(prev => [...prev, item.score])
                    creatorArray.push(item.score)
                    if(item.score > 3) {
                        setGoodReviewCounterCreator(prev => prev + 1)
                    } else {
                        setBadReviewCounterCreator(prev => prev + 1)
                    }
                } else {
                    console.log("ЭТО ЕЛЗ")
                    setPerformerScores(prev => [...prev, item.score])
                    performerArray.push(item.score)
                    if(item.score > 3) {
                        setGoodReviewCounterPerformer(prev => prev + 1)
                    } else {
                        setBadReviewCounterPerformer(prev => prev + 1)
                    }
                }
            }

            const performerSum = performerArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const creatorSum = creatorArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            setPerformerAverageScore(Math.ceil(performerSum/performerArray.length * 10) / 10)
            setCreatorAverageScore(Math.ceil(creatorSum/creatorArray.length * 10) / 10)


        }

    }, [reviews])




    async function sortByScore(arr, value) {

        if(value === "down") {
            const newState = [...arr].sort((a, b) => b.score - a.score)
            setReviews(prev => newState)
        } else if(value === "up") {
            const newState = [...arr].sort((a, b) => a.score - b.score);
            setReviews(prev => newState)
        } else if(value === "date") {
            const newState = [...arr].sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
            setReviews(prev => newState)
        }
    }


    return (
        <div>
            <br/>
            <div className={"ubuntu common-review-info"}>
                За время работы на платформе пользователь получил <span className={"bold"}>{reviews.length}</span> отзывов<br/>
                Как Исполнитель: <span className={"red-for-blue bold"}>{badReviewCounterPerformer}</span> плох{badReviewCounterPerformer === 1 ? "ой" : "их"}, и <span className={"green-color bold"}>{goodReviewCounterPerformer}</span> хороши{goodReviewCounterPerformer === 1 ? "й" : "х"}. Средний балл <span className={"bold"}>{isNaN(performerAverageScore) ? "не определён" : performerAverageScore}</span><br/>
                Как Заказчик: <span className={"red-for-blue bold"}>{badReviewCounterCreator}</span> плох{badReviewCounterCreator === 1 ? "ой" : "их"}, и <span className={"green-color bold"}>{goodReviewCounterCreator}</span> хороши{goodReviewCounterCreator === 1 ? "й" : "х"}. Средний балл <span className={"bold"}>{isNaN(creatorAverageScore) ? "не определён" : creatorAverageScore}</span><br/>
            </div>
            <hr className={"white"}/>
            <h4 className={"white-color ubuntu"}>Отзывы:</h4>
            <select onChange={(e) => setSortState(e.target.value)}>
                <option value={"all"}>Все</option>
                <option value={"author"}>Об исполнителе</option>
                <option value={"customer"}>О заказчике</option>
            </select>
            <select  onChange={(e) => sortByScore(reviews, e.target.value)}>
                <option value={"date"}>По дате</option>
                <option value={"down"}>Сначала хорошие</option>
                <option value={"up"}>Сначала плохие</option>
            </select>
            <input placeholder={"По дисциплине..."} onChange={(e) => {setDisciplineState(e.target.value)}}/>
            {reviews.map((item, index) => {



                if(((sortState === "author" && (!item.asAuthor))
                    || (sortState === "customer" && (item.asAuthor))
                    || sortState === "all") && (disciplineState === "" || item.discipline.toLowerCase().includes(disciplineState.toLowerCase()))) {
                    return (

                        <div key={index} className={"review-plate"}>
                            <div className={"padding-right-50"}>
                                <h6>От:
                                    {item.from?._id
                                        ?
                                        <Link
                                            to={`/user-details/${item.from?._id}`}
                                            target={"_blank"}
                                            className={"margin-left-10 review-plate-user-link"}
                                        >{item.from?.name + " " + item.from?.lastName}
                                        </Link>
                                        :
                                        item.string_author_name
                                            ?
                                            " " + item.string_author_name
                                            :
                                            " Неизвестного пользователя"
                                    }
                                    {` ${!item.asAuthor
                                                ?
                                                "на исполнителя"
                                                :
                                        // item.asAuthor === null
                                        //     ?
                                        //     ""
                                        //     :
                                            "на заказчика"
                                            }`}
                                </h6>
                                <h6>Дисциплина: {item.discipline ?? "отзыв со старой платформы"}</h6>
                                {item.text.split('\n').map((fragment, index) => <p key={index}>{fragment}</p>)}

                                <h6 className={"white-back inline-block padding-5"}>{new Date(item.creationDate).toLocaleDateString()}</h6>
                            </div>
                            <div
                                className={`score-container  ${item.score === 3 ? 'orange-back' : item.score > 3 ? "green-back" : "red-back"}`}>
                                <h1 className={`ubuntu`}>{item.score}</h1>
                            </div>

                        </div>
                    )
                }
            })
            }
        </div>
    );
};

export default ReviewRoller;