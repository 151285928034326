import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import {Context} from "../../index";


import {observer} from 'mobx-react-lite';
import SvgRedCross from "../../img/red-cross.svg";
import SvgClock from "../../img/clock.svg"


const EventTab = (props) => {


    const {store} = useContext(Context)





    return (
        <div
            className={
                props.read
                    ?
                    "event-tab-read"
                    :
                    props.event === "moderator_invited"
                        ?
                        "event-tab maintenance-order"
                        :
                        props.event === "request_approved"
                            ?
                            "event-tab"
                            :
                            props.event === "order_refused"
                                ?
                                "event-tab"
                                :
                                "event-tab"
            }
        >
            <div className={props.event === "moderator_invited" ? "event-moder-helper" : ""}>
            <div className="order-top">

                    {props.event === "new_request"
                        ? <div>
                            <h5 className={"inline"}>{props.counter}. </h5>
                            <h5 className="order-title inline">
                                {`Новая заявка на выполнение`}
                            </h5>
                            <hr className={"hr-event"}/>
                            <br/>
                            <br/>
                            <h6 className={"inline"}>
                                Заказ:
                            </h6>
                            <h6 className={"black inline"}>
                                {` #${props.number} "${props.title}"`}
                            </h6>
                            <br/>
                            <h6 className={"inline"}>
                                От:
                            </h6>
                            <h6 className={"black inline"}>
                                <Link
                                    className={"agent-link"}
                                    to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                                </Link>

                            </h6>

                            </div>

                        : null

                    }

                {props.event === "request_aborted"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className={"order-title red-event inline"}>
                            {`Заявка отозвана `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"black inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            От:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }


                {props.event === "request_approved"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline green-event">
                            {`Запрос на выполнение одобрен`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"black inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }

                {props.event === "request_disapproved"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title red-event inline">
                            {`На заказ назначен другой исполнитель `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"black inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }



                {props.event === "order_refused"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title red-event inline">
                            {`Исполнитель запрашивает отмену выполнения `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Вы можете снять заказ с исполнителя, либо пригласить модератора</p>

                        <br/><br/>
                        <h6 className={"inline black"}>
                            Заказ:
                        </h6>
                        <h6 className={"black inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline black"}>
                            Исполнитель:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }



                {props.event === "order_deleted"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title red-event inline">
                            {`Заказ удалён создателем `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"black inline"}>
                            {props.message}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }



                {props.event === "order_cancelled"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Заказчик подтвердил отмену заказа `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <h6 className={"black inline"}>
                            {props.message}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }


                {props.event === "order_available"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Заказ снова доступен для заявки `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }

                {props.event === "moderator_invited"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline">
                            {`На заказ приглашается модератор `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }


                {props.event === "moderator_comes"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Модератор присоединился к чату заказа `}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }



                {props.event === "moderation_cancelled"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Модератор вынес решение`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Ознакомиться с ним можно на странице заказа</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                    </div>

                    : null

                }

                {props.event === "closed_by_moder"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Заказ закрыт модератором`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>С решением можно ознакомиться на странице заказа</p>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                    </div>

                    : null

                }


                {props.event === "files_attached"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline">
                            {`Исполнитель добавил готовые файлы`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }

                {props.event === "start_arbitration_on_your_order_creator"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {`Стартовал арбитраж по вашему исходящему заказу`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Вы можете участвовать в обсуждении в чате арбитража</p>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }

                {props.event === "start_arbitration_on_your_order_doer"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {`Стартовал арбитраж по вашему входящему заказу`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Вы можете участвовать в обсуждении в чате арбитража</p>
                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }

                {props.event === "stop_arbitration_on_your_order_creator"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Закончился арбитраж по исходящему заказу`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Вы можете ознакомиться с его решением на странице заказа</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }

                {props.event === "stop_arbitration_on_your_order_doer"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Закончился арбитраж по вашему входящему заказу`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Вы можете ознакомиться с его решением на странице заказа</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }



                {props.event === "corrections_activated"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {`Заказчик инициировал процесс исправления готового материала`}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>У вас отключена возможность добавлять готовые файлы до момента пока заказчик
                        не приложит сопутствующие материалы правок, или не подтвердит что таковые не требуются</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6><br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }

                {props.event === "corrections_attached"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            {`Заказчик добавил файлы правок`}
                        </h5>
                        <hr className={"hr-event"}/>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }


                {props.event === "moderation_called"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {`${props.to._id === props.doer ? "Заказчик" : "Исполнитель"} пригласил модератора`}
                        </h5>
                        <hr className={"hr-event"}/>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }


                {props.event === "closing_confirmation"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline green-event">
                            Заказчик инициировал закрытие заказа
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Вы можете подтвердить это действие на странице заказа, либо пригласить модератора.</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            Заказчик:
                        </h6>
                        <h6 className={"black inline"}>
                            <Link
                                className={"agent-link"}
                                to={`../user-details/${props.from?._id}`}>{` ${props.authorName}`}
                            </Link>
                        </h6>

                    </div>

                    : null

                }


                {props.event === "order_closed"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline green-event">
                            Исполнитель подтвердил закрытие заказа
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Пожалуйста отставьте оценку и отзыв о сотрудничестве на страничке заказа</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }


                {props.event === "arbitration_started"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            Начинается новый арбитраж
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Вы можете присоединиться к процессу</p>

                        <br/><br/>
                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                    </div>

                    : null

                }


                {props.event === "added_to_chat"
                    ? <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline">
                            {props.message}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p>Кнопка для перехода в него теперь есть в пункте меню "Кабинет"</p>
                        <br/><br/>


                    </div>

                    : null

                }
                {props.event === "order_expiring"
                    ?
                    <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {props.message}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Полжалуйста поменяйте дату, иначе заказ пропадёт из ленты</p>

                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <br/><br/>


                    </div>
                    :
                    null
                }

                {props.event === "order_expired"
                    ?
                    <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {props.message}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Заказ снят с публикации, и перемещен в черновики, вы можете опубликовать его заново, сменив статус на "Актуальный" в редактировании</p>

                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <br/><br/>


                    </div>
                    :
                    null
                }

                {props.event === "order_waiting"
                    ?
                    <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {props.message}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>По данному заказу инициировано закрытие, однако вы его еще
                            не подтвердили. Пожалуйста подтвердите закрытие, чтобы получить возможность отавить
                            отзыв на заказчика.
                        </p>

                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <br/><br/>


                    </div>
                    :
                    null
                }

                {props.event.startsWith("review_waiting")
                    ?
                    <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            {props.message}
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>{`Вы завершили этот заказ, однако не оставили отзыв о сотрудничестве
                        с ${props.event.split("_")[3] === "doer" ? "исполнителем" : "заказчиком" }. Это можно сделать на странице заказа.`}
                        </p>

                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>

                        <br/><br/>


                    </div>
                    :
                    null
                }

                {props.event === "private_order_offer"
                    ?
                    <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline blue-event">
                            Новый приватный заказ
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Для вас создан новый приватный заказ, чтобы принять или отказаться, перейдите на его страницу</p>

                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            От:
                        </h6>
                        <h6 className={"inline"}>{" "}
                            <Link
                                className={"agent-link"}
                                to={`/user-details/${props.from._id}`}>
                                {`${props.from.name} ${props.from.lastName}`}
                            </Link>

                        </h6>
                        <br/><br/>


                    </div>
                    :
                    null
                }

                {props.event === "private_order_declined"
                    ?
                    <div>
                        <h5 className={"inline"}>{props.counter}. </h5>
                        <h5 className="order-title inline red-event">
                            Отказ от приватного заказа
                        </h5>
                        <hr className={"hr-event"}/>
                        <p className={"padding-5"}>Исполнитель отказался от вашего предложения. Статус заказа изменен на "Черновик"</p>

                        <h6 className={"inline"}>
                            Заказ:
                        </h6>
                        <h6 className={"inline"}>
                            {` #${props.number} "${props.title}"`}
                        </h6>
                        <br/>
                        <h6 className={"inline"}>
                            От:
                        </h6>
                        <h6 className={"inline"}>{" "}
                            <Link
                                className={"agent-link"}
                                to={`/user-details/${props.from?._id}`}>
                                {`${props.from?.name} ${props.from?.lastName}`}
                            </Link>

                        </h6>
                        <br/><br/>


                    </div>
                    :
                    null
                }

                <img
                    className={"red-cross-event"}
                    src={SvgRedCross} width={"20px"}
                    onClick={() => props.editEvent(props.id, "delete")}
                    title={"Удалить"}
                />
                {/*<button*/}
                {/*    title={"Удалить"}*/}
                {/*    className={`btn btn-outline-danger event-delete`}*/}
                {/*    onClick={() => props.editEvent(props.id, "delete")}*/}
                {/*><span>❌</span></button>*/}



            </div>

            <br/>
            <div className="order-bottom">
                <div className="event-date-container">
                    <img src={SvgClock} width={"20px"} className={"inline"}/>
                    <h6 className={"black inline event-date"}> {new Date(props.date).toLocaleString(
                        [],{year: 'numeric', month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'})}</h6>

                </div>
                <div className={"minishield-button-container"}>
                    {!props.read
                        ?
                        <button
                            className={"btn btn-secondary read-event-btn border-off"}
                            onClick={() => props.editEvent(props.id, "read")}
                        >Просмотрено</button>
                        :
                        null
                    }


                    {props.event === "order_deleted"
                    || props.event === "request_disapproved"
                    || props.event === "order_cancelled"
                    || props.event === "moderator_invited"
                    || props.event === "arbitration_started"
                    || props.event === "added_to_chat"

                        ? null

                        : <Link
                            to={"/order-details/" + props.order_id}
                            className="about-order-btn btn primary-btn-color border-off margin-left-10">К заказу</Link>
                    }
                    {props.event === "arbitration_started"
                    || props.event === "start_arbitration_on_your_order_creator"
                    || props.event === "start_arbitration_on_your_order_doer"
                        ?
                        <Link
                            to={"/arbitroom"}
                            className="about-order-btn btn primary-btn-color border-off margin-left-10">Перейти</Link>
                        :
                        null
                    }
                    {props.event === "added_to_chat"
                        ?
                        <Link
                            to={`/chat/${props.chat_id}`}
                            className="about-order-btn btn primary-btn-color border-off margin-left-10">Открыть чат</Link>
                        :
                        null
                    }
                    {store.user.maintenance && props.event === "moderator_invited"
                        ?
                        <Link
                            to={"/moderator_room"}
                            className={"about-order-btn btn primary-btn-color border-off margin-left-10"}>В кабинет</Link>
                        :
                        null
                    }
                </div>
            </div>
            </div>

        </div>
    );
};

export default observer(EventTab);