import React, {useContext, useEffect, useState, useRef} from 'react';
import {Context} from "../index";
import $api from "../http";
import {toJS} from 'mobx'
import {observer} from 'mobx-react-lite';
import ModerationList from "./helpers/ModerationList"
import OrderDetails from "./OrderDetails";
import EmptyMessage from "./helpers/EmptyMessage";
import ModalDialog from "./helpers/ModalDialog";
import SvgAnswer from "../img/answer-arrow.svg";
import NestedMessageModal from "./helpers/NestedMessageModal";
import {API_URL} from "../http";
import {processMessageText} from "./helpers/repeat_functions";
import {useLocation} from "react-router-dom";



const ModeratorRoom = ({newOrderMessage, notifySocket}) => {

    const {store} = useContext(Context)


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const order_id_from_mail = queryParams.get("order_id_from_mail");

    console.log(order_id_from_mail)


    const [moderatedOrdersList, setModeratedOrdersList] = useState([])
    const [activeOrder, setActiveOrder] = useState(order_id_from_mail ? order_id_from_mail : null)
    const [allMessages, setAllMessages] = useState([])



    const [activeOrderNew, setActiveOrderNew] = useState(order_id_from_mail ? order_id_from_mail : null)
    const [ordersInfo, setOrdersInfo] = useState({})
    const [messageListToRoll, setMessageListToRoll] = useState([])
    const [unreadOrders, setUnreadOrders] = useState([])

    const messageAreaModerRef = useRef()
    const newMessageLineRef = useRef(true)


    const [activeTakeToWork, setActiveTakeToWork] = useState("no")


    const [myOrderRemove, setMyOrderRemove] = useState(true)
    const [nowTaking, setNowTaking] = useState(false)

    const readMessagesModer = useRef([])

    const [slicerModer, setSlicerModer] = useState({start: 0, stop: 50})
    const slicerModerRef =  useRef(0)

    const readMessages = useRef([])


    function computeSlicerCounter(messageMassive) {
        let counter = 0

        for(let message of messageMassive) {
            if(message.userId._id !== store.user.id
                && (!message.read?.includes(store.user.id)
                    || !readMessages.current?.includes(message._id))) {

                counter++
            }
            if(message.userId._id !== store.user.id
                && (message.read?.includes(store.user.id)
                    || readMessages.current?.includes(message._id))) {
                break;
            }
        }

        return counter

    }


    function dateLook(check) {
        if(new Date(Date.now()).toLocaleDateString() === new Date(check).toLocaleDateString()) {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleTimeString(
                    [],{hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        } else {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleDateString(
                    [],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        }
    }


    useEffect(() => {
        store.editWhitePointer({place: "Кабинет модератора:", color: "gold-color"})
    }, [])


    useEffect(() => {

        let allOfMessages = []
        async function getModeratedOrders() {
            const response = await $api.get(`/get_moderated_orders?id=${store.user.id}`)

            console.log(response.data)
            setModeratedOrdersList(sortOrders(response.data))

            let sortedMessagesTmp = {}

            let ordersInfoTmp = {}

            for (let key in response.data) {
                //console.log(response.data[key].msgs)
                sortedMessagesTmp[response.data[key]._id] = response.data[key].msgs
                ordersInfoTmp[response.data[key]._id] = response.data[key]
                allOfMessages.push(...response.data[key].msgs.reverse())

            }

            const unreadSet = new Set()

            for(let item in sortedMessagesTmp) {
                for(let message of sortedMessagesTmp[item]) {
                    if(!message.read.includes(store.user.id)
                        && ordersInfoTmp[item].moderator === store.user.id
                        && (message.userId._id === ordersInfoTmp[item].creator._id || message.userId._id === ordersInfoTmp[item].doer._id)
                    ) {
                        console.log(toJS(store.user.id))
                        console.log(message)
                        unreadSet.add(item)
                        break;
                    }
                }
            }

            await setUnreadOrders([...unreadSet])

            setAllMessages(sortedMessagesTmp)
            setOrdersInfo(ordersInfoTmp)
            console.log(sortedMessagesTmp)



        }

        getModeratedOrders()



    }, [store.moderUpdate])


    const [answeringMessage, setAnsweringMessage] = useState({
        text: null,
        id: null,
        date: null,
        self: null,
        quote: null,
        sender: null
    })


    function answerOnMessage(text, id, date, self, quote, sender) {
        console.log("SET 2")
        console.log(text, id, date, self)
        setAnsweringMessage({text: text, id: id, date: date, self: self, quote: quote, sender: sender})
    }



    function containsLink(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/;
        return linkRegex.test(text);
    }


    function splitWord(text, size) {
        const chunks = [];
        let container = [];
        console.log("NOW SPLITTING")
        console.log(text)

        for (let i = 0; i < text.length; i += size) {
            chunks.push(text.slice(i, i + size));
        }


        for(let chunk of chunks) {

            container.push(<span key={chunk}>{chunk}<wbr/></span>);
        }

        return container


    }

    function processMessageText(text) {

        if(text) {
            if (containsLink(text)) {
                const messageContent = text.replace(
                    /(https?:\/\/[^\s]+)/g,
                    (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
                );

                return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
            }
            //console.log(text)
            const main_array = text.split(" ")
            let decision = false

            let container = []

            for(let piece of main_array) {
                if (piece.length > 25) {
                    decision = true;
                    break
                }
            }

            if (decision) {

                const chunkSize = 20;



                //const wordChunks = splitWord(text, chunkSize);
                return splitWord(text, chunkSize);
                // for(let chunk of wordChunks) {
                //
                //     container.push(
                //         <span>{chunk}<wbr/></span>
                //     )
                // }
                //
                // return container

            } else {
                return text
            }
        } else {
            return text
        }


    }

    function textReducer(text) {
        if (text.length > 150) {
            return text.slice(0, 150) + "..."
        }
        return text
    }

    const [nestedContent, setNestedContent] = useState()
    const [stepCounter, setStepCounter] = useState(0)
    const [lastSteps, setLastSteps] = useState([])
    const [activeMessageModal, setActiveMessageModal] = useState("no")

    async function showNestedMessage(id, stepBack, marker) {
        console.log(id)
        console.log(marker, " - marker")
        const response = await $api.get(`/get_nested_message_work?id=${id}`)
        console.log(response.data)
        await setNestedContent(response.data)
        if (stepBack) {
            console.log("step back")
            setStepCounter(stepCounter - 1)
            setLastSteps(prev => prev.slice(0, -1));
        } else {
            console.log("step forward")
            setStepCounter(stepCounter + 1)
            setLastSteps(prev => [...prev, id])
        }

        setActiveMessageModal("progress")
        setTimeout(() => {
            setActiveMessageModal("active")
        }, 100)
    }



    function processQuote(message) {
        return <div className={"answered-message answered-right"}>

            {/*<p className={"private-message-date rem07"}>*/}
            {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
            {/*</p>*/}
            <span className={"bold rem08"}>{
                message.quote_userId === store.user.id || message.quote.userId === store.user.id
                    ?
                    "Вы"
                    :
                    message.quote.userId === ordersInfo[activeOrderNew]?.creator._id || message.quote_userId === ordersInfo[activeOrderNew]?.creator._id
                        ?
                        ordersInfo[activeOrderNew]?.creator.name + " " + ordersInfo[activeOrderNew]?.creator.lastName
                        :

                        ordersInfo[activeOrderNew]?.doer.name + "17 " + ordersInfo[activeOrderNew]?.doer.lastName
            }:</span>

            <span className={"rem08"}>
                                        {message.quote.text
                                            ?
                                            processMessageText(textReducer(message.quote.text))
                                            :
                                            processMessageText(textReducer(message.quote_text))
                                        }
                                    </span>

            {message.quote.quote || message.need_rolling
                ?
                <a
                    onClick={() => showNestedMessage(message.quote._id ? message.quote._id : message.quote , false, 2)}
                    className={"rem07 modal-link"}
                >показать вложения</a>
                :
                null
            }

        </div>
    }



    const [isChatVisible, setIsChatVisible] = useState(false)

    useEffect(() => {
        newMessageLineRef.current = true
    }, [activeOrderNew])

    useEffect(() => {


        console.log("НАМ НУЖЕН ЭТОТ ЭФФЕКТ")

        if(activeOrderNew && isChatVisible) {

            console.log("Switch to FALSE 3")
            let newMessageLineSwitch = false
            let newMessagesLine = false
            let stopSearching = false


            const slicerCounter = computeSlicerCounter(allMessages[activeOrderNew])

            const messagesMapped = allMessages[activeOrderNew]?.slice(slicerModer.start, slicerCounter >  slicerModer.stop ? slicerCounter + 20 : slicerModer.stop).map((message, index) => {



                //console.log("Работает 1")
                //if(activeOrderNew === message.about) {

                    newMessagesLine = false

                    //это IF для первого сообщения при отправке заявки, его не должно быть видно
                    if (message.text === "#####") {
                        return null
                    }
                    //тут опять их делим на левые и правые для отображения
                    //это вариант сообщение от модератора
                    if (message.userId?._id === store.user.id) {

                        if(index === 0) {
                            stopSearching = true
                        }

                        if(newMessageLineSwitch) {
                            console.log("Switch to FALSE 4")
                            newMessageLineSwitch = false
                            console.log("NEW LINE ACTIVATED 1")
                            newMessagesLine = true
                            // console.log("СТАВИМ ФОЛС 1")
                            // if(showChat.display !== "none") {
                            //     newMessageLineRef.current = false
                            // }
                        }


                        return <div className={"native-chat-main-message-wrapper"} key={message._id}>

                            <div className={"native-chat-message-wrapper-center"}>

                            <div className={"chat-message chat-center"}>
                            <div className={"chat-username"}>{"Модератор"}</div>
                            {dateLook(message.date)}

                                {message.quote
                                    ?
                                    processQuote(message)
                                    :
                                    null
                                }

                            {processMessageText(message.text)}
                        </div>
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, true, message.quote, message.userId._id)
                                    }}
                                />
                            </div>
                            {newMessagesLine
                                ?
                                <div>
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                                </div>
                                :
                                null
                            }
                        </div>
                        //это сообщение от создателя заказа для массива (модерируемы заказ), либо для исполнителя
                    } else if ((message.userId?._id === ordersInfo[activeOrderNew]?.creator._id)// && Array.isArray(message.to))
                        //|| (message.userId?._id === ordersInfo[activeOrderNew]?.creator._id && message.to === ordersInfo[activeOrderNew]?.doer._id
                        //)
                    ) {

                        if(newMessageLineSwitch
                            && (message.read?.includes(store.user.id)
                            || readMessagesModer.current.includes(message._id))
                        ) {

                            console.log("Switch to FALSE 1")
                            newMessageLineSwitch = false
                            console.log("NEW LINE ACTIVATED 2")
                            newMessagesLine = true
                            newMessageLineRef.current = false

                        }


                        console.log(!stopSearching)
                        console.log(newMessageLineRef.current)
                        console.log(!message.read?.includes(store.user.id))
                        console.log(!readMessagesModer.current?.includes(message._id))


                        if(!stopSearching
                            && newMessageLineRef.current
                            && !message.read?.includes(store.user.id)
                            && !readMessagesModer.current.includes(message._id)
                        ) {
                            console.log(message)
                            console.log("Switch to TRUE 1")
                            newMessageLineSwitch = true
                            stopSearching = true
                        }

                        //console.log(message)

                        return <div className={"native-chat-main-message-wrapper"} key={message._id}>
                            <div className={"native-chat-message-wrapper-right"}>
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            <div
                                className={"chat-message chat-right"}
                                data-read={message.read?.includes(store.user.id) || false}
                                id={message._id}>
                            <div className={"chat-username"}>{message.userId.name + " " + message.userId.lastName}</div>
                            {dateLook(message.date)}
                                {message.quote
                                    ?
                                    processQuote(message)
                                    :
                                    null
                                }
                            {processMessageText(message.text)}
                        </div>

                        </div>
                            {newMessagesLine
                                ?
                                <div>
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                                </div>
                                :
                                null
                            }
                        </div>
                        //это сообщения от исполнителя
                    } else if (message.userId?._id === ordersInfo[activeOrderNew]?.doer._id)
                        //|| (message.to === ordersInfo[activeOrderNew]?.creator._id && message.userId === ordersInfo[activeOrderNew].doer._id)
                     {




                         if(newMessageLineSwitch
                             && (message.read?.includes(store.user.id)
                             || readMessagesModer.current.includes(message._id))
                         ) {

                             console.log("Switch to FALSE 2")
                             newMessageLineSwitch = false
                             console.log("NEW LINE ACTIVATED 3")
                             newMessagesLine = true
                             newMessageLineRef.current = false

                         }


                         console.log(!stopSearching)
                         console.log(newMessageLineRef.current)
                         console.log(!message.read?.includes(store.user.id))
                         console.log(!readMessagesModer.current?.includes(message._id))

                         console.log("_______________________")

                         if(!stopSearching
                             && newMessageLineRef.current
                             && (!message.read?.includes(store.user.id)
                             || !readMessagesModer.current?.includes(message._id))
                         ) {
                             console.log(message)
                             console.log("Switch to TRUE 2")

                             newMessageLineSwitch = true
                             stopSearching = true
                         }

                        return <div className={"native-chat-main-message-wrapper"} key={message._id}>
                            <div className={"native-chat-message-wrapper-left"}>

                            <div
                                className={"chat-message chat-left"}
                                data-read={message.read?.includes(store.user.id) || false}
                                id={message._id}>
                            <div className={"chat-username"}>{message.userId.name + " " + message.userId.lastName}</div>
                            {dateLook(message.date)}
                                {message.quote
                                    ?
                                    processQuote(message)
                                    :
                                    null
                                }
                            {processMessageText(message.text)}
                        </div>
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />

                        </div>
                            {newMessagesLine
                                ?
                                <div>
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
                                </div>
                                :
                                null
                            }
                         </div>


                    }
                //}
            })

            setMessageListToRoll(messagesMapped)

        }





    }, [allMessages, activeOrderNew, slicerModer, isChatVisible])






    async function downloadHandler(path) {

        // тут использован fetch вместо аксиоса, потому что Ulbi сказал что так проще блять
        const response = await fetch(API_URL + path, {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        })

        //создаем блоб (Binary Large OBject) из ответа
        const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = window.URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        link.download = await path.split('/')[5]
        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
    }


    function mainModerStepperLoop(files, corrections, step) {


        const results = []
        console.log(corrections)

        for(let i=0; i <= step; i++) {

            results.push(
                <div key={i} className={"main-stepper-loop"}>
                    <h4 className={"red"}>Фаза {i + 1}</h4>
                    {mapReadyFiles(files[i], "Готовые файлы:")}

                    {i < step ? <hr className={"native-blue"}/> : null}
                    {mapReadyFiles(corrections[i], "Файлы правок:")}
                    <hr style={{height: "5px"}} className={"red"}/>
                </div>
            )

        }

        return results

    }


    function mapReadyFiles(arrayToMap, text) {
        let count = 0


        if(arrayToMap?.length > 0) {
            return <div>
                <h4>{text}</h4>
                {arrayToMap.map(file => {

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else {
                        type = "doc_thumb"
                    }


                    //пятерка в квадратных скобках это количество папок вложенных)))

                    const filename = file.path.split('/')[5].replace(/_/g, " ")
                    count++
                    return<div key={file.path}>
                        {count > 1 ? <hr className={"native-blue"}/> : null}
                        <div className={"one-ready-order-container"} key={file.path}>

                            <div className={"ready-filename-container"}>

                                <img className={"order-done-file-image"} src={require(`../img/${type}.png`)} width={"50"}/>
                                <p className={"inline"}>{filename.length > 50 ? filename.slice(0, 50)+"..." : filename}</p>
                                <br/>
                                <p className={"white-back inline-block padding-5"}>📌 {new Date(file.date).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</p>


                            </div>
                            <button className={"btn btn-primary order-download-btn"} onClick={() => downloadHandler(file.path)}>
                                Скачать
                            </button>

                        </div>
                    </div>
                })}
            </div>
        }

    }






    //функция сортирует заказы по принципу ЕСТЬ или НЕТ модератор, те у которых нет, появляются в начале списка
    function sortOrders(array) {
        array.sort((a, b) => {
            if(a.moderator !== undefined && b.moderator === undefined) {
                return 1
            } else if(a.moderator === undefined && b.moderator !== undefined) {
                return -1
            } else {
                return 0
            }
        })

        return array
    }






    useEffect(() => {


        async function addToStakan(order_id) {
            const response = await $api.post('/get_to_moderation', {order_id})
            console.log(response.data)
            console.log(response.data.msgs)
            //let newStateOfList = moderatedOrdersList.slice(0)
            //sortOrders(newStateOfList)

            setModeratedOrdersList(prev => sortOrders([response.data, ...prev]))
            //setAllMessages(prev => [...prev, ...response.data.msgs.reverse()])

            setAllMessages(prev => {

                return {
                    ...prev,
                    [response.data._id]: [...response.data.msgs.reverse()]
                }

            })

            setOrdersInfo(prev => {
                return {
                    ...prev,
                    [response.data._id]: response.data
                }
            })




        }

        if(store.moderStakanUpdater.length === 1) {
            addToStakan(store.moderStakanUpdater[0])
        }
    }, [store.moderStakanUpdater])



    async function removeFromStakan(order_to_remove) {

        const newStakanState = moderatedOrdersList.filter(order => {
            if(order._id !== order_to_remove) {
                return true
            } else {
                console.log("FOUND WHAT REMOVE")
                return false
            }
        })


        setModeratedOrdersList(newStakanState)

        await setOrdersInfo(prev => {
            //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
            const { [order_to_remove]: deletedKey, ...updatedState } = prev;
            //и возвразаем то что получилось обновляя стейт
            return updatedState;
        })



    }



    useEffect(() => {


        console.log(activeOrder)

        if((store.moderStakanReducer.length === 1 && myOrderRemove) || (ordersInfo[activeOrderNew]?.moderator === store.user.id && !nowTaking)) {
            removeFromStakan(store.moderStakanReducer[0])
            if (activeOrder === store.moderStakanReducer[0]) {
                setActiveOrderNew(null)
            }

            setMyOrderRemove(true)
        }
    }, [store.moderStakanReducer])


    // function setActive(inp) {
    //     setOrderPlacer(prev => moderatedOrdersList[inp].creator.name + " " + moderatedOrdersList[inp].creator.lastName)
    //     console.log(inp)
    //     setActiveOrder(prev => moderatedOrdersList[inp])
    //
    //
    // }



    function showTakeOrderModal() {
        console.log("WANT TO TAKE")
        setActiveTakeToWork("progress")
        setTimeout(() => {
            setActiveTakeToWork("active")
        }, 100)

    }


    const [addTextArea, setAddTextArea] = useState(false)

    async function takeOrder() {
        setNowTaking(true)
        console.log(`${activeOrderNew} is TAKEN`)
        setMyOrderRemove(false)
        const response = await $api.post('/take_to_moderation', {order_id: activeOrderNew, moderator: store.user.id})

        if(response.data.status === "OK") {

            const newStateOfStakan = moderatedOrdersList.map(order => {
                if(order._id === activeOrderNew) {
                    order.moderator = store.user.id
                    return order
                } else {
                    return order
                }
            })

            setAddTextArea(true)

            setModeratedOrdersList([...newStateOfStakan])





        }
    }


    //эффект срабатывает на изменение листа со списком заказов, по которым требуется модерация
    useEffect(() => {

        //и обновляет информацию по активному заказу, это происходит когда модератор тыкает кнопку "взять а работу"
        if(moderatedOrdersList.length && activeOrder) {
            setNowTaking(false)
            moderatedOrdersList.forEach((order, index) => {
                if(order._id === activeOrder._id) {
                    setActiveOrder(moderatedOrdersList[index])
                }
            })
        }

    }, [moderatedOrdersList])


    useEffect(() => {
        //закидываем их в стейт сообщений
        //console.log(toJS(store.notificationsOut))
        console.log("GOT NEW MESSAGE")

        console.log(newOrderMessage)

        if (newOrderMessage.about in allMessages) {


            if(!unreadOrders.includes(newOrderMessage.about)) {
                setUnreadOrders(prev => [...prev, newOrderMessage.about])
            }

            setAllMessages(prev => {
                if(allMessages.hasOwnProperty(newOrderMessage.about)) {
                    return {
                        ...prev,
                        [newOrderMessage.about]: [newOrderMessage, ...prev[newOrderMessage.about]]
                    }
                } else {
                    return {
                        ...prev,
                        [newOrderMessage.about]: [newOrderMessage]
                    }
                }
            })

        }



        //если оно по активному закузу, то кидаем его и в сетйт активного заказа, представляете себе))
        // if(newOrderMessage.about === activeOrder?._id) {
        //
        //     console.log("ADD MESSAGE TO OPER")
        //     setChatMessagesOper(prev => [newOrderMessage, ...prev])
        //
        // }
    }, [newOrderMessage])



    // async function setMessageList() {
    //
    //     let counter = 0
    //     const list = allMessages.filter(message => {
    //
    //         if(message.about === activeOrder._id) {
    //             counter++
    //             //console.log(message)
    //             //console.log("Добавлено ↑")
    //             return true
    //
    //         } else {
    //
    //             return false
    //         }
    //     })
    //
    //     console.log(counter)
    //     setChatMessagesOper(prev => [...list])
    // }


    // useEffect(() => {
    //
    //     if(activeOrder?._id !== undefined) {
    //         console.log("YEPP")
    //         console.log(activeOrder?._id)
    //         setMessageList()
    //         console.log(allMessages)
    //     }
    //
    // }, [activeOrder?._id])





    useEffect(() => {
        console.log("Крутим 5")
        messageAreaModerRef?.current?.scrollTo({
            top: 0,
            //behavior: 'smooth', // Прокручиваем с анимацией
        })
    }, [activeOrder])



    return (
        <div className={"moderator-room-main-wrapper"}>
            <div className={"moderation-list-wrapper"}>
                <ModerationList
                    orderList={moderatedOrdersList}
                    activeOrder={activeOrderNew}
                    //setOrder={setActive}
                    setActiveOrder={setActiveOrderNew}
                    unreadOrders={unreadOrders}
                />
            </div>
            <div className={"moderation-order-wrapper"}>
                {activeOrderNew && ordersInfo[activeOrderNew]
                    ?
                    <OrderDetails
                        notifySocket={notifySocket}
                        moderation={true}
                        moderatedOrder={ordersInfo[activeOrderNew]}
                        //orderPlacer={orderPlacer}
                        // messages={allMessagesList}
                        messageListToRoll={messageListToRoll}
                        //chatMessagesOper={chatMessagesOper}
                        //setChatMessagesOper={setChatMessagesOper}
                        setMessages={setAllMessages}
                        newOrderMessage={newOrderMessage}
                        takeOrder={showTakeOrderModal}
                        mapFiles={mainModerStepperLoop}
                        messageAreaModerRef={messageAreaModerRef}
                        unreadOrders={unreadOrders}
                        setUnreadOrders={setUnreadOrders}
                        activeOrder={activeOrderNew}
                        setActiveOrder={setActiveOrderNew}
                        removeFromStakan={removeFromStakan}
                        addTextArea={addTextArea}
                        answeringMessage={answeringMessage}
                        setAnsweringMessage={setAnsweringMessage}

                        readMessagesModer={readMessagesModer}
                        slicerModer={slicerModer}
                        setSlicerModer={setSlicerModer}
                        slicerModerRef={slicerModerRef}

                        isChatVisible={isChatVisible}
                        setIsChatVisible={setIsChatVisible}

                    />
                    :
                    <div className={"moderation-empty-message-wrapper"}>
                        <EmptyMessage

                            title={"Не выбран ни один заказ"}
                            message={
                                <div>
                                    Выбирайте заказ слева в стакане чтобы начать работу<br/><br/>
                                    <div className={"rule-container"}>
                                        <div className={"btn-sample btn-danger"}>Красный</div><div><p className={"inline"}>- На заказ не назначен модератор</p></div>
                                    </div>
                                    <div className={"rule-container"}>
                                        <div className={"btn-sample btn-primary"}>Синий</div><div><p className={"inline"}>- Вы модератор этого заказа, активный заказ</p></div>
                                    </div>
                                    <div className={"rule-container"}>
                                        <div className={"btn-sample btn-secondary"}>Серый</div><div><p className={"inline"}>- Вы модератор этого заказа, неактивное состояние</p></div>
                                    </div><br/>
                                    В стакане не отображаются заказы по которым вы являетесь заказчиком или исполнителем.
                                </div>
                            }
                        />
                    </div>

                }
            </div>

            <ModalDialog
                active={activeTakeToWork}
                setActive={setActiveTakeToWork}
                action={() => takeOrder()}
                redMessage={`Вы собираетесь взять на медиацию заказ #${ordersInfo[activeOrderNew]?.number} "${ordersInfo[activeOrderNew]?.title}"`}
                message={`Уверены что хотите продолжить?`}
                purpose={"choice"}
            />
            <NestedMessageModal
                active={activeMessageModal}
                setActive={setActiveMessageModal}
                message={nestedContent}
                dateSet={dateLook}
                // renderForwardedMessages={renderForwardedMessages}
                showNestedMessage={showNestedMessage}
                //linkRoller={linkRoller}
                processMessageText={processMessageText}
                lastSteps={lastSteps}
                setLastSteps={setLastSteps}
                stepCounter={stepCounter}
                setStepCounter={setStepCounter}

                workMessages={true}
                moderator={ordersInfo[activeOrderNew]?.moderator}
                user_id={store.user.id}
            />

        </div>
    );
};

export default observer(ModeratorRoom);