import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Context} from "../index";
import $api from '../http';
import ModalDialog from "./helpers/ModalDialog";
import Loading from "./Loading";
import {observer} from 'mobx-react-lite';
import {toJS} from 'mobx';
import SvgAnswer from "../img/answer-arrow.svg"; // Путь к SVG-файлу
import SvgPencil from "../img/pencil-svgrepo-com.svg"; // Путь к SVG-файлу
import SvgGoToMsg from "../img/go-to-msg.svg";
import SvgArrowDown from "../img/arrow-down.svg";
import SvgSearchLens from "../img/search-lens.svg";
import SvgSoundOn from '../img/sound-on.svg'
import SvgSoundOff from '../img/sound-off.svg'
import NestedMessageModal from "./helpers/NestedMessageModal";
import TextExpander from "./helpers/TextExpander";
import {API_URL} from "../http";
import {API_IP} from "../http";
import {
    processMessageText, downloadHandler,
    getMinutesBetweenDates, sortByArrayLength,
    highlightTextAroundPattern, chatNameResolver
} from "./helpers/repeat_functions";
import {Link} from "react-router-dom";
import SvgUpload from "../img/upload-minimalistic.svg";
import SvgHeart from "../img/heart-reactions.svg"
import ModalImgViewer from "./helpers/ModalImgViewer";
import SvgAtSign from "../img/at-symbol.svg";
import SvgArrowsAlt from "../img/arrows-alt.svg";
import {v4} from "uuid";



const WsChatArbitr = ({arbitrAccess, archivedState}) => {

    const {store} = useContext(Context)


    const chatId = archivedState ? 'archive' : 'arbitr'
    store.setChatId(chatId)
    store.setPageId("ws-chat")

    const stopMakingObserver = useRef(false)

    const [messages, setMessages] = useState([]);
    const [fetching, setFetching] = useState(false)
    const [value, setValue] = useState('');

    const socket = useRef()
    const [connected, setConnected] = useState(false)
    const userName = {...store.user}.name + " " + {...store.user}.lastName



    const [pinnedFiles, setPinnedFiles] = useState([])
    const [spinner, setSpinner] = useState(false)



    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})

    const [imageSource, setImageSource] = useState()
    const [activeModalImgViewer, setActiveModalImgViewer] = useState("no")
    const [activeMessageModal, setActiveMessageModal] = useState("no")
    const [nestedContent, setNestedContent] = useState()
    const [lastSteps, setLastSteps] = useState([])
    const [stepCounter, setStepCounter] = useState(0)
    const nowDate = Date.now()

    const [showInvocationList, setShowInvocationList] = useState(false)
    const [fullUserList, setFullUserList] = useState([])
    const [invocationTo, setInvocationTo] = useState({})


    const [markedMessages, setMarkedMessages] = useState([])
    const [highLightedMessage, setHighLightedMessage] = useState()

    const [activeFileDoesntExist, setActiveFileDoesntExist] = useState("no")


    const [reactionVisibility, setReactionVisibility] = useState()
    const [reactions, setReactions] = useState(["👍", "👎", "❤", "💩", "😄", "😢", "😊", "😡", "🔥", "🤡"])
    const [styleForList, setStyleForList] = useState("")


    const [archivedCreator, setArchivedCreator] = useState("")
    const [archivedDoer, setArchivedDoer] = useState("")
    const [archivedModerator, setArchivedModerator] = useState("")



    const [messageSearching, setMessageSearching] = useState(false)
    const [foundedMessages, setFoundedMessages] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [showFoundedList, setShowFoundedList] = useState(false)


    async function messageSearch(search_string) {

        const response = await $api.get(`/message_search?chat_id=${archivedState ? "archived_arbitr" : chatId}&search_string=${search_string}&registrationDate=${archivedState ? null : store.user.registrationDate}`)
        console.log(response.data)
        setFoundedMessages(response.data)
        setShowFoundedList(true)

    }

    const searchMessageTimerRef = useRef()


    useEffect(() => {
        if(searchValue.length >= 3) {

            clearTimeout(searchMessageTimerRef.current)

            searchMessageTimerRef.current = setTimeout(() => {
                messageSearch(searchValue)
            }, 1000)

        } else {
            clearTimeout(searchMessageTimerRef.current)
            setShowFoundedList(false)
            setFoundedMessages([])
        }
    }, [searchValue])

    const [forwardToChat, setForwardToChat] = useState(false)

    const isChatSelected = (chat) => forwardingChatList.some(selectedChat => selectedChat._id === chat._id);

    const toggleForwardingChat = (chat) => {
        setForwardingChatList(prev => {
            const newForwardingChatState = [...prev];
            const chatIndex = newForwardingChatState.findIndex(selectedChat => selectedChat._id === chat._id);

            if (chatIndex !== -1) {
                newForwardingChatState.splice(chatIndex, 1);
            } else {
                newForwardingChatState.push(toJS(chat));
            }

            return newForwardingChatState;
        });
    };

    const renderForwardedMessages = (list, key_id, from, on_fly) => {

        console.log(list)
        console.log(from)

        const sortedList = list.sort((a, b) => {
            if (a._id < b._id) {
                return -1;
            }
            if (a._id > b._id) {
                return 1;
            }
            return 0;
        });

        const mappedArray = sortedList.map((message, index) => {

            console.log(message)
            //если сообщение не ... то нужно запросить по нему информацию

            return (
                <div key={message._id + key_id} className={"forwarded-message"}>
                    <h6 className={"rem08 bold margin-0"}>{
                        message.sender?.name
                            ?
                            message.sender?.lastName
                                ?
                                message.sender?.name + " " + message.sender?.lastName + ":"
                                :
                                message.sender?.name + ":"

                            :
                            message.sender_name
                    }</h6>

                    {message.links?.length
                        ?
                        linkRoller(message.links)
                        :
                        null
                    }
                    <div className={"rem08 margin-0"}>{processMessageText(message?.text)}</div>
                    {message.forwarded_array?.length || message.quote_disput || message.forwarded_disput?.length || message.forwarded?.length || message.quote || message.quote_forwarded_disput?.length || message.quote_forwarded?.length
                        ?
                        <a
                            href={"#"}
                            onClick={() => showNestedMessage(message._id, from, false, 1)}
                            className={'rem07'}
                        >
                            показать вложения
                        </a>
                        :
                        null
                    }



                </div>
            )
        })

        return mappedArray

    }




    async function giveReaction(message_id, reaction) {
        setReactionVisibility()

        const response = await $api.post('/give_reaction', {message_id, reaction, chatId, user_id: store.user.id, username: store.user.name + " " + store.user.lastName})
        console.log(response.data)
    }

    async function takeReactionBack(message_id, reaction) {
        console.log("TAKING BACK")
        setReactionVisibility()
        const response = await $api.post('/take_reaction_back', {message_id, reaction, chatId, user_id: store.user.id})
        console.log(response.data)
    }


    const styleTimeoutRef = useRef()

    const reactionListScrollCompensator = (target, message_id, index) => {


        const containerElement = target
        const listElement = target.querySelector('.reaction-list')
        console.log(listElement)
        const containerElementRect = containerElement.getBoundingClientRect()
        listElement.style.top = `${containerElementRect.bottom+25}px`

        clearTimeout(styleTimeoutRef.current);
        styleTimeoutRef.current = setTimeout(() => {
            setStyleForList({id: message_id, index: index})
        }, 1000)





    }


    const reactionScrollCompensator = (messageId) => {
        const messageElement = document.getElementById(messageId).querySelector('.ws-message-bottom');
        const reactionContainer = document.getElementById(messageId)?.querySelector('.reaction-add-container');

        if (messageElement && reactionContainer) {
            const messageRect = messageElement.getBoundingClientRect();

            // Вычисляем компенсацию
            const compensation = messageRect.bottom

            // Применяем компенсацию к стилю .reaction-add-container
            reactionContainer.style.top = `${compensation+25}px`;
        }
    };



    useEffect(() => {
        if(reactionVisibility) {
            setTimeout(() => {
                reactionScrollCompensator(reactionVisibility)
            }, 1)

        }
    }, [reactionVisibility])


    let lastDate = Date.now()
    const dateNow = Date.now()
    let lastUser = ""

    const currentMessageId = useRef(null)
    const canvasRef = useRef()


    const messageArrayValue = 50


    const [foundedInvokUsers, setFoundedInvokUsers] = useState()


    const [nowUserSearching, setNowUserSearching] = useState(false)

    async function getSearchingUsers(value, chatId) {
        setNowUserSearching(true)
        const emptyResult = <div className={"info-message"}>Нет совпадений</div>
        const response = await $api.get(`/search_users?value=${value}&chatId=${chatId}&my_id=${store.user.id}`)

        console.log(response.data)

        const mappedFoundedUserList = response.data.map(user => (
            <div
                className={"invocation-user-and-avatar-container"}
                key={user.id}
                onClick={() =>
                    setInvocationTo(prev => ({
                        ...prev,
                        [user.id]: user,
                    }))
                }
            >
                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`} />
                <p className={"invocation-user margin-0"}>{user.name + " " + user.lastName}</p>
            </div>
        ));

        setFoundedInvokUsers(mappedFoundedUserList.length ? mappedFoundedUserList : emptyResult)
        setNowUserSearching(false)
    }

    const userSearchTimer = useRef()


    useEffect(() => {

        clearTimeout(userSearchTimer.current)

        if(value.slice(0, 1) === "@") {
            setShowInvocationList(true)
        } else {
            setShowInvocationList(false)
        }


        if(value.slice(0, 1) === "@" && value.length > 3) {

            userSearchTimer.current = setTimeout(() => {getSearchingUsers(value.slice(1), chatId)}, 1000)


            console.log("ТУТ МЫ поКАЗЫВЕМ СПИсОК")

            // const filteredUsers = fullUserList
            //     .filter(user => {
            //               // Проверяем, имеются ли у пользователя username, name или lastName и они не пустые строки
            //         if (user.name && user.lastName) {
            //             // Фильтруем только пользователей с непустым именем, фамилией или username
            //             //return true
            //             return (
            //                 (user.name.toLowerCase() + " " + user.lastName.toLowerCase()).includes(value.slice(1).toLowerCase()) ||
            //                 (user.lastName.toLowerCase() + " " + user.name.toLowerCase()).includes(value.slice(1).toLowerCase())
            //             );
            //         }
            //     })
            //     .map(user => (
            //         <div
            //             className={"invocation-user-and-avatar-container"}
            //             key={user.id}
            //             onClick={() =>
            //                 setInvocationTo(prev => ({
            //                     ...prev,
            //                     [user.id]: user,
            //                 }))
            //             }
            //         >
            //             <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`} />
            //             <p className={"invocation-user margin-0"}>{user.name + " " + user.lastName}</p>
            //         </div>
            //     ));
            //
            //

            // const emptyResult = <div className={"info-message"}>Нет совпадений</div>

            // setFoundedInvokUsers(filteredUsers.length ? filteredUsers : emptyResult)

            if(!forwardingChatList.length) {
                console.log("CLEAR MARKED MESSAGES")
                setMarkedMessages([])
            }

            cancelEditing()
            cancelAnswering()
            setMessageSearching(false)
            setSearchValue("")

        } else {
            setFoundedInvokUsers([])
        }

    }, [value, fullUserList])



    // useEffect(() => {
    //     console.log(value)
    //     console.log(value.slice(0, 1) === "@")
    //     if(value.slice(0, 1) === "@" && fullUserList.length) {
    //
    //
    //         const filteredUsers = fullUserList
    //             .filter(user => {
    //                 // Проверяем, имеются ли у пользователя username, name или lastName и они не пустые строки
    //                 if (user.name && user.lastName) {
    //                     // Фильтруем только пользователей с непустым именем, фамилией или username
    //
    //                     return (
    //                         (user.name.toLowerCase() + " " + user.lastName.toLowerCase()).includes(value.slice(1).toLowerCase()) ||
    //                         (user.lastName.toLowerCase() + " " + user.name.toLowerCase()).includes(value.slice(1).toLowerCase())
    //                     );
    //                 }
    //             })
    //             .map(user => (
    //                 <div
    //                     className={"invocation-user-and-avatar-container"}
    //                     key={user.id}
    //                     onClick={() =>
    //                         setInvocationTo(prev => ({
    //                             ...prev,
    //                             [user.id]: user,
    //                         }))
    //                     }
    //                 >
    //                     <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`} />
    //                     <p className={"invocation-user margin-0"}>{user.name + " " + user.lastName}</p>
    //                 </div>
    //             ));
    //
    //
    //         const emptyResult = <div className={"info-message"}>Нет совпадений</div>
    //
    //         setFoundedInvokUsers(filteredUsers.length ? filteredUsers : emptyResult)
    //
    //         if(!forwardingChatList.length) {
    //             console.log("CLEAR MARKED MESSAGES")
    //             setMarkedMessages([])
    //         }
    //         cancelEditing()
    //         cancelAnswering()
    //         setMessageSearching(false)
    //         setSearchValue("")
    //         setShowInvocationList(true)
    //     } else {
    //         setShowInvocationList(false)
    //     }
    //
    // }, [value, fullUserList])

    // useEffect(() => {
    //     if(value === "@") {
    //         setTimeout(() => {
    //             window.scrollTo(0, document.body.scrollHeight)
    //         }, 200)
    //
    //     }
    // }, [value])


    useEffect(() => {
        if(Object.keys(invocationTo).length) {
            cancelAnswering()
            cancelEditing()
            setMessageSearching(false)
            setSearchValue("")
            if(!forwardingChatList.length) {

                setMarkedMessages([])
            }
            setValue("")
        }
    }, [invocationTo])


    // async function getChatUsers() {
    //     try {
    //         const response = await $api.get(`/get_users?chat_id=${chatId}&user_id=${store.user.id}`)
    //         console.log(response.data)
    //         setFullUserList(response.data)
    //     } catch {
    //         setErrorMessage({show: true, text: "Не удалось загрузить список пользователей"})
    //     }
    //
    //
    // }
    //
    // useEffect(() => {
    //     getChatUsers()
    // }, [])


    const [showArrowDown, setShowArrowDown] = useState(false)

    const veryFirstMessage = useRef()


    // async function processNewMessage() {
    //     const message = newChatMessage
    //
    //     console.log("We got message")
    //     console.log(message)
    //
    //     if(message.sender?._id !== store.user.id) {
    //         currentMessageId.current = await message._id
    //     }
    //
    //
    //     console.log(message)
    //
    //     if(stopDownFetchingRef.current) {
    //         console.log("SET MESSAGES")
    //         setMessages(prev => [message, ...prev])
    //     } else {
    //         console.log("SET TEMPORY")
    //         setTemporaryMessageStorage(prev => [message, ...prev])
    //     }
    // }
    //
    //
    // useEffect(() => {
    //
    //     if(newChatMessage !== "") {
    //         processNewMessage()
    //     }
    //
    // }, [newChatMessage])





    //этот реф призван хранить элементы которые обсервер уже пронаблюдал в зоне видимости,
    //чтобы при добавлении не добавить ничего второй раз
    const markedElements = useRef(new Set())

    const [arbitratedOrder, setArbitratedOrder] = useState()

    async function getArbitratedOrder() {
        const response = await $api.get('/get_arbitration')
        if(response.data.message !== "EMPTY") {
            setArbitratedOrder(response.data.message)
        }

    }


    const [archiveList, setArchiveList] = useState([])
    const [archivedInfo, setArchivedInfo] = useState()

    async function getArbitrageArchive() {

        const response = await $api.get('/get_archived_arbitr_list')
        console.log(response.data)
        setArchiveList(response.data)
    }


    async function setArchivedOrder(order_id) {
        try {

            setOrderChosen(false)

            const response = await $api.get(`/set_archived_order?order_id=${order_id}`)

            if (response.data === "OK") {
                setOrderChosen(true)

            }
        } catch {
            setErrorMessage({show: true, text: "Не удалось загрузить архивные данные"})
        }



    }


    useEffect(() => {
        if(typeof arbitrAccess === 'boolean') {
            setArbitratedOrder(prev => ({
                ...prev,
             ['arbitrAccess']: arbitrAccess
            }))
        }
    }, [arbitrAccess])

    const [orderChosen, setOrderChosen] = useState(false)


    useEffect(() => {


        setMessages([])

        if(archivedState) {
            getArbitrageArchive()

        } else {
            getArbitratedOrder()
        }



        const handlePressEsc = (event) => {
            if (event.code === 'Escape') {
                cancelEditing();
                cancelAnswering();
                cancelInvocating()
                setShowCanvas(true)
                setMessageSearching(false)
                setSearchValue("")
                setMarkedMessages([])
                setForwardingList([])
                setForwardingChatList([])
            }
        };

        document.addEventListener('keydown', handlePressEsc);
        document.addEventListener('click', (e) => handleDocClick(e));

        return () => {
            document.removeEventListener('keydown', handlePressEsc);
            document.removeEventListener('click', handleDocClick);
        };

    }, [archivedState, archivedInfo])





    async function makeActualObserver(list) {



        // Получаем ссылку на div .message-area
        const messageArea = document.querySelector('.message-area');


        // Создаем новый IntersectionObserver и передаем ему колбэк-функцию
        // для обработки изменений видимости элементов
        const messageObserver = new IntersectionObserver((entries) => {
            // Проходимся по каждой записи в массиве entries
            entries.forEach(async (entry) => {
                // Если элемент .chat-message находится в видимой части экрана
                if (entry.isIntersecting && !markedElements.current.has(entry.target.id)) {

                    markedElements.current.add(entry.target.id);
                    // Делаем что-то с этим элементом
                    console.log(entry.target.id, 'is visible');
                    await markAsReadWsChat(entry.target.id)
                    const myElement = document.getElementById(entry.target.id)
                    if (myElement) {
                        messageObserver.unobserve(myElement)
                    }
                }
            });
        }, {
            // Передаем ссылку на родительский элемент .message-area
            root: messageArea
        });

        const chatMessages = document.querySelectorAll('.ws-chat-left');

        //фильтруем массив элементов так, чтобы не добавлять элементы которые уже были отработаны
        const newElements = Array.from(chatMessages).filter(element => {

            const isExistingElement = markedElements.current.has(element.id);
            return !isExistingElement;
        });


        // Проходимся по каждому элементу .chat-message
        newElements.forEach((chatMessage) => {
            // Добавляем элемент в IntersectionObserver
            //console.log(chatMessage.getAttribute('data-read'))
            if (chatMessage.getAttribute('data-read') === "false") {
                console.log("Добавляем элемент - ", chatMessage)
                messageObserver.observe(chatMessage);
                //console.log("добавляем", chatMessage)
            }
        });
    }


    const [addFirstMessages, setAddFirstMessages] = useState(true)
    const [rollArea, setRollArea] = useState(true)
    const [messagesToRoll, setMessagesToRoll] = useState([])

    useEffect(() => {

        console.log("MessagesTOROll updated")

        if(!stopMakingObserver.current) {

            setTimeout(() => {
                makeActualObserver(messagesToRoll)
            }, 2000)
        }


        if(scrollToInvokeRef.current) {

            //setTimeout(() => {
            const targetElement = document.getElementById(invokedMessageIdRef.current);

            console.log(invokedMessageIdRef.current)
            console.log(targetElement)
            setTimeout(() => {


                if (targetElement) {

                    targetElement.scrollIntoView({
                        block: 'center',
                    });
                    invokedMessageIdRef.current = null
                }
            }, 10)

            stopDownFetchingRef.current = false
            stopFetching.current = false
            scrollToInvokeRef.current = false


        } else {

            if(canvasRef.current?.querySelector('.new-message-line') && rollArea) {

                console.log("SCROLL TO 1")
                canvasRef.current?.scrollTo({
                    top: canvasRef.current?.querySelector('.new-message-line').offsetTop - 150, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                    // behavior: 'smooth', // Прокручиваем с анимацией
                })

                setRollArea(false)

            } else {
                if(canvasRef.current?.scrollTop > -100 && !stopDownFetchingRef.current) {

                    const element = document.getElementById(rollToRef.current)
                    console.log("SCROLL TO 2")

                    canvasRef.current?.scrollTo({
                        top: element?.offsetTop - (canvasRef.current.offsetHeight + 35), // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                        //behavior: 'smooth', // Прокручиваем с анимацией
                    })

                }
            }



        }


        // if(canvasRef.current.querySelector('.new-message-line') && rollArea) {
        //
        //     console.log("SCROLL TO 1")
        //     canvasRef.current.scrollTo({
        //         top: canvasRef.current.querySelector('.new-message-line').offsetTop - 150, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
        //         // behavior: 'smooth', // Прокручиваем с анимацией
        //     })
        //
        //     setRollArea(false)
        //
        // }

    }, [messagesToRoll])



    useEffect(() => {



        if(canvasRef.current.scrollTop > -100 && !stopDownFetchingRef.current) {

            const element = document.getElementById(rollToRef.current)
            console.log("SCROLL TO 2")

            canvasRef.current.scrollTo({
                top: element?.offsetTop - (canvasRef.current.offsetHeight + 35), // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                //behavior: 'smooth', // Прокручиваем с анимацией
            })

        }


    }, [messagesToRoll])


    const stopDownFetchingRef = useRef(true)
    const stopFetching = useRef(false)

    const [downFetch, setDownFetch] = useState(false)

    let handleScroll = throttle(scroller, 300)



    function scroller(e) {

        //console.log('scroll')
        setReactionVisibility()

        if(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 100 && !stopFetching.current) {
            console.log("SCRLHNDLR CHANGING FETCHING")
            console.log('scroll 1')
            if(needSliceRef.current) {
                console.log('scroll 12')
                setSlicer(prev => prev + 1000000)
            }

            setFetching(true)

        } else if(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 100 && stopFetching.current) {

            console.log('scroll 2')
            setSlicer(prev => {
                console.log(prev + messageArrayValue)
                return prev + messageArrayValue
            })

        }


        if(e.target.scrollTop > -5 && !stopDownFetchingRef.current) {
            console.log("DOWN FETCHING")
            console.log('scroll 3')
            setDownFetch(true)

        } else if(e.target.scrollTop > -5 && stopDownFetchingRef.current) {
            console.log('scroll 4')
            setSlicer(messageArrayValue)

        }

        if (e.target.scrollTop < -150) {
            setShowArrowDown(true)
        }

        if (e.target.scrollTop > -1 && stopDownFetchingRef.current) {
            setShowArrowDown(false)
        }


        // console.log(e.target.scrollHeight)
        // console.log(e.target.scrollTop)
        // console.log(e.target.clientHeight)
    }



    const [slicer, setSlicer] = useState(messageArrayValue)
    const [needSlice, setNeedSlice] = useState(false)
    const needSliceRef = useRef(false)


    function throttle(func, delay) {
        let timerId;
        let lastExecTime = 0;

        return function (...args) {
            const currentTime = Date.now();

            if (currentTime - lastExecTime > delay) {
                func.apply(this, args);
                lastExecTime = currentTime;
            } else {
                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    func.apply(this, args);
                    lastExecTime = currentTime;
                }, delay);
            }
        };
    }




    const [nowFetching, setNowFetching] = useState(false)
    const rollToRef = useRef()





    async function getDownMessages() {


        const response = await $api.get(`/get_prev_messages?firstId=${firstIdRef.current}&register_date=${store.user.registrationDate}&chatId=${archivedState ? "arbitr_archive" : chatId}`)
        console.log(response.data)
        if(!response.data.stop) {
            await setMessages(prev => [...response.data.messages, ...prev])

            rollToRef.current = firstIdRef.current
            firstIdRef.current = response.data.firstId

        } else {
            console.log("stopDownFetchingRef.current SET TO true")
            stopDownFetchingRef.current = true
            setNeedSlice(true)
            needSliceRef.current = true
        }

        setDownFetch(false)
        setNowFetching(false)

    }


    useEffect(() => {
        if(stopDownFetchingRef.current) {

            setMessages(prev => [...prev, ...temporaryMessageStorage])

        }
    }, [stopDownFetchingRef.current])



    useEffect(() => {


        if (downFetch && messages. length && !nowFetching && firstIdRef.current && !stopDownFetchingRef.current) {
            setNowFetching(true)
            console.log("WE DOES DOWN FETCH")

            setDownFetch(false)

            getDownMessages()

        }


    }, [downFetch])



    async function getPrevMessages() {
        const response = await $api.get(`/get_prev_messages?lastId=${lastIdRef.current}&register_date=${store.user.registrationDate}&chatId=${archivedState ? "arbitr_archive" : chatId}`)
        console.log(response.data)
        if(response.data) {
            await setMessages(prev => [...prev, ...response.data.messages])
            if(response.data.lastId) {
                lastIdRef.current = await response.data.lastId
            } else {
                stopFetching.current = true
            }

        }

        setFetching(false)
    }


    useEffect(() => {

        //if(fetching && messages.length > fetchCounter) {

        if(fetching && messages.length) {
            console.log('WE DOES FETCH')
            getPrevMessages()
        }


    }, [fetching])


    async function markAllAsRead() {
        await $api.post('/mark_all_as_read', {my_id: store.user.id, register_date: store.user.registrationDate, chatId: chatId})
    }


    async function markAsReadWsChat(messageId) {

        await $api.post('/mark_as_read_ws_message', {message_id: messageId, user_id: store.user.id, chatId: chatId})

    }


    const lastIdRef = useRef()
    const firstIdRef = useRef()

    const [firstDataLoading, setFirstDataLoading] = useState(archivedState ? false : true)

    async function getFirstMessagesArray(need_unread) {


        try {
            setFirstDataLoading(true)
            setCleanTemp(true)
            const response = await $api.get(`/get_first_messages?id=${store.user.id}&register_date=${store.user.registrationDate}&need_unread=${archivedState ? false : need_unread}&chatId=${archivedState ? "archived_arbitr" : chatId}`)

            console.log(response.data)
            console.log("last ID is - ", response.data.lastId)
            lastIdRef.current = response.data.lastId
            firstIdRef.current = response.data.firstId
            veryFirstMessage.current = response.data.veryFirstMessage
            console.log(response.data.veryFirstMessage)
            console.log(response.data.stopDownFetching)

            console.log("stopDownFetchingRef.current SET TO ", response.data.stopDownFetching)
            stopDownFetchingRef.current = response.data.stopDownFetching

            if (response.data.stopDownFetching) {
                setNeedSlice(true)
                needSliceRef.current = true

            }

            await setMessages(prev =>[...response.data.messages])

        } catch (e) {

            setError(true)
            setErrorMessage(e.message)

        } finally {

            setTimeout(() => {
                setFirstDataLoading(false)
            }, 500)

        }



    }

    const [typingUser, setTypingUser] = useState()


    const [temporaryMessageStorage, setTemporaryMessageStorage] = useState([])
    const [cleanTemp, setCleanTemp] = useState(false)

    const [newMessageState, setNewMessageState] = useState()

    const processNewMessage = useCallback(() => processNM(newMessageState), [newMessageState, temporaryMessageStorage, cleanTemp])


    useEffect(() => {
        if(newMessageState) {
            stopMakingObserver.current = false
            processNewMessage(newMessageState)
        }
    }, [newMessageState])


    const storedSoundOn = localStorage.getItem(`sound_${chatId}`);
    const initialSoundOn = storedSoundOn ? JSON.parse(storedSoundOn) : true;

    const [soundOn, setSoundOn] = useState(initialSoundOn);


    const message_notification_sound = new Audio('/sounds/message1.mp3')

    function playSound() {
        message_notification_sound.play()
        new Notification('Тихое уведомление', { silent: true });
    }


    async function processNM(message) {
        //const message = newChatMessage

        console.log("We got message")
        console.log(message)

        if(message.sender?._id !== store.user.id) {
            currentMessageId.current = await message._id
        }


        console.log(message)

        if(stopDownFetchingRef.current) {
            console.log("SET MESSAGES")
            if(message.sender?._id !== store.user.id && soundOn) {
                playSound()
            }
            setMessages(prev => {
                return [message, ...prev]
            })

        } else {
            console.log("SET TEMPORY")
            if(cleanTemp) {
                setTemporaryMessageStorage([])
                setCleanTemp(false)
            } else {
                if(message.sender?._id !== store.user.id && soundOn) {
                    playSound()
                }
                setTemporaryMessageStorage(prev => [message, ...prev])
            }

        }

    }




    useEffect(() => {

        if(archivedState) {
            store.editWhitePointer({place: "Архив арбитражей:"})

            if(orderChosen) {
                getFirstMessagesArray()
            }

        } else {
            getFirstMessagesArray()
            store.editWhitePointer({place: "Арбитраж:"})


            socket.current = new WebSocket(`wss://${API_IP}:5323`)


            socket.current.onopen = () => {
                setConnected(true)
                const message = {
                    event: 'connection',
                    user: {...store.user}.id,
                    id: Date.now()
                }
                socket.current.send(JSON.stringify(message))
                console.log("WS подключение установлено")


            }

            socket.current.onmessage = async (event) => {
                const message = JSON.parse(event.data)


                if(message.event === "user_typing") {
                    setTypingUser(message.user)
                }

                if (message.event === 'message-arbitr') {

                    await setNewMessageState(message)

                    setTypersArray(prev => prev.filter(user => user.id !== message.sender?._id));

                }

                if(message.event === "editing" && message.chatId === chatId) {
                    setEditingData(message.data)
                }

            }

            socket.current.onclose = () => {
                console.log("Подключение закрылось")
                setConnected(false)

            }

            socket.current.onerror = () => {
                console.log("Ошибка сокета")

            }


            return () => {
                // Отписываемся от событий WebSocket
                socket.current.close();
            };

        }




    }, [archivedState, orderChosen])


    useEffect(() => {

        const element = canvasRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [canvasRef])


    useEffect(() => {

        setError(true)

    }, [errorMessage])

    const [messageSending, setMessageSending] = useState(false)


    async function sendMessage(trigger) {

        setMessageSending(true)

        console.log("JUST CLICKED SEND")

        let forwarding = trigger ? true : false

        let event



        if(forwarding) {
            if(trigger === 'chat') {
                if (chatId === 'disput') {
                    event = trigger ? 'private_message' : 'message-chat'
                } else if (chatId === 'arbitr') {
                    event = 'message-arbitr'
                }
            }

            if(trigger === 'private') {
                event = 'private_message'
            }

            //если пересылаем сообщение в лички
            if(trigger === 'private') {
                if (value !== "" || pinnedFiles.length) {
                    document.getElementById("chat-input").focus()
                    const message = {
                        receiver: forwardingList,
                        sender: store.user.id,
                        sender_name: store.user.name + " " + store.user.lastName,
                        sender_id: store.user.id,
                        username: store.user.name + " " + store.user.lastName,
                        text: value,
                        read: false,
                        date: Date.now(),
                        need_rolling: answeringMessage.need_rolling,
                        quote_disput: answeringMessage.id ? answeringMessage : null,
                        quote_sender: answeringMessage.sender,
                        quote_sender_name: answeringMessage.quote_sender_name,
                        quote_self: answeringMessage.self,
                        quote_date: answeringMessage.date,
                        quote_text: answeringMessage.text,
                        quote_links: answeringMessage.links,
                        forwarded_disput: markedMessages,
                        forwarded_array: markedMessages.map((markedMessageId) => messages.find((message) => message._id === markedMessageId) || null),
                        forwarded_from: chatId,
                        on_fly: true,
                        invocation: JSON.stringify(invocationTo) ?? null,
                        event: event,
                        links: pinnedFiles,

                    }

                    console.log(message)

                    //await socket.current.send(JSON.stringify(message));

                    if (true) {
                        try {
                            const response = await $api.post('/post_private_message_to_bd', {message})

                            console.log(response.data)
                            if (response.status < 400) {
                                downArrowAction()
                                console.log("Scrolling to 0")
                                // canvasRef.current.scrollTo({
                                //     top: 0,
                                //     //behavior: 'smooth'
                                // })


                            }

                        } catch {
                            console.log("чот ошибка какая то")
                            setError(true)
                            setErrorMessage("Сервер недоступен, попробуйте обновить страничку")
                        }
                    }
                }


                //если пересылаем сообщение в чаты
            } else {

                console.log(toJS(forwardingChatList))

                for(let chat of forwardingChatList) {
                    console.log(chat.chat_id)

                    let forwardChatId = chat.chat_id

                    event = `message-${chat.chat_id}`

                    //if (value !== "" || pinnedFiles.length) {
                    document.getElementById("chat-input").focus()
                    const message = {
                        receiver: null,
                        sender: {
                            _id: store.user.id,
                            name: store.user.name,
                            lastName: store.user.lastName
                        },
                        sender_name: store.user.name + " " + store.user.lastName,
                        sender_id: store.user.id,
                        username: store.user.name + " " + store.user.lastName,
                        text: value,
                        read: [],
                        date: Date.now(),
                        need_rolling: answeringMessage.need_rolling,
                        quote_disput: answeringMessage.id ? answeringMessage : null,
                        quote_sender: answeringMessage.sender,
                        quote_sender_name: answeringMessage.quote_sender_name,
                        quote_self: answeringMessage.self,
                        quote_date: answeringMessage.date,
                        quote_text: answeringMessage.text,
                        quote_links: answeringMessage.links,
                        forwarded_disput: markedMessages,
                        forwarded_array: markedMessages.map((markedMessageId) => messages.find((message) => message._id === markedMessageId) || null),
                        forwarded_from: chatId,
                        on_fly: true,
                        invocation: JSON.stringify(invocationTo) ?? null,
                        event: event,
                        links: pinnedFiles,

                    }

                    console.log(message)

                    //await socket.current.send(JSON.stringify(message));

                    if (true) {
                        try {
                            const response = await $api.post("/write_message_to_bd", {message, chatId: forwardChatId})

                            console.log(response.data)
                            if (response.status < 400) {
                                downArrowAction()
                                console.log("Scrolling to 0")
                                // canvasRef.current.scrollTo({
                                //     top: 0,
                                //     //behavior: 'smooth'
                                // })


                            }

                        } catch {
                            console.log("чот ошибка какая то")
                            setError(true)
                            setErrorMessage("Сервер недоступен, попробуйте обновить страничку")
                        }
                    }
                    //}


                }

            }





        } else {
            if (chatId === 'disput') {
                event = 'message-chat'
            } else if (chatId === 'arbitr') {
                event = 'message-arbitr'
            }


            if (value !== "" || pinnedFiles.length) {
                document.getElementById("chat-input").focus()
                const message = {
                    receiver: trigger ? forwardingList : null,
                    sender: trigger ? store.user.id : {
                        _id: store.user.id,
                        name: store.user.name,
                        lastName: store.user.lastName
                    },
                    sender_name: store.user.name + " " + store.user.lastName,
                    sender_id: store.user.id,
                    username: store.user.name + " " + store.user.lastName,
                    text: value,
                    read: trigger ? false : [],
                    date: Date.now(),
                    need_rolling: answeringMessage.need_rolling,
                    quote_disput: answeringMessage.id ? answeringMessage : null,
                    quote_sender: answeringMessage.sender,
                    quote_sender_name: answeringMessage.quote_sender_name,
                    quote_self: answeringMessage.self,
                    quote_date: answeringMessage.date,
                    quote_text: answeringMessage.text,
                    quote_links: answeringMessage.links,
                    forwarded_disput: markedMessages,
                    forwarded_array: markedMessages.map((markedMessageId) => messages.find((message) => message._id === markedMessageId) || null),
                    forwarded_from: chatId,
                    on_fly: true,
                    invocation: JSON.stringify(invocationTo) ?? null,
                    event: event,
                    links: pinnedFiles,

                }

                console.log(message)

                //await socket.current.send(JSON.stringify(message));

                if (true) {
                    try {
                        const response = await $api.post(trigger ? '/post_private_message_to_bd' : "/write_message_to_bd", trigger ? {message} : {
                            message,
                            chatId
                        })

                        console.log(response.data)
                        if (response.status < 400) {
                            downArrowAction()
                            console.log("Scrolling to 0")
                            // canvasRef.current.scrollTo({
                            //     top: 0,
                            //     //behavior: 'smooth'
                            // })


                        }

                    } catch {
                        console.log("чот ошибка какая то")
                        setError(true)
                        setErrorMessage("Сервер недоступен, попробуйте обновить страничку")
                    }
                }
            }



        }

        // if (chatId === 'disput') {
        //     event = trigger ? 'private_message' : 'message-chat'
        // } else if (chatId === 'arbitr') {
        //     event = 'message-arbitr'
        // }





        setAnsweringMessage({sender: null, quote_sender_name: null, text: null, id: null, date: null, self: null, links: null, need_rolling: null})

        setInvocationTo({})
        setPinnedFiles([])
        setValue("")
        setError(false)
        setMarkedMessages(prev => [])
        setShowCanvas(true)
        setForwardingList(prev => [])
        setForwardingChatList(prev => [])
        textAreaRef.current.style.height = "auto"
        setMessageSending(false)
        window.scrollTo(0, 0)

    }


    // async function sendMessage(trigger) {
    //
    //
    //         let event
    //
    //
    //         if (chatId === 'disput') {
    //             event = trigger ? 'private_message' : 'message'
    //         } else if (chatId === 'arbitr') {
    //             event = 'message-arbitr'
    //         }
    //
    //         if ((value !== "" || pinnedFiles.length) && arbitratedOrder) {
    //             document.getElementById("chat-input").focus()
    //             const message = {
    //                 receiver: trigger ? forwardingList : null,
    //                 sender: trigger ? store.user.id : { _id: store.user.id, name: store.user.name, lastName: store.user.lastName },
    //                 sender_name: store.user.name + " " + store.user.lastName,
    //                 sender_id: store.user.id,
    //                 username: store.user.name + " " + store.user.lastName,
    //                 text: value,
    //                 read: trigger ? false : [],
    //                 date: Date.now(),
    //                 need_rolling: answeringMessage.need_rolling,
    //                 quote_disput: answeringMessage.id ? answeringMessage : null,
    //                 quote_sender: answeringMessage.sender,
    //                 quote_sender_name: answeringMessage.quote_sender_name,
    //                 quote_self: answeringMessage.self,
    //                 quote_date: answeringMessage.date,
    //                 quote_text: answeringMessage.text,
    //                 quote_links: answeringMessage.links,
    //                 //forwarded_disput: markedMessages,
    //                 forwarded_array: markedMessages.map((markedMessageId) => messages.find((message) => message._id === markedMessageId) || null),
    //                 forwarded_from: chatId,
    //                 invocation: JSON.stringify(invocationTo) ?? null,
    //                 event: event,
    //                 links: pinnedFiles,
    //
    //             }
    //
    //             console.log(message)
    //
    //             //await socket.current.send(JSON.stringify(message));
    //
    //             if(connected){
    //                 try {
    //                     const response = await $api.post(trigger ? '/post_private_message_to_bd' : "/write_message_to_bd", trigger ? {message} : {message, chatId})
    //
    //                     console.log(response.data)
    //                     if (response.status < 400) {
    //                         downArrowAction()
    //                         console.log("Scrolling to 0")
    //                         // canvasRef.current.scrollTo({
    //                         //     top: 0,
    //                         //     //behavior: 'smooth'
    //                         // })
    //                     }
    //
    //                 } catch {
    //                     console.log("чот ошибка какая то")
    //                     setError(true)
    //                     setErrorMessage("Сервер недоступен, попробуйте обновить страничку")
    //                 }
    //             }
    //
    //             setAnsweringMessage({sender: null, quote_sender_name: null, text: null, id: null, date: null, self: null, links: null, need_rolling: null})
    //
    //             setInvocationTo({})
    //             setPinnedFiles([])
    //             setValue("")
    //             setError(false)
    //             setMarkedMessages(prev => [])
    //             setShowCanvas(true)
    //             setForwardingList(prev => [])
    //             textAreaRef.current.style.height = "auto"
    //
    //     }
    //
    // }


    // async function downloadHandler(path) {
    //
    //
    //     try {
    //         const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})
    //
    //
    //         //создаем блоб (Binary Large OBject) из ответа
    //         const blob = new Blob([response.data], { type: 'application/octet-stream' });
    //         //const blob = await response.blob()
    //         //из него генерируем ссылку на файл
    //         const downloadURL = URL.createObjectURL(blob)
    //         //создаем элемент ссылки
    //         const link = await document.createElement('a')
    //         //назначаем элементу собственно ссылку
    //         link.href = downloadURL
    //         //это тоже всё еще назначение ссылки (тут добавляется имя файла)
    //         link.download = await path.split('\\')[5]
    //         //добавляем то что создали в ДОМ
    //         document.body.appendChild(link)
    //         //кликаем
    //         link.click()
    //         //и избавляемся от элемента
    //         link.remove()
    //         URL.revokeObjectURL(downloadURL)
    //
    //     } catch (e) {
    //         setActiveFileDoesntExist("progress")
    //         setTimeout(() => {
    //             setActiveFileDoesntExist("active")
    //         }, 100)
    //     }
    //
    // }


    function linkRoller(linksArray) {
        const result = []
        for(let link of linksArray) {

            let type

            if(link.type === "doc" || link.type === "docx") {
                type = "word"
            } else if (link.type === "xls" || link.type === "xlsx") {
                type = "excel"
            } else if (link.type === "ppt" || link.type === "pptx") {
                type = "power_point"
            } else if (link.type === "rar" || link.type === "zip") {
                type = "winrar"
            } else if (link.type === "pdf") {
                type = "pdf"
            } else if (link.type === "image") {
                type = "image"
            } else {
                type = "doc_thumb"
            }

            if(type === "image") {

                result.push(
                    <div
                        className={"chat-pre-image-2 dont-drag"}
                        onClick={() => {
                            setActiveModalImgViewer("progress")
                            setTimeout(() => {
                                setActiveModalImgViewer("active")
                            }, 100)
                            setImageSource(link.path)
                        }}
                    >
                        {/*<a className={"link-from-roller"} href={API_URL + "/" + link.path} target={"_blank"}>*/}
                        <img
                            className={"chat-pre-image dont-drag"}
                            src={API_URL + "/" + link.thumbnail}

                        />
                        {/*</a>*/}

                    </div>

                )
            } else {

                const filenameWindows = link.path.substring(link.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                let filename;

                if (filenameWindows.includes('/')) {
                    console.log("БЛОК ИФФ")
                    filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                } else {
                    console.log("БЛОК ЭЛЗЕ")
                    filename = filenameWindows;
                }

                result.push(
                    <div>

                        <img className={"order-done-file-image dont-drag"} src={require(`../img/${type}.png`)} width={"50"}/>
                        <a
                            className={"fake-link link-from-roller "}
                            onClick={async() => {

                                const condition = await downloadHandler(link.path)

                                if(condition === "FAIL") {
                                    setActiveFileDoesntExist("progress")
                                    setTimeout(() => {
                                        setActiveFileDoesntExist("active")
                                    }, 100)
                                }
                            }}
                        >"{filename.length > 60 ? filename.slice(0, 60) + "..." + filename.slice(-10) : filename}"</a>

                    </div>

                )
            }

        }

        return result

    }


    async function deleteAttached(path, filetype) {


        setErrorMessage({show: false, text: ""})

        if(isMessageEditing) {

            setDeleteThisFiles(prev => prev.push({path, filetype}))

            setPinnedFiles((prevState) => {
                return prevState.filter((file) => file.path !== path);
            });

        } else {
            const response = await $api.post('/delete_ws_chat_file', {path, filetype})
            if(response.status < 400) {
                const newState = pinnedFiles.filter(item => item.path !== path)
                setPinnedFiles(prev => newState)
            } else {
                setErrorMessage({show: true, text: response.data.message})
            }
        }
    }


    async function handlePaste(e) {
        //e.preventDefault();


        const files = e.clipboardData.items;

        if (files) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].kind === 'file') {
                    console.log('Есть файлы');
                    setSpinner(true);
                    break;  // Прерываем цикл, так как файл уже найден
                }
            }
        }

        console.log(pinnedFiles.length < 3)

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if(file.size > 5000000){
                setErrorMessage({show: true, text: "Размер файла не должен превышеть 5мб"})
                return false
            }


            if (file.type.indexOf("image") !== -1) {
                const blob = file.getAsFile();
                //formData.append("files[]", blob, "pasted-image.png");
                formData.append("files[]", blob, `pasted-image-${v4()}.png`);
            }
        }

        if (formData.has("files[]")) {
            try {
                if(pinnedFiles.length < 3) {
                    const response = await $api.post(`/load_ws_chat_files/${store.user.id}`, formData);
                    console.log(response.data)
                    if(response.status < 400) {
                        setSpinner(false)
                        await setPinnedFiles(prev => [...prev, ...response.data.files])
                    }
                } else {
                    setSpinner(false)
                    setErrorMessage({show: true, text: "К одному сообщению можно приложить не более 3-х файлов"})
                }

            } catch (error) {
                setSpinner(false)
                setErrorMessage({show: true, text: "Что то с сервером"})
                // Обработай ошибку, если необходимо
            }
        }
    }



    async function handleFiles(files) {



        setErrorMessage({show: false, text: ""})
        if(files.length > 3) {
            setErrorMessage({show: true, text: "Максимум 3 файла в сообщении"})
            return false
        }

        for(let file of files) {
            if(file.size > 5000000){
                setErrorMessage({show: true, text: "Размер файла не должен превышеть 5мб"})
                return false
            }
        }

        if(pinnedFiles.length < 3) {
            setSpinner(true)
            setTimeout(async () => {

                const response = await $api.post(`/load_ws_chat_files/${store.user.id}`, files)
                console.log(response.data)
                 if(response.status < 400) {
                     setSpinner(false)
                     await setPinnedFiles(prev => [...prev, ...response.data.files])
                 } else {
                     setSpinner(false)
                     setErrorMessage({show: true, text: response.data.message})
                 }
            }, 3000)


        } else {
            setErrorMessage({show: true, text: "К одному сообщению можно приложить не более 3-х файлов"})
        }
        return true
    }


    function handleDocClick(event) {


        if(event.target.matches('.reaction')
            || event.target.matches('.reaction-add-container')
            || event.target.matches('.arrows')
        ) {
            return;
        } else {
            setReactionVisibility()
        }
    }


    function handleClick(event) {

        if(window.getSelection().toString()) {
            return;
        }

        if(event.target.matches('.reaction')
            || event.target.matches('.reaction-add-container')
            || event.target.matches('.arrows')
            || event.target.matches('.reaction-under-message')
            || event.target.matches('.reaction-counter')
            || event.target.matches('.no-mark-message')
        ) {
            return;
        }

        const current_message_id = event.currentTarget.id


        //если событие клика происходит не на элементе с селектором arrows
        if (!event.target.matches('.arrows')
            && !event.target.matches('.chat-pre-image')
            && !event.target.matches('a')
            && !event.target.closest('.answered-message')
        ) {

            // Если клик произошел на блоке div, выполняем действие
            if (markedMessages.indexOf(current_message_id) === -1) {
                console.log("SET 8")
                setMarkedMessages(prev => [...prev, current_message_id])
            } else {
                const newMarkedList = markedMessages.filter(item => item !== current_message_id)
                console.log("SET 9")
                setMarkedMessages(newMarkedList)
            }

        }
    };


    useEffect(() => {
        if(markedMessages.length) {
            cancelEditing()
            cancelAnswering()
            cancelInvocating()
            setMessageSearching(false)
            setSearchValue("")
            setShowInvocationList(false)
        }
    }, [markedMessages])


    useEffect(() => {
        if(highLightedMessage) {
            renderMessages()

        }
    }, [highLightedMessage])



    useEffect(() => {

        if(messages) {
            console.log("NEED TO RENDER")
            renderMessages()

        }
        if(markedMessages.length) {
            setIsMessageEditing(false)
        }

    }, [messages, markedMessages, slicer, needSlice, reactionVisibility, styleForList])


    const [answeringMessage, setAnsweringMessage] = useState({sender: null, quote_sender_name: null, text: null, id: null, date: null, self: null, links: null, need_rolling: null})

    useEffect(() => {
        if(answeringMessage.sender) {
            window.scrollTo(0, document.body.scrollHeight)
        } else {
            //28 это вертикальный размер пустой области ввода текста
            if(textAreaRef.current?.scrollHeight <= 28) window.scrollTo(0, 0)

        }
    }, [answeringMessage])

    function answerOnMessage(sender, sender_name, text, id, date, self, links, need_rolling) {
        setMarkedMessages([])
        cancelEditing()
        cancelInvocating()
        setMessageSearching(false)
        setSearchValue("")
        console.log("SET 2")
        console.log(need_rolling)
        console.log(sender, text, id, date, self)
        document.getElementById("chat-input").focus()
        setAnsweringMessage({sender: sender, quote_sender_name: sender_name, text: text, id: id, date: date, self: self, links: links, need_rolling: need_rolling})
    }

    function cancelAnswering() {
        console.log("SET 3")
        setAnsweringMessage({sender: null, quote_sender_name: null,  text: null, id: null, date: null, self: null, links: null, need_rolling: null})
    }

    function cancelInvocating() {
        setInvocationTo({})
    }



    function renderMessages() {

        let newMessagesLine = false
        let nmlSwitch = true


        let preparedArray

        if(needSlice) {
            preparedArray = messages.slice(0, slicer)
        } else {
            preparedArray = messages
        }



        const mappedMessages =


            preparedArray.map((message, index) => {

                //console.log(message)

                console.log(message.invocationTo)

                if(message.sender?._id !== store.user.id) {
                    console.log(message.read?.includes(store.user.id))
                }


                let markedMessage = false

                if (markedMessages.indexOf(message._id) > -1) {
                    markedMessage = true
                }

                newMessagesLine = false
                //флаг о необходимости разделительной линии дат
                const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                //дата из прошлого сообщения для установки в разделительную линиию
                const date = new Date(lastDate)
                //обновление переменной с последней датой, именно в такой строжайшей последовательности
                lastDate = message.date
                lastUser = message.sender?._id


                if((nmlSwitch
                    && message.sender?._id !== store.user.id
                    && message.read?.indexOf(store.user.id) !== -1)
                    ||
                    (nmlSwitch
                    && message.sender?._id === store.user.id
                    && index > 0)

                ) {
                    console.log(message.text)
                    newMessagesLine = true
                    nmlSwitch = false
                }
                if(message.sender?._id === store.user.id && index === 0) {
                    console.log("nmlSwitch to false")
                    nmlSwitch = false
                }


                return (
                    <div
                        className={`ws-chat-message-wrapper ${highLightedMessage === message._id ? "highlighted-message" : null} ${markedMessage ? "marked-message" : null}`}
                        key={message._id}
                        id={message._id}
                        onClick={(e) => handleClick(e)}
                    >

                        {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        {newMessagesLine && index !== 0 ?  <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}

                        <div
                            className={message.sender?._id === store.user.id
                                ? "wrapper-for-arrow wrapper-for-arrow-right"
                                : "wrapper-for-arrow wrapper-for-arrow-left"
                            }
                        >
                            {message.sender?._id === store.user.id
                                ?
                                <img
                                    className={"ws-chat-arrow-svg arrows dont-drag"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    // width={"25px"}
                                    onClick={(e) => {
                                        answerOnMessage(message.sender, message.sender?.name + " " + message.sender?.lastName, message.text, message._id, message.date, true, message.links, message.quote_disput)
                                    }}
                                />
                                :
                                null
                            }
                            {message.sender?._id === store.user.id && getMinutesBetweenDates(new Date (message.date).getTime(), Date.now()) < 60
                                ?
                                <img
                                    className={"ws-chat-arrow-svg arrows dont-drag"}
                                    src={SvgPencil}
                                    alt="Ответить"
                                    width={"25px"}
                                    onClick={(e) => {
                                        editMessage(message._id, message.text, message.links)
                                    }}
                                />
                                :
                                null
                            }

                        <div

                            className={message.sender?._id === store.user.id
                                ? "ws-chat-right chat-message"
                                : "ws-chat-left chat-message"
                            }
                            id={message._id}
                            data-read={message.read?.includes(store.user.id)}
                        >


                            {message.sender?._id === store.user.id
                                ?
                                <div>
                                    {message.invocationTo?.length //&& message.invocationTo !== ""
                                        ?
                                        <p className={"ws-chat-message-date inline margin-right-10"}>{"Для "}
                                            <span className={"underline"} title={`${Array.isArray(message.invocationTo) ? message.invocationTo.join(",\n ")  : null}`}>
                                                {
                                                    message.invocationTo[0] === "all" || message.invocationTo === "всех"
                                                        ? "всех"
                                                        :
                                                        message.invocationTo[0] === "online" || message.invocationTo === "online"
                                                            ? "всех кто online"
                                                            :
                                                            Array.isArray(message.invocationTo)
                                                                ?
                                                                message.invocationTo.length > 2
                                                                    ?
                                                                    message.invocationTo[0] + ", " + message.invocationTo[1] + ` и еще ${message.invocationTo.length - 2}`
                                                                    :
                                                                    message.invocationTo.join(", ")
                                                                :
                                                                message.invocationTo
                                                }
                                            </span>
                                        </p>
                                        :
                                        null
                                    }
                                    {new Date(Date.now()).toLocaleDateString() === new Date(message.date).toLocaleDateString()
                                        ?
                                        <span className={"ws-chat-message-date"}>{new Date(message.date).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}</span>
                                        :
                                        <span className={"ws-chat-message-date"}>{new Date(message.date).toLocaleString([],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'})}</span>}
                                </div>


                                :
                                <div className={"chat-username"}>

                                    <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${message.sender?._id}/avatar.png`}/>
                                    <div>
                                        <Link target={"_blank"} style={{ textDecoration: 'none', color: 'black' }} to={`/user-details/${message.sender?._id}`}>{message.sender?.name + " " + message.sender?.lastName}</Link>
                                        {message.invocationTo?.length
                                            ?
                                            <p className={"ws-chat-message-date inline margin-right-10"}>{" для "}
                                                <span className={"underline"}>
                                                    {
                                                        message.invocationTo[0] === "all" || message.invocationTo === "всех"
                                                            ? "всех"
                                                            :
                                                            message.invocationTo[0] === "online" || message.invocationTo === "online"
                                                                ? "всех кто online"
                                                                :
                                                                Array.isArray(message.invocationTo)
                                                                    ?
                                                                    message.invocationTo.join(", ")
                                                                    :
                                                                    message.invocationTo
                                                    }
                                                </span>
                                            </p>
                                            // <p className={"ws-chat-message-date inline"}> для <span className={"underline"}>{message.invocationTo?.to === "all" || message.invocationTo === "всех" ? "всех" : message.invocationTo}</span></p>
                                            // <p className={"ws-chat-message-date inline"}> для <span className={"underline"}>{message.invocationTo}</span></p>
                                            :
                                            null
                                        }
                                        <div>
                                            {new Date(Date.now()).toLocaleDateString() === new Date(message.date).toLocaleDateString()
                                                ?
                                                <span className={"ws-chat-message-date"}>{new Date(message.date).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}</span>
                                                :
                                                <span className={"ws-chat-message-date"}>{new Date(message.date).toLocaleString([],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'})}</span>

                                            }</div></div>

                                </div>

                            }
                            <div

                                className={message.sender?._id === store.user.id
                                    ? "ws-message-container-right"
                                    : "ws-message-container-left"
                                }
                            >
                                {message.forwarded_array?.length
                                    ?
                                    <div className={"private-message-forwarded-container"}>
                                        <h6 className={"rem08"}>Пересылаемые сообщения{chatNameResolver(message.forwarded_from, store.chatList)}:</h6>
                                        {renderForwardedMessages(message.forwarded_array, message._id, message.forwarded_from, message.on_fly)}
                                    </div>
                                    :
                                    null
                                }

                                {message.quote_disput
                                    ?
                                    <div
                                        className={"answered-message"}
                                    >
                                        {/*<p className={"private-message-date rem07"}>*/}
                                        {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
                                        {/*</p>*/}
                                        <div className={"nick-and-go"}>
                                            <span className={"bold rem07 nowrap"}>
                                                {message?.quote_disput?.sender?._id === store.user.id || message.quote_sender === store.user.id || message?.quote_sender === store.user.id
                                                    ? "Я"
                                                    : message.quote_sender_name || message?.quote_disput?.sender?.name + " " + message?.quote_disput?.sender?.lastName
                                                }:
                                            </span>
                                            <img
                                                title={"Перейти к сообщению"}
                                                src={SvgGoToMsg} width={"15px"}
                                                className={"pointer nowrap margin-left-10"}
                                                onClick={(e) => goToQuotedMessage(e, message.quote_disput)}
                                            />
                                            {/*<div*/}
                                            {/*    title={"Перейти к сообщению"}*/}
                                            {/*    className={"pointer nowrap margin-left-10"}*/}
                                            {/*    onClick={(e) => goToQuotedMessage(e, message.quote_disput)}*/}
                                            {/*>⏫</div>*/}
                                        </div>
                                        {message.quote_links?.length || message.quote_disput.links
                                            ?
                                            linkRoller(message.quote_links || message.quote_disput.links)
                                            :
                                            null
                                        }
                                        <span className={"rem08"}>
                                            {message.quote_disput.text
                                                ?
                                                <TextExpander text={message.quote_disput.text} />
                                                :
                                                <TextExpander text={message.quote_text} />
                                            }
                                        </span>
                                        {message.quote_disput.quote_disput || message.need_rolling
                                            ?
                                            <a className={"rem07"} href={"#"} onClick={() => showNestedMessage(message.quote_disput._id || message.quote_disput.id, chatId, false, 1)}>показать вложения</a>
                                            :
                                            null
                                        }

                                        {message.quote_disput.forwarded_array?.length
                                            ?
                                            <a className={"rem07"} href={"#"} onClick={() => showNestedMessage(message.quote_disput._id || message.quote_disput.id, chatId, false, 1)}>показать вложения</a>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {message.links.length
                                    ?
                                    linkRoller(message.links)
                                    :
                                    null
                                }
                                {message.link && message.filetype === "image"
                                    ?
                                    <div
                                        className={"chat-pre-image-2 dont-drag"}
                                        onClick={() => {
                                            setActiveModalImgViewer("progress")
                                            setTimeout(() => {
                                                setActiveModalImgViewer("active")
                                            }, 100)
                                            setImageSource(message.link.replace("thumbnails/", ""))
                                        }}
                                    >
                                        {/*<a className={"link-from-roller"} href={API_URL + "/" + link.path} target={"_blank"}>*/}
                                        <img
                                            className={"chat-pre-image dont-drag"}
                                            src={API_URL + "/" + message.link}

                                        />
                                        {/*</a>*/}

                                    </div>

                                    :
                                    null}

                                {message.link && message.filetype === "document"
                                    ?
                                    <a className={"ws-chat-link"} href={message.link}>
                                        <img className={"ws-chat-image-static dont-drag"} src={require("../img/doc_thumb.png")} width={"50"}/>
                                        <h6>{message.link.split("/")[7]}</h6>
                                    </a>
                                    :
                                    null}


                                <div className={"text-ws-container"} id="container">
                                    {processMessageText(message.text)}
                                </div>
                                <div className={"ws-message-bottom"}>
                                    {message.reactions
                                        ?
                                        <div className={"reaction-container rem08"}>
                                            {Object.keys(sortByArrayLength(message.reactions)).map((reaction, index) => {
                                                if(message.reactions[reaction].length) {
                                                    return (
                                                        <div
                                                            className={"reaction-and-list-container"}
                                                            onMouseOver={(e) => reactionListScrollCompensator(e.currentTarget, message._id, index)}
                                                            onMouseLeave={() => {
                                                                console.log(styleTimeoutRef.current)
                                                                clearTimeout(styleTimeoutRef.current)
                                                                setStyleForList({})
                                                            }}
                                                        >
                                                            <div
                                                                className={`reaction-under-message ${message.reactions[reaction].some(obj => Object.keys(obj)[0] === store.user.id) ? "light-blue" : null}`}
                                                                onClick={
                                                                    message.reactions[reaction].some(obj => Object.keys(obj)[0] === store.user.id) && message.sender?._id !== store.user.id
                                                                        ?
                                                                        () => takeReactionBack(message._id, reaction)
                                                                        :
                                                                        message.sender?._id !== store.user.id
                                                                            ?
                                                                            () => giveReaction(message._id, reaction)
                                                                            :
                                                                            null}

                                                            >
                                                                {`${reaction}`} <span className={"reaction-counter bold"}>{`${message.reactions[reaction].length}`}</span>


                                                            </div>
                                                            <div className={`reaction-list no-mark-message ${message._id === styleForList.id && styleForList.index === index ? "visible" : "" }`}>
                                                                {message.reactions[reaction].map(user => {
                                                                    return (
                                                                        <Link
                                                                            key={Object.keys(user)[0]}
                                                                            to={`/user-details/${Object.keys(user)[0]}`}
                                                                            target={"_blank"}
                                                                            className={"none-decoration no-mark-message"}
                                                                        >
                                                                            <div className={"reaction-list-item no-mark-message"}>
                                                                                <img className="avatar-micro dont-drag no-mark-message" src={`${API_URL}/public/avatars/${Object.keys(user)[0]}/avatar.png`}/>
                                                                                <div className={"no-mark-message"}>{Object.values(user)[0]}</div>
                                                                            </div>
                                                                        </Link>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })}
                                        </div>
                                        :
                                        null
                                    }
                                    {message.edited
                                        ?
                                        <div className={"edit-mark-wrapper"}>
                                            <p className={`${message.sender?._id === store.user.id ? "hints-on-blue" : "hints-on-orange"}`}>(ред.)</p>
                                        </div>

                                        :
                                        null
                                    }

                                </div>

                            </div>
                        </div>

                            {message.sender?._id !== store.user.id
                                ?
                                <div className={"wrapper-for-arrow wrapper-for-arrow-left"}>
                                    {message._id === reactionVisibility
                                        ?
                                        <div className={"reaction-add-container"}>
                                            {reactions.map(reaction => {
                                                return (
                                                    <div
                                                        key={message._id + reaction}
                                                        onClick={() => giveReaction(message._id, reaction)}
                                                        className={"reaction"}
                                                    >
                                                        {reaction}
                                                    </div>)
                                            })}

                                        </div>
                                        :
                                        null
                                    }

                                    <img
                                        className={"ws-chat-arrow-svg arrows dont-drag"}
                                        src={SvgHeart}
                                        alt="Реакция"
                                        width={"25px"}
                                        onClick={() => {
                                            //reactionScrollCompensator(message._id)
                                            setStyleForList({})
                                            setReactionVisibility(message._id)
                                        }}
                                    />
                                    <img
                                        className={"ws-chat-arrow-svg arrows dont-drag"}
                                        src={SvgAnswer}
                                        alt="Ответить"
                                        width={"25px"}
                                        onClick={(e) => {
                                            answerOnMessage(message.sender, message.sender?.name + " " + message.sender?.lastName, message.text, message._id, message.date, false, message.links, message.quote_disput)
                                        }}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                        {preparedArray.length - 1 === index && stopFetching.current ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        {veryFirstMessage.current === message._id ?  <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                    </div>)


                })


        setMessagesToRoll(prev => mappedMessages)
    }


    async function downArrowAction() {
        if (stopDownFetchingRef.current) {
            console.log("SCROLL TO 4")
            canvasRef.current.scrollTo({
                top: 0,
                //behavior: 'smooth'
            })
        } else {

            await getFirstMessagesArray(false)
            setTimeout(async () => {
                console.log("SCROLL TO 5")
                await canvasRef.current.scrollTo({
                    top: 0
                })
                setShowArrowDown(false)

            }, 500)

            setTimeout(() => {
                stopFetching.current = false
            }, 1000)

            markAllAsRead()

        }
    }

    const textAreaRef = useRef()
    const lastTextHeight = useRef(0)

    useEffect(() => {
        if(value === "" && !archivedState) {
            textAreaRef.current.style.height = "auto"
        }

    }, [value, archivedState])


    function textAreaAdjust() {

        const maxHeight = 300

        if(!archivedState) {
            textAreaRef.current.style.height = "auto"

            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            if(textAreaRef.current.scrollHeight > lastTextHeight.current) {

                lastTextHeight.current = textAreaRef.current.scrollHeight
                //window.scrollTo(0, document.body.scrollHeight)
            } else {

            }


            if (textAreaRef.current.scrollHeight > maxHeight) {
                textAreaRef.current.style.height = maxHeight + "px";
                textAreaRef.current.style.overflowY = "scroll";
            } else {
                textAreaRef.current.style.overflowY = "hidden";
            }


            if(textAreaRef.current.scrollHeight > 28) {
                window.scrollTo(0, document.body.scrollHeight)
            }
        }
    }

    function textReducer(text) {
        if (text.length > 150) {
            return text.slice(0, 150) + "..."
        }
        return text
    }

    const [showCanvas, setShowCanvas] = useState(true)
    const [foundedUsers, setFoundedUsers] = useState([])
    const [searchString, setSearchString] = useState(null)

    const [forwardingList, setForwardingList] = useState([])
    const [forwardingChatList, setForwardingChatList] = useState([])

    let search_timer


    async function findUsers(search_string) {

        clearTimeout(search_timer)
        search_timer = setTimeout(async () => {

            if(search_string.length) {
                setSearchString(search_string)
                const response = await $api.get(`/find_user?name=${search_string}&my_id=${store.user.id}`)
                setFoundedUsers(response.data)
            } else {
                setSearchString(null)
                setFoundedUsers([])
            }

        }, 1000)
    }


    function dateSet(date) {

        if(new Date(nowDate).toLocaleDateString() === new Date(date).toLocaleDateString()) {

            return new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        } else {

            return new Date(date).toLocaleDateString([], {month: "long", year: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})
        }
    }


    async function showNestedMessage(id, chatId, stepBack, marker) {
        console.log(id)
        console.log(marker, " - marker")
        console.log(chatId)
        const response = await $api.get(`/get_nested_message?message_id=${id}&chat_id=${chatId}`)
        console.log(response.data)
        await setNestedContent(response.data)
        if (stepBack) {
            console.log("step back")
            setStepCounter(stepCounter - 1)
            setLastSteps(prev => prev.slice(0, -1));
        } else {
            console.log("step forward")
            setStepCounter(stepCounter + 1)
            setLastSteps(prev => [...prev, id])
        }

        setActiveMessageModal("progress")
        setTimeout(() => {
            setActiveMessageModal("active")
        }, 100)
    }


    //__________________________________________________________________________________________________________________

    const [editingData, setEditingData] = useState()


    useEffect(() => {

        if(editingData) {
            refreshMessagesArray()
        }

    }, [editingData])




    const [editedText, setEditedText] = useState("")
    const [editedMessageId, setEditedMessageId] = useState()
    const [isMessageEditing, setIsMessageEditing] = useState(false)
    const [deleteThisFiles, setDeleteThisFiles] = useState([])
    const [showDelButton, setShowDelButton] = useState(true)

    function editMessage(messageId, text, links) {
        setMarkedMessages([])
        cancelAnswering()
        cancelInvocating()
        setMessageSearching(false)
        setSearchValue("")
        document.getElementById("chat-input").focus()
        setIsMessageEditing(true)
        setEditedMessageId(messageId)
        setEditedText(text)
        setPinnedFiles(links)
    }


    useEffect(() => {
        textAreaAdjust()
    }, [editedText])

    function cancelEditing() {
        setIsMessageEditing(false)
        setEditedMessageId()
        setEditedText("")
        setPinnedFiles([])
        setDeleteThisFiles([])
        setShowDelButton(true)
    }


    async function deleteMessage(admin) {

        if(admin) {
            setShowCanvas(true)
            setForwardingList(prev => [])
            setForwardingChatList(prev => [])
            setSearchString(null)
            const response = await $api.post(`/delete_chat_message`, {chatId, messages: markedMessages})
            if(response.data.message === "OK") {
                cancelEditing()
                textAreaAdjust()
                setMarkedMessages([])
            }
        } else {
            const response = await $api.delete(`/delete_chat_message?chatId=${chatId}&message_id=${editedMessageId}`,)
            if(response.data.message === "OK") {
                cancelEditing()
                textAreaAdjust()
            }
        }
    }

    async function saveEditedMessage() {

        const response = await $api.post('/chat_message_edit', {chatId, message_id: editedMessageId, text: editedText, pinnedFiles, deleteThisFiles})

        if(response.data.message === "OK") {
            cancelEditing()
            textAreaAdjust()
        }
    }


    function refreshMessagesArray() {

        stopMakingObserver.current = true

        console.log(editingData)

        if(editingData.action === "edit" || editingData.action === "reaction") {

            setMessages((prevMessages) => {
                return prevMessages.map((message) => {
                    if (message._id === editingData.message_id) {
                        // Если _id совпадает, обновляем текст в текущем сообщении
                        //return { ...message, text: editedText };
                        return { ...message,
                            text: editingData.text || message.text,
                            reactions: editingData.reactions || message.reactions,
                            links: editingData.pinnedFiles || message.links,
                            edited: editingData.action === "reaction" ? editingData.edit : true };
                    }
                    // В противном случае оставляем сообщение без изменений
                    return message;
                });
            });

        } else if (editingData.action === "delete") {

            setMessages((prevMessages) => {
                return prevMessages.filter((message) => !editingData.message_ids.includes(message._id));
            });
        }
    }


    const [shiftEnterPressed, setShiftEnterPressed] = useState(false);

    //___________________________________________________________________________________________________________________________


    const [drag, setDrag] = useState(false)



    const handleDragStart = (event) => {
        event.preventDefault()
        if (!event.target.classList.contains('dont-drag')) {
            event.preventDefault();
            setDrag(true);
            console.log("DragStart");
        }
    };

    const handleDragLeave = (event) => {
        event.preventDefault()
        setDrag(false)
        console.log("DragLeave")
    };

    const onDropHandler = (e) => {
        e.preventDefault()

        setDrag(false)
        handleFiles(e.dataTransfer.files)

    }


    const [threeDots, setThreeDots] = useState("...")
    const [somebodyTyping, setSomebodyTyping] = useState(true)
    const [typersArray, setTypersArray] = useState([])


    const timersRef = useRef({});


    useEffect(() => {

        if(typersArray.length) {
            setSomebodyTyping(true)
        } else {
            setSomebodyTyping(false)
        }


        if(typingUser?.chatId === chatId && !archivedState) {


            if (timersRef.current[typingUser.id]) {
                clearTimeout(timersRef.current[typingUser.id]);
                timersRef.current[typingUser.id] = setTimeout(() => {
                    setTypersArray(prev => prev.filter(user => user.id !== typingUser.id));
                }, 4000);
            } else {
                timersRef.current[typingUser.id] = setTimeout(() => {
                    setTypersArray(prev => prev.filter(user => user.id !== typingUser.id));
                }, 4000);
            }



            setTypersArray(prev => {
                if (prev.some(user => user.id === typingUser.id)) {
                    // Если уже есть объект с таким `id`, не добавляем его повторно
                    return prev;
                } else {
                    // Если нет объекта с таким `id`, добавляем `typingUser`
                    return [typingUser, ...prev];
                }
            })


        }
    }, [typingUser, archivedState])

    useEffect(() => {


        if(somebodyTyping) {
            setTimeout(() => {

                if(threeDots.length === 0) {
                    setThreeDots(prev => prev + ".")
                }
                if(threeDots.length === 1) {
                    setThreeDots("..")
                }
                if(threeDots.length === 2) {
                    setThreeDots("...")
                }
                if(threeDots.length === 3) {
                    setThreeDots("")
                }

            }, 300)
        }

    }, [threeDots, somebodyTyping])



    const userTyping = useCallback(() => {
        console.log("USER TYPING");
        socket.current.send(JSON.stringify({
            event: 'user_typing',
            user: { id: store.user.id, name: store.user.name, chatId: chatId }
        }));
    }, []);


    const [debounceTimeout, setDebounceTimeout] = useState(false)

    const iAmTyping = useCallback(throttle(userTyping, 1500), []);

    async function goToQuotedMessage(event, quote_disput) {
        console.log("GOTOQ!")


        if (!event.target.matches('.arrows')
            && !event.target.matches('.chat-pre-image')
            && !event.target.matches('a')
            && !event.target.matches('.no-click-to-go')

        ) {

            goToInvocation(quote_disput._id ?? quote_disput)
        }


        console.log("QUOTED MESSAGE ID - ", quote_disput._id ?? quote_disput)
    }


    async function getInvokedMessages(invoked) {
        console.log(toJS(store.invocations))
        console.log(invoked)
        const response = await $api.get(`/get_invoked_messages?chatId=${chatId}&invoked=${invoked}`)
        console.log(response.data)
        await setMessages(response.data.messages)
        setHighLightedMessage(invoked)


        rollToRef.current = firstIdRef.current
        firstIdRef.current = response.data.firstId
        lastIdRef.current = response.data.lastId



        setFetching(false)
        setDownFetch(false)
        setNowFetching(false)
    }


    const scrollToInvokeRef = useRef(false)
    const invokedMessageIdRef = useRef()


    async function goToInvocation(id) {

        console.log("goToInvocation ARMED")

        console.log(id)


        const invoked_message_id = id ?? store.invocations[chatId][0].message_id

        let needDBRequest = true

        for(let message of messages) {
            if(message._id === invoked_message_id) {
                needDBRequest = false
                break;
            }
        }

        if(needDBRequest) {

            scrollToInvokeRef.current = true
            invokedMessageIdRef.current = invoked_message_id

            console.log("WE NEED TO MAKE REQUEST")

            getInvokedMessages(invoked_message_id)


        } else {

            console.log("WE HAVE INVOKED MESSAGE IN STATE")

            const targetElement = document.getElementById(invoked_message_id);

            console.log(targetElement)

            if (targetElement) {
                await targetElement.scrollIntoView({
                    block: 'center',
                });

            }


            setHighLightedMessage(invoked_message_id)
            setTimeout(() => {
                setHighLightedMessage()
            }, 2000)

        }

    }



    return (



        <div className={"arbitration-main-container"}>



            {archivedState
                ?
                <div className={"arbitr-info-shield"}>
                    <select
                        className={"margin-bottom-20 archived-order-selector"}
                        defaultValue={""}
                        onChange={(e) => {

                            setArchivedOrder(e.target.value)


                            const selectedIndex = e.target.selectedIndex;
                            const selectedOption = e.target.options[selectedIndex];

                            console.log(selectedOption.dataset)
                            setArchivedInfo({title: `#${selectedOption.dataset.orderNumber} ${selectedOption.dataset.orderTitle}`, stopDate: selectedOption.dataset.stopDate, order_id: e.target.value});

                            setArchivedCreator({name: selectedOption.dataset.creator, id: selectedOption.dataset.creatorId})
                            setArchivedDoer({name: selectedOption.dataset.doer, id: selectedOption.dataset.doerId})
                            setArchivedModerator({name: selectedOption.dataset.moderator, id: selectedOption.dataset.moderatorId})

                        }}
                    >
                        <option value={""} disabled>
                            Заказ
                        </option>
                        {archiveList.map(item => {
                            // console.log(item)
                            return <option
                                key={item.order_id}
                                value={item.order_id}
                                title={item.order_title}
                                data-order-number={item.order_number}
                                data-order-title={item.order_title}
                                data-creator={item.creator.name + " " + item.creator.lastName}
                                data-creator-id={item.creator._id}
                                data-doer={item.doer.name + " " + item.doer.lastName}
                                data-doer-id={item.doer._id}
                                data-moderator={item.moderator.name + " " + item.moderator.lastName}
                                data-moderator-id={item.moderator._id}
                                data-stop-date={item.stopArbitrageDate}
                            >
                                {`#${item.order_number} ${item.order_title.slice(0, 30)}`}
                            </option>
                        })}
                    </select>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"ubuntu information-hard-left"}>Заказчик:</h5>
                        {archivedCreator
                            ?
                            <div className={"information-right"}>
                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${archivedCreator?.id}/avatar.png`}/>
                                <Link

                                    to={`/user-details/${archivedCreator?.id}`}
                                    target={"_blank"}

                                >{archivedCreator?.name}</Link>
                            </div>
                            :
                            <h6 className={"information-right"}>Не выбран</h6>
                        }
                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"ubuntu information-hard-left"}>Исполнитель:</h5>
                        {archivedDoer
                            ?
                            <div className={"information-right"}>
                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${archivedDoer?.id}/avatar.png`}/>
                                <Link
                                    to={`/user-details/${archivedDoer?.id}`}
                                    target={"_blank"}

                                >{archivedDoer?.name}</Link>
                            </div>
                            :
                            <h6 className={"information-right"}>Не выбран</h6>
                        }
                    </div>
                    {store.user.admin
                        ?
                        <div className={"order-information-mc-helper"}>
                            <h5 className={"ubuntu information-hard-left"}>Модератор:</h5>
                            {archivedModerator
                                ?
                                <div className={"information-right"}>
                                    <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${archivedModerator?.id}/avatar.png`}/>
                                    <Link
                                        to={`/user-details/${archivedModerator?.id}`}
                                        target={"_blank"}

                                    >{archivedModerator?.name}</Link>
                                </div>
                                :
                                <h6 className={"information-right"}>Не выбран</h6>
                            }
                        </div>
                        :
                        null
                    }
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"ubuntu information-hard-left"}>Арбитраж завершён:</h5>
                        {archivedInfo
                            ?
                            <h5 className={"information-right"}>
                                <span>{new Date(archivedInfo.stopDate).toLocaleString([],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'})}</span>
                            </h5>
                            :
                            <h6 className={"information-right"}>Не ясно</h6>
                        }
                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"ubuntu information-hard-left"}>Страница заказа:</h5>
                        {archivedInfo
                            ?
                            <Link
                                to={`/order-details/${archivedInfo?.order_id}`}
                                target={"_blank"}
                                className={"information-right btn btn-primary"}
                            >Открыть</Link>
                            :
                            <h6 className={"information-right"}>Нет</h6>
                        }
                    </div>
                </div>
                :
                <div className={"arbitr-info-shield"}>
                <div className={"order-information-mc-helper"}>
                    <h5 className={"information-hard-left"}>На обсуждении:</h5>
                    {arbitratedOrder
                        ?
                        <h6 className={"information-right"} title={arbitratedOrder?.title}>#{arbitratedOrder?.number} "{arbitratedOrder?.title?.slice(0, 100)}{arbitratedOrder?.length > 100 ? "..." : null}"</h6>
                        :
                        <h6 className={"information-right"}>Пока ничего нет</h6>
                    }
                </div>
                <div className={"order-information-mc-helper"}>
                    <h5 className={"information-hard-left"}>Заказчик:</h5>
                    {arbitratedOrder
                        ?
                        <div className={"information-right"}>
                            <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${arbitratedOrder?.creator._id}/avatar.png`}/>
                            <Link
                                className={"arbitr-user-link"}
                                to={`/user-details/${arbitratedOrder?.creator?._id}`}
                                target={"_blank"}
                                className={"arbitr-user-link"}
                            >{arbitratedOrder?.creator?.name} {arbitratedOrder?.creator?.lastName}</Link>
                        </div>
                        :null
                    }
                </div>
                <div className={"order-information-mc-helper"}>
                    <h5 className={"information-hard-left"}>Исполнитель:</h5>
                    {arbitratedOrder
                        ?
                        <div className={"information-right"}>
                            <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${arbitratedOrder?.doer._id}/avatar.png`}/>
                        <Link

                        to={`/user-details/${arbitratedOrder?.doer?._id}`}
                        target={"_blank"}
                        className={"arbitr-user-link"}
                        >{arbitratedOrder?.doer?.name} {arbitratedOrder?.doer?.lastName}</Link>
                    </div>
                    :
                    null}
                </div>
                <div className={"order-information-mc-helper"}>
                    <h5 className={"information-hard-left"}>Материалы:</h5>
                    {arbitratedOrder?.arbitrAccess
                        ?
                        <Link
                            to={`/order-details/${arbitratedOrder?.id}`}
                            target={"_blank"}
                            className={"btn primary-btn-color information-right"}
                        >Открыть</Link>
                        :
                        <button disabled className={"btn btn-secondary information-right"}>Не доступны</button>
                    }
                </div>

            </div>
            }

            <div
                className={"ws-chat-wrapper"}
                onDragStart={(e) => handleDragStart(e)}
                onDragLeave={(e) => handleDragLeave(e)}
                onDragOver={(e) => handleDragStart(e)}
            >


        {/*<div className={"shadow-helper"}>*/}
        <div className={"ws-chat-main"}>


            {/*{errorMessage.show ? <h6 className={'red'}>{errorMessage.text}</h6> : null}*/}
            {showCanvas
                ?
                drag
                    ?
                    <div
                        className={"drop-area"}
                        onDragStart={(e) => handleDragStart(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                        onDragOver={(e) => handleDragStart(e)}
                        onDrop={(e) => onDropHandler(e)}
                    >
                        <h3>Отпустите файл здесь</h3>
                        <img src={SvgUpload} width={"250"}/>
                    </div>
                    :
                    <div className={"ws-chat-arbitr-canvas-under message-area"} id={"chat-canvas"} ref={canvasRef}>

                        <div className={"fixed-arrow-at-container"}>
                            {store.invocations[chatId]?.length > 0
                                ?
                                <div
                                    className={`fixed-arrow`}
                                    onClick={() => {
                                        console.log("Летим к обращению!")
                                        goToInvocation()

                                    }}
                                >
                                    <img className={"dont-drag"} src={SvgAtSign} width={"35"}/>
                                </div>
                                :
                                null
                            }
                            {showArrowDown
                                ?
                                <div
                                    className={`fixed-arrow`}
                                    onClick={() => {
                                        console.log("Давайте скроллить!")
                                        downArrowAction()

                                    }}
                                >
                                    <img className={"dont-drag"} src={SvgArrowDown} width={"35"}/>
                                </div>
                                :
                                null
                            }
                        </div>

                        {messagesToRoll.length
                            ?
                            <div className={"ws-chat-canvas"}>
                                {messagesToRoll}
                            </div>
                            :
                            <div className={"wc-chat-empty-canvas"}>
                                {firstDataLoading
                                    ?
                                    <Loading/>
                                    :
                                    archivedState
                                        ?
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            Не выбран ни один заказ
                                        </div>
                                        :
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            Сообщений пока нет
                                        </div>
                                }

                            </div>

                        }

                    </div>

                :
                <div className={"ws-forwarding-list-wrapper"}>
                    <div className={"ws-forwarding-list-top"}>
                        <div className={"forward-choice-button-container"}>
                            <button
                                className={`btn ${forwardToChat ? "btn-secondary" : "btn-primary" }`}
                                onClick={() => setForwardToChat(false)}
                            >В личку</button>
                            <button
                                className={`btn ${!forwardToChat ? "btn-secondary" : "btn-primary" } margin-left-20`}
                                onClick={() => setForwardToChat(true)}
                            >В другой чат</button>
                        </div>
                        {!forwardToChat
                            ?
                            <input
                                placeholder={"Найти..."}
                                value={searchString}
                                onChange={(e) => findUsers(e.target.value)}
                            />
                            :
                            null
                        }
                    </div>
                    <div className={"ws-forwarding-list-middle"}>
                        <h4 className={"ubuntu"}>{forwardToChat ? "Куда" : "Кому"}:</h4>
                    </div>
                    {forwardToChat
                        ?
                        <div className={"ws-forwarding-list-bottom"}>
                            <button
                                className={`btn ${isChatSelected({"chat_id": "disput"}) ? "btn-primary" : "btn-secondary"}  ws-forwarding-contact-button`}
                                onClick={() => toggleForwardingChat({"chat_id": "disput"})}
                            >
                                {/*<img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>*/}
                                Светский диспут {isChatSelected({"chat_id": "disput"}) ? "✓" : null}
                            </button>
                            {store.chatList.map(chat => {
                                return (
                                    <button
                                        key={chat._id}
                                        className={`btn ${isChatSelected(chat) ? "btn-primary" : "btn-secondary"}  ws-forwarding-contact-button`}
                                        onClick={() => toggleForwardingChat(chat)}
                                    >
                                        {/*<img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>*/}
                                        {chat.chatName} {isChatSelected(chat) ? "✓" : null}
                                    </button>
                                );
                            })}
                            <button
                                className={`btn ${isChatSelected({"chat_id": "arbitr", _id: "arbitr"}) ? "btn-primary" : "btn-secondary"}  ws-forwarding-contact-button`}
                                onClick={() => {toggleForwardingChat({"chat_id": "arbitr", _id: "arbitr"})}}
                            >
                                {/*<img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>*/}
                                Арбитраж {isChatSelected({"chat_id": "arbitr", _id: "arbitr"}) ? "✓" : null}
                            </button>
                        </div>
                        :
                        <div className={"ws-forwarding-list-bottom"}>
                            {searchString
                                ?
                                foundedUsers.length
                                    ?
                                    foundedUsers.map(user => {
                                        return(
                                            <button
                                                key={user.id}
                                                className={`btn ${forwardingList.includes(user?.id) ? "btn-primary" : "btn-secondary" }  ws-forwarding-contact-button`}
                                                onClick={() => {
                                                    store.addToFullUserList({id: user.id, username: user.name + " " + user.lastName})
                                                    setSearchString("")
                                                    setForwardingList(prev => {
                                                        const newForwardingState = [...prev]
                                                        if(newForwardingState.includes(user?.id)) {
                                                            const superNewState = newForwardingState.filter(item => item !== user?.id)
                                                            return superNewState
                                                        } else {
                                                            newForwardingState.push(user?.id)
                                                            return newForwardingState
                                                        }

                                                    })
                                                }}

                                            >
                                                <img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                                {user.name + " " + user.lastName}
                                            </button>
                                        )})
                                    :
                                    <h4>{`По запросу "${searchString}" никто не найден`}</h4>

                                :
                                store.fullUserList.map(user => {
                                    return(
                                        <button
                                            key={user.id}
                                            className={`btn ${forwardingList.includes(user?.id) ? "btn-primary" : "btn-secondary" }  ws-forwarding-contact-button`}
                                            onClick={() => {
                                                setForwardingList(prev => {
                                                    const newForwardingState = [...prev]
                                                    if(newForwardingState.includes(user?.id)) {
                                                        const superNewState = newForwardingState.filter(item => item !== user?.id)
                                                        return superNewState
                                                    } else {
                                                        newForwardingState.push(user?.id)
                                                        return newForwardingState
                                                    }

                                                })
                                            }}
                                        >
                                            <img className="avatar-mini dont-drag" src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                            {user.username} {forwardingList.includes(user?.id) ? "✓" : null}
                                        </button>
                                    )})
                            }

                        </div>
                    }
                </div>
            }
            {/*{typersArray.length*/}
            {/*    ?*/}
            {/*    <p className={"somebody-writing"}>*/}
            {/*        {typersArray.length > 2*/}
            {/*            ?*/}
            {/*            typersArray[0]?.name + ", " + typersArray[1]?.name + ", и ещё " + (typersArray.length - 2).toString() + " человек"*/}
            {/*            :*/}
            {/*            typersArray.length === 2*/}
            {/*                ?*/}
            {/*                typersArray[0]?.name + " и " + typersArray[1]?.name*/}
            {/*                :*/}
            {/*                typersArray[0]?.name*/}
            {/*        } {` пиш${typersArray.length > 1 ? "ут" : "ет" }`}{threeDots}🖊*/}
            {/*    </p>*/}
            {/*    :*/}
            {/*    <p className={"somebody-writing"}></p>*/}
            {/*}*/}


        </div>
            {archivedState
                ?
                archivedInfo
                    ?

                    <div className={"ws-bottom-part"}>
                        <div className={"ws-chat-compose-form"}>

                            {/*{!Object.keys(invocationTo).length*/}
                            {/*    ?*/}
                            {/*    <div className={"sound-and-search-container"}>*/}
                            {/*        <div className={`search-message-control-container ${messageSearching ? "white-back-search" : ""}`}>*/}
                            {/*            <img*/}
                            {/*                className={"dont-drag search-lens-messages pointer"}*/}
                            {/*                src={SvgSearchLens}*/}
                            {/*                alt="Поиск по истории сообщений"*/}
                            {/*                title="Поиск по истории сообщений"*/}
                            {/*                width={"25px"}*/}
                            {/*                onClick={() => {*/}
                            {/*                    if(messageSearching) {*/}
                            {/*                        setSearchValue("")*/}
                            {/*                    }*/}
                            {/*                    setMessageSearching(prev =>!prev)*/}
                            {/*                    textAreaRef.current.focus()*/}
                            {/*                }}*/}
                            {/*            />*/}
                            
                            {/*        </div>*/}
                            
                            {/*    </div>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}

                        <div className={"textarea-and-invocation-list-container"}>



                            {searchValue.length >=3 && showFoundedList
                                ?
                                <div className="search-list-container">


                                    {foundedMessages.length
                                        ?
                                        foundedMessages.map(message => {
                                            return <div
                                                className={"invocation-user-and-avatar-container"}
                                                onClick={() => {
                                                    setShowFoundedList(false)
                                                    goToInvocation(message._id)
                                                }}
                                            >
                                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${message.sender?._id}/avatar.png`}/>
                                                <div className={"search-date-and-name-container"}>
                                            <span className={"ws-chat-message-date"}>
                                                {new Date(message.date).toLocaleString([],{year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})}
                                            </span>
                                                    <span className={"rem07 margin-right-10 bold"}>
                                                {message.sender?.name + " " + message.sender?.lastName + ":" }
                                            </span>
                                                </div>
                                                <div
                                                    title={message.text}
                                                    className={"answering-message"}>{highlightTextAroundPattern(message.text, searchValue, "blue")}
                                                </div>


                                            </div>
                                        })
                                        :
                                        <div
                                            className={"invocation-user-and-avatar-container bold"}
                                            //onClick={() => setInvocationTo({to: "all"})}
                                        >Совпадений не найдено</div>
                                    }



                                </div>
                                :
                                null
                            }

                            <textarea
                                ref={textAreaRef}
                                rows={1}
                                autoComplete={"off"}
                                id={"chat-input"}
                                className={`ws-chat-input-archive`}
                                placeholder={`Поиск по сообщениям...`}
                                value={
                                    searchValue
                                }
                                onChange={(e) => setSearchValue(e.target.value)}
                                onClick={() => setShowFoundedList(true)}

                            />
                        </div>

                    </div>
                        <h3
                            className={"ubuntu"}
                            title={archivedInfo?.title}
                        >{archivedInfo?.title.length > 50 ? archivedInfo?.title.slice(0, 50) + "..." : archivedInfo.title}</h3>
                    </div>
                    :
                    null
                :
                <div className={"ws-bottom-part"}>


                {Object.keys(invocationTo).length
                    ?
                    invocationTo.to === "all" || invocationTo.to === "online"
                        ?
                        <div className={"ws-answering-message-wrapper"}>
                            <div className={"answering-message"}>
                                {`Обращение ко всем ${invocationTo.to === "online" ? "кто онлайн" : ""}`}
                            </div>
                            <button
                                className={"btn inline margin-left-20 cancel-answering-button"}
                                onClick={() => cancelInvocating()}
                            >❌</button>
                        </div>
                        :
                        <div className={"ws-answering-message-wrapper"}>
                            <div className={"invocation-message"}>
                                Обращение к {Object.values(invocationTo).map(user => {
                                return (
                                    <div className={"invocator-container margin-top-5"}>
                                        <div>
                                            <img
                                                className={"ws-message-avatar dont-drag margin-left-10"}
                                                src={`${API_URL}/public/avatars/${user.id}/avatar.png`}
                                            />
                                            {user.name + " " + user.lastName}
                                        </div>
                                        <button
                                            className={"btn inline margin-left-20 cancel-answering-button rem07"}
                                            onClick={() => setInvocationTo(prev => {
                                                let newState = {...prev}
                                                delete newState[user.id]
                                                return newState
                                            })}
                                        >❌</button>
                                    </div>)
                            })}
                                {/*{`Обращение к ${invocationTo.name + " " + invocationTo.lastName}`}*/}
                                {/*<img className={"ws-message-avatar dont-drag margin-left-10"} src={`${API_URL}/public/avatars/${invocationTo.id}/avatar.png`}/>*/}
                            </div>
                            <button
                                className={"btn inline margin-left-20 cancel-answering-button"}
                                onClick={() => cancelInvocating()}
                            >❌</button>
                        </div>
                    :
                    null
                }

                {answeringMessage.text
                    ?
                    <h6 className={"margin-top-5"}>В ответ на:</h6>
                    :
                    answeringMessage.sender
                        ?
                        <div className={"ws-answering-message-wrapper"}>
                            <h6 className={"margin-top-5 answering-message"}>{`В ответ на сообщение от ${answeringMessage.quote_sender_name}`}</h6>
                            <button
                                className={"btn inline margin-left-20 cancel-answering-button"}
                                onClick={() => cancelAnswering()}
                            >❌</button>
                        </div>
                        :
                        null
                }
                {answeringMessage.text
                    ?
                    <div className={"ws-answering-message-wrapper"}>

                        <div className={"answering-message"}>
                            {processMessageText(textReducer(answeringMessage.text))}
                        </div>
                        <button
                            className={"btn inline margin-left-20 cancel-answering-button"}
                            onClick={() => cancelAnswering()}
                        >❌</button>
                    </div>

                    :
                    null
                }
                {isMessageEditing
                    ?
                    <div className={"ws-answering-message-wrapper"}>

                        <div className={"answering-message"}>
                            <h6>{`${showDelButton ? "Редактирование сообщения" : "Удаляем?" }`}</h6>
                        </div>
                        {showDelButton
                            ?
                            <button
                                className={"btn btn-secondary inline margin-left-20"}
                                onClick={() => cancelEditing()}
                            >Отменить</button>
                            :
                            null
                        }
                        {showDelButton
                            ?
                            <button
                                className={"btn btn-danger inline margin-left-20"}
                                onClick={() => setShowDelButton(false)}
                            >Удалить</button>
                            :
                            <div>
                                <button
                                    className={"btn btn-danger inline margin-left-20"}
                                    onClick={() => deleteMessage()}
                                >Да</button>
                                <button
                                    className={"btn btn-primary inline margin-left-20"}
                                    onClick={() => setShowDelButton(true)}
                                >Нет</button>
                            </div>

                        }
                    </div>
                    :
                    null
                }
                {markedMessages.length && (store.user.admin || store.user.maintenance) && showCanvas
                    ?
                    <div className={"ws-answering-message-wrapper"}>
                        <div className={"answering-message"}>
                            <h6>Админские права:</h6>
                        </div>
                        <button
                            className={"btn btn-danger inline margin-left-20"}
                            onClick={() => deleteMessage(true)}
                        >{`Удалить сообщени${markedMessages.length > 1 ? "я" : "е"}`}</button>
                    </div>
                    :
                    null
                }

                    <div>
                        {typersArray.length
                            ?
                            <div className={"writing-container"}>
                                {/*<p className={"somebody-writing"}>*/}
                                {typersArray.length > 2
                                    ?
                                    typersArray[0]?.name + ", " + typersArray[1]?.name + ", и ещё " + (typersArray.length - 2).toString() + " человек"
                                    :
                                    typersArray.length === 2
                                        ?
                                        typersArray[0]?.name + " и " + typersArray[1]?.name
                                        :
                                        typersArray[0]?.name
                                } {` пиш${typersArray.length > 1 ? "ут" : "ет" }`}{threeDots}🖊
                                {/*</p>*/}
                            </div>
                            :
                            <div className={"writing-container"}></div>
                        }

                <div className={"ws-chat-compose-form"}>

                    {!Object.keys(invocationTo).length
                        ?
                        <div className={"sound-and-search-container"}>
                            <div className={`search-message-control-container ${messageSearching ? "white-back-search" : ""}`}>
                                <img
                                    className={"dont-drag search-lens-messages pointer"}
                                    src={SvgSearchLens}
                                    alt="Поиск по истории сообщений"
                                    title="Поиск по истории сообщений"
                                    width={"25px"}
                                    onClick={() => {
                                        if(messageSearching) {
                                            setSearchValue("")
                                        }
                                        setMessageSearching(prev =>!prev)
                                        textAreaRef.current.focus()
                                    }}
                                />
                                {/*{!messageSearching*/}
                                {/*    ?*/}
                                {/*    <img*/}
                                {/*        className={"dont-drag search-lens-messages pointer"}*/}
                                {/*        src={soundOn ? SvgSoundOn : SvgSoundOff}*/}
                                {/*        alt={soundOn ? "Отключить звук" : "Включить звук"}*/}
                                {/*        title={soundOn ? "Отключить звук" : "Включить звук"}*/}
                                {/*        width={"25px"}*/}
                                {/*        onClick={() => {*/}
                                {/*            localStorage.setItem(`${"sound_"}${chatId}`, !soundOn);*/}
                                {/*            if(!soundOn) {*/}
                                {/*                playSound()*/}
                                {/*            }*/}
                                {/*            setSoundOn(prev =>!prev)*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}
                                {/*<div*/}
                                {/*    className={`animated-search-indicator pointer ubuntu red ${messageSearching ? 'visible' : ''}`}*/}
                                {/*    onClick={() => {*/}
                                {/*        setSearchValue("")*/}
                                {/*        setMessageSearching(false)*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    поиск активен*/}
                                {/*</div>*/}
                            </div>
                            {/*<div className={`sound-message-control-container`}>*/}
                            {/*    */}
                            {/*</div>*/}
                        </div>
                        :
                        null
                    }

                    <div className={"textarea-and-invocation-list-container"}>

                        {showInvocationList //&& !invocationTo
                            ?
                            <div className="user-list-container">
                                {value.slice(0, 1) === "@"
                                    ?
                                    value === "@"
                                        ?
                                        <div>

                                            <div
                                                className={"invocation-user-and-avatar-container"}
                                                onClick={() => setInvocationTo({to: "all"})}
                                            >
                                                <img className={"dont-drag margin-right-10"} src={SvgArrowsAlt} width={"35"}/>
                                                Ко всем
                                            </div>
                                            <div
                                                className={"invocation-user-and-avatar-container"}
                                                onClick={() => setInvocationTo({to: "online"})}
                                            >
                                                <img className={"dont-drag margin-right-10"} src={SvgAtSign} width={"35"}/>
                                                Ко всем кто онлайн
                                            </div>
                                            {fullUserList
                                                .filter(user => {
                                                    // Проверяем, имеются ли у пользователя username, name или lastName и они не пустые строки
                                                    if (user.name && user.lastName) {
                                                        // Фильтруем только пользователей с непустым именем, фамилией или username
                                                        return true

                                                    } else {

                                                        // Если у пользователя нет имени, фамилии или username, пропускаем его
                                                        return false;
                                                    }
                                                })
                                                .map(user => {
                                                    if(user.id !== store.user.id)
                                                        if(!Object.keys(invocationTo).includes(user.id))
                                                            return (<div
                                                                className={"invocation-user-and-avatar-container"}
                                                                key={user.id}
                                                                onClick={() => {
                                                                    if(invocationTo?.to) {
                                                                        setInvocationTo(({[user.id]: user}))
                                                                    } else {
                                                                        setInvocationTo(prev => ({
                                                                            ...prev,
                                                                            [user.id]: user
                                                                        }))
                                                                    }
                                                                }
                                                                }
                                                            >
                                                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${user.id}/avatar.png`}/>
                                                                <p
                                                                    className={"invocation-user margin-0"}
                                                                >{user.name + " " + user.lastName}</p></div>)
                                                })}
                                        </div>
                                        :
                                        value === "@all"
                                            ?
                                            <div
                                                className={"invocation-user-and-avatar-container"}
                                                onClick={() => setInvocationTo({to: "all"})}
                                            >
                                                Ко всем
                                            </div>
                                            :
                                            value === "@online"
                                                ?
                                                <div
                                                    className={"invocation-user-and-avatar-container"}
                                                    onClick={() => setInvocationTo({to: "online"})}
                                                >
                                                    Ко всем кто онлайн
                                                </div>
                                                :
                                                foundedInvokUsers

                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }


                        {/*{!Object.keys(invocationTo).length*/}
                        {/*&& !answeringMessage.quote_sender_name*/}
                        {/*&& !isMessageEditing*/}
                        {/*&& !markedMessages.length*/}
                        {/*// && !foundedMessages.length*/}
                        {/*&& !showInvocationList*/}
                        {/*&& !showFoundedList*/}


                        {/*    ?*/}
                        {/*    <div className={"sound-and-search-container"}>*/}
                        {/*        <div className={`search-message-control-container ${messageSearching ? "white-back-search" : ""}`}>*/}
                        {/*            <img*/}
                        {/*                className={"dont-drag search-lens-messages pointer"}*/}
                        {/*                src={SvgSearchLens}*/}
                        {/*                alt="Поиск по истории сообщений"*/}
                        {/*                title="Поиск по истории сообщений"*/}
                        {/*                width={"25px"}*/}
                        {/*                onClick={() => {*/}
                        {/*                    if(messageSearching) {*/}
                        {/*                        setSearchValue("")*/}
                        {/*                    }*/}
                        {/*                    setMessageSearching(prev =>!prev)*/}
                        {/*                    textAreaRef.current.focus()*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*            {!messageSearching*/}
                        {/*                ?*/}
                        {/*                <img*/}
                        {/*                    className={"dont-drag search-lens-messages pointer"}*/}
                        {/*                    src={soundOn ? SvgSoundOn : SvgSoundOff}*/}
                        {/*                    alt={soundOn ? "Отключить звук" : "Включить звук"}*/}
                        {/*                    title={soundOn ? "Отключить звук" : "Включить звук"}*/}
                        {/*                    width={"25px"}*/}
                        {/*                    onClick={() => {*/}
                        {/*                        localStorage.setItem(`${"sound_"}${chatId}`, !soundOn);*/}
                        {/*                        if(!soundOn) {*/}
                        {/*                            playSound()*/}
                        {/*                        }*/}
                        {/*                        setSoundOn(prev =>!prev)*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*                :*/}
                        {/*                null*/}
                        {/*            }*/}
                        {/*            <div*/}
                        {/*                className={`animated-search-indicator pointer ubuntu red ${messageSearching ? 'visible' : ''}`}*/}
                        {/*                onClick={() => {*/}
                        {/*                    setSearchValue("")*/}
                        {/*                    setMessageSearching(false)*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                поиск активен*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        /!*<div className={`sound-message-control-container`}>*!/*/}
                        {/*        /!*    *!/*/}
                        {/*        /!*</div>*!/*/}
                        {/*    </div>*/}
                        {/*    :*/}
                        {/*    null*/}
                        {/*}*/}

                        {messageSearching && searchValue.length >=3 && showFoundedList
                            ?
                            <div className="search-list-container">


                                {foundedMessages.length
                                    ?
                                    foundedMessages.map(message => {
                                        return <div
                                            className={"invocation-user-and-avatar-container"}
                                            onClick={() => {
                                                setShowFoundedList(false)
                                                goToInvocation(message._id)
                                            }}
                                        >
                                            <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${message.sender?._id}/avatar.png`}/>
                                            <div className={"search-date-and-name-container"}>
                                            <span className={"ws-chat-message-date"}>
                                                {new Date(message.date).toLocaleString([],{year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute:'2-digit'})}
                                            </span>
                                                <span className={"rem07 margin-right-10 bold"}>
                                                {message.sender?.name + " " + message.sender?.lastName + ":" }
                                            </span>
                                            </div>
                                            <div
                                                title={message.text}
                                                className={"answering-message"}>{highlightTextAroundPattern(message.text, searchValue, "blue")}
                                            </div>


                                        </div>
                                    })
                                    :
                                    <div
                                        className={"invocation-user-and-avatar-container bold"}
                                        //onClick={() => setInvocationTo({to: "all"})}
                                    >Совпадений не найдено</div>
                                }



                            </div>
                            :
                            null
                        }

                        <textarea
                            ref={textAreaRef}
                            rows={1}
                            autoComplete={"off"}
                            id={"chat-input"}
                            className={`ws-chat-input ${markedMessages.length && showCanvas ? "width-60" : null}`}
                            // className={`ws-chat-input ${markedMessages.length && !forwardingList.length ? "width-60" : null}`}
                            placeholder={`${messageSearching ? "Введите запрос..." : "Напишите сообщение..."}`}
                            value={
                                isMessageEditing
                                    ?
                                    editedText
                                    :
                                    messageSearching
                                        ?
                                        searchValue
                                        :
                                        value
                            }
                            onPaste={(e) => {
                                if(!messageSearching) {
                                    handlePaste(e)
                                }
                            }}

                            onClick={() => {
                                if(searchValue.length >= 3) {
                                    setShowFoundedList(true)
                                }
                            }}

                            onChange={e => {

                                if(!isMessageEditing && !messageSearching) {
                                    console.log("SET USUAL VALUE")
                                    if(value === "" && e.target.value === "\n") {
                                        ;
                                    } else {
                                        setValue(e.target.value)
                                        textAreaAdjust()
                                    }
                                } else {

                                    if(messageSearching) {
                                        console.log("SET SEARCHING VALUE")
                                        setSearchValue(e.target.value)
                                    } else {
                                        console.log("SET EDITING VALUE")
                                        if(editedText === "" && e.target.value === "\n") {
                                            ;
                                        } else {
                                            setEditedText(e.target.value)
                                            textAreaAdjust()
                                        }
                                    }


                                }
                            }

                            }
                            onKeyUp={(key) => {
                                if ((key.code === "ShiftLeft" || key.code === "ShiftRight") && !messageSearching) {
                                    setShiftEnterPressed(false);

                                }
                            }}

                            // onKeyDown={async (key) => {
                            //
                            //     if(!messageSearching) {
                            //         if (key.shiftKey) {
                            //             setShiftEnterPressed(true);
                            //         }
                            //
                            //
                            //         if (key.code === "Enter" && !shiftEnterPressed) {
                            //             console.log("CASE1")
                            //             key.preventDefault()
                            //             if(!isMessageEditing) {
                            //                 console.log("CASE11")
                            //                 if(value.length > 0 && value !== "\n") {
                            //                     console.log("CASE111")
                            //                     //этот превент позволяет добиться отмены добавления двойного переноса строки
                            //                     //при зажатом шифте, и оставить только один
                            //                     key.preventDefault()
                            //
                            //                 }
                            //             } else {
                            //                 console.log("CASE12")
                            //                 if(editedText.length > 0 && editedText !== "\n") {
                            //                     console.log("CASE121")
                            //                     key.preventDefault()
                            //                     const textarea = key.target;
                            //                     const start = textarea.selectionStart;
                            //                     const end = textarea.selectionEnd;
                            //
                            //                     const textBeforeCursor = editedText.substring(0, start);
                            //                     const textAfterCursor = editedText.substring(end);
                            //
                            //                     //await setEditedText(prev => prev + "\n")
                            //                     await setEditedText(textBeforeCursor + "\n" + textAfterCursor);
                            //
                            //                     // Восстановление положения курсора
                            //                     const newPosition = start + 1; // +1, так как добавили один символ
                            //                     textarea.setSelectionRange(newPosition, newPosition);
                            //
                            //                 }
                            //             }
                            //
                            //             textAreaAdjust()
                            //
                            //
                            //         }
                            //
                            //         if(key.code === "Enter" && !key.shiftKey || key.code === "NumpadEnter") {
                            //
                            //             console.log("CASE2")
                            //             if(showCanvas) {
                            //
                            //                 if(!isMessageEditing) {
                            //                     sendMessage()
                            //                 } else {
                            //                     saveEditedMessage()
                            //                 }
                            //
                            //             } else {
                            //                 sendMessage(true)
                            //             }
                            //
                            //         } else {
                            //             if(key.code !== "Enter" && key.code !== "NumpadEnter") {
                            //                 console.log("CASE3")
                            //                 if(!isMessageEditing) {
                            //
                            //                     if(!debounceTimeout) {
                            //                         setDebounceTimeout(true)
                            //                         setTimeout(() => {
                            //                             setDebounceTimeout(false)
                            //                         }, 1500)
                            //                         iAmTyping()
                            //                     }
                            //
                            //
                            //                 }
                            //                 //textAreaAdjust()
                            //             }
                            //         }
                            //     }
                            //
                            //
                            //
                            // }}
                            onKeyDown={async (key) => {
                                if (!messageSearching) {
                                    if (key.shiftKey) {
                                        setShiftEnterPressed(true);
                                    }

                                    if ((key.code === "Enter" && !shiftEnterPressed) || (key.code === "NumpadEnter" && !shiftEnterPressed)) {
                                        console.log("CASE1");
                                        key.preventDefault();

                                        if (!isMessageEditing) {
                                            console.log("CASE11");
                                            if (value.trim().length > 0 && value !== "\n") {
                                                console.log("CASE111");
                                                // Этот prevent позволяет добиться отмены добавления двойного переноса строки
                                                // при зажатом шифте, и оставить только один
                                                key.preventDefault();
                                            }
                                        } else {
                                            console.log("CASE12");
                                            if (editedText.trim().length > 0 && editedText !== "\n") {
                                                console.log("CASE121");
                                                key.preventDefault();
                                                const textarea = key.target;
                                                const start = textarea.selectionStart;
                                                const end = textarea.selectionEnd;

                                                const textBeforeCursor = editedText.substring(0, start);
                                                const textAfterCursor = editedText.substring(end);

                                                // Добавляем новую строку только если не редактируем сообщение
                                                // (если isMessageEditing = false)
                                                await setEditedText(textBeforeCursor + (isMessageEditing ? "" : "\n") + textAfterCursor);

                                                // Восстановление положения курсора
                                                const newPosition = start + 1; // +1, так как добавили один символ
                                                textarea.setSelectionRange(newPosition, newPosition);
                                            }
                                        }

                                        textAreaAdjust();

                                        if (showCanvas) {
                                            if (!isMessageEditing) {
                                                if(!messageSending) {
                                                    sendMessage();
                                                }

                                            } else {
                                                saveEditedMessage();
                                            }
                                        } else {
                                            if(!messageSending) {
                                                sendMessage(forwardToChat ? "chat" : "private");
                                            }

                                        }
                                    } else {
                                        if (key.code !== "Enter" && key.code !== "NumpadEnter") {
                                            console.log("CASE3");
                                            if (!isMessageEditing) {
                                                if (!debounceTimeout) {
                                                    setDebounceTimeout(true);
                                                    setTimeout(() => {
                                                        setDebounceTimeout(false);
                                                    }, 1500);
                                                    iAmTyping();
                                                }
                                            }
                                        }
                                    }
                                }
                            }}

                        />
                    </div>
                    <div className={"ws-ballast"}></div>
                    <form className={"ws-chat-input-file-wrapper"} id={"chat-form"}>
                        <input type={"file"} id={"ws-chat-input-file"}
                               accept={"image/png, image/gif, image/jpeg, application/pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .rar, .zip, .mp3"}
                               files={"files[]"}
                               multiple
                               onChange={(e) => {handleFiles(e.target.files)}}

                        />
                        {messageSearching || markedMessages.length
                            ?
                            null
                            :
                            <label htmlFor={"ws-chat-input-file"}>
                                <span className={"ws-input-file-icon-wrapper"}>
                                    <img className={"ws-input-file-icon dont-drag"} src={require("../img/skrepka.png")} width={"30"}/>
                                </span>
                            </label>
                        }
                    </form>
                    <div className={"ws-chat-send-button-container"}>
                        {markedMessages.length && showCanvas
                            ?
                            null
                            :
                            <button
                                disabled={(!showCanvas && !forwardingList?.length && !forwardToChat) || (!showCanvas && !forwardingChatList?.length && forwardToChat)}
                                className={"ws-chat-send-button btn primary-btn-color"}
                                onClick={() => {
                                    if(showCanvas) {
                                        if(!isMessageEditing) {
                                            if(messageSearching) {
                                                setMessageSearching(false)
                                                setSearchValue("")
                                                setFoundedMessages([])
                                            } else {
                                                if(!messageSending) {
                                                    sendMessage()
                                                }

                                            }

                                        } else {
                                            saveEditedMessage()
                                        }

                                    } else {
                                        if(!messageSending) {
                                            sendMessage(forwardToChat ? "chat" : "private")
                                        }

                                    }

                                }}>
                                {pinnedFiles.length
                                    ?
                                    isMessageEditing
                                        ?
                                        "Сохранить"
                                        :
                                        "Отправить"
                                    :
                                    showCanvas
                                        ?
                                        isMessageEditing
                                            ?
                                            "Сохранить"
                                            :
                                            messageSearching
                                                ?
                                                "Отмена"
                                                :
                                                "Сказать"
                                        :
                                        "Отправить"
                                }
                            </button>
                        }
                        {markedMessages.length
                            ?
                            <div className={"ws-forward-and-close-buttons-container"}>
                                {showCanvas
                                    ?
                                    <button
                                        className={"btn btn-warning margin-left-10"}
                                        onClick={() => {setShowCanvas(false)}}
                                        // onClick={() => {
                                        //     document.querySelector("#user-list").classList.add("rotated-180")
                                        //     if(forwardingList.length) {
                                        //         sendMessage()
                                        //     }
                                        // }}
                                    >Переслать ➔</button>
                                    :
                                    null
                                }

                                <button
                                    className={"btn btn-warning margin-left-10"}
                                    onClick={() => {
                                        setMarkedMessages(prev => [])
                                        setShowCanvas(true)
                                        setForwardingList(prev => [])
                                        setForwardingChatList(prev => [])
                                        setSearchString(null)
                                        // document.querySelector("#user-list").classList.remove("rotated-180")
                                    }}
                                >❌</button>
                            </div>
                            :
                            null
                        }
                    </div>

                </div></div>
                    {!messageSearching
                        ?
                        <p className={"hints-on-orange ws-search-hint"}>Новая строка Shift+Enter</p>
                        :
                        <p className={"hints-on-orange ws-search-hint"}>Кто ищет, тот найдёт!</p>
                    }
                {!connected ? <h6 className={'red'}>Потеряно соединение с сервером, нажмите F5</h6> : null}
                {spinner
                    ?
                    <div>
                        <p>Прикрепляем файлы...</p>
                        <img src={require('../img/loading.gif')} width={"35px"} className={"margin-left-20 dont-drag"}/>
                    </div>
                    :
                    null
                }

                {pinnedFiles.map((file, index) => {

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else {
                        type = "doc_thumb"
                    }
                    console.log(file.type)
                    const filenameWindows = file.path.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                    let filename
                    if (file.type !== "image" && file.type !== undefined) {
                        //filename = file.path.split('\\').pop().replace(/_/g, " ")
                        filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                    }
                    console.log(file)
                    return (
                        <div key={filename} className={"margin-top-20 private-attached-file-container"}>
                            {file.type === "image"
                                ?
                                <img className={"chat-pre-image dont-drag"} src={API_URL + '/' + file.thumbnail}/>
                                :
                                file.type !== undefined
                                    ?
                                    <div>
                                        <img className={"order-done-file-image dont-drag"} src={require(`../img/${type}.png`)} width={"50"}/>
                                        <div className={"inline"} key={index}>{filename.length > 70 ? filename.slice(0, 50) + " ... " + filename.slice(-10) : filename}</div>
                                    </div>
                                    :
                                    null
                            }

                            <button
                                className={"btn btn-outline-danger inline margin-left-20"}
                                onClick={() => deleteAttached(file.path, file.type)}
                            >❌</button>
                        </div>
                    )})}


                {errorMessage.show ? <div className={"ws-chat-error-message"}>{errorMessage.text}</div> : null}


            </div>
            }

            <ModalDialog
                active={activeFileDoesntExist}
                setActive={setActiveFileDoesntExist}
                action={() => {;}}
                // redMessage={`Вы считаете что по заказу #`}
                message={`Этот файл больше не существует`}
                purpose={"info"}
            />

            <ModalImgViewer
                active={activeModalImgViewer}
                setActive={setActiveModalImgViewer}
                action={() => {;}}
                imgSrc={imageSource}
                setImgSrc={setImageSource}

            />


            <NestedMessageModal
                active={activeMessageModal}
                setActive={setActiveMessageModal}
                message={nestedContent}
                dateSet={dateSet}
                // renderForwardedMessages={renderForwardedMessages}
                showNestedMessage={showNestedMessage}
                linkRoller={linkRoller}
                processMessageText={processMessageText}
                lastSteps={lastSteps}
                setLastSteps={setLastSteps}
                stepCounter={stepCounter}
                setStepCounter={setStepCounter}
                action={goToQuotedMessage}
                chatId={chatId}
            />
        {/*</div>*/}
        </div>

        </div>


    );
};

export default observer(WsChatArbitr);