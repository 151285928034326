import React, {useEffect, useState, useContext, useRef} from 'react';
import $api from "../http";
import {observer} from 'mobx-react-lite'
import {toJS} from "mobx";
import {useForm} from "react-hook-form";
import InputMask from 'react-input-mask';
import AuthService from "../services/AuthService";
import {Context} from "../index";
import MyEditor from "./helpers/AvatarEditor";
import {Link} from "react-router-dom";
import {getSixteenYearsAgoDate} from "./helpers/repeat_functions";
import SvgBackSq from "../img/arrow-left-svgrepo-sq.svg";
import SvgRedCross from "../img/red-cross.svg"
import ModalDialog from "./helpers/ModalDialog";
import FileAdderAndRoller from "./helpers/FileAdderAndRoller";
import Select from "react-select"
import {log} from "util";




const PersonalData = (props) => {

    const {store} = useContext(Context)

    //это стейт понимания того, существует ли уже юзер или только создается, для вилки в других стейтах этого элемента
    const [userExists] = useState(props.exists)

    //этот стейт сохраняет юзера из обновленных запросов
    const [user, setUser] = useState("")
    //этот стейт должен триггерить главный useEffect
    const [reload, setReload] = useState(false)
    const [reload2, setReload2] = useState(0)
    //эта группа стейтов для понимания находится ли у нас какой либо элемент персональных данных в фазе редактирования
    const [nameEdit, setNameEdit] = useState(false)
    const [lastNameEdit, setLastNameEdit] = useState(false)
    const [vkLinkEdit, setVkLinkEdit] = useState(false)
    const [phoneEdit, setPhoneEdit] = useState(false)
    const [birthDayEdit, setBirthdayEdit] = useState(false)
    const [cityEdit, setCityEdit] = useState(false)
    const [aboutEdit, setAboutEdit] = useState(false)
    const [disciplineSaved, setDisciplineSaved] = useState(false)

    const [errorMessage, setErrorMessage] = useState(false)

    const [callRequest, setCallRequest] = useState(false)
    const [verificationMethod, setVerificationMethod] = useState()

    const [haventTG, setHaventTG] = useState(false)
    const [haventCall, setHaventCall] = useState(false)

    const [vkError, setVkError] = useState(false)

    const [refuseLettersNewOrder, setRefuseLettersNewOrder] = useState(false)

    const [activeInfoModal, setActiveInfoModal] = useState("no")


    const [dataLoaded, setDataLoaded] = useState(false)


    const [portfolioFiles, setPortfolioFiles] = useState([])
    const [isMenuOpen, setIsMenuOpen] = useState(false)


    useEffect(() => {
        if(!store.user.name || !store.user.lastName || !store.user.phone) {
            setActiveInfoModal("progress")
            setTimeout(() => {
                setActiveInfoModal("active")
            }, 100)
        }
    }, [])


    const [privateCounting, setPrivateCounting] = useState(false)
    const [showOkPrivate, setShowOkPrivate] = useState(false)
    const [workCounting, setWorkCounting] = useState(false)
    const [showOkWork, setShowOkWork] = useState(false)

    async function recountPrivateMessages() {
        setPrivateCounting(true)
        const response = await $api.get(`/recount_private_messages?user_id=${store.user.id}`)
        store.setPrivateUnreadCounter(response.data)
        console.log(response.data)
        setPrivateCounting(false)
        setShowOkPrivate(true)


    }


    async function recountWorkMessages() {
        setWorkCounting(true)
        const response = await $api.get(`/recount_work_messages?user_id=${store.user.id}`)
        store.setWorkUnreadCounterIn(response.data.in)
        store.setWorkUnreadCounterOut(response.data.out)
        console.log(response.data)

        setWorkCounting(false)
        setShowOkWork(true)

    }

    async function setRefuseLetters(state) {
        const response = await $api.post('/set_refuse_new_order_letters', {user_id: store.user.id, state})
        console.log(response.data)
    }



    useEffect(() => {
        if(dataLoaded) {
            setRefuseLetters(refuseLettersNewOrder)
        }

    }, [refuseLettersNewOrder, dataLoaded])


    const formattedDate = getSixteenYearsAgoDate()


    //создаем переменную из хука useForm, он используется для валидации форм
    const {
        //эта штука позволит зарегистрировать поле формы (там ниже смотри, всё понятно будет)
        register,
        //это видимо как то отвечает за ошибки, там внизу тоже все это есть
        formState: {errors},
        //это видимо хрень которая отлавливает сабмит и что то делает)))
        handleSubmit,
        clearErrors,
    } = useForm({mode: "onSubmit"});//это режим проверки данных только после попытки сабмита, есть и другие если надо


    //в этот стейт помещаем массив со всеми дисциплинами что есть в базе, потом работаем с ними
    const [disciplines, setDisciplines] = useState([])
    //этот стейт отвечает за количество окошек выбора дисциплин
    const [count, setCount] = useState([1])
    //этот стейт отвечает за созданный список дисциплин текущего пользователя, который должен быть отправлен на сохранение
    const [disList, setDisList] = useState([])
    const [classifierList, setClassifierList] = useState([])
    //этт стейт хранит текущее значение дисциплины, которое выбрано в активном окошке с дисциплинами
    const [currentDis, setCurrentDis] = useState("")
    const [currentClassifier, setCurrentClassifier] = useState("")
    const [filterForDis, setFilterForDis] = useState(false)
    const [disciplineError, setDisciplineError] = useState(false)
    const [verifyStarted, setVerifyStarted] = useState(false)
    const [disableVerify, setDisableVerify] = useState(false)
    const [seconds, setSeconds] = useState(180)
    const [timer, setTimer] = useState(false)
    const [rightCode, setRightCode] = useState('')
    const [givenCode, setGivenCode] = useState('')
    const [codeOk, setCodeOk] = useState(false)
    const [outOfCoverage, setOutOfCoverage] = useState(false)


    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [vkLink, setVkLink] = useState('')
    const [phone, setPhone] = useState('')
    const [birthDay, setBirthDay] = useState('')
    const [city, setCity] = useState('')
    const [about, setAbout] = useState('')


    const [newPassword, setNewPassword] = useState("")
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const [passwordMessage, setPasswordMessage] = useState("")


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${minutes}:${remainingSeconds >= 10 ? remainingSeconds : "0" + remainingSeconds}`;
    };


    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);

        compareCode(inputValue);
    };



    useEffect(() => {
        store.editWhitePointer({place: "Мои данные:"})
        document.title = store.whitePointer.place
    }, [])



    async function getUserInfo() {

        const response = await $api.get(`user_info/${store.user.id}`, )
        console.log(response.data)
        await setUser(response.data)
        await setPhone(response.data.phone)
        await setName(response.data.name)
        await setLastName(response.data.lastName)
        await setVkLink(response.data.vkLink)
        await setDisList(response.data.disciplines)
        console.log(response.data.disciplines)
        await setClassifierList(response.data.classifiers ? response.data.classifiers : [])
        await setCity(response.data.city)
        await setBirthDay(response.data.birthDay)
        await setAbout(response.data.about)
        await setPortfolioFiles(response.data.portfolioFiles)
        if(response.data.letters) {
            await setRefuseLettersNewOrder(response.data.letters.new_orders)
        }

        setFilterForDis(true)

        setDataLoaded(true)


    }


    useEffect(() => {
        console.log("MAIN USE EFFECT TRIGGERED")
        console.log(userExists)
        // if(userExists) {

             getUserInfo()

        // } else {
        //     console.log("DOESNT EXIST")
        // }






    }, [reload])


    useEffect(() => {
        //если переменная с секундами больше нуля и таймер в положении тру и код не совпал
        //то запускаем таймер
        if (seconds > 0 && timer && !codeOk) {
            setTimeout(() => {
                setSeconds(seconds-1)
            }, 1000)
        } else {
            //в противном случае вырубаем таймер, выключаем флаг дизэйблверифай
            //проверочный код с этого момента имеет время жизни 120 секунд
            setTimer(false)
            setDisableVerify(false)
            setVerifyStarted(false)
            setRightCode("");

        }


    }, [seconds, timer, codeOk])


    async function delAnotherDis(e, dis) {

        e.preventDefault()
        const index = disList.indexOf(dis);

        console.log(disList)
        console.log(dis)
        console.log(index)



        if (index > -1) { // only splice array when item is found

            const response = await $api.get(`/get_classifier_id?discipline=${dis}`)
            const classToDel = response.data



            await setClassifierList(prev => {
                return prev.filter(item => item !== response.data)


            })
            await setDisciplines(prev => [...prev, dis].sort())
            await setDisList(prev => {

                const newState = prev.filter(item => item !== dis)


                $api.post(
                    '/save_user_dislist',
                    {
                        id: store.user.id,
                        disList: newState,
                        classifierList: classifierList.filter(item => item !== classToDel),
                    })

                return newState

            })


        }
    }


    useEffect(() => {

        if(reload2 > 0) {
            console.log("SPLICE DISCIPLINE TRIGGERED")

            setDisList(prev => [currentDis, ...prev])
            setClassifierList(prev => [...prev, classifierId])

            setDiscipline("")
            if(emptyMassive) {
                setScience()
            }


            console.log(disciplines)
            console.log(currentDis)

            const index = disciplines.indexOf(currentDis);

            if (index > -1) { // only splice array when item is found

                setDisciplines(prev => {
                    prev.splice(index, 1);
                    return prev
                }); // 2nd parameter means remove one item only
            }

        }

    }, [reload2])


    useEffect(() => {


        if(props.verifyPhone) {
            setCodeOk(true)
            clearErrors("phone")
            console.log('коды совпали')
            setVerifyStarted(false)
            setDisableVerify(false)
            //store.setUserPhone(phone)
            console.log("SENDING 1")
            sendData(true)
        }
    }, [props.verifyPhone])



    const [classifier, setClassifier] = useState()
    const [classifierNew, setClassifierNew] = useState()
    const [section, setSection] = useState()
    const [science, setScience] = useState()
    const [discipline, setDiscipline] = useState()

    const [emptyMassive, setEmptyMassive] = useState(false)
    const [disString1, setDisString1] = useState("")
    const [disString2, setDisString2] = useState("")
    const [classifierId, setClassifierId] = useState()

    const [verificationLinkTg, setVerificationLinkTg] = useState()





    async function loadClassifier() {
        const response = await $api.get('/get_classifier')
        setClassifierNew(response.data.new)

    }


    async function saveDisList() {
        console.log("saving DIS LIST")

        console.log(disList)
        console.log(classifierList)

        await $api.post(
            '/save_user_dislist',
            {
                id: store.user.id,
                disList,
                classifierList,
            })
    }


    useEffect(() => {
        if(section) {
            console.log("YOU ARE RIGHT!")
            saveDisList()
        }

    }, [disList])


    function isVKURL(str) {
        if(!str) return true
        // Регулярное выражение для проверки URL-адреса VK
        var urlRegex = /^https?:\/\/(www\.)?vk\.com\/\w*/i;
        console.log(str)
        console.log(urlRegex.test(str))
        return urlRegex.test(str);
    }


    async function sendData(codeOk) {

        console.log("SENDING DATA")
        console.log(codeOk)


        if(oldPassword && newPassword === newPasswordRepeat) {

            const response = await $api.post('/change_pass', {id: store.user.id, newPass: newPassword, oldPass: oldPassword})

            if(response.data.message === "Неверный пароль") {

                setPasswordMessage("Старый пароль не верный")
                return "stop"
            } else if(response.data.message === "Новый пароль сохранен") {
                setPasswordMessage("Новый пароль сохранён")
            }
        }


        console.log(toJS(store.user.id))
        if(isVKURL(vkLink)) {
            console.log("SAVING DATA")
            const response = await $api.post(
                '/save_user',
                {
                    id: store.user.id,
                    name: name?.trim(),
                    lastName: lastName?.trim(),
                    vkLink: vkLink?.trim(),
                    phone,
                    birthDay,
                    city: city?.trim(),
                    about,
                    codeOk
                })

            console.log(response.data)
            setUser(response.data)
            store.setUser(response.data)
            setReload(true)
            setLastNameEdit(false)
            setNameEdit(false)
            setVkLinkEdit(false)

            if(codeOk) {
                setPhoneEdit(false)
            }

            setCityEdit(false)
            setBirthdayEdit(false)
            setDisciplineSaved(true)



            setTimeout(() => {
                setDisciplineSaved(false)
            }, 3000)

            if (!props.exists) {
                //window.location.reload()
            }

            //store.setLoading(false)
        } else {
            console.log("FAIL TO SAVE")
            setVkError(true)
        }
    }



    async function setPhone2(xxx) {
        const purePhone = await xxx.replace("(", "").replace(")", "").replace("+", "").replace(" ", "").replace("-", "").replace("-", "")
        await setPhone(purePhone)

    }

    async function showPhone(phone, e, method) {

        e.preventDefault()

        if(phone.length === 11) {

            const code = String(Math.floor(Math.random()*10)) + String(Math.floor(Math.random()*10)) + String(Math.floor(Math.random()*10)) + String(Math.floor(Math.random()*10))
            //console.log(code)



            if(method === "call" || method === "SMS") {
                setVerificationMethod(method)
                setCallRequest(true)
                setOutOfCoverage(false)
                const response = await AuthService.viryPhone(phone, code, store.user.id, method);
                console.log(response.data)

                if(response.data.status || response.data.response?.msg?.err_code === "0" || (response.data.hasOwnProperty("errors") && response.data.errors === false)) {
                    setRightCode(code)
                    setVerifyStarted(true)
                    setDisableVerify(true)
                    setTimer(true)
                    setSeconds(180)

                } else if (response.data.status === false) {
                    setOutOfCoverage(true)
                } else if (response.data === "SMS_FAIL") {
                    setErrorMessage("Ошибка отправки SMS")
                } else if (response.data === "OK") {
                    setRightCode(code)
                    setVerifyStarted(true)
                    setDisableVerify(true)
                    setTimer(true)
                    setSeconds(180)
                }
                setTimeout(() => {
                    setCallRequest(false)
                }, 3000)
            } else if(method === "TG") {


                const response = await $api.post('/set_phone_to_actiovation_link', {phone, user_id: store.user.id})
                setErrorMessage()
                setVerificationMethod("TG")
                // if(response.data === "OK") {
                setVerifyStarted(true)
                setDisableVerify(true)

                const link = await document.createElement('a')

                if(window.location.hostname === "localhost") {
                    setVerificationLinkTg(`https://t.me/ultima_thule_test_bot?start=${phone}_${store.user.id}`)
                    //назначаем элементу собственно ссылку
                    link.href = `https://t.me/ultima_thule_test_bot?start=${phone}_${store.user.id}`
                } else {
                    setVerificationLinkTg(`https://t.me/ultima_thule_bot?start=${phone}_${store.user.id}`)
                    //назначаем элементу собственно ссылку
                    link.href = `https://t.me/ultima_thule_bot?start=${phone}_${store.user.id}`
                }


                link.target = "_blank"

                //добавляем то что создали в ДОМ
                document.body.appendChild(link)
                //кликаем
                link.click()
                //и избавляемся от элемента
                link.remove()

            }


            //две нижние строчки покажут код и ответ сервера в консоли браузера
            //console.log("New code is - " + code)
            //console.log(response.data)






        } else {
            console.log("ТЕЛЕФОН ГДЕ?")
        }

    }

    async function compareCode(codeToCompare) {
        setGivenCode(codeToCompare)

        if(codeToCompare.length === 4 && codeToCompare === rightCode) {
            setCodeOk(true)
            clearErrors("phone")
            console.log('коды совпали')
            setVerifyStarted(false)
            setDisableVerify(false)
            store.setUserPhone(phone)
            console.log("SENDING 2")
            sendData(true)

        }
    }


    useEffect(() => {


        loadClassifier()


    }, [filterForDis]);


    function edit(parameter) {
        setVkError(false)

        if(parameter === "name") {
            setName(store.user.name)
            setNameEdit(true)
            setPhoneEdit(false)

        } else if(parameter === "lastName") {
            setLastName(store.user.lastName)
            setLastNameEdit(true)
            setPhoneEdit(false)

        } else if (parameter === "vkLink") {
            setVkLink(store.user.vkLink)
            setVkLinkEdit(true)
            setPhoneEdit(false)

        } else if (parameter === "phone") {
            setPhone(store.user.phone)
            setPhoneEdit(true)

        } else if (parameter === "city") {
            setCity(store.user.city)
            setCityEdit(true)
            setPhoneEdit(false)

        } else if (parameter === "birthDay") {
            setBirthdayEdit(true)
            setPhoneEdit(false)
        }
    }


    const aboutTimerRef = useRef()

    useEffect(() => {


        if(dataLoaded) {
            clearTimeout(aboutTimerRef.current)
            aboutTimerRef.current = setTimeout(() => {
                console.log("SENDING 3")
                sendData()
            }, 1000)
        }



    }, [about, dataLoaded])


    const [selectScienceState, setSelectScienceState] = useState([])
    const [selectDisciplineState, setSelectDisciplineState] = useState([])

    const [scienceValue, setScienceValue] = useState("")
    const [disciplineValue, setDisciplineValue] = useState("")

    useEffect(() => {
        if(section) {
            const sectionSelectCalculation = section
                .map(item2 => item2.science_name)
                .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты
                .sort() // Сортируем в алфавитном порядке
                .map(scienceName => {
                    if(!disList.includes(scienceName)) {
                        return(
                            {
                                value: section.find(item => item.science_name === scienceName).science,
                                label: scienceName
                            }

                        )
                    }
                })
                .filter(item => item !== undefined); // Убираем undefined


            console.log(sectionSelectCalculation)


            setSelectScienceState(sectionSelectCalculation)

        }


    }, [section])



    useEffect(() => {
        if(science) {
            const disciplineSelectCalculation = science
                .map(item3 => item3.discipline)
                .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты
                .sort() // Сортируем в алфавитном порядке
                //этот фильтр выкидывает undefined элементы, которые со стандартным select никак не отсвечивали
                //а вот с react-select стали мешать
                .filter(disciplineName => {
                    return !disList.includes(disciplineName);
                })
                .map(disciplineName => {
                    return {
                        value: disciplineName,
                        label: disciplineName,
                        dataId: science.find(item => item.discipline === disciplineName)._id,
                    };
                });

            console.log(disciplineSelectCalculation)

            setSelectDisciplineState(disciplineSelectCalculation)
        }
    }, [science, disList])




    return (
        <div className={"personal-data-wrapper"}>
        <div className={"personal-data-shield"}
             // onSubmit={handleSubmit(sendData)}
        >


            <div className={"personal-data-underwrapper"}>
                <div className={"personal-data-top"}>
                    <div className={"personal-data-left-half"}>


                        <div className={"personal-data-balance-wrapper"}>
                            <Link to={"/pay"} className={"btn primary-btn-color inline"}>Внести платёж</Link>
                            <div className={"personal-data-balance-container"}>
                                <h6 className={"rem09 inline personal-data-balance"}>Баланс: {store.balance.toFixed(2)} ₽</h6>
                                <h6 className={"rem09 inline personal-data-balance"}>Следующее
                                    списание: {store.user.nextDebitDate ? new Date(store.user.nextDebitDate).toLocaleDateString() : null}</h6>
                            </div>
                        </div>

                        <hr/>
                        {/*<MyEditor exists={props.exists}/>*/}

                        <MyEditor exists={true}/>

                        <div className="error-container">{errors?.name &&
                            <p className="error-message">Давайте знакомиться, как вас зовут? =)</p>}</div>


                        {!nameEdit && store.user.name
                            ?
                            user
                                ?
                                <div className={"personal-data-container"}>
                                    <button
                                        className={"btn primary-btn-color btn-data-edit"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            edit("name")
                                        }}
                                    >✏
                                    </button>
                                    <h5 className={"inline personal-data-phone"}> {user.name}</h5>
                                </div>
                                :
                                null
                            :
                            <div className={"personal-data-container"}>
                                <button
                                    className={"btn primary-btn-color btn-data-edit inline"}
                                    onClick={(e) => {

                                        setNameEdit(false)
                                        sendData()
                                    }}
                                >{`${name?.length ? "✔️" : "❌"}`}</button>
                                <input
                                    {...register('name', {required: true})}
                                    className={"personal-data-input inline"}
                                    placeholder={"Имя"}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setName(e.target.value)
                                        clearErrors("name")
                                    }}
                                    value={name}
                                />
                            </div>
                        }

                        <div className="error-container">{errors?.lastName &&
                            <p className="error-message">Укажите фамилию, чтобы мы могли отличить вас от других
                                людей {name ? "с именем" : null} {name + " =)"}</p>}</div>

                        {!lastNameEdit && store.user.lastName
                            ?
                            user
                                ?
                                <div className={"personal-data-container"}>
                                    <button
                                        className={"btn primary-btn-color btn-data-edit"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            edit("lastName")
                                        }}
                                    >✏
                                    </button>
                                    <h5 className={"inline personal-data-phone"}>{user.lastName}</h5>
                                </div> : null

                            :
                            <div className={"personal-data-container"}>
                                <button
                                    className={"btn primary-btn-color btn-data-edit"}
                                    onClick={(e) => {
                                        if (!lastName?.length) {
                                            e.preventDefault()
                                        }
                                        setLastNameEdit(false)
                                        sendData()
                                    }}
                                >{`${lastName?.length ? "✔️" : "❌"}`}</button>
                                <input
                                    {...register('lastName', {required: true})}
                                    className={"personal-data-input inline"}
                                    placeholder={"Фамилия"}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        clearErrors("lastName")

                                        setLastName(e.target.value)
                                    }}
                                    value={lastName}
                                />
                            </div>
                        }

                        <div className="error-container">{errors?.birthDay &&
                            <p className="error-message">Мы бы хотели иметь шанс поздравить вас</p>}</div>
                        {!birthDayEdit && store.user.birthDay
                            ?
                            user
                                ?
                                <div className={"personal-data-container"}>
                                    <button
                                        className={"btn primary-btn-color btn-data-edit"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            edit("birthDay")
                                        }}
                                    >✏
                                    </button>
                                    <h5 className={"inline personal-data-phone"}>
                                        {new Date(user.birthDay).toLocaleDateString()}
                                    </h5>
                                </div>
                                :
                                null
                            :
                            <div className={"personal-data-container"}>
                                <button
                                    className={"btn primary-btn-color btn-data-edit"}
                                    onClick={(e) => {
                                        if (!birthDay) {
                                            e.preventDefault()
                                        }
                                        setBirthdayEdit(false)
                                        sendData()
                                    }}
                                >{`${birthDay ? "✔️" : "❌"}`}</button>
                                <input
                                    {...register('birthDay', {required: true})}
                                    type={"date"} className={"personal-data-input inline"}
                                    placeholder={"День рождения"}
                                    max={formattedDate}
                                    onChange={(e) => {
                                        setBirthDay(e.target.value)
                                        clearErrors("birthDay")
                                    }}
                                    defaultValue={birthDay}
                                />
                                <label className={"inline margin-left-20"}>День рождения</label>
                            </div>

                        }

                        <div className="error-container">{errors?.city &&
                            <p className="error-message">Как без города мы будем передавать погоду?))</p>}</div>


                        {!cityEdit && store.user.city
                            ?
                            user
                                ?
                                <div className={"personal-data-container"}>
                                    <button
                                        className={"btn primary-btn-color btn-data-edit"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            edit("city")
                                        }}
                                    >✏
                                    </button>
                                    <h5 className={"inline personal-data-phone"}>{user.city}</h5>
                                </div>
                                :
                                null

                            :
                            <div className={"personal-data-container"}>
                                <button
                                    className={"btn primary-btn-color btn-data-edit inline"}
                                    onClick={(e) => {
                                        if (!city?.length) {
                                            e.preventDefault()
                                        }
                                        setCityEdit(false)
                                        sendData()
                                    }}
                                >{`${city?.length ? "✔️" : "❌"}`}</button>
                                <input
                                    {...register('city', {required: true})}
                                    type={'text'}
                                    className={"personal-data-input inline"}
                                    placeholder={"Город"}
                                    onChange={(e) => {
                                        setCity(e.target.value)
                                        clearErrors("city")
                                    }}
                                    value={city}
                                />
                            </div>
                        }


                        <div className="error-container">{errors?.vkLink &&
                            <p className="error-message">Просто скопируйте ссылку на свою страницу, она должна быть
                                похожа на что то такое "https://vk.com/freelancer"</p>}</div>

                        {vkError
                            ?
                            <p className="error-message"> Здесь должны быть ссылка Вконтакте</p>
                            :
                            null
                        }

                        {!vkLinkEdit && store.user.name
                            ?
                            user
                                ?
                                <div className={"personal-data-container"}>
                                    <button
                                        className={"btn primary-btn-color btn-data-edit"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            edit("vkLink")
                                        }}
                                    >✏
                                    </button>
                                    <h5 className={"inline personal-data-phone"}>
                                        <a href={user.vkLink}>{user.vkLink ?? "ссылка ВК не предоставлена"}</a>
                                    </h5>
                                </div>
                                :
                                null
                            :
                            <div className={"personal-data-container"}>
                                <button
                                    className={"btn primary-btn-color btn-data-edit inline"}
                                    onClick={(e) => {
                                        if (!vkLink?.length) {
                                            e.preventDefault()
                                        }
                                        setVkLinkEdit(false)
                                        sendData()
                                    }}
                                >{`${vkLink?.length ? "✔️" : "❌"}`}</button>
                                <input
                                    //{...register('vkLink', {required: true, pattern: /(http:\/\/|https:\/\/)?(www.)?(vk\.com|vkontakte\.ru)\/(id(\d{9})|[a-zA-Z0-9_.]+)/g})}
                                    onChange={(e) => {
                                        setVkLink(e.target.value)
                                        clearErrors("vkLink")
                                    }}
                                    className={"personal-data-input inline"}
                                    placeholder={"Ссылка ВК"}
                                    value={vkLink}
                                />
                            </div>
                        }

                        {!phoneEdit && store.user.phone
                            ?
                            user
                                ?
                                <div className={"personal-data-phone-container"}>
                                    <button
                                        className={"btn primary-btn-color btn-data-edit"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            edit("phone")
                                        }}
                                    >✏
                                    </button>
                                    {!codeOk
                                        ?
                                        <h5 className={"inline personal-data-phone"}>+{user.phone}</h5>
                                        :
                                        <div className={"data-border personal-data-input margin-top-20"}>
                                            <h5 className={"inline personal-data-phone"}>+{user.phone}</h5>
                                            <label style={{color: "forestgreen", marginLeft: "15px"}}>✔</label>
                                        </div>
                                    }

                                </div> : null
                            :
                            <div className={"data-border personal-data-input margin-top-20"}>
                                <ul className={!codeOk ? null : "hide"}>
                                    <li>номер телефона доступен только администрации сервиса</li>
                                    <li>используется только в самых крайних случаях</li>
                                    <li>мы не присылаем рекламу и не сливаем номера третьим лицам</li>
                                </ul>
                                <div className={outOfCoverage ? "" : "hide"}><p className="error-message">Телефон
                                    недоступен</p></div>

                                <div
                                    className={!codeOk ? "error-container block" : "error-container block"}>{errors?.phone &&
                                    <p className="error-message">Нужно подтвердить телефон</p>}</div>
                                {disableVerify
                                    ?
                                    <h5 className={"inline personal-data-phone"}>+{phone}</h5>
                                    :
                                    codeOk
                                        ?
                                        <h5 className={"inline personal-data-phone"}>+{phone}</h5>
                                        :
                                        <InputMask
                                            {...register("phone", {validate: () => codeOk})}
                                            disabled={codeOk}
                                            className={"personal-data-input"}
                                            style={{display: "inline"}}
                                            onChange={(e) => {
                                                clearErrors("phone")
                                                setPhone2(e.target.value)
                                            }}
                                            alwaysShowMask={true}
                                            mask="+7 (\999)-999-9999"
                                            maskChar="_"
                                            value={phone}
                                            placeholder={"Телефон"}
                                        />
                                }


                                {codeOk
                                    ? <label style={{color: "forestgreen", marginLeft: "15px"}}>✔</label>
                                    : null
                                }

                                {!disableVerify
                                    ?
                                    <div className={"verification-buttons-container"}>
                                        {haventTG
                                            ?
                                            haventCall
                                                ?
                                                <div className={"inline"}>
                                                    <button
                                                        disabled={
                                                            (!phone?.includes("_") && phone?.length === 11 && !codeOk)
                                                                ?
                                                                null
                                                                :
                                                                "disabled"}
                                                        onClick={(e) => showPhone(phone, e, "SMS")}
                                                        className={codeOk ? "btn primary-btn-color hide" : "btn primary-btn-color"}
                                                    >по SMS
                                                    </button>
                                                    <button
                                                        className={"btn secondary-btn-color margin-left-20"}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setHaventCall(false)
                                                        }}
                                                    >
                                                        <img src={SvgBackSq} width={"20px"}/>
                                                    </button>
                                                </div>
                                                :
                                                <div className={"inline"}>
                                                    <button
                                                        disabled={
                                                            (!phone?.includes("_") && phone?.length === 11 && !codeOk)
                                                                ?
                                                                null
                                                                :
                                                                "disabled"}
                                                        onClick={(e) => showPhone(phone, e, "call")}
                                                        className={codeOk ? "btn primary-btn-color hide" : "btn primary-btn-color"}
                                                    >Запросить код
                                                    </button>
                                                    <button
                                                        disabled={
                                                            (!phone?.includes("_") && phone?.length === 11 && !codeOk)
                                                                ?
                                                                null
                                                                :
                                                                "disabled"}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setHaventCall(true)
                                                        }}
                                                        className={codeOk ? "btn primary-btn-color margin-left-20 hide" : "btn secondary-btn-color margin-left-20"}
                                                    >Нет звонка?
                                                    </button>
                                                    <button
                                                        className={"btn secondary-btn-color margin-left-20"}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setHaventTG(false)
                                                        }}
                                                    >
                                                        <img src={SvgBackSq} width={"20px"}/>
                                                    </button>
                                                </div>
                                            :
                                            <div className={"inline"}>
                                                <button
                                                    disabled={
                                                        (!phone?.includes("_") && phone?.length === 11 && !codeOk)
                                                            ?
                                                            null
                                                            :
                                                            "disabled"}
                                                    onClick={(e) => showPhone(phone, e, "TG")}
                                                    className={codeOk ? "btn primary-btn-color hide" : "btn primary-btn-color"}
                                                >Через Telegram
                                                </button>
                                                <button
                                                    disabled={
                                                        (!phone?.includes("_") && phone?.length === 11 && !codeOk)
                                                            ?
                                                            null
                                                            :
                                                            "disabled"}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setHaventTG(true)
                                                    }}
                                                    className={codeOk ? "btn primary-btn-color margin-left-20 hide" : "btn secondary-btn-color margin-left-20"}
                                                >Нет Telegram?
                                                </button>
                                            </div>
                                        }

                                    </div>
                                    :
                                    verificationMethod === "call" || verificationMethod === "SMS"
                                        ?
                                        callRequest
                                            ?
                                            <div className={"sms-call margin-top-10 margin-bottom-20"}>
                                                {haventCall
                                                    ?
                                                    <p className={"margin-0"}>Запрос SMS</p>
                                                    :
                                                    <p className={"margin-0"}>Запрос звонка</p>
                                                }

                                                <img className={"margin-left-20"} src={require('../img/loading.gif')}
                                                     height={"35px"}/>
                                            </div>
                                            :
                                            haventCall
                                                ?
                                                <p className={"red margin-top-20"}>повторное сообщение можно запросить
                                                    через {formatTime(seconds)}</p>
                                                :
                                                <p className={"red margin-top-20"}>повторный звонок можно запросить
                                                    через {formatTime(seconds)}</p>

                                        :
                                        <div>
                                            <a target={"_blank"} href={verificationLinkTg}>Открыть Telegram</a>
                                            <button
                                                className={"btn btn-danger margin-left-20"}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setDisableVerify(false)
                                                    setPhoneEdit(false)
                                                    setVerificationMethod()
                                                }}
                                            >Отменить
                                            </button>
                                        </div>


                                }

                                {verifyStarted
                                    ?
                                    <div>


                                        {verificationMethod === "call" || verificationMethod === "SMS"
                                            ?
                                            callRequest
                                                ?
                                                null
                                                :
                                                haventCall
                                                    ?
                                                    <p>сейчас мы пришлём проверочный код, введите его в строку ниже</p>
                                                    :
                                                    <p>сейчас вам позвонит робот и продиктует код</p>
                                            :
                                            null
                                        }


                                        {verificationMethod === "call" || verificationMethod === "SMS"
                                            ?
                                            <input
                                                style={{display: "inline"}}
                                                placeholder={"код подтверждения"}
                                                maxLength={4}
                                                type={"text"}
                                                onChange={(e) => handleInputChange(e)}
                                                onKeyDown={(e) => {
                                                    if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                            :
                                            null
                                        }


                                        {givenCode.length === 4
                                            ? <div style={{display: "inline"}}>❌</div>
                                            : null}
                                    </div>
                                    :
                                    null
                                }

                                {errorMessage
                                    ?
                                    <h4 className={"red"}>{errorMessage}</h4>
                                    :
                                    null
                                }
                            </div>


                        }
                        <hr/>
                        <label className={"margin-right-20 margin-bottom-20"}>Отказ от писем о новых заказах</label>
                        <input
                            type={"checkbox"}
                            checked={refuseLettersNewOrder}
                            onChange={(e) => {
                                setRefuseLettersNewOrder(e.target.checked)
                            }}
                        />
                        <br/>
                        <hr/>
                        <label className={"margin-bottom-10"}>Установить новый пароль</label>

                        <input

                            className={"block personal-data-input"}
                            type={"password"}
                            placeholder={"новый пароль"}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                                setPasswordMessage("")
                            }}
                            value={newPassword}

                        />
                        {newPassword && newPassword.length > 7
                            ?
                            <input
                                className={"block personal-data-input"}
                                type={"password"}
                                placeholder={"ещё раз"}
                                onChange={(e) => {
                                    setNewPasswordRepeat(e.target.value)
                                    setPasswordMessage("")
                                }}
                                value={newPasswordRepeat}
                            />
                            : !newPassword ?? newPassword.length > 7 ? null :
                                <h6 className={"error-message"}>Не менее 8 символов</h6>}
                        {newPasswordRepeat && newPassword === newPasswordRepeat
                            ?
                            <input
                                className={"block personal-data-input inline"}
                                type={"password"}
                                placeholder={"текущий пароль"}
                                onChange={(e) => {
                                    setOldPassword(e.target.value)
                                    setPasswordMessage("")
                                }}
                                value={oldPassword}
                            />

                            :
                            newPasswordRepeat && newPassword.length > 7
                                ?
                                <h6 className={"error-message"}>Не совпадают</h6>
                                :
                                null
                        }
                        {oldPassword.length > 0 && newPassword === newPasswordRepeat
                            ?
                            <button
                                className={"btn primary-btn-color btn-data-edit inline margin-left-20"}
                                onClick={() => {
                                    console.log("SENDING 4")
                                    sendData()
                                }}
                            >✔️</button>
                            : null}
                        {passwordMessage ? <h6 className={"error-message"}>{passwordMessage}</h6> : null}

                    </div>

                    <div className={"personal-data-right-half"}>

                        <div className={"save-blinker-container"}>
                        <h4 className={"personal-data-input"}>Я выполняю:</h4>
                        {disciplineSaved ? "Данные сохранены ✅" : null}
                    </div>


                    {/*<div className="error-container block">{disciplineError ? <p className="error-message">Надо бы указать предмет, хоть один</p> : null}</div>*/}


                    <div className={"dis-adder-n-save-indicator"}>
                        {/*{disciplines.length > 0 ? myDisciplines() : <h4 className={"red"}>Похоже вы выполняете всё и сразу</h4>}*/}


                    </div>


                    {/*{classifierNew*/}
                    {/*    ?*/}
                    {/*    <div className={"personal-classifier-wrapper"}>*/}
                    {/*        <div className={"classifier-section-container"}>*/}
                    {/*            <select*/}
                    {/*                className={"width-100p"}*/}
                    {/*                name={"section"}*/}
                    {/*                defaultValue={""}*/}
                    {/*                value={section?.section_name}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    const selectedSection = classifierNew.find(item => JSON.stringify(item.section) === e.target.value);*/}
                    {/*                    setSection(selectedSection.section);*/}
                    {/*                    setScience("");*/}
                    {/*                    setDiscipline("");*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <option value={""} disabled>*/}
                    {/*                    Раздел*/}
                    {/*                </option>*/}
                    {/*                {classifierNew.map(item1 => (*/}
                    {/*                    <option key={item1._id} value={JSON.stringify(item1.section)}>*/}
                    {/*                        {item1.section_name}*/}
                    {/*                    </option>*/}
                    {/*                ))}*/}
                    {/*            </select>*/}
                    {/*        </div>*/}

                    {/*        {section*/}
                    {/*            ?*/}
                    {/*            <div className={"new-order-classifier-science-discipline-container"}>*/}
                    {/*                <div className={"classifier-section-container"}>*/}
                    {/*                    <select*/}
                    {/*                        className={"width-100p"}*/}
                    {/*                        name={"science"}*/}
                    {/*                        value={science ? JSON.stringify(science) : ""}*/}
                    {/*                        onChange={(e) => {*/}
                    {/*                            const selectedScience = JSON.parse(e.target.value);*/}
                    {/*                            setScience(selectedScience);*/}
                    {/*                            const selectedSection = section.find(item => JSON.stringify(item.science) === e.target.value);*/}
                    {/*                            setEmptyMassive(selectedSection?.empty_massive || false);*/}

                    {/*                            if (selectedSection?.empty_massive) {*/}
                    {/*                                setClassifierId(selectedSection?.science[0]?._id);*/}
                    {/*                                setDiscipline(selectedSection?.science[0]?.discipline);*/}
                    {/*                                setReload2(prev => prev + 1);*/}
                    {/*                                setCurrentDis(selectedSection?.science[0]?.discipline);*/}
                    {/*                                setDisciplineError(false);*/}
                    {/*                            } else {*/}
                    {/*                                setClassifierId(null);*/}
                    {/*                            }*/}

                    {/*                            setDiscipline("");*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <option value={""} disabled>*/}
                    {/*                            Область*/}
                    {/*                        </option>*/}
                    {/*                        {section*/}
                    {/*                            .map(item2 => item2.science_name)*/}
                    {/*                            .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты*/}
                    {/*                            .sort() // Сортируем в алфавитном порядке*/}
                    {/*                            .map(scienceName => {*/}
                    {/*                                if(!disList.includes(scienceName)) {*/}
                    {/*                                    return(*/}
                    {/*                                        <option key={scienceName} value={JSON.stringify(section.find(item => item.science_name === scienceName).science)}>*/}
                    {/*                                            {scienceName}*/}
                    {/*                                        </option>*/}
                    {/*                                    )*/}
                    {/*                                }*/}
                    {/*                                })}*/}
                    {/*                    </select>*/}
                    {/*                </div>*/}

                    {/*                {science && !emptyMassive*/}
                    {/*                    ?*/}
                    {/*                    <div className={"classifier-section-container"}>*/}
                    {/*                        <select*/}
                    {/*                            className={"width-100p"}*/}
                    {/*                            name={"discipline"}*/}
                    {/*                            value={discipline}*/}
                    {/*                            onChange={(e) => {*/}
                    {/*                                const selectedDisciplineId = e.target.options[e.target.selectedIndex].getAttribute('data-id');*/}
                    {/*                                setClassifierId(selectedDisciplineId);*/}
                    {/*                                setDiscipline(e.target.value);*/}
                    {/*                                setReload2(prev => prev + 1);*/}
                    {/*                                setCurrentDis(e.target.value);*/}
                    {/*                                setDisciplineError(false);*/}
                    {/*                            }}*/}
                    {/*                            */}
                    {/*                        >*/}
                    {/*                            <option value={""} disabled>*/}
                    {/*                                Дисциплина*/}
                    {/*                            </option>*/}
                    {/*                            {science*/}
                    {/*                                .map(item3 => item3.discipline)*/}
                    {/*                                .filter((value, index, self) => self.indexOf(value) === index) // Убираем дубликаты*/}
                    {/*                                .sort() // Сортируем в алфавитном порядке*/}
                    {/*                                .map(disciplineName => {*/}

                    {/*                                    if(!disList.includes(disciplineName)) {*/}
                    {/*                                        return(*/}
                    {/*                                            <option*/}
                    {/*                                                key={disciplineName}*/}
                    {/*                                                value={disciplineName}*/}
                    {/*                                                data-id={science.find(item => item.discipline === disciplineName)._id}*/}
                    {/*                                                title={disciplineName}*/}
                    {/*                                            >*/}
                    {/*                                                {disciplineName}*/}
                    {/*                                            </option>*/}
                    {/*                                        )*/}
                    {/*                                    }*/}
                    {/*                                    })}*/}
                    {/*                        </select>*/}

                    {/*                    </div>*/}
                    {/*                    :*/}
                    {/*                    null*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*            :*/}
                    {/*            null*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    
                    {classifierNew
                        ?
                        <div className={"personal-classifier-wrapper"}>
                            <div className={"classifier-section-container"}>
                                <Select
                                    placeholder={"Раздел"}
                                    className={"discipline-react-select"}
                                    options={
                                        classifierNew.map(item1 => (
                                            {
                                                value: item1.section,
                                                label: item1.section_name
                                            }
                                        ))
                                    }
                                    onChange={(selected) => {
                                        console.log(selected)
                                        const selectedSection = classifierNew.find(item => JSON.stringify(item.section) === JSON.stringify(selected.value));
                                                setSection(selectedSection.section);
                                                setScience("");
                                                setDiscipline("");
                                                setScienceValue("")
                                                setDisciplineValue("")
                                    }}
                                
                                />

                            </div>

                            {section
                                ?
                                <div className={"new-order-classifier-science-discipline-container"}>
                                    <div className={"classifier-section-container"}>
                                        <Select
                                            value={scienceValue ? scienceValue : ""}
                                            placeholder={"Область"}
                                            className={"discipline-react-select"}
                                            options={selectScienceState}
                                            onChange={(selected) => {
                                                setScienceValue(selected)
                                                setDisciplineValue("")
                                                const selectedScience = selected.value;
                                                setScience(selectedScience);
                                                const selectedSection = section.find(item => JSON.stringify(item.science) === JSON.stringify(selected.value));
                                                setEmptyMassive(selectedSection?.empty_massive || false);

                                                if (selectedSection?.empty_massive) {
                                                    setClassifierId(selectedSection?.science[0]?._id);
                                                    setDiscipline(selectedSection?.science[0]?.discipline);
                                                    setReload2(prev => prev + 1);
                                                    setCurrentDis(selectedSection?.science[0]?.discipline);
                                                    setDisciplineError(false);
                                                } else {
                                                    setClassifierId(null);
                                                }

                                                setDiscipline("");
                                            }}
                                        />
                                    </div>
                                    {science && !emptyMassive
                                        ?
                                        <Select
                                            placeholder={"Дисциплины"}
                                            className={"discipline-react-select"}
                                            options={selectDisciplineState}
                                            value={disciplineValue ?? ""}
                                            isSearchable
                                            onMenuClose={()=> {
                                                setDisciplineValue("")
                                            }}
                                            closeMenuOnSelect={false} // Отключаем автоматическое закрытие меню
                                            onChange={(selected) => {
                                                const selectedDisciplineId = selected.dataId;
                                                setClassifierId(selectedDisciplineId);
                                                setDiscipline(selected.value);
                                                setReload2(prev => prev + 1);
                                                setCurrentDis(selected.value);
                                                setDisciplineError(false);

                                            }}
                                        />
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }

                        </div>
                        :
                        null
                    }





                    {classifier
                        ?
                        <div className={"admin-classifier-wrapper"}>
                            <select
                                className={"margin-bottom-10"}
                                defaultValue={""}
                                value={section}
                                onChange={(e) => {
                                    setSection(e.target.value)
                                    setScience("")
                                    setDiscipline("")
                                }}
                            >
                                <option value={""} disabled>
                                    --Выбрать--
                                </option>
                                {Object.keys(classifier).sort().map(cat1 => (
                                    <option key={cat1} value={cat1}>
                                        {cat1}
                                    </option>
                                ))}
                            </select>
                            {section
                                ?
                                <div className={"admin-classifier-science-discipline-container"}>
                                    <select
                                        className={"margin-bottom-10"}
                                        value={science}
                                        onChange={(e) => {
                                            setScience(e.target.value)
                                            setDiscipline("")

                                            if(!classifier[section][e.target.value].length) {
                                                setDiscipline(e.target.value)
                                                setReload2(prev => prev + 1)
                                                setCurrentDis(e.target.value)
                                                setDisciplineError(false)
                                            }


                                        }}
                                    >
                                        <option value={""} disabled>
                                            --Область--
                                        </option>
                                        {Object.keys(classifier[section]).sort().map(cat2 => (
                                            disList.includes(cat2)
                                                ?
                                                null
                                                :
                                            <option key={cat2} value={cat2}>
                                                {cat2}
                                            </option>
                                        ))}
                                    </select>
                                    {classifier[section][science]?.length
                                        ?
                                        <select
                                            className={"margin-bottom-10"}
                                            value={discipline}
                                            onChange={(e) => {
                                                setDiscipline(e.target.value)
                                                setReload2(prev => prev + 1)
                                                setCurrentDis(e.target.value)
                                                setDisciplineError(false)
                                            }}>
                                            <option value={""} disabled>
                                                --Дисциплина--
                                            </option>
                                            {classifier[section][science].sort().map(cat3 => (

                                                disList.includes(cat3)
                                                        ?
                                                        null
                                                        :
                                                        <option key={cat3} value={cat3}>
                                                            {cat3}
                                                        </option>

                                            ))}
                                        </select>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }

                        </div>
                        :
                        null
                    }


                    <div className={"chosen-discipline-container"}>
                        {/*{!disList.length? <div className={"padding-20"}>Добавьте дисциплины, так вас будет проще найти  👆</div> : null}*/}
                        {disList.length
                            ?
                            disList.map(item =><div className={"added-discipline"} key={item}>
                                <p className={"inline rem09 margin-0"}>{item}</p>
                                {/*<button className={"btn btn-danger data-btn"} onClick={(e) => delAnotherDis(e, item)}>⨯</button>*/}
                                    <img
                                        className={"svg-closing-cross-lk"}
                                        src={SvgRedCross}
                                        width={"15px"}
                                        onClick={(e) => {
                                            delAnotherDis(e, item)
                                        }}
                                    />
                            </div>)
                            :
                            <div className={"padding-20"}>
                                Добавьте дисциплины, так вас будет проще найти  👆<br/>
                                И ещё мы будем оповещать вас по почте о появлении нового заказа по вашей специальности
                            </div>
                        }
                    </div>


                    <h4 className={"personal-data-input margin-top-20"}>О себе:</h4>
                    <textarea
                        className={"personal-data-text-about-me"}
                        placeholder={"Вы можете поделиться чем то важным или интересным," +
                            " эта информация будет видна на страничке вашего профиля, а также она участвует в поиске"}
                        onChange={(e) => {

                            setAbout(e.target.value)
                        }}
                        value={about}
                    ></textarea>

                    <h4 className={"personal-data-input margin-top-20"}>Сервисные кнопки:</h4>
                    <div className={"service-buttons-area"}>
                        <div className={"service-button-container"}>
                            <label>Пересчитать личные сообщения</label>
                            <div>
                                <button
                                    disabled={showOkPrivate}
                                    className={"btn btn-warning border-1-gray align-self-left service-button"}
                                    onClick={() => recountPrivateMessages()}
                                >GO</button>
                                {privateCounting
                                    ?
                                    <img className={"margin-left-20"} src={require('../img/loading.gif')} height={"35px"}/>
                                    :
                                    showOkPrivate
                                        ?
                                        <span className={"margin-left-20"}>✔️</span>
                                        :
                                        null
                                }
                            </div>

                        </div>
                        <div className={"service-button-container"}>
                            <label>Пересчитать рабочие сообщения</label>
                            <div>
                                <button
                                    disabled={showOkWork}
                                    className={"btn btn-warning border-1-gray align-self-left service-button"}
                                    onClick={() => recountWorkMessages()}
                                >GO</button>
                                {workCounting
                                    ?
                                    <img className={"margin-left-20"} src={require('../img/loading.gif')} height={"35px"}/>
                                    :
                                    showOkWork
                                        ?
                                        <span className={"margin-left-20"}>✔️</span>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            </div>
            <hr/>
            <div className={"personal-data-portfolio-container"}>

                <FileAdderAndRoller
                    attachedFiles={portfolioFiles}
                    setAttachedFiles={setPortfolioFiles}
                    savingPath={`/usersFiles/${store.user.id}/portfolio/`}
                    title={<h4 className={"ubuntu"}>Портфолио</h4>}
                    noFilesDescription={"Здесь можно разместить примеры выполненных вами работ, а также документы подтверждающие вашу квалификацию"}
                />
            </div>
            <div className={"save-button-container"}>
                <Link to={`/user-details/${store.user.id}`} className={"btn primary-btn-color margin-left-20 block"}>Моя страница</Link>
                {store.user.name && store.user.lastName && store.user.phone && store.user.city && store.user.birthDay
                    ?
                    null
                    :
                    <button
                        className={"btn primary-btn-color margin-left-20 block"}
                        type="submit"
                        onClick={() => sendData()}
                    >Сохранить</button>
                }
            </div>
        </div>
            <ModalDialog
                active={activeInfoModal}
                setActive={setActiveInfoModal}
                action={() => {
                    setActiveInfoModal("progress")
                    setTimeout(() => {
                        setActiveInfoModal("no")
                    }, 100)
                }}
                message={`Чтобы получить доступ к полному функционалу сервиса, пожалуйста заполните свой профиль`}
                purpose={"info"}
            />
        </div>

    );
};

export default observer(PersonalData);