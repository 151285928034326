import React, {useState, useContext} from 'react';
import $api from "../http";
import {Context} from "../index";

const TestingRoom = () => {

    const {store} = useContext(Context)

    const [testingData, setTestingData] = useState([])


    async function doItNow() {
        const response = await $api.get(`/testing_room?user_id=${store.user.id}`)

        console.log(response.data)


        setTestingData(response.data)


    }



    return (
        <div className={"testing-room"}>
            <button
                className={"btn btn-danger"}
                onClick={() => doItNow()}
            >Пошумим блять!</button>

        </div>
    );
};

export default TestingRoom;