import React from 'react';

const ErrorMessage = (props) => {
    return (
        <div style={{display: 'inline'}}>
            <h3 className={"ubuntu"} style={{color: props.color}}>{props.message}</h3>
        </div>
    );
};

export default ErrorMessage;