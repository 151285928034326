import React, {useContext, useState} from 'react';
import {Context} from "../index";
import {toJS} from 'mobx'
import {observer} from 'mobx-react-lite';
import {API_URL} from "../http";
import {getAgeFromDate} from '../components/helpers/repeat_functions'
import {Link} from "react-router-dom"




const Dossier = () => {


    console.log(API_URL)

    const {store} = useContext(Context)
    const [avatarSrc, setAvatarSrc] = useState(`${API_URL}/public/avatars/${{...store.user}.id}/avatar.png`)


    console.log(toJS(store.balance))
    console.log(!store.paymentBlock)
    console.log(store.balance < 90 && store.paymentBlock)

    //console.log(toJS(store.user.outgoingOrders))

    return (
        <div className="dossier">
            {/*<img className="avatar" src="https://cs6.pikabu.ru/avatars/404/x404070-828004264.png"/>*/}
            <img className="avatar-dossier" src={avatarSrc}/>
            <div className="name-container">
                <h5 className={"rem09 bold"}>{store.user.name}</h5>
                <h5 className={"rem09 bold"}>{store.user.lastName}</h5>
                {/*<h5><span className="colored-stars">★★★★★</span> <span className="rating">4.7</span></h5>*/}
                <h6 className={"margin-top-20 rem08"}>Участник: {getAgeFromDate(store.user.registrationDate)}</h6>
                <br/>
                <h6 className={"rem08"}>Заказов в работе: {{...store.user}.incomingOrders.length}</h6>
                <h6 className={"rem08"}>Заказов отдано: {{...store.user}.outgoingOrders.length}</h6>
                <h6 className={"rem08"}>Всего выполнено: {{...store.user}.totalIncoming}</h6>
                <h6 className={"rem08"}>Всего отдано: {{...store.user}.totalOutgoing + {...store.user}.outgoingOrders.length}</h6>
                <br/>
                <h6 className={"rem09"}>
                    Баланс: <Link style={{ textDecoration: 'none', color: 'black' }} to={"/pay"}>{store.balance.toFixed(2)} ₽</Link>
                </h6>

                {store.balance < 90 && !store.paymentBlock
                    ?
                    Math.floor(store.balance / 30) > 0
                        ?
                        <h6 className={"rem09 red"}>{Math.floor(store.balance / 30) === 1
                            ?
                            "Завтра произоёдет блокировка"
                            :
                            `В ближайшие ${Math.floor(store.balance / 30)} дня произоёдет блокировка`
                        }</h6>
                        :
                        <h6 className={"rem09 red"}>{`Сегодня произойдёт блокировка`}</h6>
                    :
                    null
                }
                {store.paymentBlock
                    ?
                    <h6 className={"rem09 red"}>{`ЗАБЛОКИРОВАН`}</h6>
                    :
                    null
                }


                {/*<h6 className={"rem09"}>Списание: 28.08.2022</h6>*/}
            </div>



        </div>
    );
};

export default observer(Dossier);