import $api, {API_URL} from "../../http";
import {toJS} from "mobx";



function getAgeFromDate(startDate) {
        const futureDate = new Date();
        const today = new Date(startDate);

        let diffYears = futureDate.getFullYear() - today.getFullYear();
        let diffMonths = futureDate.getMonth() - today.getMonth();
        let diffDays = futureDate.getDate() - today.getDate();

        // Если количество дней отрицательное, значит, между датами находится меньше месяца
        if (diffDays < 0) {
            diffMonths--;
            const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
            diffDays += lastMonth.getDate();
        }

        // Если количество месяцев отрицательное, значит, между датами находится меньше года
        if (diffMonths < 0) {
            diffYears--;
            diffMonths += 12;
        }

        let result = "";

        if (diffYears > 0) {
            const yearsString = getYearString(diffYears);
            result += `${diffYears} ${yearsString}, `;
        }

        if (diffMonths > 0) {
            const monthsString = getMonthString(diffMonths);
            result += `${diffMonths} ${monthsString}, `;
        }

        if (diffDays > 0) {
            const daysString = getDayString(diffDays);
            result += `${diffDays} ${daysString}`;
        }

        // Удаляем последнюю запятую и пробел в строке результата
        result = result.replace(/,\s*$/, "");

        return result

        function getYearString(years) {
            if (years === 1) {
                return "год";
            } else if (years >= 2 && years <= 4) {
                return "года";
            } else {
                return "лет";
            }
        }

        function getMonthString(months) {
            if (months === 1) {
                return "месяц";
            } else if (months >= 2 && months <= 4) {
                return "месяца";
            } else {
                return "месяцев";
            }
        }

        function getDayString(days) {
            if (days === 1) {
                return "день";
            } else if (days >= 2 && days <= 4) {
                return "дня";
            } else {
                return "дней";
            }
        }
    }

    //ограничитель количества выполнений функции
    //принимает как аргументы саму функцию и задержку перед следующим выполнением в миллисекундах
    function throttle(func, delay) {
        let timerId;
        let lastExecTime = 0;

        return function (...args) {
            const currentTime = Date.now();

            if (currentTime - lastExecTime > delay) {
                func.apply(this, args);
                lastExecTime = currentTime;
            } else {
                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    func.apply(this, args);
                    lastExecTime = currentTime;
                }, delay);
            }
        };
    }

    function containsLink(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/;
        return linkRegex.test(text);
    }

    function processMessageText(text) {
        if (text) {
            console.log(text)
            // Заменяем символы новой строки на теги <br>
            const formattedText = text.replace(/\n/g, ' <br>');

            if (containsLink(formattedText)) {
                const messageContent = formattedText.replace(
                    /(https?:\/\/[^\s]+)/g,
                    (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
                );

                return <div className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
            }

            const main_array = formattedText.split(" ")

            let decision = false

            let container = []

            for (let piece of main_array) {
                if (piece.length > 25) {
                    decision = true;
                    break
                }
            }

            if (decision) {

                const chunkSize = 20;

                function splitWord(text, size) {
                    const chunks = [];

                    for (let i = 0; i < text.length; i += size) {
                        chunks.push(text.slice(i, i + size));
                    }

                    return chunks;
                }

                const wordChunks = splitWord(formattedText, chunkSize);

                for (let chunk of wordChunks) {

                    container.push(
                        <span>{chunk}<wbr /></span>
                    )
                }

                return container

            } else {
                return <div  className={"no-click-to-go"} dangerouslySetInnerHTML={{ __html: formattedText }} />;
            }
        }
    }

    function capitalizeFirstLetter(str) {
        if (!str) {
            return "";
        }
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

async function downloadHandler(path, setModal) {


    try {
        const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})


        //создаем блоб (Binary Large OBject) из ответа
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        link.download = await path.replace(/\\/g, '/').split('/').pop().replace(/_/g, ' ');
        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)

        return "OK"

    } catch (e) {
        return "FAIL"
    }

}


function getMinutesBetweenDates(date1, date2) {
    const diffInMilliseconds = Math.abs(date2 - date1); // Разница в миллисекундах
    const minutes = diffInMilliseconds / (1000 * 60); // Преобразование в минуты
    return minutes;
}


function hasChatIdInChatList(chatList, chatId) {
    return chatList.some(chat => chat.chat_id === chatId);
}


function formatPhoneNumber(phoneNumber) {
    // Удаляем все символы, кроме цифр
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Проверяем, что получилось 11 цифр
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
        // Форматируем номер
        return `+${match[1]}-(${match[2]})-${match[3]}-${match[4]}-${match[5]}`;
    }

    // Возвращаем оригинальный номер, если не удалось форматировать
    return phoneNumber;
}



function sortByArrayLength(inputObject) {
    // Создаем массив из ключей объекта

    console.log(inputObject)

    const keys = Object.keys(inputObject);

    // Сортируем ключи по длине соответствующих массивов (по убыванию)
    keys.sort((a, b) => inputObject[b]?.length - inputObject[a]?.length);

    // Создаем новый объект с отсортированными ключами
    const sortedObject = {};
    keys.forEach(key => {
        sortedObject[key] = inputObject[key];
    });

    return sortedObject;
}


function highlightTextAroundPattern(mainText, pattern, color, nocut) {


    if(mainText) {
        const index = mainText?.toLowerCase().indexOf(pattern?.toLowerCase());

        if (index !== -1) {
            let start = 0;
            let end = mainText?.length;

            if (!nocut) {
                start = Math.max(0, index - 50);
                end = Math.min(mainText?.length, index + pattern.length + 50);
            }

            let preText = '';
            let highlightedText = mainText.substring(start, end);
            let postText = '';

            if (start > 0) {
                preText = '...';
            }

            if (end < mainText.length) {
                postText = '...';
            }

            let highlightClass = '';
            if (color === 'blue') {
                highlightClass = 'highlight-blue';
            } else if (color === 'orange') {
                highlightClass = 'highlight-orange';
            } else {
                highlightClass = 'highlight'; // Default class
            }

            highlightedText = highlightedText.replace(
                new RegExp(pattern, 'i'),
                match => `<span class="${highlightClass}">${match}</span>`
            );

            const resultText = `${preText}${highlightedText}${postText}`;


            return (
                <div dangerouslySetInnerHTML={{ __html: `"${resultText}"` }} />
            );
        } else {
            return [mainText];
        }
    } else {
        return ""
    }

}

// function highlightTextAroundPattern(mainText, pattern, color) {
//     const index = mainText.toLowerCase().indexOf(pattern.toLowerCase());
//
//     if (index !== -1) {
//         const start = Math.max(0, index - 50);
//         const end = Math.min(mainText.length, index + pattern.length + 50);
//
//         let preText = '';
//         let highlightedText = mainText.substring(start, end);
//         let postText = '';
//
//         if (start > 0) {
//             preText = '...';
//         }
//
//         if (end < mainText.length) {
//             postText = '...';
//         }
//
//         let highlightClass = '';
//         if (color === 'blue') {
//             highlightClass = 'highlight-blue';
//         } else if (color === 'orange') {
//             highlightClass = 'highlight-orange';
//         } else {
//             highlightClass = 'highlight'; // Default class
//         }
//
//         highlightedText = highlightedText.replace(
//             new RegExp(pattern, 'i'),
//             match => `<span class="${highlightClass}">${match}</span>`
//         );
//
//         const resultText = `${preText}${highlightedText}${postText}`;
//
//         return (
//             <div dangerouslySetInnerHTML={{ __html: `"${resultText}"` }} />
//         );
//     } else {
//         return [mainText];
//     }
// }



function getSixteenYearsAgoDate() {

    function addZero(number) {
        return number < 10 ? `0${number}` : number;
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear() - 16;
    const month = currentDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    const day = currentDate.getDate();

    // Форматируем месяц и день, чтобы добавить ведущий ноль, если они меньше 10
    const formattedMonth = addZero(month);
    const formattedDay = addZero(day);

    return `${year}-${formattedMonth}-${formattedDay}`;
}

function chatNameResolver(chat_id, chatList) {
    console.log(chat_id)
    console.log(toJS(chatList))

    if (chat_id === "disput") return " (из Светского диспута)"
    if (chat_id === "admin") return " (из админской)"
    if (chat_id === "arbitr") return " (из арбитража)"

    for (let chat of chatList) {
        console.log(toJS(chat))
        if (chat.chat_id === chat_id) {
            return " (из чата " + chat.chatName + ")";
        }
    }
    return null; // Возвращаем пустую строку, если совпадений не найдено
}


function mapMaterials(arrayToMap, showDate, setActiveModalImgViewer, setImageSource) {
    let count = 0

    if(arrayToMap?.length > 0) {

        console.log(arrayToMap)

        return <div>

            {arrayToMap.map((file, index) => {

                console.log(file)

                let type

                if(file.type === "doc" || file.type === "docx") {
                    type = "word"
                } else if (file.type === "xls" || file.type === "xlsx") {
                    type = "excel"
                } else if (file.type === "ppt" || file.type === "pptx") {
                    type = "power_point"
                } else if (file.type === "rar" || file.type === "zip") {
                    type = "winrar"
                } else if (file.type === "pdf") {
                    type = "pdf"
                } else if (file.type === "png" || file.type === "jpg" || file.type === "jpeg") {
                    type = "image"
                } else {
                    type = "doc_thumb"
                }


                //пятерка в квадратных скобках это количество папок вложенных)))

                const filenameWindows = file.path?.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");

                //const filename = file.path.split('/')[5].replace(/_/g, " ")
                let filename;

                if (filenameWindows?.includes('/')) {
                    filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                } else {
                    filename = filenameWindows;
                }

                //убираем расширение
                filename = filename.split('.').slice(0, -1).join('.');

                count++
                return<div key={file.path}>
                    {count > 1 ? <hr className={"native-blue"}/> : null}
                    <div className={"one-ready-order-container"} key={file.path}>

                        <div className={"users-filename-container"}>

                            {type === "image"
                                ?
                                <img className={"order-done-file-image"} src={API_URL + "/" + file.path} width={"50"}/>
                                :
                                <img className={"order-done-file-image"} src={require(`../../img/${type}.png`)} width={"50"}/>
                            }
                            <p title={filename} className={"filename-container"}>{filename}</p>
                            <br/>
                            {showDate
                                ?
                                <p className={"white-back inline-block padding-5"}>📌 {new Date(file.date).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</p>
                                :
                                null
                            }



                        </div>
                        <div>
                            {type === "image"
                                ?
                                <button
                                    className={"btn btn-primary order-download-btn"}
                                    onClick={() => {
                                        setActiveModalImgViewer("progress")
                                        setTimeout(() => {
                                            setActiveModalImgViewer("active")
                                        }, 100)
                                        setImageSource(file.path)
                                    }}
                                >
                                    Открыть
                                </button>
                                :
                                <button className={"btn btn-primary order-download-btn"} onClick={() => downloadHandler(file.path)}>
                                    Скачать
                                </button>
                            }

                            {/*<button className={"btn btn-danger order-download-btn"} onClick={() => deleteFile(file.path, index)}>*/}
                            {/*    Удалить*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            })}
        </div>
    }

}


function assignWidthFromDocumentWidth(element_id, width_coeff) {
    // Находим элемент по его id
    const element = document.getElementById(element_id);

    // Проверяем наличие элемента и присваиваем ширину
    if (element) {
        const documentWidth = document.documentElement.scrollWidth;
        const width = documentWidth / 100 * (width_coeff ?? 1);

        element.style.width = `${width}px`;
    }
}



function assignWidth(firstId, secondId, width_coeff) {
    // Находим элементы по их id
    const firstElement = document.getElementById(firstId);
    const secondElement = document.getElementById(secondId);

    const coeff = width_coeff ?? 1

    // Проверяем наличие элементов и присваиваем ширину
    if (firstElement && secondElement) {
        const width = firstElement.offsetWidth;
        secondElement.style.width = `${width * coeff}px`;
    }
}

function assignHeight(firstId, secondId, height_coeff) {
    // Находим элементы по их id
    const firstElement = document.getElementById(firstId);
    const secondElement = document.getElementById(secondId);

    const coeff = height_coeff ?? 1

    // Проверяем наличие элементов и присваиваем ширину
    if (firstElement && secondElement) {
        const height = firstElement.offsetHeight;
        secondElement.style.height = `${height * coeff}px`;
    }
}



function textProcessor(text) {
    if (text) {
        // Обработка ссылок
        if (containsLink(text)) {
            const messageContent = text.replace(
                /(https?:\/\/[^\s]+)/g,
                (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
            );

            return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
        }

        // Обработка переходов на новую строку
        const lines = text.split("\n");
        const formattedText = lines.map((line, index) => <p className={"p-from-text-processor"} key={index}>{line}</p>);

        return formattedText;
    } else {
        return text;
    }
}



function processTextWithLinksAndHighlight(mainText, pattern, color, nocut) {


    if (mainText) {

        const patternToRegex = pattern?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // экранирование специальных символов

        // Функция для выделения паттерна
        const highlightPattern = (text) => {
            const index = text?.toLowerCase().indexOf(pattern?.toLowerCase());

            if (index !== -1) {
                let start = 0;
                let end = text?.length;

                if (!nocut) {
                    start = Math.max(0, index - 50);
                    end = Math.min(text?.length, index + pattern.length + 50);
                }

                let preText = '';
                let highlightedText = text.substring(start, end);
                let postText = '';

                if (start > 0) {
                    preText = '...';
                }

                if (end < text.length) {
                    postText = '...';
                }

                let highlightClass = '';
                if (color === 'blue') {
                    highlightClass = 'highlight-blue';
                } else if (color === 'orange') {
                    highlightClass = 'highlight-orange';
                } else {
                    highlightClass = 'highlight'; // Default class
                }


                highlightedText = highlightedText.replace(
                    new RegExp(patternToRegex, 'i'),
                    match => `<span class="${highlightClass}">${match}</span>`
                );

                const resultText = `${preText}${highlightedText}${postText}`;

                return resultText;
            } else {
                return text;
            }
        };

        // Функция для проверки ссылок
        const containsLink = (str) => {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            return urlRegex.test(str);
        };

        // Функция для обработки ссылок
        const linkifyText = (text) => {
            return text.replace(
                /(https?:\/\/[^\s]+)/g,
                (url) => `<a className="link-ws-container" href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
            );
        };

        // Функция для обработки переходов на новую строку
        const formatNewLines = (text) => {
            const lines = text.split("\n");
            return lines.map((line, index) => `<p style="display: inline" class="p-from-text-processor" key=${index}>${line}</p>`).join('');
        };

        // Шаг 1: Выделение паттерна
        let processedText = highlightPattern(mainText);

        // Шаг 2: Обработка ссылок
        if (containsLink(processedText)) {
            processedText = linkifyText(processedText);
        }

        // Шаг 3: Обработка переходов на новую строку
        processedText = formatNewLines(processedText);

        return (
            <div className={"inline"} dangerouslySetInnerHTML={{ __html: processedText }} />
        );
    } else {
        return "";
    }
}



// function processTextWithLinksAndHighlight(mainText, pattern, color, nocut) {
//
//
//     console.log(mainText)
//     console.log(pattern)
//
//     if (mainText) {
//         // Функция для выделения паттерна и добавления ссылок
//         const highlightAndLinkifyText = (text) => {
//             // Функция для проверки ссылок
//             const containsLink = (str) => {
//                 const urlRegex = /(https?:\/\/[^\s]+)/g;
//                 return urlRegex.test(str);
//             };
//
//             // Функция для обработки ссылок
//             const linkifyText = (text) => {
//                 return text.replace(
//                     /(https?:\/\/[^\s]+)/g,
//                     (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
//                 );
//             };
//
//             // Функция для обработки переходов на новую строку
//             const formatNewLines = (text) => {
//                 const lines = text.split("\n");
//                 return lines.map((line, index) => `<p className={"p-from-text-processor"} key={index}">${line}</p>`).join('');
//             };
//
//             // Обработка ссылок
//             let processedText = text;
//             if (containsLink(text)) {
//                 processedText = linkifyText(text);
//             }
//
//             // Обработка переходов на новую строку
//             processedText = formatNewLines(processedText);
//
//             return processedText;
//         };
//
//         // Функция для выделения паттерна
//         const highlightPattern = (text) => {
//             const index = text?.toLowerCase().indexOf(pattern?.toLowerCase());
//
//             if (index !== -1) {
//                 let start = 0;
//                 let end = text?.length;
//
//                 if (!nocut) {
//                     start = Math.max(0, index - 50);
//                     end = Math.min(text?.length, index + pattern.length + 50);
//                 }
//
//                 let preText = '';
//                 let highlightedText = text.substring(start, end);
//                 let postText = '';
//
//                 if (start > 0) {
//                     preText = '...';
//                 }
//
//                 if (end < text.length) {
//                     postText = '...';
//                 }
//
//                 let highlightClass = '';
//                 if (color === 'blue') {
//                     highlightClass = 'highlight-blue';
//                 } else if (color === 'orange') {
//                     highlightClass = 'highlight-orange';
//                 } else {
//                     highlightClass = 'highlight'; // Default class
//                 }
//
//                 highlightedText = highlightedText.replace(
//                     new RegExp(pattern, 'i'),
//                     match => `<span class="${highlightClass}">${match}</span>`
//                 );
//
//                 const resultText = `${preText}${highlightedText}${postText}`;
//
//                 return resultText;
//             } else {
//                 return text;
//             }
//         };
//
//         // Обработка текста
//         const processedText = highlightAndLinkifyText(mainText);
//         const textWithHighlight = highlightPattern(processedText);
//
//         return (
//             <div dangerouslySetInnerHTML={{ __html: textWithHighlight }} />
//         );
//     } else {
//         return "";
//     }
// }




export {
    getAgeFromDate,
    throttle,
    processMessageText,
    capitalizeFirstLetter,
    downloadHandler,
    getMinutesBetweenDates,
    hasChatIdInChatList,
    formatPhoneNumber,
    sortByArrayLength,
    highlightTextAroundPattern,
    getSixteenYearsAgoDate,
    chatNameResolver,
    mapMaterials,
    assignWidth,
    assignHeight,
    textProcessor,
    processTextWithLinksAndHighlight,
    assignWidthFromDocumentWidth


}