import React, {useState, useEffect, useContext} from 'react';
import $api from "../../http";
import {observer} from 'mobx-react-lite';
import {Context} from "../../index";
import ModalDialog from "./ModalDialog";
import {API_URL} from "../../http";
import SvgClock from "../../img/clock.svg";
import SvgPinRed from "../../img/pin-red.svg";


const Stepper = ({doer, orderId, readyFiles, notifySocket, orderDetails, correctionFiles, adminView}) => {

    const {store} = useContext(Context)

    //стейт для ссылок на готовые файлы
    const [files, setFiles] = useState([])

    //стейт для ссылок на файлы правок
    const [corrections, setCorrections] = useState([])



    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})

    const [deadline, setDeadline] = useState(null)


    const [activeModeratorInvite, setActiveModeratorInvite] = useState("no")
    const [activeCorrections, setActiveCorrections] = useState("no")
    const [activeCancelCorrections, setActiveCancelCorrections] = useState("no")
    const [activeClosing, setActiveClosing] = useState("no")
    const [activeCloseConfirm, setActiveCloseConfirm] = useState("no")
    const [activeWithoutFiles, setActiveWithoutFiles] = useState("no")

    const [moderatorMessage, setModeratorMessage] = useState("")


    const [needCorrectionsRequest, setNeedCorrectionsRequest] = useState(false)

    async function weNeedCorrections(state) {
        console.log("YES WE NEED CORRECTIONS")

        setNeedCorrectionsRequest(true)
        
        try{
            const response = await $api.post('/corrections', {order_id: orderId, need: state})
            console.log(response.data)

        } catch (e) {
            console.log(e)
        } finally {
            setNeedCorrectionsRequest(false)
        }
    }


    //приглашатор модератора
    async function inviteModerator() {
        const response = await $api.post('/invite_moderator', {order_id: orderId, caller: doer ? "doer" : "creator", message: moderatorMessage})

        if(response.data.status !== "OK") {
            setErrorMessage({show: true, text: "Не удалось пригласить модератора"})
            console.log(response.data)
        }
    }




    //установщик дедлайна
    async function deadlineSetter() {
        if(deadline) {

            try {

                const response = await $api.post('/set_deadline', {date: new Date(deadline).toISOString(), order: orderDetails._id})
                if(response.data.status === 200) {
                    store.setUpdateOrderProcess()
                }

            } catch (e) {
                console.log(e)
            }
        }
    }

    //эта магия позволяет задавать количество дней для прибавки к дате
    Date.prototype.addDays = function(days) {
        let date2 = new Date(this.valueOf());
        date2.setDate(date.getDate() + days);
        return date2;
    }





    //Ниже две переменных и функция для приведения даты к формату который прожуёт инпут с датой в форме,
    // чтобы можно было установить минимальное значение даты в этой форме
    function addZero(num) {
        if (num <= 9) {
            return '0' + num;
        } else {
            return num;
        }
    }



    let date = new Date()
    let formattedDate = date.getFullYear() + "-" + addZero(date.getMonth()+1) + "-" + addZero(date.getDate())
    let formattedDateStop


    if(orderDetails.type === "Диплом") {
        let dateStop = new Date().addDays(180);
        formattedDateStop = dateStop.getFullYear() + "-" + addZero(dateStop.getMonth()+1) + "-" + addZero(dateStop.getDate())

    } else {
        let dateStop = new Date().addDays(30)
        formattedDateStop = dateStop.getFullYear() + "-" + addZero(dateStop.getMonth()+1) + "-" + addZero(dateStop.getDate())

    }



    useEffect(() => {

        if(readyFiles) {
            console.log("SETFILES1")

            setFiles(readyFiles)
        }

    }, [readyFiles])

    useEffect(() => {

        if(correctionFiles) {
            console.log(correctionFiles)
            setCorrections(correctionFiles)
        }

    }, [correctionFiles])

    // useEffect(() => {
    //
    //
    // }, [files])


    async function withoutFiles() {
        await $api.post(`/corrections/${orderId}`, {without: true})
        store.setUpdateOrderProcess()
    }


    async function downloadHandler(path) {

        // тут использован fetch вместо аксиоса, потому что Ulbi сказал что так проще блять
        const response = await fetch(API_URL + "/" + path, {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        })

        //создаем блоб (Binary Large OBject) из ответа
        const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        link.download = await path.split('/')[5]
        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)
    }


    function addInputAndButton(purpose, step, phase) {

        //if((readyFiles?.length < 10 || readyFiles === undefined) && purpose === "ready") {
        if(purpose === "ready" && step === phase) {
            return (
                <div className={"stepper-btn-container"}>

                    <input
                        type={'file'}
                        multiple
                        name={"files[]"}
                        id={"stepper-file-input"}
                        onChange={(e) => {

                            attacheFilesHandler(e.target.files, purpose)
                        }}/>
                    <button className={"btn primary-btn-color margin-right-20 add-files-stepper-btn"} onClick={() => document.getElementById("stepper-file-input").click()}>
                        {readyFiles?.length > 0 ? <span>Добавить файлы <img src={SvgPinRed} width={"20px"}/></span> : <span>Прикрепить <img src={SvgPinRed} width={"20px"}/></span>}
                    </button>

                    {errorMessage.show ? <h5 className={"red error-message-ready-order"}>{errorMessage.text}</h5> : null}
                </div>
            )
        }


        //if(purpose === "corrections" && correctionFiles?.length < 10) {
        if(purpose === "corrections") {
            return (
                <div className={"stepper-btn-container"}>
                    <input
                        type={'file'}
                        multiple
                        name={"files[]"}
                        id={"stepper-file-input"}
                        onChange={(e) => {

                            attacheFilesHandler(e.target.files, purpose)
                        }}/>

                    {/*{correctionFiles[orderDetails.step - 1][0] === "without"*/}
                    {/*    ?*/}
                    {/*    <p>Тут что то есть</p>*/}
                    {/*    :*/}
                    {/*    <p>Тут ничего нет</p>*/}
                    {/*}*/}
                    <button className={"btn primary-btn-color margin-right-20"} onClick={() => document.getElementById("stepper-file-input").click()}>
                        Прикрепить <img src={SvgPinRed} width={"20px"}/>
                    </button>

                    {step > phase && correctionFiles[step-1]
                        ?
                        null
                        :
                        <button
                            className={"btn primary-btn-color margin-right-20"}
                            onClick={() => {
                                setActiveWithoutFiles("progress")
                                setTimeout(() => {
                                    setActiveWithoutFiles("active")
                                }, 100)
                            }}
                        >
                            Без файлов
                        </button>
                    }
                    {step === phase
                        ?
                        <button
                            className={"btn btn-danger order-decision-btn"}
                            onClick={() => {
                                setActiveCancelCorrections("progress")
                                setTimeout(() => {
                                    setActiveCancelCorrections("active")
                                }, 100)
                            }}
                        >Отменить правки</button>
                        :
                        null
                    }




                    {errorMessage.show ? <h5 className={"red error-message-ready-order"}>{errorMessage.text}</h5> : null}
                </div>
            )
        }

    }





    function mapReadyFiles(arrayToMap, text) {
        let count = 0


        if(arrayToMap?.length > 0) {

            console.log(arrayToMap)



            return <div>
                <h4 className={"ubuntu"}>{text}</h4>
                {arrayToMap.map(file => {

                    console.log(file)

                    let type

                    if(file.type === "doc" || file.type === "docx") {
                        type = "word"
                    } else if (file.type === "xls" || file.type === "xlsx") {
                        type = "excel"
                    } else if (file.type === "ppt" || file.type === "pptx") {
                        type = "power_point"
                    } else if (file.type === "rar" || file.type === "zip") {
                        type = "winrar"
                    } else if (file.type === "pdf") {
                        type = "pdf"
                    } else {
                        type = "doc_thumb"
                    }


                    //пятерка в квадратных скобках это количество папок вложенных)))

                    const filenameWindows = file.path?.substring(file.path.lastIndexOf("\\") + 1).replace(/_/g, " ");

                    //const filename = file.path.split('/')[5].replace(/_/g, " ")
                    let filename;

                    if (filenameWindows?.includes('/')) {
                        filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                    } else {
                        filename = filenameWindows;
                    }

                    count++
                    return<div key={file.path}>
                        {count > 1 ? <hr className={"native-blue"}/> : null}
                        <div className={"one-ready-order-container"} key={file.path}>

                            <div className={"ready-filename-container"}>

                                <img className={"order-done-file-image"} src={require(`../../img/${type}.png`)} width={"50"}/>
                                <p className={"inline"}>{filename.length > 50 ? filename.slice(0, 50)+"..." : filename}</p>
                                <br/>

                                <p className={"white-back inline-block padding-5"}><img src={SvgPinRed} width={"20px"}/> {new Date(file.date).toLocaleString([], {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</p>


                            </div>
                            <button className={"btn primary-btn-color order-download-btn"} onClick={() => downloadHandler(file.path)}>
                                Скачать
                            </button>

                        </div>
                    </div>
                })}
            </div>
        }

    }

    //проверялка длины имени файла
    async function lengthController(files){

        //if(files.length + readyFiles?.length > 10) {
        if(files.length > 10) {
            setErrorMessage({
                show: true,
                text: `Максимальное количество файлов, которое можно прикрепить к заказу 10, вы 
                пытаетесь прикрепить ${files.length + readyFiles?.length}, так не получится =)`})
            return false
        }


        for(let i=0; i < files.length; i++) {
            if(files[i].name.length > 140) {
                setErrorMessage({
                    show: true,
                    text: `Один из файлов имеет длину имени более 140 символов, 
                    его нужно сократить, речь идет о файле ${files[i].name}`})
                return false
            }

            // if(files[i].size > 104857600) {
            //     setErrorMessage({
            //         show: true,
            //         text: `Один из файлов имеет размер более 100 мегабайт,
            //         мы не принимаем файлы, чей размер превышает это значение, речь идет о файле ${files[i].name}`})
            //     return false
            // }

            if(files[i].type === "application/x-msdownload") {
                setErrorMessage({
                    show: true,
                    text: `Вы пытаетесь прикрепить исполняемый файл, это противоречит всему на свете, речь идет о файле ${files[i].name}`})
                return false
            }

        }

        console.log(files)

        return true
    }


    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)



    useEffect(() => {
        console.log(uploading)
        console.log(uploadProgress)
    }, [uploading, uploadProgress])


    //обработчик прикрепления файлов

    async function attacheFilesHandler(files, purpose) {

        console.log("attache started")

        await setErrorMessage(prev => ({ show: false, text: "" }));

        if (await lengthController(files)) {
            try {
                // Сбрасываем состояния перед новой загрузкой
                if(files) {
                    setUploading(true);
                    setUploadProgress(0);
                }


                const formData = new FormData();

                // Добавляем файлы в объект FormData
                for (let i = 0; i < files.length; i++) {
                    formData.append("files[]", files[i]);
                }

                // Добавляем другие необходимые данные в FormData, если есть
                formData.append("orderId", orderId);

                // Создаем конфигурацию для axios с обработкой прогресса
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);


                        // Обновляем состояния прогресса
                        setUploadProgress(percentCompleted);
                    }
                };

                // Отправляем файлы на сервер


                if(purpose === "ready") {
                    const response = await $api.post(`/add_done_files_to_order/${orderId}`, formData, config);
                    await setFiles([...response.data])
                    window.scrollTo(0, document.body.scrollHeight)


                }

                if(purpose === "corrections") {
                    const response = await $api.post(`/corrections/${orderId}`, files, config)
                    console.log(response.data)
                    await setCorrections([...response.data])


                }

                store.setUpdateOrderProcess()


                // Добавьте обработку ответа, если необходимо

                // Сбрасываем состояния после завершения загрузки
                setUploading(false);
                setUploadProgress(0);

                // Оповестите об обновлении процесса

            } catch (e) {
                setErrorMessage(prev => ({ show: true, text: e.message }));

                // В случае ошибки также устанавливаем флаг загрузки в false
                setUploading(false);
                setUploadProgress(0);
            } finally {
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight)
                }, 800)

            }
        }
    }





    // async function attacheFilesHandler(files, purpose) {
    //
    //
    //
    //     await setErrorMessage(prev => ({show: false, text: ""}))
    //
    //     if(await lengthController(files)) {
    //         console.log(errorMessage)
    //         //if(errorMessage.show === false) {
    //         try {
    //
    //             if(purpose === "ready") {
    //                 const response = await $api.post(`/add_done_files_to_order/${orderId}`, files)
    //                 console.log("SETFILES2")
    //                 await setFiles([...response.data])
    //                 const notify = {
    //                     to: orderDetails.creator._id,
    //                     about: orderDetails._id,
    //                     event: 'step',
    //                 }
    //
    //                 await notifySocket.current.send(JSON.stringify(notify));
    //                 store.setUpdateOrderProcess()
    //             }
    //
    //             if(purpose === "corrections") {
    //                 const response = await $api.post(`/corrections/${orderId}`, files)
    //                 console.log(response.data)
    //                 await setCorrections([...response.data])
    //
    //                 store.setUpdateOrderProcess()
    //             }
    //
    //
    //
    //
    //         } catch (e) {
    //
    //             setErrorMessage(prev => ({show: true, text: e.message}))
    //         }
    //        //}
    //     }
    // }



    function mainStepperLoop(step) {

        const results = []

        for(let phase=0; phase < step+1; phase++) {
            results.push(
                <div key={phase} className={"main-stepper-loop"}>
                    <h3 className={"stepper-titles ubuntu margin-bottom-20"}>Фаза {phase + 1}</h3>
                    {doer
                        ?
                        //ниже код который компилируется для исполнителя
                        <div>
                            {phase === 0
                                ?
                                orderDetails.step === phase
                                    ?
                                    <p>{phase+1}.1 Заказчик закрепил заказ за вами,
                                        {orderDetails.closestDate
                                            ? " перед выполнением необходимо назначить дедлайн"
                                            : " можно приступать к выполнению. По завершении работы приложите готовые файлы "
                                        }
                                    </p>
                                    :
                                    null
                                :
                                orderDetails.step === phase
                                    ?
                                    <div>
                                        <h4 className={'red-for-blue margin-top-20'}>Внимание!</h4>
                                        <p>{phase+1}.1 Заказчик затребовал внесение правок, вы можете ознакомиться с файлами правок, если заказчик их приложил,
                                            либо дополнительно обсудить ситуацию в чате заказа.</p><br/>
                                        <p>По завершении корректировок прикрепите пожалуйста готовые файлы здесь</p>
                                    </div>

                                    :
                                    null
                            }



                            {files[phase] && files !== undefined

                                ?
                                <div className={"ready-files-container"}>
                                    {/*Маппинг готовых файлов*/}
                                    {mapReadyFiles(files[phase], "Готовые файлы:")}
                                    {((orderDetails.corrections?.status && phase < step || orderDetails.corrections?.step !== step) && !orderDetails.onModeration.status) || orderDetails.onModeration.status ? null : addInputAndButton("ready", orderDetails.step, phase)}
                                    {uploading && phase === step && orderDetails.creator._id !== store.user.id
                                        ?
                                        <div className="progress-bar-container">
                                            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                                            <span className="progress-text">{uploadProgress}% загружено</span>
                                        </div>
                                        :
                                        null
                                    }
                                    <hr/>
                                    {orderDetails.step === phase
                                        ?
                                        <p className={"margin-top-20"}>{phase+1}.2 Мы оповестили заказчика о готовности файлов, теперь нужно дождаться его действий.
                                            Это может быть закрытие заказа и его оплата, либо от вас потребуется внесение правок.
                                            Когда заказчик примет решение, вам прилетит уведомление, и вы сможете сделать свой ход.
                                            Либо, вы можете пригласить модератора, если возникла спорная ситуация, которую не удалось решить
                                            в диалоге с заказчиком.
                                        </p>
                                        :
                                        null
                                    }

                                    {orderDetails.corrections?.status && orderDetails.step > phase
                                        ?
                                        <div>

                                            {/*Маппинг файлов по корректировкам*/}
                                            {corrections[phase]
                                                ?
                                                corrections[phase][0] !== "without"
                                                    ?
                                                    mapReadyFiles(corrections[phase], "Файлы правок:")
                                                    :
                                                    <h4>В этой фазе нет приложенных файлов правок</h4>
                                                :
                                                null
                                            }
                                            <br/>

                                        </div>

                                        :
                                        <div>
                                            {corrections[phase] && !orderDetails.corrections.status
                                                ?
                                                corrections[phase][0] !== "without"
                                                    ?
                                                    mapReadyFiles(corrections[phase], "Файлы правок:")
                                                    :
                                                    <h4>В этой фазе нет приложенных файлов правок</h4>


                                                :
                                                null}
                                        </div>
                                    }

                                    <div className={"stepper-file-attache-button-container"}>

                                        {orderDetails.onModeration?.status
                                        || orderDetails.orderStatus === "waiting"
                                        || orderDetails.orderStatus === "closed"
                                            ?
                                            null
                                            :
                                            phase === step && !orderDetails.onModeration.status && !adminView
                                                ?
                                                <button
                                                    className={"btn primary-btn-color order-decision-btn"}
                                                    onClick={() => {
                                                        setActiveModeratorInvite("progress")
                                                        setTimeout(() => {
                                                            setActiveModeratorInvite("active")
                                                        }, 100)
                                                    }
                                                    }
                                                >Модератор</button>
                                                :
                                                null
                                        }

                                    </div>

                                </div>


                                :
                                //если у заказа выбрана ближайшая дата автора, то есть дата еще не назначена
                                orderDetails.closestDate
                                    ?
                                    <div className={"stepper-date-setter-container"}>
                                        <input
                                            className={"margin-right-20"}
                                            type="date"
                                            name="deadline"
                                            min={formattedDate}
                                            max={formattedDateStop}
                                            onChange={(e) => setDeadline(e.target.value)}
                                        />
                                        <button
                                            className={"btn primary-btn-color"}
                                            onClick={() => deadlineSetter(deadline)}
                                        >Назначить</button><br/>
                                    </div>
                                    :

                                        orderDetails.onModeration?.status || adminView
                                            ?
                                            null
                                            :
                                            <div className={"stepper-btn-container"}>
                                                <div className={"stepper-file-attache-button-container"}>
                                                    {addInputAndButton("ready", orderDetails.step, phase)}


                                            <button
                                                className={"btn primary-btn-color order-decision-btn"}
                                                onClick={() => {
                                                    setActiveModeratorInvite("progress")
                                                    setTimeout(() => {
                                                        setActiveModeratorInvite("active")
                                                    }, 100)
                                                }
                                                }
                                            >Модератор</button>



                                                </div>

                                            </div>

                            }
                            {uploading && phase === step && orderDetails.creator._id !== store.user.id && !files[phase]?.length
                                ?
                                <div className="progress-bar-container">
                                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                                    <span className="progress-text">{uploadProgress}% загружено</span>
                                </div>
                                :
                                null
                            }


                        </div>

                        //______________________________________________________________________________________________________
                        //граница кода, ниже компилируемая часть которую видит заказчик
                        : <div>

                            {phase === 0
                                ?
                                orderDetails.step === phase
                                    ?
                                    <p>{phase+1}.1 Вы назначили исполнителя. Мы уже оповестили его об этом. Как только он загрузит готовый файл, вам прилетит уведомление,
                                        а здесь появятся ссылки для скачивания.
                                        Вы можете связаться с исполнителем с помощью кнопки "Сообщения" на этой странице, либо в Центре сообщений
                                        в разделе "Заказчик"</p>
                                    :
                                    null
                                :
                                orderDetails.step === phase
                                    ?
                                    <p>{phase+1}.1 Исполнитель получил уведомление о необходимости правок, </p>
                                    :
                                    null
                            }



                            {files[phase]
                                ?

                                <div className={"ready-files-container"}>

                                    {console.log("MAP3")}
                                    {mapReadyFiles(files[phase], "Готовые файлы:")}

                                    <hr/>
                                    {orderDetails.step === phase
                                        ?
                                        <p className={"margin-top-20"}>{phase+1}.2 Исполнитель добавил файлы, вы уже можете ознакомиться с ними и принять решение
                                        о своих дальнейших действиях.</p>
                                        :
                                        null}

                                    {orderDetails.corrections?.status && orderDetails.corrections?.step > phase
                                        ?
                                        <div>
                                            {orderDetails.step === phase
                                            ?
                                            <p>{phase+1}.3 <span className={"red-for-blue"}>Исполнитель получит уведомление о том что потребуются правки, сразу после того как вы приложете файлы правок либо нажмёте кнопку "Без файлов".
                                                Пожалуйста опишите возникшую ситуацию в чате заказа.</span>
                                            </p>
                                            :
                                            null}
                                            {console.log("MAP4")}
                                            {corrections[phase]
                                                ?
                                                corrections[phase][0] !== "without"
                                                    ?
                                                    mapReadyFiles(corrections[phase], "Файлы правок:")
                                                    :
                                                    <h4>В этой фазе вы не прикладывали файлы правок</h4>


                                                :
                                                null
                                            }
                                            <br/>

                                                {orderDetails.corrections.step - 1 ===  phase
                                                        ?
                                                        <div className={"stepper-file-attache-button-container"}>
                                                            {files[orderDetails.corrections.step] || orderDetails.onModeration.status || (corrections[phase] && corrections[phase][0] === "without")
                                                                ?
                                                                null
                                                                :
                                                                addInputAndButton("corrections", step, phase)}


                                                            {step !== phase
                                                                ?
                                                                null
                                                                :
                                                                orderDetails.onModeration.status || adminView
                                                                    ?
                                                                    null
                                                                    :
                                                                    <button
                                                                        className={"btn primary-btn-color order-decision-btn"}
                                                                        onClick={() => {
                                                                            setActiveModeratorInvite("progress")
                                                                            setTimeout(() => {
                                                                                setActiveModeratorInvite("active")
                                                                            }, 100)
                                                                        }
                                                                        }
                                                                    >Модератор</button>
                                                            }

                                                        </div>
                                                        :
                                                    null
                                                    // <div>{addInputAndButton("corrections")}</div>
                                                }

                                        </div>
                                        :
                                        <div className={"order-decisions-button-container"}>

                                            {orderDetails.onModeration.status
                                            || orderDetails.orderStatus === "waiting"
                                            || orderDetails.orderStatus === "closed"
                                                ?
                                                null
                                                :
                                                orderDetails.corrections?.step === phase && !adminView
                                                    ?
                                                    <div>
                                                        <button
                                                            className={"btn primary-btn-color order-decision-btn"}
                                                            onClick={() => {
                                                                setActiveModeratorInvite("progress")
                                                                setTimeout(() => {
                                                                    setActiveModeratorInvite("active")
                                                                }, 100)
                                                            }
                                                            }
                                                        >Модератор</button>
                                                        <button
                                                        className={"btn btn-danger order-decision-btn"}
                                                        onClick={() => {
                                                        setActiveCorrections("progress")
                                                        setTimeout(() => {
                                                        setActiveCorrections("active")
                                                    }, 100)
                                                    }}
                                                        >Правки {needCorrectionsRequest ? <img src={require('../../img/loading.gif')} height={"15px"}/> : null}</button>

                                                    </div>
                                                    :
                                                    null

                                            }

                                            {orderDetails.onModeration.status
                                            || orderDetails.orderStatus === "waiting"
                                            || orderDetails.orderStatus === "closed"
                                                ?
                                                null
                                                :
                                                orderDetails.corrections?.step === phase && !adminView
                                                    ?
                                                    <button
                                                        className={"btn primary-btn-color order-decision-btn"}
                                                        onClick={() => {
                                                            setActiveClosing("progress")
                                                            setTimeout(() => {
                                                                setActiveClosing("active")
                                                            }, 100)
                                                        }
                                                        }
                                                    >Завершить</button>
                                                    :
                                                    null

                                            }


                                        </div>

                                    }
                                    <div>
                                        {corrections[phase] && !orderDetails.corrections.status
                                            ?
                                            corrections[phase][0] !== "without"
                                                ?
                                                mapReadyFiles(corrections[phase], "Файлы правок:")
                                                :
                                                <h4>В этой фазе вы не прикладывали файлы правок</h4>


                                            :
                                            null}
                                    </div>
                                </div>

                                :
                                    orderDetails.onModeration?.status
                                    || orderDetails.orderStatus === "waiting"
                                    || orderDetails.orderStatus === "closed"
                                    || adminView
                                            ?
                                            null
                                            :
                                            <button
                                                className={"btn primary-btn-color order-decision-btn"}
                                                onClick={() => {
                                                    setActiveModeratorInvite("progress")
                                                    setTimeout(() => {
                                                        setActiveModeratorInvite("active")
                                                    }, 100)
                                                }
                                                }
                                            >Модератор</button>


                                }



                        </div>
                    }
                    {orderDetails.onModeration.status && step === phase && orderDetails.orderStatus !== "closed"
                        ?
                        <div>
                            <hr style={{height: "5px"}}/>
                            <div className={"ubuntu red-for-blue white-back padding-5 bold rem-13"}>
                                Открыт спор, возможность добавлять файлы и менять статус заказа отключена
                            </div>

                        </div>

                        :
                        null
                    }


                    {uploading
                    && ((phase === step-1 && Array.isArray(orderDetails.corrections.files[phase])  && !Array.isArray(orderDetails.readyFiles[phase +1])) || (phase === step && Array.isArray(orderDetails.readyFiles[phase])))
                    && orderDetails.creator._id === store.user.id
                        ?
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                            <span className="progress-text">{uploadProgress}% загружено</span>
                        </div>
                        :
                        null}


                    <hr style={{height: "5px"}}/>
                </div>
            );
        }

        return results


    }



    async function closeOrder() {

        const response = await $api.post('/close_order', {order_id: orderId, action: "init"})
        if (response.status >= 400) {
            setErrorMessage({show: true, text: response.data.message})
        }


    }

    async function closeConfirm() {
        const response = await $api.post('/close_confirm', {order_id: orderId, action: "confirm"})

        if (response.status >= 400) {
            setErrorMessage({show: true, text: response.data.message})
        } else {
            store.removeMessagesAboutIncoming(orderId)
        }
    }





    return (
        <div className={"stepper-wrapper"}>
            <hr/>
            <h4 className={"ubuntu"}>Ход работы над заказом</h4>
            <hr style={{height: "5px"}}/>


            {mainStepperLoop(orderDetails.step)}

            {orderDetails.orderStatus === "waiting" && (store.user.id === orderDetails.doer._id || store.user.id === orderDetails.creator._id) && !orderDetails.onModeration.status
                ?
                <div>
                    {store.user.id === orderDetails.doer._id
                        ?
                        <p>Заказчик инициировал закрытие заказа, вы можете подтвердить это действие, либо пригласить модератора</p>
                        :
                        <p>Вы инициировали закрытие заказа, ожидается подтверждение исполнителя.</p>
                    }

                    {orderDetails.onModeration.status || adminView
                        ?
                        null
                        :
                        <button
                            className={"btn primary-btn-color order-decision-btn"}
                            onClick={() => {
                                setActiveModeratorInvite("progress")
                                setTimeout(() => {
                                    setActiveModeratorInvite("active")
                                }, 100)
                            }
                            }
                        >Модератор</button>
                    }
                    {store.user.id === orderDetails.doer._id
                        ?
                        <button
                            className={"btn btn-danger order-decision-btn"}
                            onClick={() => {
                                setActiveCloseConfirm("progress")
                                setTimeout(() => {
                                    setActiveCloseConfirm("active")
                                }, 100)
                            }
                            }
                        >Подтвердить закрытие</button>
                        :
                        null
                    }
                </div>
                :
                null

            }
            {orderDetails.onModeration.decision.length
                ?
                <div className={"margin-bottom-20 margin-top-20"}>
                    <h3 className={"ubuntu"}>Решени{orderDetails.onModeration.decision.length > 1 ? "я" : "е"} модератора:</h3>
                    <div className={"white-back padding-20"}>
                        {orderDetails.onModeration.decision.map((decis, index) => {
                            return (
                                <div key={index}>
                                    {index > 0 ? <hr/> : null}
                                    <h6 className={"inline"}>{index + 1}. </h6>

                                        {decis.text.split("\n").map((item, key) => {
                                            return <p className={`${key === 0 ? "inline" : "margin-for-decision"}`} key={key}>{item}</p>;
                                        })    }
                                    <br/><br/>
                                    <div className={"decision-date-container"}>
                                        <img src={SvgClock} width={"20px"}/>
                                        <p className={"inline decision-p"}>{new Date(decis.date).toLocaleString([], {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}</p>
                                    </div>


                                </div>

                            )
                        })}
                        </div>
                </div>
                :
                null
            }
            {orderDetails.arbitrDecision?.text && orderDetails.moderator !== store.user.id
                ?
                <div className={"margin-bottom-20"}>
                    <h3 className={"ubuntu"}>Решение арбитража:</h3>
                    <div className={"white-back padding-20"}>
                        <p>{orderDetails.arbitrDecision?.text}</p>
                        <div className={"decision-date-container"}>
                            <img src={SvgClock} width={"20px"}/>
                            <p className={"decision-p inline"}>{new Date(orderDetails.arbitrDecision?.date).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</p>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {orderDetails.orderStatus === "closed"
                ?
                <h1 className={"ubuntu red-for-blue"}>Заказ завершён {orderDetails.onModeration.status ? "модератором" : null}</h1>
                :
                null
            }


            <ModalDialog
                active={activeModeratorInvite}
                setActive={setActiveModeratorInvite}
                action={() => inviteModerator()}
                redMessage={`Вы собираетесь пригласить модератора для решения спора по заказу #${orderDetails.number} "${orderDetails.title}".
                Это следует делать только если все способы решить вопрос в диалоге друг с другом исчерпаны`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}
                text={true}
                changeText={setModeratorMessage}
                textValue={moderatorMessage}
            />

            <ModalDialog
                active={activeCorrections}
                setActive={setActiveCorrections}
                action={() => weNeedCorrections(true)}
                redMessage={`Вы считаете что по заказу #${orderDetails.number} "${orderDetails.title}" требуется внесение правок. \n Для этого подтребуется описать в чате суть требуемых изменений и, при необходимости приложить сопутствующие материалы.`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}

            />

            <ModalDialog
                active={activeCancelCorrections}
                setActive={setActiveCancelCorrections}
                action={() => weNeedCorrections(false)}
                redMessage={`Вы вы вдруг решили что по заказу #${orderDetails.number} "${orderDetails.title}" правки всё-таки не требуются.`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}

            />

            <ModalDialog
                active={activeClosing}
                setActive={setActiveClosing}
                action={() => closeOrder()}
                redMessage={`Вы собираетесь завершить заказ #${orderDetails.number} "${orderDetails.title}"\n Для этого подтребуется подтверждение исполнителя.`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}

            />

            <ModalDialog
                active={activeCloseConfirm}
                setActive={setActiveCloseConfirm}
                action={() => closeConfirm()}
                redMessage={`Вы собираетесь подтвердить закрытие заказа #${orderDetails.number} "${orderDetails.title}"\n После этого его уже нельзя будет оспорить`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}

            />

            <ModalDialog
                active={activeWithoutFiles}
                setActive={setActiveWithoutFiles}
                action={() => withoutFiles()}
                redMessage={`Вы собираетесь отказаться от прикладывания файлов правок в этой фазе. Это значит что вы уже объяснили исполнителю в чате, что именно от него требуется сделать в качестве исправления готовых файлов`}
                message={`Вы уверены что хотите продолжить?`}
                purpose={"choice"}

            />


        </div>
    );
};

export default observer(Stepper);