import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import {Context} from "../index";
import $api, {API_URL} from "../http";
import {observer} from 'mobx-react-lite';


const OrderMiniShield = (props) => {


    const {store} = useContext(Context)




    return (
        <div className="order">

            <div className="order-top">
                <h5 className="order-title">{"#" + props.number + " " + `"`+props.title+`"`} </h5>
                <h4>{props.price ? props.price : props.offeredPrices[store.user.id]} р.</h4>
            </div>
            {props.doer
                ?
                props.doer === store.user.id
                    ?
                    props.orderStatus === "closed"
                        ?
                        props.reviews.doer
                            ?
                            <div className={'inline closed-request'}>ЗАВЕРШЁН</div>
                            :
                            <div className={'inline red-request'}>ТРЕБУЕТСЯ ОТЗЫВ</div>
                        :
                        props.orderStatus === "waiting" && !props.onModeration.status
                            ?
                            <div className={'inline red-request'}>ТРЕБУЕТСЯ ПОДТВЕРЖДЕНИЕ ЗАКРЫТИЯ</div>
                            :
                            props.onModeration.status
                                ?
                                props.arbitration
                                    ?
                                    <div className={'inline red-request'}>ИДЁТ АРБИТРАЖ</div>
                                    :
                                    <div className={'inline red-request'}>ОТКРЫТ СПОР</div>
                                :
                                props.refuse
                                    ?
                                    <div className={'inline red-request'}>ЗАПРОШЕНА ОТМЕНА</div>
                                    :
                                    <div className={'inline green-request'}>ЗАКАЗ ЗАКРЕПЛЁН</div>
                    :
                    <div className={"inline"}>
                        <div className={'inline red-request'}>НАЗНАЧЕН ДРУГОЙ ИСПОЛНИТЕЛЬ</div>

                    </div>
                :
                props.orderStatus === "private"
                    ?
                    <div className={'inline blue-request'}>ПЕРСОНАЛЬНОЕ ПРЕДЛОЖЕНИЕ</div>
                    :
                    <div className={'inline blue-request'}>ЗАЯВКА ОТПРАВЛЕНА</div>
            }
            <br/><br/>
            <div className="order-bottom">
                <div className="order-prefs">
                    <h6>{props.type}</h6>
                    <h6>{props.discipline}</h6>
                    <h6>Срок: <span className={"normal"}>{props.date}</span></h6>
                    {store.user.id === props.creator_id
                        ?
                        null
                        :
                        <h6 className={"inline margin-0"}>Заказчик:
                            <Link className={"agent-link normal"} to={`../user-details/${props.creator_id}`}>{" " + props.customer}</Link>
                        </h6>


                    }

                </div>
                <div className={"minishield-button-container"}>
                    {props.doer !== store.user.id && props.doer !== undefined
                        ?
                        <button
                            className={"btn btn-danger dissapprove-delete"}
                            onClick={() => props.takeBackRequest(props.id, props.creator_id)}
                        >Убрать</button>
                        :
                        null
                    }
                    <Link
                        to={"/order-details/" + props.id}
                        className={"about-order-btn btn primary-btn-color"}
                    >Подробнее...</Link>
                </div>
            </div>


        </div>
    );
};

export default observer(OrderMiniShield);