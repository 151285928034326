import React, {useContext} from 'react';
import {Context} from "../../index";
import SvgBack from "../../img/arrow-left-svgrepo-com.svg";
import TextExpander from "./TextExpander"; // Путь к SVG-файлу
import {API_URL} from "../../http";


const NestedMessageModal = ({
                                active,
                                setActive,
                                message,
                                dateSet,
                                linkRoller,
                                processMessageText,
                                showNestedMessage,
                                lastSteps,
                                setLastSteps,
                                stepCounter,
                                setStepCounter,
                                workMessages,
                                moderator,
                                user_id,
                                action,
                                chatId
                            }) => {

    const colorBank = ['#ffe78e', '#45ec2f','#ffffff', '#ffb703',   '#f8e405', ];

    const {store} = useContext(Context)

    const usedColors = new Map();

    let nextColorIndex = 0;


    console.log(message)
    // console.log(chatId === message?.forwarded_from)




    function getColorForSender(senderId) {
        if (usedColors.has(senderId)) {
            // Если цвет уже использован для данного отправителя, вернуть его сохраненный цвет
            return usedColors.get(senderId);
        } else {
            // Получить цвет из банка по текущему индексу
            const color = colorBank[nextColorIndex];

            // Сохранить цвет для данного отправителя
            usedColors.set(senderId, color);

            // Увеличить индекс для следующего цвета
            nextColorIndex = (nextColorIndex + 1) % colorBank.length;

            return color;
        }
    }


    function containsLink(text) {
        const linkRegex = /(https?:\/\/[^\s]+)/;
        return linkRegex.test(text);
    }

    function processMessageText(text) {

        if(text) {
            console.log(text)

            if (containsLink(text)) {
                const messageContent = text.replace(
                    /(https?:\/\/[^\s]+)/g,
                    (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url}</a>`
                );

                return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
            }

            const main_array = text?.split(" ")

            console.log(main_array)

            let decision = false

            let container = []

            for(let piece of main_array) {
                if (piece.length > 25) {
                    decision = true;
                    break
                }
            }

            if (decision) {

                const chunkSize = 20;

                function splitWord(text, size) {
                    const chunks = [];

                    for (let i = 0; i < text.length; i += size) {
                        chunks.push(text.slice(i, i + size));
                    }

                    return chunks;
                }

                const wordChunks = splitWord(text, chunkSize);

                for(let chunk of wordChunks) {

                    container.push(
                        <span>{chunk}<wbr/></span>
                    )
                }

                return container

            } else {
                return text
            }

        } else {
            return
        }


    }





    const renderForwardedMessages = (list, key_id, from, on_fly) => {


        console.log(list)

        const mappedArray = list.map((message, index) => {

            getColorForSender(message.sender?._id)

            return (
                <div
                    key={message._id + key_id + index}
                    className={"forwarded-message-nested"}
                    style={{backgroundColor: getColorForSender(message.sender?._id)}}
                >
                    <h6 className={"rem07 bold margin-0"}>{
                        message.sender?._id === store.user.id
                            ?
                            "Я:"
                            :
                            message.sender?.lastName
                                ?
                                message.sender?.name + " " + message.sender?.lastName + ":"
                                :
                                message.sender?.name + ":"

                    }</h6>
                    {message.links?.length
                        ?
                        linkRoller(message.links)
                        :
                        null
                    }
                    <div className={"rem08 margin-0"}>{processMessageText(message?.text)}</div>
                    {message.quote_disput || message.forwarded_disput?.length || message.forwarded?.length || message.quote || message.forwarded_array?.length
                        ?
                        <a className={"rem07 modal-link"} onClick={() => showNestedMessage(message._id, from, false, 4)}>показать вложения</a>
                        :
                        null
                    }


                </div>
            )
        })

        if(on_fly) {
            return mappedArray.reverse()
        } else {
            return mappedArray
        }

    }



    if(message) {



        // console.log(lastSteps)
        console.log(message)

        return (
            <div className={active === "active"
                ?
                "modal active"
                :
                active === "progress" ? "modal" : "none"}
                 onClick={() => {
                     setLastSteps([])
                     console.log("Setting step counter to 0 one")
                     setStepCounter(0)
                     setActive("progress")
                     setTimeout(() => {
                         setActive("no")
                     }, 300)
                 }}
            >

                <div className={"modal-content-nested-under"}>


                    <div

                        className={active === "active"
                        ?
                        "modal-content-nested active"
                        :
                        active === "progress" ? "modal-content-nested" : "none"}
                         onClick={(e) => e.stopPropagation()}
                    >

                        <div className={"nested-modal-content-top"}>
                            <h6 className={"ubuntu"}>Вложенное сообщение:</h6>
                        </div>

                        <div className={"modal-content-nested-bottom"}>
                            <div
                                className={"nested-message-in-modal"}
                                key={message?.date}

                            >
                                <div className={"nested-message-name-n-avatar-container"}>
                                    {
                                        workMessages
                                            ?
                                            null
                                            :
                                            <img className={"ws-message-avatar"} src={`${API_URL}/public/avatars/${message.sender?._id}/avatar.png`}/>}
                                    <div className={"bold"}>{
                                        workMessages
                                            ?
                                            message.isModerator
                                                ?
                                                message.userId._id === user_id
                                                    ?
                                                    "Я"
                                                    :
                                                    "Модератор "
                                                :
                                                message.userId._id === user_id
                                                    ?
                                                    "Я"
                                                    :
                                                    message.userId?.name + " " + message.userId?.lastName
                                            :
                                            message.sender?._id === store.user.id
                                                ?
                                                "Я"
                                                :
                                                message.sender?.name + " " + message.sender?.lastName
                                    }</div>

                                </div>
                                <p className={"forward-message-date"}>
                                    {dateSet(message?.date)}
                                </p>


                                {message?.forwarded_array?.length && !workMessages
                                    ?
                                    <div>
                                        <h6 className={"rem08"}>Пересылаемые сообщения:</h6>
                                        {renderForwardedMessages(message?.forwarded_array, message?._id, message.forwarded_from, message.on_fly)}
                                    </div>

                                    :
                                    null
                                }

                                {message?.forwarded_disput?.length && !workMessages
                                    ?
                                    <div>
                                        <h6 className={"rem08"}>Пересылаемые сообщения:</h6>
                                        {renderForwardedMessages(message?.forwarded_disput, message?._id)}
                                    </div>

                                    :
                                    null
                                }

                                {message.quote_disput && !workMessages
                                    ?
                                    <div className={"answered-message answered-left"}>

                                        {/*<p className={"private-message-date rem07"}>*/}
                                        {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
                                        {/*</p>*/}
                                        <div className={"nick-and-go"}>
                                            <span className={"bold rem07"}>
                                                {message.quote_disput.sender?._id === store.user.id
                                                    ?
                                                    "Я23"
                                                    :
                                                    message.quote_disput.sender?.name + " " + message.quote_disput.sender?.lastName
                                                }:</span>

                                            {chatId === message?.forwarded_from
                                                ?
                                                <div
                                                    title={"Перейти к сообщению"}
                                                    className={"pointer nowrap margin-left-10"}
                                                    onClick={(e) => {
                                                        setLastSteps([])
                                                        console.log("Setting step counter to 0 two")
                                                        setStepCounter(0)
                                                        setActive("progress")
                                                        console.log(message)
                                                        setTimeout(() => {
                                                            setActive("no")
                                                        }, 300)
                                                        action(e, message.quote || message.quote_disput._id)
                                                    }}
                                                >⏫</div>
                                                :
                                                null
                                            }
                                        </div>
                                        {message?.quote_links?.length || message?.quote_disput?.links
                                            ?
                                            linkRoller(message?.quote_links || message.quote_disput.links)
                                            :
                                            null
                                        }
                                        <span className={"rem08"}>
                                            {message?.quote_disput.text
                                                ?
                                                <TextExpander text={processMessageText(message?.quote_disput.text)} />
                                                :
                                                <TextExpander text={processMessageText(message?.quote_text)} />
                                            }
                                        </span>
                                        {message.quote_disput.quote_disput
                                            ?
                                            <a className={"rem07 modal-link"} onClick={() => showNestedMessage(message.quote_disput._id, chatId, false, 1)}>показать вложения</a>
                                            :
                                            null
                                        }
                                        {message.quote_disput.forwarded_array?.length
                                            ?
                                            <a className={"rem07 modal-link"} onClick={() => showNestedMessage(message.quote_disput._id, message.forwarded_from, false, 1)}>показать вложения</a>
                                            :
                                            null
                                        }

                                    </div>
                                    :
                                    null
                                }

                                {message?.quote
                                    ?
                                    <div className={"forwarded-message-nested"}>

                                        {/*<p className={"private-message-date rem07"}>*/}
                                        {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
                                        {/*</p>*/}

                                        <div className={"nick-and-go"}>
                                            <span className={"bold rem07"}>{
                                                workMessages && !message.quote.isModerator
                                                    ?
                                                    message.quote.userId._id === user_id
                                                        ?
                                                        "Я"
                                                        :
                                                        message.quote.userId.name + " " + message.quote.userId.lastName
                                                    :
                                                    moderator
                                                        ?
                                                        moderator === user_id
                                                            ?
                                                            "Я"
                                                            :
                                                            "Модератор"
                                                        :
                                                        message.quote.sender?.name + " " + message.quote.sender?.lastName}
                                            :</span>
                                            {chatId === message?.forwarded_from
                                                ?
                                                <div
                                                    title={"Перейти к сообщению"}
                                                    className={"pointer nowrap margin-left-10"}
                                                    onClick={(e) => {
                                                        setLastSteps([])
                                                        console.log("Setting step counter to 0 three")
                                                        setStepCounter(0)
                                                        setActive("progress")
                                                        setTimeout(() => {
                                                            setActive("no")
                                                        }, 300)
                                                        action(e, message.quote)
                                                    }}
                                                >⏫</div>
                                                :
                                                null
                                            }
                                        </div>
                                        {(message?.quote_links?.length || message?.quote.links) && !workMessages
                                            ?
                                            linkRoller(message?.quote_links || message.quote.links)
                                            :
                                            null
                                        }
                                        <span className={"rem08"}>
                                            {message?.quote.text
                                                ?
                                                <TextExpander text={processMessageText(message?.quote.text)} />
                                                :
                                                <TextExpander text={processMessageText(message?.quote_text)} />
                                            }
                                        </span>
                                        {message.quote.quote_disput || message.quote.forwarded_disput?.length || message.quote.forwarded?.length || message.quote.quote
                                            ?
                                            <a className={"rem07 modal-link"} onClick={() => showNestedMessage(message.quote._id, message.quote.forwarded_from, false, 5)}>показать вложения</a>
                                            :
                                            null
                                        }
                                        {/*{message.forwarded_disput?.length || message.forwarded?.length*/}
                                        {/*    ?*/}
                                        {/*    <a href={"#"} onClick={() => showNestedMessage(message.quote._id, false, 5)}>показать вложения</a>*/}
                                        {/*    :*/}
                                        {/*    null*/}
                                        {/*}*/}




                                    </div>
                                    :
                                    null
                                }
                                <p className={"nested-message-text"}>{processMessageText(message?.text)}</p>
                                {message?.links?.length && !workMessages
                                    ?
                                    linkRoller(message?.links)
                                    :
                                    null
                                }
                            </div>

                            <div>
                                <button
                                    className={`btn ${stepCounter <= 1 ? "btn-danger" : "btn-warning" } back-nested-btn`}
                                    onClick={() => {
                                        console.log(stepCounter, " - step")
                                        if (stepCounter <= 1) {
                                            setLastSteps([])
                                            console.log("Setting step counter to 0 four")
                                            setStepCounter(0)
                                            setActive("progress")
                                            setTimeout(() => {
                                                setActive("no")
                                            }, 300)
                                        } else {

                                            console.log(lastSteps)
                                            showNestedMessage(lastSteps[stepCounter - 2], chatId, true, 6)
                                        }

                                    }}
                                >{stepCounter <= 1 ? "Закрыть" : <img src={SvgBack} width={"25px"}/>}</button>
                            </div>
                        </div>




                    </div>

                </div>

            </div>
        );

    } else {
        return
    }



};

export default NestedMessageModal;