import React, {useState, useEffect} from 'react';
import $api, {API_URL} from "../../http";
import {Link} from "react-router-dom";
import {throttle} from "./repeat_functions";
import Loading from "../Loading";


const OrderViwer = () => {

    const fetchLimit = 20
    const [orderKeeper, setOrderKeeper] = useState([])
    const [orderFetching, setOrderFetching] = useState(true)
    const [skipCounter, setSkipCounter] = useState(0)
    const [stopFetching, setStopFetching] = useState(false)
    const [filter, setFilter] = useState("")


    async function deleteOrder(order_id) {
        const response = await $api.post('/order_delete', {order_id: order_id})
        if(response.status < 400) {
            setOrderKeeper(prev => {
                const newState = prev.filter(order => order._id !== order_id)
                return newState


            })
        }
    }

    function handleScroll(e) {

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            console.log("Пора фетчить")
            setOrderFetching(true)
        }

        console.log('Общая высота', e.target.documentElement.scrollHeight)
        console.log('Текущее положение', e.target.documentElement.scrollTop)
        console.log('Высота видимой области', window.innerHeight)

    }

    const scrollHandler = throttle(handleScroll, 600)


    useEffect(() => {

        //слушатель события "scroll" повешенный на весь документ
        document.addEventListener('scroll', scrollHandler)

        //при размонтировании элемента эта функция так же отключает слушатель события
        return function() {
            console.log("DO REMOVE")
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])


    async function getOrders(skipCounter, filter) {
        console.log(filter)
        const response = await $api.get(`/get_orders?skip=${skipCounter}&limit=${fetchLimit}&filter=${filter}`)
        console.log(response.data)
        setOrderKeeper(prev => [...prev, ...response.data])
        setOrderFetching(false)
        setSkipCounter(prev => prev + fetchLimit)
        if(response.data.length < fetchLimit) setStopFetching(true)
    }


    useEffect(() => {
        if(orderFetching && !stopFetching) {
            getOrders(skipCounter, filter)
        }
    }, [orderFetching, skipCounter, stopFetching, filter])


    return (
        <div>
            <select
                onChange={(e) => {

                    setFilter(e.target.value)
                    setOrderKeeper([])
                    setSkipCounter(0)
                    setStopFetching(false)
                    setTimeout(() => {
                        setOrderFetching(true)
                    }, 1000)
                }}
            >
                <option value={""}>Все</option>
                <option value={"taken"}>В работе</option>
                <option value={"offer"}>Свободные</option>
                <option value={"moderation"}>Модерация</option>
                <option value={"closed"}>Закрытые</option>
            </select>

            <table className="table invite-table">
                <thead>
                <tr>
                    <th className={"order-table-th"} scope="col">#</th>
                    <th className={"order-table-th"} scope="col">Название</th>
                    <th className={"order-table-th"} scope="col">Заказчик</th>
                    <th className={"order-table-th"} scope="col">Исполнитель</th>
                    <th className={"order-table-th"} scope="col">Статус</th>
                    <th className={"order-table-th"} scope="col"></th>
                </tr>
                </thead>
                <tbody>

                {orderKeeper.map(order => {
                    return (
                        <tr key={order._id}>
                            <td className={"order-table-td"}>{order.number}</td>
                            <td title={order.title} className={"order-table-td"}>{order.title}</td>
                            <td className={"order-table-td"}>
                                <div>
                                    <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${order.creator?._id}/avatar.png`} />
                                    <Link target={"_blank"} to={`/user-details/${order.creator?._id}`}>
                                        {order.creator?.name + " " + order.creator?.lastName}
                                    </Link>
                                </div>


                            </td>
                            <td className={"order-table-td"}>
                                {order.doer
                                    ?
                                    <div>
                                        <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${order.doer?._id}/avatar.png`} />
                                        <Link target={"_blank"} to={`/user-details/${order.doer?._id}`}>
                                            {order.doer?.name + " " + order.doer?.lastName}
                                        </Link>
                                    </div>

                                    :
                                    "N/A"
                                }
                            </td>
                            <td className={"order-table-td"}>{order?.orderStatus}</td>
                            <td className={"order-table-td"}>
                                <Link className={"none-decoration"} target={"_blank"} to={`/order-details-admin/${order?._id}`}>
                                    <button className={"btn btn-primary-poor open-order-admin-btn"}>Открыть</button>
                                </Link>
                                {/*<button*/}
                                {/*    className={"btn btn-danger open-order-admin-btn margin-top-5"}*/}
                                {/*    onClick={() => deleteOrder(order?._id)}*/}
                                {/*>Удалить</button>*/}

                            </td>
                        </tr>
                    )})}

                </tbody>
            </table>
            {orderFetching && !stopFetching
                ?
                <Loading/>
                :
                null
            }

        </div>
    );
};

export default OrderViwer;