import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {toJS} from "mobx";
import {Context} from "../index";
import $api from "../http";
import {observer} from 'mobx-react-lite';
import NestedMessageModal from "./helpers/NestedMessageModal";
import TextExpander from "./helpers/TextExpander";
import SvgAnswer from "../img/answer-arrow.svg";
import SvgArrowDown from "../img/arrow-down.svg";
import {API_URL} from "../http";
import {throttle, processMessageText, getMinutesBetweenDates, assignWidth} from "./helpers/repeat_functions";
import {Link} from "react-router-dom"
import SvgIncomingOrder from "../img/incoming-order.svg"
import SvgOutgoingOrder from "../img/outgoing-order.svg"
import SvgCheckUnread from "../img/checkmark-unread.svg"
import SvgCheckRead from "../img/checkmark-read.svg"
import SvgRedDot from "../img/red-dot.svg"
import SvgWhiteDot from "../img/white-dot.svg"
import SvgUserArrow from "../img/user-arrow.svg"
import ModalDialog from "./helpers/ModalDialog";
import ModalImgViewer from "./helpers/ModalImgViewer";
import SvgSend from "../img/send.svg";


const NewMessageCenter = ({notifySocket, newOrderMessage, orderServiceMessage, setOrderServiceMessage, pendingMessagesCenter, setPendingMessagesCenter}) => {




    const {store} = useContext(Context)
    //режим работы исполнитель (performer), либо заказчик (creator), просто строка
    const [mode, setMode] = useState("all")
    const [subMode, setSubMode] = useState()


    useEffect(() => {
        store.setPageId("message-center")
    }, [])


    const storedSoundOn = localStorage.getItem(`sound_work`);


    const [shiftEnterPressed, setShiftEnterPressed] = useState(false);

    const [soundOutOn, setSoundOutOn] = useState(localStorage.getItem(`sound_work_out`) === "true");
    const [soundInOn, setSoundInOn] = useState(localStorage.getItem(`sound_work_in`) === "true");

    const event_message_notification_sound = new Audio('/sounds/message3.mp3')

    function playSound() {
        event_message_notification_sound.play()
        new Notification('Тихое уведомление', { silent: true });
    }

    //реф-ссылка на область с сообщениями
    const messagesAreaRef = useRef()

    const stopMakingObserver = useRef(false)

    //это реф-ссылка на обзёрвер который следит за появление хтмл элементов с сообщениями в области видмости
    //он нужен для того чтобы отмечать сообщения прочитанными
    const messageObserver = useRef(null);

    //этот реф призван хранить элементы которые обсервер уже пронаблюдал в зоне видимости,
    //чтобы при добавлении не добавить ничего второй раз
    const markedElements = useRef(new Set())


    //это стейты сообщений разделенные на входящие и исходящие заказы
    //вид стейта такой - {айдишник заказа: [массив сообщений по нему]}
    const [outgoingOrdersMessages, setOutgoingOrdersMessages] = useState({})
    const [incomingOrdersMessages, setIncomingOrdersMessages] = useState({})

    //это стейты инфы по каждому заказу, разделенные на входящие и исходящие заказы, с вырезанными сообщениями
    //вид стейта такой - {айдишник заказа: {объект инфы}}
    const [outgoingOrdersInfo, setOutgoingOrdersInfo] = useState([])
    const [incomingOrdersInfo, setIncomingOrdersInfo] = useState([])

    //это стейт для разворота кнопок заказов, входящи или исходящих, внутри просто массив айдишников
    //при развороте берем инфу из стейта с инфой
    const [outgoingOrdersToRoll, setOutgoingOrdersToRoll] = useState([])
    const [incomingOrdersToRoll, setIncomingOrdersToRoll] = useState([])
    const [allOrdersToRoll, setAllOrdersToRoll] = useState([])

    //стейты активного заказа, входящего или исходящего, внутри просто айдишник
    const [activeOrderOut, setActiveOrderOut] = useState()
    const [activeOrderIn, setActiveOrderIn] = useState()

    //активный юзер по исходящему заказу, внутри просто его айдишник
    const [activeUserOut, setActiveUserOut] = useState()

    //стейт для развертывания сообщений, по входящим либо исходящим заказам
    const [messagesToRollOut, setMessagesToRollOut] = useState([])
    const [messagesToRollIn, setMessagesToRollIn] = useState([])

    //стейты с айдишниками заказов по которым есть непрочитанные сообщения, нужен для отображения конверта мигалки
    const [unreadOrdersIn, setUnreadOrdersIn] = useState([])
    const [unreadOrdersOut, setUnreadOrdersOut] = useState({})

    //список существующих юзеров (диалогов) для каждого исходящего заказа
    //вид {айди заказа: [массив айди юзеров]}
    const [usersOfOutOrder, setUsersOfOutOrder] = useState({})
    //короткая инфа о каждом юзере, айди, имя и фамилия
    //вид {айди юзера: {объект инфы}}
    const [shortUsersInfo, setShortUsersInfo] = useState({})
    //список юзеров для развертывания, обновляется после смены активного юзера
    //внутри массив айдишников
    const [userListToRoll, setUserListToRoll] = useState([])

    //стейт с инфой по текущему заказу, обновляется после смены активного заказа или смены режима работы
    //внутри хтмл, как он вормируется ниже будет понятно в одном из useEffect-ов
    //const [currentOrderInfo, setCurrentOrderInfo] = useState(<p>Здесь будет отображаться информация об активном заказе.</p>)
    const [currentOrderInfo, setCurrentOrderInfo] = useState(<p></p>)

    //стейт с содержимым инпута чатика
    const [chatValue, setChatValue] = useState("")


    async function downloadHandler(path) {


        const response = await $api.get(`/download_file?path=${path}`, {responseType: 'arraybuffer'})

        // тут использован fetch вместо аксиоса, потому что Ulbi сказал что так проще блять
        // const response = await fetch("http://localhost:5000/download_file", {
        //     Authorization: `Bearer ${localStorage.getItem('token')}`,
        // })

        //создаем блоб (Binary Large OBject) из ответа
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        //const blob = await response.blob()
        //из него генерируем ссылку на файл
        const downloadURL = URL.createObjectURL(blob)
        //создаем элемент ссылки
        const link = await document.createElement('a')
        //назначаем элементу собственно ссылку
        link.href = downloadURL
        //это тоже всё еще назначение ссылки (тут добавляется имя файла)
        //link.download = await path.split('\\')[4]
        //link.download = path.substring(path.lastIndexOf("\\") + 1).replace(/_/g, " ")

        // Разделите строку пути с использованием '/' или '\', затем возьмите последний элемент
        const pathParts = path.split(/[\\/]/);
        const fileName = pathParts.pop();
        link.download = fileName.replace(/_/g, ' ');

        //добавляем то что создали в ДОМ
        document.body.appendChild(link)
        //кликаем
        link.click()
        //и избавляемся от элемента
        link.remove()
        URL.revokeObjectURL(downloadURL)
    }




    const [activeFileDoesntExist, setActiveFileDoesntExist] = useState("no")
    const [activeModalImgViewer, setActiveModalImgViewer] = useState("no")
    const [imageSource, setImageSource] = useState()


    function linkRoller(linksArray) {
        const result = []
        for(let link of linksArray) {

            let type

            if(link.type === "doc" || link.type === "docx") {
                type = "word"
            } else if (link.type === "xls" || link.type === "xlsx") {
                type = "excel"
            } else if (link.type === "ppt" || link.type === "pptx") {
                type = "power_point"
            } else if (link.type === "rar" || link.type === "zip") {
                type = "winrar"
            } else if (link.type === "pdf") {
                type = "pdf"
            } else if (link.type === "image") {
                type = "image"
            } else {
                type = "doc_thumb"
            }

            if(type === "image") {

                result.push(
                    <div
                        className={"chat-pre-image-2"}
                        onClick={() => {
                            setActiveModalImgViewer("progress")
                            setTimeout(() => {
                                setActiveModalImgViewer("active")
                            }, 100)
                            setImageSource(link.path)
                        }}
                    >
                        {/*<a className={"link-from-roller"} href={API_URL + "/" + link.path} target={"_blank"}>*/}
                        <img
                            className={"chat-pre-image dont-drag"}
                            src={API_URL + "/" + link.thumbnail}

                        />
                        {/*</a>*/}

                    </div>

                )
            } else {


                const filenameWindows = link.path.substring(link.path.lastIndexOf("\\") + 1).replace(/_/g, " ");
                let filename;

                if (filenameWindows.includes('/')) {
                    filename = filenameWindows.split('/').pop().replace(/_/g, " ");
                } else {
                    filename = filenameWindows.replace(/_/g, " ");

                }

                result.push(
                    <div>


                        <a
                            className={"fake-link link-from-roller "}
                            onClick={async() => {

                                const condition = await downloadHandler(link.path)

                                if(condition === "FAIL") {
                                    setActiveFileDoesntExist("progress")
                                    setTimeout(() => {
                                        setActiveFileDoesntExist("active")
                                    }, 100)
                                }
                            }}
                        ><img className={"order-done-file-image dont-drag"} src={require(`../img/${type}.png`)} width={"35"}/>
                            "{filename.length > 60 ? filename.slice(0, 60) + "..." + filename.slice(-10) : filename}"</a>
                    </div>

                )
            }

        }

        return result

    }


    async function showUnreadOrders(direction) {
        const response = await $api.get(`/search_for_work_unread?my_id=${store.user.id}&direction=${direction}`)
        console.log(response.data)





        // Создайте пустые объекты или массивы для хранения промежуточных данных
        let usersOfOutOrder = {};
        let outgoingOrdersInfo = {};
        let unreadOrdersOut = {};

        // Обработка каждого заказа
        for (let order of response.data) {
            temporaryMessageStorage.current[order._id] = [...order.msgs]
            await $api.post('/unhide_dialog', {order_id: order._id, user_id: store.user.id})
            const listOfUniqueUsers = new Set();
            let listOfUsers = order.msgs?.reduce((acc, current) => {

                if (acc.indexOf(current.userId._id) === -1 &&
                    current.userId._id !== store.user.id &&
                    (!response.data.hiddenUsers?.includes(current.userId._id) || current.userId._id === newOrderMessage.userId._id)
                ) {
                    if (response.data.hiddenUsers?.includes(current.userId._id)) {
                        unhideUser(newOrderMessage.about, current.userId._id, false)
                    }
                    acc.push(current.userId._id);
                    listOfUniqueUsers.add(current.userId._id);
                }
                return acc;
            }, []);

            console.log(listOfUsers)
            console.log("GET SHORT 1")
            await getShortUsersInfo([...listOfUsers]).then(() => console.log("users added"))

            // Заполняем промежуточные хранилища внутри цикла
            usersOfOutOrder[order._id] = listOfUsers;
            outgoingOrdersInfo[order._id] = order;

            // Фильтрация айдишников пользователей
            const unreadUsers = new Set();
            for (let message of order.msgs) {
                if (!message.read.includes(store.user.id)) {
                    unreadUsers.add(message.userId._id);
                }
            }



            const unreadUsersArray = Array.from(unreadUsers);
            unreadOrdersOut[order._id] = unreadUsersArray;
        }

        // Установка состояний после завершения цикла
        await setUsersOfOutOrder(prevState => ({ ...prevState, ...usersOfOutOrder }));
        await setOutgoingOrdersInfo(prevState => ({ ...prevState, ...outgoingOrdersInfo }));
        await setUnreadOrdersOut(prevState => ({ ...prevState, ...unreadOrdersOut }));


    }




    useEffect(() => {
        if(chatValue === "" && textAreaRef.current) {
            textAreaRef.current.style.height = "auto"
        }
        console.log("ADJUSTING 1")
        if(textAreaRef.current) textAreaAdjust()

    }, [chatValue])

    //ссылка на элемент хтмл внутри которого пишется новое сообщение
    const textAreaRef = useRef()
    //реф запоминалка прошлого активного юзера по исзодящим заказам
    const lastActiveUserOutRef = useRef()
    //ссылка запоминалка текущего активного юзера по исходящим заказам
    const activeUserOutRef = useRef()

    //рефы-запоминалки о том какой заказ сейчас активен, потому что функция отметки прочитанным
    //не может из стейта эту инфу достать, не успевает обновиться или типа того
    const activeOrderInRef = useRef()
    const activeOrderOutRef = useRef()
    //ссылка запоминалка на текущий режим работы
    const modeRef = useRef()
    const subModeRef = useRef()
    //ссылка запоминалка о том, какой режим работы был прошлым (их всего два, но она меняется не только при смене режима)
    //но и при смене активного заказа и активного юзера, нужна для того чтобы корректно работало отображениие
    //линии НОВЫЕ СОБЩЕНИЯ
    const lastModeRef = useRef()


    //тут храним адишники прочитанных сообщений, чтобы при повторном развертывании уже прочитанных сообжений
    //опять таки правильно отображать линию НОВЫЕ СООБЩЕНИЯ
    const readMessagesOut = useRef({})
    const readMessagesIn = useRef({})

    //рефы с флагом о необходимости рисовать линию НОВЫЕ СООБЩЕНИЯ
    //используется как одно из условия для принятия решения о рисовании такой линии
    const needNewMessageLineOut = useRef(true)
    const needNewMessageLineIn = useRef(true)

    //временное хранилище новых сообщений,
    //сюда попадают все сообщения которые пришли когда их целевое окно не было активным
    const temporaryMessageStorage = useRef({})

    //это резалка массива сообщений чтобы в моменте у нас отрисовывалось не более 50 объектов сообщений
    //для того чтобы фронт не занимался лишней работой и не тормозил
    const [slicerOut, setSlicerOut] = useState({start: 0, stop: 50})
    const [slicerIn, setSlicerIn] = useState({start: 0, stop: 10})

    //эти рефы запоминают полную высоту области прокрутки сообщений, для того чтобы когда она перестанет увеличиваться
    //мы бы перестали менять значение резака массивов, чтобы не нагружать комп не нужными расчётами
    const slicerRefOut =  useRef(0)
    const slicerRefIn =  useRef(0)

    //это рефы помощники используемые в момент принятия решения о том, нужно ли прокручивать область сообщений
    //в момент обновления массива с сообщениями для развёртвания
    const scrollerRefOut = useRef(true)
    const scrollerRefIn = useRef(true)


    const [showArrowDown, setShowArrowDown] = useState(false)


    //функция обработчика скролла завернутая в функцию throttle


    const chatScrollTopRef = useRef({})


    // const scroller = useCallback(scrolling, [activeOrderIn, activeOrderOut, activeUserOut, mode])

    const handleScroll = throttle(scroller, 600)

    //обработчик события скролла области отображения сообщений
    function scroller(e) {

         console.log(e.target.scrollTop)
        // console.log(e.target.scrollHeight)
        // console.log(e.target.clientHeight)


        console.log(activeOrderInRef.current)
        console.log(activeOrderOutRef.current)
        console.log(activeUserOutRef.current)
        console.log(modeRef.current)
        console.log(subModeRef.current)

        if((modeRef.current === "all" && subModeRef.current === "creator") || modeRef.current === "creator") {
            setTimeout(() => {
                console.log(`Выставляем значение ${messagesAreaRef.current.scrollTop} для ${activeOrderOutRef.current + activeUserOutRef.current}, сейчас высота ${messagesAreaRef.current.scrollHeight}`)
                chatScrollTopRef.current[activeOrderOutRef.current + activeUserOutRef.current] = messagesAreaRef.current.scrollTop
            }, 500)
        } else {
            setTimeout(() => {
                console.log(`Выставляем значение ${messagesAreaRef.current.scrollTop} для ${activeOrderInRef.current}, сейчас высота ${messagesAreaRef.current.scrollHeight}`)
                chatScrollTopRef.current[activeOrderInRef.current] = messagesAreaRef.current.scrollTop
            }, 500)
        }

        // setTimeout(() => {
        //     console.log(`Выставляем значение ${messagesAreaRef.current.scrollTop} для ${chatId}, сейчас высота ${messagesAreaRef.current.scrollHeight}`)
        //     chatScrollTopRef.current[chatId] = messagesAreaRef.current.scrollTop
        // }, 500)


        //если текущий режим работы ЗАКАЗЧИК
        if((modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator"))) {
            //если мы находимся на расстоянии менее 150рх от верхнего края полной области развернутых сообщений
            //и текщая высота полной области прокрутки больше таковой на прошлой итерации
            if(e.target.scrollTop + e.target.scrollHeight - e.target.clientHeight < 150
                && e.target.scrollHeight > slicerRefOut.current) {
                console.log("CHANGING SLICER INIT")
                //то увеличиваем резак на 50 сообщений
                setSlicerOut({start: 0, stop: slicerOut.stop + 50})
                //и запоминаю высоту прокручиваемой области
                slicerRefOut.current = e.target.scrollHeight
            }
            //если мы находимся при этом в самом низу полной области прокрутки...
            if(e.target.scrollTop === 0) {
                //... то сбрасываем резак и запоминалку высоты прокручиваемой области
                setSlicerOut({start: 0, stop: 50})
                slicerRefOut.current = 0
            }
            //если же текущий режим работы ИСПОЛНИТЕЛЬ
        } else {
            //если мы находимся на расстоянии менее 150рх от верхнего края полной области развернутых сообщений
            //и текщая высота полной области прокрутки больше таковой на прошлой итерации
            if(e.target.scrollTop + e.target.scrollHeight - e.target.clientHeight < 150
                && e.target.scrollHeight > slicerRefIn.current) {
                console.log("CHANGING SLICER INIT 2")
                //то увеличиваем резак на 50 сообщений
                setSlicerIn({start: 0, stop: slicerIn.stop + 50})
                //и запоминаю высоту прокручиваемой области
                slicerRefIn.current = e.target.scrollHeight
            }
            //если мы находимся при этом в самом низу полной области прокрутки...
            if(e.target.scrollTop === 0) {
                //... то сбрасываем резак и запоминалку высоты прокручиваемой области
                setSlicerIn({start: 0, stop: 50})
                slicerRefIn.current = 0
            }
        }

        if (e.target.scrollTop < -150) {
            setShowArrowDown(true)
        }

        if (e.target.scrollTop > -10) { //&& stopDownFetchingRef.current) {
            setShowArrowDown(false)
        }


    }


    const updateMessagesReadStatus = async (object) => {

        stopMakingObserver.current = true

        const { direction, order_id, messagesIdArray, reader } = object;

        // Выбор стейта на основе direction
        const targetState = direction === "in" ? outgoingOrdersMessages : incomingOrdersMessages;

        // Поиск сообщений по order_id
        const orderMessages = targetState[order_id];

        if (orderMessages) {
            // Обновление read для соответствующих сообщений
            const updatedMessages = orderMessages.map((message) => {
                if (messagesIdArray.includes(message._id)) {
                    return {
                        ...message,
                        read: [...message.read, reader],
                    };
                }
                return message;
            });

            // Обновление стейта
            if (direction === "in") {
                console.log("set OOM 1")
                setOutgoingOrdersMessages((prev) => ({
                    ...prev,
                    [order_id]: updatedMessages,
                }));
            } else {
                console.log("set IOM 1")
                setIncomingOrdersMessages((prev) =>
                    {
                        console.log(prev)
                        return {
                            ...prev,
                            [order_id]: updatedMessages,
                        }
                    });
            }
        }
    }




    const queueTimer = useRef()

    async function mergeArraysAndProcess(inputArray) {
        // Группируем объекты по полю order_id
        const groupedObjects = inputArray.reduce((groups, obj) => {
            const orderId = obj.order_id;
            if (!groups[orderId]) {
                groups[orderId] = [];
            }
            groups[orderId].push(obj);
            return groups;
        }, {});

        // Обработка каждой группы
        for (const orderId in groupedObjects) {
            if (groupedObjects.hasOwnProperty(orderId)) {
                const group = groupedObjects[orderId];

                // Создаем объект для хранения уникальных элементов messagesIdArray
                let uniqueMessages = {};

                // Проходим по каждому объекту в группе
                group.forEach(obj => {
                    // Объединяем значения messagesIdArray в объект uniqueMessages
                    obj.messagesIdArray.forEach(messageId => {
                        uniqueMessages[messageId] = true;
                    });
                });

                // Создаем объект для результирующего массива
                let resultObject = {
                    // Выбираем любой объект из группы для копирования значений
                    ...group[0],
                    // Присваиваем объединенный массив uniqueMessages полю messagesIdArray
                    messagesIdArray: Object.keys(uniqueMessages)
                };

                // Передаем объект в функцию и ждем выполнения
                await updateMessagesReadStatus(resultObject);
            }
        }
    }



    useEffect(() => {

        if(orderServiceMessage.length) {


            clearTimeout(queueTimer.current)

            queueTimer.current = setTimeout(() => {

                //updateMessagesReadStatus(mergeArrays(orderServiceMessage))
                console.log("Тут запускается магия!")

                mergeArraysAndProcess(orderServiceMessage)
                setOrderServiceMessage([])

            }, 1500)

            console.log("WE HAVE NEW SERVICE MESSAGE")


        }

    }, [orderServiceMessage])


    //ограничитель количества выполнений функции
    //принимает как аргументы саму функцию и задержку перед следующим выполнением в миллисекундах
    // function throttle(func, delay) {
    //     let timerId;
    //     let lastExecTime = 0;
    //
    //     return function (...args) {
    //         const currentTime = Date.now();
    //
    //         if (currentTime - lastExecTime > delay) {
    //             func.apply(this, args);
    //             lastExecTime = currentTime;
    //         } else {
    //             clearTimeout(timerId);
    //             timerId = setTimeout(() => {
    //                 func.apply(this, args);
    //                 lastExecTime = currentTime;
    //             }, delay);
    //         }
    //     };
    // }

    //этот эффект вешает на зону с сообщениями обработчик события скролла
    //чтобы у функции обработчика всегда были актуальные данные, ее нужно перевешивать каждый раз
    //как меняется одна из ключевых зависимостей
    useEffect(() => {
        console.log("EFFECT 2")
        const element = messagesAreaRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [messagesAreaRef, slicerIn, slicerOut])


    //функция создания (ну и пересоздания) обсервара (наблюдателя) сообщений
    //для того чтобы отслеживать появление сообщения в зоне видимости и на основании этого отмечать его как прочитанное
    async function makeActualObserver(list) {


        //срабатывает только если массив поданный в функцию не является пустым
        if(list?.length) {
            console.log("MAKING OBSERVER")
            //получаем актуальную область наблюдения
            const messageArea = messagesAreaRef.current

            // Создаем новый IntersectionObserver и передаем ему колбэк-функцию
            // для обработки изменений видимости элементов
            messageObserver.current = new IntersectionObserver((entries) => {
                // Проходимся по каждой записи в массиве entries
                entries.forEach(async (entry) => {
                    // Если элемент .chat-left или .chat-center находится в видимой части экрана
                    console.log("INTERSECTION WORK")
                    //console.log(entry.target.id)
                    // Если элемент наблюдаемый находится в видимой части экрана
                    // и не находится в массиве уже отработанных
                    if (entry.isIntersecting && !markedElements.current.has(entry.target.id)) {// && !markedElements.has(entry.target.id)) {
                        console.log("Вижу элемент")
                        // добавляем айди элемента в массив уже отработанных
                        markedElements.current.add(entry.target.id);

                        // Получаем элемент по айдишнику из DOM
                        const myElement = document.getElementById(entry.target.id)
                        //console.log(myElement)
                        if (myElement) {
                            //console.log("Выкидываем из наблюдения")
                            await messageObserver.current.unobserve(myElement)
                        }

                        console.log("В зоне видимости элемент - ", entry.target)
                        console.log("Его ID - ", entry.target.id)
                        //используем айдишник элемента для того чтобы отметить сообщение с соответствующим
                        //айди как прочитанное с помощью соответствующей функции
                        await markAsRead(entry.target.id)


                    } //else {
                        //console.log("НЕ вижу элемент")
                    //}
                });
            }, {
                // Передаем ссылку на родительский элемент .message-area
                root: messageArea,
                //это значение площади элемента на экране для того чтобы он считался видимым
                //в данном случае 10%
                threshold: 0.1
            });

                // Получаем все элементы .chat-left и .chat-center
            const chatMessages = document.querySelectorAll('.chat-left, .chat-center');
            //setAddFirstMessages(false)

            //фильтруем массив элементов так, чтобы не добавлять элементы которые уже были отработаны
            const newElements = Array.from(chatMessages).filter(element => {
                const isExistingElement = markedElements.current.has(element.id);
                return !isExistingElement;
            });

            // Проходимся по каждому элементу .chat-left и .chat-center



            newElements.forEach((chatMessage) => {
                //и если аттрибут 'data-read' равен false (этот аттрибут самопальный и его значение создается на основе
                // того, есть ли айди пользователя в массиве прочитавших в объекте сообщения)
                if (chatMessage.getAttribute('data-read') === "false") {
                    // Добавляем элемент в IntersectionObserver
                    messageObserver.current.observe(chatMessage);
                    //console.log(chatMessage)
                    //console.log("добавляем", chatMessage)
                    console.log("Добавляем наблюдалку")
                    console.log(chatMessage)
                }
            });



        }
    }


    //этот эффект пересоздаем обсервер сообщений чтобы актуализировать в нем инфу после перерисовки сообщений
    //то есть когда приходит новое сообщение по ВХОДЯЩИМ заказам,
    //так как перерисованные сообщения уже не являются теми же самыми элементами DOM
    //при перерисовке сообщений по ВХОДЯЩИМ заказам, или изменении режима работы ЗАКАЗЧИК/ИСПОЛНИТЕЛЬ
    useEffect(() => {
        //запоминаем какой режим был прошлым
        lastModeRef.current = modeRef.current
        //запоминаем какой режим есть сейчас
        modeRef.current = mode
        subModeRef.current = subMode

        //если в DOM существует элемент .new-message-line, это линия НОВЫЕ СООБЩЕНИЯ
        //а она существует только тогда, когда они есть, и в данный момент
        if(messagesAreaRef.current.querySelector('.new-message-line') && (mode === "performer" || (mode === "all" && subMode === "performer"))) {
            console.log('5 scroll')
            //то прокручиваем область сообщений до этого элемента с небольшой поправкой в 250рх
            messagesAreaRef.current.scrollTo({
                top: messagesAreaRef.current.querySelector('.new-message-line').offsetTop - 250, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                //behavior: 'smooth', // Прокручиваем с анимацией
            })
        //если такого элемента нет...
        } else {
            //...а так же соблюдается ряд условий
            if (
                //окно сообщений откручено от начального положения не более чем на 300рх и текущий режим работы ИСПОЛНИТЕЛЬ
                messagesAreaRef.current.scrollTop > -300 && (mode === "performer" || (mode === "all" && subMode === "performer")) && messagesToRollIn.length > 10
                //или у нас явно указано условие крутануть окошко
                || scrollerRefIn.current
                //или прошлый режим работы не соответствует текущему
                || lastModeRef.current !== mode
            ) {

                console.log("PIN 34")
                //снимаем флаг о необходимости дальнейшей прокрутки
                scrollerRefIn.current = false
                //и крутим окошко просмотра сообщений в самый низ
                messagesAreaRef.current.scrollTo({
                    top: 0,
                    //behavior: 'smooth', // Прокручиваем с анимацией
                })
            }
        }

        //и уже после того как прокрутили окошко в правильное место
        //тогда и только тогда актуализируем информацию обсервера сообщений!!!
        if(!stopMakingObserver.current) {
            makeActualObserver(messagesToRollIn)
        }

    }, [messagesToRollIn, mode, subMode])


    //этот эффект пересоздаем обсервер сообщений чтобы актуализировать в нем инфу после перерисовки сообщений
    //то есть по приходе нового сообщения по ИСХОДЯЩИМ заказам
    //так как перерисованные сообщения уже не являются теми же самыми элементами DOM
    //при перерисовке сообщений по ИСХОДЯЩИМ заказам, и изменении режима работы ЗАКАЗЧИК/ИСПОЛНИТЕЛЬ
    useEffect(() => {

        //запоминаем какой режим был прошлым
        lastModeRef.current = modeRef.current
        //запоминаем какой режим есть сейчас
        modeRef.current = mode
        subModeRef.current = subMode

        //если в DOM существует элемент .new-message-line, это линия НОВЫЕ СООБЩЕНИЯ
        //а она существует только тогда, когда они есть, и в данный момент
        if(messagesAreaRef.current.querySelector('.new-message-line') && (mode === "creator" || (mode === "all" && subMode === "creatoer"))) {
            console.log("PIN 2")
            //то прокручиваем область сообщений до этого элемента с небольшой поправкой в 250рх
            messagesAreaRef.current.scrollTo({
                top: messagesAreaRef.current.querySelector('.new-message-line').offsetTop - 250, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                //behavior: 'smooth', // Прокручиваем с анимацией
            })

        //если же такого элемента нет, то...
        } else {
            //при соблюдении ряда условия крутим окошко в самый низ к самому последнему сообщению
            if (
                //окно сообщений откручено от начального положения не более чем на 300рх и текущий режим работы ЗАКАЗЧИК
                messagesAreaRef.current.scrollTop > -300 && (mode === "creator" || (mode === "all" && subMode === "creatoer")) && messagesToRollOut.length > 10
                //или у нас явно указано условие крутануть окошко
                || scrollerRefOut.current
                //или прошлый режим работы не соответствует текущему
                || lastModeRef.current !== mode

            ) {
                console.log("PIN 3")
                //сбрасываем флаг о необходимости прокрутки
                scrollerRefOut.current = false
                //и крутим окно к самому последнему сообщению, то есть в самый низ
                messagesAreaRef.current.scrollTo({
                    top: 0, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                    //behavior: 'smooth', // Прокручиваем с анимацией
                })
            }
        }

        //и уже после того как прокрутили окошко в правильное место
        //тогда и только тогда актуализируем информацию обсервера сообщений!!!
        if(!stopMakingObserver.current) {
            makeActualObserver(messagesToRollOut)
        }


    }, [messagesToRollOut, mode, subMode])


    const lastModeState = useRef("all")

    //этот эффект выставляет флажок о необходимости нарисовать линию НОВЫЕ СООБЩЕНИЯ в false
    //при смене режима работы АВТОР/ЗАКАЗЧИК
    useEffect(() => {
        //похоже тут не нужны никакие условия
        //потому что ветка IF ни разу не сработала на тестировании
        if(lastModeRef.current !== mode) {

          //  needNewMessageLineOut.current = true
          //  needNewMessageLineIn.current = true
        } else {

            needNewMessageLineOut.current = false
            needNewMessageLineIn.current = false
        }



        if(mode === "all" && lastModeState.current === "creator") {
            setActiveOrderIn()
        }

        if(mode === "all" && lastModeState.current === "performer") {
            setActiveOrderOut()
        }

        lastModeState.current = mode


    }, [mode])


    const [loadingDialogues, setLoadingDialogues] = useState(false)

    //функция для получения минимального набора данных из базы при первом монтировании данного компонента
    async function getMinimumCenterMessages() {
        setLoadingDialogues(true)
        const response = await $api.get(`/get_minimum_center_messages?id=${store.user.id}`)
        await console.log(response.data)
        //временные хранилища всех сообщений разделенных на ВХОДЯЩИЙ/ИСХОДЯЩИЙ
        //а внутри имеющщих вид {айди заказа: [массив сообщений]}
        let outgoingOrdersMessagesTmp = {}
        let incomingOrdersMessagesTmp = {}

        let unreadOrderIdsIn = {}
        let unreadOrderIdsOut = {}


        let usersOfOutOrderTmp = {}

        const listOfUniqueUsers = new Set()
        //циклы для сортировки сообщений по айдишнику заказа,
        //с сервера они прилетают общей кучей, деленные только на ВХОДЯЩИЙ/ИСХОДЯЩИЙ
        for(let order of response.data.outgoingOrders) {

            //реверс нужен потому что на стороне сервера сортировки по дате не происходит, так как
            //сообщения это не самостоятельные документы, а всего лишь составная часть документа заказа
            outgoingOrdersMessagesTmp[order._id] = [...order.msgs.reverse()]



            const listOfUsers = order.msgs?.reduce((acc, current) => {
                //и если в аккумуляторе нет айдишника, который бы соответствовал отправителю сообщения
                //при этом пропуская сообщения от текущего пользователя (для которого выполняется операция)
                //то закидываем этот айди в массив аккумулятора
                console.log(current)
                if (current.userId?._id
                    && acc.indexOf(current.userId?._id) === -1
                    && current.userId?._id !== store.user.id
                    && !order.hiddenUsers?.includes(current.userId?._id)
                ) {

                        acc.push(current.userId?._id)
                    listOfUniqueUsers.add(current.userId?._id)
                }
                return acc
            }, [])

            //закидываем получившийся список в хранилище юзерныз списков с ключами-айди заказов
            if(listOfUsers.length) {
                console.log(order.number)
                console.log(listOfUsers)
                usersOfOutOrderTmp[order._id] = listOfUsers
            }
        }

        await setUsersOfOutOrder(usersOfOutOrderTmp)
        console.log("GET SHORT 2")
        await getShortUsersInfo([...listOfUniqueUsers]).then(() => console.log("users added"))

        //цикл для сообщений входящих заказов
        for(let order of response.data.incomingOrders) {
            incomingOrdersMessagesTmp[order._id] = [...order.msgs.reverse()]
        }

        for(const order in incomingOrdersMessagesTmp) {
            const orderArray = incomingOrdersMessagesTmp[order].reduce((acc, message) => {
                if(!message.read.includes(store.user.id)
                    && !acc.includes(message.userId._id)
                    && message.userId._id !== store.user.id) {
                    // console.log(message)
                    // console.log("Pushing - ", message.userId._id)
                    if(!acc.includes(message.about)) {
                        acc.push(message.about)
                        console.log(message)
                    }
                    //acc.push(message.userId._id)
                }
                return acc
            }, [])

            if (orderArray.length) {
                //unreadOrderIdsIn[order] = orderArray
                console.log("SET UNREAD IN 1")
                await setUnreadOrdersIn(orderArray)
            }
        }

        for(const order in outgoingOrdersMessagesTmp) {

            const orderArray = outgoingOrdersMessagesTmp[order].reduce((acc, message) => {
                if(!message.read.includes(store.user.id) && !acc.includes(message.userId._id) && message.userId._id !== store.user.id) {
                    console.log(message)
                    acc.push(message.userId._id)
                }
                return acc
            }, [])

            if (orderArray.length) {
                unreadOrderIdsOut[order] = orderArray
            }

        }

        await setUnreadOrdersOut(unreadOrderIdsOut)

        console.log(unreadOrderIdsOut)

        //формирование промежуточных стейтов с сообщениями, после изменения которого сработают соответствующие
        //useEffect и сделают развертывание
        console.log("set OOM 2")
        await setOutgoingOrdersMessages(outgoingOrdersMessagesTmp)
        console.log("set IOM 2")
        await setIncomingOrdersMessages(incomingOrdersMessagesTmp)

        //временные хранилища информации о каждом заказе
        let outgoingOrdersInfoTmp = {}
        let incomingOrdersInfoTmp = {}

        //вырезаем сообщения из тех заказов что прилетели с сервера, и складываем их во временные хранилища
        for(let order of response.data.outgoingOrders) {
            //order.msgs = null
            if(usersOfOutOrderTmp[order._id]) {
                outgoingOrdersInfoTmp[order._id] = order
            }

        }

        for(let order of response.data.incomingOrders) {
           // order.msgs = null
            incomingOrdersInfoTmp[order._id] = order
        }

        //далее помещаем временные хранилища в постоянные стейты инфы о заказах
        //вид у них уже описан выже, но он все же такой {айдишник заказа: {объект с инфой}}
        await setOutgoingOrdersInfo(outgoingOrdersInfoTmp)
        await setIncomingOrdersInfo(incomingOrdersInfoTmp)
        setLoadingDialogues(false)

    }


    //это установка метки с названием компонента для отображения
    useEffect(() => {
        store.editWhitePointer({place: "Центр сообщений:"});
        assignWidth("message-center-order-list-wrapper", "new-center-order-list-container");

        const handleScroll = () => {
            const fixedElement1 = document.getElementById('new-center-order-list-container');
            const fixedElement2 = document.getElementById('mc-mode-selector');
            const scrollLeft = document.documentElement.scrollLeft;

            if (fixedElement1) fixedElement1.style.left = -scrollLeft + 'px';
            if (fixedElement2) fixedElement2.style.left = -scrollLeft + 'px';
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    //это запуск функции при инициализации компонента для получения стартовой инфы из базы данных
    useEffect(() => {
        getMinimumCenterMessages()
    }, [])


    const [isFirstRender, setIsFirstRender] = useState(true)

    //это эффект срабатывающий по приходе нового сообщения по вебсокету
    useEffect(() => {
        stopMakingObserver.current = false
        console.log(newOrderMessage)
        //работает только если у сообщения эвент "message"
        if(isFirstRender) {
            setIsFirstRender(false)
        } else {

            if (newOrderMessage.event === 'message') {
                //не помню зачем здесь трай кэтч, но пусть будет и дальше
                try {
                    //узнаем существует ли заказ о котором пришло сообщение в наших стартовых стейтах
                    const isInState =
                        incomingOrdersInfo.hasOwnProperty(newOrderMessage.about)
                        || outgoingOrdersInfo.hasOwnProperty(newOrderMessage.about)

                    //это маркер о том, по какому заказу (ВХОДЯЩИЙ/ИСХОДЯЩИЙ) сообщение
                    //если по входящему то true если по исходящему то false
                    let messageAboutIn = false

                    //если айдишник заказа в стейте
                    if(isInState) {
                        //то проверяем в каком именно чтобы задать правильное значение того,
                        //по какому типа заказа это сообщение, если по входящему то true если по исходящему то false,
                        //чтобы далее принять решение в какой стейт его класть
                        messageAboutIn = incomingOrdersInfo.hasOwnProperty(newOrderMessage.about)
                        if(!messageAboutIn) {

                            if((mode === "performer" || (mode === "all" && subMode === "performer")) && activeOrderOut === newOrderMessage.about && activeUserOut === newOrderMessage.userId._id) {
                                setActiveOrderOut()
                            }

                        } else {
                            if((mode === "creator" || (mode === "all" && subMode === "creator")) && activeOrderIn === newOrderMessage.about) {
                                setActiveOrderIn()
                            }
                        }
                    }

                    //запускаем функцию обработчик нового сообщения, передаем в нее стартовые параметры,
                    //которые только что выяснили
                    processNewMessage(isInState, messageAboutIn)

                    console.log(messageAboutIn)
                    if(messageAboutIn) {
                        console.log("Setting new text, is ", newOrderMessage.text)

                    } else {

                    }




                } catch (e) {
                    console.log(e)
                }
            }

        }

    }, [newOrderMessage])


    async function unhideUser(order_id, user_id, toState) {

        console.log("UNHIDING USER")

        await $api.post(
            '/unhide_user',
            {order_id: order_id, user_id: user_id}
        )
        //...то надо его добавить

        if(toState) {
            console.log("SET USERS 1")
            setUsersOfOutOrder(prevState => {
                return {
                    ...prevState,
                    [order_id]: [...prevState[order_id], user_id]
                }
            })
            console.log("GET SHORT 3")
            //инфу по нему притащить если не притащена (притащена или нет проверится внутри функции)
            await getShortUsersInfo([newOrderMessage.userId._id])
        }
    }

    const [rerenderOrderListIn, setRerenderOrderListIn] = useState(0)
    const [rerenderOrderListOut, setRerenderOrderListOut] = useState(0)


    //функция обработчик нового сообщения
    async function processNewMessage(isInState, aboutIncoming) {




        console.log("PROCESS START")

        console.log(incomingOrdersMessages)

        //по умолчанию флажок о том, есть ли юзер в списке диалогов заказа ставим на true,
        //потом по ходу дела если надо поменяем
        let isUserInList = true

        //если инфы о заказе нет в стейтах
        if(!isInState) {
            console.log("PROCESS NOT IN STATE")
            //то нужно притащить инфу по данному заказу из базы
            const response = await $api.get(`/order-details/${newOrderMessage.about}/${store.user.id}`)


            //если создателем данного заказа является текущий юзер
            if(response.data.creator._id === store.user.id) {

                //помещаем сообщения данного заказа во временное хранилище, чтобыне перерисовывать
                //отображаемые сообщения раньше времени
                temporaryMessageStorage.current[newOrderMessage.about] = [...response.data.msgs]

                await $api.post('/unhide_dialog', {order_id: newOrderMessage.about, user_id: store.user.id})

                const listOfUniqueUsers = new Set()

                const listOfUsers = response.data.msgs?.reduce((acc, current) => {
                    //и если в аккумуляторе нет айдишника, который бы соответствовал отправителю сообщения
                    //при этом пропуская сообщения от текущего пользователя (для которого выполняется операция)
                    //то закидываем этот айди в массив аккумулятора
                    //console.log(current)
                    if (acc.indexOf(current.userId._id) === -1
                        && current.userId._id !== store.user.id
                        && (!response.data.hiddenUsers?.includes(current.userId._id) || current.userId._id === newOrderMessage.userId._id)
                    ) {

                        if(response.data.hiddenUsers?.includes(current.userId._id)) {
                            unhideUser(newOrderMessage.about, current.userId._id, false)
                        }

                        console.log("adding")
                        acc.push(current.userId._id)
                        listOfUniqueUsers.add(current.userId._id)
                    }
                    return acc
                }, [])

                //закидываем получившийся список в хранилище юзерныз списков с ключами-айди заказов
                console.log("SET USERS 3")
                await setUsersOfOutOrder(prevState => {
                    return {
                        ...prevState,
                        [newOrderMessage.about]: listOfUsers
                    }
                })
                console.log("GET SHORT 4")
                await getShortUsersInfo([...listOfUniqueUsers]).then(() => console.log("users added"))

                //инфу по заказу тоже закидываем в стейт со всей инфой
                await setOutgoingOrdersInfo(prevState => {
                    return {
                        ...prevState,
                        [newOrderMessage.about]: response.data
                    }
                })


                //добавляем юзера в массив непрочитанных
                await setUnreadOrdersOut(prevState => {
                    return {
                        ...prevState,
                        [newOrderMessage.about]: [newOrderMessage.userId._id]
                    }
                })
                setLastMessagesTextOut(prevState => {
                    const newState = prevState

                    newState[newOrderMessage.about] = newOrderMessage.text
                    console.log(newState)
                    return newState
                });

            //если текущий юзер НЕ является создателем заказа (я не уверен что эта ветка вообще требуется, похоже,
            //что она сработает только если открыт мессэдж центр, а чувак при этом на другой вкладке пишет первое
            //сообщение позаказу который хочет взять)
            } else {
                console.log("ТАКИ ДА, МЫ ЗДЕСЬ!")

                await $api.post('/unhide_dialog', {order_id: newOrderMessage.about, user_id: store.user.id})

                //то добавляем его сообщения вовременное храниилище
                temporaryMessageStorage.current[newOrderMessage.about] = [...response.data.msgs]

                //инфу по заказу добавляем
                await setIncomingOrdersInfo(prevState => {
                    return {
                        ...prevState,
                        [newOrderMessage.about]: response.data
                    }
                })

                ///вот эта часть кода...
                const orderArray = response.data.msgs.reduce((acc, message) => {
                    if(!message.read[0] !== store.user.id && !acc.includes(message.userId._id) && message.userId._id !== store.user.id) {
                        acc.push(message.userId._id)
                    }
                    return acc
                }, [])

                //...как и эта, возможно никогда не понадобится...
                console.log("SET UNREAD IN 2")

                await setUnreadOrdersIn(prev => [...prev, newOrderMessage.about])
                setLastMessagesTextIn(prevState => {
                    const newState = prevState

                    newState[newOrderMessage.about] = newOrderMessage.text
                    console.log(newState)
                    return newState
                });
            }

            //если инфа о заказе всё же в стейте есть...
        } else {
            console.log("PROCESS IN STATE ELSE")
            //...то проверяем есть ли юзер в списке юзеров этого заказа
            if(Object.keys(outgoingOrdersInfo).includes(newOrderMessage.about)) {
                isUserInList = usersOfOutOrder[newOrderMessage.about]?.includes(newOrderMessage.userId._id)
            }

        }

        //если сообщение по ВХОДЯЩЕМУ заказу и заказ есть в стейте
        if(aboutIncoming && isInState) {

            //если отправитель это не сам пользователь и и заказа нет в массиве непрочитанных
            if(newOrderMessage.userId._id !== store.user.id && !unreadOrdersIn.includes(newOrderMessage.about)) {
                console.log("Это нам не надо 1")
                //то добавляем его в массив непрочитанных
                console.log("SET UNREAD IN 3")

                await setUnreadOrdersIn(prev => [...prev, newOrderMessage.about])
            }

            console.log(modeRef.current)
            console.log(subModeRef.current)
            //если...
            if(
                //текущий режим работы ИСПОЛНИТЕЛЬ или никакого режима не выбрано и данное сообщение не об активном ВХОДЯЩЕМ заказе
                ((modeRef.current === "performer" || (modeRef.current === "all" && subModeRef.current !== "creator")) && (newOrderMessage.about !== activeOrderInRef.current))

            ) {
                console.log("Работает временное хранилище")
                //...то скидываем сообщение во временное хранилище
                //если массив данного заказа там уже есть..
                if(temporaryMessageStorage.current[newOrderMessage.about]) {
                    //то пушим в него сообщение
                    temporaryMessageStorage.current[newOrderMessage.about].push(newOrderMessage)
                    //если массива нет
                } else {
                    //то создаем его и сразу кладем в него сообщение
                    temporaryMessageStorage.current[newOrderMessage.about] = [newOrderMessage]
                }

                setRerenderOrderListIn(prev => prev + 1 )

                //если же сообщение должно быть отображено в активном окне, то...
            } else {

                //кладем его в стейт
                console.log("set IOM 3")
                await setIncomingOrdersMessages(prevState => {
                    // Проверяем, существует ли уже массив сообщений для указанного заказа
                    if (prevState.hasOwnProperty(newOrderMessage.about)) {

                        console.log(prevState[newOrderMessage.about])

                        // Если существует, добавляем новое сообщение в конец массива
                        return {
                            ...prevState,
                            [newOrderMessage.about]: [newOrderMessage, ...prevState[newOrderMessage.about]]
                        };
                        //если по какой то причине мссива нет, то создаем его
                    } else {
                        // Если массив сообщений для заказа еще не существует, создаем его с новым сообщением
                        return {
                            ...prevState,
                            [newOrderMessage.about]: [newOrderMessage]
                        };
                    }
                })
            }


            setLastMessagesTextIn(prevState => {
                const newState = prevState

                newState[newOrderMessage.about] = newOrderMessage.text
                console.log(newState)
                return newState
            });


        }

        //если сообщение по ИСХОДЯЩЕМУ заказу, и он в стейте
        if(!aboutIncoming && isInState) {

            console.log("PROCESS IN STATE OUTGOING")
            //если сообщение не от самого себя
            //и в объекте непрочитанных заказов нет ключа соответствующего тому по которому данное сообщение пришло
            if(newOrderMessage.userId._id !== store.user.id && !Object.keys(unreadOrdersOut).includes(newOrderMessage.about)) {

                //то добавляем в объект непрочитанных этот заказ, создавая по такому ключу массив
                //в который помещаем отправителя данного сообщения
                await setUnreadOrdersOut(prevState => {
                    return {
                        ...prevState,
                        [newOrderMessage.about]: [newOrderMessage.userId._id]
                    }
                })
            //в случае если ключ соответствующий заказу есть
            } else {
                //но при этом отправитель не сам юзер
                //(это странное условие, но именно так оно и работает, не надо его трогать)
                //и в массиве непрочитанных юзеров нет записи об отправителе текущего сообщения
                if(newOrderMessage.userId._id !== store.user.id && !unreadOrdersOut[newOrderMessage.about]?.includes(newOrderMessage.userId._id)) {
                    console.log("RADIO")
                    //то добавляем его в этот массив обновляя стейт непрочитанных ИСХОДЯЩИХ заказов
                    await setUnreadOrdersOut(prev => {
                        return {
                            ...prev,
                            [newOrderMessage.about]: [...prev[newOrderMessage.about], newOrderMessage.userId._id]
                        }
                    })
                }
            }

            //и вот тут проверяем флажок есть ли юзер в списке юзеров текущего заказа
            //и если его там нет...
            if (!isUserInList && newOrderMessage.userId._id !== store.user.id) {
                unhideUser(newOrderMessage.about, newOrderMessage.userId._id, true)
            }



            //вот над этим условием я долго бился, оно абсолютно нечитаемо, но я сейчас попробую его откомментить
            if(
                //если...
                //Активен режим заказчика
                // И
                // (
                //   (сообщение об активном ИСХОДЯЩЕМ ЗАКАЗЕ
                //    И
                //      (
                //       (отправитель не является ни активным юзером,
                //       ни это сообщение от самого себя,
                //       не содержит имя пользователя "Модератора",
                //       )
                //       ИЛИ
                //      (сообщение от модератора не являющегося модератором именно на этом заказа)
                //      )
                //    ИЛИ
                //      сообщение не об активном ИСХОДЯЩЕМ заказе
                // )
                // ИЛИ
                //  еще не выбран режим работы
                (
                    (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current !== "performer")) && ((newOrderMessage.about === activeOrderOutRef.current && ((newOrderMessage.userId._id !== activeUserOut && newOrderMessage.userId._id !== store.user.id && newOrderMessage.userName !== "Модератор") || (newOrderMessage.userName === "Модератор" && outgoingOrdersInfo[activeOrderOutRef.current].moderator !== newOrderMessage.userId._id))) || (newOrderMessage.about !== activeOrderOutRef.current))
                )

            ) {
                console.log("PROCESS TEMPORARY STOREAGE")

                //...то скидываем сообщение во временное хранилище
                if(temporaryMessageStorage.current[newOrderMessage.about]) {
                    temporaryMessageStorage.current[newOrderMessage.about].push(newOrderMessage)
                } else {
                    temporaryMessageStorage.current[newOrderMessage.about] = [newOrderMessage]
                }
                console.log(temporaryMessageStorage.current)
                //если же сообщение должно быть отображено в активном окне, то...
            } else {
                console.log("PROCESS SET OUT MESSAGES")
                //кидаем его в стейт с сообщениями
                console.log("set OOM 3")
                await setOutgoingOrdersMessages(prevState => {
                    // Проверяем, существует ли уже массив сообщений для указанного заказа
                    if (prevState.hasOwnProperty(newOrderMessage.about)) {
                        console.log("РАБОТАЕТ ИФ 2")

                        // Если существует, добавляем новое сообщение в конец массива
                        return {
                            ...prevState,
                            [newOrderMessage.about]: [newOrderMessage, ...prevState[newOrderMessage.about]]
                        };
                        //если массив сообщений для заказа еще не существует,
                    } else {
                        console.log("РАБОТАЕТ ЭЛЗ 2")
                        // создаем его с новым сообщением внутри
                        return {
                            ...prevState,
                            [newOrderMessage.about]: [newOrderMessage]
                        };
                    }
                })
            }

            setLastMessagesTextOut(prevState => {
                const newState = prevState

                newState[newOrderMessage.about] = newOrderMessage.text
                console.log(newState)
                return newState
            })
            setRerenderOrderListOut(prev => prev + 1 )
        }




    }


    const [lastMessagesTextIn, setLastMessagesTextIn] = useState({})
    const [lastMessagesTextOut, setLastMessagesTextOut] = useState({})

    //этот эффект находит последние сообщение по входящим заказам чтобы потом отобразить их текст в кнопке диалога
    useEffect(() => {
        console.log(incomingOrdersMessages)
        if(incomingOrdersMessages) {
            let stateOfLastMessagesIn = {}

            for(let order in incomingOrdersMessages) {
                console.log(order)
                stateOfLastMessagesIn[order] = incomingOrdersMessages[order][0]?.text
            }

            console.log(stateOfLastMessagesIn)
            setLastMessagesTextIn(stateOfLastMessagesIn)
        }




    }, [incomingOrdersMessages, mode])

    useEffect(() => {
        console.log(outgoingOrdersMessages)
        if(outgoingOrdersMessages) {
            let stateOfLastMessagesOut = {}

            for(let order in outgoingOrdersMessages) {
                console.log(order)
                stateOfLastMessagesOut[order] = outgoingOrdersMessages[order][0]?.text
            }

            console.log(stateOfLastMessagesOut)
            setLastMessagesTextOut(stateOfLastMessagesOut)
        }




    }, [outgoingOrdersMessages, mode])

    //это стейт массив в котором лежат отсортированные айди заказов по дате последних сообщений,
    //нужен чтобы потом отсортировать сами заказы для отображения в листе
    const [sortedOrdersIds, setSortedOrdersIds] = useState([])

    //этот эффект срабатывает на изменение активного ВХОДЯЩЕГО заказа
    //и на изменение общего хранилища инфы по ВХОДЯЩИМ заказам
    //а так же на изменение стейта непрочитаных ВХОДЯЩИХ заказов
    useEffect(() => {


        if(mode === "performer") {
            const incomingOrdersToSort = Object.values(incomingOrdersInfo)
            const incomingOrdersToMap =  incomingOrdersToSort.sort((a, b) => sortedOrdersIds.indexOf(a._id) - sortedOrdersIds.indexOf(b._id));


            //маппим массив со значениями стейта инфы по заказам
            console.log("Маппим опять список заказов")
            console.log(lastMessagesTextIn)
            const incomingOrdersNumbers = Object.values(incomingOrdersToMap).map(order => {
                console.log(order._id)
                console.log(lastMessagesTextIn[order._id])
                //если текущий юзер не является модератором данного заказа (не совсем понимаю зачем я сделал такое условие)
                if(store.user.id !== order.moderator) {
                    return (
                        //...то возвращаем вот такой элемент с кнопками
                        <div className={"lots-of-orders-out-container"}>
                            <div
                                key={order._id}
                                className={`lotsOfButtons ${order._id === activeOrderIn ? "blue-theme-back" : ""}`}
                                onClick={() => {
                                    console.log('Set Active IN 2')
                                    setActiveOrderIn(order._id)

                                }}
                            >
                                {/*<div className={"btn-order-n-btn-close-container"}>*/}
                                <div className={"dot-container-message-center"}>
                                    {unreadOrdersIn.indexOf(order._id) !== -1 && activeOrderIn !== order._id
                                        ?
                                        <img src={SvgRedDot} width={"15px"}/>
                                        :
                                        <img src={SvgWhiteDot} width={"15px"}/>
                                    }
                                </div>


                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${order.creator._id}/avatar.png`}/>
                                <div className={"width-100p"}>
                                    <p className={"p-order-choice-message-center bold"}>#{order.number}<span className={"margin-left-10"}>{order.title}</span></p>
                                    <p className={"p-order-choice-message-center"}>{lastMessagesTextIn[order._id] ?? "..."}</p>
                                </div>
                                {/*<div className={"cross-container-message-center"}>*/}
                                {/*    <img*/}
                                {/*        className={"margin-0 svg-closing-cross-message-center"}*/}
                                {/*        src={SvgRedCross}*/}
                                {/*        width={"15px"}*/}
                                {/*        onClick={(e) => {*/}
                                {/*            e.stopPropagation()*/}
                                {/*            closeIncomingDialog(order._id)*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {/*<button*/}
                                {/*    className={`btn order-switch-button ${order._id === activeOrderIn ? 'btn-primary' : 'btn-secondary'}`}*/}
                                {/*    onClick={() => {*/}
                                {/*        console.log('Set Active IN 2')*/}
                                {/*        setActiveOrderIn(order._id)*/}
                                {/*       }*/}
                                {/*    }*/}
                                {/*>#{order.number} {order.moderator ? "⚖" : null}*/}


                                {/*    }</div>*/}
                                {/*</button>*/}
                                {/*<button*/}
                                {/*    onClick={() => closeIncomingDialog(order._id)}*/}
                                {/*    className={`btn close-dialog-btn`}*/}
                                {/*>❌</button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )
                }
            })

            //после того как раскатали список кнопок заказов закидываем его в стейт для развертывания на экране
            setIncomingOrdersToRoll(incomingOrdersNumbers)
        }


    }, [mode, sortedOrdersIds, temporaryMessageStorage.current, incomingOrdersInfo, activeOrderIn, unreadOrdersIn, lastMessagesTextIn, rerenderOrderListIn])



    //этот эффект срабатывает на изменение активного ИСХОДЯЩЕГО заказа
    //и на изменение списка юзеров в соответствующем стейте (для каждого заказа свой список)
    useEffect(() => {

        //если у заказа существует список юзеров
        if (usersOfOutOrder[activeOrderOut]) {
            //то первого кто в нем есть назначаем активным юзером
            setActiveUserOut(usersOfOutOrder[activeOrderOut][0])
        }

        //если существует список юзеров активного заказа и у заказа НЕ назначен исполнитель
        if(usersOfOutOrder[activeOrderOut] && !outgoingOrdersInfo[activeOrderOut].doer?._id ) {
            console.log("setting activeUserOut to -", usersOfOutOrder[activeOrderOut][0])
            //...то делаем активным первого юзера в списке
            setActiveUserOut(usersOfOutOrder[activeOrderOut][0])
            //если же есть список юзеров у активного заказа и у заказа назначен исполнитель
        } else if (usersOfOutOrder[activeOrderOut] && outgoingOrdersInfo[activeOrderOut].doer._id) {
            //то назначаем исполнителя активным юзером
            //(потому что остальные юзеры более не отображаются в данном диалоге)
            setActiveUserOut(outgoingOrdersInfo[activeOrderOut].doer._id)
        }


    }, [activeOrderOut, usersOfOutOrder])



    async function closeIncomingDialog(order_id) {
        const response = await $api.post('/close_dialog', {order_id, user_id: store.user.id})

        if(response.status < 400) {

            await setIncomingOrdersInfo(prev => {
                //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                const { [order_id]: deletedKey, ...updatedState } = prev;
                //и возвразаем то что получилось обновляя стейт
                return updatedState;
            })
            console.log("set IOM 4")
            await setIncomingOrdersMessages(prev => {
                //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                const { [order_id]: deletedKey, ...updatedState } = prev;
                //и возвразаем то что получилось обновляя стейт
                return updatedState;
            })

            if(activeOrderIn === order_id) {
                await setActiveOrderIn()
            }

        }


    }


    async function closeOutgoingDialog(order_id) {

        const response = await $api.post('/close_dialog', {order_id, user_id: store.user.id})


        if(response.status < 400) {

            await setOutgoingOrdersInfo(prev => {
                //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                const { [order_id]: deletedKey, ...updatedState } = prev;
                //и возвразаем то что получилось обновляя стейт
                return updatedState;
            })
            console.log("set OOM 4")
            await setOutgoingOrdersMessages(prev => {
                //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                const { [order_id]: deletedKey, ...updatedState } = prev;
                //и возвразаем то что получилось обновляя стейт
                return updatedState;
            })

            console.log("SET USERS 4")
            await setUsersOfOutOrder(prev => {
                //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                const { [order_id]: deletedKey, ...updatedState } = prev;
                //и возвразаем то что получилось обновляя стейт
                return updatedState;
            })

            if(activeOrderOut === order_id) {
                await setActiveUserOut()
                await setActiveOrderOut()
            }
        }
    }



    //функция закрывает диалог с конкретным юзеров открытом заказе
    async function hideOutgoingUser(order_id, user_id) {

        try {
            //заносим инфу в базу
            const response = await $api.post('/hide_user', {order_id, user_id})
            //const response = {status: 200}
            if(response.status < 400) {
                //заносим инфу в локальный стор
                console.log("SET USERS 5")
                await setUsersOfOutOrder(prev => {
                    const newUserListOfOrder = usersOfOutOrder[order_id].filter(id => id !== user_id)

                    if(!newUserListOfOrder.length) {
                        closeOutgoingDialog(order_id)
                    }

                    return {
                        ...prev,
                        [order_id]: newUserListOfOrder
                    }

                })

            }
        } catch (e) {
            console.log(e)
        }
    }



    function mergeFirstElements(outgoingOrdersMessages, incomingOrdersMessages) {
        // Получаем массивы из значений объектов
        console.log(temporaryMessageStorage.current)

        //ниже глубокое копирование объектов, у меня ушли сутки чтобы найти это место в котором
        //происходило паразитное обновление стейтов incomingOrdersMessages и outgoingOrdersMessages
        //а происходит оно потому что spread оператор делает поверхностное копирование, и в итоге мы
        //имеем дело со ссылками на стейты, а не с их копиями
        const incomingMessages = JSON.parse(JSON.stringify(incomingOrdersMessages))
        const outgoingMessages = JSON.parse(JSON.stringify(outgoingOrdersMessages))

        Object.keys(temporaryMessageStorage.current).forEach(key => {
            // Проверяем, есть ли такой ключ в outgoingMessages
            if (outgoingMessages[key]) {
                // Добавляем значения из temporaryMessageStorage.current[key] в начало массива outgoingMessages[key]
                outgoingMessages[key].unshift(...temporaryMessageStorage.current[key]);
            }

            // Проверяем, есть ли такой ключ в incomingMessages
            if (incomingMessages[key]) {
                // Добавляем значения из temporaryMessageStorage.current[key] в начало массива incomingMessages[key]
                incomingMessages[key].unshift(...temporaryMessageStorage.current[key]);
            }
        });


        const outgoingValues = Object.values(outgoingMessages);
        const incomingValues = Object.values(incomingMessages);

        // Создаем результирующий массив, содержащий нулевые элементы из каждого массива
        const mergedArray = [];

        // Добавляем нулевые элементы из массива outgoingValues
        outgoingValues.forEach(array => {
            if (array.length > 0) {
                mergedArray.push(array[0]);
            }
        });

        // Добавляем нулевые (всмысле первые) элементы из массива incomingValues
        incomingValues.forEach(array => {
            if (array.length > 0) {
                mergedArray.push(array[0]);
            }
        });

        console.log(mergedArray)

        const sortedMergedArray = mergedArray.sort((a, b) => new Date(b.date) - new Date(a.date));

        const sortedOrdersNumbers = sortedMergedArray.map(message => message.about)

        setSortedOrdersIds(sortedOrdersNumbers)

        // return mergedArray;
    }


    useEffect(() => {

        console.log("MERGING MESSAGES")
        console.log("MODE IS -", mode)

        switch (mode) {
            case "all":
                mergeFirstElements(outgoingOrdersMessages, incomingOrdersMessages);
                break;
            case "creator":
                mergeFirstElements(outgoingOrdersMessages, {});
                break;
            case "performer":
                mergeFirstElements({}, incomingOrdersMessages);
                break;
            default:
                // Что делать в случае, если mode не соответствует ни одному из условий
                break;
        }


    }, [outgoingOrdersMessages, incomingOrdersMessages, mode, unreadOrdersIn, unreadOrdersOut])



    useEffect(() => {

        console.log(incomingOrdersInfo)

        const allOrdersToSort = [...Object.values(incomingOrdersInfo), ...Object.values(outgoingOrdersInfo)]

        console.log(allOrdersToSort)

        const allOrdersToMap =  allOrdersToSort.sort((a, b) => sortedOrdersIds.indexOf(a._id) - sortedOrdersIds.indexOf(b._id));


        const allOrdersToSet = Object.values(allOrdersToMap).map(order => {

            if(order.creator._id !== store.user.id) {
                //если текущий юзер не является модератором данного заказа (не совсем понимаю зачем я сделал такое условие)
                if(store.user.id !== order.moderator) {
                    return (
                        //...то возвращаем вот такой элемент с кнопками
                        <div className={"lots-of-orders-out-container"}>
                            <div
                                key={order._id}
                                className={`lotsOfButtons ${order._id === activeOrderIn ? "blue-theme-back" : ""}`}
                                onClick={() => {
                                    console.log('Set Active IN 2')
                                    setActiveOrderIn(order._id)
                                    setSubMode("performer")
                                    setActiveUserOut()
                                    setActiveOrderOut()

                                }}
                            >
                                {/*<div className={"btn-order-n-btn-close-container"}>*/}
                                <div className={"dot-container-message-center"}>
                                    {unreadOrdersIn.indexOf(order._id) !== -1 && activeOrderIn !== order._id
                                        ?
                                        <img src={SvgRedDot} width={"15px"}/>
                                        :
                                        <img src={SvgWhiteDot} width={"15px"}/>
                                    }
                                </div>


                                <img className={"mc-avatar dont-drag"} src={`${API_URL}/public/avatars/${order.creator._id}/avatar.png`}/>
                                <div className={"chat-name-first-message-inactive-holder"}>
                                    <p className={"p-order-choice-message-center bold"}>#{order.number}<span className={"margin-left-10"}>{order.title?.slice(0, 15)}{order.title?.slice(0, 15).length >= 15 ? "..." : ""}</span></p>
                                    <p className={"p-order-choice-message-center"}>{lastMessagesTextIn[order._id]?.slice(0, 20) ?? "..."}{lastMessagesTextIn[order._id]?.slice(0, 20).length >= 20 ? "..." : ""}</p>
                                </div>
                                <div className={"cross-container-message-center"}>
                                    {/*<img*/}
                                    {/*    className={"margin-0 svg-closing-cross-message-center"}*/}
                                    {/*    src={SvgRedCross}*/}
                                    {/*    width={"15px"}*/}
                                    {/*    onClick={(e) => {*/}
                                    {/*        e.stopPropagation()*/}
                                    {/*        closeIncomingDialog(order._id)*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <img src={SvgIncomingOrder} width={"20px"}/>
                                </div>

                            </div>
                        </div>
                    )
                }

            } else {

                return (
                    <div className={"lots-of-orders-out-container"}>
                        <div
                            key={order._id}
                            className={`lotsOfButtons`}
                            onClick={() => {
                                console.log('Set Active IN 2')
                                setSubMode("creator")
                                setActiveOrderOut(order._id)
                                setActiveOrderIn()

                            }}
                        >
                            {/*<div className={"btn-order-n-btn-close-container"}>*/}
                            <div className={"dot-container-message-center"}>
                                {Object.keys(unreadOrdersOut).indexOf(order._id) !== -1 && activeOrderOut !== order._id
                                    ?
                                    <img src={SvgRedDot} width={"15px"}/>
                                    :
                                    <img src={SvgWhiteDot} width={"15px"}/>
                                }
                            </div>

                            {activeOrderOut === order._id
                                ?
                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${activeUserOut}/avatar.png`}/>
                                :
                                null
                            }

                            <div className={"chat-name-first-message-inactive-holder"}>
                                <p className={"p-order-choice-message-center bold"}>#{order.number}<span className={"margin-left-10"}>{order.title?.slice(0, 15)}{order.title?.slice(0, 15).length >= 15 ? "..." : ""}</span></p>
                                <p className={"p-order-choice-message-center"}>
                                    {activeOrderOut === order._id
                                        ?
                                        null
                                        :
                                        <span className={"margin-right-10 white-color"}>#{order.number}</span>
                                    }

                                    {lastMessagesTextOut[order._id] ?? "..."}{lastMessagesTextOut[order._id]?.slice(0, 20).length >= 20 ? "..." : ""}
                                </p>
                            </div>
                            <div className={"cross-container-message-center"}>
                                {/*<img*/}
                                {/*    className={"margin-0 svg-closing-cross-message-center"}*/}
                                {/*    src={SvgRedCross}*/}
                                {/*    width={"15px"}*/}
                                {/*    onClick={(e) => {*/}
                                {/*        e.stopPropagation()*/}
                                {/*        closeOutgoingDialog(order._id)*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <img className={"margin-left-5"} src={SvgOutgoingOrder} width={"20px"}/>
                            </div>

                        </div>
                        <div className={"lotsOdUsers"}>
                            {activeOrderOut === order._id
                                ?
                                userListToRoll
                                :
                                null
                            }
                        </div>

                    </div>

                )

            }


        })



        // const incomingOrdersNumbers = Object.values(incomingOrdersInfo).map(order => {
        //
        //     //если текущий юзер не является модератором данного заказа (не совсем понимаю зачем я сделал такое условие)
        //     if(store.user.id !== order.moderator) {
        //         return (
        //             //...то возвращаем вот такой элемент с кнопками
        //             <div className={"lots-of-orders-out-container"}>
        //                 <div
        //                     key={order._id}
        //                     className={`lotsOfButtons ${order._id === activeOrderIn ? "blue-theme-back" : ""}`}
        //                     onClick={() => {
        //                         console.log('Set Active IN 2')
        //                         setActiveOrderIn(order._id)
        //                         setSubMode("performer")
        //                         setActiveUserOut()
        //                         setActiveOrderOut()
        //
        //                     }}
        //                 >
        //                     {/*<div className={"btn-order-n-btn-close-container"}>*/}
        //                     <div className={"dot-container-message-center"}>
        //                         {unreadOrdersIn.indexOf(order._id) !== -1 && activeOrderIn !== order._id
        //                             ?
        //                             <img src={SvgRedDot} width={"15px"}/>
        //                             :
        //                             <img src={SvgWhiteDot} width={"15px"}/>
        //                         }
        //                     </div>
        //
        //
        //                     <img className={"mc-avatar dont-drag"} src={`${API_URL}/public/avatars/${order.creator._id}/avatar.png`}/>
        //                     <div className={"chat-name-first-message-inactive-holder"}>
        //                         <p className={"p-order-choice-message-center bold"}>#{order.number}<span className={"margin-left-10"}>{order.title}</span></p>
        //                         <p className={"p-order-choice-message-center"}>{lastMessagesTextIn[order._id]?.slice(0, 20) ?? "..."}{lastMessagesTextIn[order._id]?.slice(0, 20).length >= 20 ? "..." : ""}</p>
        //                     </div>
        //                     <div className={"cross-container-message-center"}>
        //                         {/*<img*/}
        //                         {/*    className={"margin-0 svg-closing-cross-message-center"}*/}
        //                         {/*    src={SvgRedCross}*/}
        //                         {/*    width={"15px"}*/}
        //                         {/*    onClick={(e) => {*/}
        //                         {/*        e.stopPropagation()*/}
        //                         {/*        closeIncomingDialog(order._id)*/}
        //                         {/*    }}*/}
        //                         {/*/>*/}
        //                         <img src={SvgIncomingOrder} width={"20px"}/>
        //                     </div>
        //
        //                 </div>
        //             </div>
        //         )
        //     }
        // })
        //
        //
        // const outgoingOrdersNumbers = Object.values(outgoingOrdersInfo).map(order => {
        //
        //     return (
        //         <div className={"lots-of-orders-out-container"}>
        //             <div
        //                 key={order._id}
        //                 className={`lotsOfButtons`}
        //                 onClick={() => {
        //                     console.log('Set Active IN 2')
        //                     setSubMode("creator")
        //                     setActiveOrderOut(order._id)
        //                     setActiveOrderIn()
        //
        //                 }}
        //             >
        //                 {/*<div className={"btn-order-n-btn-close-container"}>*/}
        //                 <div className={"dot-container-message-center"}>
        //                     {Object.keys(unreadOrdersOut).indexOf(order._id) !== -1 && activeOrderOut !== order._id
        //                         ?
        //                         <img src={SvgRedDot} width={"15px"}/>
        //                         :
        //                         <img src={SvgWhiteDot} width={"15px"}/>
        //                     }
        //                 </div>
        //
        //                 {activeOrderOut === order._id
        //                     ?
        //                     <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${activeUserOut}/avatar.png`}/>
        //                     :
        //                     null
        //                 }
        //
        //                 <div className={"chat-name-first-message-inactive-holder"}>
        //                     <p className={"p-order-choice-message-center bold"}>#{order.number}<span className={"margin-left-10"}>{order.title}</span></p>
        //                     <p className={"p-order-choice-message-center"}>
        //                         {activeOrderOut === order._id
        //                             ?
        //                             null
        //                             :
        //                             <span className={"margin-right-10 white-color"}>#{order.number}</span>
        //                         }
        //
        //                         {lastMessagesTextOut[order._id] ?? "..."}{lastMessagesTextOut[order._id]?.slice(0, 20).length >= 20 ? "..." : ""}
        //                     </p>
        //                 </div>
        //                 <div className={"cross-container-message-center"}>
        //                     {/*<img*/}
        //                     {/*    className={"margin-0 svg-closing-cross-message-center"}*/}
        //                     {/*    src={SvgRedCross}*/}
        //                     {/*    width={"15px"}*/}
        //                     {/*    onClick={(e) => {*/}
        //                     {/*        e.stopPropagation()*/}
        //                     {/*        closeOutgoingDialog(order._id)*/}
        //                     {/*    }}*/}
        //                     {/*/>*/}
        //                     <img className={"margin-left-5"} src={SvgOutgoingOrder} width={"20px"}/>
        //                 </div>
        //
        //             </div>
        //             <div className={"lotsOdUsers"}>
        //                 {activeOrderOut === order._id
        //                     ?
        //                     userListToRoll
        //                     :
        //                     null
        //                 }
        //             </div>
        //
        //         </div>
        //
        //     )
        // })




        setAllOrdersToRoll(allOrdersToSet)



     }, [sortedOrdersIds, outgoingOrdersInfo, activeOrderOut, userListToRoll, lastMessagesTextOut, unreadOrdersOut, rerenderOrderListOut, incomingOrdersInfo, activeOrderIn, unreadOrdersIn, lastMessagesTextIn, rerenderOrderListIn])
    //}, [sortedOrdersIds])



    useEffect(() => {

        cancelAnswering()

        if((mode === "all" && subMode === "creator") || mode === "creator") {

            if(chatScrollTopRef.current[activeOrderOut + activeUserOut]) {

                setTimeout(() => {
                    console.log("Changing scroll position to", chatScrollTopRef.current[activeOrderOut + activeUserOut])
                    messagesAreaRef.current.scrollTo({
                        top: chatScrollTopRef.current[activeOrderOut + activeUserOut],
                        //behavior: 'smooth'
                    })
                }, 200)

            } else {
                messagesAreaRef.current.scrollTo({
                    top: 0,
                    //behavior: 'smooth'
                })
            }

        } else {

            if(chatScrollTopRef.current[activeOrderIn]) {

                setTimeout(() => {
                    console.log("Changing scroll position to", chatScrollTopRef.current[activeOrderIn])
                    messagesAreaRef.current.scrollTo({
                        top: chatScrollTopRef.current[activeOrderIn],
                        //behavior: 'smooth'
                    })
                }, 200)

            } else {
                messagesAreaRef.current.scrollTo({
                    top: 0,
                    //behavior: 'smooth'
                })
            }

        }


    }, [activeOrderOut, activeUserOut, activeOrderIn, mode, subMode])




    //этот эффект срабатывает на изменение активного ИСХОДЯЩЕГО заказа,
    //стейта с инфой по ИСХОДЯЩИМ заказам, и стейта юзеров которых нужно развернуть под кнопкой активного заказа
    useEffect(() => {

        if(mode === "creator") {
            console.log("Сработала сортировка ИСХОДЯЩИХ")
            const outgoingOrdersToSort = Object.values(outgoingOrdersInfo)
            const outgoingOrdersToMap =  outgoingOrdersToSort.sort((a, b) => sortedOrdersIds.indexOf(a._id) - sortedOrdersIds.indexOf(b._id));

            //маппим из значений стейта с инфой исходящих заказов кнопки т номерами этих заказов
            const outgoingOrdersNumbers = Object.values(outgoingOrdersToMap).map(order => {

                return (
                    <div className={"lots-of-orders-out-container"}>
                        <div
                            key={order._id}
                            className={`lotsOfButtons`}
                            onClick={() => {
                                console.log('Set Active IN 2')
                                setActiveOrderOut(order._id)
                            }}
                        >
                            {/*<div className={"btn-order-n-btn-close-container"}>*/}
                            <div className={"dot-container-message-center"}>
                                {Object.keys(unreadOrdersOut).indexOf(order._id) !== -1 && activeOrderOut !== order._id
                                    ?
                                    <img src={SvgRedDot} width={"15px"}/>
                                    :
                                    <img src={SvgWhiteDot} width={"15px"}/>
                                }
                            </div>

                            {activeOrderOut === order._id
                                ?
                                <img className={"ws-message-avatar dont-drag"} src={`${API_URL}/public/avatars/${activeUserOut}/avatar.png`}/>
                                :
                                null
                            }

                            <div className={"chat-name-first-message-inactive-holder"}>
                                <p className={"p-order-choice-message-center bold"}>#{order.number}<span className={"margin-left-10"}>{order.title}</span></p>
                                <p className={"p-order-choice-message-center"}>
                                    {activeOrderOut === order._id
                                        ?
                                        null
                                        :
                                        <span className={"margin-right-10 white-color"}>#{order.number}</span>
                                    }

                                    {lastMessagesTextOut[order._id] ?? "..."}
                                </p>
                            </div>
                            {/*<div className={"cross-container-message-center"}>*/}
                            {/*    <img*/}
                            {/*        className={"margin-0 svg-closing-cross-message-center"}*/}
                            {/*        src={SvgRedCross}*/}
                            {/*        width={"15px"}*/}
                            {/*        onClick={(e) => {*/}
                            {/*            e.stopPropagation()*/}
                            {/*            closeOutgoingDialog(order._id)*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}

                        </div>
                        <div className={"lotsOdUsers"}>
                            {activeOrderOut === order._id
                                ?
                                userListToRoll
                                :
                                null
                            }
                        </div>



                    </div>

                    // <div key={order._id} className={"lotsOfButtons"}>
                    //     <div className={"order-n-close-order-container"}>
                    //         <div className={"lotsOfOrders"}>
                    //
                    //             <button
                    //                 className={`btn order-switch-button ${order._id === activeOrderOut ? 'btn-primary' : 'btn-secondary'}`}
                    //                 onClick={() => setActiveOrderOut(order._id)}
                    //             >#{order.number} {order.moderator ? "⚖" : null}
                    //                 <div className={"margin-left-10 private-notify-sign ubuntu"}>{Object.keys(unreadOrdersOut).indexOf(order._id) !== -1 && activeOrderOut !== order._id
                    //                     ?
                    //                     "✉"
                    //                     :
                    //                     null
                    //
                    //                 }</div>
                    //             </button>
                    //             <button
                    //                 onClick={() => closeOutgoingDialog(order._id)}
                    //                 className={`btn close-dialog-btn`}
                    //             >❌</button>
                    //         </div>
                    //     </div>
                    //     {/*вот здесь разворачивается спиок юзеров под активным заказом*/}
                    //     <div className={"lotsOdUsers"}>
                    //         {activeOrderOut === order._id
                    //             ?
                    //             userListToRoll
                    //             :
                    //             null
                    //         }
                    //     </div>
                    // </div>

                )
            })

            //обновляем финальный стейт который развернется в виде кнопок для выбора ИСХОДЯЩЕГО заказа (диалога)
            //реверс для того чтобы свежие заказы были выше
            setOutgoingOrdersToRoll(outgoingOrdersNumbers)
        }




    }, [mode, sortedOrdersIds, temporaryMessageStorage.current, outgoingOrdersInfo, activeOrderOut, userListToRoll, lastMessagesTextOut, unreadOrdersOut, rerenderOrderListOut])


    //эта функция приведения даты в читаемый человеком вид
    function dateLook(check) {
        if(new Date(Date.now()).toLocaleDateString() === new Date(check).toLocaleDateString()) {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleTimeString(
                    [],{hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        } else {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleDateString(
                    [],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        }
    }

    //функция добавлятор инфы про юзера в стейт с такой инфой
    function adderToInfo(prev, response) {
        const result = { ...prev };

        for (const key in response) {
                result[key] = response[key];
        }

        return result;
    }


    //функция запроса короткой инфы по юзеру из быза на основе его айдишника, или массива айдишников
    async function getShortUsersInfo(idList) {
        console.log(idList)
        //проверяем с помощью фильтра есть ли уже инфа по юзеру из данного списка
        //создаем массив только по тем юзерам которые нужны для запроса
        const listOfFilteredIds = idList.filter(id => !shortUsersInfo.hasOwnProperty(id));
        //если этот список получается не пустым
        if (listOfFilteredIds.length) {
            //...то передаем его на бэкенд
            const response = await $api.post('/get_short_user_info', {idList: listOfFilteredIds})
            //то что прилетело в ответ с помощью функции добавлятора, собственно добавляем в стейт с инфой по юзерам
            setShortUsersInfo(prev => adderToInfo(prev, response.data.shortInfo))
        }
    }


    //эффект срабатывает на:
    //1. изменение активного ИСХОДЯЩЕГО заказа
    //2. изменение списка юзеров текущего заказа (например при добавлении ного или удалении старого)
    //3. изменение стейта с инфой по юзерам
    //4. изменение активного юзера
    useEffect(() => {

        //записываем прошлого актисного юзера
        lastActiveUserOutRef.current = activeUserOutRef.current
        //записываем текущего активного юзера
        activeUserOutRef.current = activeUserOut

        //если у активного заказа есть исполнитель
        if(outgoingOrdersInfo[activeOrderOut]?.doer) {
            //... то создаем промежуточный массив для разворота с кнопками юзеров под заказом содержащий
            //только одну кнопку, соответствующую исполнителю
            const ordersUsers = [<div
                    key={outgoingOrdersInfo[activeOrderOut]?.doer._id}
                    className={`arrow-and-button blue-theme-back`}
                >
                <div className={"dot-container-user-message-center"}>
                    <img src={SvgWhiteDot} width={"10px"}/>
                </div>
                <img className={"margin-right-10"} src={SvgUserArrow} width={"15px"}/>
                    {/*<div className={"user-n-close-user-btn"}>*/}
                        <p
                            className={"p-user-choice-message-center bold"}
                        >
                            {shortUsersInfo[outgoingOrdersInfo[activeOrderOut]?.doer._id]?.name + " " + shortUsersInfo[outgoingOrdersInfo[activeOrderOut]?.doer._id]?.lastName}
                        </p>
                        {/*<button*/}
                        {/*    className={`btn ${outgoingOrdersInfo[activeOrderOut].doer._id === activeUserOut ? "btn-danger doer-button" : "chose-user-button"} rem08`}*/}
                        {/*    // onClick={async () => {*/}
                        {/*    //     console.log("Set Active User OUT 9")*/}
                        {/*    //     await setActiveUserOut(outgoingOrdersInfo[activeOrderOut].doer)*/}
                        {/*    //     console.log('FLAG 12')*/}
                        {/*    //     //clearNotifications(store.user.id, props.activeOrderOut, activeUserOut)*/}
                        {/*    //     //clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)*/}
                        {/*    //*/}
                        {/*    // }}*/}
                        {/*>*/}
                        {/*    /!*{usersInfo[user]?.lastName}{activeUserOut === user ? "✓" : null}*!/*/}
                        {/*    {shortUsersInfo[outgoingOrdersInfo[activeOrderOut].doer._id]?.name + " " + shortUsersInfo[outgoingOrdersInfo[activeOrderOut].doer._id]?.lastName + "✓"}*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    //onClick={() => hideOutgoingUser(item.order._id, user)}*/}
                        {/*    className={`btn close-user-btn`}>❌</button>*/}
                    {/*</div>*/}
            </div>]
            setUserListToRoll(ordersUsers)

        //...а если у актиыного заказа нет исполнителя, то...
        } else {
            //...делаем маппинг по списку юзеров активного заказа, создавай кнопку для каждого
            const ordersUsers = usersOfOutOrder[activeOrderOut]?.map(user => {

                //if(item.order.hiddenUsers.indexOf(user) === -1) {
                //   if(toJS(store.hiddenUsers[[user.order._id]])?.indexOf(user) === -1) {

                return <div
                    key={user}
                    className={`arrow-and-button ${user === activeUserOut ? "blue-theme-back" : ""}`}
                    onClick={() => {
                        setActiveUserOut(user)
                    }}
                >
                    <div className={"dot-container-user-message-center"}>
                        {unreadOrdersOut[activeOrderOut]?.includes(user) && activeUserOut !== user
                            ?
                            <img src={SvgRedDot} width={"10px"}/>
                            :
                            <img src={SvgWhiteDot} width={"10px"}/>
                        }
                    </div>

                    <img className={"margin-right-10"} src={SvgUserArrow} width={"15px"}/>
                    <p
                        className={"p-user-choice-message-center bold"}
                    >
                        {shortUsersInfo[user]?.name + " " + shortUsersInfo[user]?.lastName}
                    </p>
                    {/*<div className={"user-n-close-user-btn"}>*/}
                    {/*    /!*если юзер активен то он будет красного цвета*!/*/}
                    {/*    <button*/}
                    {/*        className={`btn ${user === activeUserOut ? "btn-danger" : "btn-primary-poor"} chose-user-button rem08`}*/}
                    {/*        onClick={async () => {*/}
                    {/*            console.log("Set Active User OUT 9")*/}
                    {/*            await setActiveUserOut(user)*/}
                    {/*            console.log('FLAG 12')*/}
                    {/*            //clearNotifications(store.user.id, props.activeOrderOut, activeUserOut)*/}
                    {/*            //clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)*/}
                    
                    {/*        }}*/}
                    {/*        //и если юзер активен то у него будет галочка после имени*/}
                    {/*    >*/}
                    {/*        <div className={"margin-left-10 private-notify-sign ubuntu"}>{unreadOrdersOut[activeOrderOut]?.includes(user) && activeUserOut !== user*/}
                    {/*            ?*/}
                    {/*            "✉"*/}
                    {/*            :*/}
                    {/*            null*/}
                    
                    {/*        }</div>*/}
                    {/*    </button>*/}
                    {/*    <button*/}
                    {/*        onClick={() => hideOutgoingUser(activeOrderOut, user)}*/}
                    {/*        className={`btn close-user-btn`}>❌</button>*/}
                    {/*</div>*/}
                </div>
                //}
            })
            //файналли запиливаем стейт который будет разворачиваться под кнопкой активного заказа
            setUserListToRoll(ordersUsers)
        }

    }, [activeOrderOut, usersOfOutOrder, shortUsersInfo, activeUserOut, unreadOrdersOut])


    //функция упаковки сообщений из временного хранилища в основной стейт,
    //в качестве аргумента принимает направление заказа (входящий/исходящий)
    async function packTempMessages(direction) {
        const messageArray = Object.values(temporaryMessageStorage.current[direction === "out" ? activeOrderOut : activeOrderIn]).reverse();

        console.log(messageArray)

        if (direction === "out") {
            console.log("set OOM 5")


            await setOutgoingOrdersMessages(prev => {
                console.log(prev[activeOrderOut])

                return {
                    ...prev,
                    [activeOrderOut]: prev[activeOrderOut] ? [...messageArray, ...prev[activeOrderOut]] : messageArray
                }
            })


        } else {
            console.log("set IOM 5")
            await setIncomingOrdersMessages(prev => {

                console.log(prev[activeOrderIn])

                return {
                    ...prev,
                    [activeOrderIn]: prev[activeOrderIn] ? [...messageArray, ...prev[activeOrderIn]] : messageArray
                }
            })


        }
        //то что добавлено в основной стейт можно теперь удалить из временного
        delete temporaryMessageStorage.current[direction === "out" ? activeOrderOut : activeOrderIn]

    }



    //этот эффект срабатывает на любое изменение активных заказов,
    //активного пользователя или режима работы ЗАКАЗЧИК/ИСПОЛНИТЕЛЬ
    useEffect(() => {

        //и закидывает сообщения во АКТИВНОМУ ТЕПЕРЬ заказу из временного хранилиша в основной стейт
        if (temporaryMessageStorage.current.hasOwnProperty(activeOrderOut)) {
            console.log("Надо обновить сообщения")
            packTempMessages("out")
        }

        if (temporaryMessageStorage.current.hasOwnProperty(activeOrderIn)) {
            console.log("Надо обновить сообщения")
            packTempMessages("in")
        }


    }, [activeUserOut, activeOrderOut, mode, activeOrderIn])


    //этот эффект срабатывает на изменение активного ИСХОДЯЩЕГО заказа либо его активного юзера
    useEffect(() => {
        console.log("NOW ON")
        //выставляет флаг о необходимости линии НОВЫЕ СООБЩЕНИЯ в true
        needNewMessageLineOut.current = true
        //и влаг о необходимости прокрутиь окно сообщений в низ, тоже в true
        scrollerRefOut.current = true

        //эти флажки потом будут использованы для принятия соответствующего решения


    }, [activeOrderOut, activeUserOut])


    //тоже самое что и эффект выше, но для ВХОДЯЩИХ заказов
    useEffect(() => {
        console.log("NOW ON")
        needNewMessageLineIn.current = true
        scrollerRefIn.current = true


    }, [activeOrderIn])


    const [answeringMessageNative, setAnsweringMessageNative] = useState({
        text: null,
        id: null,
        date: null,
        self: null,
        quote: null,
        sender: null
    })


    function answerOnMessage(text, id, date, self, quote, sender) {
        console.log("SET 2")
        console.log(text, id, date, self)
        setAnsweringMessageNative({text: text, id: id, date: date, self: self, quote: quote, sender: sender})
    }



    // function containsLink(text) {
    //     const linkRegex = /(https?:\/\/[^\s]+)/;
    //     return linkRegex.test(text);
    // }
    //
    //
    // function splitWord(text, size) {
    //     const chunks = [];
    //     let container = [];
    //     console.log("NOW SPLITTING")
    //     console.log(text)
    //
    //     for (let i = 0; i < text.length; i += size) {
    //         chunks.push(text.slice(i, i + size));
    //     }
    //
    //
    //     for(let chunk of chunks) {
    //
    //         container.push(<span key={chunk}>{chunk}<wbr/></span>);
    //     }
    //
    //     return container
    //
    //
    // }
    //
    // function processMessageText(text) {
    //
    //     if(text) {
    //         if (containsLink(text)) {
    //             const messageContent = text.replace(
    //                 /(https?:\/\/[^\s]+)/g,
    //                 (url) => `<a className={"link-ws-container"} href="${url}" target="_blank">${url.length > 100 ? url.slice(0, 100) + "..." : url}</a>`
    //             );
    //
    //             return <span className={"text-ws-container"} dangerouslySetInnerHTML={{ __html: messageContent }} />;
    //         }
    //         //console.log(text)
    //         const main_array = text.split(" ")
    //         let decision = false
    //
    //         let container = []
    //
    //         for(let piece of main_array) {
    //             if (piece.length > 25) {
    //                 decision = true;
    //                 break
    //             }
    //         }
    //
    //         if (decision) {
    //
    //             const chunkSize = 20;
    //
    //
    //
    //             //const wordChunks = splitWord(text, chunkSize);
    //             return splitWord(text, chunkSize);
    //             // for(let chunk of wordChunks) {
    //             //
    //             //     container.push(
    //             //         <span>{chunk}<wbr/></span>
    //             //     )
    //             // }
    //             //
    //             // return container
    //
    //         } else {
    //             return text
    //         }
    //     } else {
    //         return text
    //     }
    //
    //
    // }

    function textReducer(text) {
        console.log(text)
        if (text?.length > 150) {
            return text.slice(0, 150) + "..."
        }
        return text
    }

    const [nestedContent, setNestedContent] = useState()
    const [stepCounter, setStepCounter] = useState(0)
    const [lastSteps, setLastSteps] = useState([])
    const [activeMessageModal, setActiveMessageModal] = useState("no")

    async function showNestedMessage(id, stepBack, marker) {
        console.log(id)
        console.log(marker, " - marker")
        const response = await $api.get(`/get_nested_message_work?id=${id}`)
        console.log(response.data)
        await setNestedContent(response.data)
        if (stepBack) {
            console.log("step back")
            setStepCounter(stepCounter - 1)
            setLastSteps(prev => prev.slice(0, -1));
        } else {
            console.log("step forward")
            setStepCounter(stepCounter + 1)
            setLastSteps(prev => [...prev, id])
        }

        setActiveMessageModal("progress")
        setTimeout(() => {
            setActiveMessageModal("active")
        }, 100)
    }



    function processQuote(message, orderInfo) {

        console.log(message)
        console.log(message.quote.isModerator)
        console.log(message.quote_userId === orderInfo.moderator)
        return <div className={"answered-message answered-right"}>

            {/*<p className={"private-message-date rem07"}>*/}
            {/*    {dateSet(message.quote.date ? message.quote.date : message.quote_date)}*/}
            {/*</p>*/}
            <span className={"bold rem08"}>{
                message.quote.isModerator || (message.quote_userId === orderInfo.moderator && orderInfo.moderator)
                    ?
                    "Модератор"
                    :
                    message.quote.userId === orderInfo.creator?._id || message.quote_userId === orderInfo.creator?._id
                        ?
                        orderInfo.creator?._id === store.user.id
                            ?
                            "Я"
                            :
                            orderInfo.creator.name + " " + orderInfo.creator.lastName

                        :
                        orderInfo.doer?._id === store.user.id
                            ?
                            "Я"
                            :
                            orderInfo.doer
                                ?
                                orderInfo.doer?.name + " " + orderInfo.doer?.lastName
                                :
                                mode === "performer" || (mode === "all" && subMode === "performer")
                                    ?
                                    "Я"
                                    :
                                    shortUsersInfo[activeUserOut]?.name + " " + shortUsersInfo[activeUserOut]?.lastName

            }:</span>

            <span className={"rem08"}>

                {message.quote.text
                    ?
                    //processMessageText(textReducer(message.quote.text))
                    <TextExpander text={message?.quote.text} />
                    //message.quote.text
                    :
                    //processMessageText(textReducer(message.quote_text))
                    <TextExpander text={message?.quote_text} />
                    //message?.quote_text
                }
            </span>

            {message.quote.quote || message.need_rolling
                ?
                <a
                    onClick={() => showNestedMessage(message.quote._id ? message.quote._id : message.quote , false, 2)}
                    className={'rem07 modal-link'}
                >показать вложение</a>
                :
                null
            }

        </div>
    }

    // const [triggerOut, setTriggerOut] = useState(false)
    // const slicerBigRef = useRef(0)
    //
    // useEffect(() => {
    //
    //     let slicerCounter = 0
    //
    //
    //     if(outgoingOrdersMessages[activeOrderOut]) {
    //
    //         for(let message of outgoingOrdersMessages[activeOrderOut]) {
    //             if(message.userId._id !== store.user.id && !message.read?.includes(store.user.id)) {
    //                 slicerCounter++
    //             }
    //             if(message.userId._id !== store.user.id && message.read?.includes(store.user.id)) {
    //                 break;
    //             }
    //         }
    //
    //         if(slicerCounter > slicerOut.stop) {
    //             //setSlicerOut({start: 0, stop: slicerCounter + 10})
    //             slicerBigRef.current = slicerCounter + 10
    //             setTriggerOut(true)
    //
    //         } else {
    //             setTriggerOut(true)
    //         }
    //
    //
    //
    //     }
    //
    //
    // }, [activeUserOut])




    //данный эффект срабатывает на изменение активного ИСХОДЯЩЕГО заказа, активного юзера по такому заказу
    //и на изменение массива сообщений по таким заказам
    useEffect(() => {



        //создаем заметку о том какой заказ сейчас активный, а то видети ли из стейта мы не можем достать эту инфу
        activeOrderOutRef.current = activeOrderOut

        //это флажок активируется когда в одном из следующих сообщений нужно принять решение о показе линии НОВЫЕ СООБЩЕНИЯ
        let newMessageLineSwitch = false
        //это влажок о том что со следующим сообщением нужно нарисовать линию НОВЫЕ СООБЩЕНИЯ
        let newMessagesLine = false
        //если все сообщения непрочитанные, то этот флажок добавит линию НОВЫЕ СООБЩЕНИЯ на самом верху
        let allMessagesAreUnread = true
        //это счётчик непрочитанных сообщений в массиве развёртывания
        let unreadCounter = 0
        //этот влажок сигнализирует о том, что можно больше не искать уда ставить линию НОВЫЕ СООБЩЕНИЯ
        let stopSearching = false



        let lastDate = Date.now()
        let lastUser = ""



        const userAmount = outgoingOrdersMessages[activeOrderOut]?.reduce((acc, current) => {
            if(current.userId._id === activeUserOut) {
                acc = {indexCommon: acc.indexCommon + 1, indexUser: acc.indexCommon}
            } else {
                acc = {indexCommon: acc.indexCommon + 1, indexUser: acc.indexUser}
            }

            return acc
        }, {indexCommon: 0, indexUser: 0})

        const lastUserIndex = userAmount?.indexUser


        //теперь создаем промежуточный массив, раньше мы его маппили, но позже концепция изменилась
        let messagesOut = []




        //кусок старой концепции с маппингом
        //let messagesOut = outgoingOrdersMessages[activeOrderOut]?.slice(slicer.start, slicer.stop).map((message, index) => {

        //проверяем что массив сообщений существует
        if (outgoingOrdersMessages[activeOrderOut]) {
            //это номер итерации
            //он увеличивается только когда мы что то добавляем в массив
            //так мы отслеживаем что все сообщения в нем соответствуют текущему активному окошку
            let index = 0
            //бежим по сообщениям в массиве


            let slicerCounter = 0

            console.log(outgoingOrdersMessages[activeOrderOut])

            for(let message of outgoingOrdersMessages[activeOrderOut]) {
                console.log(message.text)
                if(message.userId._id !== store.user.id
                    && (!message.read?.includes(store.user.id)
                    || !readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id))) {

                    slicerCounter++
                }
                if(message.userId._id !== store.user.id
                    && (message.read?.includes(store.user.id)
                    || readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id))) {
                    break;
                }
            }

            console.log(outgoingOrdersMessages[activeOrderOut])


            for(let message of outgoingOrdersMessages[activeOrderOut]) {

                //до тех пор пока не доберемся до сообщения с индексом указанным в резаке
                if(slicerCounter > slicerOut.stop ? index <= slicerCounter + 20 : index <= slicerOut.stop) {


                    console.log(message.text, index, lastUserIndex)
                    //флаг о необходимости разделительной линии дат
                    const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                    //дата из прошлого сообщения для установки в разделительную линиию
                    const date = new Date(lastDate)
                    //обновление переменной с последней датой, именно в такой строжайшей последовательности
                    lastDate = message.date
                    lastUser = message.userId?._id

                    //это мой костылик, первое сообщение пользователя отправляется автоматически
                    //оно выглядит вот так и нужно для того чтобы юзер приславший заявку на выполнение числился
                    //в юзерлисте данного заказа и чтобы заказчик мог первый ему что то написать
                    if (message.text === "#####") {
                        newMessagesLine = false
                        console.log("Это не надо возвращать")
                        return
                    }

                    //это установка самого свежего сообщения по исходящим заказам, это маркер для понимания нужна ли отправка уведомления на почту
                    if (index === 0) {
                        setMessagesOutForNotificator(message)
                    }
                    //если сообщение от текущего пользователя и оно предназначено активному юзеру
                    if ((message.userId._id === store.user.id || message.userId === store.user.id) && message.to.includes(activeUserOut)) {// && message.about === activeOrderOut) {

                        //отключаем флажок о показе линии НОВЫЕ СООБЩЕНИЯ, иначе он бы показал на сообщение от самого себя
                        //а так быть не может по определению
                        newMessagesLine = false

                        //если же в прошлой итерации сработал флажок о необходимости линии НОВЫЕ СООБЩЕНИЯ...
                        if (newMessageLineSwitch) {

                            //...то на этой итерации мы включаем флаг о том, что нужно эту линию в данной итерации нарисовать
                            newMessagesLine = true
                            //...отключаем флаг о необходимости рисовать новую линию, чтобы она в следующих сообщениях не рисовалась
                            newMessageLineSwitch = false
                            //..отключаем флажок о том что все сообщения непрочитаны, потому что это уже не так
                            allMessagesAreUnread = false

                        }

                        //если нулевым индексом идет сообщения от себя самого, то по определению все прошлые сообщения прочитаны
                        //и нужно флажок о всех непрочитанных снять
                        if (index === 0) {
                            allMessagesAreUnread = false
                        }

                        //дальше фозвращаем конструкцию сообщения


                        messagesOut.push( <div className={"work-chat-main-message-wrapper"}>
                            {/*{outgoingOrdersMessages[activeOrderOut]?.length - 1 === index || lastUserIndex === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}*/}
                            <div className={"work-message-wrapper work-message-wrapper-right"} key={message._id}>

                                <img
                                className={"private-arrow-svg arrows"}
                                src={SvgAnswer}
                                alt="Ответить"
                                width={"25px"}

                                onClick={(e) => {
                                answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                            }}
                                />
                            <div className={"chat-message chat-right"}>
                                <div className={'mc-date-self-container'}>
                                    {dateLook(message.date)}
                                    <img
                                        className={"dont-drag work-read-img margin-left-10"}
                                        src={message.read.includes(activeUserOut) ? SvgCheckRead : SvgCheckUnread}
                                        width={"17px"}/>
                                </div>
                                {message.quote
                                    ?
                                    processQuote(message, outgoingOrdersInfo[activeOrderOut])
                                    :
                                    null
                                }
                                <div className={"chat-text-right"}>{processMessageText(message.text)}</div>
                                {message.links?.length
                                    ?
                                    <div className={"white-back margin-top-10"}>
                                        {linkRoller(message.links)}
                                    </div>
                                    :
                                    null
                                }
                                {/*<div className={"work-read-marker margin-top-5"}>*/}
                                {/*    <img*/}
                                {/*        className={"dont-drag work-read-img"}*/}
                                {/*        src={message.read.includes(activeUserOut) ? SvgOpenedEye : SvgClosedEye}*/}
                                {/*        width={"17px"}/>*/}
                                {/*</div>*/}
                            </div>

                            </div>
                            <div>
                                {newMessagesLine && index !== 0 ?
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}

                            </div>
                            {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        </div>)
                        // index++

                        //если сообщение от активного юзера
                    } else if (message.userId._id === activeUserOut) { //&& message.to === store.user.id && message.about === activeOrderOut) {

                        //снимаем флаг о необходимости нового сообщения, это делается в начале любой итерации
                        newMessagesLine = false

                        //далее, если в массиве прочитавших сообщение есть айди пользователя,
                        //или в оперативном хранилище есть информация что это сообщение недавно прочитано
                        //и при этом на одной из прошлых итерация активирован сигнал о том что нам нужна линия НОВЫЕ СООБЩЕНИЯ
                        //это позволяет при появлении первого прочитанного сообщения понять что до них шли непрочитанные
                        if ((message.read.includes(store.user.id) || readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id))
                            && newMessageLineSwitch) {
                            //...то включаем сигнал о том что эту линию нужно нарисовать сейчас
                            newMessagesLine = true
                            //..отключаем флаг о дальнейшей необходимости линии НОВЫЕ СООБЩЕНИЯ
                            newMessageLineSwitch = false
                            //и переводим флаг о том что все сообщения являются непрочитанными в положение false
                            allMessagesAreUnread = false
                        }

                        // если сообщение не содержит в массиве прочитавших айди юзера
                        if (!message.read?.includes(store.user.id)
                            //&& message.userId._id !== store.user.id
                            //и сигнал об остановке поиска линии НОВЫЕ СООБЩЕНИЯ еще не активирован
                            && !stopSearching
                            //а переключатель который сбрасывается на true при каждой смене активного заказа или
                            //активного пользователя сейчас в положении true
                            && needNewMessageLineOut.current
                        ) {
                            //еще одна проверка на то что сообщения нет в оперативном хранилище недавно прочитанных
                            if (!readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id)) {
                                console.log("Свитч здесь 1")
                                //включаем сигнал о том что в следующем сообщении нужно нарисовать линию НОВЫЕ СООБЩЕНИЯ
                                newMessageLineSwitch = true
                                //и активируем сигнал о том что ее можно больше не искать
                                stopSearching = true
                                console.log("NOW OFF")
                                //и сигнал о том что в данном активном окне больше не нужно рисовать эту линию с приходом новых сообщений
                                needNewMessageLineOut.current = false
                            }
                            console.log("ЭТОТ МАРКЕРР 2")
                        }

                        //если сообщение от активного юзера, и в его массиве о прочтении нет айдишника юзера
                        if (message.userId._id === activeUserOut && !message.read.includes(store.user.id)) {
                            //и если после этой проверки мы его не находим и в оперативном хранилище прочитанных
                            if (!readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id)) {
                                //то увеличиваем счетчик непрочитанных
                                unreadCounter++
                            }
                        }

                        //далее возвращаем конструкт сообщения

                        messagesOut.push(<div className={"work-chat-main-message-wrapper"}>
                            {outgoingOrdersMessages[activeOrderOut]?.length - 1 === index || lastUserIndex === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}
                            <div className={"work-message-wrapper-left"} key={message._id}>

                            <div
                                className={"chat-message chat-left"}
                                data-read={message.read?.includes(store.user.id) || false}
                                id={message._id}
                            >
                                   <span className={"chat-username"}>
                                       {/*<img*/}
                                       {/*    className={"ws-message-avatar"}*/}
                                       {/*    src={`${API_URL}/public/avatars/${message.userId._id}/avatar.png`}*/}
                                       {/*    alt={"аватар"}*/}
                                       {/*/>*/}
                                       <div className={'mc-date-username-holder'}>
                                           <Link
                                               to={`/user-details/${message.userId._id}`}
                                               className={'block'}
                                               style={{ textDecoration: 'none', color: 'black' }}
                                           >
                                               {message.userId.name + " " + message.userId.lastName}
                                           </Link>
                                           <div className={'block margin-left-20'}>{dateLook(message.date)}</div>
                                       </div>
                                   </span>
                                {message.quote
                                    ?
                                    processQuote(message, outgoingOrdersInfo[activeOrderOut])
                                    :
                                    null
                                }
                                <div className={"chat-text"}>{processMessageText(message.text)}</div>
                                {message.links?.length
                                    ?
                                    <div className={"white-back margin-top-10"}>
                                        {linkRoller(message.links)}
                                    </div>
                                    :
                                    null
                                }
                            </div>

                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            </div>
                            <div>
                                {newMessagesLine && index !== 0 ?
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                            </div>
                            {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        </div>)
                        // index++


                        //если в сообщении не указан модератор, и сообщение не от того кто их сейчас смотрит
                    } else if (message.userId._id !== store.user.id && message.isModerator
                        //и если сейчас открыта (активна) кнопочка (диалог) исполнителя данного заказа
                        && outgoingOrdersInfo[activeOrderOut]?.doer
                    ) {

                        //убираем влажок о рисовании линии НОВЫЕ СООБЩЕНИЯ, это делаем на каждой итерации
                        newMessagesLine = false

                        //проверяем что айди юзера есть в массиве тех кто прочитал данное сообщение
                        //или что в оперативном хранилище прочитанных тоже есть инфа о прочтении
                        //и что активирован флаг о необходимости линии НОВЫЕ СООБЩЕНИЯ
                        if ((message.read.includes(store.user.id) || readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id))
                            && newMessageLineSwitch) {
                            //активируем флажок показа линии НОВЫЕ СООБЩЕНИЯ в данном сообщении
                            newMessagesLine = true
                            //отключаем флажок о необходимости таковой линии в будущем
                            newMessageLineSwitch = false
                            //и убереам флаг о том что все сообщения непрочитаны
                            allMessagesAreUnread = false
                        }

                        //проверяем если
                        if (
                            //в массиве прочитавших сообщения нашего айдишника нет
                            !message.read.includes(store.user.id)
                            //и сообщение не от самого себя
                            && message.userId._id !== store.user.id
                            //и флажок что в этом раззвертывании нужна линия НОВЫЕ СООБЩЕНИЯ
                            && needNewMessageLineOut.current
                            //и что флажок отмены ее поиска еще не активирован
                            && !stopSearching) {
                            console.log("ЭТОТ МАРКЕРР !")
                            //далее проверяем что в оперативном хранилище так же нет сведений о том что мы это сообщение прочитали
                            if (!readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id)) {
                                console.log("Свитч здесь 2")
                                //и если условия выполнены, то активируем флаг
                                //о необходимости нарисовать в этом развертывании линию НОВЫЕ СООБЩЕНИЯ
                                newMessageLineSwitch = true
                                //активируем флаг о прекращении поиска этой линии в будущем
                                stopSearching = true
                                //и флаг о том что все сообщения являются непрочитанными тоже снимаем
                                allMessagesAreUnread = false
                                console.log("NOW OFF")
                                //а так же отключаем необходимость рисовании линии НОВЫЕ СООБЩЕНИЯ
                                //в дальнейших развертываниях (в активном окне)
                                needNewMessageLineOut.current = false
                            }
                        }

                        //если данное сообщение об активном заказе
                        if (message.about === activeOrderOut
                            //и нашего ид нет в массиве прочитавших сообщение
                            && !message.read.includes(store.user.id)
                            //и сообение это не от самого себя
                            && message.userId._id !== store.user.id

                        ) {
                            //и в оперативном хранилище информации о его прочтении тоже нет
                            if (!readMessagesOut.current[activeOrderOutRef.current]?.includes(message._id)) {
                                //то увеличиваем счетчик непрочитанных
                                unreadCounter++
                            }
                        }

                        //возвращаем конструкт сообщения

                        messagesOut.push(<div className={"work-chat-main-message-wrapper"}>

                            <div className={"work-message-wrapper-center"} key={message._id}
                        >
                            <div
                                className={"chat-message chat-center"}
                                data-read={message.read?.includes(store.user.id) || false}
                                id={message._id}
                            >
                                <div className={"chat-username"}>Модератор</div>
                                {dateLook(message.date)}
                                {message.quote
                                    ?
                                    processQuote(message, outgoingOrdersInfo[activeOrderOut])
                                    :
                                    null
                                }
                                {processMessageText(message.text)}
                            </div>
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            </div>
                            <div>
                                {newMessagesLine && index !== 0 ?
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                            </div>
                            {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        </div>)
                        // index++

                    }

                //это кусочек старой концепции с маппингом
                //})

                //если индекс привысил допустимое число указанное в резаке (slier)
                } else {
                    //то тормозим выполнение цикла
                    break
                }

                index++
        }}


        //если счетчик непрочитанных больше нуля
        //и флаг о том что все сообщения непрочитаны не снят и массив сообщений не пуст
        // if(unreadCounter > 0 && allMessagesAreUnread && messagesOut?.length) {
        //     //то закидываем на самый верх линию НОВЫЕ СООБЩЕНИЯ
        //     messagesOut?.push(
        //         <div key={"dlfkmfdkm"}>
        //             <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6>
        //         </div>
        //     )
        // }

        //после маппинга кидаем всё в стейт для развертывания сообщений по ИСХОДЯЩИМ ЗАКАЗАМ
        setMessagesToRollOut(messagesOut)


    }, [activeOrderOut, activeUserOut, outgoingOrdersMessages, slicerOut])



    //этот эффект срабатывает при изменении активного ВХОДЯЩЕГО заказа и массива сообщений по таким заказам
    useEffect(() => {

        console.log("ФОРМИРУЕМ СООБЩЕНИЯ ПО ВХОДЯЩИМ")

        console.log(activeOrderIn, incomingOrdersMessages, slicerIn)

        //создаем заметку о том какой заказ сейчас активный, а то видети ли из стейта мы не можем достать эту инфу
        activeOrderInRef.current = activeOrderIn



        //это флажок активируется когда в одном из следующих сообщений нужно принять решение о показе линии НОВЫЕ СООБЩЕНИЯ
        let newMessageLineSwitch = false
        //это влажок о том что со следующим сообщением нужно нарисовать линию НОВЫЕ СООБЩЕНИЯ
        let newMessagesLine = false
        //этот влажок сигнализирует о том, что можно больше не искать уда ставить линию НОВЫЕ СООБЩЕНИЯ
        let stopSearching = false

        let lastDate = Date.now()
        let lastUser = ""


        //const messagesIn = incomingOrdersMessages[activeOrderIn]?.map((message, index) => {

        let messagesIn = []




        if (incomingOrdersMessages[activeOrderIn]) {


            let slicerCounter = 0

            for(let message of incomingOrdersMessages[activeOrderIn]) {
                if(message.userId._id !== store.user.id
                    && (!message.read?.includes(store.user.id)
                        || !readMessagesIn.current[activeOrderInRef.current]?.includes(message._id))) {

                    slicerCounter++
                }
                if(message.userId._id !== store.user.id
                    && (message.read?.includes(store.user.id)
                        || readMessagesIn.current[activeOrderInRef.current]?.includes(message._id))) {
                    break;
                }
            }


            console.log('Settring MTRI', incomingOrdersMessages[activeOrderIn])

            let index = 0
            for(let message of incomingOrdersMessages[activeOrderIn]) {


                if(slicerCounter > slicerIn.stop ? index <= slicerCounter + 30 : index <= slicerIn.stop) {

                    //флаг о необходимости разделительной линии дат
                    const horizontal = new Date(lastDate).toDateString() !== new Date(message.date).toDateString()
                    //дата из прошлого сообщения для установки в разделительную линиию
                    const date = new Date(lastDate)
                    //обновление переменной с последней датой, именно в такой строжайшей последовательности
                    lastDate = message.date
                    lastUser = message.userId?._id

                    if (message.text === "#####") {
                        //return
                        console.log("Это не надо возвращать")
                    }
                    //если айдишник того кто написал данное сообщение совпадает с айдишником пользователя
                    if (message.userId._id === store.user.id) {

                        //отключаем флажок о показе линии НОВЫЕ СООБЩЕНИЯ, иначе он бы показал на сообщение от самого себя
                        //а так быть не может по определению
                        newMessagesLine = false

                        //если же в прошлой итерации сработал флажок о необходимости линии НОВЫЕ СООБЩЕНИЯ...
                        if (newMessageLineSwitch) {

                            //...то на этой итерации мы включаем флаг о том, что нужно эту линию в данной итерации нарисовать
                            newMessagesLine = true
                            //...отключаем флаг о необходимости рисовать новую линию, чтобы она в следующих сообщениях не рисовалась
                            newMessageLineSwitch = false
                        }

                        // index++
                        messagesIn.push(<div className={"work-chat-main-message-wrapper"} key={message._id}>
                            {incomingOrdersMessages[activeOrderIn]?.length - 1 === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}
                            <div className={"work-message-wrapper-right"}>
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />
                            <div className={"chat-message chat-right"}>
                                <div className={'mc-date-self-container'}>
                                    {dateLook(message.date)}
                                    <img
                                        className={"dont-drag work-read-img margin-left-10"}
                                        src={message.read.includes(incomingOrdersInfo[activeOrderInRef.current].creator._id) ? SvgCheckRead : SvgCheckUnread}
                                        width={"17px"}/>
                                </div>
                                {message.quote
                                    ?
                                    processQuote(message,  incomingOrdersInfo[activeOrderIn])
                                    :
                                    null
                                }
                                {processMessageText(message.text)}
                                {message.links?.length
                                    ?
                                    <div className={"white-back margin-top-10"}>
                                        {linkRoller(message.links)}
                                    </div>
                                    :
                                    null
                                }

                            </div>


                        </div>
                            <div>
                                {newMessagesLine && index !== 0 ?
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                            </div>
                            {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        </div>)

                        //если отправитель это заказчик
                    } else if (message.userId._id === incomingOrdersInfo[activeOrderIn].creator._id) { //&& message.to === store.user.id && message.about === activeOrderIn) {


                        //снимаем флаг о необходимости нового сообщения, это делается в начале любой итерации
                        newMessagesLine = false

                        //далее, если в массиве прочитавших сообщение есть айди пользователя,
                        //или в оперативном хранилище есть информация что это сообщение недавно прочитано
                        //и при этом на одной из прошлых итерация активирован сигнал о том что нам нужна линия НОВЫЕ СООБЩЕНИЯ
                        //это позволяет при появлении первого прочитанного сообщения понять что до них шли непрочитанные
                        if ((message.read.includes(store.user.id) || readMessagesIn.current[activeOrderInRef.current]?.includes(message._id))
                            && newMessageLineSwitch) {
                            //...то включаем сигнал о том что эту линию нужно нарисовать сейчас
                            newMessagesLine = true
                            //..отключаем флаг о дальнейшей необходимости линии НОВЫЕ СООБЩЕНИЯ
                            newMessageLineSwitch = false
                        }

                        // если сообщение не содержит в массиве прочитавших айди юзера
                        if (!message.read?.includes(store.user.id)
                            //&& message.userId._id !== store.user.id
                            //и сигнал об остановке поиска линии НОВЫЕ СООБЩЕНИЯ еще не активирован
                            && !stopSearching
                            //а переключатель который сбрасывается на true при каждой смене активного заказа или
                            //активного пользователя сейчас в положении true
                            && needNewMessageLineIn.current
                        ) {
                            //еще одна проверка на то что сообщения нет в оперативном хранилище недавно прочитанных
                            if (!readMessagesIn.current[activeOrderInRef.current]?.includes(message._id)) {
                                console.log("Свитч здесь 1")
                                //включаем сигнал о том что в следующем сообщении нужно нарисовать линию НОВЫЕ СООБЩЕНИЯ
                                newMessageLineSwitch = true
                                //и активируем сигнал о том что ее можно больше не искать
                                stopSearching = true
                                console.log("NOW OFF")
                                //и сигнал о том что в данном активном окне больше не нужно рисовать эту линию с приходом новых сообщений
                                needNewMessageLineIn.current = false
                            }
                            console.log("ЭТОТ МАРКЕРР 2")
                        }

                        // index++
                        messagesIn.push(<div className={"work-chat-main-message-wrapper"} key={message._id}>
                            {incomingOrdersMessages[activeOrderIn]?.length - 1 === index ? <h6 className="ws-chat-hr">{new Date(message.date).toLocaleDateString()}</h6> : null}
                            <div className={"work-message-wrapper-left"}>
                            <div
                                className={"chat-message chat-left"}
                                id={message._id}
                                data-read={message.read?.includes(store.user.id) || false}
                            >
                            <span className={"chat-username"}>
                                {/*<img*/}
                                {/*    className={"ws-message-avatar"}*/}
                                {/*    src={`${API_URL}/public/avatars/${message.userId._id}/avatar.png`}*/}
                                {/*    alt={"аватар"}*/}
                                {/*/>*/}
                                <div className={'inline'}>
                                    <Link
                                        to={`/user-details/${message.userId._id}`}
                                        className={'block'}
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        {message.userId.name + " " + message.userId.lastName}
                                    </Link>
                                    <div className={'block'}>
                                        {dateLook(message.date)}
                                    </div>
                                </div>
                            </span>
                                {message.quote
                                    ?
                                    processQuote(message,  incomingOrdersInfo[activeOrderIn])
                                    :
                                    null
                                }
                                {processMessageText(message.text)}
                                {message.links?.length
                                    ?
                                    <div className={"white-back margin-top-10"}>
                                        {linkRoller(message.links)}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                                <img
                                    className={"private-arrow-svg arrows"}
                                    src={SvgAnswer}
                                    alt="Ответить"
                                    width={"25px"}

                                    onClick={(e) => {
                                        answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                    }}
                                />

                        </div>
                            <div>
                                {newMessagesLine && index !== 0 ?
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                            </div>
                            {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        </div>)
                        //если сообщение от модератора
                    } else if (
                        message.userId._id !== store.user.id && !message.moderator
                    ) {


                        //убираем влажок о рисовании линии НОВЫЕ СООБЩЕНИЯ, это делаем на каждой итерации
                        newMessagesLine = false

                        //проверяем что айди юзера есть в массиве тех кто прочитал данное сообщение
                        //или что в оперативном хранилище прочитанных тоже есть инфа о прочтении
                        //и что активирован флаг о необходимости линии НОВЫЕ СООБЩЕНИЯ
                        if ((message.read.includes(store.user.id) || readMessagesIn.current[activeOrderInRef.current]?.includes(message._id))
                            && newMessageLineSwitch) {
                            //активируем флажок показа линии НОВЫЕ СООБЩЕНИЯ в данном сообщении
                            newMessagesLine = true
                            //отключаем флажок о необходимости таковой линии в будущем
                            newMessageLineSwitch = false
                        }

                        //проверяем если
                        if (
                            //в массиве прочитавших сообщения нашего айдишника нет
                            !message.read.includes(store.user.id)
                            //и сообщение не от самого себя
                            && message.userId._id !== store.user.id
                            //и флажок что в этом раззвертывании нужна линия НОВЫЕ СООБЩЕНИЯ
                            && needNewMessageLineIn.current
                            //и что флажок отмены ее поиска еще не активирован
                            && !stopSearching) {
                            console.log("ЭТОТ МАРКЕРР !")
                            //далее проверяем что в оперативном хранилище так же нет сведений о том что мы это сообщение прочитали
                            if (!readMessagesIn.current[activeOrderInRef.current]?.includes(message._id)) {
                                console.log("Свитч здесь 2")
                                //и если условия выполнены, то активируем флаг
                                //о необходимости нарисовать в этом развертывании линию НОВЫЕ СООБЩЕНИЯ
                                newMessageLineSwitch = true
                                //активируем флаг о прекращении поиска этой линии в будущем
                                stopSearching = true
                                console.log("NOW OFF")
                                //а так же отключаем необходимость рисовании линии НОВЫЕ СООБЩЕНИЯ
                                //в дальнейших развертываниях (в активном окне)
                                needNewMessageLineIn.current = false
                            }
                        }

                        // index++
                        messagesIn.push(<div className={"work-chat-main-message-wrapper"} key={message._id}>
                            <div className={"work-chat-main-message-wrapper"}>
                            <div className={"work-message-wrapper-center"}>
                            <div
                                className={"chat-message chat-center"}
                                id={message._id}
                                data-read={message.read?.includes(store.user.id) || false}
                            >
                                <div className={"chat-username"}>Модератор</div>
                                {dateLook(message.date)}
                                {message.quote
                                    ?
                                    processQuote(message,  incomingOrdersInfo[activeOrderIn])
                                    :
                                    null
                                }
                                {message.text}
                            </div>

                        </div>
                            <img
                                className={"private-arrow-svg arrows"}
                                src={SvgAnswer}
                                alt="Ответить"
                                width={"25px"}

                                onClick={(e) => {
                                    answerOnMessage(message.text, message._id, message.date, false, message.quote, message.userId._id)
                                }}
                            />

                        </div>
                            <div>
                                {newMessagesLine && index !== 0 ?
                                    <h6 className="private-chat-hr new-message-line">↓ НОВЫЕ СООБЩЕНИЯ ↓</h6> : null}
                            </div>
                            {horizontal && index !== 0 && !newMessagesLine ? <h6 className="ws-chat-hr">{date.toLocaleDateString()}</h6> : null}
                        </div>)
                    } else {
                        console.log("THERE IS ELSE")
                        console.log(message)
                    }

                    index++


                    //})


    }}}
        //закидываем размапленные сообщения в стейт для развертывания

        setMessagesToRollIn(messagesIn)


    }, [activeOrderIn, incomingOrdersMessages, slicerIn])





    //этот эффект нужен для корректного отображения инфы о заказе слева от чатика
    //он срабатывает на изменение любого активного заказа, либо на изменение режима работы (атор/заказчик)
    useEffect(() => {


        //если режим ЗАКАЗЧИК и есть активный ИСХОДЯЩИЙ заказ
        if ((mode === "creator"  || (mode === "all" && subMode === "creator")) && activeOrderOut) {
            //то создаем пустой массив
            const results = []
            //и в него закидываем хтмл конструкции, да, так можно
            results.push(
                <div className={"mc-result-info-container"}>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left"}>Заказ #{outgoingOrdersInfo[activeOrderOut]?.number}:</h5>
                        <h6 className={"information-right"}>{outgoingOrdersInfo[activeOrderOut]?.type}</h6>
                        <h6 className={"information-right"}>"{outgoingOrdersInfo[activeOrderOut]?.title?.slice(0, 100)}{outgoingOrdersInfo[activeOrderOut]?.title?.length > 100 ? "..." : null}"</h6>
                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left"}>Срок до:</h5>
                        <h6 className={"information-right"}>{outgoingOrdersInfo[activeOrderOut]?.closestDate ? "Ближайшая дата автора" : new Date(outgoingOrdersInfo[activeOrderOut]?.deadline).toLocaleDateString()}</h6>
                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left"}>Стоимость:</h5>
                        <h6 className={"information-right"}>{`${outgoingOrdersInfo[activeOrderOut]?.price ? outgoingOrdersInfo[activeOrderOut]?.price + " ₽" : "не утверждена"}`} </h6>
                    </div>

                    <Link className={"to-order-link"} target={"_blank"} to={`/order-details/${outgoingOrdersInfo[activeOrderOut]?._id}`}>
                        <button className={"btn primary-btn-color mc-to-order-btn"}>К заказу</button>
                    </Link>
                    {/*<img*/}
                    {/*    className={"dont-drag search-lens-messages pointer margin-left-20"}*/}
                    {/*    src={soundOutOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*    alt={soundOutOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*    title={soundOutOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*    width={"25px"}*/}
                    {/*    onClick={() => {*/}
                    {/*        console.log("CLICK SOUND 1")*/}
                    {/*        console.log(typeof soundOutOn)*/}
                    {/*        console.log("Setting to - ", !soundOutOn)*/}
                    {/*        localStorage.setItem(`sound_work_out`, !soundOutOn);*/}
                    {/*        if(!soundOutOn) {*/}
                    {/*            console.log("PLAY 3")*/}
                    {/*            playSound()*/}
                    {/*        }*/}
                    {/*        setSoundOutOn(!soundOutOn)*/}
                    {/*    }}*/}
                    {/*/>*/}

                </div>
            )
            //потом всё это созраняем в стейт для отображения
            setCurrentOrderInfo(...results)

            //а если режи ЗАКАЗЧИК и активного заказа нет, то обнуляем инфу и ставим заглушку
        } else if (mode === "creator" || (mode === "all" && subMode === "creator")) {
            // setCurrentOrderInfo(<p>Здесь будет отображаться информация об активном заказе</p>)
            setCurrentOrderInfo()
        }

        //если режим ИСПОЛНИТЕЛЬ/АВТОР и есть активный ВХОДЯЩИЙ заказ
        if ((mode === "performer" || (mode === "all" && subMode === "performer")) && activeOrderIn) {
            //создаем пустой массив
            const results = []
            //и в него закидываем хтмл конструкции, да, так можно
            results.push(
                <div className={"mc-result-info-container"}>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left mc-info-list"}>Заказ #{incomingOrdersInfo[activeOrderIn]?.number}:</h5>
                        <h6 className={"information-right"}>{incomingOrdersInfo[activeOrderIn]?.type}</h6>
                        <h6 className={"information-right"}>"{incomingOrdersInfo[activeOrderIn]?.title.slice(0, 100)}{incomingOrdersInfo[activeOrderIn]?.title.length > 100 ? "..." : null}"</h6>
                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left"}>Заказчик:</h5>
                        <h6 className={"information-right"}>{incomingOrdersInfo[activeOrderIn]?.creator.name + " " + incomingOrdersInfo[activeOrderIn]?.creator.lastName}</h6>
                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left"}>Срок до:</h5>
                        <h6 className={"information-right"}>{incomingOrdersInfo[activeOrderIn]?.closestDate ? "Ближайшая дата автора" : new Date(incomingOrdersInfo[activeOrderIn]?.deadline).toLocaleDateString()}</h6>

                    </div>
                    <div className={"order-information-mc-helper"}>
                        <h5 className={"information-hard-left mc-info-list"}>Стоимость:</h5>
                        <h6 className={"information-right"}>{`${incomingOrdersInfo[activeOrderIn]?.price ? incomingOrdersInfo[activeOrderIn]?.price + " ₽" : "не утверждена"}`} </h6>
                    </div>

                    <Link className={"to-order-link"} target={"_blank"} to={`/order-details/${incomingOrdersInfo[activeOrderIn]?._id}`}>
                        <button className={"btn primary-btn-color mc-to-order-btn"}>К заказу</button>
                    </Link>
                    {/*<img*/}
                    {/*    className={"dont-drag search-lens-messages pointer margin-left-20"}*/}
                    {/*    src={soundInOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*    alt={soundInOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*    title={soundInOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*    width={"25px"}*/}
                    {/*    onClick={() => {*/}
                    {/*        console.log("CLICK SOUND 2")*/}
                    {/*        localStorage.setItem(`sound_work_in`, !soundInOn);*/}
                    {/*        if(!soundInOn) {*/}
                    {/*            console.log("PLAY 3")*/}
                    {/*            playSound()*/}
                    {/*        }*/}
                    {/*        setSoundInOn(prev => !prev)*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            )
            //кидаем в стейт для отображения на экране
            setCurrentOrderInfo(...results)

            //а если режи ИСПОЛНИТНЛЬ/АВТОР и активного заказа нет, то обнуляем инфу и ставим заглушку
        } else if (mode === "performer" || (mode === "all" && subMode === "performer")) {
            // setCurrentOrderInfo(<p>Здесь будет отображаться информация об активном заказе</p>)
            setCurrentOrderInfo()
        }

    }, [activeOrderIn, activeOrderOut, mode, soundOutOn, soundInOn])


    //функция изменения размера поля ввода текста, она срабатывает каждый раз при изменении его содержимого
    function textAreaAdjust() {
        //задаем максимальную границу высоты в пикселях
        const maxHeight = 300
        //обнуляем стили через ссылку (ref) на область ввода текста
        //этот чтобы высота уменьшилась, если текста стало меньше или если его вообще нет
        textAreaRef.current.style.height = "auto"
        //а этот чтобы скрыть бегунок если он пока не нужен
        textAreaRef.current.style.overflowY = "hidden";

        //теперь если текущая высота возможного скрола не превышает максимальный размер области
        if(textAreaRef.current.scrollHeight <= maxHeight) {

            console.log(textAreaRef.current.scrollHeight)

            //то увеличиваем размер области до размеров возможного скролла
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            //и прокручиваем окно
            //window.scrollTo(0, document.body.scrollHeight)

            //если же возможный скролл превышает допустимую нами высоту
        } else {
            //то делаем высоту области максимально возможной
            textAreaRef.current.style.height = maxHeight + "px";
            //и разрешаем полосу прокрутки
            textAreaRef.current.style.overflowY = "auto";
        }
    }


    const [messageSending, setMessageSending] = useState(false)


    const [messagesOutForNotificator,  setMessagesOutForNotificator] = useState([])

    //функция отправки сообщений в этом чатике
    async function sendMessage() {


        let needNotify = false

        if((modeRef.current === "performer" || (modeRef.current === "all" && subModeRef.current === "performer")) && !incomingOrdersMessages[activeOrderIn].length) {
            needNotify = true
        } else if((modeRef.current === "performer" || (modeRef.current === "all" && subModeRef.current === "performer")) && getMinutesBetweenDates(new Date(incomingOrdersMessages[activeOrderIn][0].date), Date.now()) > 720) {
            needNotify = true
        } else if((modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) && getMinutesBetweenDates(new Date(messagesOutForNotificator.date), Date.now()) > 720) {
            needNotify = true
        }


        setMessageSending(true)


        //если поле воода не пустое, то...
        if (chatValue !== "") {

            //формируем сообщение, формируем его так чтобы его получили те кто должны и не получили те кто не должны)))
            //больше я ничего об этом комментировать не буду
            const msg = {
                isAuthor: mode === "creator" || (mode === "all" && subMode === "creator"),
                to:
                    mode === "creator" || (mode === "all" && subMode === "creator")
                        ?
                        outgoingOrdersInfo[activeOrderOut].moderator && activeUserOut === outgoingOrdersInfo[activeOrderOut].doer._id
                            ?
                            [activeUserOut, outgoingOrdersInfo[activeOrderOut].moderator]
                            :
                            [activeUserOut]
                        :
                        incomingOrdersInfo[activeOrderIn].moderator && store.user.id === incomingOrdersInfo[activeOrderIn].doer
                            ?
                            [incomingOrdersInfo[activeOrderIn].creator._id, incomingOrdersInfo[activeOrderIn].moderator]
                            :
                            [incomingOrdersInfo[activeOrderIn].creator._id],
                userId: {_id: store.user.id, name: store.user.name, lastName: store.user.lastName},
                about: mode === "creator" || (mode === "all" && subMode === "creator") ? outgoingOrdersInfo[activeOrderOut]._id : incomingOrdersInfo[activeOrderIn]._id,
                //userName: store.user.name + " " + store.user.lastName,
                text: chatValue,
                date: Date.now(),
                quote: answeringMessageNative.id ?? null,
                quote_date: answeringMessageNative.date,
                quote_text: answeringMessageNative.text,
                quote_userId: answeringMessageNative.sender,
                need_rolling: answeringMessageNative.quote,
                read: [],
                moderator:
                    mode === "creator" || (mode === "all" && subMode === "creator")
                        ?
                        outgoingOrdersInfo[activeOrderOut].moderator
                        :
                        incomingOrdersInfo[activeOrderIn].moderator,
                event: 'message',
                needNotify: needNotify,

            }



            // console.log(props.activeOrderIn?._id)
            // console.log(props.activeOrderOut?._id)
            //сформированное сообщение постим в БД
            try {
                const response = await $api.post(`/chat_send/${mode === "creator" || (mode === "all" && subMode === "creator") ? outgoingOrdersInfo[activeOrderOut]._id : incomingOrdersInfo[activeOrderIn]._id}`, msg)
                if (response.status >= 400) {
                    console.log("Что то с серваком, ошибка 400+")
                }
            } catch (e) {
                console.log(e)
            }


            //очищаем поле ввода текста
            setChatValue("")
            textAreaRef.current.style.height = "auto"
            setAnsweringMessageNative({text: null, id: null, date: null, self: null, quote: null, sender: null})

            messagesAreaRef.current.scrollTo({
                top: 0, // Прокручиваем до отступа (offsetTop) элемента "new-messages-line"
                //behavior: 'smooth', // Прокручиваем с анимацией
            })

        }


        setMessageSending(false)
    }

    //функция отмечания сообщения как прочитанного, принимает айдишник сообщения ак аргумент
    async function markAsRead(item, message_id) {
        //console.log(`Marging as read - ${message_id}`)

        if(item === "all") {


            // это запрос на изменение счетчика непрочитанных сообщений в базе данных, в табличке информации о юзерах
            const response = await $api.post('/mark_work_message_as_read',
                {
                    user_id: store.user.id,
                    message_id: message_id,
                    order_id: (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) ? activeOrderOutRef.current : activeOrderInRef.current,
                    direction: (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) ? "out" : "in",
                    all: true,
                    sender: (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) ? activeUserOutRef.current : incomingOrdersInfo[activeOrderInRef.current].creator._id,
                    reader: store.user.id
                }
            )

            // await store.setWorkUnreadCounterIn(store.workUnreadCounterIn - response.data)
            // localStorage.setItem('ls-workUnreadCounterIn', store.workUnreadCounterIn.toString())

            if((modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator"))) {

                await store.setWorkUnreadCounterOut(store.workUnreadCounterOut - response.data)
                localStorage.setItem('ls-workUnreadCounterOut', store.workUnreadCounterOut.toString())

                await setUnreadOrdersOut(prev => {
                    //сначала создаем новый массив с непрочитанными юзерами путем фильтрации, выкидывая от туда активного
                    const newArray = prev[activeOrderOut]?.filter(id => id !== activeUserOut && outgoingOrdersInfo[activeOrderOutRef.current].moderator !== id)
                    //дальше если этот массив не пустой...

                    if(newArray?.length) {
                        //возвращаем старое состояние стейта с обновлением ключа активного заказа на новый массив
                        return {
                            ...prev,
                            [activeOrderOut]: newArray
                        }
                        //а если массив опустел, то...
                    } else {
                        //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                        const { [activeOrderOut]: deletedKey, ...updatedState } = prev;
                        //и возвразаем то что получилось обновляя стейт
                        return updatedState;
                    }
                })


            } else {

                await store.setWorkUnreadCounterIn(store.workUnreadCounterIn - response.data)
                localStorage.setItem('ls-workUnreadCounterIn', store.workUnreadCounterIn.toString())

                console.log("SET UNREAD IN 4")
                await setUnreadOrdersIn(prev => {
                    const newState = prev.filter(id => id !== activeOrderIn)
                    return newState
                })
            }




        } else {
            message_id = item



            console.log(readMessagesOut.current)

            //если режим исполнителя активен...
            if((modeRef.current === "performer" || (modeRef.current === "all" && subModeRef.current === "performer"))) {

                if(readMessagesIn.current[activeOrderInRef.current]) {
                    readMessagesIn.current[activeOrderInRef.current].push(message_id)
                } else {
                    readMessagesIn.current[activeOrderInRef.current] = [message_id]
                }

                //...то уменьшаем счетчик сообщений по ВХОДЯЩИМ заказам в store
                await store.setWorkUnreadCounterIn(store.workUnreadCounterIn - 1)
                localStorage.setItem('ls-workUnreadCounterIn', store.workUnreadCounterIn.toString())

                //и если окошко просмотра сообщений находится в нижнем положении или очень близко к нему...
                if(messagesAreaRef.current.scrollTop > -50) {
                    //..то выкидываем айдишник активного входящего заказа из стейта непрочитанных, чтобы погасить
                    //мигающий конверт
                    console.log("SET UNREAD IN 5")
                    await setUnreadOrdersIn(prev => {
                        const newState = prev.filter(id => id !== activeOrderIn)
                        return newState
                    })
                }

            }

            //если же активен режим заказчика...
            if((modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator"))) {


                if(readMessagesOut.current[activeOrderOutRef.current]) {
                    readMessagesOut.current[activeOrderOutRef.current].push(message_id)
                } else {
                    readMessagesOut.current[activeOrderOutRef.current] = [message_id]
                }

                //то уменьшаем счетчик сообщений по исходящим заказам
                await store.setWorkUnreadCounterOut(store.workUnreadCounterOut - 1)
                localStorage.setItem('ls-workUnreadCounterOut', store.workUnreadCounterOut.toString())

                console.log(messagesAreaRef.current.scrollTop)

                //и если окошко просмотра сообщений находится в нижнем положении или около него...
                if(messagesAreaRef.current.scrollTop > -50) {

                    console.log("сейчас маркер нужно убрать")
                    //то активируем логику отключения мигающего конвертика
                    await setUnreadOrdersOut(prev => {
                        //сначала создаем новый массив с непрочитанными юзерами путем фильтрации, выкидывая от туда активного
                        const newArray = prev[activeOrderOut]?.filter(id => id !== activeUserOut && outgoingOrdersInfo[activeOrderOutRef.current].moderator !== id)
                        //дальше если этот массив не пустой...

                        if(newArray?.length) {
                            //возвращаем старое состояние стейта с обновлением ключа активного заказа на новый массив
                            return {
                                ...prev,
                                [activeOrderOut]: newArray
                            }
                            //а если массив опустел, то...
                        } else {
                            //удаляем такой ключ из стейта непрочитанных ИСХОДЯЩИХ заказов вот этой строкой
                            const { [activeOrderOut]: deletedKey, ...updatedState } = prev;
                            //и возвразаем то что получилось обновляя стейт
                            return updatedState;
                        }
                    })
                }


        }


            const synchroMessage = {
                to: store.user.id,
                numberIn: Number(store.workUnreadCounterIn),
                numberOut: Number(store.workUnreadCounterOut),
                event: "synchro_work"
            }
            await notifySocket.current.send(JSON.stringify(synchroMessage));

        }


        // это запрос на изменение счетчика непрочитанных сообщений в базе данных, в табличке информации о юзерах
        const response = await $api.post('/mark_work_message_as_read',
            {
                user_id: store.user.id,
                message_id: message_id,
                order_id: (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) ? activeOrderOutRef.current : activeOrderInRef.current,
                direction: (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) ? "out" : "in",
                all: false,
                sender: (modeRef.current === "creator" || (modeRef.current === "all" && subModeRef.current === "creator")) ? activeUserOutRef.current : incomingOrdersInfo[activeOrderInRef.current].creator._id,
                reader: store.user.id

            }
        )

        console.log(response.data)
    }


    function cancelAnswering() {
        console.log("SET 3")
        setAnsweringMessageNative({text: null, id: null, date: null, self: null, links: null, quote: null, sender: null})
    }



    async function downArrowAction() {
        messagesAreaRef.current.scrollTo({top: 0, behavior: 'smooth'})
        markAsRead("all")

    }


    return (
        <div className={"new-center-main-wrapper"}>
            {/*<div className={"new-center-shadow-wrapper"}>*/}
            <div className={"new-center-main"}>

                <div className={'message-center-header'}>


                    <div className={"new-center-in-out-switch-button-container"}>



                    </div>


                </div>

                <div className={"new-center-middle-area"}>

                    <div
                        className={"message-center-order-list-wrapper"}
                        id={"message-center-order-list-wrapper"}
                    >
                        <select
                            className={"mc-mode-selector"}
                            id={"mc-mode-selector"}
                            onClick={(e) => {
                                setMode(e.target.value)
                            }}
                        >
                            <option value={"all"}>Все диалоги</option>
                            <option value={"creator"}>Заказчик</option>
                            <option value={"performer"}>Исполнитель</option>
                        </select>
                        <div
                            className={"new-center-order-list-container"}
                            id={"new-center-order-list-container"}

                        >
                            {/*<button*/}
                            {/*    id={'beru'}*/}
                            {/*    className={`btn mc-choice-btn ${mode === "performer" ? "primary-btn-color" : "secondary-btn-color-border"}`}*/}
                            {/*    onClick={() => {*/}
                            {/*        //     console.log("CLICK АВТОР")*/}
                            {/*        setMode("performer")*/}
                            {/*        store.setMessageCenterMode("performer")*/}
                            {/*    }}*/}
                            {/*>{(mode === "creator" || !mode) && store.workUnreadCounterIn > 0*/}
                            {/*    ?*/}
                            {/*    <span*/}
                            {/*        className={"notify-counter-red-border-3 inline"}*/}
                            {/*    >{store.workUnreadCounterIn}*/}
                            {/*    </span>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}Исполнитель {mode === "performer" ? " ✓" : null}*/}
                            {/*</button>*/}

                            {/*<button*/}
                            {/*    id={'otdam'}*/}
                            {/*    className={`btn mc-choice-btn ${mode === "creator" ? "primary-btn-color" : "secondary-btn-color-border"}`}*/}
                            {/*    onClick={() => {*/}
                            {/*        setMode("creator")*/}
                            {/*        store.setMessageCenterMode("creator")*/}
                            {/*    }}*/}
                            {/*>{(mode === "performer" || !mode) && store.workUnreadCounterOut > 0*/}
                            {/*    ?*/}
                            {/*    <span*/}
                            {/*        className={"notify-counter-red-border-3 inline"}*/}
                            {/*    >{store.workUnreadCounterOut}*/}
                            {/*    </span>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}Заказчик {mode === "creator" ? " ✓" : null}*/}
                            {/*</button>*/}


                            {mode === "all"
                                ?
                                allOrdersToRoll.length
                                    ?
                                    allOrdersToRoll
                                    :
                                    loadingDialogues
                                        ?
                                        <div>
                                            <p className={"inline margin-right-10"}>Загружаем диалоги</p>
                                            <img src={require('../img/loading.gif')} width={"25px"}/>
                                        </div>
                                        :
                                        null
                                :
                                null
                            }


                            {mode === "creator"
                                ?
                                outgoingOrdersToRoll.length
                                    ?
                                    outgoingOrdersToRoll
                                    :
                                    <div className={"new-center-info"}>
                                        <p className={"mc-p-hint"}>Здесь будут кнопки диалогов, опубликованных вами заказов.{"\n\n"}
                                            Чтобы они появились нужно чтобы кто-нибудь написал вам первое сообщение.</p>
                                        {store.workUnreadCounterOut > 0
                                            ?
                                            <button
                                                className={"btn btn-warning border-1-gray"}
                                                onClick={() => showUnreadOrders("out")}
                                            >Показать непрочитанные</button>
                                            :
                                            null
                                        }

                                    </div>
                                :
                                null
                            }

                            {mode === "performer"
                                ?
                                incomingOrdersToRoll.length
                                    ?
                                    incomingOrdersToRoll
                                    :
                                    <div className={"new-center-info"}>
                                        <p className={"mc-p-hint"}>Здесь будут кнопки открытия диалогов по заказам, закрепленными за вами,
                                            и по которым вы отправили заявку на выполнение.{"\n\n"}
                                            Чтобы они появились нужно начать диалог на странице одного из заказов.{"\n\n"}
                                            Посмотреть новые заказы можно в <Link to={"/order-feed"}>ленте</Link>.</p>
                                    </div>
                                :
                                null
                            }
                        </div>
                    </div>



                    <div className={"new-center-message-box-container-wrapper"}>
                    <div className={"new-center-message-box-container"}>
                        {showArrowDown
                            ?
                            <div
                                className={`fixed-arrow-moder`}
                                onClick={() => {
                                    console.log("Давайте скроллить!")
                                    downArrowAction()
                                }}
                            >
                                <img src={SvgArrowDown} width={"35"}/>
                            </div>
                            :
                            null
                        }
                        <div
                            className={"new-center-message-box-under"}
                            ref={messagesAreaRef}
                        >
                            <div

                                className={`${(activeOrderIn && (mode === "performer" || mode === "all")) || (activeUserOut && (mode === "creator" || mode === "all"))
                                    ?
                                    (((mode === "performer" || subMode === "performer") && messagesToRollIn?.length < 10)) || (((mode === "creator" || subMode === "creator") && messagesToRollOut?.length < 10))
                                        ?
                                        "new-center-message-box mb-min-height"
                                        :
                                        "new-center-message-box"
                                    :
                                    "new-center-message-box-empty" }`}

                            >
                                {/*{mode === undefined*/}
                                {/*    ?*/}
                                {/*    <div className={"private-plug margin-auto"}>*/}
                                {/*        Нужно выбрать режим работы "Исполнитель" или "Заказчик"*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}
                                {/*{mode === "all" && !activeOrderOut*/}
                                {/*    ?*/}
                                {/*    <div className={"private-plug margin-auto ubuntu"}>*/}
                                {/*        Не выбран ни один диалог*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    !mode || mode === "performer"*/}
                                {/*        ?*/}
                                {/*        null*/}
                                {/*        :*/}
                                {/*        outgoingOrdersInfo*/}
                                {/*            ?*/}
                                {/*            messagesToRollOut*/}
                                {/*            :*/}
                                {/*            <div className={"private-plug margin-auto ubuntu"}>*/}
                                {/*                У вас пока нет переписок по исходящим заказам*/}
                                {/*            </div>*/}
                                {/*}*/}
                                {mode === "all" && !activeOrderOut && !activeOrderIn
                                    ?
                                    allOrdersToRoll.length
                                        ?
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            Не выбран ни один диалог
                                        </div>
                                        :
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            У вас нет начатых диалогов
                                        </div>
                                    :
                                    null
                                }
                                {mode === "creator" && !activeOrderOut
                                    ?
                                    outgoingOrdersToRoll.length
                                        ?
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            Не выбран ни один диалог
                                        </div>
                                        :
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            У вас нет начатых диалогов по исходящим заказам
                                        </div>
                                    :
                                    mode === "performer" || (mode === "all" && subMode === "performer")
                                        ?
                                        null
                                        :
                                        outgoingOrdersInfo
                                            ?
                                            messagesToRollOut
                                            :
                                            <div className={"private-plug margin-auto ubuntu"}>
                                                У вас пока нет переписок по исходящим заказам
                                            </div>
                                }
                                {mode === "performer" && !activeOrderIn
                                    ?
                                    incomingOrdersToRoll.length
                                        ?
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            Не выбран ни один диалог
                                        </div>
                                        :
                                        <div className={"private-plug margin-auto ubuntu"}>
                                            У вас нет начатых диалогов по входящим заказам
                                        </div>
                                    :
                                    mode === "creator" || (mode === "all" && subMode === "creator")
                                        ?
                                        null
                                        :
                                        incomingOrdersInfo
                                            ?
                                            messagesToRollIn
                                            :
                                            <div className={"private-plug margin-auto ubuntu"}>
                                                У вас пока нет переписок по входящим заказам
                                            </div>
                                }

                            </div>
                        </div>
                    </div>
                        <div className={"new-center-answering-n-input-container"}>
                            {answeringMessageNative.text
                                ?
                                <h6 className={"margin-top-5"}>В ответ на:</h6>
                                :
                                null
                            }
                            {answeringMessageNative.text
                                ?
                                <div className={"answering-message-wrapper-message-center"}>
                                    <div className={"answering-message"}>
                                        {processMessageText(textReducer(answeringMessageNative.text))}
                                    </div>
                                    <button
                                        className={"btn inline margin-left-20 cancel-answering-button"}
                                        onClick={() => cancelAnswering()}
                                    >❌</button><br/><br/>
                                </div>

                                :
                                null
                            }
                        </div>


                    </div>

                    <div className={"new-center-order-info-container"}>

                        <div className={"new-center-info"}>
                            {currentOrderInfo}
                        </div>
                    </div>


                </div>



                {(mode === "all" && (activeUserOut || activeOrderIn)) || (mode === "creator" && activeOrderOut) || (mode === "performer" && activeOrderIn)
                    ?



                        <div className={"new-center-input-container-wrapper"}>


                            <div className={"new-center-input-container"}>

                                <div className={"message-center-textarea-n-close-button-container"}>
                            <textarea
                                ref={textAreaRef}
                                rows={1}
                                type={"text"}
                                className={"chat-input-mc"}
                                placeholder={"Начните что-то писать..."}

                                onKeyUp={(key) => {
                                    if (key.code === "ShiftLeft" || key.code === "ShiftRight") {
                                        setShiftEnterPressed(false);

                                    }
                                }}

                                onKeyDown={async (key) => {

                                    if (key.shiftKey) {
                                        setShiftEnterPressed(true);
                                    }


                                    if (key.code === "Enter" && shiftEnterPressed) {
                                        key.preventDefault()
                                        if(chatValue.length > 0 && chatValue !== "\n") {
                                            await setChatValue(prev => prev + "\n")
                                        }
                                        textAreaAdjust()

                                    } else if (key.code === "Enter" || key.code === "NumpadEnter") {
                                        key.preventDefault()

                                        if(!messageSending) {
                                            sendMessage()
                                        }

                                    } else {
                                        if(key.code !== "Enter" && key.code !== "NumpadEnter") {
                                            console.log("CASE 23")
                                            //textAreaAdjust()

                                        }
                                    }
                                }
                                }
                                value={chatValue}
                                onChange={e => {

                                    if(chatValue === "" && e.target.value === "\n") {
                                        ;
                                    } else {
                                        setChatValue(e.target.value)
                                        //textAreaAdjust()
                                    }
                                }}
                            />
                                    <img
                                        src={SvgSend}
                                        width={"30px"}
                                        className={"pointer dont-drag margin-left-10"}
                                        onClick={() => {
                                            if(!messageSending) {
                                                sendMessage()
                                            }
                                        }}
                                    />
                                    {/*<button*/}
                                    {/*    className={"btn chat-send-btn"}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        if(!messageSending) {*/}
                                    {/*            sendMessage()*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*>Отправить*/}
                                    {/*</button>*/}
                                </div>
                                <p className={"hints-on-blue mc-search-hint"}>Новая строка Shift+Enter</p>
                            </div>

                        </div>

                    :
                    null
                }

                {/*<div><h3 className={"red"}>{props.errorMessage.show ? props.errorMessage.text : null}</h3></div>*/}
            </div>
            {/*<div className={"new-center-footer"}>*/}

            {/*</div>*/}
            {/*</div>*/}


            <NestedMessageModal
                active={activeMessageModal}
                setActive={setActiveMessageModal}
                message={nestedContent}
                dateSet={dateLook}
                // renderForwardedMessages={renderForwardedMessages}
                showNestedMessage={showNestedMessage}
                //linkRoller={linkRoller}
                processMessageText={processMessageText}
                lastSteps={lastSteps}
                setLastSteps={setLastSteps}
                stepCounter={stepCounter}
                setStepCounter={setStepCounter}

                workMessages={true}
                moderator={outgoingOrdersInfo[activeOrderOut]?.moderator}
                user_id={store.user.id}
            />

            <ModalDialog
                active={activeFileDoesntExist}
                setActive={setActiveFileDoesntExist}
                action={() => {;}}
                // redMessage={`Вы считаете что по заказу #`}
                message={`Этот файл больше не существует`}
                purpose={"info"}
            />


            <ModalImgViewer
                active={activeModalImgViewer}
                setActive={setActiveModalImgViewer}
                action={() => {;}}
                imgSrc={imageSource}
                setImgSrc={setImageSource}
            />


        </div>


    );
};

export default observer(NewMessageCenter);