import React from 'react';
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import FileViewer from 'react-file-viewer';
import {API_URL} from "../../http";
import SvgRedCross from "../../img/red-cross.svg";




const DocViewerComponent = ({path, filetype, filename, close}) => {


    // Формируем URL для перенаправления без GET-параметров
    const redirectUrl = `${API_URL}/download_file?path=${path}`;

    console.log(redirectUrl)


    return (
        <div className={"back-of-doc-viewer"}>
            <div className={"fileviewer-top"}>
                <h3 className={"p-doc-viewer-title margin-0"}>{filename}</h3>
                <img
                    className={"red-cross-close-fileviewer"}
                    src={SvgRedCross} width={"25px"}
                    onClick={() => close()}
                    title={"Удалить вложение"}
                />
            </div>


            <iframe
                className={filetype}
                width="100%"
                height="95%"
                src={`https://docs.google.com/gview?url=${redirectUrl}&embedded=true`}

            ></iframe>
        </div>
    );
};

export default DocViewerComponent;