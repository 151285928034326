import $api from '../http';


class AuthService {
    static async login(email, password) {
        return $api.post('/login', {email, password})

    }

    static async registration(email, password, invite) {
        return $api.post('/register', {email, password, invite})

    }

    static async logout() {

        //этот таймаут нужен потому что файрфокс не успевает выйти из учетки, не спрашивайте как я это понял
        setTimeout(() => {
            return $api.post('/logout')
        }, 1000)


    }

    static async viryPhone(phone, code, user_id, method) {
        console.log("veryPhone called")
        return $api.post('/phone', {phone, code, user_id, method})
    }

}


export default AuthService;