import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {Context} from "../index";
import {observer} from 'mobx-react-lite';
import {autorun} from 'mobx';
import $api from "../http";
import {toJS} from "mobx";
import SvgSoundOn from "../img/sound-on-white.svg";
import SvgSoundOff from "../img/sound-off-white.svg";
import SvgRedDot from "../img/red-dot.svg"


const Header = ({isMobile, notifySocket, notifyChatSynchronizer}) => {

    const {store} = useContext(Context)


    const [showNotifierOnButton, setShowNotifierOnButton] = useState(true)
    const [showEventsOnButton, setShowEventsOnButton] = useState(true)

    const [invocationCounter, setInvocationCounter] = useState(0)





    const [chatId, setChatId] = useState("")

    const [initialSoundOn, setInitialSoundOn] = useState(false)
    const [soundOn, setSoundOn] = useState(initialSoundOn);
    const message_notification_sound_wschat = new Audio('/sounds/message1.mp3')
    const event_message_notification_sound = new Audio('/sounds/message2.mp3')
    const message_notification_sound_work = new Audio('/sounds/message3.mp3')


    const storedSoundEventOn = localStorage.getItem(`sound_events`);
    const initialSoundEventOn = storedSoundEventOn ? JSON.parse(storedSoundEventOn) : true;

    const [soundEventOn, setSoundEventOn] = useState(initialSoundOn);


    const [soundOutOn, setSoundOutOn] = useState(localStorage.getItem(`sound_work_out`) === "true");
    const [soundInOn, setSoundInOn] = useState(localStorage.getItem(`sound_work_in`) === "true");


    const [allChatUnreadCounter, setAllChatUnreadCounter] = useState(0)

    useEffect(() => {

        console.log(toJS(store.chatList))

        if(true) {
            let messageCounter = 0

            messageCounter += store.privateUnreadCounter

            if(store.invocations['admin']?.length) {
                messageCounter += store.invocations['admin']?.length
            }
            if(store.invocations['arbitr']?.length) {
                messageCounter += store.invocations['arbitr']?.length
            }
            if(store.invocations['disput']?.length) {

                messageCounter += store.invocations['disput']?.length
            }

            for(let chat of store.chatList) {
                if(store.invocations[chat.chat_id]?.length) {
                    messageCounter += store.invocations[chat.chat_id]?.length
                }


            }



            setAllChatUnreadCounter(messageCounter)

        }

    }, [store.chatList, store.invocationUpdater, store.privateUnreadCounter])



    useEffect(() => {
        console.log("chatId changed")

        if(store.pageId === "ws-chat") {
            const storedSoundOn = localStorage.getItem(`sound_${chatId}`);
            setSoundOn(storedSoundOn ? JSON.parse(storedSoundOn) : true)
        }


        if(store.pageId === "message-center") {
            const storedSoundIn = localStorage.getItem(`sound_work_in`) === "true"
            setSoundOutOn(storedSoundIn)
            const storedSoundOut = localStorage.getItem(`sound_work_out`) === "true"
            setSoundOutOn(storedSoundOut)
        }


    }, [chatId, store.pageId])



    function playSoundEvent() {
        event_message_notification_sound.play()
        new Notification('Тихое уведомление', { silent: true });
    }

    function playSoundWsChat() {
        message_notification_sound_wschat.play()
        new Notification('Тихое уведомление', { silent: true });
    }

    function playSoundWork() {
        message_notification_sound_work.play()
        new Notification('Тихое уведомление', { silent: true });
    }

    useEffect(() => {
        console.log("Setting chat id to - ", toJS(store.chatId))
        setChatId(store.chatId)
    }, [store.chatId])


    // function calculateInvocations() {
    //
    //     let result = 0
    //     for(let chat_array in toJS(store.invocations)) {
    //         result += store.invocations[chat_array].length
    //     }
    //
    //     setInvocationCounter(result)
    // }
    //
    // useEffect(() => {
    //     calculateInvocations()
    //
    // }, [store.invocationUpdater])




    function generateFavicon(number) {

        if(number < 1) {
            return "/favicon-32x32.png"
        }

        let backgroundColor = '#ffb703';
        backgroundColor = encodeURIComponent(backgroundColor);
        let textColor = 'black';

        // Если число больше 99, отображаем символ "∞"
        let faviconText = number <= 99 ? number.toString() : '∞';

        return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><rect x="0" y="0" width="32" height="32" rx="4" ry="4" style="fill:${backgroundColor};"/><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" style="fill:${textColor};" font-family="Ubuntu, sans-serif" font-size="20">${faviconText}</text></svg>`
    }


// Функция для обновления фавикона
    async function updateFavicon(number) {
         const favicon = document.querySelector("link[rel='icon']");
         favicon.href = generateFavicon(number)
    }


    const [isTabActive, setIsTabActive] = useState(true);

    const [blinkerTimer, setBlinkerTimer] = useState()



    useEffect(() => {

        const counter = store.eventCounter + store.privateUnreadCounter + store.workUnreadCounterIn + store.workUnreadCounterOut + store.workUnreadCounterModer + invocationCounter

        let timer

        if(isTabActive) {
            clearInterval(blinkerTimer)
            if(store.whitePointer.place) {
                document.title = store.whitePointer.place
            } else {
                document.title = "Ultima Thule"
            }
            updateFavicon(0)
        } else {
            clearInterval(blinkerTimer)



            if(counter > 0) {



                timer = setInterval(() => {

                    if(document.title === store.whitePointer.place) {
                        document.title = ` новых уведомлений`;
                        updateFavicon(counter)
                    } else {
                        if(store.whitePointer.place) {
                            document.title = store.whitePointer.place
                        } else {
                            document.title = "Ultima Thule"
                        }

                        updateFavicon(0)
                    }
                }, 2000)

                setBlinkerTimer(timer)
            }
        }

        return () => clearInterval(timer);


    }, [isTabActive, store.privateUnreadCounter, store.eventCounter, store.workUnreadCounterIn, store.workUnreadCounterOut, store.workUnreadCounterModer, invocationCounter, store.whitePointer])






    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setIsTabActive(false);
            } else {
                setIsTabActive(true);
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Очистка слушателя при размонтировании компонента
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);






    useEffect(() => {


        document.addEventListener('click', function (e) {
                if (e.target.id != 'button1' && e.target.id != 'button11' && store.user.name) {
                    let linker = document.querySelector('#list1')
                    linker?.classList.remove('show-dropdown')
                }
                if (e.target.id != 'button2' && e.target.id != 'button22' && store.user.name) {
                    let linker = document.querySelector('#list2')
                    setShowNotifierOnButton(true)
                    linker?.classList.remove('show-dropdown')

                }
                if (e.target.id != 'button3' && e.target.id != 'button33' && store.isAuth) {
                    let linker = document.querySelector('#list3')
                    setShowEventsOnButton(true)
                    linker?.classList.remove('show-dropdown')

                }
            }
        )


    }, [])


    function showDropDown(e) {
        e.preventDefault()
        if ((e.target.id === "button1" || e.target.id === "button11") && store.isAuth) {
            let linker = document.querySelector('#list1')
            linker.classList.toggle('show-dropdown')

        }
        if ((e.target.id === "button2" || e.target.id === "button22" || e.target.id === "button222") && store.isAuth) {
            let linker = document.querySelector('#list2')
            setShowNotifierOnButton(prev => !prev)
            console.log(store.notifyCounter)
            linker.classList.toggle('show-dropdown')
        }
        if ((e.target.id === "button3" || e.target.id === "button33") && store.isAuth) {
            let linker = document.querySelector('#list3')
            setShowEventsOnButton(prev => !prev)
            linker.classList.toggle('show-dropdown')

        }

    }



    function setWhitePointer(text, color) {
        store.editWhitePointer({place: text, color: color})
    }

    return (

        <nav className="header">
            {/*<div className="header-main-container">*/}
                <div className={`text-logo ${isMobile ? "mobile" : null}`}>
                    <Link
                        to={"/"}
                        className={"text-decoration-none text-black"}
                        onClick={() => {
                            store.setPageId("home")
                        }}

                    >Ultima Thule</Link>
                    <span className={`white-pointer ${isMobile ? "mobile" : null} ${
                        store.whitePointer.color
                            ?
                            store.whitePointer.color
                            :
                            "white-color"
                    }`}>
                        {store.whitePointer.place}{store.whitePointer.place === "Лента заказов:" && store.feedBlinker
                        ?
                        <span>
                            <span className={"feed-update-blinker"}> обновлена</span>
                            {window.scrollY > 250
                                ?
                                <span className={"feed-update-blinker"}>🠕</span>
                                :
                                null
                            }</span>
                        :
                        null
                        }</span>
                </div>

                {/*<h4 className={"white-pointer"}>{store.whitePointer}</h4>*/}
                <div className={`helper menu`}>

                    {/*{store.pageId === "events-feed"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundEventOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundEventOn ? "Отключить звук событий" : "Включить звук событий"}*/}
                    {/*        title={soundEventOn ? "Отключить звук событий" : "Включить звук событий"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem(`sound_events`, !soundEventOn);*/}
                    {/*            if(!soundEventOn) {*/}
                    {/*                console.log("PLAY 3")*/}
                    {/*                playSoundEvent()*/}
                    {/*            }*/}
                    {/*            setSoundEventOn(prev =>!prev)*/}
                    {/*        }}*/}
                    {/*     />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {/*{store.pageId === "ws-chat"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        title={soundOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem(`${"sound_"}${chatId}`, !soundOn);*/}
                    {/*            if(!soundOn) {*/}
                    {/*                playSoundWsChat()*/}
                    {/*            }*/}
                    {/*            setSoundOn(prev =>!prev)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {/*{store.pageId === "message-center" && store.messageCenterMode === "performer"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundInOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundInOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        title={soundInOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            console.log("CLICK SOUND 1")*/}
                    {/*            console.log(typeof soundInOn)*/}
                    {/*            console.log("Setting to - ", !soundInOn)*/}
                    {/*            localStorage.setItem(`sound_work_out`, !soundInOn);*/}
                    {/*            if(!soundInOn) {*/}
                    {/*                console.log("PLAY 3")*/}
                    {/*                playSoundWork()*/}
                    {/*            }*/}
                    {/*            setSoundInOn(!soundInOn)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {/*{store.pageId === "message-center" && store.messageCenterMode === "creator"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundOutOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundOutOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        title={soundOutOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            console.log("CLICK SOUND 1")*/}
                    {/*            console.log(typeof soundOutOn)*/}
                    {/*            console.log("Setting to - ", !soundOutOn)*/}
                    {/*            localStorage.setItem(`sound_work_out`, !soundOutOn);*/}
                    {/*            if(!soundOutOn) {*/}
                    {/*                console.log("PLAY 3")*/}
                    {/*                playSoundWork()*/}
                    {/*            }*/}
                    {/*            setSoundOutOn(!soundOutOn)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}{store.pageId === "events-feed"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundEventOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundEventOn ? "Отключить звук событий" : "Включить звук событий"}*/}
                    {/*        title={soundEventOn ? "Отключить звук событий" : "Включить звук событий"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem(`sound_events`, !soundEventOn);*/}
                    {/*            if(!soundEventOn) {*/}
                    {/*                console.log("PLAY 3")*/}
                    {/*                playSoundEvent()*/}
                    {/*            }*/}
                    {/*            setSoundEventOn(prev =>!prev)*/}
                    {/*        }}*/}
                    {/*     />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {/*{store.pageId === "ws-chat"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        title={soundOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            localStorage.setItem(`${"sound_"}${chatId}`, !soundOn);*/}
                    {/*            if(!soundOn) {*/}
                    {/*                playSoundWsChat()*/}
                    {/*            }*/}
                    {/*            setSoundOn(prev =>!prev)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {/*{store.pageId === "message-center" && store.messageCenterMode === "performer"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundInOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundInOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        title={soundInOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            console.log("CLICK SOUND 1")*/}
                    {/*            console.log(typeof soundInOn)*/}
                    {/*            console.log("Setting to - ", !soundInOn)*/}
                    {/*            localStorage.setItem(`sound_work_out`, !soundInOn);*/}
                    {/*            if(!soundInOn) {*/}
                    {/*                console.log("PLAY 3")*/}
                    {/*                playSoundWork()*/}
                    {/*            }*/}
                    {/*            setSoundInOn(!soundInOn)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                    {/*{store.pageId === "message-center" && store.messageCenterMode === "creator"*/}
                    {/*    ?*/}
                    {/*    <img*/}
                    {/*        className={"dont-drag search-lens-messages pointer"}*/}
                    {/*        src={soundOutOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*        alt={soundOutOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        title={soundOutOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*        width={"25px"}*/}
                    {/*        onClick={() => {*/}
                    {/*            console.log("CLICK SOUND 1")*/}
                    {/*            console.log(typeof soundOutOn)*/}
                    {/*            console.log("Setting to - ", !soundOutOn)*/}
                    {/*            localStorage.setItem(`sound_work_out`, !soundOutOn);*/}
                    {/*            if(!soundOutOn) {*/}
                    {/*                console.log("PLAY 3")*/}
                    {/*                playSoundWork()*/}
                    {/*            }*/}
                    {/*            setSoundOutOn(!soundOutOn)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}


                    <div className={`menu__list ${isMobile ? "mobile" : null}`}>
                        {!store.isAuth && store.pageId !== "terms" ?
                            <li>
                                <Link
                                    id="button4"
                                    to={"/terms"}
                                    className={`menu__link ${isMobile ? "mobile" : null} btn btn-secondary btn-main`}
                                    onClick={() => store.setPageId("terms")}
                                >О сервисе</Link>
                            </li>
                            : null}
                        {store.user.name && store.user.lastName && store.user.phone ?
                            <li>
                                <Link
                                    id="button4"
                                    to={"/user_search"}
                                    className={`menu__link ${isMobile ? "mobile" : null} btn btn-secondary btn-main`}
                                    onClick={() => store.setPageId("user-search")}
                                >Люди</Link>
                            </li>
                            : null}
                        {/*{store.user.name && store.user.lastName && store.user.phone ?*/}
                        {/*    <li>*/}
                        {/*        <a*/}
                        {/*            href="#"*/}
                        {/*            onClick={showDropDown}*/}
                        {/*            id="button1"*/}
                        {/*            className={`menu__link ${isMobile ? "mobile" : null} btn btn-secondary btn-main`}*/}
                        {/*        >Новости<span id={"button11"} className="link-arrow">▼</span></a>*/}
                        {/*        <div className={`sub-menu__list ${isMobile ? "mobile" : null}`} id="list1">*/}
                        {/*            <Link onClick={() => setWhitePointer("Погода:")} to={"/weather"} className="sub-menu__link arrow1">Погода</Link>*/}
                        {/*            <Link onClick={() => setWhitePointer("О платформе:")} to={"/about"} className="sub-menu__link arrow1">О платформе</Link>*/}
                        {/*            <Link onClick={() => setWhitePointer("Новости:")} to={"/news"} className="sub-menu__link">Хорошие новости</Link>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*    : null}*/}
                        {store.user.name && store.user.lastName && store.user.phone ?
                            <li>
                                <a href="#"
                                   onClick={showDropDown}
                                   id="button2"
                                   className={`menu__link ${isMobile ? "mobile" : null} btn btn-secondary btn-main`}>
                                    {(store.workUnreadCounterOut > 0 || store.workUnreadCounterIn > 0) && showNotifierOnButton && store.whitePointer.place !== "Центр сообщений:"
                                        ?

                                        <div
                                            id={"button222"}
                                            className={`inline`}
                                            style={{ position: 'relative', display: 'inline-block' }}
                                        >
                                            <img src={SvgRedDot} width={isMobile ? "25px" : "35px"} alt="Red Dot" />
                                            <span style={{ position: 'absolute', top: '50%', left: '47%', transform: 'translate(-50%, -50%)', color: 'white', fontWeight: "bold", fontSize: '12px' }}>
                                                    {store.workUnreadCounterOut + store.workUnreadCounterIn <= 99 ? store.workUnreadCounterOut + store.workUnreadCounterIn : "99+"}
                                                </span>
                                        </div>
                                        :
                                        null
                                    } Работа<span id={"button22"} className="link-arrow">▼</span>
                                </a>
                                <div className={`sub-menu__list ${isMobile ? "mobile" : null}`} id="list2">
                                    <Link
                                        onClick={() => store.setPageId("create-order")}
                                        to="/place-new-order" className="sub-menu__link">Создать
                                    </Link>
                                    <Link
                                        onClick={() => store.setPageId("order-feed")}
                                        to="/order-feed" className="sub-menu__link">Лента заказов
                                    </Link>
                                    <Link
                                        onClick={() => store.setPageId("outgoing-feed")}
                                        to="/outgoing" className="sub-menu__link"
                                    >
                                        Отдаю</Link>
                                    <Link
                                        onClick={() => store.setPageId("incoming-feed")}
                                        to="/incoming" className="sub-menu__link"
                                    >
                                        Беру</Link>
                                    <Link
                                        onClick={() => store.setPageId("message-center")}
                                        to={"/new_message_center"}
                                        className="sub-menu__link"
                                    >
                                        {(store.workUnreadCounterOut > 0 || store.workUnreadCounterIn > 0)  && store.whitePointer.place !== "Центр сообщений:"
                                            ?
                                            <div
                                                style={{ position: 'relative', display: 'inline-block' }}
                                            >
                                                <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />
                                                <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>
                                                    {store.workUnreadCounterOut + store.workUnreadCounterIn <= 99
                                                        ?
                                                        store.workUnreadCounterOut + store.workUnreadCounterIn
                                                        :
                                                        "99+"
                                                    }
                                                </span>
                                            </div>
                                            :
                                            null
                                        }Рабочие сообщения</Link>
                                    {/*<Link*/}
                                    {/*    onClick={() => store.setPageId("user-search")}*/}
                                    {/*    to="/user_search"*/}
                                    {/*    className="sub-menu__link">Поиск людей*/}
                                    {/*</Link>*/}
                                </div>
                            </li>
                            : null}

                        {store.isAuth ?
                            <li>
                                <a href="#" onClick={showDropDown} id="button3"
                                   className={`menu__link ${isMobile ? "mobile" : null} btn btn-secondary btn-main`}
                                >
                                    {(store.eventCounter > 0 ||  store.workUnreadCounterModer > 0 || allChatUnreadCounter > 0
                                        > 0 )  && showEventsOnButton
                                        ?

                                            <div
                                                id={"button222"}
                                                className={`inline`}
                                                style={{ position: 'relative', display: 'inline-block' }}
                                            >
                                                <img src={SvgRedDot} width={isMobile ? "25px" : "35px"} alt="Red Dot" />
                                                <span style={{ position: 'absolute', top: '50%', left: '47%', transform: 'translate(-50%, -50%)', color: 'white', fontWeight: "bold", fontSize: '12px' }}>
                                                    {store.eventCounter + store.workUnreadCounterModer + allChatUnreadCounter <= 99
                                                        ?
                                                        store.eventCounter + store.workUnreadCounterModer + allChatUnreadCounter
                                                        :
                                                        "99+"
                                                    }
                                                </span>
                                            </div>


                                        :
                                        null
                                    }
                                    Кабинет<span id={"button33"}
                                                 className="link-arrow">▼</span></a>

                                <div className={`sub-menu__list ${isMobile ? "mobile" : null}`} id="list3">
                                    {store.user.isActivated
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("personsl-data")}
                                            className="sub-menu__link"
                                            id="click"
                                            to="/personal_data"
                                        >
                                            Мои данные
                                        </Link>
                                        :
                                        null
                                    }

                                    {store.user.name && store.user.lastName && store.user.phone
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("messenger")}
                                            to="/messenger"
                                            className="sub-menu__link"
                                        >
                                            {allChatUnreadCounter > 0 && store.pageId !== "messenger"
                                                ?
                                                <div
                                                    style={{ position: 'relative', display: 'inline-block' }}
                                                >
                                                    <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />
                                                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>
                                                        {allChatUnreadCounter <= 99
                                                            ?
                                                            allChatUnreadCounter
                                                            :
                                                            "99+"
                                                        }
                                                     </span>
                                                </div>
                                                :
                                                null
                                            }
                                            Мессенджер
                                        </Link>
                                        :
                                        null
                                    }

                                    {store.user.admin
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("admins-office")}
                                            to="/admin_office"
                                            className="sub-menu__link"
                                        >
                                            Пульт админа

                                        </Link>
                                        :
                                        null
                                    }
                                    {/*{store.user.admin || store.user.maintenance*/}
                                    {/*    ?*/}
                                    {/*    <Link*/}
                                    {/*        to={"/chat/admin"} className="sub-menu__link"*/}
                                    {/*        onClick={() => {*/}
                                    {/*            store.setActiveChat({chat_id: "admin"})*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        {store.invocations['admin']?.length > 0*/}
                                    {/*            ?*/}
                                    {/*            <div*/}
                                    {/*                style={{ position: 'relative', display: 'inline-block' }}*/}
                                    {/*            >*/}
                                    {/*                <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />*/}
                                    {/*                <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>*/}
                                    {/*                    {store.invocations['admin']?.length <= 99*/}
                                    {/*                        ?*/}
                                    {/*                        store.invocations['admin']?.length*/}
                                    {/*                        :*/}
                                    {/*                        "99+"*/}
                                    {/*                    }*/}
                                    {/*                 </span>*/}
                                    {/*            </div>*/}
                                    {/*            :*/}
                                    {/*            null*/}
                                    {/*        }*/}
                                    {/*        Админская*/}
                                    {/*    </Link>*/}
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}
                                    {/*{store.user.arbitr*/}
                                    {/*    ?*/}
                                    {/*    <Link to="/arbitroom" className="sub-menu__link">*/}
                                    {/*        {store.invocations['arbitr']?.length > 0*/}
                                    {/*            ?*/}
                                    {/*            <div*/}
                                    {/*                style={{ position: 'relative', display: 'inline-block' }}*/}
                                    {/*            >*/}
                                    {/*                <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />*/}
                                    {/*                <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>*/}
                                    {/*                    {store.invocations['arbitr']?.length <= 99*/}
                                    {/*                        ?*/}
                                    {/*                        store.invocations['arbitr']?.length*/}
                                    {/*                        :*/}
                                    {/*                        "99+"*/}
                                    {/*                    }*/}
                                    {/*                 </span>*/}
                                    {/*            </div>*/}
                                    {/*            :*/}
                                    {/*            null*/}
                                    {/*        }*/}
                                    {/*        Арбитраж*/}
                                    {/*    </Link>*/}
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}
                                    {store.user.name && store.user.lastName && store.user.phone
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("events-feed")}
                                            to="/events_feed"
                                            className="sub-menu__link"
                                        >
                                            {store.eventCounter > 0
                                                ?
                                                <div
                                                    style={{ position: 'relative', display: 'inline-block' }}
                                                >
                                                    <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />
                                                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>
                                                        {store.eventCounter <= 99
                                                            ?
                                                            store.eventCounter
                                                            :
                                                            "99+"
                                                        }
                                                     </span>
                                                </div>
                                                :
                                                null
                                            }
                                            События
                                        </Link>
                                        :
                                        null
                                    }
                                    {/*{store.user.name && store.user.lastName && store.user.phone*/}
                                    {/*    ?*/}
                                    {/*    <Link to="/chatroom" className="sub-menu__link">*/}
                                    {/*        {store.invocations['disput']?.length > 0*/}
                                    {/*            ?*/}
                                    {/*            <div*/}
                                    {/*                style={{ position: 'relative', display: 'inline-block' }}*/}
                                    {/*            >*/}
                                    {/*                <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />*/}
                                    {/*                <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>*/}
                                    {/*                    {store.invocations['disput']?.length <= 99*/}
                                    {/*                        ?*/}
                                    {/*                        store.invocations['disput']?.length*/}
                                    {/*                        :*/}
                                    {/*                        "99+"*/}
                                    {/*                    }*/}
                                    {/*                 </span>*/}
                                    {/*            </div>*/}
                                    {/*            :*/}
                                    {/*            null*/}
                                    {/*        } Общий чат*/}
                                    {/*    </Link>*/}
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}

                                    {/*{store.chatList.length*/}
                                    {/*    ?*/}
                                    {/*    store.chatList.map(chat => {*/}
                                    {/*        return <Link*/}
                                    {/*                to={`/chat/${chat.chat_id}`}*/}
                                    {/*                className={"sub-menu__link"}*/}
                                    {/*                key={chat.chat_id}*/}
                                    {/*                onClick={() => store.setActiveChat(chat)}*/}
                                    {/*            >*/}
                                    {/*                {store.invocations[chat.chat_id]?.length > 0*/}
                                    {/*                    ?*/}
                                    {/*                    <div*/}
                                    {/*                        style={{ position: 'relative', display: 'inline-block' }}*/}
                                    {/*                    >*/}
                                    {/*                        <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />*/}
                                    {/*                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>*/}
                                    {/*                            {store.invocations[chat.chat_id]?.length <= 99*/}
                                    {/*                                ?*/}
                                    {/*                                store.invocations[chat.chat_id]?.length*/}
                                    {/*                                :*/}
                                    {/*                                "99+"*/}
                                    {/*                            }*/}
                                    {/*                        </span>*/}
                                    {/*                    </div>*/}
                                    {/*                    :*/}
                                    {/*                    null*/}
                                    {/*                }*/}
                                    {/*                Чат {chat.chatName}*/}
                                    {/*            </Link>*/}
                                    {/*    })*/}
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}
                                    {store.user.maintenance
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("moderator-room")}
                                            to="/moderator_room"
                                            className="sub-menu__link"
                                        >
                                            {store.workUnreadCounterModer > 0
                                                ?
                                                <div
                                                    style={{ position: 'relative', display: 'inline-block' }}
                                                >
                                                    <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />
                                                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>
                                                                {store.workUnreadCounterModer <= 99
                                                                    ?
                                                                    store.workUnreadCounterModer
                                                                    :
                                                                    "99+"
                                                                }
                                                            </span>
                                                </div>
                                                :
                                                null
                                            }
                                            Модератор
                                        </Link>
                                        :
                                        null
                                    }
                                    {/*{store.user.name && store.user.lastName && store.user.phone*/}
                                    {/*    ?*/}
                                    {/*    <Link to="/private_messages" className="sub-menu__link">*/}
                                    {/*        {store.privateUnreadCounter > 0*/}
                                    {/*            ?*/}
                                    {/*            <div*/}
                                    {/*                style={{ position: 'relative', display: 'inline-block' }}*/}
                                    {/*            >*/}
                                    {/*                <img src={SvgRedDot} width={isMobile ? "20px" : "35px"} alt="Red Dot" />*/}
                                    {/*                <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: "bold", fontSize: isMobile ? "8px" : '12px' }}>*/}
                                    {/*                            {store.privateUnreadCounter <= 99*/}
                                    {/*                                ?*/}
                                    {/*                                store.privateUnreadCounter*/}
                                    {/*                                :*/}
                                    {/*                                "99+"*/}
                                    {/*                            }*/}
                                    {/*                        </span>*/}
                                    {/*            </div>*/}
                                    {/*            :*/}
                                    {/*            null*/}
                                    {/*        }*/}
                                    {/*        Личные сообщения*/}
                                    {/*    </Link>*/}
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}
                                    {store.user.name && store.user.lastName && store.user.phone
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("cash")}
                                            to="/pay"
                                            className="sub-menu__link"
                                        >Касса</Link>
                                        :
                                        null
                                    }

                                    {store.user.name && store.user.lastName && store.user.phone
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("terms")}
                                            to="/terms"
                                            className="sub-menu__link"
                                        >О сервисе</Link>
                                        :
                                        null
                                    }


                                    {store.user.admin || store.user.maintenance
                                        ?
                                        <Link
                                            onClick={() => store.setPageId("arbitr-archive")}
                                            to="/arbitr_archive"
                                            className="sub-menu__link"
                                        >Архив арбитража</Link>
                                        :
                                        null
                                    }
                                    <div
                                        onClick={async () => {
                                            store.setPageId("")
                                            store.logout()

                                            const message = {
                                                event: 'offline',
                                                user: store.user.id,

                                            }
                                            //await console.log({...store.user}.id)
                                            await notifySocket.current.send(JSON.stringify(message))
                                        }}
                                        className="sub-menu__link"

                                    >Выход</div>
                                </div>

                            </li>
                            : null}
                    </div>


                </div>
            {/*</div>*/}
        </nav>
    );
};

export default observer(Header);