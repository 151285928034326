import React from 'react';
import {Link} from "react-router-dom";

const EmptyMessage = ({message, title, payButton}) => {
    return (

        <div className="empty-message-wrapper and">

            <div className={"white padding-20"}>

                <h4 className="order-title">
                    {title}
                </h4>
                <hr/>
                <div className={"margin-bottom-10"}>
                    {message}
                </div>
                {payButton
                    ?
                    <Link className={"btn btn-primary"} to="/pay">Внести оплату</Link>
                    :
                    null
                }


            </div>

        </div>
    );
};

export default EmptyMessage;