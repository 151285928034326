import React, {useState, useEffect} from 'react';
import {API_URL} from "../../http";


const ModalCreator = ({
                     active,
                     setActive,
                     action,
                     action2,
                     message,
                     redMessage,
                     purpose,
                     parameter1,
                     setParameter1,
                     parameter2,
                     setParameter2,
                     parameter3,
                     setParameter3,
                     alignFlag

}) => {

    useEffect(() => {
        const handlePressEsc = (event) => {
            if (event.code === 'Escape') {
                setActive("progress")
                setTimeout(() => {
                    setActive("no")
                }, 300)
            }
        };

        document.addEventListener('keydown', handlePressEsc);

        return () => {
            document.removeEventListener('keydown', handlePressEsc);
        };
    }, [])


    const pattern = /^[a-zA-Z]*$/;

    const [filter, setFilter] = useState("")
    const [onlyFans, setOnlyFans] = useState(false)

    const [modalStyle, setModalStyle] = useState({})

    //этот эффект меняет стиль главного элемента modal в зависимости от высоты элемента modal-content, так как по умолчанию
    //стоит стиль выравнивания по центру по вертикали, который все ломает, если контент не входит полностью на экран
    useEffect(() => {
        const modalContentHeight = document.querySelector('.modal-content')?.offsetHeight; // Получаем высоту элемента .modal-content
        if (modalContentHeight > 0.85 * window.innerHeight) { // Если высота .modal-content больше 85% высоты окна
            setModalStyle({ alignItems: 'unset', paddingTop: "20vh" }); // Устанавливаем стиль alignItems: safe для .modal
        } else {
            setModalStyle({}); // Иначе сбрасываем стили
        }
    }, [message, onlyFans]);




    return (
        <div className={active === "active"
                                    ?
                                    alignFlag
                                        ?
                                        "modal active"
                                        :
                                        "modal active"
                                        :
                                        active === "progress" ? "modal" : "none"}
             style={modalStyle}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     setActive("no")
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                `${purpose === "fileviewer"
                    ?
                    "modal-100"
                    :
                    
                    "modal-content"
            } active `
                :
                active === "progress" ? "modal-content" : "none"}
                 onClick={(e) => e.stopPropagation()}>



                <p className={"modal-message-creator"}>{message}</p>
                {purpose === "chat-user-list"
                    ?
                    <button
                        title={"Сохранить"}
                        className={"btn btn-warning fake-save-btn"}
                        onClick={() => {
                            setActive("progress")
                            setTimeout(() => {
                                setActive("no")
                            }, 300)
                        }}
                    >💾</button>
                    :
                    null
                }
                {redMessage
                    ?
                    <div className={'modal-message-red margin-bottom-20'}>
                        <p>{redMessage}</p>
                    </div>
                    :
                    null
                }


                {purpose === "chat-creation"
                    ?
                    <div>
                        <input
                            className={"modal-creator-input"}
                            type={'text'}
                            placeholder={"Название чата"}
                            value={parameter1}
                            onChange={(e) => setParameter1(e.target.value)}
                        />
                        <input
                            className={"modal-creator-input"}
                            type={'text'}
                            placeholder={"ID чата (только английские буквы)"}
                            value={parameter2}
                            onChange={(e) =>{
                                setParameter2(e.target.value)
                                if(action2(e.target.value)) {
                                    setParameter3(true)
                                } else {
                                    setParameter3(false)
                                }
                            }}
                            onKeyDown={(e) => {

                                if(e.key !== "Backspace"
                                    && e.key !== "Delete"
                                    && e.key !== "ArrowLeft"
                                    && e.key !== "ArrowRight"
                                    && e.key !== "Shift"
                                    && e.key !== "Home"
                                    && e.key !== "Tab"
                                    && e.key !== "End") {
                                    if (!pattern.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }

                            }}
                        />
                        {parameter3
                            ?
                            <div>Такой ID уже существует</div>
                            :
                            null
                        }

                        <div className={"decision-btn-container"}>

                            <button
                                disabled={parameter1 === "" || parameter2 === "" || parameter3}
                                className={"btn btn-primary decision-btn"}
                                onClick={() => {
                                    action()
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>Создать</button>

                            <button
                                className={"btn btn-danger decision-btn"}
                                onClick={() => {

                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>Отмена</button>
                        </div>
                    </div>
                    :
                    null
                }

                {purpose === "chat-user-list"
                    ?
                    <div>
                        <input
                            className={"margin-bottom-10"}
                            placeholder={"Фильтр"}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <label className={"margin-left-10"}>Только участники</label>
                        <input
                            className={"margin-left-10"}
                            type={"checkbox"}
                            onChange={() => setOnlyFans(prev => !prev)}
                        />

                        {parameter2?.map((user) => {
                            const isUserInChat = parameter1?.chat_users.includes(user.id);
                            const matchesFilter = user.name?.toLowerCase().includes(filter?.toLowerCase()) || user.lastName?.toLowerCase().includes(filter?.toLowerCase());

                            if ((!filter || matchesFilter) && (!onlyFans || isUserInChat) && user.name) {
                                return (
                                    <div key={user.id} className="user-chat-adder-container">
                                        <img className="ws-message-avatar" src={`${API_URL}/public/avatars/${user.id}/avatar.png`} />
                                        <div>{user.name} {user.lastName}</div>
                                        <button
                                            className={`btn admin-btn ${isUserInChat ? 'btn-danger' : 'btn-primary'}`}
                                            onClick={() => isUserInChat ? action2(user.id) : action(user.id)}
                                        >
                                            {isUserInChat ? 'Исключить' : 'Добавить'}
                                        </button>
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                    :
                    null
                }


                {purpose === "chat-delete"
                    ?
                    <div>
                        <input
                            className={"modal-creator-input"}
                            type={'text'}
                            placeholder={"Название чата"}
                            value={parameter1}
                            onChange={(e) => {setParameter1(e.target.value)}}
                        />
                        <div className={"decision-btn-container"}>
                            <button
                                disabled={parameter1 !== parameter2?.chatName}
                                className={"btn btn-danger decision-btn"}
                                onClick={() => {
                                    action(parameter2.chat_id)
                                    setParameter1("")
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>Удалить</button>

                            <button
                                className={"btn btn-secondary decision-btn"}
                                onClick={() => {
                                    setParameter1("")
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>Отмена</button>
                        </div>
                    </div>
                    :
                    null
                }


            </div>
        </div>
    );
};

export default ModalCreator;