import React, {useState, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {useForm} from "react-hook-form";
import ErrorMessage from "./ErrorMessage";


const Registration = () => {

    const [inviteCode, setInviteCode] = useState('');



    //создаем переменную из хука useForm, он используется для валидации форм
    const {
        //эта штука позволит зарегистрировать поле формы (там ниже смотри, всё понятно будет)
        register,
        //это видимо как то отвечает за ошибки, там внизу тоже все это есть
        formState: {errors},
        //это видимо хрень которая отлавливает сабмит и что то делает)))
        handleSubmit,
        clearErrors,
    } = useForm({mode: "onBlur"});//это режим проверки данных только после попытки сабмита, есть и другие если надо


    const {store} = useContext(Context);


    const [requesting, setRequesting] = useState(false)

    async function click() {
        setRequesting(true)
        try {
            const response = await store.registration(store.mail, store.password, inviteCode)

        } catch (e) {
            console.log(e)
        } finally {
            setRequesting(false)
        }


    }




    return (
        <div className={"empty-message-holder"}>

            <form className="registration-form" onSubmit={handleSubmit(click)}>
                <h3>Есть особое приглашение?!</h3><br/>

                {store.isTriedToRegister ? <div><ErrorMessage color="red" message={store.errorMessage}/></div> : null}

                <div className="error-container">{errors?.email && <p className="error-message">{store.mail === "" ? "Введите почту" : "Это не адрес почты"}</p>}</div>
                <input {
                    ...register('email', {required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                       onChange={(e) => {
                           store.setMail(e.target.value)
                           clearErrors("email")
                       }}
                       value={store.mail}
                       placeholder={"email"} type={"text"}/>

                <div className="error-container">{errors?.password && <p className="error-message">{store.password === "" ? "Придумайте пароль" : "Минимум 8 символов"}</p>}</div>
                <input {...register('password', {required: true, minLength: 8})} placeholder="password" onChange={(e) => store.setPassword(e.target.value)} value={store.password} type={"password"}/>

                <div className="error-container">{errors?.invite && <p className="error-message">Нужен пригласительный</p>}</div>
                <input {...register('invite', {required: true})} placeholder="invite-code" onChange={(e) => setInviteCode(e.target.value)} value={inviteCode} type={'text'}/>

                <button className="btn primary-btn-color"  type={'submit'}>
                    Зарегистрироваться
                    {requesting ?<img src={require('../img/loading.gif')} height={"25px"}/> : null}
                </button>
                <button className="btn primary-btn-color main-login" onClick={() => store.setChoice("sign")}>... или войти</button>
                <button
                    className="btn primary-btn-color main-login"
                    onClick={(e) => {
                        e.preventDefault()
                        const link = document.createElement('a')
                        //назначаем элементу собственно ссылку
                        link.href = "https://vk.me/join/wYspiLdxetEHtk2jvBzep74x3TwC32LJDxw="
                        //добавляем то что создали в ДОМ
                        document.body.appendChild(link)
                        //кликаем
                        link.click()
                        //и избавляемся от элемента
                        link.remove()
                    }}
                >Нет приглашения</button>
            </form>
        </div>
    );
};

export default observer(Registration);
