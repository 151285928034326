import React, {useState, useEffect, useContext} from 'react';
import OrderMiniShield from "./OrderMiniShield";
import {Context} from "../index";
import {observer} from 'mobx-react-lite';
import {toJS} from "mobx";
import $api from "../http";
import Dossier from "./Dossier";
import {assignWidth} from "./helpers/repeat_functions";


const IncomingFeed = (props) => {

    const {store} = useContext(Context)

    const [orderList, setOrderList] = useState([])

    const [selectKey1, setSelectKey1] = useState(0)
    const [selectKey2, setSelectKey2] = useState(100)
    const [showSpinner, setShowSpinner] = useState(false)
    const [isFiltered, setIsFiltered] = useState(false)
    const [showEmpty, setShowEmpty]  = useState(false)

    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})

    const [currentAmount, setCurrentAmount] = useState({start: 0, finish: 5})
    const [stopFetching, setStopFetching] = useState(false)
    const [fetching, setFetching] = useState(true)

    const [disciplineState, setDisciplineState] = useState("")
    const [creatorState, setCreatorState] = useState("")
    const [dateSort, setDateSort] = useState("new")
    const [wholeFilter, setWholeFilter] = useState("all")


    useEffect(() => {
        store.editWhitePointer({place: "Лента входящих:"})
        assignWidth("order-sorting-section-wrapper", "order-sorting-section", 0.9)


        window.addEventListener('scroll', function() {
            const orderSortingSection = document.getElementById('order-sorting-section');
            const orderFeed = document.getElementById('order-feed');
            const orderFeedRect = orderFeed.getBoundingClientRect();
            const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
            const distanceToLeft = orderFeedRect.left + document.documentElement.scrollLeft;
            const orderFeedWidth = orderFeed.offsetWidth;

            console.log((-scrollLeft + orderFeedWidth))
            console.log(orderFeedRect)

            orderSortingSection.style.left = (-scrollLeft + orderFeedWidth)+ distanceToLeft + 'px';
        });
    }, [])


    // useEffect(() => {
    //
    //     if(store.requests) {
    //         setOrderList(toJS(store.requests).reverse())
    //     }
    //
    // }, [store.requests, store.update])


    useEffect(() => {

        console.log(fetching)
        console.log(!stopFetching)

        if(fetching && !stopFetching) {
            console.log('DO FETCH')
            fetchOrders()
        } else {
            console.log('NOT FETCH')
        }


//},[fetching])
    },[store.update, fetching])


    async function fetchOrders() {

        await setShowSpinner(true)



        const response = await $api.post("/incoming", {
            userid: store.user.id,
            amount: currentAmount,
            filter: {disciplineState, creatorState, dateSort, wholeFilter}
        })


        console.log(response)
        if (response.status >= 400) {
            //setMessage('Сервер временно недоступен')
            console.log("400 or more")
            setShowSpinner(false)
            setErrorMessage({show: true, text: "Сервер не отвечает"})

        } else {
            if(currentAmount.start === 0) {
                await setOrderList(response.data.orders)
            } else {
                await setOrderList(prev => [...prev, ...response.data.orders])
            }

            //await setTotalCount(prev => response.data.totalCount)
            await setCurrentAmount({start: currentAmount.finish, finish: currentAmount.finish + 5})
            await setFetching(false)
            if(response.data.orders.length === 0) {
                await setStopFetching(true)
            }
            await setShowEmpty(true)
        }
        await setShowSpinner(false)
    }


    const [filterTrigger, setFilterTrigger] = useState(0)

    let filterCounter

    async function setFilterTriggerTimer() {

        await clearInterval(filterCounter)

        filterCounter = setTimeout(() => {
            setStopFetching(false)
            setFilterTrigger(filterTrigger + 1)
        }, 1000)

    }

    useEffect(() => {

        console.log("FILTER TRIGGER IS - ", filterTrigger)

        console.log("TRIPPLE EFFECT")

        if (creatorState || dateSort === "old" || disciplineState || wholeFilter !== "all") {
            console.log("GUESS RIGHT")
            setIsFiltered(true)
        } else {
            setIsFiltered(false)
        }

        setCurrentAmount({start: 0, finish: 5})
        setFilterTriggerTimer()



    }, [creatorState, dateSort, disciplineState, wholeFilter])


    useEffect(() => {
        if (filterTrigger > 1) {
            console.log("FILTER TRIGGER EFFECT")
            fetchOrders()
        }


    }, [filterTrigger])





    useEffect(() => {

        async function getRequests(id) {

            const response = await $api.get(`/get_requests/${id}`)
            store.setRequests(response.data)
        }

        getRequests(store.user.id)

    }, [store.update])








    async function takeBackRequest(order, creator) {

        const response = await $api.post("/take_back_request", {order: order, user: store.user.id})
        if(response.data.message === "request revoked") {

            await store.removeRequests(order)

            const update = {
                from: store.user.id,
                to: creator,
                event: 'update'
            }

            await props.notifySocket.current.send(JSON.stringify(update));

            setOrderList(prev => prev.filter(item => item._id !== order))


        } else {
            //setErrorMessage({show: true, text: response.data})
            console.log(response.data)
        }


    }


    async function refreshOrderById(id) {

        try {
            const response = await $api.get(`/get_order_details_by_id?order_id=${id}`)
            console.log(response)
            await setOrderList(prev => prev.map(order => order._id === id ? response.data : order))
            //store.setFeedBlinker(true)
        } catch {
            setErrorMessage({show: true, text: "Не удолось обновить информацию о заказе"})
        }
    }



    useEffect(() => {
        if(props.incomingOrderToUpdate) {

            refreshOrderById(props.incomingOrderToUpdate)
        }
    }, [props.incomingOrderToUpdate])


    async function setFilter(value, purpose) {


        await setCurrentAmount({start: 0, finish: 5})

        if (purpose === "whole") {
            await setWholeFilter(value)
        }

        if(purpose === "date") {
            console.log(`Setting ${purpose} state`)
            await setDateSort(value)
        }

        if(purpose === "reset") {

            await setShowEmpty(false)
            await setStopFetching(false)
            await setDateSort('new')
            await setWholeFilter("all")
            await setCreatorState("")
            await setDisciplineState("")
            await setIsFiltered(false)
            await setSelectKey1(selectKey1 + 1)
            await setSelectKey2(selectKey2 + 10)



        } else {
            await setIsFiltered(true)
        }


    }


    useEffect(() => {


        document.addEventListener('scroll', scrollHandler)
        return function() {
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [])


    function scrollHandler(e) {

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)
        }
    }


    return (
        <div className={"incoming-order-feed-wrapper"}>

            {/*<Dossier/>*/}
            <div className="order-feed" id={'order-feed'}>
            {orderList?.length
                ?
                orderList.map((order, index) => {


                return <OrderMiniShield

                    title={order.title}
                    offeredPrices={order.offeredPrices}
                    number={order.number}
                    type={order.type}
                    price={order.price}
                    discipline={order.discipline ? order.discipline : null}
                    date={order.closestDate ? "Ближайшая дата автора" : new Date(order.deadline).toLocaleDateString()}
                    customer={order.creator.name + " " + order.creator.lastName}
                    takeBackRequest={takeBackRequest}
                    creator_id={order.creator._id}
                    doer={order.doer}
                    id={order._id}
                    onModeration={order.onModeration}
                    key={index}
                    orderStatus={order.orderStatus}
                    arbitration={order.arbitration}
                    reviews={order.reviews}
                    refuse={order.refuse}
                />})
            :

                isFiltered
                    ?
                    <div className={"order-empty-placeholder"}>
                        <h5 className={"ubuntu"}>По данному фильтру нет совпадений ¯\_(ツ)_/¯</h5>

                    </div>
                    :
                    showEmpty
                        ?
                        <div className={"order-empty-placeholder"}>
                            <h5 className={"ubuntu"}>У вас нет закрепленных заказов и заявок ¯\_(ツ)_/¯</h5>
                        </div>
                        :
                        null



            }
                {stopFetching && orderList.length
                    ?
                    <div className={"order-empty-placeholder"}>
                        <h5 className={"ubuntu"}>Вы докрутили до конца ¯\_(ツ)_/¯</h5>

                    </div>
                    :
                    null
                }
                {errorMessage.show
                    ?
                    <h1>{errorMessage.text}</h1>
                    :
                    null
                }
            </div>

            <div className={"order-sorting-section-wrapper"} id={"order-sorting-section-wrapper"}>

                <div className={"order-sorting-section"} id={"order-sorting-section"}>
                    <h6 className={"ubuntu margin-bottom-20"}>Фильтр</h6>

                    <select
                        key={selectKey2}
                        className={"margin-bottom-10"}
                        onChange={(e) => {setFilter(e.target.value, "whole")}}
                        value={wholeFilter}
                    >
                        <option value={"all"}>Все</option>
                        <option value={"work"}>🖊 В работе</option>
                        <option value={"request"}>❓ Заявки</option>
                        <option value={"moderation"}>⚖ Открыт спор</option>
                        <option value={"closed"}>🗄 Архивные</option>


                    </select>

                    <select
                        key={selectKey1}
                        className={"margin-bottom-10"}
                        onChange={(e) => {setFilter(e.target.value, "date")}}>
                        value={dateSort}

                        <option value={"new"}>Сначала новые</option>
                        <option value={"old"}>Сначала старые</option>
                    </select>

                    <input
                        className={"margin-bottom-10"}
                        placeholder={"По дисциплине..."}
                        onChange={(e) => setDisciplineState(e.target.value)}
                        value={disciplineState}
                    />
                    <input
                        className={"margin-bottom-10"}
                        placeholder={"По заказчику..."}
                        onChange={(e) => setCreatorState(e.target.value)}
                        value={creatorState}
                    />

                    <div className={"filter-spinner-n-reset-button-container"}>
                        <div className={"filter-spinner-container"}>
                            {showSpinner
                                ?
                                <img src={require('../img/loading.gif')} height={"35px"}/>
                                :
                                null
                            }
                        </div>

                        <div>
                            <button
                                className={"btn btn-danger btn-reset-filter"}
                                onClick={() => setFilter(null, 'reset')}
                            >Сбросить</button>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    );
};

export default observer(IncomingFeed);