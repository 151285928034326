import React, {useContext, useEffect, useState, useRef} from 'react';
import {Context} from "../../index";
import $api from "../../http";
import {toJS} from "mobx";
import {observer} from 'mobx-react-lite';
import {API_URL} from "../../http";
import {processMessageText} from "./repeat_functions";


//это компонент чатика внутри мессэдж центра
const MessageCenterChat = (props) => {

    const {store} = useContext(Context)

    //не трогай, это для сравнения дат при развертке списка сообщений
    const dateNow = Date.now()

    //это нужно чтобы отметить элемент на страничке, чтобы потом повесить на него слушатель события
    const myRef = React.createRef();


    //этот эффект помогает повесить слушатель события на элемент который помечен как ref
    //вот так через жопу это делается
    useEffect(() => {
        const element = myRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [myRef])



    function dateLook(check) {
        if(new Date(dateNow).toLocaleDateString() === new Date(check).toLocaleDateString()) {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleTimeString(
                    [],{hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        } else {
            return (
                <div className={"ws-chat-message-date"}>{new Date(check).toLocaleDateString(
                    [],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'}
                )}</div>
            )
        }
    }


    function handleScroll(e) {

        //это условие добавляет лимитов на отображение сообщений в чате, если докрутить его до самого верха
        if(e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight < 10) {
            setChatLimits({start: 0, stop: chatLimits.stop + 25})
        }
        //а это условие обнуляет все лимиты если опуститься обратно в самый низ чата
        if(e.target.scrollTop > -10) {
            setChatLimits({start: 0, stop: 100})
        }
    }



    // вроде бы этот стейт не нужен, но пусть пока полежит, раскомментить проще чем придумать заново, или нет...
    // const [counterOutgoing, setCounterOutgoing] = useState(0)


    //оперативный массив сообщений по ВХОДЯЩИМ заказам
    const [chatMessagesOperIn, setChatMessagesOperIn] = useState([])
    //оперативный массив сообщений по ИСХОДЯЩИМ заказам
    const [chatMessagesOperOut, setChatMessagesOperOut] = useState([])

    //это лимиты отображения количества сообщений в чатике, меняются по скроллу
    const [chatLimits, setChatLimits] = useState({start: 0, stop: 100})


    //это стейт с сообщениями по заказам которые юзер хочет ВЗЯТЬ
    const [messageListBeru, setMessageListBeru] = useState([])

    //содержимое строки чатика, тут всё просто
    const [chatValue, setChatValue] = useState("")

     //это стейт индикатор активного юзера (того с которым на данный момент открыто окно сообщения, нажата его кнопка под
    //заказом
    const [activeUserOut, setActiveUserOut] = useState()

    //это лист со всеми айдишниками юзеров которые писали АВТОРУ-ПОСРЕДНИКУ, он нужен чтобы потом оптом запросить всю
    //инфу по юзерам с сервака, лист создается в одном из хуков ниже тут же
    const [userIDS, setUserIDS] = useState([])

    //а это стейт с информацией о юзерах полученной на основе айдишников из прошлого стейта
    //так получилось потому что в одном из мест (гдето, не помню где) у нас были только айдишники, но нужно было больше
    //инфы по юзерам
    const [usersInfo, setUsersInfo] = useState({})

    //это функция стирания уведомлений из базы там где они больше не нужны, а так же уменьшатор счетчика уведомлений
    //она принимает айди юзера, айди заказа и айди человека отправившего сообщение по данному заазу
    async function clearNotifications(myId, orderId, from) {

        //запускается в разных местах, но срабатывает только если счетчик уведомлений в mobx имеет значение больше нуля
        //и переданы все данные для того чтобы выполнить своё предназначение =)
        if(store.notifyCounter >= 0 && myId && orderId && from) {
            console.log('clearNotifications armed')
            //удаляем уведомлялки из базы
            const response = await $api.post('/clear_notifications', {myId: myId, orderId: orderId, from: from})
            //console.log(response.data)
            //и уменьшаем счетчик уведомлений на количество удаленных уведомлений, всё логично
            console.log("SET NOT COUNTER 3")
            console.log(store.notifyCounter)
            console.log(response.data.value)
            await store.setNotifyCounter(response.data.value)
            // if(store.notifyCounter > 0) {
            //     //await store.setNotifyCounter(store.notifyCounter - response.data.value)
            //
            // }

        }
    }

    //удаление инфы о более ненужных уведомлениях об ОТДАВАЕМЫХ заказах в стейте mobx
    async function clearLocalNotificationsOut(orderId, from) {
        //console.log(props.notificationsOut)
        await store.removeNotificationsOut(orderId, from)
        await store.removeNotificationsOut(orderId, "moderator")

        //await props.setNotificationsOut(toJS(store.notificationsOut))
    }

    //удаление инфы о более ненужных уведомлениях о ВХОДЯЩИХ заказах в стейте mobx
    async function clearLocalNotificationsIn(orderId) {

        store.removeNotificationsIn(orderId)
        //console.log("SET NOTIN 1")
        //await props.setNotificationsIn(toJS(store.notificationsIn))

    }


    //функция отправки сообщений в этом чатике
    async function sendMessage() {


        //если поле воода не пустое, то...
        if (chatValue !== "") {

            //формируем сообщение
            const msg = {
                isAuthor: props.isAuthor,
                to:
                    props.isAuthor
                        ?
                        props.activeOrderOut.moderator && activeUserOut === props.activeOrderOut.doer
                            ?
                            [activeUserOut, props.activeOrderOut.moderator]
                            :
                            activeUserOut
                        :
                        props.activeOrderIn.moderator && store.user.id === props.activeOrderIn.doer
                            ?
                            [props.activeOrderIn.creator._id, props.activeOrderIn.moderator]
                            :
                            props.activeOrderIn.creator._id,
                userId: store.user.id,
                about: props.isAuthor ? props.activeOrderOut._id : props.activeOrderIn._id,
                userName: store.user.name + " " + store.user.lastName,
                text: chatValue,
                id: Date.now(),
                moderator:
                    props.isAuthor
                        ?
                        props.activeOrderOut.moderator
                        :
                        props.activeOrderIn.moderator,
                event: 'message',

            }


            //формируем сообщение и постим его по адресу на сервак в БД
            // console.log(props.activeOrderIn?._id)
            // console.log(props.activeOrderOut?._id)

            try {
                const response = await $api.post(`/chat_send/${props.isAuthor ? props.activeOrderOut._id : props.activeOrderIn._id}`, msg)
                if (response.status >= 400) {
                    console.log("Что то с серваком, ошибка 400+")
                }
            } catch (e) {
                console.log(e)
            }


            await props.notifySocket.current.send(JSON.stringify(msg));


            //ниже магия которая крутит окно сообщений с небольшой задержкой вниз после отправки сообщения
            const msgBox = document.getElementsByClassName("message-box-mc")[0]

            setTimeout(() => {
                msgBox.scrollTo(0, 0)
            }, 100)

            //тут блок который отвечает за уведомления
            //если создатель заказа отправляет уведомление, то оно формируется так..
            if (props.isAuthor) {

                const notify = {
                    toCreator: false,
                    from: props.activeOrderOut.moderator && activeUserOut === props.activeOrderOut.doer
                            ?
                            "moderator"
                            :
                            store.user.id,
                    to: activeUserOut,
                    about: props.activeOrderOut._id,
                    event: 'notification',
                }

                //и отправляется через вебсокет
                await props.notifySocket.current.send(JSON.stringify(notify));
                console.log("Отправили нотифайку на сервак от автора")


                if(props.activeOrderOut.moderator) {
                    const moder_notify ={
                        toModerator: true,
                        from: store.user.id,
                        to: props.activeOrderOut.moderator,
                        about: props.activeOrderOut._id,
                        event: 'moder_notification',
                    }

                    await props.notifySocket.current.send(JSON.stringify(moder_notify));
                }



            } else {

                //а тут тоже самое если это сообщение отправил исполнитель
                const notify = {
                    toCreator: true,
                    from:
                        props.activeOrderIn.moderator && store.user.id === props.activeOrderIn.doer
                            ?
                            "moderator"
                            :
                            store.user.id,
                    to: props.activeOrderIn.creator._id,
                    about: props.activeOrderIn._id,
                    event: 'notification',
                }

                await props.notifySocket.current.send(JSON.stringify(notify));
                console.log("Отправили нотифайку на сервак от исполнителя")

                if(props.activeOrderIn.moderator && props.activeOrderIn.doer === store.user.id) {
                    const moder_notify ={
                        toModerator: true,
                        from: store.user.id,
                        to: props.activeOrderIn.moderator,
                        about: props.activeOrderIn._id,
                        event: 'moder_notification',
                    }

                    await props.notifySocket.current.send(JSON.stringify(moder_notify));
                }


            }





            //очищаем поле ввода текста
            setChatValue("")
            textAreaRef.current.style.height = "auto"
        }
    }

    //функция закрытия диалога по ВХОДЯЩЕМУ заказу в "центре сообщений"
    async function closeIncomingDialog(order_id) {

        //если закрываем активный (открытый диалог) в данный момент заказ
        if(props.activeOrderIn._id === order_id) {
            //то сделаем нулевой заказ активным в обновленном списке

            await props.setOrderInToActivate(0)
            //только потом удалим айдишник диалога из локального (mobx) хранилища
            await store.removeMessagesAboutIncoming(order_id)

            //если же закрываем неактивный заказ
        } else {
            let activeOrderNumber
            let closingOrderNumber

            //то нужно пробежаться по списку открытых диалогов (заказом)
            for(let i=0; i<props.ordersIncoming.length; i++) {
                //console.log(props.ordersIncoming[i].number, i)

                //узнать номер активного заказа
                if(props.ordersIncoming[i]._id === props.activeOrderIn._id) {
                    activeOrderNumber = i
                }
                //и номер закрываемого заказа
                if(props.ordersIncoming[i]._id === order_id) {
                    closingOrderNumber = i
                }
            }

            //и далее принять решение, если закрываемый меньше активного, то
            if(closingOrderNumber < activeOrderNumber) {
                //назначаем новым активным заказ с номером в массиме на единичку меньше
                await props.setOrderInToActivate(activeOrderNumber - 1)
                await store.removeMessagesAboutIncoming(order_id)
            } else {
                //а если больше, то оставляем номер как есть
                await props.setOrderInToActivate(activeOrderNumber)
                await store.removeMessagesAboutIncoming(order_id)
            }
        }

        const response = await $api.post("/close_dialog", {direction: "incoming", order_id: order_id, user_id: store.user.id})
        if(response.data.status >= 400) {
            console.log(response.data)
        }



    }

    useEffect(() => {

        if(store.closingDialog !== "") {

            closeOutgoingDialog(store.closingDialog)
            //const newListOut = ordersOutgoing.filter(item => item.order._id !== store.closingDialog)
            //console.log(newListOut)
            //setOrdersOutgoing(prev => newListOut)
            //store.removeMessagesAboutOutgoing(store.closingDialog)
            //console.log(activeOrderOut)
            //if(activeOrderOut._id === store.closingDialog) {
              //  setActiveOrderOut()
            //}
        }

    }, [store.closingDialog])



    //функция закрытия диалогов по ИСХОДЯЩИМ заказам
    async function closeOutgoingDialog(order_id) {

        props.setClosingOrder(true)
        store.setIsAuthor(true)
        //console.log(toJS(store.messagesAboutOutgoing))
        //если закрываем активный (открытый диалог) в данный момент заказ
        if(props.activeOrderOut._id === order_id) {

            if(props.ordersOutgoing.length > 1) {
                await props.setOrderOutToActivate(0)
            } else {
                await props.setActiveOrderOut()
            }

            //то сделаем нулевой заказ активным в обновленном списке
            console.log("ДЛИНА = ", props.ordersOutgoing.length)
            // if(props.ordersOutgoing.length === 1) {
            //     console.log("Назначаем индекс заказа для активации 1")
            //     await props.setOrderOutToActivate(0)
            // } else if (props.ordersOutgoing.length === 0) {
            //     console.log("Set Active OUT 1")
            //     await props.setActiveOrderOut()
            // }

            //только потом удалим айдишник диалога из локального (mobx) хранилища
            await store.removeMessagesAboutOutgoing(order_id)

            //если же закрываем неактивный заказ
        } else {
            console.log("Set Active OUT 8")
            props.setActiveOrderOut(prevActiveOrderOut.current)
            await store.removeMessagesAboutOutgoing(order_id)
            // let activeOrderNumber
            // let closingOrderNumber
            //
            // //то нужно пробежаться по списку открытых диалогов (заказом)
            // console.log(props.ordersOutgoing)
            // for(let i=0; i<props.ordersOutgoing.length; i++) {
            //
            //
            //     //узнать номер активного заказа
            //     if(props.ordersOutgoing[i].order._id === props.activeOrderOut._id) {
            //         activeOrderNumber = i
            //         console.log("", activeOrderNumber)
            //     }
            //     //и номер закрываемого заказа
            //     if(props.ordersOutgoing[i].order._id === order_id) {
            //         closingOrderNumber = i
            //         console.log("", closingOrderNumber)
            //     }
            // }
            //
            // //и далее принять решение, если закрываемый меньше активного, то
            // if(closingOrderNumber < activeOrderNumber) {
            //     //назначаем новым активным заказ с номером в массиме на единичку меньше
            //     await props.setOrderOutToActivate(activeOrderNumber - 1)
            //     await store.removeMessagesAboutOutgoing(order_id)
            // } else {
            //     //а если больше, то оставляем номер как есть
            //     await props.setOrderOutToActivate(activeOrderNumber)
            //     await store.removeMessagesAboutOutgoing(order_id)
            // }
        }

        //если массив открытых диалогов в хранилище опустел, то убираем активного юзера, чтобы сработало условие
        //которое активирует заглушку окна с сообщениями, с текстом о том что открытых диалогов нет
        //и активный заказ тоже убираем, чтобы инфа по нему не светилась в информационном блочке
        if(toJS(store.messagesAboutOutgoing).length === 0) {
            console.log("Set Active OUT 2")
            props.setActiveOrderOut()

            console.log("Set Active User OUT 1")
            setActiveUserOut()
        }

        const response = await $api.post("/close_dialog", {direction: "outgoing", order_id: order_id, user_id: store.user.id})
        if(response.data.status >= 400) {
            console.log(response.data)
        }
    }

    //функция закрывает диалог с конкретным юзеров открытом заказе
    async function hideOutgoingUser(order_id, user_id) {

        try {
            //заносим инфу в базу
            const response = await $api.post('/hide_user', {order_id, user_id})
            //const response = {status: 200}
            if(response.status >= 400) {
                console.log("ОШИБКА!!1")
            } else {
                //заносим инфу в локальный стор

                console.log(toJS(store.hiddenUsers[order_id]))
                props.ordersOutgoing.forEach(item => {
                    if(item.order._id === order_id && (item.userList.length - toJS(store.hiddenUsers[order_id]).length) === 1) {
                        console.log("ЕСТЬ ТАКОЕ")
                        closeOutgoingDialog(order_id)
                    }
                })

                store.addHiddenUser(order_id, user_id)

                //теперь если закрыли активного юзера
                if(activeUserOut === user_id) {

                    //бежим по списку исходящих заказов
                    props.ordersOutgoing.forEach(item => {
                        //пока не найдем в нем активный заказ
                        if(item.order._id === props.activeOrderOut._id) {
                            //теперь бежим по списку юзеров активного заказа (его нет в стейте активного заказа, простите)
                            for (let i=0; i<item.userList.length; i++) {
                                //как только встречаем первый айдишник не совпадающий с закрываемым, то назначаем его активным
                                if(item.userList[i] !== user_id) {
                                    console.log("Set Active User OUT 2")
                                    setActiveUserOut(item.userList[i])
                                    break
                                }
                            }
                        }
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }



    useEffect(() => {

        if(props.isAuthor && store.notifyCounter > 0) {
            //console.log(props.activeOrderOut)
            if(props.activeOrderOut) {
                console.log('FLAG 0')
                clearNotifications(store.user.id, props.activeOrderOut._id, activeUserOut)
                clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)

            }
        } else if(!props.isAuthor) {
            //console.log(props.activeOrderIn)
            if(props.activeOrderIn) {
                console.log('FLAG 1')
                clearNotifications(store.user.id, props.activeOrderIn._id, props.activeOrderIn.creator._id)
                clearLocalNotificationsIn(props.activeOrderIn._id, props.activeOrderIn.creator._id)
            }

        }

    }, [props.isAuthor])


    useEffect(() => {
        //закидываем их в стейт сообщений


        async function processNewMessage() {

            console.log("FIRST SET")
            await setMessageListBeru(prev => [props.newOrderMessage, ...prev])
            console.log("set OTDAU 1")
            await props.setMessageListOtdau(prev => [props.newOrderMessage, ...prev])

            if(props.newOrderMessage.about === props.activeOrderOut?._id) {
                await setChatMessagesOperOut(prev => [props.newOrderMessage, ...prev])
            }

            console.log(props.activeOrderIn)
            if(props.newOrderMessage.about === props.activeOrderIn?._id) {
                await setChatMessagesOperIn(prev => [props.newOrderMessage, ...prev])
            }

            //console.log("Место номер 2")
            //console.log(toJS(store.notificationsOut))
            console.log(props.newOrderMessage)
            //console.log(toJS(store.notificationsOut))

            //вот это место является развилкой для сообщений и уведомлений на входящие (ХОЧУ ВЗЯТЬ)
            //и исходящие (ХОЧУ ОТДАТЬ)
            if ((props.newOrderMessage.isAuthor && props.newOrderMessage.to === store.user.id)
                || (props.newOrderMessage.isAuthor && Array.isArray(props.newOrderMessage.to) && props.newOrderMessage.moderator !== store.user.id)
                || (!props.newOrderMessage.isAuthor && props.newOrderMessage.to !== store.user.id && !Array.isArray(props.newOrderMessage.to)
                || (!props.newOrderMessage.isAuthor && Array.isArray(props.newOrderMessage.to) &&  props.newOrderMessage.userName === "Модератор" && props.newOrderMessage.to[1] === store.user.id)
                )) {
                console.log(props.newOrderMessage)

                console.log("Это сообщение по заказу который я хочу ВЗЯТЬ")
                //console.log("SET NOTIN 2")
                //await props.setNotificationsIn(toJS(store.notificationsIn))
            } else {
                console.log("Это сообщение по заказу который я хочу ОТДАТЬ")
                console.log("TWO")
                //if(!Array.isArray(props.newOrderMessage.to)) {
                    //await props.setNotificationsOut(toJS(store.notificationsOut))
                //}
            }


            //console.log(props.activeOrderOut?._id, props.newOrderMessage.userId)


        }

        if (props.newOrderMessage.event === 'message') {
            try {
                // console.log(props.newOrderMessage)
                // console.log(props.activeOrderOut)
                processNewMessage()
                //console.log(props.activeOrderIn?._id)
                //console.log(props.activeOrderOut?._id)

                if(props.newOrderMessage.about === props.activeOrderOut?._id && props.newOrderMessage.userName === "Модератор" && props.isAuthor) {
                    console.log("FLAG 222")
                    clearNotifications(store.user.id, props.activeOrderOut._id, "moderator")
                    clearLocalNotificationsOut(props.activeOrderOut._id, "moderator")
                }

                if (props.newOrderMessage.about === props.activeOrderIn?._id && !props.isAuthor) {
                    console.log('FLAG 2')

                    console.log(store.user.id, props.activeOrderIn?._id, props.newOrderMessage.userId)
                    clearNotifications(store.user.id, props.activeOrderIn?._id, props.newOrderMessage.userId)
                    clearLocalNotificationsIn(props.activeOrderIn?._id, props.newOrderMessage.userId)

                }
                if (props.newOrderMessage.userId === activeUserOut && props.isAuthor) {
                    console.log('FLAG 3')
                    clearNotifications(store.user.id, props.activeOrderOut?._id, props.newOrderMessage.userId)
                    clearLocalNotificationsOut(props.activeOrderOut?._id, props.newOrderMessage.userId)

                }


            } catch (e) {
                console.log(e)
            }
        }

    }, [props.newOrderMessage])


    useEffect(() => {


        if (props.activeOrderIn) {


            console.log('FLAG 4')
            //console.log(store.user.id, props.activeOrderIn._id, props.activeOrderIn.creator._id)
            clearNotifications(store.user.id, props.activeOrderIn._id, props.activeOrderIn.creator._id)
            clearLocalNotificationsIn(props.activeOrderIn._id, props.activeOrderIn.creator._id)

        }

    }, [props.activeOrderIn])


    //это стейт помощник для useEffect ниже, читай описание там
    const [lastLengthIn, setLastLengthIn] = useState(props.ordersIncoming.length)


    //упаковщик сообщений по ВХОДЯЩИМ заказам, срабатывает только когда число таких заказов увеличивается
    //чтобы избежать повторного упаковывания старых сообщений
    //чтобы была такая логика, мы храним количемство заказов с сообщениями в стейте lastLengthIn, он чуть выше
    //то что упаковалось потом маппится в окно чата
    useEffect(() => {

        //это важная строка, именно она отвечает за обновление активного заказа при
        //удалении диалога по ВХОДЯЩИМ заказам из центр сообщений
        console.log('Set Active IN 1')
        props.setActiveOrderIn(props.ordersIncoming[props.orderInToActivate])


        if (props.ordersIncoming.length > lastLengthIn && props.ordersIncoming.length !== 0) {

            async function packMessages() {

                await setMessageListBeru(() => [])
                await props.ordersIncoming.forEach(order => {

                    const msgs = order.messages.slice(0).reverse()
                    console.log(messageListBeru)
                    console.log("SECOND SET")
                    setMessageListBeru(prev => [...msgs, ...prev])
                    //setMessageListBeru(msgs)
                })
                //await props.setActiveOrderIn(props.ordersIncoming[0])
                console.log("Set Active OUT 7")
                await props.setActiveOrderOut()
                await setLastLengthIn(props.ordersIncoming.length)
            }
            packMessages().then(() => console.log("OK"))
        }
    }, [props.ordersIncoming])



    //это стейт помощник для useEffect ниже, читай описание там
    const [lastLengthOut, setLastLengthOut] = useState(props.ordersOutgoing.length)




    useEffect(() => {

        console.log("ORDERS OUT TRIGGERED")

        async function activateOrderOut() {



            //это важная строка, именно она отвечает за обновление активного заказа и активного пользователя при
            //удалении диалога по ИСХОДЯЩИМ заказам из центр сообщений

            //если нет номера активируемого юзера и стейт с исходящими заказами еще пустой
            if(props.orderOutToActivate === undefined && props.ordersOutgoing.length) {
                console.log("Set Active OUT 3")
                if(props.ordersOutgoing.length > 1) {
                    console.log("Set Active OUT 31")
                    await props.setActiveOrderOut(prevActiveOrderOut.current)
                } else {
                    console.log("Set Active OUT 32")
                    await props.setActiveOrderOut(props.ordersOutgoing[0]?.order)
                }



                //проверяем что список скрытых юзеров не пустой
                if(toJS(store.hiddenUsers).length > 0) {
                    //этот цикл ищет первого юзера который не значится в списках скрытых и назначемт его активным
                    for (let i=0; i<props.ordersOutgoing[0].userList.length; i++) {

                        if(toJS(store.hiddenUsers[[props.ordersOutgoing[0]?._id]]).indexOf(props.ordersOutgoing[0].userList[i]) === -1) {
                            //и назначает его активным
                            console.log("Set Active User OUT 3")
                            await setActiveUserOut(props.ordersOutgoing[0].userList[i]);
                            break
                        }
                    }
                    //если же список скрытых пуст, то назначаем активным самого первого
                } else {
                    console.log("Set Active User OUT 4")
                    await setActiveUserOut(props.ordersOutgoing[0].userList[0]);
                }


            // если у нас есть заказы в стейте исходящих и номер активируемого (а он назначается в компоненте чата)
            } else if (props.ordersOutgoing.length) {


                //то его и активируем, если он от нуля и выше
                if(props.orderOutToActivate === 0) {
                    console.log("Set Active OUT 4")
                    console.log(props.ordersOutgoing[props.orderOutToActivate]?.order)

                    //await props.setActiveOrderOut(prevActiveOrderOut.current)
                    await props.setActiveOrderOut(props.ordersOutgoing[props.orderOutToActivate]?.order)
                    await props.setOrderOutToActivate()


                } else {
                    //либо назначаем активным предыдущий
                    console.log("Set Active OUT 5")
                    //await props.setActiveOrderOut(props.ordersOutgoing[0]?.order)
                    await props.setActiveOrderOut(prevActiveOrderOut.current)
                }



                //проверяем что список скрытых юзеров не пустой
                if(toJS(store.hiddenUsers).length > 0) {
                    //этот цикл ищет первого юзера который не значится в списках скрытых и назначемт его активным
                    for (let i = 0; i < props.ordersOutgoing[props.orderOutToActivate].userList.length; i++) {

                        if (toJS(store.hiddenUsers[[props.ordersOutgoing[props.orderOutToActivate]._id]]).indexOf(props.ordersOutgoing[props.orderOutToActivate].userList[i]) === -1) {
                            console.log("Set Active User OUT 5")
                            await setActiveUserOut(props.ordersOutgoing[props.orderOutToActivate].userList[i]);
                            break
                        }
                    }

                    //если пустой то самого первого
                } else {
                    console.log("Set Active User OUT 6")
                    //await setActiveUserOut(prevActiveUserOut.current)
                    await setActiveUserOut(props.ordersOutgoing[0].userList[0]);
                }
            }
        }


        activateOrderOut()


        if (props.ordersOutgoing.length > lastLengthOut && props.ordersOutgoing.length !== 0) {



            async function packMessages() {

                let userList = new Set()
                props.setMessageListOtdau([])
                //бежим по списку ИСХ заказов
                await props.ordersOutgoing.forEach(item => {
                    //во вложенном цикле бежим по сообщениям каждого конкретного заказа
                    item.order.messages.forEach(message => {
                        //если айдишник отправителя не совпадает с айдишником текущего юзера
                        if (message.userId !== store.user.id) {
                            //то добавляем его в множество, так у нас получится список всех юзеров что либо писавших по
                            //существующему ПОЛНОМУ списку ИСХ заказов
                            userList.add(message.userId)
                        }
                    })
                    //разворачивамем (от слова реверс) массив сообщений данного заказа,
                    //чтобы сообщения шли в правильном порядке
                    const msgs = item.order.messages.slice(0).reverse()
                    console.log("set OTDAU 2")
                    //и запаковываем в общий массив ВСЕХ сообщений по ИСХОДЯЩИМ заказам
                    props.setMessageListOtdau(prev => [...msgs, ...prev])

                })

                await setLastLengthOut(props.ordersIncoming.length)

                //здесь ищем первого юзера, который не значится в списках скрытых, и назначаем его активным

                //это индекс из которого будем назначать активного юзера, по умолчанию ноль
                let indexOut = 0

                //если активный юзер уже есть,
                if(props.activeOrderOut?._id) {

                    for(let i = 0; i < props.ordersOutgoing.length; i++) {
                        //то ищем, какой индекс у активного заказа в стейте заказов
                        if(props.ordersOutgoing[i].order._id === props.activeOrderOut._id) {
                            //если находим то меняем его на найденный
                            indexOut = i
                            //и завершаем цикл
                            break
                        }
                    }
                }

                //далее в юзерлисте нужного заказа ищем первого нескрытого юзера
                for (let i=0; i<props.ordersOutgoing[indexOut].userList.length; i++) {

                    if(toJS(store.hiddenUsers[[props.ordersOutgoing[indexOut].order._id]]).indexOf(props.ordersOutgoing[indexOut].userList[i]) === -1) {
                        console.log("Set Active User OUT 7")
                        console.log(props.ordersOutgoing)
                        console.log(props.activeOrderOut)
                        //как только находим, проверяем был ли он прошлым активным
                        if(props.ordersOutgoing[indexOut].userList[i].indexOf(prevActiveUserOut.current) > -1) {
                            //если был, то делаем его снова активным
                            await setActiveUserOut(prevActiveUserOut.current)
                        } else {
                            //если не был, то назначем активным первого не скрытого
                            await setActiveUserOut(props.ordersOutgoing[indexOut].userList[i]);
                        }


                        break
                    }
                }

                await setUserIDS([...userList])
            }

            packMessages()
        }

    }, [props.ordersOutgoing])


    useEffect(() => {


        if (userIDS.length > 0) {

            async function getUserInfo() {

                const response = await $api.post('/users_info', {ids: userIDS})
                setUsersInfo(response.data)

            }

            getUserInfo()

        }
    }, [userIDS])

    useEffect(() => {


        async function clearAndActive() {
            if (props.activeOrderOut) {
                console.log('FLAG 5')
                clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)
                clearNotifications(store.user.id, props.activeOrderOut, activeUserOut)

            }
        }

        clearAndActive()


    }, [props.rerender])

    useEffect(() => {


        //console.log('FLAG 6')

        if (props.activeOrderOut && props.isAuthor) {
            console.log('FLAG 7')
            clearNotifications(store.user.id, props.activeOrderOut, activeUserOut)
            clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)
        }


    }, [activeUserOut])


    // useEffect(() => {
    //
    //     if(props.isAuthor) {
    //         console.log('FLAG 8')
    //         clearNotifications(store.user.id, props.activeOrderOut, activeUserOut)
    //         if (props.activeOrderOut) {
    //             console.log('FLAG 9')
    //             clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)
    //         }
    //     }
    //
    // }, [props.notificationsOut])



    async function setMessageListOut(allMessagesArray, activeOrder, activeUser) {

        console.log("activeOrder is ↓")
        console.log(activeOrder)
        console.log("activeUser is ↓")
        console.log(activeUser)


        let counter = 0
        const list = allMessagesArray.filter(message => {

            if(message.about === activeOrder?._id
                && (message.userId === store.user.id || message.userId === activeUser || message.userName === "Модератор")
            ) {
                counter++
                return true

            } else {
                return false
            }
        })

        console.log(counter)
        setChatMessagesOperOut(prev => [...list])
    }


    async function setMessageListIn(allMessagesArray, activeOrder) {

        let counter = 0
        const list = allMessagesArray.filter(message => {

            if(message.about === activeOrder?._id
                && (message.userId === store.user.id || message.userId === activeOrder.creator._id || message.userName === "Модератор")
            ) {
                counter++
                return true

            } else {
                return false
            }
        })

        console.log(counter)
        setChatMessagesOperIn(prev => [...list])
    }



    const prevActiveOrderOut = useRef()
    const prevActiveUserOut = useRef()

    useEffect(() => {

        console.log("ACTIVE ORDER OR USER OUT CHANGEd")

        console.log("Prev Order is - ", prevActiveOrderOut)
        console.log("Prev User is - ", prevActiveUserOut)
        prevActiveOrderOut.current = props.activeOrderOut
        prevActiveUserOut.current = activeUserOut
        console.log("Now Order - ", props.activeOrderOut)
        console.log('Now User - ', activeUserOut)

        setMessageListOut(props.messageListOtdau, props.activeOrderOut, activeUserOut)
        setChatLimits({start: 0, stop: 100})

    }, [props.activeOrderOut, activeUserOut])


    useEffect(() => {

        setMessageListIn(messageListBeru, props.activeOrderIn)
        setChatLimits({start: 0, stop: 100})

    }, [props.activeOrderIn])



    useEffect(() => {
        if(chatValue === "") {
            textAreaRef.current.style.height = "auto"
        }
    }, [chatValue])

    const textAreaRef = useRef()

    const lastTextHeight = useRef(0)

    function textAreaAdjust() {

        const maxHeight = 300

        textAreaRef.current.style.height = "auto"

        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        if(textAreaRef.current.scrollHeight > lastTextHeight.current) {

            lastTextHeight.current = textAreaRef.current.scrollHeight
            window.scrollTo(0, document.body.scrollHeight)
        }

        if (textAreaRef.current.scrollHeight > maxHeight) {
            textAreaRef.current.style.height = maxHeight + "px";
            textAreaRef.current.style.overflowY = "scroll";
        } else {
            textAreaRef.current.style.overflowY = "hidden";
        }

    }

    const chatValueRef = useRef("")

    return (
        <div className={"message-center-chat-wrapper"}>


            <div className={"chat-mc"}>


                <div className={"chat-and-information-mc"}>

                    <div className={"order-information-mc"}>
                        {props.activeOrderIn && !props.isAuthor
                            ?
                            <div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left mc-info-list"}>Заказ:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderIn.type}</h6>
                                    <h6 className={"information-right"}>"{props.activeOrderIn.title.slice(0, 100)}{props.activeOrderIn.title.length > 100 ? "..." : null}"</h6>
                                </div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left"}>Заказчик:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderIn.creator.name + " " + props.activeOrderIn.creator.lastName}</h6>
                                </div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left"}>Срок до:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderIn.closestDate ? "Ближайшая дата автора" : new Date(props.activeOrderIn.deadline).toLocaleDateString()}</h6>

                                </div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left mc-info-list"}>Стоимость:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderIn.price} р.</h6>
                                </div>

                            </div>
                            :
                            null
                        }
                        {props.activeOrderOut && props.isAuthor
                            ?
                            <div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left"}>Заказ:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderOut.type}</h6>
                                    <h6 className={"information-right"}>"{props.activeOrderOut.title?.slice(0, 100)}{props.activeOrderOut.title?.length > 100 ? "..." : null}"</h6>
                                </div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left"}>Срок до:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderOut.closestDate ? "Ближайшая дата автора" : new Date(props.activeOrderOut.deadline).toLocaleDateString()}</h6>
                                </div>
                                <div className={"order-information-mc-helper"}>
                                    <h5 className={"information-hard-left"}>Стоимость:</h5>
                                    <h6 className={"information-right"}>{props.activeOrderOut.price} р.</h6>
                                </div>

                            </div>
                            :
                            null
                        }
                    </div>


                    <div className={"message-box-and-input-container"}>
                        <div className={"message-box-mc"}  ref={myRef}>

                            {!props.isAuthor && props.activeOrderIn
                                ?
                                //messageListBeru.map(message => {
                                chatMessagesOperIn.slice(chatLimits.start, chatLimits.stop).map(message => {
                                        //если айдишник того кто написал данное сообщение совпадает с айдишником пользователя

                                        if(message.text === "#####"){
                                            return null
                                        }
                                        if (message.userId === store.user.id && message.about === props.activeOrderIn._id) {
                                            return <div className={"chat-message chat-right"} key={message.id}>
                                                {processMessageText(message.text)}
                                            </div>

                                        } else if (message.userId === props.activeOrderIn.creator._id && message.to === store.user.id && message.about === props.activeOrderIn._id) {


                                            return <div className={"chat-message chat-left"} key={message.id}>
                                                <span className={"chat-username"}>
                                                    <img className={"ws-message-avatar"} src={`${API_URL}/public/avatars/${message.userId}/avatar.png`} alt={"аватар"}/>
                                                    <div className={'inline'}>
                                                        <div className={'block'}>{message.userName}</div>
                                                        <div className={'block'}>
                                                        {new Date(dateNow).toLocaleDateString() === new Date(message.id).toLocaleDateString()
                                                            ?
                                                            <span className={"ws-chat-message-date"}>{new Date(message.id).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}</span>
                                                            :
                                                            <span className={"ws-chat-message-date"}>{new Date(message.id).toLocaleString([],{year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute:'2-digit'})}</span>
                                                        }</div>
                                                    </div>

                                                </span>
                                                {processMessageText(message.text)}
                                            </div>
                                        }  else if (message.userId === props.activeOrderIn.moderator
                                            //если у него в юзернейме написано "Модератор"
                                            && message.userName === "Модератор"
                                            //и если сообщение по активному заказу
                                            && message.about === props.activeOrderIn._id
                                            && props.activeOrderIn.doer === store.user.id
                                        ) {

                                            return <div className={"chat-message chat-center"} key={message.id}>
                                                <div className={"chat-username"}>Модератор</div>
                                                {dateLook(message.id)}
                                                {processMessageText(message.text)}
                                            </div>
                                        } else if (Array.isArray(message.to)
                                            //отправитель сообщения является исполнителем этого заказа и..
                                            && message.userId === props.activeOrderIn.creator._id
                                            //и сообщение принадлежит к активному заказу (диалогу)
                                            && message.about === props.activeOrderIn._id
                                            && message.to[0] === store.user.id
                                        ) {

                                            return <div className={"chat-message chat-left"} key={message.id}>

                                                <span className={"chat-username"}>
                                                    <img className={"ws-message-avatar"} src={`${API_URL}/public/avatars/${message.userId}/avatar.png`} alt={"аватар"}/>
                                                    <div className={'inline'}>
                                                        <div className={'block'}>{message.userName}</div>
                                                        <div className={'block'}>
                                                        {dateLook(message.id)}</div>
                                                    </div>

                                                </span>

                                                {processMessageText(message.text)}
                                            </div>
                                        }

                                    }
                                )
                                :
                                props.ordersIncoming.length === 0
                                    ?
                                    !props.isAuthor ?
                                        <h5 className={"null-messages"}>У вас нет открытых диалогов по принимаемым
                                            заказам</h5> : null
                                    :
                                    !props.isAuthor ?
                                        <h5 className={"null-messages"}>У вас пока нет сообщений по принимаемым
                                            заказам</h5> : null}



                            {props.isAuthor && props.activeOrderOut && activeUserOut
                                ?
                                //props.messageListOtdau.map(message => {
                                chatMessagesOperOut.slice(chatLimits.start, chatLimits.stop).map(message => {
                                        //если айдишник того кто написал данное сообщение совпадает с айдишником пользователя
                                    console.log("RENDER")
                                        if(message.text === "#####"){
                                            return null
                                        }

                                        if (message.userId === store.user.id && message.about === props.activeOrderOut._id && message.to === activeUserOut) {
                                            return <div className={"chat-message chat-right"}
                                                        key={message.id + Math.random()}>
                                                <div className={'mc-date-self-container'}>
                                                    {dateLook(message.id)}</div>
                                                <div className={"chat-text-right"}>{processMessageText(message.text)}</div>
                                            </div>

                                        } else if (message.userId === activeUserOut && message.to === store.user.id && message.about === props.activeOrderOut._id) {


                                            return <div className={"chat-message chat-left"} key={message.id + Math.random()}>

                                                <span className={"chat-username"}>
                                                    <img className={"ws-message-avatar"} src={`${API_URL}/public/avatars/${message.userId}/avatar.png`}  alt={"аватар"}/>
                                                    <div className={'inline'}>
                                                        <div className={'block'}>{message.userName}</div>
                                                        <div className={'block'}>
                                                        {dateLook(message.id)}</div>
                                                    </div>

                                                </span>

                                                <div className={"chat-text"}>{processMessageText(message.text)}</div>
                                            </div>
                                            //вот здесь страшная вещь, показываем сообщение от модератора только если
                                            //отправитель сообщения является модератором данного заказа
                                         }   else if (message.userId === props.activeOrderOut.moderator
                                            //если у него в юзернейме написано "Модератор"
                                            && message.userName === "Модератор"
                                            //и если сообщение по активному заказу
                                            && message.about === props.activeOrderOut._id
                                            //и если сейчас открыта (активна) кнопочка (диалог) исполнителя данного заказа
                                            && activeUserOut === props.activeOrderOut.doer
                                            ) {

                                            return <div className={"chat-message chat-center"} key={message.id}>
                                                <div className={"chat-username"}>Модератор</div>
                                                {dateLook(message.id)}
                                                {processMessageText(message.text)}
                                            </div>

                                            //а если в качестве адресатов сообщения у нас идет массив, и..
                                        } else if (Array.isArray(message.to)
                                            //отправитель сообщения является исполнителем этого заказа и..
                                            && message.userId === props.activeOrderOut.doer
                                            //в данный момент активна кнопка (диалог) исполнителя
                                            && activeUserOut === props.activeOrderOut.doer
                                            //и сообщение принадлежит к активному заказу (диалогу)
                                            && message.about === props.activeOrderOut._id
                                        ) {

                                            return <div className={"chat-message chat-left"} key={message.id}>

                                                <span className={"chat-username"}>
                                                    <img className={"ws-message-avatar"} src={`${API_URL}/public/avatars/${message.userId}/avatar.png`} alt={"аватар"}/>
                                                    <div className={'inline'}>
                                                        <div className={'block'}>{message.userName}</div>
                                                        <div className={'block'}>
                                                        {dateLook(message.id)}</div>
                                                    </div>

                                                </span>

                                                {processMessageText(message.text)}
                                            </div>
                                        } else if (Array.isArray(message.to)
                                            //отправитель сообщения является исполнителем этого заказа и..
                                            && message.userId === store.user.id
                                            //в данный момент активна кнопка (диалог) исполнителя
                                            && activeUserOut === props.activeOrderOut.doer
                                            //и сообщение принадлежит к активному заказу (диалогу)
                                            && message.about === props.activeOrderOut._id
                                        ) {

                                            return <div className={"chat-message chat-right"} key={message.id}>

                                                {dateLook(message.id)}
                                                {processMessageText(message.text)}
                                            </div>
                                        }


                                        //else if (props.messageListOtdau.length === 0) {
                                        //
                                        //
                                        // }
                                    }
                                )
                                :
                                props.ordersOutgoing.length === 0
                                    ?
                                    props.isAuthor ?
                                        <h5 className={"null-messages"}>У вас нет открытых диалогов по созданным
                                            заказам</h5> : null
                                    :
                                    props.isAuthor ? <h5 className={"null-messages"}>У вас пока нет сообщений по созданным
                                        заказам</h5> : null}


                        </div>
                        <div className={"chat-form-mc"}>
                            <textarea
                                ref={textAreaRef}
                                rows={1}
                                type={"text"}
                                className={"chat-input-mc"} type={"text"}
                                onKeyDown={async (key) => {
                                   if (key.code === "Enter" || key.code === "NumpadEnter") {
                                       await setChatValue(chatValueRef.current)
                                       sendMessage()
                                   } else if (key.code === "Enter" && key.ctrlKey) {
                                       //await setChatValue(prev => prev + "\n")
                                       chatValueRef.current += "\n"
                                       textAreaAdjust()
                                   } else {
                                       textAreaAdjust()
                                   }
                                }
                                }
                               value={chatValue}
                               onChange={e => setChatValue(e.target.value)}
                            />
                            <button
                                className={"btn chat-send-btn"}
                                onClick={() => sendMessage()}
                            >Отправить
                            </button>
                        </div>
                        <div><h3 className={"red"}>{props.errorMessage.show ? props.errorMessage.text : null}</h3></div>
                    </div>


                    <div className={"switch-button-container-mc"}>

                        {!props.isAuthor && props.activeOrderIn
                            ?
                            props.ordersIncoming.map((order) => {

                                if(store.user.id !== order.moderator) {
                                    return (<div key={order._id}
                                                 className={"btn-order-n-btn-close-container"}

                                    >
                                        {store.notificationsIn[order._id] !== undefined && order !== props.activeOrderIn ?
                                            <span
                                                className={"notify-counter inline"}>{"✉"}</span> : null}

                                        <button

                                            className={`btn order-switch-button ${order === props.activeOrderIn ? 'btn-primary' : 'btn-secondary'}`}
                                            onClick={async () => {
                                                console.log('Set Active IN 2')
                                                await props.setActiveOrderIn(order);
                                                //await props.setActiveOrderOut();
                                                // props.setRerender(prev => prev + 1)
                                                //console.log('FLAG 10')
                                                //await clearNotifications(store.user.id, order._id, order.creator._id)
                                                //await clearLocalNotificationsIn(order._id, order.creator._id)

                                            }
                                            }

                                        >#{order.number} {order.moderator ? "⚖" : null}</button>
                                        <button onClick={() => closeIncomingDialog(order._id)}
                                                className={`btn close-dialog-btn`}>❌</button>
                                    </div>)
                                }

                            })
                            :
                            null
                        }

                        {/*{console.log(props.ordersOutgoing)}*/}
                        {props.isAuthor && props.activeOrderOut && Object.entries(usersInfo).length !== 0 && activeUserOut
                            ?
                            props.ordersOutgoing.map((item, index) => {
                                console.log(item.order._id === props.activeOrderOut._id)
                                return (
                                    <div key={item.order._id} className={"lotsOfButtons"}>
                                        <div className={"order-n-close-order-container"}>
                                        <div className={"lotsOfOrders"}>
                                            {store.notificationsOut[item.order._id] !== undefined && item.order !== props.activeOrderOut
                                                ?
                                                <span className={"notify-counter inline"}>{"✉"}</span>
                                                : null
                                            }
                                        <button
                                            className={`btn order-switch-button ${item.order._id === props.activeOrderOut._id ? 'btn-primary' : 'btn-secondary'}`}
                                            onClick={async () => {
                                                //await props.setOrderOutToActivate(index)
                                                console.log("Set Active OUT 6")
                                                await props.setActiveOrderOut(item.order);
                                                //этот цикл ищет первого юзера который не значится в списках скрытых и назначемт его активным
                                                for (let i=0; i<props.ordersOutgoing[index].userList.length; i++) {

                                                    if(toJS(store.hiddenUsers[[item.order._id]]).indexOf(props.ordersOutgoing[index].userList[i]) === -1) {
                                                        console.log("Set Active User OUT 8")
                                                        await setActiveUserOut(props.ordersOutgoing[index].userList[i]);
                                                        break
                                                    }
                                                }

                                                //возможно этот ререндер можно удалить
                                                //props.setRerender(prev => prev + 1)


                                                if(item.order.onModeration) {
                                                    console.log('FLAG 11-1')
                                                    await clearNotifications(store.user.id, item.order._id, item.order.doer)
                                                    await clearLocalNotificationsOut(item.order._id,  item.order.doer)
                                                } else {
                                                    console.log('FLAG 11-2')
                                                    await clearNotifications(store.user.id, item.order._id, props.ordersOutgoing[index].userList[0])
                                                    await clearLocalNotificationsOut(item.order._id, props.ordersOutgoing[index].userList[0])
                                                }


                                            }
                                            }
                                        >#{item.order.number} {item.order.moderator ? "⚖" : null}</button>
                                        <button onClick={() => closeOutgoingDialog(item.order._id)}
                                                className={`btn close-dialog-btn`}>❌</button></div></div>
                                        <div className={"lotsOdUsers"}>
                                        {item.order._id === props.activeOrderOut._id && Object.entries(usersInfo).length !== 0
                                            ?
                                            <div className={"user-list-animated"}>{
                                            item.userList.map((user, index) => {

                                                //if(item.order.hiddenUsers.indexOf(user) === -1) {
                                                if(toJS(store.hiddenUsers[[item.order._id]])?.indexOf(user) === -1) {

                                                    return <div
                                                                key={index + Math.random()}
                                                                className={`arrow-and-button`}
                                                            >
                                                            {store.notificationsOut[item.order._id] !== undefined && store.notificationsOut[item.order._id] !== activeUserOut
                                                                ?
                                                                activeUserOut !== user && store.notificationsOut[item.order._id] && store.notificationsOut[item.order._id].indexOf(user) !== -1
                                                                    ?
                                                                    <span className={"notify-counter-user inline"}>{"✉"}</span>
                                                                    :
                                                                    null
                                                                :
                                                                null}
                                                                <div className={"user-n-close-user-btn"}>
                                                                <button
                                                                className={`btn ${user === activeUserOut ? "btn-danger" : "btn-primary-poor"} chose-user-button`}
                                                                onClick={async () => {
                                                                    console.log("Set Active User OUT 9")
                                                                    await setActiveUserOut(user)
                                                                    console.log('FLAG 12')
                                                                    //clearNotifications(store.user.id, props.activeOrderOut, activeUserOut)
                                                                    //clearLocalNotificationsOut(props.activeOrderOut._id, activeUserOut)

                                                                }}
                                                            >{usersInfo[user]?.lastName}{activeUserOut === user ? "✓" : null}</button>
                                                            <button onClick={() => hideOutgoingUser(item.order._id, user)}
                                                                className={`btn close-user-btn`}>{"❌"}</button></div></div>
                                                }

                                            })}</div>

                                            :
                                            null
                                        }

                                    </div></div>
                                )

                            })
                            :
                            null
                        }


                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(MessageCenterChat);