import React, {useContext, useRef, useState, useEffect} from 'react';
import {Context} from "../../index";
import {observer} from 'mobx-react-lite';

const ModerationList = ({orderList, setOrder, activeOrder, setActiveOrder, unreadOrders}) => {

    const {store} = useContext(Context)

    const [startMessage, setStartMessage] = useState("🥛 пуст, тот случай когда ты этому рад!")

    //const [listOfOrders, setListOfOrders] = useState([])
    const [listOfOrdersToRoll, setListOfOrdersToRoll] = useState([])


    useEffect(() => {

        const ordersMapped = orderList.map((order, index) => {

            if((order.moderator && order.moderator !== store.user.id)
                || (order.creator._id === store.user.id)
                || (order.doer === store.user.id)
            ) {
                return null
            }

            // if(!order.moderator && startMessage !== "") {
            //     setStartMessage("")
            // }

            if(!order.moderator) {

                return (
                    <button
                        key={order._id}
                        className={`btn btn-danger moderated-order-btn`}
                        onClick={() => setActiveOrder(order._id)}
                    >{order?.number}{activeOrder === order._id ? " ✓": null}</button>
                )
            }

            if(order.moderator === store.user.id) {



                return (
                    <button
                        key={order._id}
                        className={`btn ${order._id === activeOrder ? "btn-primary" : "btn-secondary"} moderated-order-btn`}
                        //onClick={() => setOrder(index)}
                        onClick={() => {

                            setActiveOrder(order._id)
                        }}
                    >{order?.number}{activeOrder === order._id ? " ✓": null} {unreadOrders.includes(order._id) ? "✉" : null}</button>
                )
            }


        })


        setListOfOrdersToRoll(ordersMapped)


    }, [orderList, unreadOrders, activeOrder])


    useEffect(() => {

        console.log(activeOrder)


    }, [activeOrder])




    return (
        <div className={"moderation-list"}>
            <h6>Стакан модерации</h6>
            <div className={"moderated-orders-btns-container"}>
                {orderList.length ? null : startMessage}
                {listOfOrdersToRoll}
            </div>
        </div>
    );
};

export default observer(ModerationList);