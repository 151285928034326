import React, {useContext, useEffect} from 'react';
import {Context} from "../../index";




const Oferta = () => {

    const {store} = useContext(Context)

    useEffect(() => {
        store.editWhitePointer({place: "Информация о сервисе:"})
        document.title = store.whitePointer.place
    }, [])


    return (
        <div className={"terms-wrapper"}>
            <h2>О сервисе</h2>
            <p className={"terms-tab"}>"Ultima Thule" (далее "Платформа") — это онлайн-платформа для размещения заказов на
                выполнение научных и текстовых работ. Мы создаем удобные условия для взаимодействия клиентов,
                обеспечивая прозрачность и контроль на всех этапах выполнения заказа.</p>
            <p className={"terms-tab"}>Основные возможности сервиса:</p>
            <ul>
                <li className={"terms-tab"}>Размещение заказов на выполнение научных и текстовых работ.</li>
                <li className={"terms-tab"}>Возможность переписки между Клиентами, размещающими заказы (именуемыми в дальнейшем "Заказчики") и
                    берущими такие заказы на выполнение (именуемыми в дальнейшем "Исполнители") в рамках каждого заказа.
                </li>
                <li className={"terms-tab"}>Центр сообщений для удобного управления диалогами.</li>
                <li className={"terms-tab"}>Система отслеживания стадий заказа.</li>
                <li className={"terms-tab"}>Создание личных страниц с указанием компетенций и портфолио.</li>
                <li className={"terms-tab"}>Система модерации и арбитража для разрешения спорных ситуаций.</li>
                <li className={"terms-tab"}>Общий чат и тематические чаты для общения пользователей.</li>
                <li className={"terms-tab"}>Система личных сообщений для общения вне рамок заказов.</li>
                <li className={"terms-tab"}>Поиск пользователей по имени и дисциплине.</li>
                <li className={"terms-tab"}>База неблагонадежных контрагентов для повышения безопасности
                    сотрудничества.
                </li>
            </ul>

            <h2>Наши услуги</h2>
            <p className={"terms-tab"}>Мы предлагаем услуги по предоставлению платформы для размещения заказов на
                выполнение научных и текстовых работ. Наш сервис обеспечивает удобное взаимодействие между Заказчиками и Исполнителями,
                прозрачность и удобство на всех этапах выполнения заказа.</p>

            <h4>Тарифы</h4>
            <p className={"terms-tab"}>Единый тариф: абонентская плата составляет 300 рублей в месяц за присутствие на Платформе. Комиссия с
                выполненных заказов не взимается.</p>
            <p className={"terms-tab"}>Абонентская плата списывается с внутреннего счета Клиента ежемесячно. Если на
                счету недостаточно средств, аккаунт будет временно заблокирован до пополнения счета.</p>

            <h2>Информация о получении выполненных работ</h2>
            <p className={"terms-tab"}>Готовые работы доступны для скачивания на странице заказа. Доступ к файлам имеют
                только Заказчики и Исполнители, участвующие в заказе, а так же Модераторы Платформы в случае открытия спора одной из сторон.</p>
            <p className={"terms-tab"}>Срок выполнения работы и стоимость оговариваются заранее и фиксируются на
                странице заказа. Без указания срока невозможно приступить к выполнению заказа.</p>

            <h2>Пользовательское соглашение</h2>
            <p className={"terms-tab"}>Настоящее соглашение регулирует условия использования Платформы "Ultima Thule".</p>

            <h4>Права и обязанности Платформы</h4>
            <p className={"terms-tab"}><strong>Права Платформы:</strong></p>
            <ul>
                <li className={"terms-tab"}>Блокировать аккаунты пользователей за невыполнение обязанностей или
                    нарушение условий пользовательского соглашения.
                </li>
                <li className={"terms-tab"}>Модерировать и арбитрировать спорные ситуации между Заказчиками и
                    Исполнителями.
                </li>
                <li className={"terms-tab"}>Использовать данные, предоставленные пользователями, для обеспечения
                    функциональности сервиса.
                </li>
                <li className={"terms-tab"}>Вносить изменения в условия пользовательского соглашения и тарифы с
                    уведомлением пользователей.
                </li>
            </ul>

            <p className={"terms-tab"}><strong>Обязанности Платформы:</strong></p>
            <ul>
                <li className={"terms-tab"}>Обеспечивать бесперебойную работу сервиса, за исключением времени на
                    проведение технического обслуживания, а так же информировать Клиентов о времени проведения таких работ.
                </li>
                <li className={"terms-tab"}>Защищать данные пользователей и обеспечивать их конфиденциальность.</li>
                <li className={"terms-tab"}>Предоставлять поддержку пользователям в случае возникновения вопросов или
                    проблем.
                </li>
                <li className={"terms-tab"}>Разрешать спорные ситуации между пользователями, привлекая модераторов и
                    арбитражную группу.
                </li>
                <li className={"terms-tab"}>
                    Платформа НЕ берет на себя полномочия распоряжаться средствами клиентов, предназначенными для оплаты заказов.
                </li>
            </ul>

            <h4>Права и обязанности клиентов</h4>
            <p className={"terms-tab"}><strong>Права Клиентов:</strong></p>
            <ul>
                <li className={"terms-tab"}>Использовать Платформу для размещения и/или выполнения заказов на научные и
                    текстовые работы.
                </li>
                <li className={"terms-tab"}>Обращаться в службу поддержки для решения возникших вопросов и проблем.</li>
                <li className={"terms-tab"}>Приглашать модераторов для разрешения спорных ситуаций.</li>
                <li className={"terms-tab"}>Предоставлять отзывы о выполненных заказах и пользователях.</li>
            </ul>

            <p className={"terms-tab"}><strong>Обязанности Исполнителей:</strong></p>
            <ul>
                <li className={"terms-tab"}>Исполнители обязуются выполнять работу в срок, согласованный с
                    Заказчиками.
                </li>
                <li className={"terms-tab"}>Исполнители обязуются соблюдать требования Заказчиков в полном объеме.</li>
                <li className={"terms-tab"}>Исполнители обязуются выполнить все правки, не противоречащие изначальным
                    требованиям заказа и письменным договоренностям.
                </li>
            </ul>

            <p className={"terms-tab"}><strong>Обязанности Заказчиков:</strong></p>
            <ul>
                <li className={"terms-tab"}>Заказчики обязуются предоставить исчерпывающее техническое задание и все
                    необходимые сведения для выполнения заказа.
                </li>
                <li className={"terms-tab"}>Заказчики обязуются оплатить Исполнителям выполненную работу по ее готовности или в
                    оговоренные в переписке по конкретному заказу сроки, с помощью также заранее оговоренного и удобным для обеих сторон способа.
                </li>
            </ul>


            <h2>Контактная информация</h2>
            <p className={"terms-tab"}>Услуги предоставляются самозанятым Штукерт Андреем Николаевичем.</p>
            <p className={"terms-tab"}>Телефон: +7 958 089 0287</p>
            <p className={"terms-tab"}>Адрес: Алтайский край, г. Барнаул, ул. Власихинская 67а/180</p>
            <p className={"terms-tab"}>Email: ultima.thule.freelance@gmail.com</p>
            <p className={"terms-tab"}>ИНН: 222212854006</p>
        </div>

    );
};

export default Oferta;