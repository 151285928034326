import React from 'react';


function BarChart({ data, total, title }) {
    // Получаем массив ключей объекта
    const keys = Object.keys(data);


    // Находим максимальное значение в объекте данных
    const max = Math.max(...Object.values(data));

    // Функция для генерации цвета на основе процентного соотношения
    const getColor = (percent) => {
        if (percent <= 20) {
            return 'red'; // красный цвет для процентов от 0 до 20
        } else if (percent <= 40) {
            return 'orange'; // оранжевый цвет для процентов от 20 до 40
        } else if (percent <= 60) {
            return 'yellow'; // желтый цвет для процентов от 40 до 60
        } else if (percent <= 80) {
            return 'yellowgreen'; // желтозеленый цвет для процентов от 60 до 80
        } else {
            return 'green'; // зеленый цвет для процентов от 80 до 100
        }
    };

    return (
        <div className={"bar-chart-wrapper"}>
            <h3 className={"margin-bottom-20"}>{title}, всего: {total} инвайтов</h3>
            <div className="bar-chart-container">

                <div className={"bar-names-container"}>
                    {keys.map(key => {
                        return (
                            <div className={"single-bar-name-container"}>
                                <h6>{key}</h6>
                            </div>
                        )
                    })}
                </div>

                <div className="bars-container">
                    {keys.map((key, index) => {
                        const value = data[key];
                        const percent = (value / total) * 100; // вычисляем процент
                        const color = getColor(percent); // получаем цвет на основе процента
                        return (
                            <div key={index} className={"single-bar-container"}>
                                <div

                                    style={{
                                        width: `${percent}%`, // ширина полоски пропорциональна проценту
                                        backgroundColor: color // устанавливаем цвет
                                    }}
                                    className="bar"
                                >
                                    {value} {/* Выводим название ключа и его значение на полоске */}
                                </div></div>
                        );
                    })}
                </div>

            </div>
        </div>

    );
}

export default BarChart;
