import React, {useState, useEffect, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {toJS} from 'mobx'
import {Context} from "../index";
import $api from "../http"
import MessageCenterChat from "./helpers/MessageCenterChat";


const DeleteMessageCenter = (props) => {


    const {store} = useContext(Context)


    //заказы которые юзер хочет взять, по которым есть сообщений
    const [ordersIncoming, setOrdersIncoming] = useState([])
    //заказы которые юзер хочет отдать, и по которым есть сообщения
    const [ordersOutgoing, setOrdersOutgoing] = useState([])
    //активный заказ (нажата кнопка, открыто окно сообщений) из тех что юзер хочет ВЗЯТЬ
    const [activeOrderIn, setActiveOrderIn] = useState()
    //активный заказ (нажата кнопка, открыто окно сообщений) из тех что юзер хочет ОТДАТЬ
    const [activeOrderOut, setActiveOrderOut] = useState()
    //это стейт-индикатор для переключения состояния ОТДАМ/ВОЗЬМУ для центра сообщений
    const [isAuthor, setIsAuthor] = useState(false)
    //это видимо какой то костыльный ререндер))
    const [rerender, setRerender] = useState(0)
    //стейт с сообдениями по заказам которые юзер хочет ОТДАТЬ
    const [messageListOtdau, setMessageListOtdau] = useState([])
    //стейт с непрочитанными нотификаторами по заказам которые юзер хочет ВЗЯТЬ
    const [notificationsIn, setNotificationsIn] = useState({})
    //стейт с непрочитанными нотификаторами по заказам которые юзер ОТДАЁТ
    const [notificationsOut, setNotificationsOut] = useState({})

    //еще костыль
    const [cane, setCane] = useState(false)


    const [errorMessage, setErrorMessage] = useState({show: false, text: ""})


    //этот стейты тоже помощники для того что происходит в районе 384 строки файла MessageCenterChat,
    // они помогают сделать активной ту же самую кнопку заказа после закрытия одного из заказов в центре сообщений
    const [orderOutToActivate, setOrderOutToActivate] = useState()
    const [orderInToActivate, setOrderInToActivate] = useState()

    //тоже стейт помощник, помогает в выборе заказа (диалога) который нужно сделать активным, после того как один из них
    //пользователь закроет
    const [closingOrder, setClosingOrder] = useState(false)

    //пишет в хэдере название модуля который сейчас открыт
    useEffect(() => {
        store.editWhitePointer({place: "Центр сообщений:"})
    }, [])


    useEffect(() => {

        //функция возвращает кнопку юзера, если он написал какое то сообщение будучи скрытым
        //при этом для его появления при открытом центре сообщений используется другая функция (unhide)
        //а при закрытом центре сообщений, при его открытии происходит проверка (вот прямо здесь и сейчас)
        //и если есть уведомление от юзера и он при этом является скрытым по тому заказу, по которому данное уведомление
        //пришло, то делаем его нескрытым..охх блять
        async function unhideByFirstLoadWithNotify() {
            console.log("UNHIDE BY LOAD")
            //если существует массив скрытых и существует массив уведомлений
            if(Object.entries(store.hiddenUsers).length && Object.entries(store.notificationsOut).length) {

                //то начинаем бежать по ключам (а это как раз айдишник заказа и там и там) по уведомлениям
                console.log(toJS(store.notificationsOut))
                for(let key in store.notificationsOut) {

                    //и если среди уведомлений находим юзера, который скрыт в данном заказе...
                    if(store.hiddenUsers[key]?.indexOf(store.notificationsOut[key][0]) > -1) {

                        //то надо его вернуть обратно из сумрака в локальном стейте mobx
                        store.removeHiddenUser(key, store.notificationsOut[key][0])
                        console.log(store.notificationsOut[key][0])
                        //и в базе об этом пометочку сделать
                        await $api.post(
                            '/unhide_user',
                            {order_id: key, user_id: store.notificationsOut[key][0]}
                        )
                    }
                }
            }
        }

        //срабатывание только если список скрытых юзеров не пустой
        if((Object.entries(toJS(store.hiddenUsers)).length) > 0) {
            unhideByFirstLoadWithNotify()
        }

    }, [store.hiddenUsers])


    //этот юзЭффект следит за состоянием store.messagesAboutIncoming, то есть если появляется сообщение по заказу,
    //по которому до сих пор не было ни одного сообщения, то хук получает инфу по всем заказам которые теперь есть
    //то есть он как бэ обновляет полностью данную инфу для себя
    useEffect(() => {

        console.log("INCOMING STORE TRIGGERED")
        //console.log(toJS(store.messagesAboutIncoming))

        //если значение пустое, то нужно очистить и стейт, чтобы последняя кнопка убралась из
        //центра сообщений
        if(store.messagesAboutIncoming.length === 0) {
            console.log("SET INCOMING 1")
            setOrdersIncoming([])
        }

        //срабатывает только при не пустом значении того параметра который мониторит
        if (store.messagesAboutIncoming.length > 0) {

            //тащит из базы инфу по всем входящим заказам, по ктороым есть сообщения
            async function setMessagesAboutIncoming() {

                const response = await $api.post('/orders_details', {
                    type: "incoming",
                    orders: store.messagesAboutIncoming,
                    myId: store.user.id
                })
                //console.log(response.data)
                console.log("SET INCOMING 2")
                //закидывает вс это добро в стейт
                await setOrdersIncoming(prev => response.data)
            }

            setMessagesAboutIncoming()
        }

    }, [store.messagesAboutIncoming])


    //этот юзЭффект следит за состоянием store.messagesAboutOutgoing, то есть если появляется сообщение по ОТДАВАЕМОМУ заказу,
    //по которому до сих пор не было ни одного сообщения, то хук получает инфу по всем заказам которые теперь есть
    //то есть он как бэ обновляет полностью данную инфу для себя
    useEffect(() => {
        console.log('FIRST EFFECT')

        //опять же срабатывает только в случае если есть что отрабатывать

        //тащит из базы инфу по всем ОТДАВАЕМЫМ заказам, по ктороым есть сообщения
            async function setMessagesAboutOutgoing() {

                const response = await $api.post('/orders_details', {
                    type: "outgoing",
                    orders: store.messagesAboutOutgoing,
                    myId: store.user.id
                })
                console.log("FIRST OUT")
                //console.log(response.data)
                await setOrdersOutgoing(response.data)
            }

            //console.log(store.messagesAboutOutgoing)

            //если длина стейта mobx с сообщениями по ОТДАВАЕМЫМ заказам больше нуля, и при этом пустой
            //стейт с ОТДАВАЕМЫМИ заказами, то этот иф срабатывает, это должно происходить только при первом запуске эффекта
            //он закидывает все сообщения в локальный стор, чтобы потом от туда разворачивать по диалогам
            //второй случай это когда мы закрываем заказ в диалоге, об этом существует соответствующий флаг
            //наверное пункт с флагом тоже костыль, это мы проверим потом
            if(toJS(store.messagesAboutOutgoing).length > 0 && ordersOutgoing.length === 0 || closingOrder) {

                setMessagesAboutOutgoing()
                setClosingOrder(false)
            }


            //условие в случае преобладания сообщений по отдаваемым заказам автоматом включает состояние центра сообщений
            //в положение автора-посредника, того кто ОТДАЕТ заказы, просто такое небольшое удобство (или неудобство
            //потом станет понятно)
        console.log(store.messagesAboutOutgoing.length)
        console.log(store.messagesAboutIncoming.length)
            if (store.messagesAboutOutgoing.length > store.messagesAboutIncoming.length || toJS(store.isAuthor)) {
                setTimeout(() => {
                    document.getElementById("otdam").click()
                }, 500)

            } else {
                //а вот это костылик, без нажатия на кнопку почему то не прогружается список заказов (кнопок)
                //надо отыскать что там ему мешает, но не сегодня
                setTimeout(() => {
                    document.getElementById("beru").click()
                }, 500)
            }

    }, [store.messagesAboutOutgoing])


    useEffect(() => {

        //функция активирует составление стартового листа скрытых юзеров
        async function markHiddenUsers() {
            if(ordersOutgoing) {

                let startingValue = {}

                ordersOutgoing.forEach(item => {
                    //console.log(item.order.hiddenUsers)
                    startingValue[[item.order._id]] = item.order.hiddenUsers
                })
                //вот тут полученные данные залетают в локальное хранилище
                store.setStartValueForHiddenUsers(startingValue)
            }
        }

        markHiddenUsers()

    }, [ordersOutgoing])



    //это огромный хук, который срабатывает на каждое новое сообщение, там много всякой логике, и вся она расписана
    //читайте на здоровье =)

    useEffect(() => {
        console.log('SECOND EFFECT')

        //проверялка и доставалка скрытых пользователей обратно, если они что то написали
        //скрытый пользователь, это тот, что не отображается в области выбора диалога, потому что пользователь нажал
        //рядом с его именем крестик, и диалог этот соответственно закрыл
        async function unhide() {

            //если новое сообщение прилетело НЕ ОТ СОЗДАТЕЛЯ заказа то... производим действия, а если от создателя, тогда
            //это по определению не может быть сообщение от скрытого юзера
            if (!props.newOrderMessage.isAuthor) {

                //создаем переменную со скрытыми юзверями, дергаем ее из локльного стора mobx
                const hiddenUsers = toJS(store.hiddenUsers)

                //и ищем там совпадение
                for(let key in hiddenUsers) {

                    //и если пришло сообщение по заказу от юзера, который в этом заказе скрыт, то достаем его обратно
                    if(hiddenUsers[key].indexOf(props.newOrderMessage.userId) !== -1
                        && key === props.newOrderMessage.about
                        && props.newOrderMessage.userName !== "Модератор"
                    ) {
                        //в локальном хранилище mobx
                        console.log("НЕТ ВОТ ЭТА ФУНКЦИЯ")
                        store.removeHiddenUser(key, props.newOrderMessage.userId)

                        //и в базе данных
                        const response = await $api.post(
                            '/unhide_user',
                            {order_id: key, user_id: props.newOrderMessage.userId}
                        )
                        //если с базой нормально поговорить не вышло, то нужно об этом написать, наверно
                        if (response.status >= 400) {
                            setErrorMessage({show: true, text: "КАКАЯ ТО ОШИБКА СЕРВЕРА"})
                        }
                        //тормозим этот перебор, потому что за одно входящее сообщение может быть только один скрытый диалог,
                        //и мы его уже нашли
                        break;
                    }
                }
            }
        }


        unhide()


        //эта функция просто получает инфу по требуемым ОТДАВАЕМЫМ заказам, потом эту инфу добавляет в стейт, который
        //разворачивается в виде кнопок для выбора диалога,
        //поддерживает отправку сразу нескольких айдишников для пакетной работы
        async function getOrderInfoToAddToChat(order_id) {
            const response = await $api.post('/orders_details', {
                type: "outgoing",
                orders: [order_id],
                myId: store.user.id
            })
            const newOrderToAdd = response.data

            console.log("SECOND OUT")

            //эта строчка позволяет оставить активную кнопку заказа там же где она была до добавления новой кнопки в стейт
            //await setOrderOutToActivate(prev => prev + 1)
            //а эта строчка собственно добавляет новый заказ в стейт так, чтобы его кнопка оказалась сверху
            //сначала логика была такая что кнопка добавлялась в конец, но мы тут решили что это неправильно и развернули
            //всё, поэтому и понадобилась строчка которая идет перед этой
            await setOrdersOutgoing(prev => [...newOrderToAdd, ...prev])
        }

        //если сообщение написано не создателем заказа
        if(!props.newOrderMessage.isAuthor
            //и если оно написано не самим пользователем
            && props.newOrderMessage.userId !== store.user.id && props.newOrderMessage.moderator !== store.user.id) {
            //то проверяем, есть ли уже заказ, по которому написано данное сообщение в стейте ОТДАВАЕМЫХ
            //если нет, то он фильтруется и попадает в список на добавление
            const orderToAdd = ordersOutgoing.filter(item => item.order._id === props.newOrderMessage.about)

            if(
                //и если этот список имеет не нулевую длину, то есть если туда что то упало...
                orderToAdd.length === 0
                //и если новое сообщение не пустая строка (я не помню зачем это, но не буду трогать)
                && props.newOrderMessage.text !== ""
                //и если трость (костыль), блять это я тем более не буду трога, я помню как я это сделал, но не помню зачем
                && cane

            ) {
                //...то тогда и только тогда запускаем вышеназванную функцию
                getOrderInfoToAddToChat(props.newOrderMessage.about)
            }
        }
        //устанавливаем костыль в положение true (о, я понял зачем он - чтобы предотвращать выполнение функции при первом
        // запуске хука, это же очевидно, осталось только понять ЗАЧЕМ ЭТО НУЖНО)))
        setCane(true)

        //функция обновляет инфу по заказу (тянет ее из базы), по которому появился новый юзер
        //который еще не писал по данному заказу сообщения, решение о ее запуске принимается на основе логики ниже, читай)
        async function updateOrderWithNewUser(listToUpdate) {

                //находим индекс обновляемого заказа в стейте ОТДАВАЕМЫХ заказов
                const index = ordersOutgoing.indexOf(listToUpdate[0])
                //запрашиваем обновленные данные
                const response = await $api.post('/orders_details', {
                    type: "outgoing",
                    orders: [props.newOrderMessage.about],
                    myId: store.user.id
                })
                //копируем стейт ОТДАВАЕМЫХ в новую переменную, чтобы там его подправить (обновить инфу по одному заказу)
                let current_state = ordersOutgoing.slice(0)
                //собственно меняем инфу по нужному заказу на обновленную
                current_state[index] = await response.data[0]
                console.log("THIRD OUT")
                //обновляем стейт
                await setOrdersOutgoing(prev => current_state)
        }


        if (
            //если сообщение написал не я
            //props.newOrderMessage.userId !== store.user.id
            //если сообщение написал НЕ создатель заказа
            !props.newOrderMessage.isAuthor
            //и если в локальном сторе ОТДАВАЕМЫХ не учтен заказ по которому прилетело это сообщение
            && toJS(store.messagesAboutOutgoing).indexOf(props.newOrderMessage.about) !== -1
        ) {
            console.log("ША")

            //эта функция позволяет добавлять кнопку пользователя который написал сообщение в диалоги по ОТДАВАЕМОМУ заказу
            //срабатывает (возвращает true) только в случае если этот пользователь вообще еще ни разу не писал по данному
            //заказу, то есть по определению не может являться здесь скрытым, и пока еще в списке тех кто писал по данному заказу
            //не значится
            async function makeListToUpdate() {
                //создаем лист (он состоит из одного элемента) обновляемых заказов путем фильтрации тех заказов
                //по которым уже есть сообщения
                const listToUpdate = ordersOutgoing.filter(item => {

                    //если айдишник фильтруемого заказа равен айдишнику того заказа по которому пришло новое сообщение
                    if (item.order._id === props.newOrderMessage.about
                        //и список пользователей фильтруемого заказа еще не содержит айдишника пользователя отправившего сообщение
                        && item.userList.indexOf(props.newOrderMessage.userId) === -1
                        //и айдишник отправителя не совпадает с айди самого пользователя то
                        && props.newOrderMessage.userId !== store.user.id
                    ) {
                        //то такой заказ нужно обновить и он становится заказом который нужно обновить
                        //закидывается в список на обновление, да, этот список состоит из одного заказа

                        console.log("ЭТА ФУНКЦИЯ")
                        return true
                    } else {
                        return false
                    }
                })

                //ну и если у нас есть заказ по которому нашелся такой юзер
                if(listToUpdate.length > 0) {
                    console.log("WE HAVE LIST")
                    //то передаем его в функцию которая этот заказ обновит
                    updateOrderWithNewUser(listToUpdate)
                }
            }
            console.log(props.newOrderMessage)
            makeListToUpdate()
        }


        //if (props.newOrderMessage.userId === store.user.id &&
          if(
              //если сообщение заказ по которому прилетело сообщение не учтен в локальном сторе входящих
              toJS(store.messagesAboutIncoming).indexOf(props.newOrderMessage.about) === -1
              //и сообщение написал создатель заказа
              && props.newOrderMessage.isAuthor
              //и если его написал не я
              && props.newOrderMessage.userId !== store.user.id
          ) {

            console.log("ША2")
            //то очевидно что нужно добавить этот заказ в диалоги, собственно этим данная функция и занимается
            async function setMessagesAboutIncoming() {
                console.log("setMessagesAboutIncoming")

                //закидывает в локальный стор айдишник заказа
                await store.addMessagesAboutIncoming(props.newOrderMessage.about)
                //запрашивает инфу о нем из БД
                const response = await $api.post('/orders_details', {
                    type: "incoming",
                    orders: [props.newOrderMessage.about],
                    myId: store.user.id
                })

                console.log("SET INCOMING 3")
                //добавляем единичку к номеру активируемого заказа, чтобы оставить активным тот же заказ, который и был
                await setOrderInToActivate(prev => prev + 1)
                //и эту инфу закидывает в стейт ВХОДЯЩИХ заказов, это те которые я хочу взять как автор
                await setOrdersIncoming(prev => [...response.data, ...prev])
                //setRerender(prev => prev + 1)

            }
            //а запускается она вот тут
            setMessagesAboutIncoming()
        }

    }, [props.newOrderMessage])


    return (
        <div className={"message-center-main"}>

            <div className={'message-center-header'}>

                {/*<h3 className={"h3-logo"}>Центр сообщений</h3>*/}
                <div className={"mc-in-out-switch-button-container"}>

                    <button
                        id={'beru'}
                        className={`btn mc-choice-btn ${!isAuthor ? "btn-primary" : "btn-secondary"}`}
                        onClick={() => {
                            console.log("CLICK АВТОР")
                            setIsAuthor(false)
                            activeOrderIn
                                ?
                                console.log("OK")
                                :
                                //console.log('Set Active IN 3')
                                setActiveOrderIn(ordersIncoming[0])
                            //setActiveOrderOut()
                        }}
                    >{isAuthor && Object.keys(toJS(store.notificationsIn)).length ? <span
                        className={"notify-counter-red-border inline"}>{Object.keys(toJS(store.notificationsIn)).length}</span> : null}Автор {!isAuthor ? " ✓" : null}</button>

                    <button
                        id={'otdam'}
                        className={`btn mc-choice-btn ${isAuthor ? "btn-primary" : "btn-secondary"}`}
                        onClick={() => {
                            console.log(activeOrderIn)
                            console.log(Object.keys(toJS(store.notificationsOut)).length)
                            setIsAuthor(true)
                            activeOrderOut
                                ?
                                console.log("OK")
                                :
                                //console.log("OUR1")
                                setActiveOrderOut(ordersOutgoing[0]?.order)
                            //setActiveOrderIn()
                        }}
                    >{!isAuthor && Object.keys(toJS(store.notificationsOut)).length ? <span
                        className={"notify-counter-red-border inline"}>{Object.keys(toJS(store.notificationsOut)).length}</span> : null}Заказчик {isAuthor ? " ✓" : null}</button>
                </div>


            </div>


            <MessageCenterChat
                ordersIncoming={ordersIncoming}
                ordersOutgoing={ordersOutgoing}
                activeOrderIn={activeOrderIn}
                setActiveOrderIn={setActiveOrderIn}
                activeOrderOut={activeOrderOut}
                setActiveOrderOut={setActiveOrderOut}
                notifySocket={props.notifySocket}
                newOrderMessage={props.newOrderMessage}
                isAuthor={isAuthor}
                setIsAuthor={setIsAuthor}
                rerender={rerender}
                setRerender={setRerender}
                messageListOtdau={messageListOtdau}
                setMessageListOtdau={setMessageListOtdau}
                notificationsOut={notificationsOut}
                setNotificationsOut={setNotificationsOut}
                notificationsIn={notificationsIn}
                setNotificationsIn={setNotificationsIn}
                orderOutToActivate={orderOutToActivate}
                setOrderOutToActivate={setOrderOutToActivate}
                orderInToActivate={orderInToActivate}
                setOrderInToActivate={setOrderInToActivate}
                setClosingOrder={setClosingOrder}
                errorMessage={errorMessage}

            />


        </div>
    );
};

export default observer(DeleteMessageCenter);