import React, {useEffect, useState} from 'react';

import DocViewerComponent from "./DocViewer";


const ModalDialog = ({
                     active,
                     setActive,
                     action,
                     message,
                     redMessage,
                     purpose,
                     setDate,
                     dateState,
                     setDateState,
                     closestDate,
                     setClosestDate,
                     minDate,
                     text,
                     textValue,
                     changeText,
                     winner,
                     setWinner,
                    needWinner,
                    filepath,
                    filename,
                    filetype,
                    price,
                    setPrice,
                    timer, seconds
}) => {


    useEffect(() => {
        const handlePressEsc = (event) => {
            if (event.code === 'Escape') {
                setActive("progress")
                setTimeout(() => {
                    setActive("no")
                    setTimerRelease(false)
                    setTimerSeconds(seconds ?? 10)

                }, 300)
            }
        };

        document.addEventListener('keydown', handlePressEsc);

        return () => {
            document.removeEventListener('keydown', handlePressEsc);
        };
    }, [])


    const [timerRelease, setTimerRelease] = useState(!timer)
    const [timerSeconds, setTimerSeconds] = useState(seconds ?? 10)

    const [safeDealFee, setSafeDealFee] = useState(5)


    function safeDealCalc(amount, fee) {
        return parseFloat(((amount / (100 - fee)) * fee + amount).toFixed(2));
    }




    useEffect(() => {
        if(timer && timerSeconds > 0 && active === "active") {
            setTimeout(() => {
                setTimerSeconds(prev => prev - 1)
            }, 1000)
        } else {
            if(active === "active") {
                setTimerRelease(true)
            }

        }
    }, [timerSeconds, active])


    const handlePriceChange = (event) => {
        const input = event.target.value;
        const numbersOnly = input.replace(/^0+|[^0-9]/g, ''); // Удаляем ведущий ноль и все символы, кроме цифр

        setPrice(numbersOnly);
    };


    return (
        <div className={active === "active"
                                    ?
                                    "modal active"
                                    :
                                    active === "progress" ? "modal" : "none"}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     setTimerRelease(false)
                     setTimerSeconds(seconds ?? 10)
                     setActive("no")
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                `${purpose === "fileviewer" ? "modal-100" : "modal-content"} active `
                :
                active === "progress" ? "modal-content" : "none"}
                 onClick={(e) => e.stopPropagation()}>

                {redMessage
                    ?
                    <div className={'modal-message-red margin-bottom-20'}>
                        {redMessage.split("\n").map((item, key) => {
                            return <p key={key}>{item}</p>;
                        })}

                        {text
                            ?
                            <div>
                                <h6 className={"ubuntu"}>
                                    {purpose === "moder_choice" || purpose === "arbitration_decision"
                                        ?
                                        purpose === "moder_choice"
                                            ?
                                            "Решение модератора:"
                                            :
                                            "Решение арбитража:"
                                        :
                                        "Опишите ситуацию:"
                                    }</h6>
                                <textarea className={"modatTextArea"} value={textValue} onChange={(e) => changeText(e.target.value)}></textarea>
                            </div>

                            :
                            null
                        }
                        {(purpose === "moder_choice" || purpose === "safe_deal") && needWinner
                            ?
                            <div>
                                <hr/>
                                <div className={"moder-choice-container"}>
                                    <label>{`${purpose === "moder_choice"  ? "В пользу " : "За счёт"} исполнителя`}</label>
                                    <input
                                        checked={winner === "doer"}
                                        className={"margin-left-20"}
                                        type={"checkbox"}
                                        onChange={() => setWinner("doer")}
                                    />
                                </div>
                                <hr/>
                                <div className={"moder-choice-container"}>

                                    <label>{`${purpose === "moder_choice"  ? "В пользу заказчика" : "За мой счёт"}`}</label>
                                    <input
                                        checked={winner === "creator"}
                                        className={"margin-left-20"}
                                        type={"checkbox"}
                                        onChange={() => setWinner("creator")}
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                        {purpose === "safe_deal"
                            ?
                            <div>
                                <hr/>
                                <select
                                    className={"margin-bottom-10"}
                                    onChange={(e) => setSafeDealFee(e.target.value)}
                                >
                                    <option value={5}>Банковская карта (5%)</option>
                                    <option value={7.5}>Qiwi (7,5%)</option>
                                    <option value={5}>Кошелёк Юmoney (5%)</option>
                                    <option value={5.5}>SberPay (5,5%)</option>
                                    <option value={5.7}>Tinkoff Pay (5,7%)</option>
                                </select>

                                <p>{`Сумма к переводу: ${winner === "doer" ? price : safeDealCalc(price, safeDealFee)} р.`}</p>
                                <p>{`Исполнитель получит: ${winner === "creator" ? price : price - (price/100 * safeDealFee) } р.`}</p>

                            </div>
                            :
                            null
                        }
                        {setDate
                            ?
                            <div className={"margin-top-20"}>
                                {closestDate
                                    ?
                                    null
                                    :
                                <input
                                    className={"margin-bottom-20"}
                                    type={"date"}
                                    value={dateState}
                                    onChange={(e) => {
                                            setDateState(e.target.value)
                                        }
                                    }
                                    min={minDate}
                                />}

                                <br/>
                                <label className={"margin-right-20"}>Ближайшая дата автора</label>
                                <input
                                    type={"checkbox"}
                                    onChange={(e) => {
                                        setClosestDate(prev=> !prev)
                                    }}
                                />

                            </div>
                             :
                            null
                        }
                    </div>
                    :
                    null
                }

                {purpose === "fileviewer"
                    ?
                    null
                    :
                    <p className={"modal-message"}>{message}</p>
                }


                {timerRelease
                    ?
                    null
                    :
                    <div className={"ubuntu margin-bottom-10"}>Подождите {timerSeconds} секунд</div>
                }
                    {purpose === "choice" || purpose === "moder_choice" || purpose === "arbitration_decision" || purpose === "safe_deal"
                        ?
                        <div className={"decision-btn-container"}>

                            <button
                                // disabled={needWinner
                                //             ?
                                //             purpose === "moder_choice" && !winner
                                //             :
                                //             purpose === "arbitration_decision" && textValue?.length === 0
                                //             }
                                disabled={
                                    (needWinner && purpose === "safe_deal" && !winner) ||
                                    (needWinner && purpose === "moder_choice" && !winner) ||
                                    (!needWinner && purpose === "arbitration_decision" && textValue?.length === 0) ||
                                    !timerRelease || (!textValue && text && purpose === "choice")
                                }

                                className={`btn ${timerRelease ? "primary-btn-color" : "primary-btn-color" } decision-btn`}
                                onClick={() => {
                                    action(winner, safeDealFee)
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>ДА</button>

                            <button
                                className={"btn btn-danger border-radius-8 decision-btn"}
                                onClick={() => {

                                    setActive("progress")
                                    setTimeout(() => {
                                        setTimerRelease(false)
                                        setTimerSeconds(seconds ?? 10)
                                        setActive("no")
                                    }, 300)
                                }}>НЕТ</button>
                        </div>
                        :
                        null
                    }



                    {purpose === "info"
                        ?
                            <div className={"decision-btn-container"}>
                                <button
                                className={"btn primary-btn-color decision-btn"}
                                onClick={() => {
                                    action()
                                    setActive("progress")
                                    setTimeout(() => {
                                    setActive("no")
                                    }, 300)
                                }}>OK</button>
                            </div>
                        :
                        null
                    }


                {purpose === "price-offer"
                    ?
                    <div className={"price-offer-modal-wrapper"}>
                        {/*{timerRelease*/}
                        {/*    ?*/}
                        {/*    null*/}
                        {/*    :*/}
                        {/*    <div className={"ubuntu margin-bottom-10"}>Подождите {timerSeconds} секунд</div>*/}
                        {/*}*/}

                        <div className={"price-offer-label-n-input-container"}>
                            <input
                                type="text"
                                value={price}
                                onChange={handlePriceChange}
                                placeholder="Предложите цену"
                                name="price"
                            />
                            <label style={{marginLeft: '10px'}}>₽</label>
                        </div>
                        <div className={"decision-btn-container"}>
                            <button
                                disabled={price <= 0 || !timerRelease}
                                className={`btn ${timerRelease ? "primary-btn-color" : "primary-btn-color" } decision-btn`}
                                onClick={() => {
                                    action()
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>Отправить</button>
                            <button
                                className={"btn btn-danger border-radius-8 decision-btn"}
                                onClick={() => {

                                    //setPrice(0)
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                        setTimerSeconds(seconds ?? 10)
                                        setTimerRelease(false)
                                    }, 300)
                                }}>Отмена</button>
                        </div>
                    </div>
                    :
                    null
                }

                {purpose === "fileviewer" && filepath && filetype && filename
                    ?
                    // <div>
                    //     <h6>{filetype}</h6>
                    //     <h6>{filename}</h6>
                    //     <h6>{"localhost:5000/" + filepath}</h6>
                    // </div>
                    <DocViewerComponent
                        path={filepath}
                        filetype={filetype}
                        filename={filename}
                        close={() => {
                            setActive("progress")
                            setTimeout(() => {
                                setActive("no")
                            }, 300)
                            document.body.style.overflow = 'auto';
                        }}
                    />
                     : null}

                {purpose === "invite"
                    ?
                    <div>
                        <input
                            type={'text'}
                            value={textValue}
                            onChange={(e) => {
                                changeText(e.target.value)
                            }}
                        />
                        <div className={"decision-btn-container"}>
                            <button
                                disabled={!textValue}
                                className={"btn primary-btn-color decision-btn"}
                                onClick={() => {
                                    action()
                                    setActive("progress")
                                    setTimeout(() => {
                                        setActive("no")
                                    }, 300)
                                }}>Создать</button>
                        </div>
                    </div>

                    :
                    null
                }




            </div>
        </div>
    );
};

export default ModalDialog;