import React, {useState, useEffect, useContext} from 'react';
import {Context} from "../index";
import {observer} from 'mobx-react-lite';
import $api from "../http";
import EventTab from './helpers/EventTab'
import Dossier from "./Dossier";
import EmptyMessage from "./helpers/EmptyMessage";
import Loading from "./Loading";
import SvgSoundOn from "../img/sound-on.svg";
import SvgSoundOff from "../img/sound-off.svg";
import {assignWidth} from "./helpers/repeat_functions";



const EventFeed = ({notifySocket}) => {

    const {store} = useContext(Context)

    store.setPageId("events-feed")


    const [events, setEvents] = useState([])
    const [isLoading, setIsLoading] = useState(true)


    const [showRead, setShowRead] = useState(localStorage.getItem('event_feed_show_read') === "true")

    const storedSoundOn = localStorage.getItem(`sound_events`);
    const initialSoundOn = storedSoundOn ? JSON.parse(storedSoundOn) : true;

    const [soundOn, setSoundOn] = useState(initialSoundOn);

    const event_message_notification_sound = new Audio('/sounds/message3.mp3')



    let counter = 0

    useEffect(() => {
        store.editWhitePointer({place: "События:"})
        assignWidth("event-setup-bar-wrapper", "event-setup-bar", 0.9)

    }, [])

    function playSound() {
        event_message_notification_sound.play()
        new Notification('Тихое уведомление', { silent: true });
    }



    // useEffect(() => {
    //
    //     if (store.eventCounter > events.length && events.length > 0) {
    //
    //         console.log(store.eventCounter, "event counter")
    //         console.log(events.length, "events length")
    //         store.setEventCounter(0)
    //
    //         for (let item in events) {
    //             if(!events[item].read) {
    //                 console.log("INCREASE")
    //
    //                 store.increaseEvent()
    //             }
    //         }
    //
    //     }
    // }, [events])

    const [filter, setFilter] = useState(undefined)
    const [sort, setSort] = useState()


    useEffect(() => {

        async function getEvents(user_id) {

            console.log("GETTING EVENTS")
            //setIsLoading(true)
            const response = await $api.get(`/get_events_full/${user_id}?filter=${filter}&sort=${sort}&show_read=${showRead}`)
            console.log(response.data)
            const newState = response.data
            await setEvents(newState)

            setIsLoading(false)

        }
        
        getEvents(store.user.id)



    }, [store.eventUpdater, filter, sort, showRead])




    async function editEvent(id, operation) {

        let newStateOfEvents = Object.assign(events)


        for(let key in newStateOfEvents) {


            if(newStateOfEvents[key]._id === id) {

                if(operation === "read") {
                    newStateOfEvents[key].read = true
                    const response = await $api.post(`/event_edit/${id}`, {operation: "read"})
                    if(response.data.status === 200) {

                        if(!showRead) {
                            await newStateOfEvents.splice(key, 1)
                        }

                        await setEvents([...newStateOfEvents])
                        await store.decreaseEvent()
                    }
                }

                if(operation === "delete") {
                    const response = await $api.post(`/event_edit/${id}`, {operation: "delete"})

                    if(response.data.status === 200) {

                        if(!newStateOfEvents[key].read) {
                            await store.decreaseEvent()


                        }

                        await newStateOfEvents.splice(key, 1)
                        await store.eventUpdate()
                    }
                }

                break
            }
        }

        //отправляем апдейт самому себе, чтобы если на соседней странице счётчик событий тоже изменился
        const update = {
            //from: store.user.id,
            to: store.user.id,
            event: 'header'
        }

        await notifySocket.current.send(JSON.stringify(update));


    }



    return (
        <div className={"events-feed-main-wrapper"}>
            {/*<div className={"event-feed-dossier-wrapper"}>*/}
            {/*    <Dossier/>*/}
            {/*</div>*/}

            <div className={"events-feed-wrapper"}>
                {isLoading
                    ?
                    <Loading/>
                    :
                    <div className={"events-feed"}>



                        {events.length > 0
                            ?

                            events.map((element, index) => {
                                counter++
                                console.log(element)
                                return <EventTab
                                    key={element._id}
                                    id={element._id}
                                    to={element.to}
                                    from={element.from}
                                    read={element.read}
                                    event={element.event}
                                    date={element.date}
                                    message={element.message}
                                    chat_id={element.chat_id}
                                    counter={events.length - index}
                                    number={element.order?.number}
                                    title={element.order?.title}
                                    order_id={element.order?._id}
                                    doer={element.order?.doer}
                                    authorName={element.from?.name + " " + element.from?.lastName}
                                    editEvent={editEvent}

                                />

                            })
                            :
                            filter
                                ?
                                <div className={"search-empty-tab"}>
                                    <h4 className={"ubuntu no-margin"}>Совпадений нет...</h4>
                                </div>
                                :
                            <EmptyMessage
                                title={`${store.user.name}, это лента событий`}
                                message={<p>...они же уведомления о произошедших важных вещах, будь то изменение статуса заказа,
                                    приход новой заявки на выполнение и прочих событиях о которых стоит быть в курсе.
                                    Пока событий в ленте нет вы будете видеть эту рамочку, всё просто.</p>}
                            />

                        }


                    </div>
                }

            </div>

            <div className={"event-setup-bar-wrapper"} id={"event-setup-bar-wrapper"}>
                <div className={"event-setup-bar"} id={"event-setup-bar"}>
                    <h6 className={"ubuntu margin-bottom-20"}>Фильтр</h6>
                    {/*<img*/}
                    {/*    className={"dont-drag search-lens-messages pointer"}*/}
                    {/*    src={soundOn ? SvgSoundOn : SvgSoundOff}*/}
                    {/*    alt={soundOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*    title={soundOn ? "Отключить звук" : "Включить звук"}*/}
                    {/*    width={"25px"}*/}
                    {/*    onClick={() => {*/}
                    {/*        localStorage.setItem(`sound_events`, !soundOn);*/}
                    {/*        if(!soundOn) {*/}
                    {/*            console.log("PLAY 3")*/}
                    {/*            playSound()*/}
                    {/*        }*/}
                    {/*        setSoundOn(prev =>!prev)*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <select
                        className={"margin-bottom-10 event-filter-select"}
                        onChange={(e) => setSort(e.target.value)}
                    >
                        <option
                            value={"new_first"}
                        >Сначала новые
                        </option>
                        <option
                            value={"old_first"}
                        >Сначала старые
                        </option>
                    </select>
                    <input
                        type={"number"}
                        className={"margin-bottom-10 event-filter-input"}
                        placeholder={"По номеру заказа"}
                        onChange={(e) => {
                            if (e.target.value !== "") {
                                setFilter(e.target.value)
                            } else {
                                setFilter(undefined)
                            }

                        }}
                    />

                    <div>
                        <label className={"margin-right-10"}>Прочитанные</label>
                        <input
                            checked={showRead}
                            type={'checkbox'}
                            onChange={() => {
                                localStorage.setItem('event_feed_show_read', (!showRead).toString())
                                setShowRead(!showRead)
                            }}/>
                    </div>
                    <img
                        className={"dont-drag search-lens-messages pointer margin-top-10"}
                        src={soundOn ? SvgSoundOn : SvgSoundOff}
                        alt={soundOn ? "Отключить звук событий" : "Включить звук событий"}
                        title={soundOn ? "Отключить звук событий" : "Включить звук событий"}
                        width={"25px"}
                        onClick={() => {
                            localStorage.setItem(`sound_events`, !soundOn);
                            if (!soundOn) {
                                console.log("PLAY 3")
                                playSound()
                            }
                            setSoundOn(prev => !prev)
                        }}
                    />

                </div>
            </div>


        </div>

    );
};

export default observer(EventFeed);