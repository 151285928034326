import React, {useState, useEffect, useContext, useRef} from 'react';
import OrderShield from "./OrderShield";
import {Context} from "../index";
import {observer} from 'mobx-react-lite';
import $api from '../http'
import {toJS} from "mobx";
import Dossier from "./Dossier";
import {assignWidth} from "./helpers/repeat_functions";


const OutgoingFeed = (props) => {

    const {store} = useContext(Context)

    //console.log(toJS(store.moderNotificationsOut))
    //console.log(toJS(store.moderNotificationsIn))


    const [moderNotificationsIn, setModerNotificationsIn] = useState([])
    const [moderNotificationsOut, setModerNotificationsOut] = useState([])

    const [orderList, setOrderList] = useState([])
    const [message,setMessage] = useState('Вы еще не публиковали заказы')
    const [showEmpty, setShowEmpty]  = useState(false)

    const [totalCount, setTotalCount] = useState(1)
    const [currentAmount, setCurrentAmount] = useState({start: 0, finish: 5})

    const [fetching, setFetching] = useState(true)
    const [showSpinner, setShowSpinner] = useState(false)
    const [isFiltered, setIsFiltered] = useState(false)
    const [selectKey1, setSelectKey1] = useState(0)
    const [selectKey2, setSelectKey2] = useState(100)
    const [stopFetching, setStopFetching] = useState(false)

    const [disciplineState, setDisciplineState] = useState("")
    const [performerState, setPerformerState] = useState("")
    const [dateSort, setDateSort] = useState("new")
    const [wholeFilter, setWholeFilter] = useState("all")



    const orderCounterRef = useRef(0)


    useEffect(() => {


        async function setStateForOut() {

            const storeOfOut = toJS(store.moderNotificationsOut)
            const stateForOut = await storeOfOut.reduce((acc, item) => {

                if(acc.indexOf(item.about) === -1) {
                    acc.push(item.about)
                    return acc
                } else {
                    return acc
                }

            }, [])

            setModerNotificationsOut(stateForOut)
        }

        if(toJS(store.moderNotificationsOut).length) {
            setStateForOut()
        }

    }, [store.moderNotificationsOut])



    useEffect(() => {


        document.addEventListener('scroll', scrollHandler)
        return function() {
            document.removeEventListener('scroll', scrollHandler)
        }

    }, [totalCount, orderList])


    function scrollHandler(e) {

        if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < window.innerHeight + 100) {
            setFetching(true)
        }
    }



    useEffect(() => {
        store.editWhitePointer({place: "Мои предложения:"})
        assignWidth("order-sorting-section-wrapper", "order-sorting-section", 0.9)

        window.addEventListener('scroll', function() {
            const orderSortingSection = document.getElementById('order-sorting-section');
            const orderFeed = document.getElementById('order-feed');
            const orderFeedRect = orderFeed.getBoundingClientRect();
            const scrollLeft = document.documentElement.scrollLeft;
            const distanceToLeft = orderFeedRect.left + document.documentElement.scrollLeft;
            const orderFeedWidth = orderFeed.offsetWidth;

            // console.log((-scrollLeft + orderFeedWidth))
            // console.log(orderFeedRect)

            orderSortingSection.style.left = (-scrollLeft + orderFeedWidth)+ distanceToLeft + 'px';
        });

    }, [])


    useEffect(() => {

        async function refetchOrders() {
            const response = await $api.post("/outgoing", {userid: store.user.id, amount: {start: 0, finish: currentAmount.finish}})
            console.log(response)
            if (response.status >= 400) {
                setMessage('Сервер временно недоступен')
            } else {

                await setOrderList(prev => [...response.data.orders])
                await setTotalCount(prev => response.data.totalCount)
                //await setCurrentAmount({start: currentAmount.finish, finish: currentAmount.finish + 5})
                //await setFetching(false)
            }
        }
        if(store.updateOrderProcess !== 0) {
            refetchOrders()
        }


    }, [store.updateOrderProcess])



    async function fetchOrders() {

        await setShowSpinner(true)

        const response = await $api.post("/outgoing", {
            userid: store.user.id,
            amount: currentAmount,
            filter: {disciplineState, performerState, dateSort, wholeFilter}
        })
        console.log(response)
        if (response.status >= 400) {
            setMessage('Сервер временно недоступен')
        } else {
            if(currentAmount.start === 0) {
                await setOrderList(response.data.orders)
            } else {
                await setOrderList(prev => [...prev, ...response.data.orders])
            }

            //await setTotalCount(prev => response.data.totalCount)
            await setCurrentAmount({start: currentAmount.finish, finish: currentAmount.finish + 5})
            await setFetching(false)
            if(response.data.orders.length === 0) {
                await setStopFetching(true)
            }
            await setShowEmpty(true)
        }
        await setShowSpinner(false)
    }


    useEffect(() => {


        if(fetching && !stopFetching) {
            console.log('DO FETCH')
            fetchOrders()
        } else {
            console.log('NOT FETCH')
        }


//},[fetching])
    },[store.update, fetching])


    useEffect(() => {

        orderCounterRef.current = 0

    }, [performerState, disciplineState])




    const [filterTrigger, setFilterTrigger] = useState(0)

    let filterCounter

    async function setFilterTriggerTimer() {

        await clearInterval(filterCounter)

        filterCounter = setTimeout(() => {
            setStopFetching(false)
            setFilterTrigger(filterTrigger + 1)
        }, 1000)

    }

    useEffect(() => {

        console.log("FILTER TRIGGER IS - ", filterTrigger)

            console.log("TRIPPLE EFFECT")

            if (performerState || dateSort === "old" || disciplineState || wholeFilter !== "all") {
                console.log("GUESS RIGHT")
                setIsFiltered(true)
            } else {
                setIsFiltered(false)
            }

            setCurrentAmount({start: 0, finish: 5})
            setFilterTriggerTimer()



    }, [performerState, dateSort, disciplineState, wholeFilter])


    useEffect(() => {
        if (filterTrigger > 1) {
            console.log("FILTER TRIGGER EFFECT")
            fetchOrders()
        }


    }, [filterTrigger])


    async function setFilter(value, purpose) {


        await setCurrentAmount({start: 0, finish: 5})

        if (purpose === "whole") {
            await setWholeFilter(value)
        }

        if(purpose === "date") {
            console.log(`Setting ${purpose} state`)
            await setDateSort(value)
        }

        if(purpose === "reset") {

            await setShowEmpty(false)
            await setStopFetching(false)
            await setDateSort('new')
            await setWholeFilter("all")
            await setPerformerState("")
            await setDisciplineState("")
            await setIsFiltered(false)
            await setSelectKey1(selectKey1 + 1)
            await setSelectKey2(selectKey2 + 10)



        } else {
            await setIsFiltered(true)
        }


    }


    return (
        // <div className="main-field">
        <div className={"outgoing-order-feed-wrapper"}>

            {/*<Dossier/>*/}
            <div className="order-feed" id={'order-feed'}>



                {orderList.length
                    ?
                    orderList.map((order, index) => {
                        //если заказ не от текущего пользователя, то добавляем его в ленту
                        //console.log(order._id)
                        //console.log(moderNotificationsOut)
                        //console.log(moderNotificationsOut.indexOf(order._id))
                        //console.log(order.doer)

                        //
                        // if (
                        //     (disciplineState === "" || order.discipline.toLowerCase().includes(disciplineState.toLowerCase()))
                        //     && (performerState === "" || (order.doer?.name + " " + order.doer?.lastName).toLowerCase().includes(performerState.toLowerCase()))
                        //     //&& (!withoutAP || !order.antiplagiat.need)
                        //     //&& (showMine ? true : order.creator._id !== store.user.id)
                        //
                        // ) {
                        //
                        //     orderCounterRef.current++

                        return <OrderShield

                            unreadModerMessages={moderNotificationsOut.indexOf(order._id) > -1}
                            title={order.title}
                            number={order.number}
                            type={order.type}
                            price={order.price}
                            discipline={order.discipline ? order.discipline : null}
                            date={order.closestDate ? "Ближайшая дата автора" : new Date(order.deadline).toLocaleDateString()}
                            antiplagiat={order.antiplagiat}
                            marker={"my"}
                            requests={order.requests}
                            customer={order.creator.name + " " + order.creator.lastName}
                            creationDate={new Date(order.creationDate).toLocaleString([],
                                {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    day: "numeric",
                                    month : 'numeric',
                                    year: 'numeric'
                                })}
                            id={order._id}

                            cretorId={order.creator._id}
                            doer={order.doer}
                            refuse={order.refuse}
                            onModeration={order.onModeration}
                            arbitration={order.arbitration}
                            reviewStatus={order.reviews?.creator}
                            status={order.orderStatus}
                            potentialDoer={order.potentialDoer}
                            key={index}
                        />//}

                    })

                    :
                    isFiltered
                        ?
                        <div className={"order-empty-placeholder"}>
                            <h5 className={"ubuntu"}>По данному фильтру нет совпадений ¯\_(ツ)_/¯</h5>

                        </div>
                        :
                        showEmpty
                            ?
                            <div className={"order-empty-placeholder"}>
                                <h5 className={"ubuntu"}>Вы еще не публиковали заказы ¯\_(ツ)_/¯</h5>
                            </div>
                            :
                            null

                }
                {stopFetching && orderList.length
                    ?
                    <div className={"order-empty-placeholder"}>
                        <h5 className={"ubuntu"}>Вы докрутили до конца ¯\_(ツ)_/¯</h5>

                    </div>
                    :
                    null
                }





            </div>

            <div className={"order-sorting-section-wrapper"} id={"order-sorting-section-wrapper"}>
            <div className={"order-sorting-section"} id={"order-sorting-section"}>
                <h6 className={"ubuntu margin-bottom-20"}>Фильтр</h6>

                <select
                    key={selectKey2}
                    className={"margin-bottom-10"}
                    onChange={(e) => {setFilter(e.target.value, "whole")}}
                    value={wholeFilter}
                    >
                    <option value={"all"}>Все</option>
                    <option value={"taken"}>🖊 В работе</option>
                    <option value={"closed"}>❌ Закрытые</option>
                    <option value={"moderation"}>⚖ Открыт спор</option>
                    <option value={"requests"}>❓ С откликами</option>
                    <option value={"offer"}>⏳ Без исполнителя</option>
                    <option value={"draft"}>📝 Черновики</option>

                </select>

                <select
                    key={selectKey1}
                    className={"margin-bottom-10"}
                    onChange={(e) => {setFilter(e.target.value, "date")}}>
                    value={dateSort}

                    <option value={"new"}>Сначала новые</option>
                    <option value={"old"}>Сначала старые</option>
                </select>

                <input
                    className={"margin-bottom-10"}
                    placeholder={"По дисциплине..."}
                    onChange={(e) => setDisciplineState(e.target.value)}
                    value={disciplineState}
                />
                <input
                    className={"margin-bottom-10"}
                    placeholder={"По поисполнителю..."}
                    onChange={(e) => setPerformerState(e.target.value)}
                    value={performerState}
                />

                <div className={"filter-spinner-n-reset-button-container"}>
                    <div className={"filter-spinner-container"}>
                        {showSpinner
                            ?
                            <img src={require('../img/loading.gif')} height={"35px"}/>
                            :
                            null
                        }
                    </div>

                    <div>
                        <button
                            className={"btn btn-danger btn-reset-filter"}
                            onClick={() => setFilter(null, 'reset')}
                        >Сбросить</button>
                    </div>

                </div>


            </div>
            </div>


        </div>

        // </div>
    );
};

export default observer(OutgoingFeed);
