import React, {useContext, useEffect, useRef, useState} from 'react'
import {Context} from "./index";
import {observer} from 'mobx-react-lite';
import {toJS} from "mobx";
import {Routes, Route, Link, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './css/styles.css'
import './css/lk.css'
import './css/loading-spinner.css'
import './css/wsChat.css'
import './css/custom-styles.css'
import './css/modal-dialog.css'
import './css/new-center.css'
import './css/admin-styles.css'
import Loading from "./components/Loading";
import Header from './components/Header'
import Footer from './components/Footer'
import Dossier from "./components/Dossier";
import NewOrder from "./components/NewOrder";
import OrderFeed from "./components/OrderFeed";
import OutgoingFeed from "./components/OutgoingFeed";
import OrderDetails from "./components/OrderDetails";
import Registration from "./components/Registration";
import Login from "./components/Login";
import Error404 from "./components/404";
import FirstChoice from "./components/FirstChoise";
import InviteMaker from "./components/InviteMaker";
import PersonalData from "./components/PersonalData";
import DisciplineAdder from "./components/DisciplineAdder";
import UserList from "./components/UserList";
import WsChat from "./components/ws-chat";
import WsChatArbitr from "./components/ws-chat-arbitr";
import WsChatAdmin from "./components/ws-chat-admin";
import $api from "./http";
import MessageCenter from "./components/(delete) MessageCenter";
import NewMessageCenter from "./components/NewMessageCenter";
import IncomingFeed from "./components/IncomingFeed";
import EventFeed from "./components/EventFeed";
import ModeratorRoom from "./components/ModeratorRoom";
import PayForm from "./components/PayForm";
import EmptyMessage from "./components/helpers/EmptyMessage";
import InfoMessage from "./components/helpers/InfoMessage"
import UserDetails from "./components/UserDetails";
import PrivateMessages from "./components/PrivateMessages";
import {API_IP} from "./http";
import PasswordReset from "./components/helpers/PasswordReset";
import UserSearch from "./components/UserSearch";
import AdminOffice from "./components/AdminOffice";
import Activator from "./components/helpers/Activator";
import TestingRoom from "./components/TestingRoom";
// import OldPayForm from "./components/helpers/OldPayForm"
import Messenger from "./components/Messenger";
import Oferta from "./components/helpers/Oferta"




function App() {

    const {store} = useContext(Context)
    const [newOrderMessage, setNewOrderMessage] = useState("")
    const [orderServiceMessage, setOrderServiceMessage] = useState([])
    const [orderIReadMassive, setOrderIReadMassive] = useState([])
    const [privateServiceMessage, setPrivateServiceMessage] = useState([])
    const [newChatMessage, setNewChatMessage] = useState("")
    const [newAdminMessage, setNewAdminMessage] = useState("")
    const [newArbitrMessage, setNewArbitrMessage] = useState("")
    const [newPrivateMessage, setNewPrivateMessage] = useState("")
    const [updater, setUpdater] = useState(0)
    // const [oldPayTrigger, setOldPayTrigger] = useState(0)


    const [newOrderAdded, setNewOrderAdded] = useState()
    const [notifyChatSynchronizer, setNotifyChatSynchronizer] = useState()
    const [typingUser, setTypingUser] = useState()
    const [typingUserPrivate, setTypingUserPrivate] = useState()
    const [incomingOrderToUpdate , setIncomingOrderToUpdate] = useState()

    const [arbitrAccess, setArbitrAccess] = useState()


    const [editingData, setEditingData] = useState()
    const [privateEditingData, setPrivateEditingData] = useState()

    const chat_message_notification_sound = new Audio('/sounds/message1.mp3')
    const private_message_notification_sound = new Audio('/sounds/message2.mp3')
    const event_message_notification_sound = new Audio('/sounds/message3.mp3')

    const chatsKeysRef = useRef({})


    const [verifyPhone, setVerifyPhone] = useState(false)


    const [pendingMessagesWorkChat, setPendingMessagesWorkChat] = useState([])
    //const [pendingMessagesCenter, setPendingMessagesCenter] = useState([])


    const [isMobile, setIsMobile] = useState(false);

    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 1050);
    };


    useEffect(() => {
        // Первоначальная проверка при загрузке компонента
        checkIsMobile();

        // Добавление слушателя события resize
        window.addEventListener('resize', checkIsMobile);

        // Удаление слушателя при размонтировании компонента
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);



    const location = useLocation();

    useEffect(() => {
        if(location.pathname === "/") {
            store.editWhitePointer({place: ""})
        }
        // Вы можете выполнить здесь любые дополнительные действия при изменении маршрута
    }, [location]);



    useEffect(() => {
        if(localStorage.getItem('token')) {
            //console.log('first load with token')
            store.checkAuth()
        } else {
            //console.log('first load without token')
        }

    }, [])



    const [connected, setConnected] = useState(false)
    const [connectedAdmin, setConnectedAdmin] = useState(false)

    const notifySocket = useRef()
    const adminSocket = useRef()

    const [zalupa, setZalupa] = useState(0)


    async function renewEventCounter() {
        const response = await $api.get(`/get_unread_events/${store.user.id}`)
        console.log(response)
        store.setEventCounter(response.data.eventCounter)
    }


    async function connectToWebSocket() {

        notifySocket.current = new WebSocket(`wss://${API_IP}:5000`,)

        notifySocket.current.onopen = async () => {

            const message = {
                event: 'connection',
                user: {...store.user}.id,
                id: Date.now()
            }
            //await console.log({...store.user}.id)
            await notifySocket.current.send(JSON.stringify(message))
            setConnected(true)
            console.log("NOTIFY  подключение установлено")
        }


        notifySocket.current.onmessage = (event) => {
            const message = JSON.parse(event.data)
            console.log(message)


            if(message.event === "phone_approved") {
                setVerifyPhone(true)
            }


            if(message.event === "arbitr-refresh") {
                setArbitrAccess(message.access)
                if(!message.access && store.whitePointer.place === "Детали заказа:") {
                    store.checkAuth()
                }

            }


            if(message.event === "work-message-read") {
                console.log("WORK-SERVICE-MESSAGE in APP")


                setOrderServiceMessage(prev => [...prev, message])

            }

            //этот сеттер отвечает за хранение айдишников сообщений которые пользователь уже прочитал
            //для реализации маркера непрочитанных сообщений на странице деталей заказа
            if(message.event === "i-read-work-message") {
                console.log("WORK-SERVICE-MESSAGE in APP")


                setOrderIReadMassive(prev => [...prev, ...message.messagesIdArray])

            }


            if(message.event === "private-message-read") {
                setPrivateServiceMessage(prev => [...prev, {messagesIdArray: message.messagesIdArray, reader: message.reader}])
            }

            if(message.event === "chat-removed") {
                console.log("CHAT REMOVED RECEIVED")
                store.deleteInvocationsByChatId(message.chat_id)
            }


            if(message.event === 'invocation') {
                console.log("INVOCATION RECEIVER")
                console.log(message)

                const playSound = JSON.parse(localStorage.getItem(`sound_${message.invocation.chat_id}`))
                if(playSound && store.whitePointer.place !== `${chatsKeysRef.current[message.invocation.chat_id]}:`) {
                    chat_message_notification_sound.play()
                    new Notification('Тихое уведомление', { silent: true });
                }

                store.addInvocation(message.invocation)
                //
                // console.log(toJS(store.invocations))
                //
                // let newInvocationState = store.invocations
                //
                // if (store.invocations[message.invocation.chat_id]) {
                //     newInvocationState[message.invocation.chat_id].push(message.invocation)
                // } else {
                //     newInvocationState[message.invocation.chat_id] = [message.invocation]
                // }
                //
                // store.setInvocations(newInvocationState)


            }

            if(message.event === 'remove-invocation') {
                console.log("REMOVE INVOCATION RECEIVER")
                console.log(message.invocation)
                store.removeInvocation(message.invocation._id.toString(), message.invocation.chat_id)
            }


            if(message.event === "user_typing") {
                console.log("USER TYPING RECEIVED")
                console.log(message)
                setTypingUser(message.user)
            }

            if(message.event === "user_typing_private") {
                console.log("PRIVATE USER TYPING RECEIVED")
                if(store.whitePointer.place === "Личные сообщения:")
                    console.log(message.user)
                setTypingUserPrivate(message.user)
                //залцупу не удалять! без нее не работает "юзер пишет"
                setZalupa(prev => prev + 1)
            }


            if(message.event === "chat-actions") {
                if(message.action === "remove") {
                    const freshChatList = store.chatList.filter(chat => chat.chat_id !== message.data.chat_id)
                    store.setChatList(freshChatList)
                }

                if(message.action === "add") {
                    store.addChatToChatList(message.data)
                }
            }

            if(message.event === "check-balance") {
                console.log(message)
                store.setBalance(message.balance)
                // setOldPayTrigger(prev => prev + 1)
            }


            if(message.event === "block-status") {
                console.log("ПРОБУЕМ ЗАБАНИТЬ")
                if(message.status === 'payment') {
                    store.setPaymentBlock(message.value)
                    document.location.reload()
                }
                if(message.status === "total-ban") {
                    console.log("МЫ В НУЖНОЙ ВЕТКЕ")
                    //store.setTotalBan(message.value)
                    document.location.reload()


                    //store.checkAuth()
                }
            }



            if(message.event === 'message-chat') {
                console.log(message)
                console.log("Setting new message-chat")
                setNewChatMessage(message)
            }

            if(message.event === "editing") {
                setEditingData(message.data)
            }

            if(message.event === "editing_private") {
                setPrivateEditingData(message.data)
            }


            if(message.event === 'message-arbitr') {
                console.log("Setting new arbitr-chat")
                setNewArbitrMessage(message)
            }


            if(message.event === 'message') {
                setNewOrderMessage(message)
                setPendingMessagesWorkChat(prev => [...prev, message])
                //setPendingMessagesCenter(prev => [...prev, message])
                console.log("LOG FROM APP")
                console.log(message)

                //если сообщение пришло не от себя самого
                if (message.userId._id !== store.user.id) {


                    if(!message.isAuthor) {
                        const playSound = JSON.parse(localStorage.getItem(`sound_work_out`));
                        if(playSound) {
                            event_message_notification_sound.play()
                        }
                    } else {
                        const playSound = JSON.parse(localStorage.getItem(`sound_work_in`));
                        if(playSound) {
                            event_message_notification_sound.play()
                        }
                    }





                    //если сообщение от автора заказа и отправитель не модер
                    if(message.isAuthor && message.userName !== "Модератор" && message.moderator !== store.user.id) {
                        //то увеличиваем счетчик сообщений ВХОДЯЩИХ заказов на +1
                        store.setWorkUnreadCounterIn(store.workUnreadCounterIn + 1)
                        console.log('IF 1')
                    }

                    //если сообщение НЕ от автора заказа и отправитель не модер
                    console.log(message)
                    if(!message.isAuthor && message.userName !== "Модератор" && message.moderator !== store.user.id) {
                        //то увеличиваем счетчик сообщений ИСХОДЯЩИХ заказов на +1
                        console.log("MARKER 1")
                        store.setWorkUnreadCounterOut(store.workUnreadCounterOut + 1)
                        console.log('IF 2')
                    }

                    //если сообщение от модератора и пользователь идет первым в поле to (значит он создатель заказа)
                    if(message.userName === "Модератор" && message.to[0] === store.user.id) {
                        //то увеличиваем счетчик сообщений ИСХОДЯЩИХ заказов на +1
                        console.log("MARKER 2")
                        store.setWorkUnreadCounterOut(store.workUnreadCounterOut + 1)
                        console.log('IF 3')
                    }

                    //если сообщение от модератора и пользователь идет вторым в поле to (значит он исполнитель заказа)
                    if(message.userName === "Модератор" && message.to[1] === store.user.id) {
                        //то увеличиваем счетчик сообщений ВХОДЯЩИХ заказов на +1
                        store.setWorkUnreadCounterIn(store.workUnreadCounterIn + 1)
                        console.log('IF 4')
                    }

                    //если текущий юзер является модером заказа по которому сообщение
                    if(message.moderator === store.user.id) {
                        //то увеличиваем счётчик модера на +1
                        store.setWorkUnreadCounterModer(store.workUnreadCounterModer + 1)
                        console.log('IF 5')
                    }

                }

            }

            if(message.event === 'notification') {

                console.log(message)
                console.log("SET NOT  COUNTER 2")
                console.log(store.notifyCounter)
                store.setNotifyCounter(store.notifyCounter + 1)



                if(!store.messagesAboutOutgoing.includes(message.about) && message.toCreator) {
                    console.log("Order added to outgoing messages list")
                    // async function addMessageAboutOutgoing() {
                    //     await $api.post('/add_order_about_incoming', {about: message.about})
                    // }
                    // addMessageAboutOutgoing()
                    console.log("ADDING TO messagesAboutOutgoing 2")
                    store.addMessagesAboutOutgoing(message.about)
                }
            }


            if (message.event === "moder_notification") {
                console.log("Я МОДЕРАТОР И МНЕ ПИСЬМО!")
                store.addToModerNotifyCounter(message.about)

            }

            if (message.event === "set_private_counter") {

                console.log(message)
                if (store.whitePointer.place === "Личные сообщения:") {
                    //const counter = parseInt(localStorage.getItem('messageCounter'))
                    //if (counter !== store.privateUnreadCounter) {
                    console.log("UNREAD PRIVATE SET 1")
                    store.setPrivateUnreadCounter(parseInt(message.number))
                    //}
                } else {
                    console.log("UNREAD PRIVATE SET 2")
                    store.setPrivateUnreadCounter(message.number)
                    console.log(message)
                }
            }

            if(message.event === "synchro_chats") {
                console.log("SYNCHRO CHATS RECEIVED")
                setNotifyChatSynchronizer(message.invocations)
            }


            if (message.event === "synchro_work") {
                    console.log("SYNCHRO RECEIVED")
                console.log(message)
                if (store.whitePointer.place === "Центр сообщений:") {
                    const counterIn = parseInt(localStorage.getItem('ls-workUnreadCounterIn'))
                    if (counterIn !== store.workUnreadCounterIn) {
                        store.setWorkUnreadCounterIn(counterIn)
                    }

                    const counterOut = parseInt(localStorage.getItem('ls-workUnreadCounterOut'))
                    if (counterOut !== store.workUnreadCounterOut) {
                        console.log("MARKER 4")
                        store.setWorkUnreadCounterOut(counterOut)
                    }

                } else {
                    //store.setPrivateUnreadCounter(message.number)
                    console.log("MARKER 5")
                    store.setWorkUnreadCounterOut(parseInt(message.numberOut))
                    store.setWorkUnreadCounterIn(parseInt(message.numberIn))
                    console.log(message)
                }
            }


            if(message.event === 'work_notificator_update') {

                console.log(message.values)
                console.log(typeof message.values.in)

                if(typeof message.values.in === "number") {
                    store.setWorkUnreadCounterIn(parseInt(message.values.in))
                }

                if(typeof message.values.out === "number") {
                    store.setWorkUnreadCounterOut(parseInt(message.values.out))
                }

                if(typeof message.values.moder === "number") {
                    store.setWorkUnreadCounterModer(parseInt(message.values.moder))
                }

            }


            if (message.event === "private_message") {
                console.log(message)
                console.log('received new PRIVATE message')
                if(message.sender !== store.user.id && store.whitePointer.place !== "Личные сообщения:") {
                    const playSound = JSON.parse(localStorage.getItem(`sound_private_${message.sender}`));
                    if(playSound) {
                        private_message_notification_sound.play()
                    }
                    store.setPrivateUnreadCounter(store.privateUnreadCounter + 1)
                }
                setNewPrivateMessage(message)
            }



            if(message.event === "update") {
                store.setUpdate()
                console.log("Update received")
                //setUpdater(prev => prev + 1)
            }

            if(message.event === "moder_update") {
                store.setModerUpdate()
                console.log("Moder Update received")
                //setUpdater(prev => prev + 1)
            }

            //обновлятор счётчика событий в хэдере, если он был изменен с другой вкладки
            if(message.event === "header") {

                console.log("Header counter update")
                //актуальное значение всегда при изменении записывается в локальное хранилище
                //поэтому достаем его оттуда и сравниваем с текущим значением счетчика, и если нужно то изменяем
                const localCounter = Number(localStorage.getItem('eventCounterHeader'))
                if(localCounter !== store.eventCounter) {
                    store.setEventCounter(localCounter)
                }
            }


            if(message.event === "feed_update") {
                console.log('Feed update received')
                console.log(message)
                console.log(message.order)
                setNewOrderAdded(message.order)
                //store.feedUpdate()
            }


            if(message.event === "order_deleted") {
                console.log('Feed update ORDER DELETED')
                store.setRemoveFromFeed(message.order)
            }

            if(message.event === "order_cancelled") {
                console.log('Feed update ORDER CANCELLED')
                store.setReturnToFeed(message.order)
            }

            if(message.event === "price_hide") {
                console.log('Feed update ORDER CANCELLED')
                store.setNewPriceFor(message.order)
            }


            if(message.event === 'update_incoming_feed') {
                console.log("Incoming feed update received")
                setIncomingOrderToUpdate(message.order)

            }


            if(message.event === "event") {

                console.log('Event received')
                renewEventCounter()

                const playSound = JSON.parse(localStorage.getItem(`sound_events`))
                if(playSound) {
                    event_message_notification_sound.play()
                    new Notification('Тихое уведомление', { silent: true });
                }

            }

            if(message.event === "moderation") {

                if(!message.remove) {
                    renewEventCounter()
                    console.log("Update Stakan")
                    store.moderStakanUpdate(message.order_id)
                } else {
                    console.log("Reduce Stakan")
                    store.moderStakanReduce(message.order_id)
                }

            }

            if(message.event === "approve") {
                store.setUpdate()
                store.updateUserInfo()
                console.log("approve received")
            }

            if(message.event === "update_user_info") {

                //store.updateUserInfo()
                store.checkAuth()

                console.log("update user info received")
            }


            if(message.event === "update_step") {
                store.setUpdateOrderProcess()
                store.updateUserInfo()
                if(message.closeDialog) {
                    store.setClosingDialog(message.about)
                }
                console.log("STEP update received")
            }
        }

        notifySocket.current.onclose = () => {
            console.log("Подключение NOTIFIER закрылось")
            setConnected(false)
            setTimeout(connectToWebSocket, 3000)


        }

        notifySocket.current.onerror = () => {
            console.log("Ошибка сокета")
        }

    }


    async function connectToAdminWebSocket() {
        adminSocket.current = new WebSocket(`wss://${API_IP}:5323`)

        adminSocket.current.onopen = () => {
            setConnectedAdmin(true)

            const message = {
                event: 'connection',
                user: {...store.user}.id,
                id: Date.now()
            }

            adminSocket.current.send(JSON.stringify(message))
            console.log("WS подключение установлено")


        }

        adminSocket.current.onmessage = async (event) => {
            const message = JSON.parse(event.data)
            console.log(message)
            if(message.event === "editing") {
                setEditingData(message.data)
            }

            if(message.event.startsWith("message")) {
                setNewAdminMessage(message)
            }

        }

        adminSocket.current.onclose = () => {
            console.log("Подключение закрылось")
            setConnected(false)

        }

        adminSocket.current.onerror = () => {
            console.log("Ошибка сокета")

        }

    }

    const [chatListReady, setChatListReady] = useState(false)

    async function getAllStartingData(user_id) {
        const response = await $api.get(`/get_starting_data?user_id=${user_id}`)


        await store.setStartValueForMessagesAboutIncoming(response.data.ordersAboutIncoming)
        await store.setStartValueForMessagesAboutOutgoing(response.data.ordersAboutOutgoing)
        console.log("UNREAD PRIVATE SET 3")
        await store.setPrivateUnreadCounter(Number(response.data.privateUserUnreadCount))
        await store.setInvocations(response.data.invocations)
        await store.setChatList(response.data.chatsInfo)
        await store.setEventCounter(response.data.eventCounter)
        await store.setRequested(response.data.requestedOrders)
        await store.setRequests(response.data.myRequests)

        setChatListReady(true)

        for(let chat of response.data.chatsInfo) {
            chatsKeysRef.current[chat.chat_id] = chat.chatName
        }

        await store.setBalance(store.user.balance)
        await store.setPaymentBlock(store.user.paymentBlock)
        await store.setTotalBan(store.user.totalBan)
        await store.setFullUserList(store.user.fullUserList)


    }



    //в этом эффекте поддягиваются все стартовые данные
    useEffect(() => {


        if(store.user.id !== undefined) {


            getAllStartingData(store.user.id)
            connectToWebSocket()
            connectToAdminWebSocket()


            // async function setStartingOrdersAbout() {
            //
            //     const myOrdersIncoming = await $api.get(`/get_incoming_orders_about/${store.user.id}`)
            //     const myOrdersOutgoing = await $api.get(`/get_orders_about_outgoing/${store.user.id}`)
            //
            //     await store.setStartValueForMessagesAboutIncoming(myOrdersIncoming.data)
            //     await store.setStartValueForMessagesAboutOutgoing(myOrdersOutgoing.data)
            //
            //
            // }
            //
            // setStartingOrdersAbout()



            // async function getUnreadPrivateCount() {
            //     const response = await $api.get(`/get_private_unread_count/${store.user.id}`)
            //     console.log(response.data)
            //     store.setPrivateUnreadCounter(Number(response.data.count))
            // }
            //
            // getUnreadPrivateCount()


            //эта функция не исползуется, я не помню почему, но пусть так и остается

            // async function getUnreadWorkCount() {
            //     //const response = await $api.get(`/get_work_unread_count/${store.user.id}`)
            //     //console.log(response.data)
            //     //console.log(toJS(store.user))
            //     store.setWorkUnreadCounterIn(store.user.workUnreadCountIn)
            //     console.log("MARKER 3")
            //     store.setWorkUnreadCounterOut(store.user.workUnreadCountOut)
            //     store.setWorkUnreadCounterModer(store.user.workUnreadCountModer)
            // }

            //getUnreadWorkCount()


            // async function getInvocations() {
            //     const response = await $api.get(`/get_invocations?user_id=${store.user.id}`)
            //     console.log(response.data)
            //     store.setInvocations(response.data)
            // }
            //
            //
            // getInvocations()



            // function setBalance() {
            //     store.setBalance(store.user.balance)
            // }
            //
            // setBalance()


            // function  setPaymentBlock() {
            //     store.setPaymentBlock(store.user.paymentBlock)
            // }
            //
            // setPaymentBlock()


            // function setTotalBan() {
            //     store.setTotalBan(store.user.totalBan)
            // }
            //
            // setTotalBan()

            // async function getChatsInfo() {
            //     const response = await $api.get(`/get_chats_info?user_id=${store.user.id}`)
            //     console.log(response.data)
            //     await store.setChatList(response.data)
            //
            //     for(let chat of response.data) {
            //         chatsKeysRef.current[chat.chat_id] = chat.chatName
            //     }
            //
            //     //console.log(chatsKeysRef.current)
            //
            // }
            //
            // getChatsInfo()

            // async function getEvents(id) {
            //     const response = await $api.get(`/get_unread_events/${id}`)
            //     store.eventCounter = response.data.message
            // }
            //
            // getEvents(store.user.id)


            // async function getRequested(id) {
            //
            //     const response = await $api.get(`/get_requested/${id}`)
            //     store.setRequested(response.data)
            //
            // }
            //
            // getRequested(store.user.id)



            // async function getRequests(id) {
            //
            //     const response = await $api.get(`/get_requests/${id}`)
            //     store.setRequests(response.data)
            //     //console.log(response)
            // }
            //
            // getRequests(store.user.id)


            // store.setFullUserList(store.user.fullUserList)


            // async function removeAndReturnToFeed(order_id) {
            //     await store.setRemoveFromFeed(order_id)
            //     await store.setReturnToFeed(order_id)
            // }

        }
    }, [store.user?.id])




    if(store.isLoading) {

        return (<div className="wrapper">
            <Header/>
            <div className="main" style={{alignItems: "center", justifyContent: "center"}}>
                <Loading/>
            </div>
            <Footer/>
        </div>)
    }

    if(!store.user.isActivated && store.isAuth) {

        //console.log("мы в блоке активации")
        return (
            <div className="wrapper">
                <Header/>
                <Routes>
                    <Route path={'/terms'} element={<Oferta/>}/>
                    {/*<Route path={'/old-pay-form'} element={<div className="main"><OldPayForm/></div>}/>*/}
                    <Route path={'/activate/:link'} element={<Activator/>}/>
                    <Route path={'*'} element={
                        <div className="main">
                            <div className={"welcome-screen"}>
                                <EmptyMessage
                                    title={`Учётная запись создана`}
                                    message={<p>Ссылка для активации уже у вас на почте, на всякий cлучай не забудьте проверить папку "спам"</p>}
                                />
                                {/*<h6>ЗАЛУПА</h6>*/}
                                {/*<h6>{!store.user.isActivated.toString()}</h6>*/}
                                {/*<h6>{store.isAuth.toString()}</h6>*/}
                            </div>
                        </div>
                    } />

                </Routes>

                <Footer/>
            </div>
        )
    }


    if(!store.isAuth && store.choice === "unknown") {

        return (<div className="wrapper">
            <Header/>
            <Routes>
                {/*<Route path={'/old-pay-form'} element={<div className="main"><OldPayForm/></div>}/>*/}

                <Route path={'/terms'} element={<Oferta/>}/>
                <Route path={'/password_reset/:link'} element={
                    <div className="main" style={{alignItems: "center", justifyContent: "center"}}>
                        <PasswordReset/>
                    </div>
                }/>

                <Route path={"*"} element={
                    <div className="main" style={{alignItems: "center", justifyContent: "center"}}>
                        <FirstChoice/>
                    </div>
                }/>

            </Routes>
            <Footer/>
        </div>)
    }


    if(!store.isAuth && store.choice === "sign") {
        return (<div className="wrapper">
            <Header/>
            <Routes>
                <Route path={'/terms'} element={<Oferta/>}/>
                <Route path={"*"} element={
                    <div className="main" style={{alignItems: "center", justifyContent: "center"}}>
                        <Login/>
                    </div>
                }/>

            </Routes>
            <Footer/>
        </div>)
    }

    if (!store.isAuth && store.choice === "register") {
        return (<div className="wrapper">
            <Header/>
            <Routes>
                <Route path={'/terms'} element={<Oferta/>}/>
                <Route path={"*"} element={
                    <div className="main" style={{alignItems: "center", justifyContent: "center"}}>
                        <Registration/>
                    </div>
                }/>

            </Routes>

            <Footer/>
        </div>)
    }


    if (store.isAuth && {...store.user}.isActivated && (!store.user.name || !store.user.lastName || !store.user.phone)) {
        console.log("мы в блоке сбора данных")
        console.log({...store.user})
        return (<div className="wrapper">
            <Header notifySocket={notifySocket}/>
            <Routes>
                {/*<Route path={'/old-pay-form'} element={<div className="main"><OldPayForm/></div>}/>*/}
                <Route path={'/terms'} element={<Oferta/>}/>
                <Route path={"*"} element={
                    <div className="main">
                        <PersonalData verifyPhone={verifyPhone} extists={false}/>
                    </div>
                }/>
            </Routes>

            <Footer/>
        </div>)
    }





        return (<div className='wrapper'>
                <Header isMobile={isMobile} notifySocket={notifySocket} notifyChatSynchronizer={notifyChatSynchronizer}/>
                <div className="main">

                    {!store.paymentBlock && !store.totalBan
                        ?

                        <Routes>

                            <Route path={'/testing_room'} element={<TestingRoom/>}/>
                            <Route path={'/terms'} element={<Oferta/>}/>
                            {/*<Route path={'/old-pay-form'} element={<OldPayForm payTrigger={oldPayTrigger}/>}/>*/}

                            <Route
                                path={"/weather"}
                                element={
                                    <div className={"welcome-screen"}>
                                        <EmptyMessage
                                            title={`Прогноз погоды`}
                                            message={<p>В вашем городе сегодня погода ясная, посмотрите в окно, {store.user.name}</p>}
                                        />
                                    </div>
                                }
                            />

                            <Route
                                path={"/news"}
                                element={
                                    <div className={"welcome-screen"}>
                                        <EmptyMessage
                                            title={`Хорошая новость`}
                                            message={<p>Вы теперь с нами, чего еще желать?!)</p>}
                                        />
                                    </div>
                                }
                            />


                            <Route
                                path={"/about"}
                                element={
                                    <div className={"welcome-screen"}>
                                        <EmptyMessage
                                            title={`Что вам рассказать про Магадан...`}
                                            message={<p>Мы здесь пытаемся создать уютный гибрид соцсеточки с сервисом по обмену
                                                заказами и их удобным отслеживанием.<br/>
                                                Вот примерно такой план:<br/>
                                                1. <span className={"line-through"}>Создать</span><br/>
                                                2. <span className={"line-through"}>Сд</span>елать удобным <img src={require('./img/loading.gif')} height={"35px"}/><br/>
                                                3. ...<br/>
                                                4. PROFIT!<br/><br/>
                                                Все просто как раз, два, три! Четыр-четыр =)</p>}
                                        />
                                    </div>
                                }
                            />


                            <Route
                                path={"/"}
                                element={
                                    <div className={"welcome-screen"}>
                                        <InfoMessage
                                            title={`Добро пожаловать, ${store.user.name}!`}
                                            message={
                                            <div>
                                                <p>Рады приветствовать вас у себя в гостях,
                                                спасибо что вы с нами!<br/>
                                                Вся навигация в кнопках сверху, или начните прямо отсюда:
                                                </p><br/>
                                                <div className={"welcome-btn-container"}>
                                                    <h6 className={"inline margin-right-20"}>- Можете создавать рабочие места</h6>
                                                    <Link to={"/place-new-order"}>
                                                        <button className={"btn primary-btn-color welcome-btn"}>Разместить заказ</button>
                                                    </Link>
                                                </div>
                                                <hr/>
                                                <div className={"welcome-btn-container"}>
                                                    <h6 className={"inline margin-right-20"}>- Или потрудиться самостоятельно</h6>
                                                    <Link to={"/order-feed"}>
                                                        <button className={"btn primary-btn-color welcome-btn"}>Лента заказов</button>
                                                    </Link>
                                                </div>
                                                <hr/>
                                                <div className={"welcome-btn-container"}>
                                                    <h6 className={"inline margin-right-20"}>- Просто пообщаться с коллегами</h6>
                                                    <Link to={"/messenger"}>
                                                        <button className={"btn primary-btn-color welcome-btn"}>Мессенджер</button>
                                                    </Link>
                                                </div>
                                            </div>
                                            }
                                        />

                                    </div>
                                }
                            />
                            <Route path={"/place-new-order"} element={<React.Fragment><NewOrder selectedValue={""}/></React.Fragment>}/>
                            <Route path={`/edit-order/:id`} element={<React.Fragment><NewOrder editing={true} /></React.Fragment>}/>

                            <Route path={"/user-details/:id"} element={
                                <React.Fragment>
                                    <UserDetails/>
                                </React.Fragment>}
                            />

                            <Route path={"/order-feed"} element={
                                <React.Fragment>
                                    <OrderFeed newOrderAdded={newOrderAdded}/>
                                </React.Fragment>}
                            />

                            <Route path={"/outgoing"} element={
                                <React.Fragment>
                                    <OutgoingFeed updater={updater}/>
                                </React.Fragment>}
                            />

                            <Route path={"/incoming"} element={
                                <React.Fragment>

                                    <IncomingFeed
                                        updater={updater}
                                        notifySocket={notifySocket}
                                        incomingOrderToUpdate={incomingOrderToUpdate}
                                    />
                                </React.Fragment>}
                            />
                            <Route path={"/order-details/:id"} element={
                                <React.Fragment>

                                    <OrderDetails
                                        notifySocket={notifySocket}
                                        newOrderMessage={newOrderMessage}
                                        updater={updater}
                                        orderServiceMessage={orderServiceMessage}
                                        setOrderServiceMessage={setOrderServiceMessage}
                                        orderIReadMassive={orderIReadMassive}
                                        setPendingMessages={setPendingMessagesWorkChat}
                                        pendingMessages={pendingMessagesWorkChat}
                                    />
                                </React.Fragment>}
                            />
                            <Route path={"/order-details-admin/:id"} element={
                                <React.Fragment>

                                    <OrderDetails
                                        //notifySocket={notifySocket}
                                        //newOrderMessage={newOrderMessage}
                                        //updater={updater}
                                        //orderServiceMessage={orderServiceMessage}
                                        //setOrderServiceMessage={setOrderServiceMessage}
                                        //orderIReadMassive={orderIReadMassive}
                                        //setPendingMessages={setPendingMessagesWorkChat}
                                        //pendingMessages={pendingMessagesWorkChat}
                                        adminView={true}
                                    />
                                </React.Fragment>}
                            />
                            {/*<Route path={"/register"} element={<Registration/>}/>*/}
                            <Route path={"/login"} element={<React.Fragment><button onClick={() => store.logout()}>Выйти</button></React.Fragment>}/>
                            {/*<Route path={"/users"} element={*/}
                            {/*    store.user.maintenance*/}
                            {/*        ?*/}
                            {/*        <UserList/>*/}
                            {/*        :*/}
                            {/*        <div style={{margin: "auto"}}>*/}
                            {/*            <EmptyMessage title={"Вас на этой страничке быть не должно"}*/}
                            {/*                          message={store.errorMessage}*/}
                            {/*            />*/}
                            {/*        </div>*/}

                            {/*}/>*/}
                            {/*<Route path={"/invite"} element={*/}
                            {/*    store.user.maintenance*/}
                            {/*        ?*/}
                            {/*        <InviteMaker/>*/}
                            {/*        :*/}
                            {/*        <div style={{margin: "auto"}}>*/}
                            {/*            <EmptyMessage title={"Вас на этой страничке быть не должно"}*/}
                            {/*                          message={store.errorMessage}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*}/>*/}

                            <Route path={"/user_search"} element={
                                <React.Fragment>
                                    <UserSearch/>
                                </React.Fragment>}
                            />


                            <Route path={"/personal_data"} element={<PersonalData verifyPhone={verifyPhone} exists={true}/>}/>
                            {/*<Route path={"/add_discipline"} element={<DisciplineAdder/>}/>*/}

                            {/*<Route path={"/chatroom"} element={*/}
                            {/*    <React.Fragment>*/}
                            {/*        <WsChat*/}
                            {/*            newChatMessage={newChatMessage}*/}
                            {/*            setNewChatMessage={setNewChatMessage}*/}
                            {/*            connected={connected}*/}
                            {/*            editingData={editingData}*/}
                            {/*            notifySocket={notifySocket}*/}
                            {/*            typingUser={typingUser}*/}
                            {/*        />*/}
                            {/*    </React.Fragment>*/}
                            {/*}/>*/}


                            {/*{store.user.arbitr*/}
                            {/*    ?*/}
                            {/*    <Route path={"/arbitroom"} element={*/}
                            {/*        <React.Fragment>*/}
                            {/*            <WsChatArbitr*/}
                            {/*                arbitrAccess={arbitrAccess}*/}
                            {/*            />*/}
                            {/*        </React.Fragment>*/}
                            {/*    }/>*/}
                            {/*    :*/}
                            {/*    null}*/}
                            {/*}*/}


                            {store.user.arbitr || store.user.admin || store. user.maintenance
                                ?
                                <Route path={"/arbitr_archive"} element={
                                    <React.Fragment>
                                        <WsChatArbitr
                                            arbitrAccess={arbitrAccess}
                                            archivedState={true}
                                        />
                                    </React.Fragment>
                                }/>
                                :
                                null
                            }



                            {/*<Route path={"/message_center"} element={<React.Fragment><MessageCenter notifySocket={notifySocket} newOrderMessage={newOrderMessage}/></React.Fragment>}/>*/}
                            <Route
                                path={"/new_message_center"}
                                element={
                                <React.Fragment>
                                    <NewMessageCenter
                                        notifySocket={notifySocket}
                                        newOrderMessage={newOrderMessage}
                                        orderServiceMessage={orderServiceMessage}
                                        setOrderServiceMessage={setOrderServiceMessage}
                                        // pendingMessagesCenter={pendingMessagesCenter}
                                        // setPendingMessagesCenter={setPendingMessagesCenter}
                                    />
                                </React.Fragment>
                            }/>
                            <Route path={"/events_feed"} element={
                                <React.Fragment>
                                    <EventFeed notifySocket={notifySocket}/>
                                </React.Fragment>}
                            />
                            <Route path={"/pay"} element={<PayForm/>}/>
                            {store.user.maintenance
                                ?
                                <Route path={"/moderator_room"} element={<ModeratorRoom notifySocket={notifySocket} newOrderMessage={newOrderMessage}/>}/>
                                :
                                null
                            }

                            <Route path="/wrong_way" element={
                                <div style={{margin: "auto"}}>
                                    <EmptyMessage title={"Вас на этой страничке быть не должно"}
                                                  message={store.errorMessage}
                                    />
                                </div>

                            }/>

                            <Route path={"/messenger"} element={

                                <Messenger
                                    //косаемо лички
                                    notifySocket={notifySocket}
                                    newPrivateMessage={newPrivateMessage}
                                    privateServiceMessage={privateServiceMessage}
                                    setPrivateServiceMessage={setPrivateServiceMessage}
                                    privateEditingData={privateEditingData}
                                    typingUserPrivate={typingUserPrivate}
                                    setTypingUserPrivate={setTypingUserPrivate}
                                    zalupa={zalupa}

                                    //пропсы диспута
                                    newChatMessage={newChatMessage}
                                    setNewChatMessage={setNewChatMessage}
                                    connected={connected}
                                    editingData={editingData}
                                    setEditingData={setEditingData}
                                    typingUser={typingUser}
                                    setTypingUser={setTypingUser}

                                    adminSocket={adminSocket}
                                    connectedAdmin={connectedAdmin}
                                    setConnectedAdmin={setConnectedAdmin}
                                    newAdminMessage={newAdminMessage}

                                    chatListReady={chatListReady}

                                />

                            }/>

                            {/*<Route path={"/private_messages"} element={*/}

                            {/*    <PrivateMessages*/}
                            {/*        notifySocket={notifySocket}*/}
                            {/*        newPrivateMessage={newPrivateMessage}*/}
                            {/*        privateServiceMessage={privateServiceMessage}*/}
                            {/*        setPrivateServiceMessage={setPrivateServiceMessage}*/}
                            {/*        privateEditingData={privateEditingData}*/}
                            {/*        typingUserPrivate={typingUserPrivate}*/}
                            {/*    />*/}

                            {/*}/>*/}

                            {/*<Route path={"/chat/:id"} element={*/}

                            {/*    <WsChatAdmin*/}
                            {/*        adminSocket={adminSocket}*/}
                            {/*        connected={connectedAdmin}*/}
                            {/*        setConnected={setConnectedAdmin}*/}
                            {/*    />*/}

                            {/*}/>*/}


                            {store.user.admin
                                ?
                                <Route path={"/admin_office"} element={
                                    <AdminOffice/>
                                }
                                />
                                :
                                null
                            }
                            {/*{store.user.admin*/}
                            {/*    ?*/}
                            {/*    <Route path={"/admin_chat"} element={*/}
                            {/*        <WsChatAdmin adminSocket={adminSocket}/>*/}
                            {/*    }*/}
                            {/*    />*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            <Route path="*" element={

                                    <EmptyMessage title={"Такой странички сегодня нет 404!"}
                                                  message={store.errorMessage}
                                    />

                            }/>

                        </Routes>

                        :


                        !store.totalBan
                            ?
                            <Routes>

                                <Route path={"/"} element={
                                    <div className={"welcome-screen"}>
                                        <EmptyMessage
                                            title={`Здравствуйте, ${store.user.name}`}
                                            message={"К сожалению ваш доступ заблокирован, так как у вас недостаточно средств для списания абонентской платы"}
                                            payButton={true}
                                        />
                                    </div>
                                }/>

                                <Route path={"/personal_data"} element={<PersonalData verifyPhone={verifyPhone} exists={true}/>}/>
                                <Route path={"/pay"} element={<PayForm/>}/>
                                {/*<Route path={'/old-pay-form'} element={<OldPayForm payTrigger={oldPayTrigger}/>}/>*/}

                                {store.user.maintenance
                                    ?
                                    <Route path={"/moderator_room"} element={<ModeratorRoom notifySocket={notifySocket} newOrderMessage={newOrderMessage}/>}/>
                                    :
                                    null
                                }



                                <Route path="*" element={<div style={{margin: "auto", width: "50%"}}>
                                    <EmptyMessage
                                        title={`Здравствуйте, ${store.user.name}`}
                                        message={"К сожалению ваш доступ заблокирован, так как у вас недостаточно средств для списания абонентской платы"}
                                        payButton={true}
                                    />
                                </div>}/>

                            </Routes>
                            :
                            <Routes>

                                {/*<Route path={"/"} element={<div className={"welcome-screen"}><h1 className={"ubuntu"}>Добро пожаловать, {{...store.user}.name}</h1></div>}/>*/}
                                <Route path="*" element={<div style={{margin: "auto", width: "50%"}}>
                                    <EmptyMessage
                                        title={`Здравствуйте, ${store.user.name}`}
                                        message={"Ваш доступ к системе заблокирован по решению администрации"}
                                        // payButton={true}
                                    />
                                </div>}/>

                            </Routes>



                    }

                </div>
                {store.pageId === "messenger" || store.pageId === "message-center"
                    ?
                    null
                    :
                    <Footer/>
                }

            </div>

        );






}

export default observer(App);

