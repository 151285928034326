import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App'
import Store from "./store/store";


const store = new Store();


export const Context = createContext({
    store,

})


const root = createRoot(document.getElementById('root'));

root.render(
    <Context.Provider value={{store}}>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    </Context.Provider>);

//так раньше выглядел рендер реакт Апп, оставил это в образовательных целях, я так понял, эта функция
//слишком часто меняется
//ReactDOM.render(<App />, document.getElementById('root'));




