import React, {useEffect} from 'react';
import {API_URL} from "../../http";


const ModalImgViewer = ({
                     active,
                     setActive,
                     imgSrc,
                     setImgSrc


}) => {

    useEffect(() => {
        const handlePressEsc = (event) => {
            if (event.code === 'Escape') {
                setActive("progress")
                setTimeout(() => {
                    setActive("no")
                    setImgSrc()
                }, 300)
            }
        };

        document.addEventListener('keydown', handlePressEsc);

        return () => {
            document.removeEventListener('keydown', handlePressEsc);
        };
    }, [])

    // console.log(API_URL + "/" + imgSrc)


    return (
        <div className={active === "active"
                                    ?
                                    "modal active z-999"
                                    :
                                    active === "progress" ? "modal" : "none"}
             onClick={() => {
                 setActive("progress")
                 setTimeout(() => {
                     setActive("no")
                     setImgSrc()
                 }, 300)
             }}
        >
            <div className={active === "active"
                ?
                `modal-content-img active back-of-viewer`
                :
                active === "progress" ? "modal-content-img back-of-viewer" : "none"}
                 onClick={(e) => e.stopPropagation()}>


                <img className={"full-image-view dont-drag"} src={API_URL + "/" + imgSrc}/>


            </div>
        </div>
    );
};

export default ModalImgViewer;